import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Checkbox, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { ClientSelector } from "@components/client"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { StyledTextarea } from "@components/common/textareas"
import { AbilityContext } from "@components/permissions"
import { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import ServicesContext from "@context/ServicesContext"
import Hierarchy from "@models/Hierarchy"
import Product, { ProductType } from "@models/Product"
import { StatsVulnerabilities } from "@models/Stats"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import ActionButton from "@components/common/Button/ActionButton"
import ExpiredLicenses from "./ExpiredLicenses"
import ProviderGrid from "./ProviderGrid"
import HierarchyGrid from "./HierarchyGrid"

const initValue = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchies: [],
    inheritance: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    provider_ids: [],
    location: "",
    created_at: ""
}

const Details = ({ id }: { id: string | undefined }) => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [data, setData] = useState<Product>(initValue)
    const [formData, setFormData] = useState<Product>(initValue)
    const ability = useContext(AbilityContext)
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const statsService = useContext(ServicesContext).statsService
    const [successfulMessage, setSuccessfulMessage] = useState<string>()
    const navigate = useNavigate()
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filter, setFilter] = useState({})
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy)[][]>([])
    const [providers, setProviders] = useState<string[]>([""])
    const componentService = useContext(ServicesContext).componentService
    const [expiredLicenses, setExpiredLicenses] = useState<
        { license: string; license_expiration_date: string }[]
    >([])
    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)
                setProviders(val.provider_ids || [""])
                if (val.hierarchies && val.hierarchies.length > 0) {
                    const fetchedHierarchies = await Promise.all(
                        val.hierarchies.map(async (path) => {
                            const pathIds = path.split(PATH_SEPARATOR)
                            const hierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id))) // Obtener categorías
                            return hierarchies.map(h => h || null)
                        })
                    )
                    setSelectedHierarchies(fetchedHierarchies)
                }

                const filterssss: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(filterssss)
                const expiredLicensesData = await componentService.getExpiredLicensesByProductID(id as string)

                // Filtrar licencias expiradas
                const validExpiredLicenses = expiredLicensesData.expired_components.filter((license: any) => {
                    const expirationDate = new Date(license.license_expiration_date)
                    return (
                        license.license && !isNaN(expirationDate.getTime()) && expirationDate < new Date()
                    )
                })

                setExpiredLicenses(validExpiredLicenses)
                setVulnStats(vulnsStats)
                const initialFilter = {
                    sortField: "name",
                    sortOrder: "asc"
                }
                setFilter(initialFilter)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        if (id) fecthData()
    }, [id])
    // --------------------------------------------------
    // Functions
    const saveHandler = async () => {
        try {
            await productService.update(formData.id, formData)
            setData(formData)
            navigate("../")
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.version === "",
            formData.description === ""
        ]

        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === data.name,
            formData.version === data.version,
            formData.description === data.description,
            formData.type === data.type,
            formData.hierarchies === data.hierarchies,
            formData.location === data.location,
            formData.inheritance === data.inheritance,
            formData.provider_ids === data.provider_ids
        ]
        ability.can("*", "Client") ?? isNotSameOldValues.push(formData.client.id === data.client.id)
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    const handleChange = (e: any) => {
        let events: { target: { name: string, value?: any, checked?: boolean, type: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value, checked, type } }) => {
            if (type === "checkbox") {
                value = checked
            }
            return { ...result, [name]: value }
        }, formData))
    }
    // --------------------------------------------------
    const styles = {
        fontFamily: "Griff",
        "& .MuiInputBase-root": {
            color: theme.palette.primary.main, // Color del texto
            backgroundColor: theme.palette.background.paper // Color de fondo
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.primary.main // Color de la línea subrayada antes de enfocar
        },
        "& .MuiInput-underline:hover:before": {
            borderBottomColor: theme.palette.secondary.main // Color de la línea al pasar el cursor
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.dark // Color de la línea al enfocar
        }
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>

                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || successfulMessage || context.t.translate("an_error")}
                        severity={successfulMessage ? "success" : "error"}
                    />
                    <Grid container flexDirection="column" spacing="20px">
                        <Grid item flexDirection="row">
                            <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                <Grid item>
                                    <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("product_details")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <StyledBox>
                            <Grid item container gap={3}>
                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    {ability.can("*", "Product") && (

                                        <Grid item xs={12} md={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("client_name")}</Typography>
                                            <ClientSelector value={formData.client.id} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                                        </Grid>

                                    )}
                                    <Grid item xs={12} md={6}>
                                        {/* SHOW EXPIRED LICENSE */}
                                        {expiredLicenses.length > 0 && <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder" >
                                    Expired Licenses
                                        </Typography>
                                        }
                                        <ExpiredLicenses licenses={expiredLicenses} />
                                    </Grid>
                                </Grid>

                                <Grid item container flexDirection="row" alignItems='center' gap={3}>
                                    <Grid item xs={12} md={2}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_name")}</Typography>
                                        <TextField disabled={!ability.can("*", "Product")} name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff" }} sx={styles} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_version")}</Typography>
                                        <TextField disabled={!ability.can("*", "Product")} name="version" value={formData.version} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff" }} sx={styles} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_location")}</Typography>
                                        <TextField disabled={!ability.can("*", "Product")} name="location" value={formData.location} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff" }} sx={styles} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("type")}</Typography>
                                        <TextField disabled={!ability.can("*", "Product")} margin="normal" select required variant="standard" name="type" style={{ fontFamily: "Griff" }}
                                            value={formData.type} onChange={handleChange} sx={styles}>
                                            {ProductTypeKeys.map((key, index) =>
                                                (<MenuItem key={index} value={ProductType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>{key}</Typography></MenuItem>)
                                            )}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Inheritance</Typography>
                                        <Checkbox checked={formData.inheritance} onChange={handleChange} name="inheritance" />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ width: "100%", maxWidth: { xs: "100%", md: "60%" } }}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                        Hierarchy
                                    </Typography>
                                    <Grid item direction="column" spacing={2}>
                                        <HierarchyGrid
                                            initialHierarchies={selectedHierarchies}
                                            clientId={formData.client.id}
                                            onChange={(hierarchies: Hierarchy[][]) => {
                                                setSelectedHierarchies(selectedHierarchies)
                                                setFormData({ ...formData, hierarchies: hierarchies.map(h => h.map(h => h.id).join(";")) })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                                Provider
                                        </Typography>
                                        <Grid item direction="column" spacing={2}>
                                            <ProviderGrid
                                                initialProviders={formData.provider_ids}
                                                onChange={(providers: string[]) => {
                                                    setFormData({ ...formData, provider_ids: providers })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={8}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_description")}</Typography>
                                        <StyledTextarea minRows={3} name="description" placeholder="Enter description here..." value={formData.description} onChange={handleChange} style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }} />
                                    </Grid>

                                    <Grid item>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_versions")}</Typography>
                                    </Grid>
                                    <Grid item paddingTop={1}>
                                        <ActionButton onClick={saveHandler} disabled={!formValid()} text={context.t.translate("product_save")} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid>

                    {/* <ProductTree/> */}
                </Grid>
            </Grid >
        </Grid >)
}

export default Details
