import React, { useContext } from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../Styles"
const i18n = {
    es: {
        footer: {
            copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
            confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
            disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
            errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    },
    en: {
        footer: {
            copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
            confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
            disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
            errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    }
} as const
const Header: React.FC<{ template?: Template }> = ({ template }) => {
    let imagen
    let isCustomLogo = false
    if (template?.logo && template.logo !== "") {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
        isCustomLogo = true
    } else {
        imagen = "/assets/img/header_caf.png"
    }
    return (
        <View fixed style={{ marginBottom: isCustomLogo ? 150 : 0 }}>
            <View>
                <Image
                    style={{
                        width: "100%", // Se adapta al ancho del PDF
                        height: "auto", // Mantiene la proporción original
                        objectFit: "contain"
                    }}
                    src={imagen}
                />

            </View>

            {!isCustomLogo && (
                <>
                    <Text style={{ ...styles.textoEncima }}></Text>
                    <Text>&nbsp;</Text>
                </>
            )}
        </View>
    )
}

const Footer: React.FC<{ color?: string, lang?: string }> = ({ color, lang }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    // --------------------------------------------------

    // Render variables

    // --------------------------------------------------

    return (
        <View fixed style={{ width: "100%", position: "absolute", backgroundColor: color || "#271968", flexDirection: "row", justifyContent: "space-between", columnGap: 20, bottom: 0, padding: 25 }}>
            <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 5.5, color: "#fff" }}>
                    {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                    {i18n[locale].footer.disclosureWarning}
                    {i18n[locale].footer.errorNotice}
                </Text>
            </View>
            <View style={{ flex: 0.2, textAlign: "right", alignItems: "flex-end", justifyContent: "center" }}>
                <Text style={{ fontSize: 13, color: "#fff" }} render={({ pageNumber }) => `${pageNumber}`} />
            </View>
        </View>
    )
}
export { Header, Footer }
