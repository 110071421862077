import React, { useContext, useState } from "react"
import Highcharts, { Chart } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

highchartsAccessibility(Highcharts)

interface ColumnValues {
    values: {
        critical: { mitigated: number, unmitigated: number };
        high: { mitigated: number, unmitigated: number };
        medium: { mitigated: number, unmitigated: number };
        low: { mitigated: number, unmitigated: number };
        none: { mitigated: number, unmitigated: number };
    };
}

const ColumnChart: React.FC<ColumnValues> = (props: ColumnValues) => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const theme = useTheme()
    const isMobile = useMediaQuery("(max-width: 768px)")
    const [legend, setLegend] = useState(<></>)

    const options: Highcharts.Options = {
        title: { text: undefined },
        chart: {
            type: "column",
            backgroundColor: "transparent",
            height: isMobile ? 320 : 500, // Altura ajustada
            width: isMobile ? undefined : 1050, // Ancho dinámico
            spacingLeft: isMobile ? 5 : 10, // Ajuste de espaciado
            spacingRight: isMobile ? 5 : 10,
            style: {
                fontFamily: "Roboto",
                fontWeight: "700"
            }
        },
        credits: { enabled: false },
        legend: { enabled: false },
        plotOptions: {
            column: {
                colorByPoint: false,
                borderRadius: 4,
                borderWidth: 0,
                groupPadding: isMobile ? 0.1 : 0.05, // Más espacio en móvil para evitar columnas invisibles
                pointPadding: isMobile ? 0.1 : 0.05
            }
        },
        xAxis: {
            categories: [
                context.t.translate("critical"),
                context.t.translate("high"),
                context.t.translate("medium"),
                context.t.translate("low"),
                context.t.translate("none")
            ],
            labels: {
                style: {
                    color: theme.palette.primary.main,
                    fontSize: isMobile ? "12px" : "14px"
                }
            },
            tickWidth: 1,
            tickColor: theme.palette.primary.main
        },
        yAxis: {
            lineWidth: 1,
            lineColor: theme.palette.primary.main,
            gridLineWidth: 0,
            tickWidth: 1,
            tickColor: theme.palette.primary.main,
            title: {
                text: context.t.translate("mitigated_vs_unmitigated"),
                style: {
                    color: theme.palette.primary.main,
                    fontSize: isMobile ? "12px" : "16px"
                },
                margin: 10
            },
            labels: {
                style: {
                    color: theme.palette.primary.main,
                    fontSize: isMobile ? "12px" : "14px"
                }
            }
        },
        series: [
            {
                name: context.t.translate("mitigated"),
                data: [
                    { x: 0, y: props.values.critical.mitigated, color: "#7940A540" },
                    { x: 1, y: props.values.high.mitigated, color: "#D9534F40" },
                    { x: 2, y: props.values.medium.mitigated, color: "#EC971F40" },
                    { x: 3, y: props.values.low.mitigated, color: "#F2CC0C40" },
                    { x: 4, y: props.values.none.mitigated, color: "#A9A9A940" }
                ],
                type: "column",
                dataLabels: {
                    enabled: true,
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: isMobile ? "9px" : "10px",
                        fontWeight: "bold"
                    }
                }
            },
            {
                name: context.t.translate("unmitigated"),
                data: [
                    { x: 0, y: props.values.critical.unmitigated, color: "#7940A5" },
                    { x: 1, y: props.values.high.unmitigated, color: "#D9534F" },
                    { x: 2, y: props.values.medium.unmitigated, color: "#EC971F" },
                    { x: 3, y: props.values.low.unmitigated, color: "#F2CC0C" },
                    { x: 4, y: props.values.none.unmitigated, color: "#A9A9A9" }
                ],
                type: "column",
                dataLabels: {
                    enabled: true,
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: isMobile ? "9px" : "10px",
                        fontWeight: "bold"
                    }
                }
            }
        ],
        tooltip: { enabled: false },
        exporting: { enabled: false }
    }

    const chartCallback = (chart: Chart) => {
        setLegend(
            <Grid container flexDirection={isMobile ? "column" : "row"} spacing={isMobile ? 1 : 2}>
                {chart.series.map((serie, i) => (
                    <Grid key={i} item margin="auto">
                        <Typography fontSize={isMobile ? "12px" : "16px"} fontWeight="700">
                            {serie.name}
                        </Typography>
                        <Grid container flexDirection="column" spacing={1}>
                            {serie.data.map((point, j) => (
                                <Grid key={j} item container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <span
                                            style={{
                                                height: isMobile ? "8px" : "15px",
                                                width: isMobile ? "8px" : "15px",
                                                backgroundColor: point.color as any,
                                                borderRadius: "50%",
                                                display: "block"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography fontSize={isMobile ? "10px" : "15px"}>
                                            {point.category}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        )
    }

    return (
        <Grid container justifyContent="center" alignItems="center" flexDirection={isMobile ? "column" : "row"}>
            <Grid item xs={12} sm={10} md={8}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    callback={chartCallback}
                    containerProps={{ style: { width: "100%", height: "100%" } }}
                />
            </Grid>
            <Grid item xs={12} sm={10} md={4}>
                {legend}
            </Grid>
        </Grid>
    )
}

export { ColumnChart }
export default ColumnChart
