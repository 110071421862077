import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import ComponentTableDefinition from "@components/component/ComponentTableDefinition"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Component from "@models/Component"
import { Grid, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useContext, useEffect, useState } from "react"

const AffectedComponentList = (row: any) => {
    const componentService = useContext(ServicesContext).componentService
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ComponentTableDefinition, ability))
    }, [])
    const theme = useTheme()
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>

                    <Grid item container flexDirection="row" spacing="15px">
                        <GenericTable<Component> entity="Component" columns={cols}
                            dataProvider={(filter) => {
                                if (row && typeof row === "object" && "row" in row && row.row !== "") {
                                    // Si `row` es un objeto con `identifier`, usa el identificador para la llamada al servicio

                                    return componentService.getComponentsByCVE(row.row as string)
                                }
                                // Si `row` no es un identificador o no tiene `identifier`, maneja el caso aquí
                                // Por ejemplo, podrías retornar un conjunto vacío o algún valor predeterminado

                                return componentService.getComponentsByCVE(row as string) // Retorna una promesa resuelta con un array vacío o maneja el caso apropiadamente
                            }}
                        />

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AffectedComponentList
