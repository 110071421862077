import React, { useContext } from "react"
import HeatMap from "@uiw/react-heat-map"
import Tooltip from "@uiw/react-tooltip"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"
import { Grid, Typography } from "@mui/material"
import { I18nContext } from "I18nProvider"

export type DateCount = {
    count: number,
    date: string
}

type SampleHeatMapProps = {
    dateCountData: DateCount[]
}

const SampleHeatMap: React.FC<SampleHeatMapProps> = ({ dateCountData }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - 1)

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Typography variant="h6" sx={{ textAlign: "left", fontWeight: "bold", mb: 1 }}>
                    {context.t.translate("discovered_vulns")}
                </Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="space-between">
                <HeatMap
                    value={dateCountData}
                    width="100%" // Esto hará que el HeatMap ocupe el 100% del contenedor disponible
                    height={300} // Puedes ajustar el tamaño de altura según lo que necesites
                    startDate={startDate}
                    rectRender={(props, data) => {
                        return (
                            <Tooltip placement="top" content={`count: ${data.count || 0}`}>
                                <rect {...props} />
                            </Tooltip>
                        )
                    }}
                    panelColors={{
                        0: "#E8E8E8",
                        20: CVSS3SeverityColors.low,
                        40: CVSS3SeverityColors.medium,
                        60: CVSS3SeverityColors.high,
                        80: CVSS3SeverityColors.critical
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default SampleHeatMap
