import React, { useMemo } from "react"
import { Controls, Edge, Node, Position, ReactFlow } from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import Component from "@models/Component"
import { useTheme } from "@mui/material"

type ComponentHierarchyProps = {
    parentComponent: Component;
    childComponents: Component[];
    subDependencies: Component[];
};

const ComponentDependencies: React.FC<ComponentHierarchyProps> = ({ parentComponent, childComponents, subDependencies }) => {
    const theme = useTheme()

    const calculatePositions = useMemo(() => {
        const verticalSpacing = 100 // Separación vertical entre nodos
        const horizontalSpacing = 300 // Espacio horizontal entre niveles
        const subDepSpacing = 150 // Mayor espacio entre sub-dependencias

        const positions = new Map<string, { x: number; y: number }>()

        // Posicionar el nodo padre en el centro vertical de la pantalla
        positions.set(parentComponent.id, { x: 100, y: 250 })

        // Distribuir los nodos hijos a la derecha del nodo padre
        childComponents.forEach((child, index) => {
            const offset = (index - Math.floor(childComponents.length / 2)) * verticalSpacing
            positions.set(child.id, { x: 500, y: 250 + offset })
        })

        // Posicionar las sub-dependencias con más separación
        childComponents.forEach((child) => {
            const childSubDependencies = subDependencies.filter((subDependency) =>
                subDependency.depends_on?.includes(child.id)
            )

            if (childSubDependencies.length > 0) {
                const childPosition = positions.get(child.id) || { x: 500, y: 250 }

                // Calcular la altura inicial para centrar las sub-dependencias
                const totalSubDeps = childSubDependencies.length
                const totalSpacing = (totalSubDeps - 1) * subDepSpacing
                const startY = childPosition.y - totalSpacing / 2

                // Distribuir sub-dependencias con más espacio
                childSubDependencies.forEach((subDependency, index) => {
                    positions.set(subDependency.id, {
                        x: childPosition.x + horizontalSpacing,
                        y: startY + index * subDepSpacing
                    })
                })
            }
        })

        return positions
    }, [parentComponent, childComponents, subDependencies])

    // El resto del código permanece igual que en tu versión original
    const nodes: Node[] = [
        {
            id: parentComponent.id,
            position: calculatePositions.get(parentComponent.id) || { x: 100, y: 250 },
            data: { label: parentComponent.name },
            type: "default",
            sourcePosition: Position.Right,
            targetPosition: Position.Right,
            style: {
                backgroundColor: theme.palette.secondary.dark,
                color: "white",
                border: "2px solid gray",
                borderRadius: "12px",
                padding: "10px",
                width: "auto",
                minWidth: "150px",
                textAlign: "center" as const
            }
        },
        ...childComponents.map((child) => ({
            id: child.id,
            position: calculatePositions.get(child.id) || { x: 500, y: 250 },
            data: { label: child.name },
            type: "default",
            sourcePosition: child.depends_on ? Position.Right : Position.Left,
            targetPosition: Position.Left,
            style: {
                backgroundColor: theme.palette.primary.main,
                color: "white",
                border: "2px solid gray",
                borderRadius: "12px",
                padding: "10px",
                width: "auto",
                minWidth: "150px",
                textAlign: "center" as const
            }
        })),
        ...subDependencies.map((subDependency, index) => {
            const position = calculatePositions.get(subDependency.id)
            return {
                id: subDependency.id,
                position: position ?? { x: 800, y: 100 + index * 100 }, // Usamos el índice para distribuir si no hay posición
                data: { label: subDependency.name },
                type: "default",
                sourcePosition: Position.Left,
                targetPosition: Position.Left,
                style: {
                    backgroundColor: theme.palette.success.main,
                    color: "white",
                    border: "2px solid gray",
                    borderRadius: "12px",
                    padding: "10px",
                    width: "auto",
                    minWidth: "150px",
                    textAlign: "center" as const
                }
            }
        })
    ]

    const edges: Edge[] = [
        ...childComponents.map((child) => ({
            id: `${parentComponent.id}-${child.id}`,
            source: parentComponent.id,
            target: child.id,
            type: "bezier",
            animated: false,
            style: {
                stroke: theme.palette.text.secondary,
                strokeWidth: 2
            }
        })),
        ...subDependencies.map((subDependency) => {
            const edges: Edge[] = []
            childComponents.forEach((child) => {
                if (child.depends_on?.includes(subDependency.id)) {
                    edges.push({
                        id: `${child.id}-${subDependency.id}`,
                        source: child.id,
                        target: subDependency.id,
                        type: "bezier",
                        animated: false,
                        style: {
                            stroke: theme.palette.text.secondary,
                            strokeWidth: 2
                        }
                    })
                }
            })
            return edges
        }).flat()
    ]

    return (
        <div style={{ width: "100%", height: "600px" }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
                proOptions={{ hideAttribution: true }}
            >
                <Controls
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.main
                    }}
                />
            </ReactFlow>
        </div>
    )
}

export default ComponentDependencies
