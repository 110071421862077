import React, { useContext } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import { FilterOperation } from "@utils/index"
import { I18nContext } from "I18nProvider"

interface ProviderSelectorProps {
    value: string;
    disabled?: boolean;
    onSelect: (id: string | undefined) => void;
}

const ProviderSelector: React.FC<ProviderSelectorProps> = ({ value, disabled, onSelect }) => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const clientService = useContext(ServicesContext).clientService

    return (
        <SearchInput<Client>
            margin="normal"
            required
            label={context.t.translate("provider_name")}
            variant="outlined"
            value={value}
            disabled={disabled}
            requesterById={async (v) => {
                if (v && v !== "00000000-0000-0000-0000-000000000000") {
                    const client = await clientService.get(v)
                    return client?.provider ? [client] : []
                }
                return [{ id: "", name: "", description: "" }]
            }}
            requester={async (v) => {
                const response = v
                    ? await clientService.getAll({
                        filters: [{ field: "name", operation: FilterOperation.StringContains, value: v }]
                    })
                    : await clientService.getAll()

                return response.list
            }}
            getterID={(elem) => elem?.id}
            getterTitle={(elem) => `${elem.name}`}
            onClickElem={(v) => onSelect(v?.id)}
        />
    )
}

export { ProviderSelector }
export default ProviderSelector
