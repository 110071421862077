import React, { useContext, useEffect, useState } from "react"
import { Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import { convertModelDefToGridColDef } from "@components/common/tables"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { AbilityContext } from "@components/permissions"
import ProductTableDefinition, { ProductTableDefinitionES } from "@components/product/ProductTableDefinition"
import ActionButton from "@components/common/Button/ActionButton"

const initValue = {
    id: "",
    name: "",
    description: ""
}

const ClientDetailTab: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const clientService = useContext(ServicesContext).clientService
    const productService = useContext(ServicesContext).productService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [cols, setCols] = useState<GridColDef[]>([])
    const [queryParameters, setQueryParameters] = useState<QueryParameters>()
    const ability = useContext(AbilityContext)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ClientDetail" })
    }, [])
    const { id } = useParams<{ id: string }>()

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex)
    }

    const [data, setData] = useState<Client>(initValue)
    const [formData, setFormData] = useState<Client>(initValue)
    const formValid = !Object.keys(formData).every((key) => (formData as any)[key] === (data as any)[key])
    const [tabIndex, setTabIndex] = useState(0)
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await clientService.get(id as string)
                setData(val)
                setFormData(val)
            } catch (e) {
                setError(e as Error)
            }
        }
        fecthData()
        const policyTableDefinition = context.language === "es" ? ProductTableDefinitionES : ProductTableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
        setQueryParameters((prev) => ({
            ...prev,
            filters: [
                { field: "client_id", operation: FilterOperation.UUIDEqual, value: id ?? "" }
            ]
        }))
    }, [])

    const navigate = useNavigate()

    const saveHandler = async () => {
        try {
            await clientService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e) {
            setError(e as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Grid item container direction="column">
            <Grid item container flexDirection="column">
                <Grid item xs={12}>
                    <StyledBox sx={{ padding: { xs: "8px", md: "24px" }, width: "100%" }}>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={error?.message || context.t.translate("an_error")}
                        />
                        <Grid container flexDirection="column" spacing={2}>

                            {/* Botón de guardar */}
                            <Grid item container justifyContent="flex-end" sx={{ marginTop: "10px" }}>
                                <Grid item>
                                    <ActionButton text="Save Changes" onClick={saveHandler} disabled={!formValid} />
                                </Grid>
                            </Grid>

                            {/* Línea divisoria */}
                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                            </Grid>

                            {/* Campo Nombre */}
                            <Grid item container spacing={2} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="24px" fontFamily="Griff" fontWeight="bolder">
                                        {context.t.translate("name")}
                                    </Typography>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        value={formData.name}
                                        onChange={handleChange}
                                        variant="standard"
                                        InputProps={{ style: { fontFamily: "Griff", color: theme.palette.secondary.main } }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Campo Descripción */}
                            <Grid item xs={12}>
                                <Typography color="primary" fontSize="24px" fontFamily="Griff" fontWeight="bolder">
                                    {context.t.translate("description")}
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
            { /* TODO: Review this part */ }
            { /* <Grid item xs container flexDirection="column" spacing="20px" mt={5}>
                <Grid item container flexDirection="column" paddingTop="0 !important" rowGap="35px">
                    <Products></Products>
                </Grid >
            </Grid > */ }
        </Grid>
    )
}

export { ClientDetailTab }
export default ClientDetailTab
