type HBOM = {
    header_information: HeaderInformation,
    finished_product_details: FinishedProductDetail,
    entity_name: EntityName,
    entity_location: EntityLocation,
    component_part_info: ComponentPartInfo,
    component_part_details: ComponentPartDetails,
    product_details: ProductionDetails
}

type HeaderInformation = {
    std_version: string,
    author: string,
    supplier: string,
    fga_num: string,
    fga_decription: string
}

type FinishedProductDetail = {
    type?: FGAType
    version: string
}

enum FGAType {
    Hardware = "hardware",
    Software = "software",
    Service = "service"
}
type EntityName = {
    hash: string; // An intrinsic identifier for the subject of this HBOM
    main_manufacturer: string; // Company that manufactures or assembles the product
    alt_manufacturer: string; // Alternate company that manufactures or assembles the product
    comp_supplier: string; // Component Supplier (if not purchased directly from OEM)
    comp_manufacturer: string; // Component Manufacturer (If Semiconductor, list the Fab name as well)
};
type EntityLocation = {
    assy_and_test_supplier: string;
    supplier_loc: string;
    loc_coords: string;
    loc_code: string;
    main_location: string;
    main_loc_coords: string;
    main_loc_code: string;
    alt_location: string;
    alt_loc_coords: string;
    alt_loc_code: string;
    comp_mfg_location: string; // Manufacturing location of the component
    comp_mfg_loc_coords: string; // Latitude and longitude coordinates of the component MFG location
    comp_mfg_loc_code: string; // ISO Standard code for the component MFG location
    assy_and_test_location: string; // Assembly & Test Location(s) for Semiconductor parts
    assy_and_test_loc_coords: string; // Latitude and longitude coordinates of the A&T location
};

type ComponentPartInfo = {
    assy_and_test_loc_code: string; // ISO Standard for Country and Subdivisions
    comp_supplier_pn: string; // Component unique identifier (ERP part number)
    comp_manufacturer_pn: string; // OEM's Manufacturing Part Number
    comp_description: string; // Description of the component
    comp_type: "hardware" | "software" | "service" | string; // Type of component
    comp_part_type: string; // Component category (e.g., semiconductor, antenna, etc.)
    comp_part_code: string; // Industry-standard component code
    comp_hash: string; // An intrinsic identifier for the component
};

type ComponentPartDetails = {
    comp_opt_name: string; // Optional, industry-standard or project-specific name
    comp_version: string; // Hardware, Software, or Firmware version number
};

type ProductionDetails = {
    comp_datasheet: string; // Datasheet URL
    supplier_sourced_pctg: number; // Percentage sourced from this supplier
    leadtimes: number; // Supplier's lead time in days
    quantity: number; // Quantity needed to produce one unit
    technology_node: string; // Process node in nm (for semiconductors)
    comp_part_size_val: string; // Component size value
    comp_part_size_unit: string; // Unit of measure for component size
    comp_datecode: string; // Component timestamp/lot date code
};
const initData: HBOM = {
    header_information: {
        std_version: "",
        author: "",
        supplier: "",
        fga_num: "",
        fga_decription: ""
    },
    finished_product_details: {
        version: ""
    },
    entity_name: {
        hash: "",
        main_manufacturer: "",
        alt_manufacturer: "",
        comp_supplier: "",
        comp_manufacturer: ""
    },
    entity_location: {
        assy_and_test_supplier: "",
        supplier_loc: "",
        loc_coords: "",
        loc_code: "",
        main_location: "",
        main_loc_coords: "",
        main_loc_code: "",
        alt_location: "",
        alt_loc_coords: "",
        alt_loc_code: "",
        comp_mfg_location: "",
        comp_mfg_loc_coords: "",
        comp_mfg_loc_code: "",
        assy_and_test_location: "",
        assy_and_test_loc_coords: ""
    },
    component_part_info: {
        assy_and_test_loc_code: "",
        comp_supplier_pn: "",
        comp_manufacturer_pn: "",
        comp_description: "",
        comp_type: "",
        comp_part_type: "",
        comp_part_code: "",
        comp_hash: ""
    },
    component_part_details: {
        comp_opt_name: "",
        comp_version: ""
    },
    product_details: {
        comp_datasheet: "",
        supplier_sourced_pctg: 0,
        leadtimes: 0,
        quantity: 0,
        technology_node: "",
        comp_part_size_val: "",
        comp_part_size_unit: "",
        comp_datecode: ""
    }
}
export { initData }
export type { ProductionDetails, ComponentPartDetails, ComponentPartInfo, EntityLocation, EntityName, FGAType, FinishedProductDetail, HeaderInformation }
export default HBOM
