import ActionButton from "@components/common/Button/ActionButton"
import Vulnerability from "@models/Vulnerability"
import { I18nContext } from "I18nProvider"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
type MitigateVulnProps= {
    value: Vulnerability
}
const MitigateVuln: React.FC<MitigateVulnProps> = ({ value }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const navigate = useNavigate()
    return (
        <ActionButton text={context.t.translate("mitigate")} onClick={() => navigate(`../vulnerability/mitigate/${value.identifier}`)} disabled={value.supressed}/>
    )
}

export default MitigateVuln
