import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const scope = [
    ["Identification, Documentation and Management of Software Components", "SBOM analysis involves identifying all software components used in an application or system, including libraries, frameworks, modules, plugins, and any other elements that contribute to the operation of the software."],
    ["Vulnerability scan", "Once a complete SBOM has been generated, a vulnerability scan can be performed to identify potential security gaps in software components."],
    ["Vulnerability Prioritization", "This analysis involves evaluating the severity of each identified vulnerability, followed by the generation of a list ordered by its level of criticality."]
]

type TestType = "Fuzzing" | "Network Stress";
const i18n = {
    es: {
        indexTitle: "2. Alcance",
        scopeintroduction: "En el presente documento se presentan los resultados obtenidos tras la ejecución de los test de robustez de comunicaciones sobre la plataforma AchillesDEMO, en adelante Dispositivo Bajo Prueba (DUT).",
        scope: [
            ["Identificación, Documentación y Gestión de Componentes de Software", "El análisis SBOM implica identificar todos los componentes de software utilizados en una aplicación o sistema, incluidas bibliotecas, frameworks, módulos, complementos y cualquier otro elemento que contribuya al funcionamiento del software."],
            ["Escaneo de Vulnerabilidades", "Una vez que se ha generado un SBOM completo, se puede realizar un escaneo de vulnerabilidades para identificar posibles brechas de seguridad en los componentes del software."],
            ["Priorización de Vulnerabilidades", "Este análisis implica evaluar la gravedad de cada vulnerabilidad identificada, seguido de la generación de una lista ordenada por su nivel de criticidad."]
        ],
        scope1: "En el transcurso de este test, se realiza una ejecución completa de todos los tests, aquellos que pertenecen a los de tipo",
        scope2: "lo que permite identificar los tests problemáticos, entendiendo como tal aquellos en los que se producen no conformidades graves. Después, se realizan más ejecuciones de esos tests para confirmar y en su caso diagnosticar la causa de los problemas."
    },
    en: {
        indexTitle: "2. Scope",
        scopeintroduction: "This document presents the results obtained after conducting the communication robustness tests on the AchillesDEMO platform, hereinafter referred to as Device Under Test (DUT).",
        scope: [
            ["Identification, Documentation and Management of Software Components", "SBOM analysis involves identifying all software components used in an application or system, including libraries, frameworks, modules, plugins, and any other elements that contribute to the operation of the software."],
            ["Vulnerability Scan", "Once a complete SBOM has been generated, a vulnerability scan can be performed to identify potential security gaps in software components."],
            ["Vulnerability Prioritization", "This analysis involves evaluating the severity of each identified vulnerability, followed by the generation of a list ordered by its level of criticality."]
        ],
        scope1: "During this test, a full execution of all tests is carried out, specifically those that belong to the type",
        scope2: "which allows identifying problematic tests, understood as those where serious non-conformities occur. Subsequently, more executions of these tests are performed to confirm and, if necessary, diagnose the cause of the issues."
    }
} as const

interface SectionProps {
    externalData: any
    assessment: Assessment
    testType: TestType
    customScope?: string
    template?: Template
    lang?: string
}

const ScopeSection:React.FC<SectionProps> = ({ assessment, testType, externalData, customScope, template, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style= {{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>

            <Header externalData={null} template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].indexTitle}</Text>
                    <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{i18n[locale].scopeintroduction}</Text>
                    {externalData && (
                        <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{externalData.descripcion}</Text>
                    )}
                    <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{i18n[locale].scope1} {testType}, {i18n[locale].scope2}</Text>
                    <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{customScope}</Text>
                </View>
                <View style={styles.column}>
                    <Image style={styles.rightImageFull} src="/assets/img/report/octogono.jpg" />
                </View>
            </View>
            <Footer externalData={null}></Footer>
        </Page>
    )
}

export { ScopeSection }
