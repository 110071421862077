import React, { useEffect, useState } from "react"
import { Grid, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ActionButton from "@components/common/Button/ActionButton"
import ProviderSelector from "@components/providers/ProviderSelector"

interface ProviderGridProps {
    initialProviders?: string[]; // Opcional para iniciar con datos existentes
    onChange?: (updatedProviders: string[]) => void; // Prop para pasar el estado actualizado al padre
}

const ProviderGrid: React.FC<ProviderGridProps> = ({ initialProviders = [], onChange }) => {
    const [providers, setProviders] = useState<string[]>(initialProviders || [])
    const [selectedProviders, setSelectedProviders] = useState<string[]>(initialProviders || []) // Mantener los proveedores seleccionados

    useEffect(() => {
        if (initialProviders) {
            setProviders(initialProviders)
            setSelectedProviders(initialProviders)
        }
    }, [initialProviders])

    const handleAddProvider = () => {
        const updatedProviders = [""]
        setProviders(updatedProviders)
        onChange?.(updatedProviders) // Notificar al padre
    }

    const handleProviderSelect = (id: string | undefined) => {
        if (id && selectedProviders.includes(id)) return // Avoid duplicates
        if (id)setProviders([id])
        setSelectedProviders(id ? [id] : [])
        onChange?.(id ? [id] : [])
    }

    const handleDeleteProvider = (index: number) => {
        const updatedProviders = providers.filter((_, i) => i !== index)
        setProviders(updatedProviders)

        const providerToDelete = providers[index]
        setSelectedProviders(prev => prev.filter(id => id !== providerToDelete))

        onChange?.(updatedProviders) // Notificar al padre
    }

    return (
        <Grid container spacing={2}>
            {providers.length > 0
                ? providers.map((providerId, index) => (
                    <Grid item key={index} alignItems="center" spacing={2} display="flex" gap={1} xs={8}>
                        <Grid item xs={10}>
                            <ProviderSelector
                                value={providerId}
                                onSelect={(id: string) => handleProviderSelect(id)}
                                selectedProviders={selectedProviders} // Pasamos los proveedores seleccionados
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color="error" onClick={() => handleDeleteProvider(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))
                : (<Grid item xs={12}>
                    <ActionButton text="Add New Provider" onClick={handleAddProvider} />
                </Grid>)}

        </Grid>
    )
}

export default ProviderGrid
