
import { Provider } from "@models/Provider"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class ProviderService extends Service<Provider> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/providers", MediaTypes.JSON)
    }

    public getByIDExt = (id: string) => {
        return this.http.get(`/${this.path}Ext/${id}`) as any
    }
}

export { ProviderService }
export default ProviderService
