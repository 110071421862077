import { Checkbox, Divider, Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import { Vulnerability } from "@models/Vulnerability"
import { FilterOperation } from "@utils/queryParams"
import { useHierarchy } from "@context/useHierarchy"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

const HierarchyMitigate: React.FC = () => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const theme = useTheme()
    const { identifier } = useParams<{ identifier: string }>()
    const { track } = useTrack()
    const { vulnerabilityService, mitigateService } = useContext(ServicesContext)
    const productService = useContext(ServicesContext).productService
    const { hierarchyId } = useHierarchy()
    const { showDialog } = useConfirmationDialog()
    const navigate = useNavigate()

    // State management
    const [vulnerabilities, setVulnerabilities] = useState<Vulnerability[]>([])
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]) // Store selected products' IDs
    const [products, setProducts] = useState<any[]>([]) // Store products retrieved from productService
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
    const [severity, setSeverity] = useState<"success" | "error">("success")

    useEffect(() => {
        track("HierarchyMitigate")
    }, [])

    useEffect(() => {
        const fetchVulnerabilitiesAndProducts = async () => {
            try {
                if (identifier) {
                    const response = await vulnerabilityService.getAll({
                        filters: [{ field: "identifier", operation: FilterOperation.StringEqual, value: identifier }]
                    })
                    setVulnerabilities(response.list)

                    const productIds = Array.from(new Set(response.list.map(vuln => vuln.product.id)))

                    if (productIds.length > 0) {
                        const queryParams = {
                            filters: [{ field: "id", operation: FilterOperation.UUIDArrayContains, value: productIds.toString() }]
                        }

                        const productsResponse = await productService.getAll(queryParams)

                        if (!hierarchyId) {
                            setProducts(productsResponse.list)
                            // Marcar todos los productos como seleccionados
                            setSelectedProducts(productsResponse.list.map((product: any) => product.id))
                            return
                        }

                        const filteredProducts = productsResponse.list.filter((product: any) =>
                            product.hierarchy === hierarchyId
                        )

                        setProducts(filteredProducts)
                        // Marcar todos los productos filtrados como seleccionados
                        setSelectedProducts(filteredProducts.map((product: any) => product.id))
                    }
                }
            } catch (error: any) {
                console.error("Error fetching data:", error)
                setSnackbarMessage(error.message || "Error fetching data")
                setSeverity("error")
                setSnackbarOpen(true)
            }
        }

        fetchVulnerabilitiesAndProducts()
    }, [identifier, vulnerabilityService, productService, hierarchyId])

    const areProductsSelected = (): boolean => {
        return selectedProducts.length > 0
    }

    const handleProductCheckboxChange = (productId: string) => {
        setSelectedProducts(prev =>
            prev.includes(productId) ? prev.filter(id => id !== productId) : [...prev, productId]
        )
    }

    const confirmMitigation = () => {
        showDialog(
            context.t.translate("confirm_mitigation"),
            context.t.translate("are_you_sure_mitigate"),
            async () => {
                try {
                    await mitigateService.mitigateProducts(selectedProducts, identifier || "")
                    navigate("../../manage/hierarchy")
                } catch (error: any) {
                    console.error("Error mitigating products:", error)
                    setSnackbarMessage(error.message)
                    setSeverity("error")
                    setSnackbarOpen(true)
                }
            },
            context.t.translate("mitigate")
        )
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <CustomSnackbar
                        message={snackbarMessage}
                        onClose={() => setSnackbarOpen(false)}
                        severity={severity}
                        open={snackbarOpen}
                    />
                    <StyledBox>
                        <Grid item container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                    {context.t.translate("mitigate")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item my={2}>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        </Grid>
                        <Grid item container flexDirection="row" alignItems="center" spacing="40px">
                            <Grid item container flexDirection="column" rowGap="25px">
                                <Typography variant="h6">{context.t.translate("mitigate_vuln")}</Typography>
                                <Grid item container flexDirection="column" rowGap="20px">
                                    {products.map((product) => (
                                        <Grid item key={product.id} container alignItems="center">
                                            <Checkbox
                                                checked={selectedProducts.includes(product.id)}
                                                onChange={() => handleProductCheckboxChange(product.id)}
                                            />
                                            <Typography>{`${product.name} - ${product.version}`}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid>
                                    <ActionButton text={context.t.translate("mitigate")} onClick={confirmMitigation} disabled={!areProductsSelected()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
        </Grid>
    )
}

export { HierarchyMitigate }
export default HierarchyMitigate
