import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { CVSS3CriticalityKeysValueOps, getCVSS3Criticality } from "@models/Vulnerability"
import { CVSS3Severity } from "@models/index"

interface NumberColoredCVSSProps {
  value: number;
}

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type CVSS3SeverityColorMap = {
  [key in CVSS3Severity]: ColorAndBg;
};

const getCVSS3Color = (severity: CVSS3Severity): ColorAndBg => {
    const cvss3ColorMap: CVSS3SeverityColorMap = {
        [CVSS3Severity.None]: { color: "#000000", "background-color": "#A9A9A9" }, // gris
        [CVSS3Severity.Low]: { color: "#000000", "background-color": "#F2CC0C" }, // amarillo
        [CVSS3Severity.Medium]: { color: "#000000", "background-color": "#EC971F" }, // naranja
        [CVSS3Severity.High]: { color: "#000000", "background-color": "#D9534F" }, // rojo
        [CVSS3Severity.Critical]: { color: "#000000", "background-color": "#7940A5" }, // púrpura
        [CVSS3Severity.Unknown]: { color: "#000000", "background-color": "#ccc" } // negro
    }

    return cvss3ColorMap[severity]
}

const NumberColoredCVSS: React.FC<NumberColoredCVSSProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg>(getCVSS3Color(CVSS3Severity.None))
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        const criticality = getCVSS3Criticality(value)
        setColor(getCVSS3Color(criticality))
        setLabel(CVSS3CriticalityKeysValueOps.find(v => v.value === criticality)?.label)
    }, [value])

    return (
        <>
            {value !== undefined &&
        (<Chip size="small" style={{
            fontWeight: "bolder",
            color: color.color,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color.color,
            backgroundColor: color["background-color"]
        }} label={value === null ? "Unknown" : value.toFixed(1) + " " + label} />)}
        </>
    )
}

export { NumberColoredCVSS, getCVSS3Color }
export default NumberColoredCVSS
