import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import moment from "moment"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"

type TestType = "Fuzzing" | "Network Stress";
const i18n = {
    es: {
        robustness: "Diagnóstico de robustez de comunicaciones en",
        monthyear: "Mes y año:",
        footer: {
            copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
            confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
            disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
            errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    },
    en: {
        robustness: "Communications Robustness Diagnosis in",
        monthyear: "Month and year:",
        footer: {
            copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
            confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
            disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
            errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    }
} as const
interface SectionProps {
    assessment: Assessment;
    testType: TestType;
    lang?: string;
}

const FrontPage: React.FC<SectionProps> = ({ assessment, testType, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4">
            <View fixed>
                <Image style={styles.image} src="/assets/img/report/portada.jpg" />
                <View style={styles.tablea}>
                    <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                        <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                            <Text style={{ ...styles.titulopage1, fontFamily: "Griff", fontSize: "20px" }}>{i18n[locale].robustness} { assessment.product.name}</Text>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Bold" }}>{testType} Test {/* TODO: Translate */}</Text>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Bold" }}>{ assessment.client.name }</Text>
                        </View>
                        <View style={{ ...styles.tableCola2 }}>
                            <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                                <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: -40, paddingBottom: 0, fontFamily: "Griff" }}>{i18n[locale].monthyear}</Text>
                                </View>
                                <View style={{ ...styles.column, marginLeft: -50, marginTop: -40 }}>
                                    <Text style={{ ...styles.titulo2page1, paddingLeft: "-10px", paddingBottom: 2, fontFamily: "Griff" }}>{moment(assessment.endDate ? assessment.endDate : moment()).format("MMMM YYYY")}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <FooterFrontPage></FooterFrontPage>
            </View>
        </Page>
    )
}

const FooterFrontPage: React.FC<{lang?: string}> = ({ lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View>
            <View>
                <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                    {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                    {i18n[locale].footer.disclosureWarning}
                    {i18n[locale].footer.errorNotice}
                </Text>
            </View>
        </View>
    )
}

export { FrontPage }
