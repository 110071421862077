import ServicesContext from "@context/ServicesContext"
import Component from "@models/Component"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import ComponentDependencies from "./ComponentDependencies"

type DependenciesTabProps = {
    parentComponent: Component
}

const DependenciesTab: React.FC<DependenciesTabProps> = ({ parentComponent }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)

    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const componentService = useContext(ServicesContext).componentService
    const [components, setComponents] = useState<Component[]>([])
    const [subDependencies, setSubDependencies] = useState<Component[]>([]) // Nuevo estado para las dependencias de los hijos

    useEffect(() => {
        if (!parentComponent?.depends_on || parentComponent.depends_on.length === 0) return

        // Función para obtener los componentes principales
        const fetchComponentData = async () => {
            try {
                const fetchedComponents = await Promise.all(
                    (parentComponent.depends_on ?? []).map((id) => componentService.get(id))
                )
                setComponents(fetchedComponents)

                // Obtener las dependencias de los hijos
                const childDependencies = await Promise.all(
                    fetchedComponents.flatMap((child) =>
                        child.depends_on ? child.depends_on.map((id) => componentService.get(id)) : []
                    )
                )

                // Filtrar duplicados y establecer el estado
                setSubDependencies(Array.from(new Set(childDependencies.map((child) => child.id)))
                    .map((id) => childDependencies.find((child) => child.id === id)) // Mantener los elementos únicos
                    .filter((child): child is Component => child !== undefined)
                )
            } catch (error) {
                console.error("Error fetching components:", error)
            }
        }

        fetchComponentData()
    }, [parentComponent, componentService])

    return (
        <Grid item container>
            <Typography
                fontSize="45px"
                fontWeight="bolder"
                fontFamily="Griff"
                sx={{ marginTop: "-20px", marginBottom: "20px" }}
                color={theme.palette.text.secondary}
            >
                {context.t.translate("dependencies")}
            </Typography>
            <ComponentDependencies
                parentComponent={parentComponent}
                childComponents={components}
                subDependencies={subDependencies} // Pasamos las sub-dependencias al componente hijo
            />
        </Grid>
    )
}

export default DependenciesTab
