import React from "react"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsAccessibility from "highcharts/modules/accessibility"
import HighchartsReact from "highcharts-react-official"
import { useTheme } from "@mui/material"

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsAccessibility(Highcharts)

interface BenchmarkProductChartValues {
    firstProduct: string;
    secondProduct: string;

    firstItem: Array<number>;
    secondItem: Array<number>;
}

const BenchmarkProductChart: React.FC<BenchmarkProductChartValues> = ({ firstProduct, secondProduct, firstItem, secondItem }) => {
    const theme = useTheme()
    const options: Highcharts.Options = {
        chart: {
            type: "line",
            backgroundColor: theme.palette.background.default
        },
        title: {
            text: ""
        },
        xAxis: {
            categories: [firstProduct, secondProduct],
            crosshair: true,
            labels: { style: { color: theme.palette.text.primary } }
        },
        yAxis: {
            min: 0,
            title: { text: "" },
            labels: { style: { color: theme.palette.text.primary } }
        },
        tooltip: {
            valueSuffix: ""
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            itemStyle: {
                color: theme.palette.text.primary
            }
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        series: [
            { type: "column", name: "Critical", data: [(firstItem === undefined ? 0 : firstItem[0]), (secondItem === undefined ? 0 : secondItem[0])], color: "#7940A5" },
            { type: "column", name: "High", data: [(firstItem === undefined ? 0 : firstItem[1]), (secondItem === undefined ? 0 : secondItem[1])], color: "#D9534F" },
            { type: "column", name: "Medium", data: [(firstItem === undefined ? 0 : firstItem[2]), (secondItem === undefined ? 0 : secondItem[2])], color: "#EC971F" },
            { type: "column", name: "Low", data: [(firstItem === undefined ? 0 : firstItem[3]), (secondItem === undefined ? 0 : secondItem[3])], color: "#A9A9A9" }
        ]
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export { BenchmarkProductChart }
export default BenchmarkProductChart
