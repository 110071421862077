import React, { useEffect } from "react"
import { Grid } from "@mui/material"
import Products from "@views/home/Products"
import { useTrack } from "@components/track/TrackContext"

const Home: React.FC = () => {
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "Home" })
    }, [])
    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" paddingTop="0 !important" rowGap="35px">
                <Grid id="first"></Grid>
                <Products></Products>
            </Grid >
        </Grid >
    )
}

export { Home }
export default Home
