// PolicyForm.tsx
import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { I18nContext } from "I18nProvider"
import { AbilityContext, Can } from "@components/permissions"
import { useTrack } from "@components/track/TrackContext"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { ClientSelector } from "@components/client"
import ActionForm from "@components/policy/ActionForm"
import ServicesContext from "@context/ServicesContext"
import Policy, {
    ActionsType,
    Condition,
    ConditionField,
    ConditionTarget,
    ModificationActionsData,
    NotificationActionsData
} from "@models/Policy"
import { FilterOperation } from "@utils/queryParams"
import { initValue } from "./constants/constAndInit"
import PolicyBasicInfo from "./BasicInfo"
import ConditionsList from "./ConditionsList"
const PolicyForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    const policyService = useContext(ServicesContext).policyService
    const [error, setError] = useState<Error | null>(null)
    const navigate = useNavigate()
    const { track } = useTrack()

    // State for conditions and form
    const [conditions, setConditions] = useState<Condition[]>([])
    const [formData, setFormData] = useState<Policy>(initValue)

    // Action data specific states
    const [notificationData, setNotificationData] = useState<NotificationActionsData>({ emails: [], transport_types: [] })
    const [modificationData, setModificationData] = useState<ModificationActionsData[]>([])

    useEffect(() => {
        track({ view: "PolicyForm" })
    }, [track])

    useEffect(() => {
        // Update formData whenever conditions change
        setFormData(prev => ({
            ...prev,
            policy_conditions: conditions
        }))
    }, [conditions])
    useEffect(() => {
        console.log("FORM", formData)
    }, [formData])
    // Update actionsData based on action type and related data
    useEffect(() => {
        setFormData(prev => {
            if (prev.policy_actions === ActionsType.Notification) {
                return {
                    ...prev,
                    policy_actions_data: {
                        type: ActionsType.Notification,
                        data: notificationData
                    }
                }
            } else if (prev.policy_actions === ActionsType.Modification) {
                return {
                    ...prev,
                    policy_actions_data: {
                        type: ActionsType.Modification,
                        data: modificationData
                    }
                }
            }

            return prev
        })
    }, [notificationData, modificationData, formData.policy_actions])

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown; checked?: boolean; type?: string }>) => {
        const target = e.target as { name?: string; value: unknown; checked?: boolean; type?: string }
        if (!target.name) return

        const value = target.type === "checkbox" ? target.checked : target.value
        const fieldName = target.name as keyof Policy

        setFormData(prev => {
            const updatedData: Partial<Policy> = { [fieldName]: value }

            // If action changes, also update actionsData.type
            if (fieldName === "policy_actions") {
                updatedData.policy_actions_data = {
                    type: value as ActionsType,
                    data: value === ActionsType.Notification
                        ? { emails: [], transport_types: [] }
                        : []
                }

                // Reset the appropriate action data when switching types
                if (value === ActionsType.Notification) {
                    setNotificationData({ emails: [], transport_types: [] })
                } else if (value === ActionsType.Modification) {
                    setModificationData([])
                }
            }

            return {
                ...prev,
                ...updatedData
            }
        })
    }

    // Add a new condition
    const handleAddCondition = () => {
        const newCondition: Condition = {
            id: "",
            target: ConditionTarget.Vulnerability,
            field: ConditionField.EPSS,
            operator: FilterOperation.StringEqual,
            value: ""
        }
        setConditions([...conditions, newCondition])
    }

    // Delete a condition
    const handleDeleteCondition = (index: number) => {
        if (conditions.length <= 1) {
            // Don't delete if it's the only condition
            return
        }
        const updatedConditions = conditions.filter((_, i) => i !== index)
        setConditions(updatedConditions)
    }

    // Update condition
    const handleConditionChange = (updatedCondition: Condition, index: number) => {
        const updatedConditions = [...conditions]
        updatedConditions[index] = updatedCondition
        setConditions(updatedConditions)
    }

    // Handle notification data changes
    const handleNotificationChange = (data: NotificationActionsData) => {
        setNotificationData(data)
    }

    // Handle modification data changes
    const handleModificationChange = (data: ModificationActionsData[]) => {
        setModificationData(data)
    }

    // Form validation
    const formValid = (): boolean => {
        // Basic validation rules
        if (formData.name === "") return false

        // Client validation if user has permission
        if (ability.can("*", "Client") && (formData.client.id === "00000000-0000-0000-0000-000000000000" || formData.client.id === "")) {
            return false
        }

        // Validate all conditions have values
        for (const condition of conditions) {
            if (!condition.target || !condition.field || !condition.operator || condition.value === "") {
                return false
            }
        }

        // Validate action data based on action type
        if (formData.policy_actions === ActionsType.Notification) {
            if (!notificationData.emails.length) {
                return false
            }
        } else if (formData.policy_actions === ActionsType.Modification) {
            if (!modificationData.length) {
                return false
            }
        }

        return true
    }

    const handleClientSelector = (id: string | undefined) => {
        if (id) {
            setFormData(prev => ({ ...prev, client: { id } }))
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        // Final preparation of the form data with the correct action data
        const finalFormData = {
            ...formData,
            policy_conditions: conditions,
            policy_actions_data: {
                type: formData.policy_actions,
                data: formData.policy_actions === ActionsType.Notification
                    ? notificationData
                    : modificationData
            }
        }

        try {
            await policyService.create(finalFormData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    width: "80%",
                    alignSelf: "center",
                    margin: "50px auto 50px auto"
                }}
            >
                <Grid item xs container flexDirection="column">
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("policy_add")}
                    </Typography>
                </Grid>

                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />

                <Can key="ViewClients" I="*" an="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px" marginBottom={3}>
                        <Grid item xs={6}>
                            <ClientSelector
                                value={formData.client.id}
                                onSelect={handleClientSelector}
                            />
                        </Grid>
                    </Grid>
                </Can>

                <PolicyBasicInfo
                    formData={formData}
                    onChange={handleFormChange}
                />

                {/* Render action-specific form based on selected action type */}
                <ActionForm
                    actionType={formData.policy_actions}
                    notificationData={notificationData}
                    modificationData={modificationData}
                    onNotificationChange={handleNotificationChange}
                    onModificationChange={handleModificationChange}
                />

                <Typography
                    fontSize="34px"
                    fontFamily="Griff"
                    color="primary"
                    fontWeight="bolder"
                    sx={{ paddingLeft: "8px", marginTop: "20px", marginBottom: "16px" }}
                >
                    {context.t.translate("policy_conditions")}
                </Typography>

                <ConditionsList
                    conditions={conditions}
                    onConditionChange={handleConditionChange}
                    onDeleteCondition={handleDeleteCondition}
                />

                <Grid container gap={2} marginTop={2}>
                    <ActionButton
                        onClick={handleAddCondition}
                        style={{ width: "100%" }}
                        text={context.t.translate("policy_action_add_condition")}
                    />
                </Grid>

                <Typography
                    fontSize="34px"
                    fontFamily="Griff"
                    color="primary"
                    fontWeight="bolder"
                    sx={{ paddingLeft: "8px", marginTop: "20px", marginBottom: "16px" }}
                >
                    {context.t.translate("policy_notification_channels")}
                </Typography>

                <Grid container gap={2} marginTop={2}>
                    <ActionButton
                        type="submit"
                        style={{ width: "100%" }}
                        disabled={!formValid()}
                        text={context.t.translate("policy_action_add")}
                    />
                </Grid>
            </Box>
        </Box>
    )
}

export default PolicyForm
