import { Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { FC, useContext, useEffect, useState } from "react"
import ActionButton from "@components/common/Button/ActionButton"
import { AbilityContext } from "@components/permissions"
import Hierarchy, { CafHierarchyStatus } from "@models/Hierarchy"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { useNavigate } from "react-router-dom"
import useMediaResponsive from "@hooks/useMobile"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "../hierarchyUtils"

interface DetailsTabProps {
    formData: Hierarchy
    handleInputChange: (e: any) => void
    onSave: () => void
    formValid: () => boolean
    setFormData: React.Dispatch<React.SetStateAction<Hierarchy>>
}

const DetailsTab: FC<DetailsTabProps> = ({ formData, handleInputChange, onSave, formValid, setFormData }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array(MAX_DEEP_LEVEL - 1).fill(null))
    const ability = useContext(AbilityContext)
    const navigate = useNavigate()
    const isMobile = useMediaResponsive("(max-width: 780px)")

    /** Aplica filtros personalizados a los HierarchySelector */
    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        const filters: FilterOption[] = []

        if (index === 0) {
            if (ability.can("*", "Client") && formData.client.id !== "00000000-0000-0000-0000-000000000000") {
                filters.push({ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id })
            }
        } else {
            const parentHierarchy = selectedHierarchies[index - 1]
            if (!parentHierarchy) return []

            filters.push({ field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}` })
        }

        // 🔹 Agregar filtro para excluir el propio ID de la jerarquía actual
        if (formData.id) {
            filters.push({ field: "id", operation: FilterOperation.UUIDNotEqual, value: formData.id })
        }

        return filters
    }

    /** Maneja la selección de una jerarquía */
    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy

        // Limpiar los niveles inferiores
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL - 1)
        setSelectedHierarchies(newSelectedHierarchies)

        // Construir el nuevo path
        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(PATH_SEPARATOR)

        setFormData(prev => ({ ...prev, path: newPath }))
    }

    /** Maneja el checkbox de "es proyecto" */
    const handleCheckboxChange = (e: any) => {
        setFormData({ ...formData, is_proyect: e.target.checked, status: e.target.checked ? formData.status : null })
    }

    /** Sincroniza `selectedHierarchies` con `formData.path` al cargar */
    useEffect(() => {
        if (formData.path) {
            const hierarchyIds = formData.path.split(PATH_SEPARATOR)

            // Filtrar el ID propio para evitar redundancia
            const filteredHierarchyIds = hierarchyIds.filter(id => id !== formData.id)

            // Convertir los IDs en objetos `Hierarchy`
            const newSelectedHierarchies = filteredHierarchyIds.map(id => ({ id } as Hierarchy))

            setSelectedHierarchies([
                ...newSelectedHierarchies,
                ...Array(MAX_DEEP_LEVEL - newSelectedHierarchies.length).fill(null)
            ])
        }
    }, [formData.path, formData.id])

    return (
        <Grid container flexDirection="column" rowGap="35px">
            <Grid container flexDirection="row" alignItems="center">
                <Grid item container flexDirection="column" xs={12}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("product_name")}
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("product_description")}
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>

                <Grid item container flexDirection="row" gap={2} alignItems="center" xs={12} md={6}>
                    <Typography color="primary" fontSize="18px" fontWeight="bolder">
                        {context.t.translate("is_project")}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox checked={formData.is_proyect} onChange={handleCheckboxChange} />}
                        label=""
                    />
                </Grid>

                <Grid container spacing={2} direction="column">
                    {/* Selector de Estado, solo si el checkbox está marcado */}
                    {formData.is_proyect && (
                        <Grid item xs={12} md={6}>
                            <Select
                                sx= {{ width: isMobile ? "100%" : "50%" }}
                                variant="filled"
                                value={formData.status || ""}
                                onChange={handleInputChange}
                                name="status"
                                displayEmpty
                            >
                                <MenuItem value="" disabled>{context.t.translate("status")}</MenuItem>
                                {Object.keys(CafHierarchyStatus).map(statusKey => (
                                    <MenuItem key={statusKey} value={CafHierarchyStatus[statusKey as keyof typeof CafHierarchyStatus]}>
                                        {statusKey}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}

                    {/* Hierarchy Selectors */}
                    <Grid item container flexDirection="column">
                        {Array.from({ length: MAX_DEEP_LEVEL - 1 }).map((_, index) => (
                            <Grid key={index} xs={12} md={6}>
                                {index === 0 || selectedHierarchies[index - 1]
                                    ? (
                                        <HierarchySelector
                                            value={selectedHierarchies[index]?.id || ""}
                                            customFilters={getCustomFilters(index)}
                                            onSelect={(id, hierarchy) => handleSelectorChange(index, id, hierarchy)}
                                            index={index}
                                        />
                                    )
                                    : null}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <ActionButton dataTestId="edit-hierarchy-button" text={context.t.translate("save")} onClick={onSave} disabled={formValid()} />
            </Grid>
        </Grid>
    )
}

export default DetailsTab
