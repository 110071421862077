import React, { useContext, useEffect, useRef, useState } from "react"
import GenericModal from "@components/common/modals/GenericModal"
import { Box, Button, Typography, useTheme } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"
import { I18nContext } from "I18nProvider"
import ServicesContext from "@context/ServicesContext"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { FilterOperation, QueryParameters } from "@utils/queryParams"

type ModalMergeDiffProps = {
    open: boolean
    onClose: () => void
    type: string
    products_id: string[] // Cambiado a un array de strings
}

const ModalMergeDiff: React.FC<ModalMergeDiffProps> = ({ open, type, onClose, products_id }) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [selectedProductNames, setSelectedProductNames] = useState<string[]>([]) // Para guardar los nombres de los productos
    const theme = useTheme()
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const context = useContext(I18nContext)
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const productService = useContext(ServicesContext).productService
    const cycloneDXService = useContext(ServicesContext).cycloneDXService

    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const fetchProductNames = async () => {
        try {
            const queryParameters: QueryParameters = {
                filters: [
                    {
                        field: "id",
                        operation: FilterOperation.UUIDArrayContains,
                        value: products_id.join(",")
                    }
                ]
            }

            const response = await productService.getAll(queryParameters)
            setSelectedProductNames(response.list.map((product: { name: string }) => product.name))
        } catch (error: any) {
            console.error("Error fetching product names:", error)
            setSnackbarMessage(error.message || context.t.translate("an_error"))
            setSnackbarOpen(true)
        }
    }

    useEffect(() => {
        if (products_id.length > 0) {
            fetchProductNames()
        }
    }, [products_id])

    const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files) {
            const newFiles = Array.from(files).slice(0, 2)
            setUploadedFiles(newFiles)
        }
    }

    const handleSelectFilesClick = () => {
        setUploadedFiles([])
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
    }

    const handleActionClick = async () => {
        try {
            let response

            if (uploadedFiles.length > 0) {
                console.log(uploadedFiles)

                response = await cycloneDXService.mergeProductsSboms(type, uploadedFiles)
            } else {
                response = await cycloneDXService.mergeProducts(type, products_id)
            }

            setSnackbarMessage(context.t.translate("saved_successfully"))
            setSnackbarOpen(true)
            onClose()
        } catch (error: any) {
            console.error("Error en la solicitud:", error)
            setSnackbarMessage(error.message || context.t.translate("an_error"))
            setSnackbarOpen(true)
        }
    }

    const isProductSelectionInvalid = products_id.length === 1
    const isFileUploadVisible = products_id.length === 0
    const isProductSelectionValid = products_id.length > 0

    return (
        <GenericModal
            onClose={onClose}
            open={open}
            title={type === "diff" ? context.t.translate("diff") : context.t.translate("merge")}
            actions={
                <ActionButton
                    onClick={handleActionClick}
                    text={type === "diff" ? context.t.translate("diff") : context.t.translate("merge")}
                    disabled={isProductSelectionInvalid || (isFileUploadVisible && uploadedFiles.length < 2)}
                />
            }
        >
            <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={() => setSnackbarOpen(false)} />

            <Box
                sx={{
                    width: "fit-content",
                    margin: "0 auto",
                    mt: 2,
                    p: 4,
                    boxShadow: theme.shadows[1],
                    borderRadius: 2,
                    backgroundColor: theme.palette.background.paper,
                    marginBottom: 2
                }}
            >
                {/* Mensaje de error si solo hay un producto seleccionado */}
                {isProductSelectionInvalid && (
                    <Typography color="error" align="center" sx={{ mb: 2 }}>
                        {context.t.translate("select_two_or_none")}
                    </Typography>
                )}

                {/* Mostrar nombres de los productos seleccionados si hay 2 */}
                {isProductSelectionValid && selectedProductNames.length > 0 && (
                    <Box sx={{ textAlign: "center", mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.primary, mb: 2 }}>
                            {context.t.translate("selected_products")}
                        </Typography>

                        <Box sx={{ maxWidth: 400, mx: "auto" }}>
                            {selectedProductNames.map((name, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        fontSize: 14,
                                        color: theme.palette.text.secondary,
                                        padding: "8px 16px",
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        textAlign: "left"
                                    }}
                                >
                                    {name}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}

                {/* Mostrar mensaje de que se van a mergear o hacer diff entre los productos seleccionados */}
                {isProductSelectionValid && selectedProductNames.length === 0 && (
                    <Box sx={{ textAlign: "center", mb: 3 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                            {type === "diff"
                                ? context.t.translate("will_compare_products")
                                : context.t.translate("will_merge_products")}
                        </Typography>
                    </Box>
                )}

                {/* Mostrar el input de archivos solo si no hay productos seleccionados */}
                {isFileUploadVisible && (
                    <>
                        <Typography variant="h5" align="center" sx={{ mb: 3 }}>
                            Select Files (Max 2)
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                            <Button
                                variant="contained"
                                sx={{
                                    color: theme.palette.primary.contrastText,
                                    textTransform: "none"
                                }}
                                component="label"
                                onClick={handleSelectFilesClick}
                            >
                                Select Files (Max 2)
                                <input type="file" accept=".json" multiple hidden ref={fileInputRef} onChange={handleFileSelection} />
                            </Button>

                            {uploadedFiles.length === 0 && (
                                <Typography align="center" color="text.secondary" sx={{ mt: 4 }}>
                                    No files selected yet.
                                </Typography>
                            )}

                            <Box sx={{ width: "100%", textAlign: "left" }}>
                                {uploadedFiles.length > 0 &&
                                    uploadedFiles.map((file, index) => (
                                        <Typography key={index} sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                                            {file.name}
                                        </Typography>
                                    ))}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </GenericModal>
    )
}

export default ModalMergeDiff
