import { Button, Checkbox, Chip, CircularProgress, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { GenericTable } from "@components/common/tables"
import { TagDetailProductTableDefinition } from "@components/product/ProductTableDefinition"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { TagCategoryStateColors } from "@components/common/colors/TagCategoryStateColors"
import { useTrack } from "@components/track/TrackContext"
import { ServicesContext } from "@context/index"
import Product from "@models/Product"
import Tag, { TagCategoryType } from "@models/Tag"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { TagCategoryTypeKeysValueOps } from "./TagsForm"
export const initValue: Tag = {
    id: "",
    name: "",
    description: "",
    color: "",
    favorite: false,
    client: { id: "00000000-0000-0000-0000-000000000000" },
    category: TagCategoryType.Client,
    products: []
}

const TagsDetail = () => {
    const theme = useTheme()
    const { id } = useParams()
    const { tagService, productService } = useContext(ServicesContext)
    const [data, setData] = useState<Tag>(initValue)
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const context = useContext(I18nContext)
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const [cols, setCols] = useState<GridColDef[]>([])
    const [filterParams, setFilterParams] = useState<QueryParameters>({ filters: [] })
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "TagsDetail" })
    }, [])

    const colors = [
        { label: "Yellow", value: "#F39C12" },
        { label: "Red", value: "#C0392B" },
        { label: "Green", value: "#27AE60" },
        { label: "Blue", value: "#2980B9" }
    ]

    // Añadir 'context.language' a las dependencias

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const result = await tagService.getAll()
                    const tag = result.list.find(item => item.id === id) // Filtrar por el id
                    setData(tag || initValue)
                    let tagList: Tag[]

                    if (result !== undefined) {
                        tagList = result?.list
                    }

                    const newCol = convertModelDefToGridColDef(TagDetailProductTableDefinition, ability)
                    newCol.push({
                        field: "Tags",
                        type: "tags",
                        headerName: "Tags",
                        flex: 0.1,
                        minWidth: 280,
                        renderCell: (params) => {
                            if (ability.can("read", "Product")) {
                                const tagList2 = tagList && tagList.filter(tag => tag.products && tag.products.some(item => item === params.id))
                                return (
                                    <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {tagList2 && tagList2.length >= 1
                                            ? (
                                                <>
                                                    {tagList2.slice(0, 2).map((chip, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={chip.name}
                                                            variant="outlined"
                                                            style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color, marginRight: "5px" }}
                                                        />
                                                    ))}
                                                </>
                                            )
                                            : (
                                                tagList2 && tagList2.map((chip, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={chip.name}
                                                        variant="outlined"
                                                        style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color }}
                                                    />
                                                ))
                                            )}
                                    </Grid>
                                )
                            }
                            return (
                                <div>
                                    {params.value}
                                </div>

                            )
                        }

                    })
                    setCols(newCol)
                    // Obtener los productos por ID
                    if (tag.products && tag.products.length > 0) {
                        setFilterParams({
                            filters: [{ field: "id", operation: FilterOperation.UUIDArrayContains, value: tag.products.join(",") }]
                        })
                    }
                }
            } catch (e: any) {
                setError(e.message)
                setSnackbarOpen(true)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [id, context.language, ability])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleSave = async () => {
        try {
            await tagService.update(data.id, data)
            setSnackbarOpen(true)
            setError(null)
        } catch (e: any) {
            setError(e.message)
            setSnackbarOpen(true)
        }
    }
    if (loading) {
        return <CircularProgress />
    }

    return (
        <Grid container flexDirection="column" spacing={2} padding="20px">
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", paddingLeft: "20px", paddingBottom: "0px", display: "flex" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error || "Changes saved successfully."}
                    severity={error ? "error" : "success"}
                />
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("tags_details")}
                </Typography>
            </Grid>

            <Grid item xs container flexDirection="column" spacing={2}>
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <StyledBox>
                            <Grid container spacing={3} padding={3} flexDirection="column">
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">
                                            {context.t.translate("name")}
                                        </Typography>
                                        <TextField fullWidth variant="standard" name="name" value={data.name} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">
                                            {context.t.translate("description")}
                                        </Typography>
                                        <TextField fullWidth variant="standard" name="description" value={data.description} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">{context.t.translate("color")}</Typography>
                                        <Select name="color" value={data.color} onChange={handleSelectChange} variant="standard" fullWidth
                                            sx={{
                                                borderColor: theme.palette.primary.main,
                                                "& .MuiSelect-icon": { color: theme.palette.primary.main },
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": { borderColor: theme.palette.primary.main },
                                                    "&:hover fieldset": { borderColor: theme.palette.primary.main },
                                                    "&.Mui-focused fieldset": { borderColor: theme.palette.primary.main }
                                                }
                                            }}>
                                            {colors.map((color, index) => (
                                                <MenuItem key={index} value={color.value}>
                                                    <Typography sx={{ color: color.value }}>{color.label}</Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">{context.t.translate("favorite")}</Typography>
                                        <Checkbox checked={data.favorite} onChange={(e) => setData({ ...data, favorite: e.target.checked })} ></Checkbox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">
                                            {context.t.translate("category")}
                                        </Typography>
                                        <Select
                                            name="category"
                                            value={data.category}
                                            onChange={handleSelectChange}
                                            variant="filled"
                                            fullWidth
                                        >
                                            {TagCategoryTypeKeysValueOps.map((opt, idx) => (
                                                <MenuItem key={idx} value={opt.value}>
                                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: TagCategoryStateColors[opt.value] }}>
                                                        {opt.label}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    {ability.can("*", "*") && <Grid item xs={12} md={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">
                                            {context.t.translate("client")}
                                        </Typography>
                                        <TextField fullWidth variant="standard" name="name" value={data.client.name} disabled />
                                    </Grid>}
                                </Grid>

                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleSave} data-testid="save-tag-button">
                                        {context.t.translate("save")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </StyledBox>
                        <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                            {context.t.translate("tags_products")}
                        </Typography>
                        {/* TODO: asignar los products en lugar del getAll */}
                        <Grid item container flexDirection="column" rowGap="35px">
                            {data.products && data.products.length > 0
                                ? (
                                    <GenericTable<Product>
                                        entity="Product"
                                        columns={cols}
                                        dataProvider={(filter) => productService.getAllExt({ ...filter, ...filterParams })}
                                        onEdit={(elem: Product) => navigate("../../manage/product/" + elem.id)}
                                        onDelete={(elem: Product) => productService.delete(elem.id)}
                                        withTags={true}
                                    />
                                )
                                : (
                                    <Typography variant="h6" color="textSecondary" sx={{ textAlign: "center", padding: "20px" }}>
                                        {context.t.translate("tag_no_products")}
                                    </Typography>
                                )}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TagsDetail
