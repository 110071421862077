import React, { useContext, useEffect, useState } from "react"
import { Box, Button, ClickAwayListener, Divider, Grid, MenuItem, Modal, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material"
import { useAuth } from "react-oidc-context"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import { useNavigate } from "react-router-dom"
import { I18nContext } from "I18nProvider"
import { VulnerabilitiesStats } from "@components/vulnerability/index"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { GenericTable } from "@components/common/tables"
import { useTrack } from "@components/track/TrackContext"
import ProductSunburst from "@components/product/ProductSunburst"
import StatsCard from "@components/common/stats/StatsCard"
import { StyledBox } from "@components/common/Box/BoxContainer"
import ServicesContext from "@context/ServicesContext"
import { KeycloakSessions, SessionDefinition } from "@models/Keycloak"
import Event from "@models/Event"
import HierarchyStats from "@components/charts/HierarchyStats"
import Stats from "./Stats"

// declare module "jwt-decode" {
//     export interface JwtPayload {
//         resource_access: Role;
//     }
// }

type Role = {
    "realm-management": Realms
}

interface Realms {
    roles: Array<string>
}

const Products: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const auth = useAuth()
    const [loginevents, setLoginEvents] = useState<Event[]>([])
    const [userDif, setUserDif] = useState<number>(0)
    const [visible, setVisible] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    const keycloakService = useContext(ServicesContext).keycloakService

    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const [timeFilter, setTimeFilter] = useState("all")

    const handleTimeChange = (event: SelectChangeEvent) => {
        setTimeFilter(event.target.value)
    }

    useEffect(() => {
        setCols(convertModelDefToGridColDef(SessionDefinition, ability))
    }, [])

    const handleClickAway = () => {
        setOpen(false)
    }
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        color: theme.palette.primary.contrastText,
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        boxShadow: 24,
        p: 4,
        height: "85%"
    }

    const styleBox = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        color: theme.palette.primary.contrastText,
        p: 4,
        height: "90%"
    }
    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 0)",
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }
    const handleClose = () => setOpen(false)
    const MyModal = React.forwardRef(() => {
        const theme = useTheme()
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={styleBox}>
                    <Grid container item flexDirection="row" spacing="20px" padding="25px">
                        <Grid item xs={12} mx="0px" mb="25px">
                            <Typography variant="h4" color="primary" fontWeight="bolder">{context.t.translate("user_sessions")}</Typography>
                        </Grid>
                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        <Grid item xs container flexDirection="row">
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                {context.t.translate("login_in_last")} {loginevents.length}
                            </Typography>
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                {context.t.translate("users_in_last")} {userDif}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item className="scroll-container" sx={styleScroll} flexDirection="row" >
                        {visible && loginevents !== undefined && (
                            <><Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
                                <Grid item container flexDirection="column" rowGap="35px">
                                    <GenericTable<KeycloakSessions> entity="KeycloakSession" columns={cols}
                                        dataProvider={(filter) => keycloakService.getAll(filter)}
                                        onEdit={undefined}
                                        onDelete={undefined} />
                                </Grid>
                            </Grid>
                            </>
                        )}
                    </Grid>
                    <Button size="large" fullWidth variant="contained" style={{ marginTop: "20px", color: theme.palette.secondary.contrastText }} onClick={() => handleClose()}>{context.t.translate("close")}</Button>
                </Box>
            </ClickAwayListener>
        )
    })
    MyModal.displayName = "MyModal"

    // React.useEffect(() => {
    //     (async () => {
    //         try {
    //             const roles = jwtDecode<JwtPayload>(auth.user?.access_token!)
    //             if (roles.resource_access!["realm-management"].roles.includes("view-events")) {
    //                 const token = auth.user?.access_token
    //                 const response = await fetch(process.env.REACT_APP_SSO + "admin/realms/myorbik/events?type=LOGIN&first=0&max=500", {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`
    //                     }
    //                 })
    //                 setLoginEvents(await response.json())
    //                 const userDif = loginevents
    //                     .map(dataItem => dataItem.userId) // get all media types
    //                     .filter((mediaType, index, array) => array.indexOf(mediaType) === index) // filter out duplicates
    //                 const counts = userDif
    //                     .map(mediaType => ({
    //                         type: mediaType,
    //                         count: loginevents.filter(item => item.userId === mediaType).length
    //                     }))
    //                 setUserDif(counts.length)
    //                 setVisible(true)
    //             }
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     })()
    // }, [auth])

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "Home" })
    }, [])

    return (
        <Grid container gap={2}>

            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" data-testid="header">
                    {context.t.translate("dashboard")}
                </Typography><Select value={timeFilter} onChange={handleTimeChange} sx={{
                    minWidth: "150px",
                    backgroundColor: theme.palette.background.paper, // Fondo
                    color: theme.palette.text.primary, // Color de texto
                    borderRadius: "4px", // Bordes redondeados
                    "& .MuiSelect-icon": {
                        color: theme.palette.text.primary // Color de la flecha
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main // Color del borde
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.dark // Color del borde al pasar el ratón
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main // Color del borde cuando está enfocado
                    }
                }}>
                    <MenuItem value="7d">{context.t.translate("last_week")}</MenuItem>
                    <MenuItem value="30d">{context.t.translate("last_month")}</MenuItem>
                    <MenuItem value="1y">{context.t.translate("last_year")}</MenuItem>
                    <MenuItem value="all">{context.t.translate("all")}</MenuItem>
                </Select>

            </Grid>
            <Grid xs={12}>
                <StyledBox><Stats /></StyledBox>
            </Grid>

            <StyledBox>

                <Grid item container flexDirection="row" spacing="0px" sx={{ flex: 2 }} xs={12}>
                    <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingLeft: "15px", paddingTop: "-20px" }} xs={12}>
                        <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder" sx={{
                            paddingTop: "-20px",
                            cursor: "pointer",
                            "&:hover": {
                                color: "secondary.main" // Change this to the desired hover color
                            }
                        }} onClick={() => navigate("/vulnerability")}>{context.t.translate("vulnerabilities")}</Typography>
                    </Grid>
                    <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingTop: "-20px" }} xs={12}>
                        <StatsCard title={context.t.translate("vulnerabilities")} entity="CAFVulnerability" timeRange={timeFilter} />
                    </Grid>
                </Grid>
                <Grid item container flexDirection="row" spacing="0px" sx={{ flex: 2 }} xs={12}>
                    <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingTop: "-20px" }} xs={12}>
                        <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder" sx={{
                            paddingLeft: "15px",
                            paddingTop: "-20px",
                            cursor: "pointer",
                            "&:hover": {
                                color: "secondary.main" // Change this to the desired hover color
                            }
                        }} onClick={() => navigate("/assessment")}>{context.t.translate("assessments")}</Typography>
                    </Grid>
                    <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingTop: "-20px" }} xs={12}>
                        <StatsCard title={context.t.translate("assessments")} entity="Assessment" timeRange={timeFilter} />
                    </Grid>
                </Grid>
                <Grid item container flexDirection="row" spacing="0px" sx={{ flex: 2 }} xs={12}>
                    <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingTop: "-20px" }} xs={12}>
                        <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder" sx={{
                            paddingLeft: "15px",
                            paddingTop: "-20px",
                            cursor: "pointer",
                            "&:hover": {
                                color: "secondary.main" // Change this to the desired hover color
                            }
                        }} onClick={() => navigate("/manage/product")}>{context.t.translate("products")}</Typography>
                    </Grid>
                    <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingTop: "-20px" }} xs={12}>
                        <StatsCard title={context.t.translate("products")} entity="Product" timeRange={timeFilter} />
                    </Grid>
                </Grid>
            </StyledBox>
            <StyledBox sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ProductSunburst />
            </StyledBox>
            <VulnerabilitiesStats timeRange={timeFilter} />
            <HierarchyStats />
            <Modal sx={style} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <MyModal />
            </Modal>
        </Grid>
    )
}

export { Products }
export default Products
