import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

// export const introduccion = (testType: string) => [
//     { id: "Alcance: ", description: "Se describe el alcance inicial desde el cual se parte a la hora de realizar este ejercicio." },
//     { id: "Resumen ejecutivo: ", description: `Se muestra un resumen generalde los resultados obtenidos durante el test ${testType}.` },
//     { id: "Descubrimiento e identificación del sistema: ", description: "Se describen los protocolos objetivos del test." },
//     { id: "Identificación de anomalías: ", description: `Se analizan los resultados del test de ${testType} obtenidos y se identifican las anomalías ocurridas durante su ejecución.` }
// ]

type TestType = "Fuzzing" | "Network Stress";

interface SectionProps {
    assessment: Assessment;
    testType: TestType;
    customIntro?: string
    template?: Template
    lang?: string
}
const i18n = {
    es: {
        indexTitle: "1. Introducción",
        scopeintroduction1: "En este documento se describen los resultados del test que se ha realizado sobre la plataforma",
        scopeintroduction2: "El test de",
        scopeintroduction3: "tiene como objetivo identificar posibles anomalías en su funcionamiento y su capacidad para sobrellevar dichas pruebas.",
        scopeintroduction4: "El informe está organizado de esta forma:",
        introduction: (testType: string) => [
            { id: "Alcance: ", description: "Se describe el alcance inicial desde el cual se parte a la hora de realizar este ejercicio." },
            { id: "Resumen ejecutivo: ", description: `Se muestra un resumen generalde los resultados obtenidos durante el test ${testType}.` },
            { id: "Descubrimiento e identificación del sistema: ", description: "Se describen los protocolos objetivos del test." },
            { id: "Identificación de anomalías: ", description: `Se analizan los resultados del test de ${testType} obtenidos y se identifican las anomalías ocurridas durante su ejecución.` }
        ],
        achilles: "Las pruebas se ejecutan con Achilles, una plataforma de pruebas para los fabricantes de infraestructuras críticas que necesitan garantizar que sus productos no son vulnerables a ciberataques. La plataforma de pruebas permite revisar la robustez de las comunicaciones mientras supervisa tanto la red como los parámetros operativos, lo que permite descubrir vulnerabilidades, reproducir fallos, aislarlos, identificarlos y resolverlos antes de introducir los productos en el mercado",
        diagno: "El Diagnóstico de robustez de comunicaciones en",
        diagno2: " tiene como objetivo identificar anomalías en el rendimiento del dispositivo"
    },
    en: {
        indexTitle: "1. Introduction",
        scopeintroduction1: "This document describes the results of the test conducted on the",
        scopeintroduction2: "platform. The",
        scopeintroduction3: "test aims to identify possible anomalies in its operation and its ability to withstand these tests.",
        scopeintroduction4: "The report is organized in this way:",
        introduction: (testType: string) => [
            { id: "Scope: ", description: "The initial scope from which this exercise is conducted is described." },
            { id: "Executive Summary: ", description: `A general summary of the results obtained during the ${testType} test is presented.` },
            { id: "System Discovery and Identification: ", description: "The target protocols of the test are described." },
            { id: "Anomaly Identification: ", description: `The results obtained from the ${testType} test are analyzed, and anomalies that occurred during its execution are identified.` }
        ],
        achilles: "The tests are conducted using Achilles, a testing platform for manufacturers of critical infrastructure who need to ensure that their products are not vulnerable to cyberattacks. The testing platform allows for the assessment of communication robustness while monitoring both the network and operational parameters, enabling the discovery of vulnerabilities, reproduction of failures, isolation, identification, and resolution before introducing the products to the market.",
        diagno: "The Communication Robustness Diagnosis in",
        diagno2: "aims to identify anomalies in the device's performance."
    }
} as const
const Intro: React.FC<SectionProps> = ({ assessment, testType, customIntro, template, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style={{ paddingBottom: 55, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template} externalData={undefined}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <View>
                        <Text style={styles.titulo}>{i18n[locale].indexTitle}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{i18n[locale].scopeintroduction1} {assessment.product.name}. {i18n[locale].scopeintroduction2}  {testType} {i18n[locale].scopeintroduction3}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{i18n[locale].scopeintroduction4}</Text>
                        {i18n[locale].introduction(testType).map((point, index) => (
                            <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                                <Text style={styles.puntuak}>{point.description}</Text>
                            </Text>
                        ))}
                        <Text style={{ ...styles.textua, marginRight: 50, marginTop: 5, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{i18n[locale].achilles}.</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, marginTop: 5, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }} >{customIntro}</Text>
                    </View>
                </View>
                <View style={styles.column}>
                    <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="/assets/img/report/computer.jpg" />
                    <View style={{ ...styles.rightImage, marginTop: 265, height: 270, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#fffa37", flexDirection: "row" }}>
                        <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 30, textAlign: "center", fontFamily: "Bold", fontSize: 18 }}>{i18n[locale].diagno} {assessment.product.name} {i18n[locale].diagno2}</Text>
                    </View>
                </View>
            </View>
            <Footer externalData={null}></Footer>
        </Page>
    )
}

export { Intro }
