
import SAST from "@models/SAST"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class SASTService extends Service<SAST> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/sast", MediaTypes.JSON)
    }
}

export { SASTService }
export default SASTService
