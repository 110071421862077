export const colorScheme = {
    light: {
        primary: "#FAE733",
        surfaceTint: "#695F00",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FEEB37",
        onPrimaryContainer: "#544C00",
        secondary: "#47005E",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#761C94",
        onSecondaryContainer: "#FBDBFF",
        tertiary: "#3700A0",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#5A2FD9",
        onTertiaryContainer: "#FDF8FF",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFF9E7",
        onBackground: "#1D1C11",
        surface: "#FDF8F7",
        onSurface: "#1C1B1B",
        surfaceVariant: "#E7E2D7",
        onSurfaceVariant: "#49473F",
        outline: "#7A776E",
        outlineVariant: "#CBC6BB",
        shadow: "#000000",
        scrim: "#000000",
        inverseSurface: "#31302F",
        inverseOnSurface: "#F4F0EE",
        inversePrimary: "#DBC900",
        primaryFixed: "#F8E531",
        onPrimaryFixed: "#1F1C00",
        primaryFixedDim: "#DBC900",
        onPrimaryFixedVariant: "#4F4800",
        secondaryFixed: "#FAD7FF",
        onSecondaryFixed: "#330044",
        secondaryFixedDim: "#EFB0FF",
        onSecondaryFixedVariant: "#721890",
        tertiaryFixed: "#E7DEFF",
        onTertiaryFixed: "#1E0061",
        tertiaryFixedDim: "#CBBEFF",
        onTertiaryFixedVariant: "#4B13CA",
        surfaceDim: "#DDD9D8",
        surfaceBright: "#FDF8F7",
        surfaceContainerLowest: "#FFFFFF",
        surfaceContainerLow: "#F7F3F1",
        surfaceContainer: "#F1EDEB",
        surfaceContainerHigh: "#EBE7E6",
        surfaceContainerHighest: "#E6E2E0"
    },
    dark: {
        primary: "#FAE733",
        surfaceTint: "#DBC900",
        onPrimary: "#E8E7ED",
        primaryContainer: "#E9D71F",
        onPrimaryContainer: "#474000",
        secondary: "#EFB0FF",
        onSecondary: "#54006E",
        secondaryContainer: "#580073",
        onSecondaryContainer: "#EDA9FF",
        tertiary: "#CBBEFF",
        onTertiary: "#340098",
        tertiaryContainer: "#4200BD",
        onTertiaryContainer: "#D7CBFF",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFDAD6",
        background: "#151409",
        onBackground: "#E8E2D0",
        surface: "#141313",
        onSurface: "#E6E2E0",
        surfaceVariant: "#49473F",
        onSurfaceVariant: "#CBC6BB",
        outline: "#949087",
        outlineVariant: "#49473F",
        shadow: "#000000",
        scrim: "#000000",
        inverseSurface: "#E6E2E0",
        inverseOnSurface: "#31302F",
        inversePrimary: "#695F00",
        primaryFixed: "#F8E531",
        onPrimaryFixed: "#1F1C00",
        primaryFixedDim: "#DBC900",
        onPrimaryFixedVariant: "#4F4800",
        secondaryFixed: "#FAD7FF",
        onSecondaryFixed: "#330044",
        secondaryFixedDim: "#EFB0FF",
        onSecondaryFixedVariant: "#721890",
        tertiaryFixed: "#E7DEFF",
        onTertiaryFixed: "#1E0061",
        tertiaryFixedDim: "#CBBEFF",
        onTertiaryFixedVariant: "#4B13CA",
        surfaceDim: "#141313",
        surfaceBright: "#3A3938",
        surfaceContainerLowest: "#0F0E0E",
        surfaceContainerLow: "#1C1B1B",
        surfaceContainer: "#201F1F",
        surfaceContainerHigh: "#2B2A29",
        surfaceContainerHighest: "#363434"
    }

}

export const CAFColorScheme = {
    light: {
        primary: "#FAE733",
        surfaceTint: "#695F00",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FEEB37",
        onPrimaryContainer: "#544C00",
        secondary: "#47005E",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#761C94",
        onSecondaryContainer: "#FBDBFF",
        tertiary: "#3700A0",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#5A2FD9",
        onTertiaryContainer: "#FDF8FF",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFF9E7",
        onBackground: "#1D1C11",
        surface: "#FDF8F7",
        onSurface: "#1C1B1B",
        surfaceVariant: "#E7E2D7",
        onSurfaceVariant: "#49473F",
        outline: "#7A776E",
        outlineVariant: "#CBC6BB",
        shadow: "#000000",
        scrim: "#000000",
        inverseSurface: "#31302F",
        inverseOnSurface: "#F4F0EE",
        inversePrimary: "#DBC900",
        primaryFixed: "#F8E531",
        onPrimaryFixed: "#1F1C00",
        primaryFixedDim: "#DBC900",
        onPrimaryFixedVariant: "#4F4800",
        secondaryFixed: "#FAD7FF",
        onSecondaryFixed: "#330044",
        secondaryFixedDim: "#EFB0FF",
        onSecondaryFixedVariant: "#721890",
        tertiaryFixed: "#E7DEFF",
        onTertiaryFixed: "#1E0061",
        tertiaryFixedDim: "#CBBEFF",
        onTertiaryFixedVariant: "#4B13CA",
        surfaceDim: "#DDD9D8",
        surfaceBright: "#FDF8F7",
        surfaceContainerLowest: "#FFFFFF",
        surfaceContainerLow: "#F7F3F1",
        surfaceContainer: "#F1EDEB",
        surfaceContainerHigh: "#EBE7E6",
        surfaceContainerHighest: "#E6E2E0"
    },
    dark: {
        primary: "#271968",
        onPrimary: "#FFFFFF",
        secondary: "#ffffff",
        onSecondary: "#000000",
        tertiary: "#9c95ba",
        onTertiary: "#340098",
        error: "#fb3b33",
        onError: "#8a201c",
        errorContainer: "#fec2c0",
        onErrorContainer: "#b22a24",
        background: "#FFFFFF",
        onBackground: "#E8E2D0",
        surface: "#F7F6F9",
        onSurfaceVariant: "#666666",
        primaryFixedDim: "#9c95ba",
        onPrimaryFixedVariant: "#1c124a",
        secondaryFixedDim: "#979797",
        onSecondaryFixedVariant: "#666666",
        tertiaryFixed: "#E7DEFF",
        onTertiaryFixed: "#1E0061",
        tertiaryFixedDim: "#CBBEFF",
        onTertiaryFixedVariant: "#4B13CA"
    }

}
