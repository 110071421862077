import React, { useContext, useEffect, useState } from "react"
import { Divider, Grid, Typography, useTheme } from "@mui/material"
import { useParams } from "react-router-dom"
import { ColumnChart } from "@components/vulnerability/index"
import ServicesContext from "@context/ServicesContext"
import { StatsVulnerabilities } from "@models/index"
import VulnerabilityEvolutionChart from "@views/product/VersionChart/VulnerabilityChart"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ColumnChartSkeleton from "./skeleton/ColumnChartSekeleton"
import VulnerabilityEvolutionChartSkeleton from "./skeleton/VulnerabilityChartSkeleton"

type VulnerabilitiesStatsProps = {
    timeRange: string;
};

const VulnerabilitiesStats: React.FC<VulnerabilitiesStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [error, setError] = useState<unknown>(null)
    const statsService = useContext(ServicesContext).statsService
    const [stats, setStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const { id } = useParams<{ id: string }>()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await statsService.getStatsVulnerabilities()
                setStats(stats)
            } catch (e: unknown) {
                setError(e)
                setSnackbarOpen(true)
            }
        }

        const fetchDataID = async (id: string) => {
            try {
                const stats = await statsService.getStatsVulnerabilitiesFilter(id)
                setStats(stats)
            } catch (e: unknown) {
                setError(e)
                setSnackbarOpen(true)
            }
        }

        if (id !== undefined) {
            fetchDataID(id)
        } else {
            fetchData()
        }
    }, [id])

    const getErrorMessage = (error: unknown) => {
        if (error instanceof Error) {
            return error.message
        }
        return "An unknown error occurred"
    }

    return (
        <Grid item container>
            <StyledBox>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={getErrorMessage(error)}
                />
                {error
                    ? (
                        <Typography color="error">{getErrorMessage(error)}</Typography>
                    )
                    : stats === undefined
                        ? (
                            <Grid
                                item
                                container
                                flexDirection="column"
                                alignItems="center"
                                spacing={4}
                            >
                                {/* Column Chart Skeleton at the top */}
                                <Grid item xs={12} sx={{ width: "100%" }}>
                                    <ColumnChartSkeleton />
                                </Grid>

                                {/* Divider in the middle */}
                                <Grid item xs={12} sx={{ width: "100%" }}>
                                    <Divider
                                        sx={{
                                            height: "1px",
                                            width: "100%",
                                            background: theme.palette.primary.main
                                        }}
                                    />
                                </Grid>

                                {/* Vulnerability Evolution Chart Skeleton at the bottom */}
                                <Grid item xs={12} sx={{ width: "100%" }}>
                                    <VulnerabilityEvolutionChartSkeleton />
                                </Grid>
                            </Grid>
                        )
                        : (
                            <Grid container flexDirection="column" spacing="20px">
                                <Grid paddingTop="15px !important" item>
                                    <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        {context.t.translate("vulnerabilities")}
                                    </Typography>
                                </Grid>

                                {(stats.MitigatedVulnerability !== 0 || stats.UnmitigatedVulnerability !== 0)
                                    ? (
                                        <Grid item container justifyContent="center" flexDirection="column" paddingTop="15px !important" spacing="10px" sx={{ width: "100%", maxWidth: "2000px", margin: "0 auto" }}>
                                            <Grid item>
                                                <Typography fontSize="30px" fontWeight="bolder" fontFamily="Griff" color={theme.palette.primary.main}>
                                                    {context.t.translate("mitigated_vs_unmitigated")}
                                                </Typography>
                                            </Grid>
                                            <Grid item alignItems="center" sx={{ height: "100%", width: "100%" }}>
                                                <ColumnChart values={{
                                                    critical: { mitigated: stats.MitigatedVulnerabilities.critical, unmitigated: stats.UnmitigatedVulnerabilities.critical },
                                                    high: { mitigated: stats.MitigatedVulnerabilities.high, unmitigated: stats.UnmitigatedVulnerabilities.high },
                                                    medium: { mitigated: stats.MitigatedVulnerabilities.medium, unmitigated: stats.UnmitigatedVulnerabilities.medium },
                                                    low: { mitigated: stats.MitigatedVulnerabilities.low, unmitigated: stats.UnmitigatedVulnerabilities.low },
                                                    none: { mitigated: stats.MitigatedVulnerabilities.none, unmitigated: stats.UnmitigatedVulnerabilities.none }
                                                }} />
                                            </Grid>
                                        </Grid>
                                    )
                                    : (
                                        <Grid item>
                                            <Typography variant="h6" color="textSecondary">
                                                {context.t.translate("no_vulns")}
                                            </Typography>
                                        </Grid>
                                    )}

                                <Grid item sx={{ marginTop: "80px", marginBottom: "40px" }}>
                                    <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                                </Grid>
                                <Grid item container flexDirection="column" paddingTop="15px !important" spacing={2}>
                                    <Grid item>
                                        <Typography fontSize="30px" fontWeight="bolder" fontFamily="Griff" color={theme.palette.primary.main}>
                                            {context.t.translate("evolution_of_vulnerabilities")}
                                        </Typography>
                                    </Grid>
                                    <Grid container item alignItems="center" alignContent="center">
                                        <Grid item sx={{ flex: "1 1 auto", marginLeft: "15px" }}>
                                            <VulnerabilityEvolutionChart timeRange={timeRange} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
            </StyledBox>
        </Grid>
    )
}

export { VulnerabilitiesStats }
export default VulnerabilitiesStats
