import React, { useContext, useEffect, useState } from "react"
import GenericModal from "@components/common/modals/GenericModal"
import { Box, Grid, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { Reminder, ReminderDay, ReminderFrequency } from "@models/Reminder"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { AssessmentType } from "@models/Assessment"
import { AssessmentTypeKeysValueOps } from "@components/assessment"
import { AbilityContext } from "@components/permissions"
import ActionButton from "@components/common/Button/ActionButton"
import ServicesContext from "@context/ServicesContext"
import { ClientSelector } from "@components/client"

type AddReminderModalProps = {
  open: boolean;
  onClose: () => void;
  reminder?: Reminder | null;
};
export function formatLabel (label: string) {
    const acronymLabels = ["CRT", "SCA"]
    if (acronymLabels.includes(label)) {
        return label
    }
    return label.replace(/([a-z])([A-Z])/g, "$1 $2")
}
const initValue: Reminder = {
    id: "",
    reminder_frequency: ReminderFrequency.Daily,
    frequency_date: new Date().toISOString(),
    type: AssessmentType.SCA as AssessmentType,
    client: { id: "00000000-0000-0000-0000-000000000000" }
}

const AddReminderModal: React.FC<AddReminderModalProps> = ({ open, onClose, reminder }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const [formData, setFormData] = useState<Reminder>(reminder ?? initValue)
    const [frequency, setFrequency] = useState<ReminderFrequency | null>(reminder?.reminder_frequency ?? ReminderFrequency.Daily)
    const [day, setDay] = useState<ReminderDay | null>(reminder?.reminder_day ?? null)
    const reminderService = useContext(ServicesContext).reminderService
    const theme = useTheme()
    const handleFrequencyChange = (_event: React.MouseEvent<HTMLElement>, newFrequency: ReminderFrequency | null) => {
        if (newFrequency !== null) {
            setFrequency(newFrequency)
            setFormData(prevState => ({
                ...prevState,
                reminder_frequency: newFrequency // Actualiza el campo reminder_frequency en formData
            }))

            if (newFrequency !== ReminderFrequency.Weekly) {
                setDay(null) // Reset day selection if not weekly
                setFormData(prevState => ({
                    ...prevState,
                    reminder_day: undefined
                }))
            }
        }
    }
    useEffect(() => {
        if (reminder) {
            setFormData(reminder)
            setFrequency(reminder.reminder_frequency)
            setDay(reminder.reminder_day ?? null)
        }
    }, [reminder])
    const canSeeCRT = ability.can("read", "Achilles")
    const canSeeLogicalPentest = ability.can("read", "Pentest")

    const filteredAssessmentTypes = AssessmentTypeKeysValueOps.filter(opt => {
        if (opt.value === AssessmentType.CRT && !canSeeCRT) {
            return false
        }
        if (opt.value === AssessmentType.LogicalPentest && !canSeeLogicalPentest) {
            return false
        }
        return true
    })

    const handleDayChange = (_event: React.MouseEvent<HTMLElement>, newDay: ReminderDay | null) => {
        if (newDay !== null) {
            setDay(newDay)
            setFormData(prevState => ({
                ...prevState,
                reminder_day: newDay // Actualiza el campo reminder_day en formData
            }))
        }
    }

    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    useEffect(() => {
        console.log(formData)
    }, [formData])
    const handleOnSave = async () => {
        try {
            if (reminder?.id) {
                await reminderService.update(reminder.id, formData)
            } else {
                await reminderService.create(formData)
            }
        } catch (error: any) {
            console.error(error)
        }

        onClose()
    }

    const modalActions = <ActionButton text={context.t.translate("add")} onClick={handleOnSave} />
    const handleClientSelector = (id: string | undefined) => {
        if (id) { setFormData(prevState => ({ ...prevState, client: { id } })) }
    }
    return (
        <GenericModal open={open} onClose={onClose} title={context.t.translate("add_reminder")} actions={modalActions}>
            {ability.can("*", "*") && <ClientSelector value={formData.client.id} onSelect={(id) => handleClientSelector(id)}></ClientSelector>}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start" }}>
                {/* Reminder Frequency Selection */}
                <Typography variant="subtitle1" fontWeight="bold">{context.t.translate("select_frequency")}</Typography>
                <ToggleButtonGroup
                    value={frequency}
                    exclusive
                    onChange={handleFrequencyChange}
                    aria-label="reminder frequency"
                    sx={{ fontSize: "0.875rem" }} // Reduce font size for ToggleButton
                >
                    {Object.values(ReminderFrequency).map((freq) => (
                        <ToggleButton key={freq} value={freq} sx={{ fontSize: "0.875rem" }}>
                            {context.t.translate(freq)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                {/* Reminder Day Selection (only if Weekly is selected) */}
                {frequency === ReminderFrequency.Weekly && (
                    <>
                        <Typography variant="subtitle1" fontWeight="bold">{context.t.translate("select_day")}</Typography>
                        <ToggleButtonGroup
                            value={day}
                            exclusive
                            onChange={handleDayChange}
                            aria-label="reminder day"
                            sx={{ fontSize: "0.875rem" }} // Reduce font size for ToggleButton
                        >
                            {Object.values(ReminderDay).map((d) => (
                                <ToggleButton key={d} value={d} sx={{ fontSize: "0.875rem" }}>
                                    {context.t.translate(d)}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </>
                )}
                <Grid>
                    <TextField
                        select
                        required
                        fullWidth
                        variant="filled"
                        label={context.t.translate("type")}
                        name="type"
                        value={formData.type}
                        onChange={handleInputChange}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "4px"
                            },
                            // Corregir los selectores para el icono de flecha del Select
                            "& .MuiSelect-icon": {
                                color: theme.palette.primary.main // Color del icono de flecha
                            },
                            // Los selectores para limpiar están en otra clase
                            "& .MuiInputAdornment-root .MuiIconButton-root": {
                                color: theme.palette.error.main,
                                "&:hover": {
                                    color: theme.palette.error.dark
                                }
                            }
                        }}
                    >
                        {filteredAssessmentTypes.map((opt, idx) => {
                            const formattedLabel = formatLabel(opt.label)
                            return (
                                <MenuItem key={idx} value={opt.value}>
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>
                                        {formattedLabel}
                                    </Typography>
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </Grid>

            </Box>
        </GenericModal>
    )
}

export default AddReminderModal
