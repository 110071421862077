import { Box, Grid, Switch, TextField, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { ServicesContext } from "@context/index"
import { NewUser } from "@models/User"

const initValue: NewUser = {
    firstLogin: "",
    id: "",
    client_id: "",
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    pwd: "",
    email: "",
    admin: false,
    groups: [],
    last_login: "",
    provider: false
}

const UserDetail: React.FC = () => {
    const userService = useContext(ServicesContext).userService
    const { userId } = useParams<{ userId: string }>()
    const [formData, setFormData] = useState<any>(initValue)
    const [initialData, setInitialData] = useState<any>(initValue)
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const navigate = useNavigate()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChange = (e: any) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await userService.get(userId as string)
                setFormData(data)
                setInitialData(data)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        if (userId) fetchData()
    }, [userId, userService])

    const hasChanges = (): boolean => {
        return JSON.stringify(formData) !== JSON.stringify(initialData)
    }

    const formValid = (): boolean => {
        return formData?.identifier?.trim() !== "" && formData?.name?.trim() !== "" && hasChanges()
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await userService.update(userId, formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            {context.t.translate("username")}
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            label="Username"
                            name="username"
                            value={formData?.username || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            {context.t.translate("email")}
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            label={context.t.translate("email")}
                            name="email"
                            value={formData?.email || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            {context.t.translate("phone")}
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            label={context.t.translate("phone")}
                            name="phone"
                            value={formData?.phone || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("admin")}</Typography>
                        <Switch name="admin" checked={formData.admin} onChange={handleSwitchChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("provider")}</Typography>
                        <Switch name="provider" checked={formData.provider} onChange={handleSwitchChange} />
                    </Grid>
                </Grid>

                <ActionButton
                    type="submit"
                    disabled={!formValid()}
                    text={context.t.translate("product_save")}
                    style={{ width: "100%", marginTop: "20px" }}
                />
            </Box>
        </Box>
    )
}

export default UserDetail
