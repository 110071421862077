import React, { useEffect, useRef, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import sunburst from "highcharts/modules/sunburst"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Grid, Typography } from "@mui/material"
import SunburstChartBreadCrumb from "./SunburstChartBreadCrumb"

highchartsAccessibility(Highcharts)
sunburst(Highcharts)

interface SunburstChartProps {
    data: Highcharts.PointOptionsObject[],
    legend: React.JSX.Element
}

const SunburstChart: React.FC<SunburstChartProps> = (props: SunburstChartProps) => {
    const dataInstance = props.data
    const chartComponent = useRef<HighchartsReact.RefObject>(null)
    const selectedPoint = useRef<null | string>(null)
    const [selectedPath, setSelectPath] = useState<Highcharts.Point[]>([])
    const [totalDevices, setTotalDevices] = useState<number>(0)

    useEffect(() => {
        const devices = dataInstance.reduce((acc, point) => acc + (point.value === null || point.value === undefined ? 0 : point.value), 0)
        setTotalDevices(devices)
    }, [dataInstance])

    const paintNode = (chart: undefined | null | Highcharts.Chart, id: string) => {
        const points = chart?.series[0].points.reduce((acc: Highcharts.Point[], point) => {
            const isParentOrMyself = id === "" || ((point as any).id !== undefined && id.startsWith((point as any).id))
            const isGreyed = (point.color as any).length === 9
            if (isParentOrMyself) {
                if (isGreyed) {
                    point.update({ color: (point.color as any).substr(0, 7) })
                }
                acc.push(point)
            } else {
                if (!isGreyed) {
                    point.update({ color: (point.color as any) + "33" })
                }
            }
            return acc
        }, [])
        const path: undefined | Highcharts.Point[] = points?.sort((a: any, b: any) => a?.id.length - b?.id.length)
        setSelectPath(path !== undefined && id !== "" ? path : [])
    }

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        title: {
            text: undefined
        },
        chart: {
            type: "sunburst",
            backgroundColor: "transparent",
            reflow: true,
            events: {
                load: function () {
                    const chart = this
                    chart.reflow()
                }
            }
        },
        credits: { enabled: false },
        plotOptions: {
            sunburst: {
                thickness: 150,
                allowPointSelect: true,
                allowTraversingTree: false,
                dataLabels: {
                    enabled: false
                }
            },
            series: {
                point: {
                    events: {
                        mouseOver: function () {
                            if (selectedPoint.current === null) {
                                const id: string = (this as any).id
                                paintNode(this.series.chart, id)
                            }
                        },
                        click: function () {
                            const id: string = (this as any).id
                            if (selectedPoint.current === id) {
                                selectedPoint.current = null
                            } else {
                                paintNode(this.series.chart, id)
                                selectedPoint.current = id
                            }
                        }
                    }
                }
            }
        },
        series: [{
            type: "sunburst",
            data: dataInstance,
            borderColor: "#000000"
        }]
    })

    const chart = chartComponent.current
    let height = 0
    let width = 0
    let x = 0
    let y = 0
    if (chart !== null) {
        const centerX = chart.chart.plotWidth / 2
        const centerY = chart.chart.plotHeight / 2
        height = chart.chart.plotHeight / 3
        width = chart.chart.plotWidth / 3
        x = centerX - width / 2
        y = centerY - height / 2
    }
    const specificPoint: any = selectedPath.length > 0 ? selectedPath[selectedPath.length - 1] : null

    return (
        <div onMouseLeave={() => {
            selectedPoint.current = null
            paintNode(chartComponent.current?.chart, "")
        }}>
            <Grid container spacing={2}>
                {/* Columna para el gráfico */}
                <Grid item xs={12} sm={8}>
                    <div style={{ position: "relative", width: "100%", height: "100%" }}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            ref={chartComponent}
                        />
                    </div>
                </Grid>

                {/* Columna para el texto de la información */}
                <Grid item xs={12} sm={4} container flexDirection="column" justifyContent="center">
                    <Grid item container justifyContent="center">
                        <Typography component="span" fontSize="34px" fontFamily="Griff" fontWeight="bolder" margin="auto" borderBottom="1px solid #6D6C6F">
                            {specificPoint === null
                                ? "All devices"
                                : (selectedPath.length === 4 ? selectedPath[2].name : "") + " " + specificPoint.name}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Typography component="span" margin="auto" textTransform="uppercase">
                            {specificPoint === null ? "Total" : "Number of devices"}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Typography component="span" fontSize="55px" fontFamily="Griff" margin="auto">
                            {specificPoint === null
                                ? totalDevices
                                : `${specificPoint.value} / ${((specificPoint.value / totalDevices) * 100).toFixed(2)}%`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <SunburstChartBreadCrumb totalDevices={totalDevices} selectedPath={selectedPath} />
                    </Grid>
                    <Grid item>
                        {props.legend}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SunburstChart
