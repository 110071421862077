import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import Tooltip from "@mui/material/Tooltip"
import { CafVulnStatus, VulnerabilityCAFStatusKeysValueOps } from "@models/index"
import { CafVulnStatusColors } from "@components/common/colors/VulnerabilityStateColors"

interface ColorAndBg {
    color: string;
    "background-color": string;
}

type StateColorMap = {
    [key in CafVulnStatus]: ColorAndBg;
};

const getStateColor = (state: CafVulnStatus): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [CafVulnStatus.LowRisk]: { color: "black", "background-color": CafVulnStatusColors.low_risk },
        [CafVulnStatus.FinishWithMitigation]: { color: "black", "background-color": CafVulnStatusColors.finish_with_mitigation },
        [CafVulnStatus.FinishWithMitigationAndRemediation]: { color: "black", "background-color": CafVulnStatusColors.finish_with_mitigation_and_remediation },
        [CafVulnStatus.FinishWithRemediation]: { color: "black", "background-color": CafVulnStatusColors.finish_with_remediation },
        [CafVulnStatus.FinishWithoutRemediation]: { color: "black", "background-color": CafVulnStatusColors.finish_without_remediation },
        [CafVulnStatus.NotAffected]: { color: "black", "background-color": CafVulnStatusColors.not_affected },
        [CafVulnStatus.WaitingCafAssessment]: { color: "black", "background-color": CafVulnStatusColors.waiting_caf_assessment },
        [CafVulnStatus.WaitingForRemediation]: { color: "black", "background-color": CafVulnStatusColors.waiting_for_remediation },
        [CafVulnStatus.WaitingForTriage]: { color: "black", "background-color": CafVulnStatusColors.waiting_for_triage },
        [CafVulnStatus.WaitingSupplierAssessment]: { color: "black", "background-color": CafVulnStatusColors.waiting_supplier_assessment }
    }

    return stateColorMap[state]
}

// Función para determinar la categoría del estado
const getCategory = (state: CafVulnStatus): string => {
    if (state === CafVulnStatus.LowRisk || state === CafVulnStatus.WaitingForTriage) {
        return "Detected"
    } else if (state === CafVulnStatus.WaitingSupplierAssessment || state === CafVulnStatus.WaitingCafAssessment) {
        return "In Triage"
    }
    return "Triage Finished"
}

interface VulnerabilityStateColoredProps {
    value: CafVulnStatus;
}

const VulnerabilityStateColored: React.FC<VulnerabilityStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg | null>(null)
    const [label, setLabel] = useState<string | undefined>("")
    if (!Object.values(CafVulnStatus).includes(value)) {
        return null // Si no es un CafVulnStatus válido, no renderizamos nada
    }
    useEffect(() => {
        setLabel(VulnerabilityCAFStatusKeysValueOps.find(v => v.value === value)?.label)
        setColor(getStateColor(value))
    }, [value])

    return (
        <Tooltip title={getCategory(value)} arrow>
            <Chip
                size="small"
                style={{
                    fontWeight: "bolder",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }}
                label={label}
            />
        </Tooltip>
    )
}

export { VulnerabilityStateColored }
export default VulnerabilityStateColored
