import { StyledBox } from "@components/common/Box/BoxContainer"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { useState } from "react"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import Hierarchy from "@models/Hierarchy"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import ProviderSelector from "@components/providers/ProviderSelector"

// FilterBox.jsx

const FilterBox = ({ onProviderChange, onHierarchyChange }: {
    onProviderChange: (providerId: string) => void,
    onHierarchyChange: (hierarchies: (Hierarchy | null)[]) => void
}) => {
    const [selectedProvider, setSelectedProvider] = useState<string>()
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    const theme = useTheme()
    const handleProviderSelect = (id: string) => {
        setSelectedProvider(id)
        onProviderChange(id) // Llama al callback para el proveedor
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL)
        setSelectedHierarchies(newSelectedHierarchies)
        onHierarchyChange(newSelectedHierarchies) // Llama al callback para las jerarquías
    }

    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    return (
        <StyledBox sx={{ backgroundColor: theme.palette.background.default, padding: "16px" }}>
            <Grid container spacing={4} alignItems="center">
                {/* Hierarchy Section */}
                <Grid item xs={7}>
                    <Typography color={theme.palette.text.secondary} fontSize="16px" fontWeight="bolder" gutterBottom>
                        Hierarchy
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                            <HierarchySelector
                                value={selectedHierarchies[0]?.id || ""}
                                customFilters={getCustomFilters(0)}
                                onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                                index={0}
                            />
                        </Grid>
                        {selectedHierarchies[0] && (
                            <Grid item xs={6}>
                                <HierarchySelector
                                    value={selectedHierarchies[1]?.id || ""}
                                    customFilters={getCustomFilters(1)}
                                    onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                                    index={1}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {/* Manufacturer Section */}
                <Grid item xs={5}>
                    <Typography color={theme.palette.text.secondary} fontSize="16px" fontWeight="bolder" gutterBottom>
                        Manufacturer
                    </Typography>
                    <Box>
                        <ProviderSelector
                            value={selectedProvider || ""}
                            onSelect={handleProviderSelect}
                            selectedProviders={[]}
                        />
                    </Box>
                </Grid>
            </Grid>
        </StyledBox>
    )
}

export default FilterBox
