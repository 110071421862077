import React, { useContext, useEffect, useState } from "react"
import { Alert, Grid, TextField, Typography, useTheme } from "@mui/material"
import { useParams } from "react-router-dom"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import UserLog from "@models/UserLog"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { useTrack } from "@components/track/TrackContext"

const LogDetails: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const theme = useTheme()
    const { id } = useParams<{ id: string }>()
    const [logData, setLogData] = useState<UserLog>()
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const userLogService = useContext(ServicesContext).userLogService
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "UserLogsDetails" })
    }, [])
    useEffect(() => {
        const fetchLogData = async () => {
            try {
                const logParams: QueryParameters = {
                    filters: [
                        {
                            field: "id",
                            operation: FilterOperation.StringEqual,
                            value: id as string
                        }
                    ]
                }

                const log = await userLogService.getAll(logParams)
                console.log(log.list)

                setLogData(log.list[0])
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }

        fetchLogData()
    }, [id])
    const formatChanges = (changes: string): string => {
        if (!changes) {
            return "No changes available"
        }

        try {
            const parsedChanges = JSON.parse(changes)
            if (typeof parsedChanges !== "object" || parsedChanges === null) {
                return "Invalid changes format"
            }

            let changesList = ""

            Object.entries(parsedChanges).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    changesList += `${key}: \n`
                    value.forEach((item) => {
                        changesList += `       • ${item}\n`
                    })
                } else {
                    changesList += `${key}: ${value}\n`
                }
            })

            return changesList.trim()
        } catch (error) {
            console.error("Error parsing changes:", error)
            return "Invalid changes format"
        }
    }

    if (!logData) {
        return (
            <div>
                {error && <Alert severity="error">{error.message}</Alert>}
                <br />
                {context.t.translate("loading")}
            </div>
        )
    }
    const formatTimestamp = (timestamp: string): string => {
        const date = new Date(timestamp)
        return date.toLocaleString()
    }
    return (
        <Grid pl="20px">
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                User Logs Details
            </Typography>

            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={error?.message || "Error fetching log details"}
            />
            <Grid container style={{ marginTop: "20px" }}>
                <StyledBox>
                    <Grid item container spacing={3}>

                        <Grid item xs={12} md={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Action</Typography>
                            <TextField
                                disabled
                                value={logData.action}
                                variant="standard"
                                fullWidth
                                style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Service Name</Typography>
                            <TextField
                                disabled
                                value={logData.service_name}
                                variant="standard"
                                fullWidth
                                style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Changes</Typography>
                            <TextField
                                disabled
                                value={formatChanges(logData.changes)}
                                variant="standard"
                                fullWidth
                                multiline
                                rows={4}
                                style={{ fontFamily: "Griff" }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: theme.palette.primary.main,
                                        color: theme.palette.primary.main,
                                        fontFamily: "Griff",
                                        "-webkit-text-fill-color": theme.palette.primary.main
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Client ID</Typography>
                            <TextField
                                disabled
                                value={logData.client_id}
                                variant="standard"
                                fullWidth
                                style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Entity ID</Typography>
                            <TextField
                                disabled
                                value={logData.entity_id}
                                variant="standard"
                                fullWidth
                                style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Timestamp</Typography>
                            <TextField
                                disabled
                                value={formatTimestamp(logData.timestamp)}
                                variant="standard"
                                fullWidth
                                style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                            />
                        </Grid>
                    </Grid>
                </StyledBox>
            </Grid>
        </Grid>
    )
}

export { LogDetails }
export default LogDetails
