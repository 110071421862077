import ServicesContext from "@context/ServicesContext"
import { AlertColor, FormGroup, Grid, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { Component } from "@models/Component"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef, CustomAction, GenericTable } from "@components/common/tables"
import { useTrack } from "@components/track/TrackContext"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import { Provider } from "@models/Provider"
import ProviderTableDefinition from "@components/providers/ProviderTableDefinition"
import { Filter, filterOperationMap } from "@models/Filter"
import FilterDropdown from "@components/filters/FiltersDrawer"
import CreateProviderModal from "../Modals/CreateProviderModal"

type ApiResponse = {
    count: number;
    list: Component[];
}

const ProviderList: React.FC = () => {
    // Constant
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [visualizeProviderOpen, setvisualizeProviderOpen] = React.useState(false)
    const theme = useTheme()
    const providerService = useContext(ServicesContext).providerService
    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const { id } = useParams<{ id: string; componentId: string }>()
    const { track, trackData } = useTrack()
    const [params, setParams] = useState<QueryParameters>({})
    const [fetchedFilters, setFetchedFilters] = useState<Filter[]>([])
    const filterService = useContext(ServicesContext).filterService
    const [queryParameters, setQueryParameters] = useState<QueryParameters>({ filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: id as string }] })
    // --------------------------------------------------
    const onProviderCreated = () => {
        setSnackbarMessage("Provider created successfully")
        setSeverity("success")
        setSnackbarOpen(true)
    }
    // useEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProviderTableDefinition, ability))
    }, [])
    useEffect(() => {
        track({ view: "ProviderList" })
        fetchFilters()
    }, [])
    // --------------------------------------------------
    const customActions = (params: GridRowParams<Provider>) => {
        const actions: CustomAction[] = []
        return actions
    }
    const handleCreateProviderOpen = () => {
        setvisualizeProviderOpen(true)
    }
    const fetchFilters = async () => {
        try {
            const response = await filterService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: "providers" }] })
            console.log(response)
            setFetchedFilters(response.list)
        } catch (error: any) {
            console.error(error)
        }
    }
    const handleFilterApplied = (filter: Filter) => {
        // Convertimos los filtros de 'Filter' a 'FilterOption' para que coincidan con el formato de 'QueryParameters'
        const newFilters: FilterOption[] = filter.filters.map((f) => ({
            field: f.Field, // Mapear el campo
            operation: filterOperationMap[f.FilterOperation], // Convertimos el FilterOperation de tipo numérico a cadena
            value: f.Value.join(",") // Asumimos que 'Value' es un array de strings y lo convertimos a una cadena
        }))

        setParams({ filters: newFilters })
    }
    const handleFilterDeselected = () => {
        setParams({ filters: [] })
    }
    const dataProvider = useCallback(
        async (filter: any) => {
            try {
                const response = await providerService.getAllExt({ ...filter, ...params })
                return response
            } catch (error) {
                console.error("Error fetching data:", error)
                throw error
            }
        },
        [params, params.filters]
    )
    return (
        <>

            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <FilterDropdown filters={fetchedFilters} onFilterApplied={handleFilterApplied} onFilterDeselected={handleFilterDeselected} />

                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="45px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("providers")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="15px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("sections.productInventory")}
                        </Typography>
                    </Grid>
                </Grid>
                <FormGroup>
                    <Grid item display="flex" gap={2}>
                        <ActionButton onClick={() => navigate("./provider/add")} text={context.t.translate("provider_manage")} />
                    </Grid>
                </FormGroup>
            </Grid>{/*
            <StatsCard title= {context.t.translate("provider")} entity="Provider"/> */}

            <Grid container item className="scroll-container">
                <Typography fontSize="45px" fontWeight="bolder" color={theme.palette.text.secondary} fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("product_comp")}</Typography>
                <Grid item container flexDirection="column" rowGap="35px">
                    <GenericTable<Provider> entity="Provider" columns={cols}
                        dataProvider={dataProvider}
                        key={JSON.stringify(params)}
                        onEdit={(elem: Provider) => navigate("../manage/product/provider/" + elem.id)}
                        onDelete={(elem: Provider) => providerService.delete(elem.id)}
                        customActions={customActions}
                        externalParameters={queryParameters}
                    />
                </Grid>

            </Grid>
            <CreateProviderModal
                open={visualizeProviderOpen}
                onClose={() => setvisualizeProviderOpen(false)}
                onProviderCreated={onProviderCreated}
            />
        </>
    )
}
export { ProviderList }
export default ProviderList
