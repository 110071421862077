import { ModelRelation } from "./utils"

interface Tag {

    id: string;
    name: string;
    description: string;
    color: string;
    created_at?: string;
    category: TagCategoryType;
    favorite: boolean;
    products?: string[]
    client: ModelRelation

}

enum TagCategoryType {
    Client = "client",
    Location = "location",
    Status = "status",
   }
export { TagCategoryType }

export type { Tag }
export default Tag
