import React, { useContext, useEffect } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { AssessmentType, CVSS3Severity, Template } from "@models/index"
import { FilterOperation, FilterOption } from "@utils/index"
import { I18nContext } from "I18nProvider"
import { MenuItem, TextField } from "@mui/material"
import { AbilityContext } from "@components/permissions"
import { emptySCAMetadata } from "@views/templates/TemplateForm"

interface TemplateSelectorProps{
    value: string
    customFilters?: FilterOption[]
    onSelect: (id: string|undefined, p: Template|null)=>void
}
const TemplateSelector: React.FC<TemplateSelectorProps> = ({ value, customFilters, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const templateService = useContext(ServicesContext).templateService
    const [disabled, setDisabled] = React.useState<boolean>(true)

    useEffect(() => {
        console.log(customFilters)
        if (customFilters && (!customFilters.some(f => f.value === "00000000-0000-0000-0000-000000000000" || f.value === undefined || f.value === ""))) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
        // if ((customFilters !== undefined && customFilters[0].value !== undefined && customFilters[0].value !== "00000000-0000-0000-0000-000000000000") || !ability.can("*", "Client")) {
        //     setDisabled(false)
        // } else {
        //     setDisabled(true)
        // }
    }, [customFilters])

    if (disabled) {
        return (
            // TODO: Translate
            <TextField select required disabled fullWidth variant="filled" label="Template" value="no">
                <MenuItem value="no">
                    <em>No options</em>
                </MenuItem>
            </TextField>
        )
    }

    return (
        <SearchInput<Template>
            margin="none" label={context.t.translate("asess_template_name")}
            variant="filled"
            value={value}
            requesterById={async v => {
                if (v !== undefined && v !== "00000000-0000-0000-0000-000000000000" && v !== "") {
                    const template = await templateService.get(v)
                    return [template]
                }
                const template: Template = { id: "", name: "", language: "en", color: "", logo: null, metadata: { type: AssessmentType.CRT, data: emptySCAMetadata }, config: { type: AssessmentType.CRT, data: { cvss_criticality: CVSS3Severity.Unknown, cvss_minimum: 0, limit_vulnerability_count: 100 } }, client: { id: "" }, type: AssessmentType.CRT }
                return [template]
            } }
            requester={async v => {
                let filter: FilterOption[] = []
                v !== "" && (filter = filter.concat({ field: "name", operation: FilterOperation.StringContains, value: v }))
                customFilters && customFilters[0]?.value !== "" && (filter = filter.concat(customFilters))

                const templates = await templateService.getAll({ filters: filter })
                return templates.list
            }} getterID={elem => elem?.id} getterTitle={elem => `${elem.name}`} onClickElem={(v) => onSelect(v?.id, v)}></SearchInput>
    )
}

export { TemplateSelector }
export default TemplateSelector
