/* eslint-disable space-before-function-paren */
import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { DataGrid, GridActionsCellItem, type GridColDef, GridFilterModel, GridRowParams, GridSortModel } from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { IconButton, Skeleton, Tooltip, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions/index"
import { convertGridFilterModelToQueryParameters } from "@components/common/tables/index"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import { IterableList } from "@models/iterable"
import { QueryParametersToURL } from "@services/index"
import { Subjects } from "@permissions/ability"
import { QueryParameters } from "@utils/index"
import debounce from "@utils/debounce"
import ActionMenu from "./Components/ActionMenu"
import CustomSnackbar from "../Snackbar/Snackbar"

export interface CustomAction {
    icon: React.JSX.Element;
    onClick?: () => void;
    visualize?: boolean;
    title?: string;
}

interface GenericTableProps<E> {
    entity: Subjects,
    columns: GridColDef[],
    withTags?: boolean,
    dataProvider: (filter: QueryParameters) => Promise<IterableList<E>>,
    onEdit?: (elem: E) => void,
    onDelete?: ((elem: E) => Promise<any>) | undefined,
    customActions?: (params: GridRowParams<any>) => CustomAction[],
    handleAddTagOpen?: (params: GridRowParams<any>) => void,
    handleVisualizeTagOpen?: (elem: E) => void,
    chips?: { label: string; style: React.CSSProperties }[],
    dataGridProps?: any,
    externalParameters?: QueryParameters,
    isInModal?: boolean,
    disabledEdit?: boolean,
    disabledActions?: boolean,
    minHeight?: number
}

function getColumns<E>(
    columns: GridColDef[],
    ability: any,
    entity: Subjects,
    customActions: ((params: GridRowParams<any>) => CustomAction[]) | undefined,
    onEdit: (elem: E) => void,
    onDelete: ((elem: E) => Promise<any>) | undefined,
    fetchData: () => void,
    disabledEdit?: boolean,
    disabledActions?: boolean
) {
    const cols = [...columns]
    const { showDialog } = useConfirmationDialog()
    const theme = useTheme()
    if (ability.can("update", entity) || ability.can("delete", entity) || customActions != null) {
        let count = 0
        const customActionsCount = customActions ? customActions({ id: "", row: {}, columns: [] }).length : 0

        if (ability.can("update", entity)) count++
        if (ability.can("delete", entity) && onDelete !== undefined) count++
        if (customActions != null) count += customActionsCount
        const calculatedMinWidth = count > 0 ? Math.max(count * 10, 130) : 0
        !disabledActions && cols.push({
            field: "Actions",
            type: "actions",
            headerName: "Actions",
            headerAlign: "right",
            flex: 1,
            minWidth: calculatedMinWidth,
            maxWidth: 250,
            align: "right",
            getActions: (params: GridRowParams<any>) => {
                const acts: React.JSX.Element[] = []

                const customActionsList = customActions ? customActions(params) : []
                const visualizeActions = customActionsList.filter(action => action.visualize)
                const otherActions = customActionsList.filter(action => !action.visualize)

                // Agregar las acciones `visualize`
                visualizeActions.forEach(action => {
                    acts.push(action.icon)
                })
                // Agregar acciones de edición y eliminación si se tienen los permisos
                if (ability.can("update", entity) && !disabledEdit) {
                    acts.push(
                        <IconButton
                            size="small"
                            data-testid="edit"
                            onClick={() => onEdit(params.row)}
                            sx={{ color: theme.palette.secondary.contrastText }}>
                            <Tooltip title="Edit"><EditIcon /></Tooltip>
                        </IconButton>
                    )
                }
                if (ability.can("delete", entity) && onDelete !== undefined) {
                    acts.push(
                        <GridActionsCellItem
                            key="Delete"
                            data-testid="delete"
                            icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                            label="Delete"
                            sx={{ color: theme.palette.secondary.contrastText }}
                            onClick={async () => {
                                showDialog("Confirm Delete", "Are you sure you want to delete this item?", async () => {
                                    await onDelete(params.row)
                                    fetchData()
                                })
                            }}
                        />
                    )
                }
                if (otherActions.length > 0) {
                    acts.push(<ActionMenu key="more-actions" actions={otherActions} />)
                }

                return acts
            }
        })
    }

    return cols
}

function NoDataOverlay() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
                color: "gray"
            }}
        >
            <Typography variant="subtitle1">No data available</Typography>
        </Box>
    )
}

function GenericTable<E>({ entity, columns, withTags, dataProvider, onEdit = () => { }, onDelete, customActions, handleAddTagOpen, handleVisualizeTagOpen, chips, dataGridProps, externalParameters, isInModal, disabledEdit = false, disabledActions = false, minHeight = 625 }: GenericTableProps<E>) {
    const ability = useContext(AbilityContext)
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [queryOptions, setQueryOptions] = useState<GridFilterModel | null>(() =>
        externalParameters && externalParameters.filters && externalParameters.filters.length > 0
            ? {
                items: externalParameters.filters.map(filter => (
                    {
                        field: filter.field,
                        operator: filter.operation,
                        value: filter.value
                    })
                )
            }
            : null
    )
    const [queryOptionsInternal, setQueryOptionsInternal] = useState<GridFilterModel | null>(null)
    const [sortOptions, setSortOptions] = useState<GridSortModel>([])
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: isInModal ? 5 : 10
    })
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<E[]>([])
    const [rowCountState, setRowCountState] = React.useState(0)
    const [queryString, setQueryString] = useState<string>(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [showTable, setShowTable] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const onFilterChange = useCallback(
        debounce((filterModel: GridFilterModel) => {
            setQueryOptionsInternal({ ...filterModel })
        }, 500), // Espera 500ms antes de ejecutar
        []
    )
    const onSortChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions([...sortModel])
    }, [])

    const queryOptionsExt = useMemo(() => externalParameters && externalParameters.filters && externalParameters.filters.length > 0
        ? {
            items: externalParameters.filters.map(filter => (
                {
                    field: filter.field,
                    operator: filter.operation,
                    value: filter.value
                })
            )
        }
        : null, [externalParameters])

    useEffect(() => {
        const obj: GridFilterModel = { items: [] }
        if (queryOptionsExt) {
            obj.items = [...obj.items, ...queryOptionsExt.items]
        }
        if (queryOptionsInternal) {
            obj.items = [...obj.items, ...queryOptionsInternal.items]
        }

        if (JSON.stringify(obj) !== JSON.stringify(queryOptions)) {
            setQueryOptions(obj)
        }
    }, [queryOptionsExt, queryOptionsInternal])

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            if (initialLoading) {
                setShowSkeleton(true)
                setShowTable(false)
            }
            const { list, count } = await dataProvider(
                convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)
            )
            setRows(list)
            setRowCountState(count)

            if (count < paginationModel.page * paginationModel.pageSize) {
                setPaginationModel((prev) => ({ ...prev, page: prev.page - 1 }))
            }
            if (initialLoading) {
                setTimeout(() => {
                    setShowSkeleton(false)
                    setTimeout(() => {
                        setShowTable(true)
                        setLoading(false)
                        setInitialLoading(false) // Ya no es carga inicial
                    }, 50)
                }, 50)
            } else {
                setLoading(false)
            }
        } catch (e: any) {
            setError(new Error(e.error || "Failed to fetch"))
            setSnackbarOpen(true)
            setShowSkeleton(false)
            setShowTable(true)
            setLoading(false)
            setInitialLoading(false)
        }
    }, [queryOptions, sortOptions, paginationModel, dataProvider])

    useEffect(() => {
        fetchData()
    }, [queryString])

    useEffect(() => {
        setQueryString(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))
    }, [queryOptions, sortOptions, paginationModel, dataProvider])

    const fColumns = getColumns(columns, ability, entity, customActions, onEdit, onDelete, fetchData, disabledEdit, disabledActions)

    const [columnVisibility, setColumnVisibility] = useState({})
    useEffect(() => {
        setColumnVisibility(columns.reduce((result, { field, hide }: any) => {
            return hide ? { ...result, [field]: false } : { ...result }
        }, {}))
    }, [columns])

    // Manejo del cierre del Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }
    const [isLoaded, setIsLoaded] = useState(false)
    useEffect(() => {
        if (!loading) {
            setIsLoaded(true) // Cambiar el estado a true para activar el fade-in
        }
    }, [loading])
    const tableStyles = {
        opacity: isLoaded ? 1 : 0, // Aplica el fade-in
        transition: "opacity 1s ease-in-out" // Transición suave
    }
    const calculatedHeight = Math.max(
        (paginationModel.pageSize + 1) * 52 + 80,
        minHeight
    )

    return (
        <Box sx={{ height: calculatedHeight, width: "100%", position: "relative" }}>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={error?.message || context.t.translate("an_error")}
            />

            {initialLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        width: "100%",
                        opacity: showSkeleton ? 1 : 0,
                        transition: "opacity 0.3s ease-in-out",
                        pointerEvents: showSkeleton ? "auto" : "none",
                        zIndex: showSkeleton ? 2 : 1
                    }}
                >
                    <Skeleton variant="rounded" width="100%" height={(paginationModel.pageSize + 1) * 52 + 80} />
                </Box>
            )}

            <Box
                sx={{
                    position: "absolute",
                    width: "100%",
                    opacity: initialLoading ? (showTable ? 1 : 0) : 1,
                    transition: "opacity 0.3s ease-in-out",
                    pointerEvents: initialLoading ? (showTable ? "auto" : "none") : "auto",
                    zIndex: initialLoading ? (showTable ? 2 : 1) : 1
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={fColumns}
                    columnVisibilityModel={columnVisibility}
                    onColumnVisibilityModelChange={setColumnVisibility}
                    filterMode="server"
                    sortingMode="server"
                    slots={{
                        NoRowsOverlay: NoDataOverlay
                    }}
                    autoHeight={false}
                    paginationMode="server"
                    onFilterModelChange={onFilterChange}
                    onSortModelChange={onSortChange}
                    onPaginationModelChange={setPaginationModel}
                    rowCount={rowCountState}
                    loading={loading && !initialLoading} // Mostrar spinner del DataGrid solo en cargas no iniciales
                    pageSizeOptions={isInModal ? [] : [5, 10, 25]}
                    paginationModel={paginationModel}
                    disableRowSelectionOnClick
                    minHeight={400}
                    getRowId={(row) => row.id ? row.id : row.identifier}
                    sx={{
                        minHeight: 400,
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-main": {
                            minHeight: minHeight - 108
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            minHeight: minHeight - 108
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-columnHeader .MuiButtonBase-root": {
                            color: theme.palette.primary.main, // Icono del header
                            "&:hover": {
                                color: theme.palette.primary.dark
                            }
                        },
                        color: theme.palette.secondary.contrastText,
                        backgroundColor: theme.palette.background.paper,

                        // Aplicar estilos a la paginación
                        "& .MuiTablePagination-root": {
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.secondary.contrastText
                        },

                        "& .MuiTablePagination-caption": {
                            color: theme.palette.secondary.contrastText
                        },

                        "& .MuiTablePagination-selectIcon": {
                            color: theme.palette.secondary.contrastText
                        },

                        "& .MuiTablePagination-select": {
                            color: theme.palette.secondary.contrastText
                        },

                        "& .MuiTablePagination-selectLabel": {
                            color: theme.palette.secondary.contrastText
                        },

                        "& .MuiButtonBase-root": {
                            color: theme.palette.secondary.contrastText
                        },

                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.secondary.contrastText
                        },
                        "& .MuiDataGrid-filterForm": {
                            "& .MuiButton-root": {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.primary.main,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.dark
                                }
                            },
                            "& .MuiIconButton-root": {
                                color: theme.palette.primary.main, // Iconos de cerrar filtro, limpiar, etc.
                                "&:hover": {
                                    color: theme.palette.primary.dark
                                }
                            }
                        },
                        "& .MuiDataGrid-menu .MuiSvgIcon-root": {
                            color: theme.palette.primary.main // Cambia el color de los iconos del menú
                        },
                        "& .MuiDataGrid-panel .MuiDataGrid-filterFormDeleteIcon button .MuiSvgIcon-root": {
                            color: `${theme.palette.primary.main} !important`
                        },
                        "& .MuiDataGrid-menu .MuiMenuItem-root:hover": {
                            backgroundColor: theme.palette.primary.light // Color de fondo al hacer hover
                        }
                    }}
                    {...dataGridProps}
                />
            </Box>
        </Box>
    )
}

export { GenericTable }
export default GenericTable
