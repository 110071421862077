
import { Button, ButtonProps, useTheme } from "@mui/material"
import React from "react"
type ActionButtonProps = {
    text: string | React.ReactNode
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    icon?: React.ReactNode
    variant?: ButtonProps["variant"]
    disabled?: boolean
    tabIndex?: number
    type?: "button" | "submit" | "reset";
    style?: React.CSSProperties
    dataTestId?: string
}
const ActionButton = (props: ActionButtonProps) => {
    const theme = useTheme()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            props.onClick(event) // Pasamos el evento correctamente
        }
    }
    return (
        <Button
            disabled={props.disabled ?? false}
            variant={props.variant ?? "contained"}
            tabIndex={props.tabIndex ?? 0}
            startIcon={props.icon ? props.icon : null}
            sx={{
                color: props.variant === "outlined"
                    ? theme.palette.primary.main
                    : theme.palette.primary.contrastText,
                whiteSpace: "nowrap",
                // Estilo personalizado para el botón deshabilitado
                "&.Mui-disabled": {
                    backgroundColor: theme.palette.action.disabledBackground, // Cambiar el fondo cuando está deshabilitado
                    color: "rgba(255, 255, 255, 0.3)"// Cambiar el color cuando está deshabilitado
                }
            }}
            type={props.type ?? "button"}
            style={props.style}
            data-testid={props.dataTestId}
            onClick={handleClick}>{props.text}</Button>
    )
}
export default ActionButton
