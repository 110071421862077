import { NotificationConfigs } from "@models/NotificationConfig"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class NotificationConfigService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/notification-config", MediaTypes.JSON)
    }

    public getAllByClient = (id: string) => {
        if (id.trim() !== "") {
            return this.http.get(`/${this.path}/${id}`) as Promise<NotificationConfigs>
        }
        return this.http.get(`/${this.path}`) as Promise<NotificationConfigs>
    }

    public updateAllByClient = (id: string, notificationConfigs: NotificationConfigs) => {
        if (id.trim() !== "") {
            return this.http.put(`/${this.path}/${id}`, notificationConfigs.list)
        }
        return this.http.put(`/${this.path}`, notificationConfigs.list)
    }
}

export { NotificationConfigService }
export default NotificationConfigService
