/* eslint-disable multiline-ternary */
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Hierarchy from "@models/Hierarchy"
import { Divider, Grid, Menu, MenuItem, Typography, useTheme } from "@mui/material"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTrack } from "@components/track/TrackContext"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { BiBug } from "react-icons/bi"
import { MdOutlineFlipCameraIos, MdTableRows } from "react-icons/md"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import AccountTreeIcon from "@mui/icons-material/AccountTree"
import { StyledTab, StyledTabs } from "@views/product/Components/StyledTabs"
import useMediaResponsive from "@hooks/useMobile"
import ActionButton from "@components/common/Button/ActionButton"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "./hierarchyUtils"
import VulnerabilityTab from "./tabs/Vulnerability"
import ProductTab from "./tabs/Products"
import TreeTab from "./tabs/Tree"
import DetailsTab from "./tabs/Details"
import SnapshotTab from "./tabs/Snapshot"

const initValue: Hierarchy = {
    id: "",
    name: "",
    description: "",
    path: "",
    client: { id: "00000000-0000-0000-0000-000000000000" },
    is_proyect: false,
    status: undefined
}
const HierarchyDetail: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // Constants
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const { id } = useParams<{ id: string }>()
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const [error, setError] = useState<Error|null>(null)
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))
    const ability = useContext(AbilityContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [formData, setFormData] = useState<Hierarchy>(initValue)
    const [originalData, setOriginalData] = useState<Hierarchy>(initValue)
    const [tabs, setTabs] = useState<{label:string, content: string, icon?: React.ReactNode}[]>([])
    // --------------------------------------------------
    const { track, trackData } = useTrack()
    const isMobile = useMediaResponsive("(max-width: 780px)")
    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }
    useEffect(() => {
        track({ view: "HierarchyDetail" })
    }, [])
    useEffect(() => {
        const newTabs = []
        newTabs.push({ label: context.t.translate("details"), content: "details", icon: <ExpandMoreIcon /> })
        newTabs.push({ label: context.t.translate("dash_vulner"), content: "vulnerability", icon: <BiBug /> })
        newTabs.push({ label: context.t.translate("products"), content: "products", icon: <TbDeviceHeartMonitor /> })
        newTabs.push({ label: context.t.translate("hierarchy_tree"), content: "tree", icon: <AccountTreeIcon fontSize="small"/> })
        newTabs.push({ label: "Snapshot", content: "snapshot", icon: <MdOutlineFlipCameraIos /> })

        setTabs(newTabs)

        // Si el tabIndex actual no es válido, resetearlo
        if (tabIndex >= newTabs.length) {
            setTabIndex(0)
        }
    }, [formData])
    useEffect(() => {
        const fetchHierarchy = async () => {
            if (id) {
                try {
                    const hierarchy = await hierarchyService.get(id)
                    setFormData(hierarchy)
                    setOriginalData(hierarchy) // Setea el estado original
                    const pathIds = hierarchy.path.split(PATH_SEPARATOR).filter(pathId => pathId !== id)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                } catch (error) {
                    setError({ message: context.t.translate("failed_to_fetch") } as Error)
                    setSnackbarOpen(true)
                }
            }
        }
        fetchHierarchy()
    }, [id, hierarchyService])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL - 1)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, path: newPath })
    }

    const formValid = (): boolean => {
        // Primero, chequeamos que los campos `name` y `description` no estén vacíos
        const isNotNullrules = [
            formData.name === "", // Verificar si el campo 'name' está vacío
            formData.description === "" // Verificar si el campo 'description' está vacío
        ]

        // Ahora verificamos si los campos han cambiado respecto a los datos originales
        const isModified =
            formData.name !== originalData.name ||
            formData.description !== originalData.description ||
            formData.client.id !== originalData.client.id ||
            formData.path !== originalData.path ||
            formData.is_proyect !== originalData.is_proyect ||
            formData.status !== originalData.status

        // Si 'is_proyect' es false, aseguramos que 'status' sea undefined
        if (formData.is_proyect === false && formData.status !== undefined) {
            isNotNullrules.push(true) // Agregar error si 'status' no es undefined
        }

        // Si los campos han sido modificados, los agregamos a las reglas de validación
        if (isModified) {
            isNotNullrules.push(true)
        }

        // Verificar si alguna de las reglas es verdadera
        return !isNotNullrules.some(rule => rule) // Si hay alguna regla violada, el formulario no es válido
    }

    useEffect(() => {
        console.log("form0", formData)
    }, [formData])
    // Api call form validation
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            if (formData.id) {
                let updatedPath = formData.path
                if (!updatedPath || !updatedPath.endsWith(formData.id)) {
                    // Add the id to the end of the path
                    updatedPath = updatedPath ? `${updatedPath}${PATH_SEPARATOR}${formData.id}` : formData.id
                }
                const updatedFormData = { ...formData, path: updatedPath }
                // Update existing hierarchy
                await hierarchyService.update(formData.id, updatedFormData)
            } else {
                // Create new hierarchy
                await hierarchyService.create(formData)
            }
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    const handleSaveChanges = async () => {
        try {
            if (formData.id) {
                let updatedPath = formData.path
                if (!updatedPath || !updatedPath.endsWith(formData.id)) {
                    updatedPath = updatedPath ? `${updatedPath}${PATH_SEPARATOR}${formData.id}` : formData.id
                }
                const updatedFormData = { ...formData, path: updatedPath }
                // Update existing hierarchy
                await hierarchyService.update(formData.id, updatedFormData)
            } else {
                // Create new hierarchy
                await hierarchyService.create(formData)
            }
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            if (ability.can("*", "Client")) {
                if (formData.client.id !== "00000000-0000-0000-0000-000000000000") {
                    return [
                        { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                        { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    ]
                }
                return undefined
            }
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleTabSelect = (index: number) => {
        handleTabChange(null, index)
        handleMenuClose()
    }

    return (
        <Grid item flexDirection="column" rowGap="25px">
            <Grid container flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                <Grid item xs={5}>
                    <Typography variant="h5" fontWeight="bold">
                        {originalData.name}
                    </Typography>
                </Grid>

                {!isMobile
                    ? <Grid item sx={{ ml: "auto" }} xs={7}>
                        <StyledTabs value={tabIndex} onChange={handleTabChange}>
                            {tabs.map((tab, index) => (
                                <StyledTab
                                    key={index}
                                    label={context.t.translate(tab.content)}
                                    icon={tab.icon ? <>{tab.icon}</> : undefined}
                                    sx={{ minWidth: 100, width: "auto", paddingX: 1, fontSize: "0.875rem" }}
                                />
                            ))}
                        </StyledTabs>
                    </Grid>
                    : <>
                        <Grid item sx={{ flexGrow: 0.3, flexShrink: 0, minWidth: "unset", display: "flex", justifyContent: "flex-end" }}>
                            <ActionButton
                                text= {context.t.translate("tabs")}
                                onClick={handleMenuOpen}
                                icon={<MdTableRows />}
                            />

                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                {tabs.map((tab, index) => (
                                    <MenuItem key={index} onClick={() => handleTabSelect(index)}>
                                        {tab.icon && <>{tab.icon}</>} {context.t.translate(tab.content)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </> }
            </Grid>

            <Divider sx={{ width: "100%", backgroundColor: "gray", mb: 2, mt: "2px" }} />

            <Grid item xs={12} padding="30px">
                {tabs[tabIndex]?.content === "details" && <DetailsTab formData={formData} setFormData={setFormData} formValid={formValid} handleInputChange={handleInputChange} onSave={handleSaveChanges} />}
                {tabs[tabIndex]?.content === "vulnerability" && <VulnerabilityTab hierarchyId={formData.id} />}
                {tabs[tabIndex]?.content === "products" && <ProductTab hierarchyId={formData.id} />}
                {tabs[tabIndex]?.content === "tree" && <TreeTab hierarchie={formData} />}
                {tabs[tabIndex]?.content === "snapshot" && <SnapshotTab hierarchyId={formData.id} />}
            </Grid>
        </Grid>

    )
}
export { HierarchyDetail }
export default HierarchyDetail
