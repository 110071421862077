import Tag from "@models/Tag"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

interface RecentFavTags {
    recent_favs: {
        list: Tag[]; // Aquí recibes una lista de objetos Tag
        count: number;
    };
    favs: {
        list: Tag[];
        count: number;
    };
}

class TagService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/tag", MediaTypes.JSON)
    }

    public getRecentFavTags = async (): Promise<RecentFavTags> => {
        return await this.http.get(`/${this.path}/recent_favs`) as RecentFavTags
    }

    // public createTag = (data: Tag) => {
    //     return this.http.post(`/${this.path}`, data);
    // };
}

export { TagService }
export default TagService
