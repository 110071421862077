import React, { useContext, useEffect, useState } from "react"
import { FaFilter } from "react-icons/fa"
import { Grid, useTheme } from "@mui/material"
import { Filter } from "@models/Filter"
import ServicesContext from "@context/ServicesContext"
import FilterSheet from "./FiltersSheet"

type FilterDropdownProps = {
    filters: Filter[];
    onFilterApplied?: (filter: Filter) => void;
    onFilterDeselected?: () => void; // New prop for deselection handling
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
    filters,
    onFilterApplied,
    onFilterDeselected
}) => {
    const [isOpen, setIsOpen] = useState(false) // Estado de apertura del filtro
    const [currentFilters, setCurrentFilters] = useState<Filter[]>([]) // Estado local para los filtros
    const theme = useTheme()
    const filterService = useContext(ServicesContext).filterService

    // Sincronizar los filtros cuando se pasen como props desde el componente padre
    useEffect(() => {
        setCurrentFilters(filters)
    }, [filters]) // Solo actualizar cuando cambien los filtros

    // Función para actualizar un filtro como favorito
    const setFavoriteFilter = async (filter: Filter) => {
        try {
            const updatedFilter = { ...filter, favorite: !filter.favorite }
            await filterService.update(filter.id, updatedFilter)

            // Actualizar el filtro en el estado local
            setCurrentFilters((prevFilters) =>
                prevFilters.map((f) => (f.id === filter.id ? updatedFilter : f))
            )
        } catch (error: any) {
            console.error(error)
        }
    }

    const deleteFilterHandler = async (filter: Filter) => {
        try {
            await filterService.delete(filter.id)
            setCurrentFilters((prevFilters) =>
                prevFilters.filter((f) => f.id !== filter.id)
            )

            console.log("Filtro eliminado correctamente")
        } catch (error: any) {
            console.error("Error al eliminar el filtro:", error)
        }
    }

    const applyFilter = (filter: Filter) => {
        console.log("Aplicando filtro:", filter)
        if (onFilterApplied) {
            onFilterApplied(filter)
        }
        setIsOpen(false)
    }

    // Nueva función para manejar la deselección de filtros
    const handleFilterDeselection = () => {
        console.log("Deseleccionando filtro")
        if (onFilterDeselected) {
            onFilterDeselected()
        }
        setIsOpen(false)
    }

    const toggleFavorite = (e: React.MouseEvent, filter: Filter) => {
        e.stopPropagation()
        setFavoriteFilter(filter)
        console.log("Alternar favorito:", filter)
    }

    const removeFilter = (e: React.MouseEvent, filter: Filter) => {
        e.stopPropagation()
        console.log("Eliminar filtro:", filter)
        deleteFilterHandler(filter)
    }

    const favoriteFilters = currentFilters.filter((filter) => filter.favorite)

    return (
        <Grid>
            <Grid
                style={{
                    position: "fixed",
                    right: 0,
                    top: "33%",
                    zIndex: 40,
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                }}
                onClick={() => setIsOpen(true)}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: theme.palette.primary.light,
                        backdropFilter: "blur(4px)",
                        border: "1px solid #e0e0e0",
                        borderRight: "0",
                        borderRadius: "0.5rem 0 0 0.5rem",
                        padding: "0.5rem",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s"
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "rgba(255, 0, 0, 0.5)"
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = theme.palette.primary.light
                    }}
                >
                    <FaFilter
                        style={{
                            height: "20px",
                            width: "20px",
                            color: "#6b7280"
                        }}
                    />
                </div>
            </Grid>

            {/* FilterSheet recibe los filtros actualizados y funciones para modificar los filtros */}
            <FilterSheet
                isOpen={isOpen}
                onOpenChange={setIsOpen}
                recentFilters={currentFilters} // Pasamos los filtros actuales
                favoriteFilters={favoriteFilters} // Pasamos los favoritos
                onApplyFilter={applyFilter} // Función para aplicar filtro
                onToggleFavorite={toggleFavorite} // Función para alternar favorito
                onRemoveFilter={removeFilter} // Función para eliminar filtro
                onFilterDeselected={handleFilterDeselection} // Nueva prop para manejar deselección
            />
        </Grid>
    )
}

export default FilterDropdown
