import React, { useContext } from "react"
import { Font, Link, Page, Text, View } from "@react-pdf/renderer"
import Vulnerability, { AcunetixMetadata, BurpMetadata, getCVSS3Criticality, NessusMetadata } from "@models/Vulnerability"
import { CWES } from "@models/CWE"
import Template from "@models/Template"
import RenderContent from "@views/vulnerability/RenderContent"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"
import VexDocumentStyles from "../../VexStyleSheet"
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Bold.otf", fontWeight: "bold" })
Font.register({ family: "Griff", src: "/assets/fonts/Griff-RegularItalic.otf", fontStyle: "italic" })
const severityColors: { [key: string]: string } = {
    critical: "black",
    high: "#D9534F",
    medium: "#FF8537",
    low: "#A8D1E7",
    undefined: "grey"
}

const capitalizeFirstLetter = (string: any) => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
}
const i18n = {
    es: {
        title_vulnerabilities: "4.3 Lista de vulnerabilidades",
        vulnerabilities1: "La siguiente tabla enumera todas las vulnerabilidades encontradas. Estas se muestran identificando su criticidad en orden descendente.",
        identifier: "Identificador",
        cve: "CVE",
        description: "Descripción",
        critically: "Criticidad",
        affected: "Afectado",
        origin: "Origen",
        insufficient_information: "Información Insuficiente",
        name: "Nombre",
        reference: "Referencia",
        solution: "Solución"
    },
    en: {
        title_vulnerabilities: "4.3 List of vulnerabilities",
        vulnerabilities1: "The following table lists all the vulnerabilities found. These are shown by identifying their criticality in a descending manner.",
        identifier: "Identifier",
        cve: "CVE",
        description: "Description",
        critically: "Criticality",
        affected: "Affected",
        origin: "Origin",
        insufficient_information: "Insufficient Information",
        name: "Name",
        reference: "Reference",
        solution: "Solution"
    }
} as const
const VulnsList: React.FC<{ vulnerabilities:Vulnerability[], template?: Template, lang?: string }> = ({ vulnerabilities, template, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style= {{ paddingBottom: 60, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={VexDocumentStyles.section} wrap={true}>
                <Text style={{ ...styles.textua, paddingLeft: 5, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>{i18n[locale].title_vulnerabilities}</Text>
                <Text style={{ ...styles.textua, paddingLeft: 5, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7 }}>{i18n[locale].vulnerabilities1}</Text>

                <View style={VexDocumentStyles.row} fixed>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{i18n[locale].identifier}</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{i18n[locale].cve}</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "60%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{i18n[locale].description}</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{i18n[locale].critically}</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{i18n[locale].affected}</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{i18n[locale].origin}</Text></View>
                </View>

                <View>
                    {vulnerabilities.map((vulnerability, index) => (
                        <VulnerabilityRow key={index} vulnerability={vulnerability} index={index}/>
                    ))}
                </View>
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}

const VulnerabilityRow: React.FC<{ vulnerability: Vulnerability, index: number, lang?: string }> = ({ vulnerability, index, lang }) => {
    const metadata = vulnerability.metadata?.data ?? {}
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    const isNessusMetadata = (data: any): data is NessusMetadata => {
        return data && typeof data === "object" && "pluginID" in data
    }

    const isBurpMetadata = (data: any): data is BurpMetadata => {
        return data && typeof data === "object" && "title" in data
    }

    const isAcunetixMetadata = (data: any): data is AcunetixMetadata => {
        return data && typeof data === "object" && "title" in data
    }

    return (
        <View style={{ ...VexDocumentStyles.row }} wrap={false}>
            {/* Identificador */}
            <View style={{ ...VexDocumentStyles.col, width: "10%", justifyContent: "center" }}>
                <Link src={`#VUL-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>
                        {`VUL-${String(index + 1).padStart(3, "0")}`}
                    </Text>
                </Link>
            </View>

            {/* CVE o Plugin ID dependiendo del origen */}
            <View style={{ ...VexDocumentStyles.col, width: "10%", justifyContent: "center" }}>
                {vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                    ? (
                        <Link href={`https://www.tenable.com/plugins/nessus/${metadata.plugin_id}`} style={{ textDecoration: "none", color: "black" }}>
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica" }}>
                                {`ID-${metadata.plugin_id}`}
                            </Text>
                        </Link>
                    )
                    : vulnerability.identifier.startsWith("CVE-")
                        ? (
                            <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`} style={{ textDecoration: "none", color: "black" }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica" }}>
                                    {vulnerability.identifier}
                                </Text>
                            </Link>
                        )
                        : (
                            <Text style={{ ...VexDocumentStyles.info }}>N/A</Text>
                        )}
            </View>

            {/* Descripción o nombre */}
            <View style={{ ...VexDocumentStyles.col, width: "60%", justifyContent: "center" }}>
                {vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                    ? (
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>
                            {metadata.plugin_name ?? "Insufficient Information"}
                        </Text>
                    )
                    : vulnerability.origin === "Burp" && isBurpMetadata(metadata)
                        ? (
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>
                                {metadata.title}
                            </Text>
                        )
                        : vulnerability.origin === "Acunetix" && isAcunetixMetadata(metadata)
                            ? (
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>
                                    {metadata.title}
                                </Text>
                            )
                            : (
                                <Text style={{ ...VexDocumentStyles.info }}>Insufficient Information</Text>
                            )}
            </View>

            {/* Criticidad */}
            <View style={{ ...VexDocumentStyles.col, width: "10%", justifyContent: "center" }}>
                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", color: severityColors[getCVSS3Criticality(vulnerability.score)] }}>
                    {capitalizeFirstLetter(getCVSS3Criticality(vulnerability.score))}
                </Text>
            </View>

            {/* Afectados */}
            <View style={{ ...VexDocumentStyles.col, width: "10%", justifyContent: "center" }}>
                {vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                    ? (
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>
                            {metadata.service ?? "N/A"}
                        </Text>
                    )
                    : (
                        <Text style={{ ...VexDocumentStyles.info }}>N/A</Text>
                    )}
            </View>

            {/* Origen */}
            <View style={{ ...VexDocumentStyles.col, width: "10%", justifyContent: "center" }}>
                <Text style={{ ...VexDocumentStyles.info }}>{vulnerability.origin ?? "SCA"}</Text>
            </View>
        </View>
    )
}

const VulnsListDetail: React.FC<{ vulnerabilities:Vulnerability[], template?: Template, lang?: string }> = ({ vulnerabilities, template, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style={{ paddingBottom: "60", backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View wrap={true}>
                {vulnerabilities.map((vulnerability, index) => (
                    <VulnerabilityHeaderDetail key={index} vulnerability={vulnerability} index={index}/>
                ))}
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}
const VulnerabilityHeaderDetail: React.FC<{ vulnerability: Vulnerability; index: number, lang?: string }> = ({ vulnerability, index, lang }) => {
    const metadata = vulnerability.metadata?.data ?? {}
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    const isNessusMetadata = (data: any): data is NessusMetadata => {
        return data && typeof data === "object" && "pluginID" in data
    }

    const isBurpMetadata = (data: any): data is BurpMetadata => {
        return data && typeof data === "object" && "title" in data
    }

    const isAcunetixMetadata = (data: any): data is AcunetixMetadata => {
        return data && typeof data === "object" && "title" in data
    }
    const isBurpOrAcunetixMetadata = (data: any): data is BurpMetadata | AcunetixMetadata => {
        return typeof data === "object" && "title" in data
    }

    return (
        <View key={index + 1} style={{ ...VexDocumentStyles.section }} wrap={false}>
            <Text id={`VUL-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>4.2.{index} {`VUL-${String(index + 1).padStart(3, "0")} `}</Text>
            <View style={VexDocumentStyles.row}>
                {vulnerability.origin === ""
                    ? (
                        <>
                            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
                            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.identifier} </Text></Link></View>
                        </>
                    )
                    : vulnerability.origin === "Pentest"
                        ? (
                            <>
                                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
                                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.identifier} </Text></Link></View>
                            </>
                        )
                        : vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                            ? (
                                <>
                                    <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Plugin id</Text></View>
                                    <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://www.tenable.com/plugins/nessus/${metadata.plugin_id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> ID-{metadata.plugin_id} </Text></Link></View>
                                </>
                            )
                            : vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                                ? (
                                    vulnerability.identifier
                                        ? (
                                            <>
                                                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}>
                                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text>
                                                </View>
                                                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}>
                                                    {vulnerability.identifier.startsWith("CVE-")
                                                        ? (
                                                            <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`} style={{ textDecoration: "none", color: "black" }} >
                                                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                                                                    {vulnerability.identifier.split(":")[0]}
                                                                </Text>
                                                            </Link>
                                                        )
                                                        : (
                                                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                                                       N/A{}
                                                            </Text>
                                                        )}
                                                </View>
                                            </>
                                        )
                                        : (
                                            <>
                                                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}>
                                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text>
                                                </View>
                                                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}>
                                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                                            N/A
                                                    </Text>
                                                </View>
                                            </>
                                        )
                                )
                                : null
                }
            </View>
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].name}</Text></View>
                {vulnerability.origin === ""
                    ? (<View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                        <Link href={vulnerability.cwe && vulnerability.cwe.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwe[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}>
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.cwe?.length ? CWES[vulnerability.cwe[0]] : "Insufficient Information"}
                            </Text>
                        </Link>
                    </View>)
                    : vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                        ? (
                            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                                <Link href={vulnerability.cwe && vulnerability.cwe.length > 0 ? `https://www.tenable.com/plugins/nessus/${metadata.plugin_id}` : ""} style={{ textDecoration: "none", color: "black" }}>
                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{metadata.plugin_name ?? "Insufficient Information"}
                                    </Text>
                                </Link>
                            </View>
                        )
                        : (vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp") && isBurpOrAcunetixMetadata(vulnerability.metadata?.data)
                            ? (
                                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                                    <Link style={{ textDecoration: "none", color: "black" }}>
                                        {vulnerability.metadata?.data?.title
                                            ? (
                                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                                    {vulnerability.metadata.data.title}
                                                </Text>
                                            )
                                            : null}
                                    </Link>
                                </View>
                            )
                            : (
                                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                    N/A
                                    </Text>
                                </View>
                            )

                }
            </View>
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].critically}</Text></View>
                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>{capitalizeFirstLetter(getCVSS3Criticality(vulnerability.score))}</Text></View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVSS</Text></View>
                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 6, paddingBottom: 0, paddingLeft: 5 }}>
                    <View>
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>{vulnerability.score + " " + vulnerability.vector}</Text>
                    </View>
                </View>
            </View>
            <View style={{ ...VexDocumentStyles.row }}>
                <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].description}</Text></View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>{vulnerability.description !== "" ? vulnerability.origin === "Pentest" ? <View><RenderContent description={vulnerability.description} /></View> : <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 1, flexWrap: "wrap" }}>{vulnerability.description} </Text> : "N/A"}</View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].solution}</Text></View>
            </View>
            {vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                ? (
                    <View style={VexDocumentStyles.row}>
                        <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                {metadata.solution ?? "N/A"}
                            </Text>
                        </View>
                    </View>
                )
                : vulnerability.origin === "Pentest"
                    ? (
                        <View style={VexDocumentStyles.row}>
                            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                    {vulnerability.solution ?? "N/A"}
                                </Text>
                            </View>
                        </View>
                    )
                    : vulnerability.origin === "Acunetix" && isAcunetixMetadata(metadata)
                        ? (
                            <View style={VexDocumentStyles.row}>
                                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                        {metadata.recommendation ?? "N/A"}
                                    </Text>
                                </View>
                            </View>
                        )
                        : vulnerability.origin === "Burp" && isBurpMetadata(metadata)
                            ? (
                                <View style={VexDocumentStyles.row}>
                                    <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                            {metadata.solution_vuln ?? "N/A"}
                                        </Text>
                                    </View>
                                </View>
                            )
                            : (
                                <View style={VexDocumentStyles.row}>
                                    <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                    N/A
                                        </Text>
                                    </View>
                                </View>
                            ) }
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].reference}</Text></View>
                <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                    {vulnerability.origin === ""
                        ? (
                            <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`} style={{ textDecoration: "none", color: "black" }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                    {`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`}
                                </Text>
                            </Link>
                        )
                        : vulnerability.origin === "Nessus" && isNessusMetadata(metadata)
                            ? (
                                <Link href={`https://www.tenable.com/plugins/nessus/${metadata.plugin_id}`} style={{ textDecoration: "none", color: "black" }}>
                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                        {metadata.plugin_id ? `https://www.tenable.com/plugins/nessus/${metadata.plugin_id}` : "N/A"}
                                    </Text>
                                </Link>
                            )
                            : vulnerability.origin === "Pentest"
                                ? (
                                    <Link href={`${vulnerability.references}`} style={{ textDecoration: "none", color: "black" }}>
                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                            {vulnerability.references ? `${vulnerability.references}` : "N/A"}
                                        </Text>
                                    </Link>
                                )
                                : vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                                    ? (
                                        vulnerability.identifier
                                            ? (
                                                vulnerability.identifier.startsWith("CVE-")
                                                    ? (
                                                        <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`} style={{ textDecoration: "none", color: "black" }}>
                                                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                                                {`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`}
                                                            </Text>
                                                        </Link>
                                                    )
                                                    : (
                                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                                    N/A{}
                                                        </Text>
                                                    )
                                            )
                                            : (
                                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                    N/A
                                                </Text>
                                            )
                                    )
                                    : null
                    }
                </View>
            </View>
        </View>)
}

export { VulnsList, VulnsListDetail }
