import { AssessmentType } from "./Assessment"
import { ModelRelation } from "./utils"
export enum ReminderFrequency {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
    Quarterly = "quarterly",
    Yearly = "yearly",
  }

export enum ReminderDay {
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
    Sunday = "sunday",
  }

type Reminder = {
 id: string;
 reminder_frequency: ReminderFrequency;
 reminder_day?: ReminderDay;
 frequency_date: string;
 type: AssessmentType;
 client: ModelRelation;
}

export type { Reminder }
