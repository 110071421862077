import { useLocation, useNavigate } from "react-router-dom"
import React, { useEffect } from "react"
import { HierarchyStatusIndicator } from "@components/common/indicator/HierarchyStatusIndicator"
import { SnapshotFleetStatusModel } from "@models/Snapshot"
import Hierarchy from "@models/Hierarchy"

interface SnapshotFleetStatusProps {
    value: SnapshotFleetStatusModel[] | Hierarchy[];
    id: string;
}

const SnapshotFleetStatus: React.FC<SnapshotFleetStatusProps> = ({ value, id }) => {
    const navigate = useNavigate()
    const location = useLocation()

    // Contar la cantidad de cada estado en la jerarquía
    const isHierarchyArray = (arr: any[]): arr is Hierarchy[] =>
        arr.length > 0 && "status" in arr[0]

    const counts = Array.isArray(value) && isHierarchyArray(value)
        ? value.reduce(
            (acc, hierarchy) => {
                acc.waitingForCafAssessment += hierarchy.status === "waiting_caf_assessment" ? 1 : 0
                acc.underAssessment += hierarchy.status === "under_assessment" ? 1 : 0
                acc.waitingForRemediation += hierarchy.status === "waiting_for_remediation" ? 1 : 0
                acc.finishWithRemediation += hierarchy.status === "finish_with_remediation" ? 1 : 0
                acc.finishWithMitigation += hierarchy.status === "finish_with_mitigation" ? 1 : 0
                acc.finishWithMitigationAndRemediation += hierarchy.status === "finish_with_mitigation_and_remediation" ? 1 : 0
                acc.finishWithoutRecommendation += hierarchy.status === "finish_without_recommendation" ? 1 : 0
                return acc
            },
            {
                waitingForCafAssessment: 0,
                underAssessment: 0,
                waitingForRemediation: 0,
                finishWithRemediation: 0,
                finishWithMitigation: 0,
                finishWithMitigationAndRemediation: 0,
                finishWithoutRecommendation: 0
            }
        )
        : {
            waitingForCafAssessment: 0,
            underAssessment: 0,
            waitingForRemediation: 0,
            finishWithRemediation: 0,
            finishWithMitigation: 0,
            finishWithMitigationAndRemediation: 0,
            finishWithoutRecommendation: 0
        }

    useEffect(() => {
        console.log("count", counts)
        console.log("value", value)
    }, [counts])
    return (
        <HierarchyStatusIndicator
            waitingForCafAssessment={counts.waitingForCafAssessment}
            underAssessment={counts.underAssessment}
            waitingForRemediation={counts.waitingForRemediation}
            finishWithRemediation={counts.finishWithRemediation}
            finishWithMitigation={counts.finishWithMitigation}
            finishWithMitigationAndRemediation={counts.finishWithMitigationAndRemediation}
            finishWithoutRecommendation={counts.finishWithoutRecommendation}
            hierarchies={value}
        />
    )
}

export { SnapshotFleetStatus }
export default SnapshotFleetStatus
