import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useNavigate, useParams } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import Vulnerability, { PentestMetadata, VulnerabilityState } from "@models/Vulnerability"
import "./PentestForm.css"
import { EditorState } from "draft-js"
import ComponentSelector from "@components/component/ComponentSelector"
import { useTrack } from "@components/track/TrackContext"
import ReferenceGrid from "./component/ReferenceGrid"
import MetadataGrid from "./component/MetadataGrid"
import ComponentDetailsGrid from "./component/CMPDetailGrid"
import CWEGrid from "./component/CWEGrid"
import CustomEditor from "./component/CustomEditor"

const initValue: Vulnerability = {
    id: "",
    identifier: "",
    score: 0,
    revised_score: 0,
    priority: 0,
    epss: 0,
    percentile: -1,
    kev: false,
    supressed: false,
    vector: "",
    description: "",
    state: VulnerabilityState.Detected,
    revised_severity: 0,
    time_spent: 1,
    assessment: { id: "" },
    product: { id: "" },
    client: { id: "" },
    origin: "Pentest",
    affected_component: "",
    solution: "",
    references: "",
    rank: 0
}

const PentestForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const assessmentService = useContext(ServicesContext).assessmentService
    const { id } = useParams<{ id: string }>()
    const fetchData = async () => {
        try {
            const data = await assessmentService.get(id as string)
            setFormData((prevFormData) => ({
                ...prevFormData,
                assessment: { id: data.id },
                product: { id: data.product?.id || "" },
                client: { id: data.client?.id || "" }
            }))
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    // States
    const [formData, setFormData] = useState<Vulnerability>(initValue)
    const [references, setReferences] = useState<string>("")
    const [CWE, setCWE] = useState<number[]>([])
    const [metadata, setMetadata] = useState<Record<string, string>>({})
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [components, setComponents] = useState<Array<{ name: string; version: string }>>([])
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "PentestForm" })
    }, [])
    const navigate = useNavigate()
    // Functions
    const handleChange = (e: any, fieldName?: string) => {
        const { name, value } = e.target
        const updatedValue = fieldName === "score" ? parseInt(value, 10) : value
        setFormData({ ...formData, [name]: updatedValue })
    }
    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            cmp_name: components.map((component) => component.name).join(", "),
            cmp_version: components.map((component) => component.version).join(", ")
        }))
    }, [components])

    const formValid = (): boolean => {
        const isNotNullRules = [
            formData.vector === "",
            formData.origin === "",
            formData.solution === "",
            formData.description === ""
        ]
        return !isNotNullRules.some((rule) => rule)
    }
    useEffect(() => {
        console.log(editorState.getCurrentContent())
    }, [editorState])
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        console.log(formData)

        try {
            await vulnerabilityService.create(formData)
            setFormData(initValue)
            setSnackbarMessage("Pentest added successfully")
            setEditorState(EditorState.createEmpty())
            navigate("../")
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarMessage(e.error)
            setSnackbarOpen(true)
        }
    }
    useEffect(() => {
        console.log(formData)
    }, [formData])
    const handleSave = (content: string) => {
        // Guardar el contenido del editor en el campo 'description'
        setFormData((prevFormData) => ({
            ...prevFormData,
            description: content
        }))
    }

    return (
        <Grid sx={{ paddingLeft: "20px" }} container gap={2}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography
                    color={theme.palette.text.secondary}
                    fontSize="45px"
                    fontWeight="bolder"
                    fontFamily="Griff"
                >
        Pentest Assessment
                </Typography>
            </Grid>
            <StyledBox>
                <Typography
                    sx={{
                        fontSize: "27px",
                        fontFamily: "Griff",
                        color: theme.palette.primary.contrastText
                    }}
                >
          Create the list of evidences you want to add in the Pentest
                </Typography>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || snackbarMessage}
                />

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            variant="standard"
                            label="Origin"
                            name="origin"
                            value="Pentest"
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            variant="standard"
                            label="Identifier"
                            name="identifier"
                            value={formData.identifier}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            margin="normal"
                            required
                            variant="standard"
                            label="CVSS"
                            name="vector"
                            value={formData.vector}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            margin="normal"
                            required
                            variant="standard"
                            label="Score"
                            name="score"
                            type="number"
                            value={formData.score}
                            inputProps={{ min: 0, max: 10 }}
                            onChange={(e) => handleChange(e, "score")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ComponentSelector value={formData.affected_component || ""} onSelect={(componentId) => {
                            setFormData({ ...formData, affected_component: componentId })
                        }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Description
                        </Typography>
                        {/* <TextField
                            margin="normal"
                            required
                            multiline
                            rows={4}
                            variant="standard"
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            fullWidth
                        /> */}
                        <CustomEditor
                            initialContent={formData.description}
                            onSave={handleSave}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Solution
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            multiline
                            rows={4}
                            variant="standard"
                            label="Solution"
                            name="solution"
                            value={formData.solution}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize="27px" fontFamily="Griff" color="primary" fontWeight="bolder">
                            References
                        </Typography>
                        <ReferenceGrid
                            initialReference={references}
                            onChange={(updatedReferences) => {
                                setReferences(updatedReferences)
                                setFormData({ ...formData, references: updatedReferences })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize="27px" fontFamily="Griff" color="primary" fontWeight="bolder">
                            Metadata
                        </Typography>
                        <Grid>
                            <MetadataGrid
                                metadataList={metadata}
                                setMetadataList={(updatedMetadata) => {
                                    const pentestMetadata: PentestMetadata = {
                                        name: updatedMetadata.name || "",
                                        actives: updatedMetadata.actives || ""
                                    }

                                    setMetadata(pentestMetadata)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        metadata: {
                                            type: "Pentest",
                                            data: pentestMetadata
                                        }
                                    }))
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize="27px" fontFamily="Griff" color="primary" fontWeight="bolder">
                            CWE
                        </Typography>
                        <CWEGrid
                            initialReferences={CWE}
                            onChange={(updatedCWE) => {
                                setCWE(updatedCWE)
                                setFormData({ ...formData, cwe: updatedCWE })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize="27px" fontFamily="Griff" color="primary" fontWeight="bolder">
        Component Details
                        </Typography>
                        <ComponentDetailsGrid
                            components={components}
                            setComponents={setComponents}
                        />
                    </Grid>

                </Grid>
            </StyledBox>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }}
                disabled={!formValid()}
            >
        Add New Evidence
            </Button>

        </Grid>
    )
}

export default PentestForm
