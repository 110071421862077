import { AssessmentType } from "@models/Assessment"

export const AssessmentTypeColors: Record<AssessmentType, string> = {
    crt: "#42A5F5", // aquamarine
    logical_pentest: "#FF5722", // jungle green
    functional_test: "#4370a4", // blue iris
    sca: "#ec971f", // lightpink
    port_scan: "#FF5733", // neon orange
    network_scan: "#bf88c8", // amaranth
    web_scan: "#288d43", // sage green
    container_scan: "#8D6E63", // taupe
    threat_model: "#607D8B", // blue-grey
    static_analysis: "#9E9E9E", // neutral grey
    dynamic_analysis: "#BDB76B" // dark khaki
}
