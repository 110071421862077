import React, { useContext, useEffect, useState } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Vulnerability, { getCVSS3Criticality } from "@models/Vulnerability"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"
import ProgressCircle from "./ProgressCircle"

const severityMap: { [key: string]: keyof SeverityCount } = {
    critical: "critical",
    high: "high",
    medium: "medium",
    low: "low"
}

interface SeverityCount {
    critical: number;
    high: number;
    medium: number;
    low: number;
    undefined: number;
  }

// const suggestion = {
//     critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, these vulnerabilities could trigger a cybersecurity breach." },
//     high: { color: "#D9534F", label: "HIGH", subtext: ", which, if not addressed in time, these vulnerabilities could trigger a cybersecurity breach." },
//     medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, these vulnerabilities could trigger a cybersecurity breach." },
//     low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, these vulnerabilities could trigger a cybersecurity breach." },
//     undefined: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
// }

const i18n = {
    es: {
        title_summary: "Resumen Ejecutivo",
        summary1: "El objetivo de la evaluación es utilizar nuestra metodología de análisis para identificar las vulnerabilidades del sistema y evaluar su nivel de ciberseguridad. La evaluación proporciona información sobre los mecanismos de seguridad de la aplicación para resistir ataques de usuarios no autorizados y el potencial de los usuarios válidos para abusar de sus privilegios y accesos. El siguiente resumen es un enfoque condensado de los principales hallazgos. Durante el transcurso del ejercicio, el número de vulnerabilidades descubiertas fue",
        critical: "crítica",
        high: "alta",
        medium: "media",
        low: "baja",
        and: "y",
        summary2: "El estado actual de las vulnerabilidades identificadas establece el riesgo en un nivel",
        summary3: "Una descripción más detallada de las vulnerabilidades se puede encontrar en la sección",
        summary4: "Identificación de vulnerabilidades",
        summary5: "donde se describe cada una de ellas.",
        vulnereabilities: "Vulnerabilidades:",
        criticals: "CRÍTICA",
        highs: "ALTA",
        mediums: "MEDIA",
        lows: "BAJA",
        state: "El estado actual de las vulnerabilidades identificadas establece el riesgo en un nivel",
        suggestion: {
            critical: { color: "black", label: "CRÍTICA", subtext: ", que, si no se abordan lo antes posible, podrían provocar una brecha de ciberseguridad." },
            high: { color: "#D9534F", label: "ALTA", subtext: ", que, si no se abordan a tiempo, podrían provocar una brecha de ciberseguridad." },
            medium: { color: "#FF8537", label: "MEDIA", subtext: ", que, si no se corrigen, podrían provocar una brecha de ciberseguridad." },
            low: { color: "#A8D1E7", label: "BAJA", subtext: ", que, si no se evalúan más a fondo, podrían provocar una brecha de ciberseguridad." },
            undefined: { color: "grey", label: "DESCONOCIDA", subtext: ", y se recomienda un análisis más detallado." }
        }
    },
    en: {
        title_summary: "Executive Summary",
        summary1: "The objective of the evaluation is to use our analysis methodology to identify system vulnerabilities and evaluate its level of cybersecurity. The assessment provides insight into the application`&apos;`s security mechanisms to resist attacks from unauthorized users and the potential for valid users to abuse their privileges and access. The summary below is a condensed approach to the main findings. During the course of the exercise, the number of vulnerabilities discovered was",
        critical: "critical",
        high: "high",
        medium: "medium",
        low: "low",
        and: "and",
        summary2: "The current state of the identified vulnerabilities establishes the risk at a level",
        summary3: "A more detailed description of the vulnerabilities can be found in the section",
        summary4: "Identification of vulnerabilities",
        summary5: "where each of them is described.",
        vulnereabilities: "Vulnerabilities:",
        criticals: "CRITICAL",
        highs: "HIGH",
        mediums: "MEDIUM",
        lows: "LOW",
        state: "The current state of the identified vulnerabilities establishes the risk at a level",
        suggestion: {
            critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, these vulnerabilities could trigger a cybersecurity breach." },
            high: { color: "#D9534F", label: "HIGH", subtext: ", which, if not addressed in time, these vulnerabilities could trigger a cybersecurity breach." },
            medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, these vulnerabilities could trigger a cybersecurity breach." },
            low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, these vulnerabilities could trigger a cybersecurity breach." },
            undefined: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
        }

    }
} as const
const ExecutiveSection: React.FC<{ index: string, vulnerabilities:Vulnerability[], template?: Template, lang?: string }> = ({ index, vulnerabilities, template, lang }) => {
    const [severityCount, setSeverityCount] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, undefined: 0 })
    const [severityCountGraph, setSeverityCountGraph] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, undefined: 0 })
    const [severityLevel, setSeverityLevel] = useState<keyof SeverityCount>(severityMap.critical)
    const [empty, setEmpty] = useState<boolean>(false)
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    useEffect(() => {
        const newSeverityCount: SeverityCount = { ...severityCount }

        let worstScore = -1
        let worstCriticity = severityMap.undefined
        vulnerabilities.forEach((vuln) => {
            const rating = { score: vuln.score, severity: getCVSS3Criticality(vuln.score) }
            if (rating && rating.severity && rating.severity in severityMap) {
                newSeverityCount[severityMap[rating.severity]]++
                if (worstScore < (rating?.score || 0)) {
                    worstScore = rating?.score || 0
                    worstCriticity = severityMap[rating.severity]
                }
            }
        })
        setSeverityCount(newSeverityCount)
        setSeverityLevel(worstCriticity)

        const scaleFactor = (newSeverityCount.critical + newSeverityCount.high + newSeverityCount.medium + newSeverityCount.low === 0 ? 0 : (10 / (newSeverityCount.critical + newSeverityCount.high + newSeverityCount.medium + newSeverityCount.low)))
        setSeverityCountGraph({
            critical: newSeverityCount.critical * scaleFactor,
            high: newSeverityCount.high * scaleFactor,
            medium: newSeverityCount.medium * scaleFactor,
            low: newSeverityCount.low * scaleFactor,
            undefined: 0
        })
    }, [vulnerabilities])

    useEffect(() => {
        if (Object.values(severityCountGraph).map(severity => severity / 10).filter(p => p !== 0).length === 1) {
            setEmpty(true)
        }
    }, [severityCountGraph])

    return (<Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
        <Header template={template}></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{index}. {i18n[locale].title_summary}</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].summary1} <Text style={{ fontFamily: "Bold" }}>{ severityCount.critical } {i18n[locale].critical}</Text>, <Text style={{ fontFamily: "Bold", color: "#7940A5" }}>{ severityCount.high } {i18n[locale].high}</Text>, <Text style={{ fontFamily: "Bold", color: "#FF8537" }}>{ severityCount.medium } {i18n[locale].medium}</Text> {i18n[locale].and} <Text style={{ fontFamily: "Bold", color: "#A8D1E7" }}>{ severityCount.low } {i18n[locale].low}</Text>.</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].summary2} <Text style={{ fontFamily: "Bold", color: i18n[locale].suggestion[severityLevel].color }}>{i18n[locale].suggestion[severityLevel].label}</Text>{i18n[locale].suggestion[severityLevel].subtext}</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].summary3} <Text style={{ fontFamily: "Bold" }}>{i18n[locale].summary4}</Text> {i18n[locale].summary5}</Text>
            </View>
            <View style={styles.column}>
                <View style={{ ...styles.rightImage, marginTop: -40, height: 700, marginLeft: 30, marginRight: -20, paddingTop: -200, marginBottom: -100, backgroundColor: "#271968", flexDirection: "row", color: "#fff" }}>
                    <View style={{ ...styles.column, top: 50, color: "#fff" }}>
                        {/* <Image style={{ ...styles.rightImage, marginLeft: 20, paddingTop: 0, marginTop: 39.5, height: 90, width: 48 }} src="/assets/img/report/ColourArrows3.jpg" /> TODO: Change this image into a canvas */}
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Bold", fontSize: 16 }}>{i18n[locale].vulnereabilities} </Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.critical }</Text> {i18n[locale].criticals}</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.high }</Text> {i18n[locale].highs}</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.medium }</Text> {i18n[locale].mediums}</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.low }</Text> {i18n[locale].lows}</Text>
                        <Text style={{ marginLeft: 70, marginRight: 0, paddingTop: 20, marginBottom: 200, paddingRight: 30, textAlign: "left", fontFamily: "Griff", fontSize: 15 }}>{i18n[locale].state} <Text style={{ fontFamily: "Bold", color: i18n[locale].suggestion[severityLevel].color }}>{i18n[locale].suggestion[severityLevel].label} </Text></Text>
                        <ProgressCircle Low = {severityCountGraph.low} Medium = {severityCountGraph.medium} High = {severityCountGraph.high} Critical = {severityCountGraph.critical} Unknown = {0} None={0} Empty={empty} ></ProgressCircle>
                    </View>
                </View>
            </View>
        </View>
        <Footer externalData={null} template={template} />
    </Page>)
}

export { ExecutiveSection }
