import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import Tooltip from "@mui/material/Tooltip"
import { CafVulnStatus } from "@models/index"
import { CafHierarchyStatus, getCafHierarchyStatusColor, HierarchyCAFStatusKeysValueOps } from "@models/Hierarchy"
import { useTheme } from "@mui/material"

interface ColorAndBg {
    color: string;
    "background-color": string;
}

// Función para determinar la categoría del estado
const getCategory = (state: CafVulnStatus): string => {
    if (state === CafVulnStatus.LowRisk || state === CafVulnStatus.WaitingForTriage) {
        return "Detected"
    } else if (state === CafVulnStatus.WaitingSupplierAssessment || state === CafVulnStatus.WaitingCafAssessment) {
        return "In Triage"
    }
    return "Triage Finished"
}

interface VulnerabilityStateColoredProps {
    value: CafHierarchyStatus;
}

const HierarchyStatusColored: React.FC<VulnerabilityStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<string | null>(null)
    const [label, setLabel] = useState<string | undefined>("")
    const theme = useTheme()
    if (!Object.values(CafHierarchyStatus).includes(value)) {
        return null // Si no es un CafVulnStatus válido, no renderizamos nada
    }
    useEffect(() => {
        setLabel(HierarchyCAFStatusKeysValueOps.find(v => v.value === value)?.label)
        setColor(getCafHierarchyStatusColor(value))
        console.log("valueeee", value)
    }, [value])

    return (
        <Tooltip title={value} arrow>
            <Chip
                size="small"
                style={{
                    fontWeight: "bolder",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    color: theme.palette.secondary.contrastText,
                    backgroundColor: color || theme.palette.background.default
                }}
                label={label}
            />
        </Tooltip>
    )
}

export { HierarchyStatusColored }
export default HierarchyStatusColored
