import Policy from "@models/Policy"
import Snapshot from "@models/Snapshot"
import { ModelDefinition } from "@models/utils"
import { CafListNavigator } from "@components/vulnerability/caf"
import SnapshotDate from "./SnapshotDate"
import SnapshotProductNameNavigate from "./SnapshotProduct"
import SnapshotProductVulns from "./SnapshotProductVulns"
import SnapshotFleetStatus from "./SnapshotFleetStatus"

const SnapshotTableDefinition: ModelDefinition<Snapshot> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        {
            name: "supplier",
            label: "Suppliers",
            type: "string",
            flex: 0.5,
            minWidth: 220,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.supplier ?? []}
                    labelKey="name"
                    type="provider"
                />
            )
        },
        { name: "product", label: "Product", type: "struct", flex: 1, render: SnapshotProductNameNavigate },
        { name: "product_vulns", label: "Product vulns", type: "struct", flex: 1, render: SnapshotProductVulns },
        { name: "fleet_assessment", label: "Fleet assessment", type: "struct", flex: 1, render: SnapshotFleetStatus },
        { name: "snapshot_day", label: "Date", type: "string", flex: 1, render: SnapshotDate }
    ]
}
const SnapshotTableDefinitionES: ModelDefinition<Policy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "supplier", label: "Proveedor", type: "string", flex: 2 },
        { name: "sw_name", label: "Product name", type: "string", flex: 2 },
        { name: "sw_version", label: "Product version", type: "string", flex: 2 }

    ]
}

export { SnapshotTableDefinitionES, SnapshotTableDefinition }
export default SnapshotTableDefinition
