import React, { useContext, useEffect, useState } from "react"
import Joyride, { CallBackProps, EVENTS } from "react-joyride"
import { useAppContext } from "@components/walkthrough/WalkthroughProvider"
import ServicesContext from "@context/ServicesContext"

const JoyrideTour: React.FC<{ navigate: (path: string) => void }> = ({ navigate }) => {
    const [firstLogin, setFirstLogin] = useState<boolean | null>(null)
    const { state, setState } = useAppContext()
    const [clientId, setClientId] = useState<string>("")
    const [userId, setUserId] = useState<string>("")
    const clientService = useContext(ServicesContext).clientService
    const walkthroughService = useContext(ServicesContext).walkthroughService
    const [isLastStep, setIsLastStep] = useState(false)

    useEffect(() => {
        console.log(firstLogin)
        setState((prev) => ({
            ...prev,
            run: firstLogin === true // Keeps the tour running
        }))
    }, [location.pathname, firstLogin])

    useEffect(() => {
        const fetchClient = async () => {
            try {
                const response = await clientService.getAll()
                setClientId(response.list[0].id)
            } catch (error: any) {
                console.log(error)
            }
        }
        fetchClient()
    }, [])

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const users = await clientService.getUsers(clientId)
                console.log(users.list[0].id)
                setUserId(users.list[0].id)
            } catch (e: any) {
                console.log(e)
            }
        }
        fetchUserId()
    })

    useEffect(() => {
        if (!userId) return
        const fetchLogin = async () => {
            try {
                const response = await walkthroughService.getFirstLogin(userId as string)// corregir a "get()"
                setFirstLogin(response)
            } catch (error) {
                console.error("Error fetching firstLogin:", error)
            }
        }

        fetchLogin()
    }, [userId])

    const handleCallback = (data: CallBackProps) => {
        const { action, step, type } = data
        const isPreviousAction = action === "prev"
        const next = step?.data?.next || ""
        const previous = step?.data?.previous || ""
        setIsLastStep(state.stepIndex === state.steps.length - 1)

        console.log(firstLogin)

        if (type === EVENTS.STEP_AFTER) {
            setState((prev) => ({
                ...prev,
                run: false,
                stepIndex: prev.stepIndex + (isPreviousAction ? -1 : 1)
            }))

            if (isPreviousAction && previous) {
                if (previous.startsWith("/")) {
                    navigate(previous)
                }
            } else if (next) {
                if (next.startsWith("/")) {
                    navigate(next)
                }
            }
            setState((prev) => ({ ...prev, run: true }))
        }
    }

    useEffect(() => {
        if (isLastStep) {
            const updateLogin = async () => {
                try {
                    await walkthroughService.updateFirstLogin(userId)
                    console.log(firstLogin)
                } catch (error) {
                    console.error("Error updating firstLogin:", error)
                }
            }
            updateLogin()
        }
    }, [isLastStep, userId, firstLogin])

    return (
        <Joyride
            callback={handleCallback}
            continuous
            run={state.run}
            stepIndex={state.stepIndex}
            steps={state.steps}
            showProgress // the amount of steps of the tour are visible
            showSkipButton // gives the user the ability to skip the tour
        />
    )
}

export default JoyrideTour
