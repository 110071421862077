import { CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import HelpIcon from "@mui/icons-material/Help"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import SAST from "@models/SAST"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { I18nContext } from "I18nProvider"

const SASTDetail = () => {
    const theme = useTheme()
    const { id } = useParams()
    const sastService = useContext(ServicesContext).SASTService
    const [data, setData] = useState<SAST | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const result = await sastService.get(id)
                    setData(result)
                }
            } catch (e: any) {
                setError(e.message)
                setSnackbarOpen(true)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [id])

    if (loading) {
        return <CircularProgress />
    }

    if (!data) {
        return <Typography variant="h6" color="error">No data available</Typography>
    }

    return (
        <Grid item xs container spacing="20px" >
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error || context.t.translate("an_error")}
                    severity="error"
                />
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    SAST Details
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <StyledBox>
                            <Grid container spacing={3} padding="20px" flexDirection="column">
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">{context.t.translate("name")}</Typography>
                                        <TextField fullWidth variant="standard" value={data.name || "N/A"} disabled />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary" style={{ display: "flex", alignItems: "center" }}>
        Category
                                            <Tooltip
                                                title={
                                                    <TableContainer component={Paper} sx={{ maxWidth: 300 }}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontSize: "0.75rem", padding: "4px" }}>Category</TableCell>
                                                                    <TableCell sx={{ fontSize: "0.75rem", padding: "4px" }}>Description</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {[
                                                                    { category: "buffer", desc: context.t.translate("buffer_desc") },
                                                                    { category: "format", desc: context.t.translate("format_desc") },
                                                                    { category: "integer", desc: context.t.translate("integer_desc") },
                                                                    { category: "macros", desc: context.t.translate("macros_desc") },
                                                                    { category: "misc", desc: context.t.translate("misc_desc") },
                                                                    { category: "race", desc: context.t.translate("race_desc") },
                                                                    { category: "pointer", desc: context.t.translate("pointer_desc") }
                                                                ].map((row) => (
                                                                    <TableRow key={row.category}>
                                                                        <TableCell sx={{ fontSize: "0.7rem", padding: "4px" }}>{row.category}</TableCell>
                                                                        <TableCell sx={{ fontSize: "0.7rem", padding: "4px" }}>{row.desc}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                }
                                                arrow
                                                placement="right"
                                            >
                                                <IconButton size="small">
                                                    <HelpIcon fontSize="small" color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        <TextField fullWidth variant="standard" value={data.category || "N/A"} disabled />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Typography variant="h6" fontWeight="bold" color="primary" style={{ display: "flex", alignItems: "center" }}>
                                            {context.t.translate("severity")}
                                            <Tooltip
                                                title={
                                                    <TableContainer component={Paper} sx={{ maxWidth: 250 }}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontSize: "0.75rem", padding: "4px" }}>Level</TableCell>
                                                                    <TableCell sx={{ fontSize: "0.75rem", padding: "4px" }}>Severity</TableCell>
                                                                    <TableCell sx={{ fontSize: "0.75rem", padding: "4px" }}>Description</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {[
                                                                    { level: 0, sev: context.t.translate("sev_0"), desc: context.t.translate("desc_0") },
                                                                    { level: 1, sev: context.t.translate("sev_1"), desc: context.t.translate("desc_1") },
                                                                    { level: 2, sev: context.t.translate("sev_2"), desc: context.t.translate("desc_2") },
                                                                    { level: 3, sev: context.t.translate("sev_3"), desc: context.t.translate("desc_3") },
                                                                    { level: 4, sev: context.t.translate("sev_4"), desc: context.t.translate("desc_4") },
                                                                    { level: 5, sev: context.t.translate("sev_5"), desc: context.t.translate("desc_5") }
                                                                ].map((row) => (
                                                                    <TableRow key={row.level}>
                                                                        <TableCell sx={{ fontSize: "0.7rem", padding: "4px" }}>{row.level}</TableCell>
                                                                        <TableCell sx={{ fontSize: "0.7rem", padding: "4px" }}>{row.sev}</TableCell>
                                                                        <TableCell sx={{ fontSize: "0.7rem", padding: "4px" }}>{row.desc}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                }
                                                arrow
                                                placement="right"
                                            >
                                                <IconButton size="small">
                                                    <HelpIcon fontSize="small" color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        <TextField fullWidth variant="standard" value={data.level || "N/A"} disabled />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">Rule ID</Typography>
                                        <TextField fullWidth variant="standard" value={data.rule_id || "N/A"} disabled />
                                    </Grid>

                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">CWE</Typography>
                                        <TextField fullWidth variant="standard" value={data.cwe?.join(", ") || "N/A"} disabled />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">{context.t.translate("file")}</Typography>
                                        <TextField fullWidth variant="standard" value={data.file || "N/A"} disabled />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">URI</Typography>
                                        <TextField fullWidth variant="standard" value={data.help_uri || "N/A"} disabled />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">{context.t.translate("description")}</Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            variant="standard"
                                            value={data.suggestion || "No snippet available"}
                                            disabled
                                            sx={{
                                                "& .MuiInput-root.Mui-disabled:before": {
                                                    borderBottomStyle: "solid",
                                                    borderBottomColor: "rgba(0, 0, 0, 0.42) !important"
                                                },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    color: "#271968 !important",
                                                    "-webkit-text-fill-color": "#271968 !important",
                                                    opacity: "1 !important"
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="h6" fontWeight="bold" color="primary">Region Details</Typography>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Start Line</TableCell>
                                                        <TableCell>Start Column</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{data.line || "N/A"}</TableCell>
                                                        <TableCell>{data.column || "N/A"}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid></Grid>
            </Grid>
        </Grid>
    )
}

export default SASTDetail
