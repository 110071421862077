import { StyledBox } from "@components/common/Box/BoxContainer"
import ActionButton from "@components/common/Button/ActionButton"
import GenericModal from "@components/common/modals/GenericModal"
import Vulnerability from "@models/Vulnerability"
import { Button, Divider, Grid, Step, StepLabel, Stepper, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { useContext, useState } from "react"

interface Instance {
    Titles: string;
    Details?: string;
    Severity?: string;
    Confidence?: string;
    Solutions?: string;
}

interface MetadataProps {
    formData: Vulnerability;
}

const Metadata = ({ formData }: MetadataProps) => {
    const theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [openInstances, setOpenInstances] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [modalTitle, setModalTitle] = useState("")
    const [activeStep, setActiveStep] = useState(0)

    const formatKey = (key: string) => {
        return key
            .replace(/_/g, " ")
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/\b\w/g, (char: string) => char.toUpperCase())
    }

    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    if (!formData.metadata) {
        return null
    }

    const handleOpenModal = (value: string, key: string) => {
        setModalContent(value)
        setModalTitle(formatKey(key))
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setModalContent("")
        setModalTitle("")
    }

    const renderValue = (value: any) => {
        if (typeof value === "string" || typeof value === "number") {
            return value
        } else if (Array.isArray(value)) {
            return value.join(", ")
        } else if (typeof value === "object") {
            return JSON.stringify(value, null, 2)
        }
        return "N/A"
    }

    const instances: Instance[] = formData.metadata?.type === "Burp" ? formData.metadata.data.instances ?? [] : []

    const hasInstances = formData.origin === "Burp" && instances.length > 0

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>

                    <StyledBox>
                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                            {formData.origin}
                        </Typography>
                        <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main, marginBottom: "10px" }} />
                        </Grid>

                        {hasInstances && (
                            <Grid item xs={12} sx={{ marginTop: "20px" }}>

                                <ActionButton
                                    onClick={() => setOpenInstances(true)}
                                    text="View Instances"
                                />
                            </Grid>
                        )}

                        <Grid item container flexDirection="row" spacing="35px">
                            {Object.entries(formData.metadata.data || {}).map(([key, value]) => {
                                if (key === "instances") return null

                                const formattedValue = String(renderValue(value))
                                const isLongText = formattedValue.length >= 47

                                return (
                                    <Grid item md={3} xs={12} key={key} sx={{ overflowY: "hidden" }}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {formatKey(key)}
                                        </Typography>
                                        <Grid>
                                            <Tooltip title={!isLongText ? formattedValue : ""} arrow>
                                                <TextField
                                                    name={key}
                                                    disabled
                                                    value={isLongText ? formattedValue.slice(0, 47) + "..." : formattedValue}
                                                    variant="standard"
                                                    rows={2}
                                                    maxRows={2}
                                                    style={{
                                                        fontFamily: "Griff",
                                                        color: theme.palette.secondary.main,
                                                        overflowY: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis"
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>

                                        <Grid>
                                            {isLongText && (
                                                <Button
                                                    onClick={() => handleOpenModal(formattedValue, key)}
                                                    sx={{ mt: 1 }}
                                                    variant="outlined"
                                                >
                                                View More
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>

            {/* Modal para visualizar las instancias con Stepper */}
            <GenericModal open={openInstances} onClose={() => setOpenInstances(false)} title="Instances">
                {hasInstances && (
                    <>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {instances.map((instance, index) => (
                                <Step key={index}>
                                    <StepLabel>{instance.Titles}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <StyledBox sx={{
                            marginTop: "20px",
                            padding: "15px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            backgroundColor: theme.palette.background.default
                        }}>
                            <Typography fontSize="22px" fontWeight="bold" color="primary">
                                {instances[activeStep].Titles}
                            </Typography>
                            <Divider sx={{ marginY: "10px" }} />
                            <Typography fontSize="16px">{instances[activeStep].Details}</Typography>
                            <Typography fontSize="18px" fontWeight="bold" color="yellow" sx={{ marginTop: "10px", display: "block" }}>
    Severity:
                            </Typography>
                            <Typography component="span" fontSize="16px" >{instances[activeStep].Severity}</Typography>
                            <Typography fontSize="18px" fontWeight="bold" color="yellow" sx={{ display: "block" }}>
    Confidence:
                            </Typography>
                            <Typography component="span" fontSize="16px" >{instances[activeStep].Confidence}</Typography>
                            <Typography fontSize="18px" fontWeight="bold" color="yellow" sx={{ marginTop: "10px", display: "block" }}>
    Solution:
                            </Typography>
                            <Typography component="span" fontSize="16px">{instances[activeStep].Solutions}</Typography>
                            <Grid container justifyContent="space-between" sx={{ marginTop: "15px" }}>
                                <Button
                                    variant="outlined"
                                    disabled={activeStep === 0}
                                    onClick={() => setActiveStep(prev => prev - 1)}
                                >
                                    Previous
                                </Button>
                                <ActionButton
                                    variant="contained"
                                    text="Next"
                                    disabled={activeStep === instances.length - 1}
                                    onClick={() => setActiveStep(prev => prev + 1)}
                                />
                            </Grid>
                        </StyledBox>
                    </>
                )}

            </GenericModal>
            <GenericModal
                open={openModal}
                onClose={handleCloseModal}
                title={modalTitle}
            >
                <Typography>{modalContent}</Typography>
            </GenericModal>
        </Grid>
    )
}

export default Metadata
