import { AssessmentStatus, Client, ClientSector } from "@models/index"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import User from "@models/User"
import ClientSectorColored from "./ClientSectorColored"
import ClientNameNavigate from "./ClientNameNavigate"

const ClientSectorKeys = getEnumKeys(ClientSector)
const ClientSectorKeysValueOps = ClientSectorKeys.map((key) => ({ label: key, value: ClientSector[key] }))

const AssessmentStatusKeys = getEnumKeys(AssessmentStatus)
const AssessmentStatusKeysValueOps = AssessmentStatusKeys.map((key) => ({ label: key, value: AssessmentStatus[key] }))

const ClientTableDefinition: ModelDefinition<Client> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.4, render: ClientNameNavigate },
        { name: "description", label: "Description", flex: 1, type: "string" },
        { name: "sector", label: "Sector", type: "singleSelect", flex: 0.3, allowedValueOptions: ClientSectorKeysValueOps, render: ClientSectorColored },
        { name: "license_validation_date", label: "License Validation Date", type: "string", flex: 0.2 }
    ]
}

const UserTableDefinition: ModelDefinition<User> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "username", label: "Username", type: "string", flex: 0.4 },
        { name: "email", label: "Email", flex: 1, type: "string" },
        { name: "groups", label: "Groups", flex: 1, type: "string" },
        { name: "last_login", label: "Last login", flex: 1, type: "string" }
    ]
}

export { ClientTableDefinition, ClientSectorKeysValueOps, AssessmentStatusKeysValueOps, UserTableDefinition }
export default ClientTableDefinition
