
import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import SolidGauge from "highcharts/modules/solid-gauge"
import { Grid, useTheme } from "@mui/material"

type GaugeChartProps = {
    temporal: number
};

HighchartsMore(Highcharts)
SolidGauge(Highcharts)

const TemporalColorGauge: React.FC<GaugeChartProps> = ({ temporal }) => {
    const theme = useTheme()
    const getLabel = (value: number) => {
        if (value < 5) {
            return "Bad"
        } else if (value < 7) {
            return "Good"
        }
        return "Very Good"
    }
    const options = {
        chart: {
            type: "solidgauge",
            backgroundColor: theme.palette.background.paper,
            margin: [0, 0, 0, 0],
            spacingTop: 0, // Quitar el espacio superior
            spacingBottom: 0 // Quitar el espacio inferior
        },
        title: null,
        pane: {
            center: ["50%", "70%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: "#ccc",
                innerRadius: "60%",
                outerRadius: "100%",
                shape: "arc",
                borderWidth: 0
            }
        },
        yAxis: {
            min: 0,
            max: 10,
            stops: [
                [temporal! / 10, "#EF7A24"] // exploitability
            ],
            tickInterval: 2.5, // Intervalo de ticks principales
            minorTickInterval: "auto", // Intervalo de ticks menores (opcional)
            minorTickWidth: 1,
            minorTickLength: 10,
            minorTickZIndex: 15,
            minorTickPosition: "inside",
            minorTickColor: "white",
            lineWidth: 0,
            tickWidth: 2,
            tickPosition: "inside",
            tickLength: 15,
            tickColor: "white",
            tickAmount: 5,
            tickZIndex: 15,
            zIndex: 100,
            title: {
                y: -70
            },
            labels: {
                y: 16,
                style: {
                    color: theme.palette.primary.main,
                    textOutline: "none"
                },
                zIndex: 12 // asegurar que las etiquetas estén encima del gráfico
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 10,
                    borderWidth: 0,
                    useHTML: true,
                    style: {
                        color: theme.palette.primary.main, // color de los data labels,
                        fontSize: "10px",
                        fontFamily: "Griff",
                        fontWeight: "bolder",
                        zIndex: 12
                    }
                }
            }
        },
        series: [
            {
                name: "Temporal",
                data: [temporal],
                dataLabels: {
                    format: `<div style="text-align:center; margin-top: -90px; margin-bottom: 20px">
                   <span style="font-size:55px;color:${theme.palette.primary.main}">{y}</span><br/>
                                  <span style="font-size:14px;color:${theme.palette.primary.main}">${getLabel(temporal)}</span><br/>
                                <div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-top: 10px; background-color: #EF7A24; margin-right: 5px;"></div>
                                <span style="font-size:12px;color:${theme.palette.primary.main}">Impact</span><br/>
                             </div>`
                },
                tooltip: {
                    valueSuffix: " "
                }
            }
        ],
        credits: {
            enabled: false
        },
        legend: {
            enabled: false // deshabilitar la leyenda
        },
        exporting: {
            enabled: false
        }
    }

    const containerStyle = {
        maxHeight: "400px",
        overflow: "hidden",
        maxWidth: "400px"

    }

    return (
        <Grid display="flex" justifyContent="center" style={containerStyle}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Grid>
    )
}
export type { GaugeChartProps }
export default TemporalColorGauge
