import { Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import React from "react"

interface ProductNameNavigateProps {
    value: string;
    id: string;
}

const SnapshotDate: React.FC<ProductNameNavigateProps> = ({ value, id }) => {
    const navigate = useNavigate()
    const location = useLocation()

    // Convertir la fecha a formato DD/MM/YYYY
    const formattedDate = new Date(value).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    })

    return (
        <Typography>
            {formattedDate}
        </Typography>
    )
}

export { SnapshotDate }
export default SnapshotDate
