/* eslint-disable indent */
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { ChangeEvent, forwardRef, Ref, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ClickAwayListener, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip, useTheme } from "@mui/material"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, StatsVulnerabilities, TemplateReportByAssessmentType } from "@models/index"
import { FilterOperation, QueryParameters } from "@utils/index"
import { AbilityContext } from "@components/permissions"
import { I18nContext } from "I18nProvider"
import moment from "moment"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { pdf } from "@react-pdf/renderer"
import { FileTypeByAssessmentType } from "@models/File"
import saveAs from "file-saver"
import { MediaTypes } from "@services/HttpService"
import VulnReportDocumentAchilles from "@components/report/VulnReportDocumentAquilles"
import VulnReportDocument from "@components/report/VulnReportDocument"
import vulnerabilityGenerator from "mockData/vulnerabilityChart"
import { CustomText } from "@models/CustomText"
import { useTrack } from "@components/track/TrackContext"
import achillesTestGenerator from "mockData/achillesTestChart"
import assessmentGenerator from "mockData/assessmentChart"
import { TbAnalyze, TbFileSymlink } from "react-icons/tb"
import ActionButton from "@components/common/Button/ActionButton"
import { MdMoreVert, MdOutlineFileOpen, MdOutlineUploadFile, MdSpeed, MdTableRows } from "react-icons/md"
import { FaRegFileAlt } from "react-icons/fa"
import { BiBug, BiSolidComponent } from "react-icons/bi"
import { GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid"
import AssessmentStateColored from "@components/vulnerability/AssessmentStateColored"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { IoHardwareChip } from "react-icons/io5"
import { RxComponent2 } from "react-icons/rx"
import GenericModal from "@components/common/modals/GenericModal"
import useMediaResponsive from "@hooks/useMobile"
import { StyledTab, StyledTabs } from "@views/product/Components/StyledTabs"
import VulnerabilitiesTable from "./Components/VulnerabilitiesTable"
import AchillesTable from "./Components/AchillesTable"
import HardwareComponentTable from "./Components/HardwareComponentTable"
import DetailsTab from "./Components/AssessmentTab"
import ComponentView from "./ComponentView"

const initValue: Assessment = {
    id: "",
    type: AssessmentType.CRT,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "" },
    client: { id: "" },
    template_id: "00000000-0000-0000-0000-000000000000",
    analysis_status: 0
}

const AssessmentDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const assessmentService = useContext(ServicesContext).assessmentService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const achillesService = useContext(ServicesContext).achillesService
    const statsService = useContext(ServicesContext).statsService
    const fileService = useContext(ServicesContext).fileService
    const templateService = useContext(ServicesContext).templateService
    const [error, setError] = useState<Error | null>(null)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Assessment>(initValue)
    const [formData, setFormData] = useState<Assessment>(initValue)
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[data.type])
    const ability = useContext(AbilityContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [dynamicReport, setDynamicReport] = useState<React.ReactNode | null>(null)
    const { id: assessmentID } = useParams<{ id: string }>()
    const [reportUrl, setReportUrl] = useState<string | null>(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [cols, setCols] = useState<GridColDef[]>([])
    const isMobile = useMediaResponsive("(max-width: 780px)")
    const [customTexts, setCustomTexts] = useState<CustomText>({
        id: "",
        assessment_id: "",
        client_id: "",
        custom_text: {
        }
    })
    const [tabs, setTabs] = useState<{ label: string, content: string, icon?: React.ReactNode }[]>([])
    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const handleApprove = async () => {
        try {
            const updatedAssessment = { ...formData, monitoring: true }

            await assessmentService.update(formData.id, updatedAssessment)

            window.location.reload()
        } catch (error) {
            console.error("Error updating SBOM:", error)
        }
    }
    // Tracking
    const { track, trackData } = useTrack()
    // useEffect(() => {
    //     const newCols = convertModelDefToGridColDef(AssessmentTableDefinition, ability)

    //     newCols.push({
    //         field: "viewSunshine",
    //         headerName: "Reporte SBOM",
    //         width: 150,
    //         renderCell: (params) => (
    //             <IconButton
    //                 onClick={handleViewReport}
    //                 color="primary"
    //                 aria-label="Ver reporte SBOM"
    //             >
    //                 <VisibilityIcon />
    //             </IconButton>
    //         )
    //     })
    //     setCols(newCols)
    // }, [])
    useEffect(() => {
        track({ view: "AssessmentDetail" })
    }, [])
    useEffect(() => {
        const newTabs = []
        newTabs.push({ label: "Details", content: "details", icon: <GridExpandMoreIcon /> })
        if (formData.type === AssessmentType.CRT || formData.type === AssessmentType.LogicalPentest) {
            newTabs.push({ label: "Custom report", content: "custom" })
        }
        newTabs.push({
            label: formData.type === AssessmentType.CRT ? "Achilles" : "Vulnerabilities",
            content: "vulnerabilities",
            icon: <BiBug />
        })
        newTabs.push({ label: "Hardware components", content: "hardware", icon: <IoHardwareChip /> })
        if (formData.type === AssessmentType.SCA && formData.status === AssessmentStatus.Ended) { newTabs.push({ label: "Component", content: "components", icon: <BiSolidComponent /> }) }

        setTabs(newTabs)

        // Si el tabIndex actual no es válido, resetearlo
        if (tabIndex >= newTabs.length) {
            setTabIndex(0)
        }
    }, [formData])
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.id === undefined,
            formData.client.id === undefined,
            formData.contractDate === undefined,
            formData.startDate === undefined,
            formData.endDate === undefined,
            formData.contractDate === "Invalid date",
            formData.startDate === "Invalid date",
            formData.endDate === "Invalid date"
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.type === data.type,
            formData.status === data.status,
            formData.contractDate === data.contractDate,
            formData.startDate === data.startDate,
            formData.endDate === data.endDate,
            formData.client.id === data.client.id,
            formData.template_id === data.template_id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // Fetch assessment data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await assessmentService.get(id as string)
                setData(val)
                setFormData(val)
                setFiletype(FileTypeByAssessmentType[val.type])
                const queryParameters: QueryParameters = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(queryParameters)
                setVulnStats(vulnsStats)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
        fetchSunshine()
    }, [])
    // API Call save assessment
    const navigate = useNavigate()
    const saveHandler = async () => {
        try {
            await assessmentService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call save assessment - END

    // Handle component button
    const handleComponentButton = () => {
        navigate("./component")
    }
    // --------------------------------------------

    // Handle informe button
    const handleInformeButton = async () => {
        try {
            const params = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
            const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
            const vulns = await vulnerabilityService.getAllFormatted(formData.id, "cyclonedx")
            const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: formData.type }] })
            const report = formData.type === AssessmentType.SCA ? pdf(<VulnReportDocument data={vulns} assessment={formData} template={templateData.list[0]} />) : pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }
    const handleInformeAchilles = async () => {
        try {
            if (filetype.type === "achilles") {
                const data = await achillesService.getAllFormatted(id as string, "achilles")
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: formData.type }] })
                const report = pdf(
                    <VulnReportDocumentAchilles
                        assessment={formData}
                        achilles={data.list}
                        template={templateData.list[0]}
                    />
                )
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
                // Reiniciar customText después de generar el informe
            }
        } catch (error) {
            console.error(error)
            setError({ message: context.t.translate("error_generating") } as Error)
            setSnackbarOpen(true)
        }
    }
    // --------------------------------------------

    // Handle SBOM button
    const handleSBOMButton = async () => {
        const filetype = FileTypeByAssessmentType[data.type]
        const file = await fileService.downloadFile(id, filetype.type)
        const blob = new Blob([file], { type: filetype.mimetype })
        saveAs(blob, `${data.product.name}_${data.product.version}_${data.type}`)
    }

    // --------------------------------------------
    // Handle SBOM quality button
    const handleSBOMQuality = async () => {
        navigate("./sbomQuality")
    }
    // Función para descargar el archivo genérico
    const handleDownloadGenericFileClick = async () => {
        try {
            // Descarga el archivo usando fileService
            const assessmentData = await fileService.downloadFile(data.id, "original")

            // Crea un blob para el archivo descargado con el tipo MIME correcto
            const blob = new Blob([assessmentData], { type: filetype.mimetype })

            // Define el nombre del archivo
            const fileName = `${data.product.name}_${data.product.version}_${data.type}`

            // Descarga el archivo usando saveAs
            saveAs(blob, fileName)
        } catch (error) {
            console.error(context.t.translate("error_downloading"), error)
            setError({ message: context.t.translate("error_downloading_report") } as Error)
            setSnackbarOpen(true)
        }
    }
    const handleDownloadGenericFileSBOM = async () => {
        try {
            // Descarga el archivo usando fileService
            const assessmentData = await fileService.downloadFile(data.id, "report")

            // Crea un blob para el archivo descargado con el tipo MIME correcto
            const blob = new Blob([assessmentData], { type: filetype.mimetype })

            // Define el nombre del archivo
            const fileName = `${data.product.name}_${data.product.version}_${data.type}`

            // Descarga el archivo usando saveAs
            saveAs(blob, fileName)
        } catch (error) {
            console.error(context.t.translate("error_downloading"), error)
            setError({ message: context.t.translate("error_downloading_report") } as Error)
            setSnackbarOpen(true)
        }
    }
    const fetchSunshine = async () => {
        try {
            const response = await fileService.getSunshineByAssessment(id ?? "")
            console.log("sunshine", response)
        } catch (error: any) {
            console.error(error)
        }
    }
    // Handle upload SBOM button
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const VisuallyHiddenInput = forwardRef<HTMLInputElement>((props, ref: Ref<HTMLInputElement>) => {
        return (
            <input
                type="file"
                accept={FileTypeByAssessmentType[data.type].extension}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                onClick={handleFileInputClick}
                id={fileId}
                ref={ref}
            />
        )
    })
    VisuallyHiddenInput.displayName = "VisuallyHiddenInput"
    const handleUploadSBOMButton = () => {
        fileInputRef.current?.click()
    }
    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = ""
    }
    const onSectionChange = (updatedText: CustomText) => {
        setCustomTexts(updatedText)
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]

        if (selectedFile) {
            try {
                const mediaTypesValues = Object.values(MediaTypes)
                const mimetypes = filetype.mimetype.split(",")
                const foundType = mediaTypesValues.find(v => mimetypes.includes(v))

                if (foundType) {
                    const response = await fileService.uploadFileV2(
                        data.id,
                        filetype.type,
                        foundType as MediaTypes,
                        selectedFile
                    )

                    if (response.status === 200) {
                        // Actualizar el estado basado en el tipo de evaluación
                        const updatedStatus = data.type === AssessmentType.SCA
                            ? AssessmentStatus.Started
                            : AssessmentStatus.Ended

                        const updatedData = { ...formData, status: updatedStatus }

                        await assessmentService.update(updatedData.id, updatedData)
                        setData(updatedData)
                        setFormData(updatedData)
                        if (data.type === AssessmentType.SCA) { setModalOpen(true) }
                    }
                } else {
                    setError({ message: context.t.translate("unsupported_file") } as Error)
                }
            } catch (e: any) {
                setError({ message: context.t.translate("wrong_file") } as Error)
                console.error(e)
            }
        }
    }
    // --------------------------------------------
    useEffect(() => {
        const updateAssessmentStatusAndStartDate = async () => {
            const totalVulnerabilities =
                (vulnStats
                    ? Object.values(vulnStats.MitigatedVulnerabilities).reduce((sum, val) => sum + val, 0) +
                    Object.values(vulnStats.UnmitigatedVulnerabilities).reduce((sum, val) => sum + val, 0)
                    : 0)

            if (
                data.type === AssessmentType.LogicalPentest &&
                data.status === AssessmentStatus.Planned &&
                totalVulnerabilities > 0 // Asegúrate de que hay al menos una vulnerabilidad
            ) {
                const updatedData = {
                    ...formData,
                    status: AssessmentStatus.Started,
                    startDate: moment().toISOString() // Asignar la fecha actual al campo startDate
                }
                try {
                    await assessmentService.update(updatedData.id, updatedData)
                    setData(updatedData) // Actualiza el estado local
                    setFormData(updatedData)
                } catch (error: any) {
                    console.error("Error updating assessment status and startDate:", error)
                    setError({ message: "Failed to update assessment status and startDate." } as Error)
                    setSnackbarOpen(true)
                }
            }
        }

        updateAssessmentStatusAndStartDate()
    }, [vulnStats, data.type, data.status]) // Observa los cambios en vulnStats, el tipo y estado del assessment

    // --------------------------------------------

    // Functions
    const loadPreview = async () => {
        const TemplateReport = TemplateReportByAssessmentType[formData.type]
        const report = pdf(<TemplateReport data={vulnerabilityGenerator()} />)
        const blob = await report.toBlob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
    }
    // Web iframe
    useEffect(() => {
        const generatePDFComponent = async () => {
            const TemplateComponent = TemplateReportByAssessmentType[formData.type]
            if (!TemplateComponent) return

            // Incluye los datos de textos editados como parte de los props
            if (formData.type === AssessmentType.CRT) {
                setDynamicReport(
                    <TemplateComponent
                        assessment={assessmentGenerator(1)[0]}
                        achilles={achillesTestGenerator(30)}
                        externalData={customTexts.custom_text} // Pasar datos editados
                    />
                )
            } else {
                if (formData.type === AssessmentType.LogicalPentest) {
                    setDynamicReport(
                        <TemplateComponent
                            data={vulnerabilityGenerator(30)}
                            externalData={customTexts.custom_text} // Pasar datos editados
                        />
                    )
                }
            }
        }

        if (formData.type === AssessmentType.CRT || formData.type === AssessmentType.LogicalPentest) {
            generatePDFComponent()
        }
    }, [formData.type, customTexts])
    // --------------------------------------------
    const styles = {
        fontFamily: "Griff",
        "& .MuiInputBase-root": {
            color: theme.palette.primary.main, // Color del texto
            backgroundColor: theme.palette.background.paper // Color de fondo
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.primary.main // Color de la línea subrayada antes de enfocar
        },
        "& .MuiInput-underline:hover:before": {
            borderBottomColor: theme.palette.secondary.main // Color de la línea al pasar el cursor
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.dark // Color de la línea al enfocar
        }
    }
    // useEffect(() => {
    //     if (assessmentID) {
    //         console.log("📢 Ejecutando handleViewReport automáticamente...")
    //         handleViewReport()
    //     }
    // }, [assessmentID]) // ⚠️ Se ejecuta cuando assessmentID cambia

    // const handleViewReport = async () => {
    //     if (!assessmentID) {
    //         console.error("🚨 No hay assessmentID en la URL, revisa useParams() 🚨")
    //         return
    //     }

    //     try {
    //         const response = await fileService.sunshine(assessmentID) // ⬅️ Devuelve un `File`
    //         console.log("📢 Respuesta del backend (Archivo):", response)

    //         if (response) {
    //             const reader = new FileReader()
    //             reader.onload = (event) => {
    //                 if (event.target?.result) {
    //                     setReportUrl(`data:text/html;charset=utf-8,${encodeURIComponent(event.target.result as string)}`)
    //                     console.log("✅ Reporte SBOM generado correctamente")
    //                 }
    //             }
    //             reader.readAsText(response) // ⬅️ Lee el contenido del archivo como texto
    //         } else {
    //             console.warn("⚠️ No se encontró contenido en la respuesta del backend.")
    //             setReportUrl(null)
    //         }
    //     } catch (error) {
    //         console.error("🚨 Error al obtener el reporte SBOM:", error)
    //         setReportUrl(null)
    //     }
    // }

    const [anchorElDownloads, setAnchorElDownloads] = React.useState<null | HTMLElement>(null)
    const openDownloads = Boolean(anchorElDownloads)

    const handleClickDownloads = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElDownloads(event.currentTarget)
    }

    const handleCloseDownloads = () => {
        setAnchorElDownloads(null)
    }

    const [anchorElActions, setAnchorElActions] = React.useState<null | HTMLElement>(null)
    const openActions = Boolean(anchorElActions)

    const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElActions(event.currentTarget)
    }

    const handleCloseActions = () => {
        setAnchorElActions(null)
    }
    const handleCPEButton = () => {
        navigate("./cpe")
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleTabSelect = (index: number) => {
        handleTabChange(null, index)
        handleMenuClose()
    }

    return (
        <Grid item flexDirection="column" rowGap="25px" sx={{ paddingTop: "20px !important" }}>
            <StyledBox>
                <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Assessment Section">
                    <Grid item xs>
                        <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                            {context.t.translate("assessments")} {formData.product.name}
                        </Typography>
                        <GenericModal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            title={context.t.translate("approve_SBOM")}
                            actions={
                                <ActionButton
                                    variant="contained"
                                    onClick={handleApprove}
                                    text={context.t.translate("approve")}
                                />
                            }>
                            <Typography>{context.t.translate("SBOM_approval")}</Typography>
                        </GenericModal>
                        <Grid container flexDirection={isMobile ? "column" : "row"} mb={1} alignItems={isMobile ? "flex-start" : "center"} gap={2} justifyContent="space-between">
                            <Grid flexDirection={isMobile ? "column" : "row"} display="flex" gap={2}>
                                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                                        <strong>{context.t.translate("type")}:</strong>
                                    </Typography>
                                    <Tooltip title={context.t.translate("type")}>
                                        <AssessmentStateColored value={formData.type} />
                                    </Tooltip>
                                </Grid>

                                <ActionButton
                                    text={context.t.translate("actions")}
                                    icon={<MdMoreVert />}
                                    onClick={handleClickActions}
                                />

                                <Menu open={openActions} anchorEl={anchorElActions} disablePortal>
                                    <ClickAwayListener onClickAway={handleCloseActions}>

                                            <MenuList>
                                                <MenuItem onClick={() => { }}>
                                                    <ListItemIcon><TbAnalyze fontSize="small" /></ListItemIcon>
                                                    <ListItemText>{context.t.translate("reanalyze")}</ListItemText>
                                                </MenuItem>

                                                {data.type === AssessmentType.SCA && (
                                                    <>
                                                        <MenuItem onClick={handleComponentButton}>
                                                            <ListItemIcon><BiSolidComponent fontSize="small" /></ListItemIcon>
                                                            <ListItemText>{context.t.translate("components")}</ListItemText>
                                                        </MenuItem>
                                                        {(data.status === AssessmentStatus.Planned || data.status === AssessmentStatus.Contracted) && (
                                                            <MenuItem onClick={handleCPEButton}>
                                                                <ListItemIcon><RxComponent2 fontSize="small" /></ListItemIcon>
                                                                <ListItemText>CPE</ListItemText>
                                                            </MenuItem>
                                                        )}
                                                    </>
                                                )}

                                                <MenuItem onClick={handleSBOMQuality}>
                                                    <ListItemIcon><MdSpeed fontSize="small" /></ListItemIcon>
                                                    <ListItemText>{context.t.translate("SBOM_quality")}</ListItemText>
                                                </MenuItem>
                                                <VisuallyHiddenInput ref={fileInputRef} />
                                                {data.status === AssessmentStatus.Planned && data.type !== AssessmentType.LogicalPentest && (
                                                    <MenuItem onClick={handleUploadSBOMButton}>

                                                        <ListItemIcon><MdOutlineUploadFile fontSize="small" /></ListItemIcon>
                                                        <ListItemText>{context.t.translate("upload_sbom")}</ListItemText>
                                                    </MenuItem>
                                                )}

                                                {data.type === AssessmentType.LogicalPentest &&
                                                    (data.status === AssessmentStatus.Planned || data.status === AssessmentStatus.Started) && (
                                                        <MenuItem onClick={() => navigate("./addvuln")}>
                                                            <ListItemText>{context.t.translate("add_vulnerability")}</ListItemText>
                                                        </MenuItem>
                                                    )}
                                            </MenuList>
                                    </ClickAwayListener>
                                </Menu>

                                <ActionButton
                                    text={context.t.translate("downloads")}
                                    icon={<FaRegFileAlt />}
                                    onClick={handleClickDownloads}
                                />

                                <Menu open={openDownloads} anchorEl={anchorElDownloads} disablePortal>
                                    <ClickAwayListener onClickAway={handleCloseDownloads}>

                                            <MenuList>
                                                <MenuItem onClick={data.type === AssessmentType.CRT ? handleInformeAchilles : handleInformeButton}>
                                                    <ListItemIcon>
                                                        <FaRegFileAlt fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>{context.t.translate("report")}</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={handleDownloadGenericFileClick}>
                                                    <ListItemIcon>
                                                        <MdOutlineFileOpen fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>{context.t.translate("original")}</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseDownloads}>
                                                    <ListItemIcon>
                                                        <TbFileSymlink fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>{context.t.translate("SBOM_updated")}</ListItemText>
                                                </MenuItem>
                                            </MenuList>
                                    </ClickAwayListener>
                                </Menu>
                            </Grid>
                            {isMobile
                                ? <Grid item sx={{ flexGrow: 0.3, flexShrink: 0, minWidth: "unset", display: "flex", justifyContent: "flex-end" }}>
                                    <ActionButton
                                        text= {context.t.translate("tabs")}
                                        onClick={handleMenuOpen}
                                        icon={<MdTableRows />}
                                        />

                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                        {tabs.map((tab, index) => (
                                            <MenuItem key={index} onClick={() => handleTabSelect(index)}>
                                                {tab.icon && <>{tab.icon}</>} {context.t.translate(tab.content)}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                                : <>
                                    <Grid item sx={{ flexGrow: 0.3, flexShrink: 0, minWidth: "unset", display: "flex", justifyContent: "flex-end" }}>
                                        <StyledTabs value={tabIndex} onChange={handleTabChange}>
                                            {tabs.map((tab, index) => (
                                            <StyledTab
                                                key={index}
                                                label={context.t.translate(tab.content)}
                                                icon={tab.icon ? <>{tab.icon}</> : undefined}
                                                sx={{ minWidth: 100, width: "auto", paddingX: 1, fontSize: "0.875rem" }}
                                            />
                                            ))}
                                        </StyledTabs>
                                        </Grid>

                                </>}
                        </Grid>
                        <Divider sx={{ width: "100%", backgroundColor: "gray", mt: "2px" }} />
                    </Grid>

                    <Grid item xs={12} padding="30px">
                        {tabs[tabIndex]?.content === "details" && <DetailsTab formData={formData} handleInputChange={handleInputChange} onSave={saveHandler} formValid={formValid} />}
                        {tabs[tabIndex]?.content === "vulnerabilities" && (formData.type === AssessmentType.CRT
                            ? <AchillesTable id={id} />
                            : <VulnerabilitiesTable vulnStats={vulnStats} id={id} />)}
                        {tabs[tabIndex]?.content === "hardware" && <HardwareComponentTable productID={formData.product.id} />}
                        {tabs[tabIndex]?.content === "components" && <ComponentView id={formData.id} />}

                    </Grid>
                </Grid>
                {/*  <Grid item>
                    <ActionButton
                        onClick={handleViewReport}
                        text="Cargar Reporte SBOM"
                        icon={<VisibilityIcon />}
                    />
                </Grid> */}
            </StyledBox>
        </Grid>
    )
}

export { AssessmentDetail }
export default AssessmentDetail
