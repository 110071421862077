/* eslint-disable space-before-function-paren */
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { useContext, useEffect, useState } from "react"
import ServicesContext from "@context/ServicesContext"
import Assessment, { AssessmentStatus } from "@models/Assessment"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { Box, Grid, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useNavigate } from "react-router-dom"
import { Reminder, ReminderDay } from "@models/Reminder"
import useMediaResponsive from "@hooks/useMobile"

const Legend = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <div style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: isMobile ? "nowrap" : "wrap",
            justifyContent: isMobile ? "flex-start" : "space-around",
            padding: "10px",
            backgroundColor: "#f1f1f1",
            gap: isMobile ? "8px" : "4px"
        }}>
            {Object.entries(AssessmentStatusColors).map(([status, color]) => (
                <div key={status} style={{
                    textAlign: isMobile ? "left" : "center",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "5px" : 0
                }}>
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: color,
                            marginRight: "8px",
                            borderRadius: "50%"
                        }}
                    />
                    <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                </div>
            ))}
            <div style={{
                textAlign: isMobile ? "left" : "center",
                display: "flex",
                alignItems: "center"
            }}>
                <div
                    style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#271968",
                        marginRight: "8px",
                        borderRadius: "50%"
                    }}
                />
                <span>Reminder</span>
            </div>
        </div>
    )
}

const CalendarSkeleton = () => {
    const theme = useTheme()
    const isMobile = useMediaResponsive("(max-width: 768px")

    return (
        <Box sx={{ width: "100%", height: isMobile ? "400px" : "600px" }}>
            {/* Header skeleton */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, p: 2 }}>
                <Skeleton variant="rectangular" width="15%" height={30} />
                <Skeleton variant="rectangular" width={isMobile ? "40%" : "25%"} height={30} />
                <Skeleton variant="rectangular" width="15%" height={30} />
            </Box>

            {/* Days of week skeleton */}
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                {Array(7).fill(0).map((_, index) => (
                    <Skeleton key={index} variant="rectangular" width="13%" height={25} />
                ))}
            </Box>

            {/* Calendar grid skeleton - 6 weeks */}
            <Box sx={{ p: 2 }}>
                {Array(isMobile ? 4 : 6).fill(0).map((_, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                        {Array(7).fill(0).map((_, dayIndex) => (
                            <Skeleton
                                key={dayIndex}
                                variant="rectangular"
                                width="13%"
                                height={isMobile ? 60 : 180}
                                sx={{ borderRadius: 1 }}
                            />
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

const CalendarView = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const reminderService = useContext(ServicesContext).reminderService
    const assessmentService = useContext(ServicesContext).assessmentService
    const [assessmentData, setAssessmentData] = useState<Assessment[]>([]) // Inicializar como un array vacío
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [reminderData, setReminderData] = useState<Reminder[]>([])
    const [error, setError] = useState<Error | null>(null)
    const theme = useTheme()
    const [loading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const fetchData = async () => {
        try {
            const response = await assessmentService.getAll()
            setAssessmentData(response.list)
        } catch (error: any) {
            setError(error)
        }
    }

    const fetchReminderData = async () => {
        try {
            const response = await reminderService.getAll()
            setReminderData(response.list)
        } catch (error: any) {
            setError(error)
        }
    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true)
            try {
                await Promise.all([fetchReminderData(), fetchData()])
            } catch (error: any) {
                setError(error)
                setSnackbarOpen(true)
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            }
        }

        loadData()
    }, [])

    // Convertir fecha de inicio, fin y contractDate sin zonas horarias (solo el día)
    const calendarEvents = assessmentData.map((assessment) => {
        if (assessment.startDate || assessment.contractDate) {
            const startDate = assessment.startDate
                ? new Date(assessment.startDate).toLocaleDateString("en-CA")
                : assessment.contractDate
                    ? new Date(assessment.contractDate).toLocaleDateString("en-CA")
                    : null

            const endDate = assessment.endDate
                ? new Date(assessment.endDate).toLocaleDateString("en-CA")
                : startDate

            if (startDate === null) return null

            const statusColor = AssessmentStatusColors[assessment.status as AssessmentStatus]

            return {
                title: `${assessment.product.name} - ${assessment.product.version} (${assessment.client.name})`,
                start: startDate,
                end: endDate,
                backgroundColor: statusColor,
                borderColor: statusColor,
                textColor: "black",
                extendedProps: {
                    status: assessment.status,
                    id: assessment.id
                }
            }
        }
        return null
    }).filter(event => event !== null) as any[]

    // Convertimos los recordatorios en eventos de calendario
    const generateReminderEvents = (reminder: any) => {
        const events = []
        const startDate = new Date(reminder.frequency_date)

        if (reminder.reminder_frequency === "daily") {
            for (let i = 0; i < 365; i++) {
                const eventDate = new Date(startDate)
                eventDate.setDate(eventDate.getDate() + i) // Sumar días
                events.push(createEvent(reminder, eventDate))
            }
        } else if (reminder.reminder_frequency === "weekly" && reminder.reminder_day) {
            const daysOfWeek: ReminderDay[] = [
                ReminderDay.Sunday,
                ReminderDay.Monday,
                ReminderDay.Tuesday,
                ReminderDay.Wednesday,
                ReminderDay.Thursday,
                ReminderDay.Friday,
                ReminderDay.Saturday
            ]

            const reminderDayIndex = daysOfWeek.indexOf(reminder.reminder_day as ReminderDay)
            if (reminderDayIndex !== -1) {
                for (let i = 0; i < 54; i++) {
                    const eventDate = new Date(startDate)
                    const dayOffset = (reminderDayIndex - eventDate.getDay() + 7) % 7
                    eventDate.setDate(eventDate.getDate() + dayOffset + i * 7)
                    events.push(createEvent(reminder, eventDate))
                }
            }
        } else if (reminder.reminder_frequency === "monthly") {
            for (let i = 0; i < 12; i++) {
                const eventDate = new Date(startDate)
                eventDate.setMonth(eventDate.getMonth() + i) // Sumar meses
                events.push(createEvent(reminder, eventDate))
            }
        } else if (reminder.reminder_frequency === "quarterly") {
            for (let i = 0; i < 4; i++) { // Solo 4 eventos en el año
                const eventDate = new Date(startDate)
                eventDate.setMonth(eventDate.getMonth() + i * 3) // Sumar 3 meses
                events.push(createEvent(reminder, eventDate))
            }
        }

        return events
    }

    // 🔹 Función auxiliar para crear eventos (evita repetición de código)
    const createEvent = (reminder: any, eventDate: Date) => ({
        title: formatLabel(reminder.type),
        start: eventDate.toISOString(),
        end: eventDate.toISOString(),
        backgroundColor: "#271968",
        borderColor: "#271968",
        textColor: "white",
        extendedProps: {
            description: `Reminder for ${reminder.type}`,
            isReminder: true
        }
    })

    const reminderEvents = reminderData.flatMap((reminder) => generateReminderEvents(reminder))
    function formatLabel(label: string) {
        const alwaysUppercase = ["SCA", "CRT"]
        if (alwaysUppercase.includes(label.toUpperCase())) {
            return label.toUpperCase()
        }
        const formatted = label
            .replace(/_/g, " ")
            .replace(/([a-z])([A-Z])/g, "$1 $2")
        return formatted.charAt(0).toUpperCase() + formatted.slice(1)
    }

    return (
        <Grid item xs container flexDirection="column" spacing={isMobile ? 1 : 2} sx={{ position: "relative" }}>
            <Grid
                container
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: isMobile ? "10px" : "20px",
                    marginBottom: "0px",
                    paddingBottom: "0px"
                }}
            >
                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize={isMobile ? "32px" : "45px"}
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("calendar")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize={isMobile ? "13px" : "15px"}
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("sections.reportsCalendar")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container flexDirection="column" rowGap={isMobile ? "15px" : "35px"}>
                <>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("an_error")}
                    />

                    <Grid item sx={{ width: "100%", paddingBottom: isMobile ? 2 : 4 }}>
                        <Legend />
                    </Grid>

                    <Grid item sx={{
                        width: "100%",
                        height: isMobile ? "auto" : "75%",
                        paddingRight: isMobile ? "10px" : "0px"
                    }}>
                        {loading
                            ? (
                                <CalendarSkeleton />
                            )
                            : (
                                <div style={{ overflowX: isMobile ? "auto" : "visible" }}>
                                    <div style={{
                                        minWidth: isMobile ? "600px" : "auto",
                                        padding: isMobile ? "0 5px" : "0"
                                    }}>
                                        <FullCalendar
                                            initialView="dayGridMonth"
                                            headerToolbar={{
                                                left: "prev,next",
                                                center: "title",
                                                right: "dayGridMonth"
                                            }}
                                            themeSystem="Simplex"
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            events={[...calendarEvents, ...reminderEvents]}
                                            displayEventTime={false}
                                            height={isMobile ? "auto" : undefined}
                                            contentHeight={isMobile ? 450 : undefined}
                                            aspectRatio={isMobile ? 1 : 1.35}
                                            eventClick={(info) => {
                                                const eventId = info.event.extendedProps.id
                                                if (eventId) {
                                                    navigate(`../assessment/${eventId}`)
                                                }
                                            }}
                                            eventContent={({ event }) => {
                                                if (event.extendedProps.isReminder) {
                                                    return (
                                                        <Tooltip title={event.extendedProps.description}>
                                                            <div onClick={() => navigate("../settings/email")}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    cursor: "pointer",
                                                                    backgroundColor: "#271968",
                                                                    color: "#fff",
                                                                    borderRadius: "3px",
                                                                    padding: "0px 5px 0px 5px",
                                                                    fontSize: isMobile ? "10px" : "inherit"
                                                                }}
                                                            >
                                                                <AccessTimeIcon style={{
                                                                    marginRight: "5px",
                                                                    fontSize: isMobile ? "12px" : "16px"
                                                                }} />
                                                                {isMobile && event.title.length > 8
                                                                    ? event.title.substring(0, 8) + "..."
                                                                    : formatLabel(event.title)
                                                                }
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                }
                                                return (
                                                    <Tooltip title={`Status: ${event.extendedProps.status?.charAt(0).toUpperCase() + event.extendedProps.status?.slice(1)}`}>
                                                        <div
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: isMobile ? "10px" : "inherit",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                            }}
                                                            onClick={() => navigate(`../assessment/${event.extendedProps.id}`)}
                                                        >
                                                            {isMobile
                                                                ? (event.title.length > 10
                                                                    ? event.title.substring(0, 10) + "..."
                                                                    : event.title)
                                                                : event.title
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                    </Grid>
                </>
            </Grid>
        </Grid>
    )
}

export default CalendarView
