import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import { Walkthrough } from "@models/Walkthrough"

class WalkthroughService extends Service<Walkthrough> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/walkthrough/login", MediaTypes.JSON);
    }

    public getFirstLogin = (id: string) => {
        return this.http.get(`/${this.path}/${id}`) as any
    }

    public updateFirstLogin = (id: string) => {
        this.http.post(`/${this.path}/update/${id}`, {} as any)
    }
}

export { WalkthroughService }
export default WalkthroughService
