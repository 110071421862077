import { Divider, Grid, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { ClientSelector } from "@components/client"
import Product from "@models/Product"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { StyledBox } from "@components/common/Box/BoxContainer"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import AccountTreeIcon from "@mui/icons-material/AccountTree"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { GridColDef } from "@mui/x-data-grid"
import ProductTableDefinition, { ProductTableDefinitionES } from "@components/product/ProductTableDefinition"
import { AbilityContext } from "@components/permissions"
import ProviderProductsGraph from "./ProviderProductsHierarchy"
const initValue = {
    id: "",
    name: "",
    description: "",
    provider_id: "",
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" }
}

const ProviderDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error("I18n context is not initialized.")
    }
    const navigate = useNavigate()
    const theme = useTheme()
    const { providerid } = useParams<{ providerid: string }>()
    const providerService = useContext(ServicesContext).providerService
    const productService = useContext(ServicesContext).productService
    const [tabIndex, setTabIndex] = React.useState(0)
    const [formData, setFormData] = useState(initValue)
    const [originalData, setOriginalData] = useState(initValue)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>(null)
    const [products, setProducts] = useState<Product[]>([])
    const [isModified, setIsModified] = useState(false)
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const val = await providerService.getByIDExt(providerid as string)
                setFormData({
                    ...val.list[0],
                    client: val.list[0].client ?? { id: "00000000-0000-0000-0000-000000000000", name: "" }
                })
                setOriginalData(val.list[0])

                setProducts(val.list[0].product_list)
            } catch (e: any) {
                setError(e.message)
                setSnackbarOpen(true)
            }
        }

        fetchData()
    }, [providerid])

    const checkIfModified = (newData: typeof formData) => {
        const isChanged = JSON.stringify(newData) !== JSON.stringify(originalData)
        setIsModified(isChanged)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newData = { ...formData, [event.target.name]: event.target.value }
        setFormData(newData)
        checkIfModified(newData)
    }

    const handleSelectClient = (clientId: string | undefined) => {
        if (clientId) {
            const newData = { ...formData, client_id: clientId || "" }
            setFormData(newData)
            checkIfModified(newData)
        }
    }

    const handleSave = async () => {
        try {
            await providerService.update(providerid as string, formData)
            setSuccessMessage("Provider updated successfully!")
            setSnackbarOpen(true)
            setOriginalData(formData)
            setIsModified(false)
        } catch (e: any) {
            setError(e.message)
            setSnackbarOpen(true)
        }
    }

    // Verifica si los campos requeridos están completos
    const isFormComplete = () => {
        return formData.name.trim() !== "" && formData.description.trim() !== "" && formData.client.id !== ""
    }
    useEffect(() => {
        const policyTableDefinition = context.language === "es" ? ProductTableDefinitionES : ProductTableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability])
    useEffect(() => {
        console.log("PROD", products)
    }, [products])
    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("providers")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <StyledBox>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={error || successMessage || context.t.translate("an_error")}
                                severity={successMessage ? "success" : "error"}
                            />
                            <Grid item container flexDirection="column" rowGap={3}>
                                <Grid item display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                        Details
                                    </Typography>
                                    <Grid item>
                                        <ActionButton
                                            variant="contained"
                                            text={context.t.translate("save")}
                                            onClick={handleSave}
                                            disabled={!isModified || !isFormComplete()} // 🔹 Deshabilitado si no hay cambios o el formulario está incompleto
                                        />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                                <Grid item container flexDirection="row" spacing={4}>
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("name")}
                                        </Typography>
                                        <TextField fullWidth name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ marginTop: "30px" }} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("client")}
                                        </Typography>
                                        <ClientSelector value={formData.client.id ?? ""} onSelect={handleSelectClient} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("description")}
                                        </Typography>
                                        <TextField fullWidth name="description" value={formData.description} onChange={handleChange} variant="standard" multiline style={{ marginTop: "30px" }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("tags_products")}
                                        </Typography>

                                        <Grid>
                                            <Tabs value={tabIndex} onChange={handleTabChange} centered sx={{ paddingBottom: "20px" }}>
                                                <Tab label="list" icon={<FormatListBulletedIcon />} />
                                                <Tab label="tree" icon={<AccountTreeIcon/>}/>
                                            </Tabs>

                                            {tabIndex === 0 && (
                                                <Grid item container flexDirection="column" rowGap="35px">
                                                    <GenericTable<Product> entity="Product" columns={cols}
                                                        dataProvider={(filter) => {
                                                            const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "provider_ids", operation: FilterOperation.StringArrayContains, value: providerid as string }] }
                                                            return productService.getAllExt(updatedFilter)
                                                        }}
                                                        onEdit={(elem: Product) => navigate("./" + elem.id)}
                                                        onDelete={(elem: Product) => productService.delete(elem.id)}
                                                    />
                                                </Grid>
                                            )}
                                            {tabIndex === 1 && (
                                                <Grid>
                                                    <ProviderProductsGraph provider={formData} products={products || []} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export { ProviderDetail }
export default ProviderDetail
