import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

// export const vectors1 = [
//     { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
//     { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" },
//     { id: "Privileges Required (PR): ", description: "High (H), Low (L), None (N)" },
//     { id: "User Interaction (UI): ", description: "Required (R), None (N)" },
//     { id: "Scope (S): ", description: "Changed (C), Unchanged (U)" },
//     { id: "Confidenciality (C): ", description: "None (N), Partial (P), Complete (C)" },
//     { id: "Integrity (I): ", description: "None (N), Partial (P), Complete (C)" },
//     { id: "Availability (A): ", description: "None (N), Partial (P), Complete (C)" }
// ]

// export const vectors2_1 = [
//     { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
//     { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" }
// ]

// export const vectors2_2 = [
//     { id: "Authentication (Au): ", description: "Multiple (M), Single (S), None (N)" },
//     { id: "Confidenciality Impact (C): ", description: "None (N), Partial (P), Complete (C)" },
//     { id: "Integrity Impact (I): ", description: "None (N), Partial (P), Complete (C)" },
//     { id: "Availability Impact (A): ", description: "None (N), Partial (P), Complete (C)" }
// ]

// export const scoreTable = [
//     ["CVSS Score", "Rating"],
//     ["0.0", "None"],
//     ["0.1 -3.9", "Low"],
//     ["4.0 - 6.0", "Medium"],
//     ["7.0 - 8.9", "High"],
//     ["9.0 - 10.0", "Critical"]
// ]

// export const resumeTable = [
//     { id: "Summary Table: ", description: "It is the table where all the vulnerabilities found are summarized in the following format:" },
//     { id: "Identificator: ", description: "It will be displayed using the following format XYZ-nnn, where nnn will be a number that reflects the vulnerability number." },
//     { id: "Name: ", description: "It will describe the title of the vulnerability found." },
//     { id: "Criticality: ", description: "This field will contain the 'Rating' of the vulnerability found." }
// ]

// export const detailTable = [
//     { id: "Summary Table. ", description: "This table will contain three types of information:" },
//     { id: "Header. ", description: "This type of information will contain the identifier, name, criticality, CVSS value along with its values and the affected assets." },
//     { id: "Evidencias. ", description: "A descriptive text will be displayed, as well as screenshots of the identified problem." },
//     { id: "Solution/Reference. ", description: "A first solution for the problem will be described." }
// ]
const i18n = {
    es: {
        title_identification: "4. Identificación de ",
        vulnerabilities: "vulnerabilidades",
        introduction: "4.1 Introducción",
        introduction1: "En esta sección procederemos a describir todas las vulnerabilidades encontradas a lo largo del análisis realizado. Para una mejor comprensión del formato de presentación de estas y el detalle mostrado, se explicarán una serie de conceptos de métricas de seguridad.",
        introduction2: "Las vulnerabilidades encontradas serán evaluadas utilizando un estándar llamado Common Vulnerability Scoring System, en sus dos versiones, la versión 3.1 y la versión 2.0 (CVSS). Este estándar permite establecer una medida de severidad en un rango de 0 a 10, siendo 10 la criticidad más alta posible. Se han utilizado dos versiones de CVSS porque algunas de las herramientas solo proporcionan información en la versión 2.0 y no en la versión 3.1.",
        introduction3: "La versión 3.1 considera los siguientes factores de riesgo:",
        introduction4: "Mientras que la versión 2.0 considera los siguientes factores:",
        introduction5: "Basándose en el valor total obtenido, se establecen una serie de umbrales que proporcionan valores cualitativos:",
        table2: "Tabla 2. Clasificación CVSS",
        table2_explication: "Estos vectores se muestran en la sección CVSS de cada tabla de vulnerabilidad junto con el valor obtenido. Basado en este sistema, el formato de los resultados está compuesto por dos tipos de tablas:",
        table2_example: "A continuación, se muestra un ejemplo de este tipo de tabla:",
        table2_figure: "Figura 1. Formato de tabla de detalle",
        table2_sections: "En las siguientes secciones, primero se mostrará la tabla de resumen y luego, para cada vulnerabilidad, su correspondiente tabla de detalle.",
        vectors1: [
            { id: "Vector de Acceso (AV): ", description: "Local (L), Adyacente (A), Red (N)" },
            { id: "Complejidad de Acceso (AC): ", description: "Alta (H), Media (M), Baja (L)" },
            { id: "Privilegios Requeridos (PR): ", description: "Alto (H), Bajo (L), Ninguno (N)" },
            { id: "Interacción del Usuario (UI): ", description: "Requerida (R), Ninguna (N)" },
            { id: "Alcance (S): ", description: "Cambiado (C), Sin Cambios (U)" },
            { id: "Confidencialidad (C): ", description: "Ninguna (N), Parcial (P), Completa (C)" },
            { id: "Integridad (I): ", description: "Ninguna (N), Parcial (P), Completa (C)" },
            { id: "Disponibilidad (A): ", description: "Ninguna (N), Parcial (P), Completa (C)" }
        ],
        vectors2_1: [
            { id: "Vector de Acceso (AV): ", description: "Local (L), Adyacente (A), Red (N)" },
            { id: "Complejidad de Acceso (AC): ", description: "Alta (H), Media (M), Baja (L)" }
        ],
        vectors2_2: [
            { id: "Autenticación (Au): ", description: "Múltiple (M), Única (S), Ninguna (N)" },
            { id: "Impacto en la Confidencialidad (C): ", description: "Ninguno (N), Parcial (P), Completo (C)" },
            { id: "Impacto en la Integridad (I): ", description: "Ninguno (N), Parcial (P), Completo (C)" },
            { id: "Impacto en la Disponibilidad (A): ", description: "Ninguno (N), Parcial (P), Completo (C)" }
        ],
        scoreTable: [
            ["Puntuación CVSS", "Clasificación"],
            ["0.0", "Ninguna"],
            ["0.1 - 3.9", "Baja"],
            ["4.0 - 6.0", "Media"],
            ["7.0 - 8.9", "Alta"],
            ["9.0 - 10.0", "Crítica"]
        ],
        resumeTable: [
            { id: "Tabla de Resumen: ", description: "Es la tabla donde se resumen todas las vulnerabilidades encontradas en el siguiente formato:" },
            { id: "Identificador: ", description: "Se mostrará utilizando el siguiente formato XYZ-nnn, donde nnn será un número que refleja el número de la vulnerabilidad." },
            { id: "Nombre: ", description: "Describirá el título de la vulnerabilidad encontrada." },
            { id: "Criticidad: ", description: "Este campo contendrá la 'Clasificación' de la vulnerabilidad encontrada." }
        ],
        detailTable: [
            { id: "Tabla de Detalle. ", description: "Esta tabla contendrá tres tipos de información:" },
            { id: "Encabezado. ", description: "Este tipo de información contendrá el identificador, nombre, criticidad, valor CVSS junto con sus valores y los activos afectados." },
            { id: "Evidencias. ", description: "Se mostrará un texto descriptivo, así como capturas de pantalla del problema identificado." },
            { id: "Solución/Referencia. ", description: "Se describirá una primera solución para el problema." }
        ]
    },
    en: {
        title_identification: "4. Identification of ",
        vulnerabilities: "vulnerabilities",
        introduction: "4.1 Introduction",
        introduction1: "In this section we will proceed to describe all the vulnerabilities found throughout the analysis carried out. For a better understanding of the presentation format of these and the detail shown, a series of security metrics concepts will be explained.",
        introduction2: "The vulnerabilities found will be evaluated using a standard called Common Vulnerability Scoring System, in its two versions, version 3.1 and version 2.0 (CVSS). This standard allows establishing a severity measure in a range from 0 to 10, with 10 being the highest possible criticality. Two versions of CVSS have been used because some of the tools only provide information in version 2.0 and not in version 3.1",
        introduction3: "Version 3.1 considers the following risk factors:",
        introduction4: "While version 2.0 considers the following factors, which are:",
        introduction5: "Based on the total value obtained, a series of thresholds are established that provide qualitative values:",
        table2: "Table 2. CVSS rating",
        table2_explication: "These vectors are shown in the CVSS section of each vulnerability table along with the value obtained. Based on this system, the format of the results is made up of two types of tables:",
        table2_example: "An example of this type of table is shown below:",
        table2_figure: "Figure 1. Detail table format",
        table2_sections: "In the following sections, the summary table will be shown first, and then for each vulnerability its corresponding detail table.",
        vectors1: [
            { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
            { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" },
            { id: "Privileges Required (PR): ", description: "High (H), Low (L), None (N)" },
            { id: "User Interaction (UI): ", description: "Required (R), None (N)" },
            { id: "Scope (S): ", description: "Changed (C), Unchanged (U)" },
            { id: "Confidenciality (C): ", description: "None (N), Partial (P), Complete (C)" },
            { id: "Integrity (I): ", description: "None (N), Partial (P), Complete (C)" },
            { id: "Availability (A): ", description: "None (N), Partial (P), Complete (C)" }
        ],
        vectors2_1: [
            { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
            { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" }
        ],
        vectors2_2: [
            { id: "Authentication (Au): ", description: "Multiple (M), Single (S), None (N)" },
            { id: "Confidenciality Impact (C): ", description: "None (N), Partial (P), Complete (C)" },
            { id: "Integrity Impact (I): ", description: "None (N), Partial (P), Complete (C)" },
            { id: "Availability Impact (A): ", description: "None (N), Partial (P), Complete (C)" }
        ],
        scoreTable: [
            ["CVSS Score", "Rating"],
            ["0.0", "None"],
            ["0.1 -3.9", "Low"],
            ["4.0 - 6.0", "Medium"],
            ["7.0 - 8.9", "High"],
            ["9.0 - 10.0", "Critical"]
        ],
        resumeTable: [
            { id: "Summary Table: ", description: "It is the table where all the vulnerabilities found are summarized in the following format:" },
            { id: "Identificator: ", description: "It will be displayed using the following format XYZ-nnn, where nnn will be a number that reflects the vulnerability number." },
            { id: "Name: ", description: "It will describe the title of the vulnerability found." },
            { id: "Criticality: ", description: "This field will contain the 'Rating' of the vulnerability found." }
        ],
        detailTable: [
            { id: "Summary Table. ", description: "This table will contain three types of information:" },
            { id: "Header. ", description: "This type of information will contain the identifier, name, criticality, CVSS value along with its values ​and the affected assets." },
            { id: "Evidencias. ", description: "A descriptive text will be displayed, as well as screenshots of the identified problem." },
            { id: "Solution/Reference. ", description: "A first solution for the problem will be described." }
        ]
    }
} as const
const VulnsSection: React.FC<{template?: Template, lang?: string }> = ({ template, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style= {{ paddingBottom: 50, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].title_identification}</Text>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].vulnerabilities}</Text>
                    <Text style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>{i18n[locale].introduction}</Text>
                    <Text style={{ ...styles.textua, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introduction1}</Text>
                    <Text style={{ ...styles.textua, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                        {i18n[locale].introduction2}</Text>
                    <Text style={{ ...styles.textua, top: 2, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introduction3}</Text>
                    {i18n[locale].vectors1.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                            <Text style={styles.puntuak}>{point.description}</Text>
                        </Text>
                    ))}
                    <Text style={{ ...styles.textua, marginRight: 1, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introduction4}</Text>
                    {i18n[locale].vectors2_1.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                            <Text style={styles.puntuak}>{point.description}</Text>
                        </Text>
                    ))}
                </View>
                <View style={{ ...styles.column, marginLeft: 0 }}>
                    {i18n[locale].vectors2_2.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                            <Text style={styles.puntuak}>{point.description}</Text>
                        </Text>
                    ))}
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 1 }}>{i18n[locale].introduction5}</Text>
                    <View style={{ ...styles.tableScope, width: "85%" }}>
                        {i18n[locale].scoreTable.map((fila, indexFila) => (
                            <View key={indexFila} style={{ ...styles.tableRowScope, backgroundColor: indexFila === 0 ? "#271968" : "transparent", fontFamily: indexFila === 0 ? "Bold" : "Griff", color: indexFila === 0 ? "white" : "black" }}>
                                {fila.map((dato, indexDato) => (
                                    <Text key={indexDato} style={{ ...styles.tableColScope, borderBottomWidth: indexFila === i18n[locale].scoreTable.length - 1 ? 1 : 0, borderRightWidth: indexDato === fila.length - 1 ? 1 : 0 }}>
                                        {`${dato} `}
                                    </Text>
                                ))}
                            </View>
                        ))}
                    </View>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: -1, color: "gray" }} >{i18n[locale].table2}</Text>
                    <Text style={{ ...styles.textua, marginRight: 2, fontFamily: "Griff", fontSize: 10, marginTop: -5 }}>{i18n[locale].table2_explication}
                    </Text>
                    {i18n[locale].resumeTable.map((point, index) => (
                        (index === 0 || index === 1) && (
                            <Text key={index} style={{ ...styles.puntuak, top: 2, marginRight: 50, fontFamily: "Griff", fontSize: 10, marginLeft: index === 1 ? 20 : 0 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, marginRight: 1, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                                <Text style={{ ...styles.puntuak, marginRight: 1, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                            </Text>
                        )
                    ))}
                </View>
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}
const VulnsSection2: React.FC<{template?: Template, lang?: string }> = ({ template, lang }) => {
    const context = useContext(I18nContext)

    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    } return (
        <Page size="A4" style= {{ paddingBottom: 60, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    {i18n[locale].resumeTable.map((point, index) => (
                        (index === 2 || index === 3) && (
                            <Text key={index} style={{ ...styles.puntuak, top: 2, marginLeft: 50, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                                <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                            </Text>
                        )
                    ))}
                    {i18n[locale].detailTable.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 20, marginLeft: index > 0 ? 50 : 0, fontFamily: "Griff", fontSize: 9 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                        </Text>
                    ))}
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].table2_example}</Text>
                    <Image style={{ ...styles.image, width: 300, height: 200, paddingLeft: 40 }} src="/assets/img/report/tableDetail2.jpg" />
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: 8, color: "gray" }} >{i18n[locale].table2_figure}</Text>
                </View>
                <View style={styles.column}>
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].table2_sections}</Text>
                </View>
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}
export { VulnsSection, VulnsSection2 }
