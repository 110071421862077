import React, { useContext, useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { AssessmentType } from "@models/Assessment"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { IconButton, Tooltip } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { I18nContext } from "I18nProvider"
import { AssessmentTypeKeysValueOps } from ".."

interface ColorAndBg {
    color: string;
    "background-color": string;
}

type StateColorMap = {
    [key in AssessmentType]: ColorAndBg;
};

const getStateColor = (state: AssessmentType): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [AssessmentType.CRT]: { color: "black", "background-color": AssessmentTypeColors.crt },
        [AssessmentType.FunctionalTest]: { color: "black", "background-color": AssessmentTypeColors.functional_test },
        [AssessmentType.LogicalPentest]: { color: "black", "background-color": AssessmentTypeColors.logical_pentest },
        [AssessmentType.PortScan]: { color: "black", "background-color": AssessmentTypeColors.port_scan },
        [AssessmentType.NetworkScan]: { color: "black", "background-color": AssessmentTypeColors.network_scan },
        [AssessmentType.WebScan]: { color: "black", "background-color": AssessmentTypeColors.web_scan },
        [AssessmentType.SCA]: { color: "black", "background-color": AssessmentTypeColors.sca },
        [AssessmentType.ContainerScan]: { color: "black", "background-color": AssessmentTypeColors.container_scan },
        [AssessmentType.ThreatModel]: { color: "black", "background-color": AssessmentTypeColors.threat_model },
        [AssessmentType.StaticAnalysis]: { color: "black", "background-color": AssessmentTypeColors.static_analysis },
        [AssessmentType.DynamicAnalysis]: { color: "black", "background-color": AssessmentTypeColors.dynamic_analysis }
    }
    return stateColorMap[state]
}

interface AssessmentStateColoredProps {
  value: AssessmentType;
}

const AssessmentStateColored: React.FC<AssessmentStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg | null>(null)
    const [label, setLabel] = useState<string | undefined>("")
    const [description, setDescription] = useState<string | undefined>("")
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const assessmentTypeDescriptions: { [key in AssessmentType]: string } = {
        [AssessmentType.CRT]: context.t.translate("assessment_type_crt"),
        [AssessmentType.FunctionalTest]: context.t.translate("assessment_type_functional_test"),
        [AssessmentType.LogicalPentest]: context.t.translate("assessment_type_logical_pentest"),
        [AssessmentType.PortScan]: context.t.translate("assessment_type_port_scan"),
        [AssessmentType.NetworkScan]: context.t.translate("assessment_type_network_scan"),
        [AssessmentType.WebScan]: context.t.translate("assessment_type_web_scan"),
        [AssessmentType.SCA]: context.t.translate("assessment_type_sca"),
        [AssessmentType.ContainerScan]: context.t.translate("assessment_type_container_scan"),
        [AssessmentType.ThreatModel]: context.t.translate("assessment_type_threat_model"),
        [AssessmentType.StaticAnalysis]: context.t.translate("assessment_type_static_analysis"),
        [AssessmentType.DynamicAnalysis]: context.t.translate("assessment_type_dynamic_analysis")
    }

    useEffect(() => {
        if (value !== undefined) {
            setLabel(AssessmentTypeKeysValueOps.find(v => v.value === value)?.label)
            setColor(getStateColor(value))
            setDescription(assessmentTypeDescriptions[value])
        }
    }, [value])

    return (
        <>
            {value && (
                <div>
                    <Chip
                        size="small"
                        style={{
                            fontWeight: "bolder",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            color: color?.color,
                            backgroundColor: color?.["background-color"]
                        }}
                        label={label}
                    />
                    <Tooltip title={description}>
                        <IconButton>
                            <HelpOutlineIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </>
    )
}

export { AssessmentStateColored }
export default AssessmentStateColored
