import { Typography } from "@mui/material"
import React from "react"

interface PolicyNameNavigateProps {
    value?: string; // Hacerlo opcional en caso de que llegue undefined
    id: string;
}

const EventTypeColumn: React.FC<PolicyNameNavigateProps> = ({ value }) => {
    const formatText = (str?: string) => {
        if (!str) return "N/A" // Manejo seguro para valores undefined o vacíos
        return str
            .toLowerCase()
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }

    return <Typography fontSize="14px">{formatText(value)}</Typography>
}

export { EventTypeColumn }
export default EventTypeColumn
