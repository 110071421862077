import React, { createContext, ReactNode, useContext, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"

interface ConfirmationDialogContextProps {
    showDialog: (title: string, text: string, onConfirm: () => void, buttonText?: string) => void;
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContextProps | undefined>(undefined)

// Custom hook para usar el contexto
export const useConfirmationDialog = () => {
    const context = useContext(ConfirmationDialogContext)
    if (!context) {
        throw new Error("useConfirmationDialog must be used within a ConfirmationDialogProvider")
    }
    return context
}

// Provider que manejará el estado del diálogo
export const ConfirmationDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [dialogTitle, setDialogTitle] = useState("")
    const [dialogText, setDialogText] = useState("")
    const [onConfirmCallback, setOnConfirmCallback] = useState<(() => void) | null>(null)
    const [buttonText, setButtonText] = useState<string>()
    const showDialog = (title: string, text: string, onConfirm: () => void, buttonText?: string) => {
        setDialogTitle(title)
        setDialogText(text)
        setOnConfirmCallback(() => onConfirm)
        setOpen(true)
        setButtonText(buttonText)
    }

    const handleConfirm = () => {
        if (onConfirmCallback) {
            onConfirmCallback()
        }
        setOpen(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ConfirmationDialogContext.Provider value={{ showDialog }}>
            {children}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography variant="h5">{dialogTitle}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>{dialogText}</Typography>
                </DialogContent>
                <DialogActions>
                    <ActionButton variant="outlined" onClick={handleClose} text="Cancel" dataTestId="dialog-cancel-button" />
                    <ActionButton onClick={handleConfirm} text={buttonText ?? "Confirm"} dataTestId="dialog-confirm-button" />
                </DialogActions>
            </Dialog>
        </ConfirmationDialogContext.Provider>
    )
}
