import React, { useContext, useEffect, useRef, useState } from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { HierarchySelector } from "@components/hierarchy/HierarchySelector"
import { ServicesContext } from "@context/index"
import { ModelRelation } from "@models/utils"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { QueryParameters } from "@utils/index"
import Hierarchy from "@models/Hierarchy"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "./hierarchyUtils"
import HierarchyManage from "./HierarchyManage"

const HierarchyTree: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const [isEditing, setIsEditing] = useState(false)
    const [originalData, setOriginalData] = useState<Hierarchy[]>([])
    const [selectedClient, setSelectedClient] = useState<ModelRelation>({ id: "" })
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))
    const [hierarchies, setHierarchies] = useState<Hierarchy[]>([])
    const [updatedHierarchies, setUpdatedHierarchies] = useState<Hierarchy[]>([])
    const hierarchyRef = useRef<{ restore:() => void } | null>(null)
    const init = async () => {
        const { filters, orFilters } = getFilterOptions()
        const params: QueryParameters = {}
        if (filters.length > 0) {
            params.filters = filters
        }
        if (orFilters.length > 0) {
            params.orFilters = orFilters
        }

        if (filters.length > 0 || orFilters.length > 0) {
            const data = await hierarchyService.getAll(params)
            setHierarchies(data.list)
            setOriginalData(data.list)
        }
    }

    const getFilterOptions = (): { filters: FilterOption[], orFilters: FilterOption[] } => {
        const filters : FilterOption[] = []
        const orFilters : FilterOption[] = []
        if (selectedClient.id !== "") {
            filters.push({ field: "client_id", operation: FilterOperation.UUIDEqual, value: selectedClient.id })
            if (selectedHierarchies[0]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringEqual, value: selectedHierarchies[0].path })
            }
            if (selectedHierarchies[1]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringEqual, value: selectedHierarchies[1].path })
            }
            if (selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringEqual, value: selectedHierarchies[2].path })
            }
            if (selectedHierarchies[0] && !selectedHierarchies[1] && !selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringContains, value: selectedHierarchies[0].path })
            } else if (selectedHierarchies[0] && selectedHierarchies[1] && !selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringContains, value: selectedHierarchies[1].path })
            } else if (selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringContains, value: selectedHierarchies[2].path })
            }
        }
        return { filters, orFilters }
    }

    useEffect(() => {
        init()
    }, [selectedClient, selectedHierarchies])

    const handleSave = async () => {
        try {
            // Comparar hierarchies con updatedHierarchies y encontrar nodos con paths diferentes
            const nodesToUpdate: Hierarchy[] = []

            // Iterar sobre hierarchies y updatedHierarchies para encontrar los cambios
            updatedHierarchies.forEach((updatedNode, index) => {
                const originalNode = hierarchies[index]

                if (updatedNode && originalNode && updatedNode.path !== originalNode.path) {
                    // Si los paths son diferentes, agregar el nodo a la lista de actualización
                    nodesToUpdate.push(updatedNode)
                }
            })

            // Actualizar los nodos con paths diferentes
            for (const node of nodesToUpdate) {
                await hierarchyService.update(node.id, node)
            }

            // Después de actualizar, resetear el estado de edición y recargar los datos
            setIsEditing(false)
            init()
        } catch (error) {
            console.error(context.t.translate("failed_to_save"), error)
        }
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL - 1)
        setSelectedHierarchies(newSelectedHierarchies)
    }

    const getCustomFilters = (index: number): FilterOption[] => {
        if (index === 0) {
            if (selectedClient) {
                return [
                    { field: "client_id", operation: FilterOperation.UUIDEqual, value: selectedClient.id }
                    /* { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` } */
                    // { field: "path", operation: FilterOperation.StringEqual, value: "" }
                ]
            }
            return []
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            // { field: "path", operation: FilterOperation.StringEqual, value: parentHierarchy.path }
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }
    useEffect(() => {
        console.log(updatedHierarchies)
    }, [updatedHierarchies])
    const handleClientChange = (e: any) => {
        setSelectedHierarchies(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))
        setSelectedClient(e.target.value)
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">{context.t.translate("manage_hierarchy")}</Typography>
                <Grid item>

                    { isEditing &&
                        <Grid container spacing={1}>
                            <Grid item>
                                <ActionButton onClick={handleSave} text={context.t.translate("CRA_save")} />
                            </Grid>
                            <Grid item>
                                <ActionButton onClick={() => hierarchyRef.current?.restore()} text={context.t.translate("hierar_reset")} />
                            </Grid>
                        </Grid>}
                </Grid>
            </Grid>

            <Grid item container gap={2}>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ClientSelector value={selectedClient.id} onSelect={(id) => handleClientChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <HierarchySelector
                        value={selectedHierarchies[0]?.id || ""}
                        customFilters={getCustomFilters(0)}
                        onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                        index={0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    {selectedHierarchies[0] && (
                        <HierarchySelector
                            value={selectedHierarchies[1]?.id || ""}
                            customFilters={getCustomFilters(1)}
                            onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                            index={1}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    {selectedHierarchies[1] && (
                        <HierarchySelector
                            value={selectedHierarchies[2]?.id || ""}
                            customFilters={getCustomFilters(2)}
                            onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                            index={2}
                        />
                    )}
                </Grid>
            </Grid>

            <HierarchyManage fetchedHierarchies={hierarchies} setIsEditing={setIsEditing} ref={hierarchyRef} setUpdatedHierarchies={setUpdatedHierarchies} updatedHierarchies={updatedHierarchies}/>
        </Grid>

    )
}

export { HierarchyTree }
export default HierarchyTree
