/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react"
import { Box, BoxProps } from "@mui/material"

interface FadeInBoxProps extends BoxProps {
  isLoaded: boolean;
}

const FadeInBox: React.FC<FadeInBoxProps> = ({
    isLoaded,
    children,
    sx,
    ...props
}) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (isLoaded) {
            const timer = setTimeout(() => setIsVisible(true), 100)
            return () => clearTimeout(timer)
        }
    }, [isLoaded])

    return (
        <Box
            sx={{
                ...sx,
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.5s ease-in-out"
            }}
            {...props}
        >
            {children}
        </Box>
    )
}

export default FadeInBox
