import { Alert, Checkbox, Chip, Divider, FormControlLabel, Grid, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { AbilityContext } from "@components/permissions/index"
import { I18nContext } from "I18nProvider"
import SystemNotification, { CategoryType, SeverityType } from "@models/SystemNotification"
import { StyledTextarea } from "@components/common/textareas"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useTrack } from "@components/track/TrackContext"

const initValue: SystemNotification = {
    id: "",
    title: "",
    description: "",
    action: null,
    targetRole: "",
    channels: [],
    category: CategoryType.Maintenance,
    severity: SeverityType.Info,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    created_at: "",
    read: false
}

const severityColors = {
    Info: "#2196F3", // Azul para "Info"
    Warning: "#FF9800", // Naranja para "Warning"
    Error: "#F44336", // Rojo para "Error"
    Success: "#4CAF50" // Verde para "Success"
}

const categoryColors = {
    Sbom: "#00BCD4", // Cyan para "SBOM"
    Security: "#F44336", // Rojo para "Security"
    Compliance: "#FFC107", // Amarillo para "Compliance"
    Performance: "#4CAF50", // Verde para "Performance"
    Update: "#3F51B5", // Azul oscuro para "Update"
    Maintenance: "#FF9800" // Naranja para "Maintenance"
}

const SystemNotificationDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const systemNotificationService = useContext(ServicesContext).systemNotificationServices

    const [error, setError] = useState<Error|null>(null)

    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<SystemNotification>(initValue)
    const [formData, setFormData] = useState<SystemNotification>(initValue)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const ability = useContext(AbilityContext)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "SystemNotifcationDetail" })
    }, [])
    // Fetch license data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await systemNotificationService.get(id as string)
                setData(val)
                setFormData(val)
                handleRead(id as string, val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [id])

    const handleRead = async (id: string, data: any) => {
        try {
            await systemNotificationService.update(id as string, data)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    const navigate = useNavigate()

    // API Call save license - END

    if (data.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>{context.t.translate("loading")}</div>)

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("system_notification")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <StyledBox>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={error?.message || context.t.translate("error_message")}
                        />
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>

                                    <Grid item xs={2} alignSelf="right" >
                                        <Chip
                                            label={formData.category}
                                            sx={{
                                                backgroundColor: categoryColors[formData.category],
                                                color: "#000"
                                            }}
                                        />
                                        <Chip
                                            label={formData.severity}
                                            sx={{
                                                backgroundColor: severityColors[formData.severity],
                                                color: "#fff",
                                                marginLeft: "15px"
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={12}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("title")}</Typography>
                                    <TextField
                                        disabled={!ability.can("read", "SystemNotification")}
                                        name="title"
                                        value={formData.title}
                                        variant="standard"
                                        style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("description")}</Typography>
                                    <StyledTextarea disabled minRows={3} name="description" value={formData.description} style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("date")}</Typography>
                                    <TextField
                                        disabled={true}
                                        name="date"
                                        value={formData.date}
                                        variant="standard"
                                        style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                        InputProps={{
                                            readOnly: true // Asegura que el campo no sea editable
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("action")}</Typography>
                                    <TextField
                                        disabled={true}
                                        name="action"
                                        value={formData.action ?? ""} // Handle possible null value
                                        variant="standard"
                                        style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("read_status")}</Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!ability.can("update", "SystemNotification")} // Changed to "update" to allow changing the state
                                                checked={formData.read}
                                                color="primary"
                                            />
                                        }
                                        label={formData.read ? "Yes" : "No"}
                                        style={{ fontFamily: "Griff", color: theme.palette.primary.main }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("target_role")}</Typography>
                                    <TextField
                                        disabled={true}
                                        name="targetRole"
                                        value={formData.targetRole}
                                        variant="standard"
                                        style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                        InputProps={{
                                            readOnly: true // Asegura que el campo no sea editable
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("channels")}</Typography>
                                    <TextField
                                        disabled={!ability.can("read", "SystemNotification")}
                                        name="channels"
                                        value={formData.channels.join(", ")} // Join array for display
                                        variant="standard"
                                        style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                    />
                                </Grid>

                            </Grid>

                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid >
        </Grid >

    </Grid>
    )
}

export { SystemNotificationDetail }
export default SystemNotificationDetail
