import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import DownloadIcon from "@mui/icons-material/Download"
import HelpIcon from "@mui/icons-material/Help"
import {
    Box,
    Button,
    Grid,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    useTheme
} from "@mui/material"
import React from "react"
import ConsoleCommand from "./ConsoleCommand" // Asegúrate de que la ruta sea correcta
interface CustomTabPanelProps {
    value: number;
    index: number;
    context: any; // Ajusta el tipo según tu contexto
    commands: string[];
    handleDownloadOnline: () => void;
    handleHelpOpen: (type: "offline" | "online") => void;
    copyContent: (command: string) => void;
}

const CustomTabPanel: React.FC<CustomTabPanelProps> = ({
    value,
    index,
    context,
    commands,
    handleDownloadOnline,
    handleHelpOpen,
    copyContent
}) => {
    const theme = useTheme()

    if (value !== index) {
        return null // Solo renderiza si el tab está activo
    }

    return (
        <Box sx={{ color: theme.palette.primary.main }}>
            <Grid item xs={12}>
                <Typography fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                    {context.t.translate("dialogs.installationTitle")}
                </Typography>
            </Grid>
            <Box display="flex" alignItems="center" mb={2} justifyContent="center" flexDirection="column" mt="15px">
                <Grid
                    container
                    item
                    sx={{ border: "1px solid #4A4733", borderRadius: "4px", p: 2 }}
                    alignItems="center"
                    direction="row"
                    xs={12}
                >
                    <Grid container direction="row" display="flex">
                        <Grid sx={{ display: "flex", alignContent: "center" }} gap={1}>
                            <WarningAmberIcon color="warning" sx={{ fontSize: 30 }} />
                            <Typography fontSize="18px" fontFamily="Griff">
                                {context.t.translate("dialogs.portableBinaryLimitations")}
                            </Typography>
                        </Grid>
                        <Grid>
                            <ul style={{ paddingLeft: "30px" }}>
                                {context.t.translate("dialogs.requirementsList").map((requirement: string, index: number) => (
                                    <li key={index}>{requirement}</li>
                                ))}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 3, mb: 3 }}>
                <Typography fontSize="16px" fontWeight={600}>{context.t.translate("stepper.title")}</Typography>
                <Stepper orientation="vertical" sx={{ color: "primary" }} >

                    <Step active>
                        <StepLabel
                            sx={{
                                "& .MuiStepLabel-label": {
                                    color: "primary"
                                },
                                "&.Mui-active": {
                                    color: "primary"
                                },
                                "&.Mui-completed": {
                                    color: "primary"
                                }
                            }}>{context.t.translate("stepper.download")}</StepLabel>
                        <StepContent>
                            <Box mt={1} >
                                <Typography style={{ margin: "0px" }}>{context.t.translate("stepper.step1_description")}</Typography>
                                <Button
                                    variant="contained"
                                    sx={{ color: theme.palette.primary.contrastText }}
                                    onClick={handleDownloadOnline}
                                >
                                    <DownloadIcon />
                                    <Typography>
                                        {context.t.translate("download")} myorbik-tools(.zip, {index === 0 ? "16kb" : "6kb"})
                                    </Typography>
                                </Button>
                                <IconButton onClick={() => handleHelpOpen("online")} sx={{ ml: 1 }}>
                                    <HelpIcon />
                                </IconButton>
                            </Box>
                        </StepContent>
                    </Step>

                    <Step active>
                        <StepLabel sx={{
                            "& .MuiStepLabel-label": {
                                color: "primary"
                            },
                            "&.Mui-active": {
                                color: "primary"
                            },
                            "&.Mui-completed": {
                                color: "primary"
                            }
                        }}>
                            {context.t.translate("stepper.step2")}
                        </StepLabel>
                        <StepContent>
                            <ConsoleCommand command={commands[0]} copyContent={copyContent} />
                        </StepContent>
                    </Step>

                    <Step active>
                        <StepLabel sx={{
                            "& .MuiStepLabel-label": {
                                color: "primary"
                            },
                            "&.Mui-active": {
                                color: "primary"
                            },
                            "&.Mui-completed": {
                                color: "primary"
                            }
                        }}>{context.t.translate("stepper.step3")}</StepLabel>
                        <StepContent>
                            <Typography style={{ margin: "0px" }}>{context.t.translate("stepper.step3_description")}</Typography>
                            <ConsoleCommand command={commands[1]} copyContent={copyContent} />
                        </StepContent>
                    </Step>
                </Stepper>
            </Box>
            <Grid item xs={12} mt="15px">
                <Typography fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                    {context.t.translate("dialogs.usageTitle")}
                </Typography>
            </Grid>

            <Box display="flex" alignItems="center" mb={2} justifyContent="center" flexDirection="column" mt="15px">
                <Grid
                    container
                    item
                    sx={{ border: "1px solid #4A4733", borderRadius: "4px", p: 2 }}
                    alignItems="center"
                    direction="row"
                    xs={12}
                >
                    <Grid container direction="row" display="flex">
                        <Grid sx={{ display: "flex", alignContent: "center" }} gap={1}>
                            <WarningAmberIcon color="warning" sx={{ fontSize: 30 }} />
                            <Typography fontSize="18px" fontFamily="Griff">
                                {context.t.translate("dialogs.usageRequirements")}
                            </Typography>
                        </Grid>
                        <Grid>
                            <ul style={{ paddingLeft: "30px" }}>
                                {context.t.translate("dialogs.usageRequirementsList").map((requirement: string, index: number) => (
                                    <li key={index}>{requirement}</li>
                                ))}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid item xs={12} mt="15px">
                <Typography fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                    {context.t.translate("dialogs.commands")}
                </Typography>
            </Grid>
            <Typography fontSize="14px" >{context.t.translate("myorbiktoolbox_commands")}</Typography>
            <Grid sx={{ border: "1px solid #333124", backgroundColor: theme.palette.background.paper, borderRadius: "8px", padding: "16px" }}>
                <Typography fontSize="12px">{context.t.translate("dialogs.executionExamplesList")[0]}</Typography>
                <ConsoleCommand
                    command={commands[0]}
                    copyContent={copyContent}
                ></ConsoleCommand>
            </Grid>

            <Box display="flex" alignItems="center" mb={2} justifyContent="center" flexDirection="column">
                <Grid sx={{ width: "100%" }} gap={2}>
                    <Typography fontSize="14px" marginTop="15px" >{context.t.translate("myorbiktoolbox_commands2")}</Typography>
                    {context.t.translate("dialogs.executionExamplesList")
                        .slice(1)
                        .map((example: string, index: number) => (
                            <Grid key={index} sx={{ border: "1px solid #333124", borderRadius: "8px", padding: "16px", marginBottom: "15px" }}>
                                <Typography fontSize="12px">{context.t.translate("dialogs.executionExamplesList")[index + 1]}</Typography>
                                <ConsoleCommand
                                    command={commands[index + 1]}
                                    copyContent={copyContent}
                                ></ConsoleCommand>
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </Box>
    )
}

export default CustomTabPanel
