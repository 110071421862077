import React, { useContext, useEffect, useRef, useState } from "react"
import {
    alpha, Avatar, Badge, Box, Button, ButtonBase, Chip, Divider, Grid, IconButton, Link, List, ListItem, Popover, Tooltip, Typography
    , useTheme
} from "@mui/material"
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import EmailIcon from "@mui/icons-material/Email"
import { styled } from "@mui/material/styles"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ClearIcon from "@mui/icons-material/Clear"
import { SystemNotification } from "@models/SystemNotification"
import ActionButton from "@components/common/Button/ActionButton"
import { useNavigate } from "react-router-dom"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import ServicesContext from "@context/ServicesContext"
import { format } from "date-fns"
import { I18nContext } from "I18nProvider"
import SystemMessage, { MessageType } from "@models/SystemMessage"
import User from "@models/User"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import LanguageSelector from "@components/common/switches/LanguageSelector"
import PhoneIcon from "@mui/icons-material/Phone"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"

const message: SystemMessage = {

    id: "msg_001",
    title: "Actualización de Seguridad",
    message: "Se ha detectado una nueva vulnerabilidad en el sistema. Es recomendable actualizar a la última versión.",
    action: "Actualizar ahora",
    type: MessageType.Conversational,
    date: "2025-02-19T10:30:00Z",
    read: false,
    created_at: "2025-02-19T08:15:00Z"

}

export const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
      
      .MuiBadge-badge {
          background-color: ${alpha(theme.palette.error.main, 0.1)};
          color: ${theme.palette.error.main};
          min-width: 16px; 
          height: 16px;
          padding: 0;
  
          &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
              content: "";
          }
      }
  `
)

const severityColors = {
    Info: "#2196F3", // Azul para "Info"
    Warning: "#FF9800", // Naranja para "Warning"
    Error: "#F44336", // Rojo para "Error"
    Success: "#4CAF50" // Verde para "Success"
}

const messageTypeColors = {
    Conversational: "#2196F3", // Azul para "Info"
    RequiredAction: "#FF9800" // Naranja para "Warning"
}

const initValue: User = {
    firstLogin: "",
    id: "",
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    groups: [],
    last_login: ""
}

function HeaderNotifications () {
    const ref = useRef<any>(null)
    const theme = useTheme()

    const navigate = useNavigate()
    const [isOpenNotifications, setOpenNotifications] = useState<boolean>(false)
    const [clientId, setClientId] = useState<string>("")
    const [isOpenUser, setOpenUser] = useState<boolean>(false)
    const [isOpenMessage, setOpenMessage] = useState<boolean>(false)
    const [isOpenSupport, setOpenSupport] = useState<boolean>(false)
    const [totalNotifications, setTotalNotifications] = useState<number>(1)
    const [totalMessages, setTotalMessages] = useState<number>(0)
    const [systemNotifications, setSystemNotifications] = useState<SystemNotification[]>([])
    const [systemMessages, setSystemMessages] = useState<SystemMessage[]>([message])
    const userService = useContext(ServicesContext).userService
    const [userData, setUserData] = useState<User>(initValue)
    const clientService = useContext(ServicesContext).clientService
    const [userId, setUserId] = useState<string>("")
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const systemNotificationService = useContext(ServicesContext).systemNotificationServices
    const fetchNotifications = async () => {
        try {
            const params: QueryParameters = {
                filters: [
                    { field: "read", operation: FilterOperation.StringEqual, value: "false" }
                ]
            }
            const response = await systemNotificationService.getAll(params)
            setSystemNotifications(response.list)
            setTotalNotifications(response.count)
        } catch (error) {
            console.error("Error al obtener las notificaciones:", error)
            setSystemNotifications([]) // Establecer una lista vacía en caso de error
            setTotalNotifications(0) // Establecer el total a 0 en caso de error
        }
    }

    // Función para agregar el mensaje al estado
    const fetchMessages = () => {
        setTotalMessages(0)
    }

    useEffect(() => {
        fetchNotifications()
        fetchMessages()
        console.log("fetchMessages", systemMessages)
    }, [])

    const handleOpenNotifications = (): void => {
        setOpenNotifications(true)
    }

    const handleCloseNotifications = (): void => {
        setOpenNotifications(false)
    }

    const handleOpenUser = (): void => {
        setOpenUser(true)
    }

    const handleCloseUser = (): void => {
        setOpenUser(false)
    }

    const handleOpenSupport = (): void => {
        setOpenSupport(true)
    }

    const handleCloseSupport = (): void => {
        setOpenSupport(false)
    }
    const handleOpenMessage = (): void => {
        setOpenMessage(true)
    }

    const handleCloseMessage = (): void => {
        setOpenMessage(false)
    }

    const handleReadNotifications = () => {
        systemNotificationService.readAll().then((response: any) => {
            fetchNotifications()
        })
    }

    useEffect(() => {
        const fetchClient = async () => {
            try {
                const response = await clientService.getAll()
                setClientId(response.list[0].id)
            } catch (error: any) {
                console.log(error)
            }
        }
        fetchClient()
    }, [])

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const users = await clientService.getUsers(clientId)
                console.log("Users: " + JSON.stringify(users))
                console.log(users.list[0].id)
                setUserId(users.list[0].id)
            } catch (e: any) {
                console.log(e)
            }
        }
        fetchUserId()
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await userService.get(userId as string)
                setUserData(data)
            } catch (e: any) {
                console.log(e)
            }
        }
        if (userId) fetchData()
    }, [userId, userService])
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const res = await userService.get("actual") // GET /v1/user/me
                setUserData(res)
            } catch (err) {
                console.error("Error fetching current user", err)
            }
        }

        fetchUserData()
    }, [])

    return (
        <>
            <Tooltip arrow title={context.t.translate("user")}>
                <IconButton color="primary" ref={ref} onClick={handleOpenUser}>
                    <AccountCircleIcon />
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={ref.current}
                onClose={handleCloseUser}
                open={isOpenUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {/* Encabezado */}
                <Grid
                    sx={{
                        p: 2,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: theme.palette.background.paper
                    }}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography fontSize="16px" fontWeight="bolder" color="primary">
                        {context.t.translate("user")}
                    </Typography>
                    <IconButton size="small" color="info" onClick={() => handleCloseUser()}>
                        <ClearIcon color="error" />
                    </IconButton>
                </Grid>

                {/* Información del usuario */}
                <Grid container spacing={2} sx={{ p: 2, minWidth: 300 }}>
                    {/* Primera columna: Avatar y Username */}
                    <Grid item xs={6} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                        <Grid item display="flex" alignItems="center">
                            <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 1 }}>
                                {userData.firstname?.[0]?.toUpperCase() || userData.lastname?.[0]?.toUpperCase() || "?"}
                            </Avatar>
                            <Typography variant="h6">{userData.firstname} {userData.lastname}</Typography>
                        </Grid>

                        <Typography variant="body2" color="text.secondary">
                            @{userData.username}
                        </Typography>
                    </Grid>

                    {/* Segunda columna: Email, Teléfono y Ubicación */}
                    <Grid item xs={6} display="flex" flexDirection="column" justifyContent="space-between">
                        <Typography variant="body2">
                            <strong>{context.t.translate("email")}:</strong> {userData.email}
                        </Typography>
                        {userData.phone && (
                            <Typography variant="body2">
                                <strong>{context.t.translate("phone")}:</strong> {userData.phone}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Popover>

            {totalMessages > 0 && (
                <>

                    <Tooltip arrow title={context.t.translate("message")}>
                        <IconButton color="primary" ref={ref} onClick={handleOpenMessage}>
                            <NotificationsBadge
                                badgeContent={totalMessages}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                            >
                                <EmailIcon />
                            </NotificationsBadge>
                        </IconButton>
                    </Tooltip>
                    <Popover
                        anchorEl={ref.current}
                        onClose={handleCloseMessage}
                        open={isOpenMessage}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        {/* Encabezado sticky */}
                        <Grid
                            sx={{
                                p: 2,
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                backgroundColor: theme.palette.background.paper
                            }}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Typography
                                    fontSize="16px"
                                    fontFamily="Griff"
                                    color="primary"
                                    fontWeight="bolder"
                                >
                                    {context.t.translate("messages_text")}
                                </Typography>
                            </Grid>

                            <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                <IconButton size="small" color="info" onClick={() => handleReadNotifications()}>
                                    <ClearIcon color="error" />
                                    <Typography fontSize="12px" fontFamily="Griff" color="error" sx={{ ml: 0.5 }}>
                                        {context.t.translate("read_all")}
                                    </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />

                        {/* Lista de mensajes */}
                        <List sx={{ p: 0, maxHeight: "40vh", overflowY: "auto" }}>
                            {systemMessages.slice(0, 8).map((element) => (
                                <ListItem key={element.id} sx={{ p: 2, minWidth: 350, display: { xs: "block", sm: "flex", "&:hover": { backgroundColor: theme.palette.primary.light, cursor: "pointer" } } }} onClick={() => { if (element.action) { navigate("./message/" + element.id) } handleCloseMessage() }}>
                                    <Box flex="1">
                                        <Box display="flex" justifyContent="space-between" alignItems="center">

                                            <ButtonBase onClick={() => {
                                                if (element.action) {
                                                    navigate("./message/" + element.id)
                                                }
                                            }}>
                                                <Chip label={element.title} sx={{ backgroundColor: messageTypeColors[element.type], color: "#fff" }} />
                                            </ButtonBase>
                                            <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                                {element.created_at ? format(new Date(element.created_at), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                            </Typography>
                                        </Box>

                                        <Tooltip title={element.message.length > 200 ? element.message : ""}>
                                            <Typography component="span" variant="body2" color="text.secondary">
                                                {element.message.length > 200 ? `${element.message.slice(0, 200)}...` : element.message}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />

                        {/* Botón sticky */}
                        <Grid
                            container
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "5px 0px",
                                position: "sticky",
                                bottom: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 1
                            }}
                        >
                            <ActionButton icon={<ArrowForwardIosIcon />} text="Mark all as read" onClick={() => { navigate("/message") }} />
                        </Grid>
                    </Popover>
                </>
            )}

            <>

                <Tooltip arrow title={context.t.translate("notifications")}>
                    <IconButton color="primary" ref={ref} onClick={handleOpenNotifications}>
                        <NotificationsBadge
                            badgeContent={totalNotifications}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                        >
                            <NotificationsActiveTwoToneIcon />
                        </NotificationsBadge>
                    </IconButton>
                </Tooltip>
                <Popover
                    anchorEl={ref.current}
                    onClose={handleCloseNotifications}
                    open={isOpenNotifications}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    {/* Encabezado sticky */}
                    <Grid
                        sx={{
                            p: 2,
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            backgroundColor: theme.palette.background.paper
                        }}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography
                                fontSize="16px"
                                fontFamily="Griff"
                                color="primary"
                                fontWeight="bolder"
                            >
                                {context.t.translate("notifications")}
                            </Typography>
                        </Grid>

                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton size="small" color="info" onClick={() => handleReadNotifications() }>
                                <ClearIcon color="error" />
                                <Typography fontSize="12px" fontFamily="Griff" color="error" sx={{ ml: 0.5 }}>
                                    {context.t.translate("read_all")}
                                </Typography>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider />

                    {/* Lista de notificaciones */}
                    <List sx={{ p: 0, maxHeight: "40vh", overflowY: "auto" }}>
                        {systemNotifications.slice(0, 8).map((element) => (
                            <ListItem key={element.id} sx={{ p: 2, minWidth: 350, display: { xs: "block", sm: "flex", "&:hover": { backgroundColor: theme.palette.primary.light, cursor: "pointer" } } }} onClick={() => { if (element.action) { navigate("./system_notification/" + element.id) } }}>
                                <Box flex="1">
                                    <Box display="flex" justifyContent="space-between" alignItems="center">

                                        <ButtonBase onClick={() => {
                                            if (element.action) {
                                                navigate("./system_notification/" + element.id)
                                            }
                                        }}>
                                            <Chip label={element.title} sx={{ backgroundColor: severityColors[element.severity], color: "#fff" }}/>
                                        </ButtonBase>
                                        <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                            {element.created_at ? format(new Date(element.created_at), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                        </Typography>
                                    </Box>

                                    <Tooltip title={element.description.length > 200 ? element.description : ""}>
                                        <Typography component="span" variant="body2" color="text.secondary">
                                            {element.description.length > 200 ? `${element.description.slice(0, 200)}...` : element.description}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />

                    {/* Botón sticky */}
                    <Grid
                        container
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px 0px",
                            position: "sticky",
                            bottom: 0,
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1
                        }}
                    >
                        <ActionButton icon={<ArrowForwardIosIcon />} text="See all" onClick={() => { navigate("/system_notification") }} />
                    </Grid>
                </Popover>
            </>

            {/* Support */}
            <Tooltip arrow title={context.t.translate("support")}>
                <IconButton color="primary" ref={ref} onClick={handleOpenSupport}>
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={ref.current}
                onClose={handleCloseSupport}
                open={isOpenSupport}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Grid
                    sx={{
                        p: 2,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: theme.palette.background.paper
                    }}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography fontSize="16px" fontWeight="bolder" color="primary">
                        {context.t.translate("support")}
                    </Typography>
                    <IconButton size="small" color="info" onClick={() => handleCloseSupport() }>
                        <ClearIcon color="error" />
                    </IconButton>
                </Grid>

                <Grid container spacing={2} sx={{ p: 2, minWidth: 250 }}>
                    <Grid container item xs={12} justifyContent="justify-between" >
                        <Grid item xs={8} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                            <Typography fontSize="16px" fontWeight="bolder" color="primary">
                                {context.t.translate("setting_info")}
                            </Typography>
                            <Typography fontSize="16px" fontWeight="bolder" color="primary">
                                {context.t.translate("system_settings")}
                            </Typography>
                            {/* <CustomizedSwitches /> */}
                            <LanguageSelector />
                        </Grid>
                        <Grid item xs={4} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                            <Typography fontSize="16px" fontWeight="bolder" color="primary">
                                {context.t.translate("FAQ")}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate("/support/faq")}
                                sx={{ mt: 1 }}
                            >
                                <QuestionAnswerIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>
                            {context.t.translate("support")}
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontFamily: "Griff" }}>
                            {context.t.translate("have_any_question")}
                        </Typography>
                        <Typography>
                            {context.t.translate("setting_check_subscription")}:{" "}
                            <Link href="https://orbik-cybersecurity.com/my-orbik/" target="_blank" rel="noopener noreferrer">
                                                https://orbik-cybersecurity.com/my-orbik/
                            </Link>
                        </Typography>
                        <Typography>
                            <EmailIcon /> info@orbik-cybersecurity.com
                        </Typography>
                        <Typography>
                            <PhoneIcon /> 634 753 615
                        </Typography>
                        <Typography>
                            <LinkedInIcon />
                            <Link href="https://www.linkedin.com/company/orbik-cybersecurity/" target="_blank" rel="noopener noreferrer">
                                                LinkedIn
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}

export default HeaderNotifications
