import React from "react"
import { Grid, Skeleton } from "@mui/material"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"

const AverageSkeleton: React.FC = () => {
    return (
        <Grid container spacing={1} direction="column">
            <Grid item>
                <Skeleton variant="text" width={200} height={40} />
            </Grid>

            <Grid item container spacing={1} justifyContent="space-between">
                {[
                    { color: CVSS3SeverityColors.low },
                    { color: CVSS3SeverityColors.medium },
                    { color: CVSS3SeverityColors.high },
                    { color: CVSS3SeverityColors.critical }
                ].map((item, index) => (
                    <Grid item xs={3} key={index} style={{ textAlign: "center" }}>
                        <Skeleton
                            variant="circular"
                            width={60}
                            height={60}
                            sx={{
                                margin: "auto",
                                backgroundColor: `${item.color}20`
                            }}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid item container spacing={1} justifyContent="space-between">
                {[1, 2, 3, 4].map((item) => (
                    <Grid item xs={3} key={item} style={{ textAlign: "center" }}>
                        <Skeleton
                            variant="text"
                            width={60}
                            height={20}
                            sx={{ margin: "auto" }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default AverageSkeleton
