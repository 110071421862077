import React, { useContext } from "react"
import { Avatar, Box, Card, CardContent, Chip, Divider, Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { useNavigate } from "react-router-dom"
import Tag from "@models/Tag"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import StarIcon from "@mui/icons-material/Star"

interface RecentFavoriteTagsProps {
    recentTags: Tag[]
    favouriteTags: Tag[]
}

const RecentFavoriteTags: React.FC<RecentFavoriteTagsProps> = ({ recentTags, favouriteTags }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    const navigate = useNavigate()
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }
    const getTagIcon = (tag: Tag) => {
        // Si no hay un avatar específico, usamos la primera letra del nombre del tag
        return tag.name.charAt(0).toUpperCase()
    }

    return (
        <Grid container spacing={3} sx={{ padding: 2 }}>
            <Grid item xs={12} md={6}>
                <Card elevation={3} sx={{
                    borderRadius: 2,
                    height: "100%"
                }}>
                    <CardContent>
                        <Box display="flex" alignItems="center" mb={2}>
                            <AccessTimeIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                            <Typography variant="h5" fontWeight="bold">
                                {context.t.translate("recent_tags")}
                            </Typography>
                        </Box>
                        <Divider sx={{ mb: 2 }} />
                        {recentTags.length > 0
                            ? (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                    {recentTags.map(tag => (
                                        <Chip
                                            key={tag.id}
                                            avatar={<Avatar sx={{ bgcolor: tag.color }}>{getTagIcon(tag)}</Avatar>}
                                            label={tag.name}
                                            onClick={() => navigate("./" + tag.id)}
                                            sx={{
                                                m: 0.5,
                                                fontWeight: "medium",
                                                transition: "all 0.2s",
                                                "&:hover": {
                                                    transform: "scale(1.05)",
                                                    boxShadow: "0 2px 5px rgba(0,0,0,0.2)"
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                            )
                            : (
                                <Typography color="textSecondary" align="center" sx={{ py: 3 }}>
                                    {context.t.translate("no_recent_tags")}
                                </Typography>
                            )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card elevation={3} sx={{
                    borderRadius: 2,
                    height: "100%"
                }}>
                    <CardContent>
                        <Box display="flex" alignItems="center" mb={2}>
                            <StarIcon sx={{ color: theme.palette.warning.main, mr: 1 }} />
                            <Typography variant="h5" fontWeight="bold">
                                {context.t.translate("favorite_tags")}
                            </Typography>
                        </Box>
                        <Divider sx={{ mb: 2 }} />
                        {favouriteTags.length > 0
                            ? (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                    {favouriteTags.map(tag => (
                                        <Chip
                                            key={tag.id}
                                            avatar={<Avatar sx={{ bgcolor: tag.color }}>{getTagIcon(tag)}</Avatar>}
                                            label={tag.name}
                                            onClick={() => navigate("./" + tag.id)}
                                            sx={{
                                                m: 0.5,
                                                fontWeight: "medium",
                                                transition: "all 0.2s",
                                                "&:hover": {
                                                    transform: "scale(1.05)",
                                                    boxShadow: "0 2px 5px rgba(0,0,0,0.2)"
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                            )
                            : (
                                <Typography color="textSecondary" align="center" sx={{ py: 3 }}>
                                    {context.t.translate("no_favorite_tags")}
                                </Typography>
                            )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default RecentFavoriteTags
