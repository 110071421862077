
import { ModelRelation } from "@models/utils"

type Product = {
 id: string;
 name: string;
 type: ProductType;
 description: string;
 version: string;
 hierarchies: string[];
 inheritance: boolean;
 client: ModelRelation;
 created_at: string;
 location: string;
 provider_ids:string[]
}

type ProductExt = Product & {
    NumVulnerability: number;
    Risk: string;
}

type ProductCaf = {
    id: string;
    name: string;
    type: ProductType;
    description: string;
    version: string;
    hierarchies: string[];
    inheritance: boolean;
    client: ModelRelation;
    created_at: string;
    location: string;
    num_vulnerability: {detected: number, in_triage: number, triage_finish: number};
    provider_ids:string[]
   }

enum ProductType {
 Application = "application",
 Library = "library",
 Firmware = "firmware",
 OS = "operating-system",
 Container = "container",
 Device = "device",
}

export { ProductType }
export type { Product, ProductExt, ProductCaf }
export default Product
