import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

const i18n = {
    es: {
        title_introduction: "1. Introducción",
        introduction1: "Este documento describe los resultados del análisis de vulnerabilidades que se ha realizado en el dispositivo o software objetivo.",
        introduction2: "El análisis de vulnerabilidades tiene como objetivo identificar y caracterizar fallos de diseño, debilidades y vulnerabilidades del sistema. Esta actividad, que se lleva a cabo de forma rutinaria en la fase de verificación y validación del producto, es un requisito específico de la norma IEC 62443.",
        introduction3: "El informe está organizado de la siguiente manera:",
        way: [
            { id: "Resumen Ejecutivo: ", description: "Se presenta un resumen general de los resultados obtenidos durante el análisis de composición del software." },
            { id: "Identificación de Vulnerabilidades: ", description: "Se identifican las vulnerabilidades y debilidades del sistema, y se proponen una serie de contramedidas para mitigar las vulnerabilidades y debilidades identificadas y/o explotadas." },
            { id: "Próximos Pasos: ", description: "Se explican los diferentes servicios que ofrece Orbik para apoyar el cumplimiento de las normativas de ciberseguridad." }
        ],
        analysis: "El análisis tiene como objetivo identificar y caracterizar los fallos de diseño, debilidades y vulnerabilidades del sistema."
    },
    en: {
        title_introduction: "1. Introduction",
        introduction1: "This document describes the results of the vulnerability analysis that has been performed on the target device or software.",
        introduction2: "Vulnerability analysis aims to identify and characterize system design flaws, weaknesses and vulnerabilities. This activity, which is routinely carried out in the product verification and validation phase, is a specific requirement of the IEC 62443 standard.",
        introduction3: "The report is organized this way:",
        way: [
            { id: "Executive Summary: ", description: "A general summary of the results obtained during the software composition analysis is presented." },
            { id: "Vulnerability Identification: ", description: "The system's vulnerabilities and weaknesses are identified, and a series of countermeasures are proposed to mitigate the identified and/or exploited vulnerabilities and weaknesses." },
            { id: "Next Steps: ", description: "The different services offered by Orbik to support compliance with cybersecurity regulations are explained." }
        ],
        analysis: "The analysis aims to identify and characterize the design flaws, weaknesses and vulnerabilities of the system."
    }
} as const
const Intro: React.FC<{template?: Template, lang?: string}> = ({ template, lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <View>
                        <Text style={styles.titulo}>{i18n[locale].title_introduction}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introduction1}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }} wrap={true}>{i18n[locale].introduction2}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introduction3}</Text>
                        {i18n[locale].way.map((point, index) => (
                            <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                                <Text style={styles.puntuak}>{point.description}</Text>
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.column}>
                    <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="/assets/img/report/computer.jpg" />
                    <View style={{ ...styles.rightImage, marginTop: 265, height: 500, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#271968", flexDirection: "row" }}>
                        <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 30, textAlign: "right", fontFamily: "Bold", fontSize: 18, color: "#fff" }}>{i18n[locale].analysis}</Text>
                    </View>
                </View>
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}
export { Intro }
