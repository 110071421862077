import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import Component from "@models/Component"

class ComponentService extends Service<Component> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/component", MediaTypes.JSON)
    }

    public getComponentsByCVE = (cve: string) => {
        return this.http.get(`/${this.path}/vulnerability/` + cve) as any
    }

    public getLicenseByProductID = (product_id: string) => {
        return this.http.get(`/${this.path}/product/${product_id}/license`) as any
    }

    public getExpiredLicensesByProductID = (product_id: string) => {
        return this.http.get(`/${this.path}/product/${product_id}/licenseExpirationDate`) as any
    }

    public getPackages = (bom_ref: string) => {
        return this.http.get(`/${this.path}/packages/${bom_ref}`) as any
    }
}

export { ComponentService }
export default ComponentService
