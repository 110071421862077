import { StyledBox } from "@components/common/Box/BoxContainer"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import ProductTableDefinition from "@components/product/ProductTableDefinition"
import ServicesContext from "@context/ServicesContext"
import Product from "@models/Product"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface ProductTabProps {
    hierarchyId: string;
}

const ProductTab: React.FC<ProductTabProps> = ({ hierarchyId }) => {
    const navigate = useNavigate()
    const productService = useContext(ServicesContext).productService
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
    }, [])
    return (<Grid item xs container flexDirection="column" rowGap="25px" aria-description="Product Section">
        <Grid item xs sx={{ top: "0px" }}>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("product")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <StyledBox>
                        <Grid item xs container flexDirection="column" spacing="20px">
                            <Grid item container flexDirection="column" rowGap="35px">
                                <Grid item container>
                                    <GenericTable<Product> entity="Product" columns={cols}
                                        dataProvider={(filter) => {
                                            const updatedFilter: QueryParameters = {
                                                ...filter,
                                                filters: [{ field: "hierarchy", operation: FilterOperation.StringContains, value: hierarchyId }]
                                            }
                                            return productService.getAll(updatedFilter)
                                        }}
                                        onEdit={(elem: Product) => navigate("../../manage/product/" + elem.id)}
                                        onDelete={(elem: Product) => productService.delete(elem.id)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}

export default ProductTab
