import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import { Filter } from "@models/Filter"

class FilterService extends Service<Filter> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/filter", MediaTypes.JSON)
    }
}

export { FilterService }
export default FilterService
