import React from "react"
import { Controls, Edge, Node, ReactFlow } from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import { useTheme } from "@mui/material"
import { Provider } from "@models/Provider"

interface Product {
    id: string;
    name: string;
}

interface ProviderProductsGraphProps {
    provider: Provider;
    products: any[];
}

const ProviderProductsGraph: React.FC<ProviderProductsGraphProps> = ({ provider, products = [] }) => {
    const theme = useTheme()

    // Espaciado entre nodos
    const verticalSpacing = 150
    const horizontalSpacing = 250

    // Nodo del proveedor
    const providerNode: Node = {
        id: provider.id,
        data: { label: provider.name },
        position: { x: horizontalSpacing, y: 0 },
        style: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            border: "2px solid gray",
            borderRadius: "12px",
            padding: "10px",
            minWidth: "150px",
            textAlign: "center" as const
        }
    }

    // Nodos de productos
    const productNodes: Node[] = (products || []).map((product, index) => ({
        id: product.ID,
        data: { label: product.Name },
        position: { x: index * horizontalSpacing, y: verticalSpacing },
        style: {
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
            border: "2px solid gray",
            borderRadius: "12px",
            padding: "10px",
            minWidth: "150px",
            textAlign: "center" as const
        }
    }))

    // Aristas (edges) conectando proveedor con productos
    const edges: Edge[] = products.map((product) => ({
        id: `${provider.id}-${product.ID}`,
        source: provider.id,
        target: product.ID,
        type: "smoothstep",
        animated: true,
        style: { stroke: "#888" }
    }))

    return (
        <div style={{ width: "100%", height: "500px" }}>
            <ReactFlow nodes={[providerNode, ...productNodes]} edges={edges} fitView >
                <Controls style={{ backgroundColor: theme.palette.primary.main, color: "#000" }} />
            </ReactFlow>
        </div>
    )
}

export default ProviderProductsGraph
