import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { pdf } from "@react-pdf/renderer"
import { AlertColor, IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import ServicesContext from "@context/ServicesContext"
import { FileTypeByAssessmentType } from "@models/File"
import Assessment, { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { MediaTypes } from "@services/HttpService"
import saveAs from "file-saver"
import { FaFileDownload } from "react-icons/fa"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { MdSimCardDownload } from "react-icons/md"
import { FilterOperation } from "@utils/queryParams"
import Template from "@models/Template"
import { VulnReportDocument } from "./VulnReportDocument"
import VulnReportDocumentAchilles from "./VulnReportDocumentAquilles"
import PentestReport from "./VulnReportPentest"

interface VulnReportUpDownloaderProps {
    assessment: Assessment
    simplified: boolean
    handleSnackbar?: (message: string, severity: AlertColor) => void;
    setError(arg0: any): any
    onUploadSuccess?: () => void
    onUploadError?: () => void
}

const VulnReportUploader: React.FC<VulnReportUpDownloaderProps> = ({ assessment, setError, onUploadSuccess, handleSnackbar, onUploadError }) => {
    const fileService = useContext(ServicesContext).fileService
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[assessment.type])
    const [uploadDisabled, setUploadDisabled] = useState(false) // Deshabilitar inicialmente
    const [isUploading, setIsUploading] = useState(false) // Indicador de subida

    useEffect(() => {
        setFiletype(FileTypeByAssessmentType[assessment.type])
    }, [assessment])

    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = ""
    }

    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]
        if (selectedFile) {
            const validExtensions = filetype.extension.split(",")
            const validMimetypes = filetype.mimetype.split(",")
            const isValidFileType = validExtensions.some(ext => selectedFile.name.endsWith(ext)) || validMimetypes.includes(selectedFile.type)

            if (isValidFileType) {
                try {
                    setIsUploading(true) // Mostrar mensaje de subida
                    if (handleSnackbar) handleSnackbar("Uploading file...", "info")

                    const mediaTypesValues = Object.values(MediaTypes)
                    const mimetypes = filetype.mimetype.split(",")
                    const foundType = mediaTypesValues.find(v => mimetypes.includes(v))

                    if (foundType) {
                        await fileService.uploadFileV2(
                            assessment.id,
                            filetype.type,
                            foundType as MediaTypes,
                            selectedFile
                        ).then(() => {
                            setUploadDisabled(true) // Deshabilitar el botón tras el éxito
                            setIsUploading(false) // Ocultar mensaje de subida

                            if (onUploadSuccess) onUploadSuccess()

                            if (handleSnackbar) {
                                handleSnackbar("File uploaded successfully. Status changed to Started.", "success")
                            }
                        })
                    } else {
                        setIsUploading(false) // Ocultar mensaje de subida
                        if (onUploadError) onUploadError()
                        if (handleSnackbar) handleSnackbar("Unsupported file type.", "error")
                    }
                } catch (e: any) {
                    setIsUploading(false) // Ocultar mensaje de subida
                    if (onUploadError) onUploadError()
                    if (handleSnackbar) handleSnackbar("Failed to upload the file.", "error")
                    console.error(e)
                }
            } else {
                if (onUploadError) onUploadError()
                if (handleSnackbar) handleSnackbar("Invalid file type uploaded.", "error")
            }
        }
    }

    return (
        <>
            <input
                type="file"
                accept={filetype.extension}
                style={{ display: "none" }}
                onClick={handleFileInputClick}
                onChange={handleFileInputChange}
                id={fileId}
            />
            {(assessment.status === AssessmentStatus.Planned && !uploadDisabled) && (
                <label htmlFor={fileId}>
                    <Tooltip title={isUploading ? "Uploading file..." : "Upload SBOM"}>
                        <IconButton
                            size="small"
                            component="span"
                            onClick={(e) => e.stopPropagation()}
                            disabled={uploadDisabled || isUploading}
                        >
                            <CloudUploadIcon />
                        </IconButton>
                    </Tooltip>
                </label>
            )}

        </>
    )
}

const VulnReportDownloader: React.FC<VulnReportUpDownloaderProps> = ({ assessment, simplified, handleSnackbar }) => {
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const templateService = useContext(ServicesContext).templateService
    const fileService = useContext(ServicesContext).fileService
    const achillesService = useContext(ServicesContext).achillesService
    const customTextService = useContext(ServicesContext).customTextService
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[assessment.type])
    const [loading, setLoading] = useState<boolean>(false)
    /* const getCustomText = async () => {
        customTextService.getAll({ filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: assessment.id as string }] })
    } */
    const pentestService = useContext(ServicesContext).pentestService
    const [template, setTemplate] = useState<Template>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    useEffect(() => {
        setFiletype(FileTypeByAssessmentType[assessment.type])
        const fetchTemplate = async () => {
            try {
                /* const template = await templateService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: assessment.type }] })
                console.log("&&&&", template) */
            } catch (e: any) {
                console.error(e)
            }
        }
        fetchTemplate()
        /* getCustomText() */
    }, [assessment])

    const handleGenerateClick = async () => {
        try {
            let blob
            setLoading(true)
            if (handleSnackbar) handleSnackbar("Generating file...", "info")

            if (assessment.type === "logical_pentest") {
                /* const response = await vulnerabilityService.getAllFormatted(assessment.id, assessment.type)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`)
                }
                const data = await response.json() */
                /* const templateData = await templateService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: assessment.type }] }) */
                const params = { filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: assessment.id as string }] }
                const vulnerabilityList = await vulnerabilityService.getAll(params)
                console.log(vulnerabilityList)
                const customTextData = await customTextService.getAll({
                    filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: assessment.id as string }]
                })
                const report = pdf(<PentestReport data={vulnerabilityList.list} externalData={customTextData.list[0]} /* template={templateData.list[0]} */ />)
                blob = await report.toBlob()
            } else if (filetype.type === "cyclonedx") {
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: assessment.type }] })
                console.log(templateData)
                const vulns = await vulnerabilityService.getAllFormatted(assessment.id, "cyclonedx")
                const report = pdf(<VulnReportDocument simplified={simplified} assessment={assessment} data={vulns} template={templateData.list[0]} />)
                blob = await report.toBlob()
            } else if (filetype.type === "achilles") {
                const data = await achillesService.getAllFormatted(assessment.id, "achilles")
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: assessment.type }] })
                console.log(templateData)
                const customTextData = await customTextService.getAll({
                    filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: assessment.id as string }]
                })
                const report = pdf(<VulnReportDocumentAchilles assessment={assessment} achilles={data.list} template={templateData.list[0]} customText={customTextData.list[0]} />)
                blob = await report.toBlob()
            } else if (filetype.type === "nessus") {
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: assessment.type }] })
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "nessus")
                const report = pdf(<VulnReportDocumentGeneric data={data.list} template={templateData.list[0]} />)
                blob = await report.toBlob()
            } else if (filetype.type === "acunetix") {
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: assessment.type }] })
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "acunetix")
                const report = pdf(<VulnReportDocumentGeneric data={data.list} template={templateData.list[0]} />)
                blob = await report.toBlob()
            } else if (filetype.type === "burp") {
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: assessment.type }] })
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "burp")
                const report = pdf(<VulnReportDocumentGeneric data={data.list} template={templateData.list[0]} />)
                blob = await report.toBlob()
            } else {
                const data = await fileService.downloadFile(assessment.id, filetype.type)
                blob = new Blob([data], { type: filetype.mimetype })
            }

            const url = window.URL.createObjectURL(blob)
            window.open(url)

            if (handleSnackbar) handleSnackbar("File generated successfully", "success")
        } catch (e) {
            console.error(e)
            if (handleSnackbar) handleSnackbar("Error generating file", "error")
        } finally {
            setLoading(false)
        }
    }
    const handleDownload = async (format: string) => {
        try {
            if (handleSnackbar) handleSnackbar("Starting download...", "info")

            const data = await fileService.downloadFile(assessment.id, "original", format)
            const blob = new Blob([data], { type: filetype.mimetype })

            saveAs(blob, `${assessment.product.name}_${assessment.product.version}_${assessment.type}.${format.toLowerCase()}`)

            if (handleSnackbar) handleSnackbar("File downloaded successfully", "success")
        } catch (error) {
            console.error("Error downloading file:", error)
            if (handleSnackbar) handleSnackbar("Error downloading file", "error")
        }
    }
    const handleDownloadGenericFileClick = async () => {
        try {
            if (handleSnackbar) handleSnackbar("Starting download...", "info")

            // Descargar el archivo desde el servicio
            const data = await fileService.downloadFile(assessment.id, "original")
            const blob = new Blob([data], { type: filetype.mimetype })

            // Determinar la extensión del archivo basado en el tipo de assessment
            const fileType = FileTypeByAssessmentType[assessment.type]
            let extension = ""

            if (assessment.type !== AssessmentType.SCA && fileType) {
                // Usar solo la primera extensión disponible en caso de múltiples opciones
                extension = fileType.extension.split(",")[0]
            }

            // Guardar el archivo con la extensión correspondiente
            const filename = `${assessment.product.name}_${assessment.product.version}_${assessment.type}${extension}`
            saveAs(blob, filename)

            if (handleSnackbar) handleSnackbar("File downloaded successfully", "success")
        } catch (error) {
            console.error("Error downloading file:", error)
            if (handleSnackbar) handleSnackbar("Error downloading file", "error")
        }
    }

    const handleDownloadGenericFileSBOM = async (format: string) => {
        try {
            if (handleSnackbar) handleSnackbar("Starting SBOM download...", "info")

            const data = await fileService.downloadFile(assessment.id, "report", format)
            const blob = new Blob([data], { type: filetype.mimetype })

            saveAs(blob, `${assessment.product.name}_${assessment.product.version}_${assessment.type}`)

            if (handleSnackbar) handleSnackbar("SBOM downloaded successfully", "success")
        } catch (error) {
            console.error("Error downloading SBOM:", error)
            if (handleSnackbar) handleSnackbar("Error downloading SBOM", "error")
        }
    }

    return (
        <>
            {(assessment.status === AssessmentStatus.Ended) &&
                <>
                    <Tooltip title="Download Assessment">
                        <IconButton
                            size="small"
                            component="label"
                            onClick={handleGenerateClick}
                            disabled={loading}
                        >
                            {simplified ? <CloudDownloadIcon color="disabled" /> : <CloudDownloadIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download original file">
                        <IconButton
                            size="small"
                            component="label"
                            onClick={assessment.type === AssessmentType.SCA ? handleMenuOpen : handleDownloadGenericFileClick}
                            disabled={loading}
                        >
                            {simplified ? <FaFileDownload color="disabled" /> : <FaFileDownload />}
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => { handleDownload("JSON"); handleMenuClose() }}>
                            Download as JSON
                        </MenuItem>
                        <MenuItem onClick={() => { handleDownload("XML"); handleMenuClose() }}>
                            Download as XML
                        </MenuItem>
                    </Menu>
                    {assessment.type === AssessmentType.SCA && (
                        <>
                            <Tooltip title="Download updated sbom">
                                <IconButton
                                    size="small"
                                    component="label"
                                    onClick={handleMenuOpen}
                                    disabled={loading}
                                >
                                    {simplified ? <MdSimCardDownload color="disabled" /> : <MdSimCardDownload />}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleDownloadGenericFileSBOM("JSON"); handleMenuClose() }}>
                                    Download as JSON
                                </MenuItem>
                                <MenuItem onClick={() => { handleDownloadGenericFileSBOM("XML"); handleMenuClose() }}>
                                    Download as XML
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </>
            }
        </>
    )
}

export { VulnReportUploader, VulnReportDownloader }
