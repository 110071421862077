import { Button, Step, StepLabel, Stepper } from "@mui/material"
import { useState } from "react"
import HBOM, { initData } from "@models/HBOM"
import HBOMForm from "./HBOMForm"
const steps = [
    "Header Information",
    "Finished Product Details",
    "Entity Name",
    "Entity Location",
    "Component Part Info",
    "Component Part Details",
    "Production Details"
]
const HBOMStepper = ({ onComplete, onDataChange }: { onComplete: () => void, onDataChange: (data: HBOM) => void; }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [formData, setFormData] = useState<HBOM>(initData)
    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            onComplete() // Notifica al componente padre cuando se complete el paso final
        } else {
            setActiveStep((prevStep) => prevStep + 1)
        }
    }

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1)
    }

    const handleChange = (section: keyof HBOM, field: string, value: string | number) => {
        setFormData((prevFormData) => {
            const updatedData = {
                ...prevFormData,
                [section]: {
                    ...prevFormData[section],
                    [field]: value
                }
            }
            onDataChange(updatedData)
            return updatedData
        })
    }
    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <HBOMForm activeStep={activeStep} formData={formData} onChange={handleChange} />

            <div style={{ marginTop: 20 }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                </Button>
                <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
            </div>
        </div>
    )
}

export default HBOMStepper
