import React, { useEffect, useState } from "react"
import { Document, Font } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import Vulnerability from "@models/Vulnerability"
import Product from "@models/Product"
import Hierarchy from "@models/Hierarchy"
import { IterableList } from "@models/iterable"
import { BackPage } from "./sections"
import { FrontPage } from "./GenericVulnerability/sections/0_FrontPage"
import { Index } from "./GenericVulnerability/sections/0_Index"
import { Intro } from "./GenericVulnerability/sections/1_Intro"
import { ExecutiveSection } from "./GenericVulnerability/sections/3_ExecutiveSection"
import { VulnsSection, VulnsSection2 } from "./GenericVulnerability/sections/4_1_VulnsSection"
import { VulnsList, VulnsListDetail } from "./GenericVulnerability/sections/4_2_VulnsList"

Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    hierarchyData: IterableList<Hierarchy>,
    productData: IterableList<Product>,
    assessments?: Assessment[],
    template?: Template,
    vulnerabilityList: Vulnerability[]
}

const VulnReportDocumentHierarchy: React.FC<VEXDocumentProps> = ({ hierarchyData, productData, assessments, template, vulnerabilityList }) => {
    // type
    // type AssessmentWithVulnerabilities = {
    //     assessment: Assessment;
    //     vulnerabilities: Vulnerability[];
    // };
    type ProductWithVulnerabilities = {
        product: Product;
        // assessments?: AssessmentWithVulnerabilities[];
        vulnerabilityList2: Vulnerability[];
    };
    type HierarchyDataObject = {
        hierarchy: Hierarchy;
        productList?: ProductWithVulnerabilities[];
        nextHierarchy?: HierarchyDataObject[];
    };
    // ------------------------------

    // Constans
    const [orderedVulns, setOrderedVulns] = useState<Vulnerability[]>([])
    const [orderedLimitedVulns, setOrderedLimitedVulns] = useState<Vulnerability[]>([])
    const [dataReport, setDataReport] = useState<HierarchyDataObject>()
    // ------------------------------

    // useEffect
    useEffect(() => {
        const ordered = [...vulnerabilityList].sort((a, b) => {
            let rank = 0
            const rankB = (a.score)
            const rankA = (a.score)
            if (rankA != null && rankB != null) {
                rank = rankA - rankB
            }
            if (rank === 0) {
                rank = (b.score || 0) - (a.score || 0)
            }
            return rank
        })
        setOrderedVulns(ordered)
        setOrderedLimitedVulns(ordered.slice(0, 10))
    }, [vulnerabilityList])
    useEffect(() => {
        if (!hierarchyData || hierarchyData.count === 0) return

        const getProductWithVulnerabilities = (products: Product[]) => {
            return products.map(product => {
                const vulnerabilityList2 = vulnerabilityList.filter(vuln => vuln.product.id === product.id)
                return { product, vulnerabilityList2 }
            })
        }

        const buildHierarchyTree = (currentLevelHierarchy: Hierarchy, level: number): HierarchyDataObject => {
            const products = productData.list.filter(
                product => product.hierarchies.includes(currentLevelHierarchy.path)
            )
            const productList = getProductWithVulnerabilities(products)
            const childHierarchies = hierarchyData.list.filter(({ path }) =>
                path.split(";")[level] === (currentLevelHierarchy.path) &&
                path.split(";").length === currentLevelHierarchy.path.split(";").length + 1
            )
            const nextHierarchy = childHierarchies.map(childHierarchy =>
                buildHierarchyTree(childHierarchy, level + 1)
            )
            return {
                hierarchy: currentLevelHierarchy,
                productList,
                nextHierarchy: nextHierarchy.length > 0 ? nextHierarchy : undefined
            }
        }

        const [rootHierarchy, rootLevel] = hierarchyData.list.reduce(
            (minHierarchyData, currentHierarchy) => {
                const currentPathLength = currentHierarchy.path.split(";").length
                const [minHierarchy, minPathLength] = minHierarchyData
                return currentPathLength < minPathLength
                    ? [currentHierarchy, currentPathLength - 1]
                    : minHierarchyData
            },
            [hierarchyData.list[0], hierarchyData.list[0].path.split(";").length - 1]
        )

        if (rootHierarchy) {
            const hierarchyTree = buildHierarchyTree(rootHierarchy, rootLevel)
            setDataReport(hierarchyTree)
        }
    }, [hierarchyData, productData, vulnerabilityList])

    // --------------------------------

    return (
        <>
            {!template || template === undefined || template.id === "" || template.id === "00000000-0000-0000-0000-000000000000"
                ? (
                    <Document pageLayout="twoColumnRight">
                        <FrontPage></FrontPage>
                        <Index></Index>
                        <Intro></Intro>
                        <ExecutiveSection index="3" vulnerabilities={vulnerabilityList}></ExecutiveSection>
                        <VulnsSection></VulnsSection>
                        <VulnsSection2></VulnsSection2>
                        {/* <HierarchyList dataReport={dataReport}/> */}
                        <VulnsListDetail vulnerabilities={orderedLimitedVulns}></VulnsListDetail>
                        <VulnsList vulnerabilities={orderedVulns}></VulnsList>
                        <BackPage></BackPage>
                    </Document>
                )
                : (
                    <>
                        {/*
                <Document pageLayout="twoColumnRight">
                    <CustomFrontPage assessment={(assessments as Assessment[])[0]} template={template}></CustomFrontPage>
                    {template.table_index && <CustomIndex template={template!}></CustomIndex>}
                    {template.table_contents && <CustomIntro template={template!}></CustomIntro>}
                    {template.table_scope && <CustomScopeSection template={template!} metadata={dataReport.metadata}></CustomScopeSection>}
                    {template.table_executive_summary && <CustomExecutiveSection index="3" vulnerabilities={dataReport.vulnerabilities} template={template!}></CustomExecutiveSection>}
                    {template.customSections && template.customSections.length > 0 && <CustomSection template={template}></CustomSection>}
                    <VulnsSection></VulnsSection>
                    <VulnsSection2></VulnsSection2>
                    <VulnsListDetail vulnerabilities={orderedLimitedVulns}></VulnsListDetail>
                    <VulnsList vulnerabilities={orderedVulns}></VulnsList>
                    <BackPage></BackPage>
                </Document>
                */}
                    </>
                )}
        </>
    )
}

export { VulnReportDocumentHierarchy }
export default VulnReportDocumentHierarchy
