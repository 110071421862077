import React, { useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import { GridColDef } from "@mui/x-data-grid"
import { Typography, useTheme } from "@mui/material"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext } from "@components/permissions/index"
import { CAFVulnerabilityTableDefinition, CAFVulnerabilityTableDefinitionES } from "@components/vulnerability/index"
import { ServicesContext } from "@context/index"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import Product from "@models/Product"
import CAFVulnerability from "@models/CAFVulnerability"

type VulnerabilityTabProps = {
    hierarchyId: string;
};

const VulnerabilityTab: React.FC<VulnerabilityTabProps> = ({ hierarchyId }) => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const theme = useTheme()
    const { vulnerabilityService, productService } = useContext(ServicesContext)
    const navigate: NavigateFunction = useNavigate()
    const ability = useContext(AbilityContext)
    const { track } = useTrack()

    const [cols, setCols] = useState<GridColDef[]>([])
    const [products, setProducts] = useState<Product[]>([])
    const [productIds, setProductIds] = useState<string[]>([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const CafVulnerabilityTableDefinition = context.language === "es" ? CAFVulnerabilityTableDefinitionES : CAFVulnerabilityTableDefinition
        setCols(convertModelDefToGridColDef(CafVulnerabilityTableDefinition, ability))
    }, [])
    useEffect(() => {
        track({ view: "VulnerabilityList" })

        const fetchProducts = async () => {
            setLoading(true)
            try {
                const filters = {
                    filters: [
                        {
                            field: "hierarchies",
                            operation: FilterOperation.StringArrayContains,
                            value: hierarchyId
                        }
                    ]
                }
                const fetchedProducts = await productService.getAll(filters)
                const productsList = fetchedProducts?.list || []
                console.log("PROD", productsList)

                setProducts(productsList)
                setProductIds(productsList.map((p: Product) => p.id))
            } catch (e: any) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        fetchProducts()
    }, [hierarchyId])

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", paddingLeft: "20px" }}>
                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="45px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("vulnerabilities")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="15px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("sections.vulnerabilitiesList")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container flexDirection="column" rowGap="35px">
                {productIds && productIds.length > 0
                    ? (

                        <GenericTable<CAFVulnerability> entity="CAFVulnerability" columns={cols}
                            dataProvider={(filter) => {
                                const updatedFilter: QueryParameters = {
                                    ...filter,
                                    filters: [{ field: "product_id", operation: FilterOperation.UUIDEqual, value: Array.isArray(productIds) ? productIds.toString() : "" }]
                                }
                                return vulnerabilityService.getAllCafVulns(updatedFilter)
                            }}
                            disabledActions={true}
                            onEdit={(elem: CAFVulnerability) => navigate("./" + elem.id, { state: { row: elem } })}
                            onDelete={(elem: CAFVulnerability) => vulnerabilityService.delete(elem.id)}
                        />
                    )
                    : (
                        <Typography color={theme.palette.text.secondary} fontSize="18px">
                            {loading ? "Cargando productos..." : "No hay productos disponibles."}
                        </Typography>
                    )}
            </Grid>
        </Grid>
    )
}

export { VulnerabilityTab }
export default VulnerabilityTab
