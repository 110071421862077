import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../Styles"
import VexDocumentStyles from "../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

const i18n = {
    es: {
        stepTitle: "Siguiente Pasos",
        stepSubtitle: "Servicios",
        stepIntro: "¿Te preocupa el estado de seguridad de tu producto o no sabes cómo cumplir con las nuevas regulaciones de seguridad?",
        stepIntro2: "Orbik Cybersecurity ofrece una lista de servicios para esto. Desde los más básicos hasta los más complejos, ofrecemos soporte y soluciones adaptadas a ti.",
        stepIntro3: "Los servicios se categorizan siguiendo estas 4 areas:",
        ambitos: [
            { id: "Laboratorio: ", description: "Evaluaciones de cumplimiento de ciberseguridad" },
            { id: "MyOrbik: ", description: "Web para la gestión activa de vulnerabilidades de productos electrónicos" },
            { id: "Certificación: ", description: "Apoyo a la certificación de productos" },
            { id: "Consultoría técnica: ", description: "Definir la estrategia de ciberseguridad del producto y priorizar las acciones de implementación" }
        ],
        plans: [
            { id: "Evaluación de una semana: ", description: "En esta siguiente fase, profundizamos en los requisitos de su producto para lograr el cumplimiento de la CRA. Nuestros expertos llevan a cabo una evaluación exhaustiva para identificar los cambios necesarios, asegurando la alineación con las estrictas regulaciones de la CRA." },
            { id: "Evaluación de Fundamentos: ", description: "En esta siguiente fase, agregamos una capa de pruebas de seguridad a la evaluación. Un tutorial completo sobre el cumplimiento de la CRA." },
            { id: "Evaluación Personalizada: ", description: "¡Personalizamos nuestros servicios para adaptarnos a sus necesidades únicas! Comuníquese con nosotros para crear un plan de evaluación personalizado adaptado a satisfacer todas sus necesidades." },
            { id: "Monitoreo Continuo: ", description: "My Orbik proporciona monitoreo avanzado de vulnerabilidades para ayudar a las organizaciones a adelantarse a las amenazas cibernéticas. Como herramienta de gestión de vulnerabilidades, ofrece capacidad para identificar nuevas amenazas en tiempo real, My Orbik equipa a los usuarios para mitigar rápidamente los riesgos, garantizando una protección integral contra las ciberamenazas en evolución." }
        ],
        plan: "Consulte nuestros planes de trabajo.",
        planIntro: "Con base en el valor total obtenido, se establecen una serie de umbrales que proporcionan valores cualitativos:",
        contact: "Para mas información, contactenos mediante este email:"
    },
    en: {
        stepTitle: "Next Steps",
        stepSubtitle: "Services",
        stepIntro: "Are you worried about the safety status of your product or don't know how to comply with the new safety regulations?",
        stepIntro2: "Orbik Cybersecurity offers a list of services for this. From the most basic to the most complex, we offer support and solutions tailored to you.",
        stepIntro3: "The services can be categorized into the following 4 areas:",
        ambitos:
         [
             { id: "Laboratory: ", description: "Cybersecurity compliance assessments" },
             { id: "MyOrbik: ", description: "Website for active management of vulnerabilities in electronic products" },
             { id: "Certification: ", description: "Support for product certification" },
             { id: "Technic consultory : ", description: "Define the product's cybersecurity strategy and prioritize implementation actions." }
         ],
        plans: [
            { id: "One week Assessment: ", description: "In this next phase, we delve into your product's requirements to achieve CRA compliance. Our experts conduct a thorough assessment to identify the necessary changes, ensuring alignment with the strict CRA regulations." },
            { id: "Foundations Assessment: ", description: "In this next phase, we add a security testing layer to the assessment. A comprehensive tutorial on CRA compliance." },
            { id: "Custom Assessment: ", description: "We customize our services to meet your unique needs! Contact us to create a tailored assessment plan designed to fulfill all your requirements." },
            { id: "Continuous Monitoring: ", description: "My Orbik provides advanced vulnerability monitoring to help organizations stay ahead of cyber threats. As a vulnerability management tool, it offers real-time threat detection capabilities. My Orbik equips users to quickly mitigate risks, ensuring comprehensive protection against evolving cyber threats." }
        ],
        plan: "Check our work plans",
        planIntro: "Based on the total value obtained, a series of thresholds are established that provide qualitative values:",
        contact: "For more information, contact us by email:"
    }
} as const
interface CustomServicesSectionProps {
    template?: Template;
    lang?: string;
}
const ServicesSection: React.FC<CustomServicesSectionProps> = ({ template, lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>3. {i18n[locale].stepTitle} </Text>
                    <Text style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>3.1  {i18n[locale].stepSubtitle}</Text>
                    <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].stepIntro}</Text>
                    <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].stepIntro2}</Text>
                    <Text style={{ ...styles.textua, top: 2, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].stepIntro3}</Text>
                    {i18n[locale].ambitos.map((ambitos, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{ambitos.id}</Text>
                            <Text style={styles.puntuak}>{ambitos.description}</Text>
                        </Text>
                    ))}
                </View>
                <View style={{ ...styles.column, marginLeft: 0 }}>
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Bold", fontSize: 8, marginTop: -1, color: "gray" }} >{i18n[locale].plan}</Text>
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 10 }}>{i18n[locale].planIntro}</Text>
                    {i18n[locale].plans.map((plans, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 60, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{plans.id}</Text>
                            <Text style={styles.puntuak}>{plans.description}</Text>
                        </Text>
                    ))}
                </View>
            </View>
            <Footer color={template?.color}></Footer>
        </Page>
    )
}
const ServicesImg: React.FC<CustomServicesSectionProps> = ({ template, lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    {/* <Image style={{ ...styles.image, width: 575, height: 195, paddingLeft: 40 }} src="./assets/img/report/servicios.jpg" /> */}
                    <Image style={{ ...styles.image, marginTop: 50, width: 580, height: 277, paddingLeft: 20 }} src="/assets/img/report/serviciosTable.jpg" />
                    <Text style={{ ...styles.textua, marginRight: 40, marginTop: 120, fontFamily: "Griff", fontSize: 10 }}> {i18n[locale].contact}</Text>
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Bold", fontSize: 10 }}>info@orbik-cybersecurity.com</Text>
                </View>
            </View>
            <Footer color={template?.color}></Footer>
        </Page>
    )
}
export { ServicesSection, ServicesImg }
