import React from "react"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsAccessibility from "highcharts/modules/accessibility"
import HighchartsReact from "highcharts-react-official"
import { useTheme } from "@mui/material"

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsAccessibility(Highcharts)

interface ProductLifecycleChartValues {
    values: Array<string | number>[];
}

const ProductLifecycleChart: React.FC<ProductLifecycleChartValues> = ({ values }) => {
    const theme = useTheme()
    values.sort((a, b) => {
        const dateAStr = a[6]
        const dateBStr = b[6]

        if (typeof dateAStr === "string" && typeof dateBStr === "string") {
            const [monthA, yearA] = dateAStr.split("/").map(Number)
            const [monthB, yearB] = dateBStr.split("/").map(Number)

            const dateA = new Date(yearA, monthA - 1)
            const dateB = new Date(yearB, monthB - 1)

            return dateA.getTime() - dateB.getTime()
        }
        return 0
    })

    const prueba = values.map(item => item[6])

    const timestamps = values.map(item => {
        const dateString = String(item[6])
        const [month, year] = dateString.split("/")
        return new Date(`${year}-${month}-01`)
    })

    const timestampsInMilliseconds = timestamps.map(date => date.getTime())

    const minTimestamp = Math.min(...timestampsInMilliseconds)
    const oldestDate = new Date(minTimestamp)

    const options: Highcharts.Options = {
        chart: {
            type: "area",
            backgroundColor: theme.palette.secondary.contrastText,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: -10,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, 0, 90, 0]

        },
        title: {
            text: "<div style=\"font-size: 17px; font-weight: 100; font-variant: all-small-caps; margin-bottom: 20px;\">Products life cycle</div>",
            useHTML: true,
            align: "left",
            style: {
                color: theme.palette.primary.contrastText
            }
        },
        subtitle: { },
        xAxis: {
            type: "datetime",
            labels: { style: { color: theme.palette.primary.contrastText } }
        },
        yAxis: {
            title: { text: "" },
            labels: { style: { color: theme.palette.primary.contrastText } }
        },
        tooltip: {
            shared: true,
            headerFormat: "<span style=\"font-size:12px\"><b>{point.key}</b></span><br>"
        },
        plotOptions: {
            series: {
                pointStart: minTimestamp,
                pointInterval: 30 * 24 * 3600 * 1000

            },
            area: {
                stacking: "normal",
                lineColor: theme.palette.secondary.contrastText,
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: theme.palette.secondary.contrastText
                }
            }

        },
        legend: {
            itemStyle: {
                color: theme.palette.primary.contrastText
            }
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        series: [
            { type: "area", name: "Application", data: values.map(item => item[0]), color: "orange" },
            { type: "area", name: "Container", data: values.map(item => item[1]), color: "#4db04a" },
            { type: "area", name: "Device", data: values.map(item => item[2]), color: "#b4de6c" },
            { type: "area", name: "Firmware", data: values.map(item => item[3]), color: "#8dd3c7" },
            { type: "area", name: "Library", data: values.map(item => item[4]), color: "#fdffb5" },
            { type: "area", name: "OS", data: values.map(item => item[5]), color: "#477db8" }
        ]
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export { ProductLifecycleChart }
export default ProductLifecycleChart
