import React, { useContext, useEffect, useRef, useState } from "react"
import { Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { IconBaseProps, IconType } from "react-icons/lib"
import { useLocation, useNavigate } from "react-router-dom"
import { Actions, Subjects } from "@permissions/index"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import CloseIcon from "@mui/icons-material/Close"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import ServicesContext from "@context/ServicesContext"
import Client, { ClientSector } from "@models/Client"
import { AbilityContext } from "@components/permissions"
import { I18nContext } from "I18nProvider"
import License from "@models/License"
import SidebarItem from "./SidebarItem"
import MembershipCard from "./components/MembershipCard"
export interface Item {
    icon: IconType;
    title: string;
    iconProps: IconBaseProps;
    link: string;
    entity?: Subjects;
    subItems?: Array<Item>;
    permission?: { action: Actions[]; subject: Subjects };
}

interface Props {
    primaryItems: Array<Item>[]; // Items principales
    secondaryItems: Array<Item>[]; // Items secundarios
}
const initValue: Client = {
    id: "",
    name: "",
    description: "",
    license_validation_date: "",
    sector: ClientSector.Automotive
}
const Sidebar: React.FC<Props> = ({ primaryItems, secondaryItems }) => {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const [data, setData] = useState<Client>(initValue)
    const [selectedTitle, setSelectedTitle] = useState<string | null>(null)
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const [drawerContent, setDrawerContent] = useState<Item | null>(null)
    const [license, setLicense] = useState<License>()
    const licenseService = useContext(ServicesContext).licenseService
    const HEIGHT_THRESHOLD = 580
    const drawerRef = useRef<HTMLDivElement | null>(null)
    const isLocationActive = (link: string, itemTitle: string | undefined) => {
        if (link === "/") {
            return location.pathname === "/" || itemTitle === selectedTitle
        }
        return location.pathname.includes(link) || itemTitle === selectedTitle
    }
    const systemNotificationService = useContext(ServicesContext).systemNotificationServices
    const [total, setTotal] = useState<number>(0)
    const isMediumScreen = useMediaQuery("(max-height:800px)")
    const clientService = useContext(ServicesContext).clientService
    const ability = useContext(AbilityContext)
    const context = useContext(I18nContext)
    const fetchNotifications = async () => {
        try {
            const params: QueryParameters = {
                filters: [
                    { field: "read", operation: FilterOperation.StringEqual, value: "false" }
                ]
            }
            const response = await systemNotificationService.getAll(params)
            setTotal(response.count)
        } catch (error) {
            console.error("Error al obtener las notificaciones:", error)
            setTotal(0) // Establecer un valor por defecto en caso de error
        }
    }
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const fetchData = async () => {
        try {
            if (ability.can("read", "Client")) {
                return
            }

            const licenseData = await licenseService.getAll({ sortField: "id" })
            const clientData = await clientService.getAll({ sortField: "name" })

            if (clientData.count > 0) {
                setData(clientData.list[0])
                const matchingLicense = licenseData.list.find(license => license.client.id === clientData.list[0].id)
                if (matchingLicense) {
                    setLicense(matchingLicense)
                }
            } else {
                setData(initValue)
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error)
            setData(initValue)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Verificamos que event.target no sea null
            const target = event.target as Node | null

            // Si el drawer está abierto y el clic no fue en el drawer ni en el sidebar
            if (
                drawerOpen &&
                drawerRef.current &&
                target &&
                !drawerRef.current.contains(target) &&
                !(target as HTMLElement).closest(".sidebar-item")
            ) {
                handleDrawerClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [drawerOpen])
    useEffect(() => {
        const handleResize = () => setWindowHeight(window.innerHeight)
        fetchNotifications()
        fetchData()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    useEffect(() => {
        // Cierra el drawer y actualiza la ruta activa cuando cambia la ubicación
        setDrawerOpen(false)
        setSelectedTitle(null)
    }, [location.pathname])
    const handleContactClick = () => {
        const link = document.createElement("a")
        link.href = "https://orbik-cybersecurity.com/contact/"
        link.target = "_blank"
        link.rel = "noopener noreferrer"
        link.click()
    }
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleMenuClose = () => setAnchorEl(null)

    const filterItemsByPermission = (items: Array<Item>) => {
        return items.filter((item) => {
            if (!item.permission) return true
            return item.permission.action.some((action) => ability.can(action, item.permission!.subject))
        })
    }
    const handleDrawerOpen = (item: Item) => {
        if (item.subItems && item.subItems.length > 0) {
            setDrawerContent(item)
            setDrawerOpen(true)
            setSelectedTitle(item.title)
        }
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
        setSelectedTitle(null)
    }

    const handleOnClick = (item: Item) => {
        if (item.subItems && item.subItems.length > 0) {
            handleMenuClose()
            handleDrawerOpen(item)
        } else {
            navigate(item.link)
        }
    }
    return (
        <>
            <Grid item container flexDirection="column" sx={{
                background: theme.palette.primary.main,
                height: "95vh",
                width: "90px",
                zIndex: 1000,
                position: "fixed" // Añadido para fijar todo el sidebar
            }} justifyContent="space-between" className="sidebar-item">
                <Grid item xs container flexDirection="column" sx={{ zIndex: 1001 }}>
                    <Grid item
                        container
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                        marginBottom={2}
                        onClick={() => navigate("/")}
                        sx={{ cursor: "pointer", zIndex: 1000 }}>
                        {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ zIndex: 1000 }}>
                    <rect width="48" height="47.4098" fill="#F8E531" />
                    <rect width="35.8506" height="35.4098" transform="translate(6.07471 6)" fill="#F8E531" />
                    <rect x="6.07471" y="6" width="35.8506" height="35.4098" stroke="#1F1C00" />
                    <rect x="6.07471" y="6" width="17.9253" height="35.4098" stroke="#1F1C00" />
                    <path d="M24 6L41.9253 23.7049" stroke="#1F1C00" />
                    <ellipse cx="23.9627" cy="23.7418" rx="17.888" ry="17.668" stroke="#1F1C00" />
                </svg> */}
                        <img src="/assets/img/CAF_TRANSPARENTE.png" style={{
                            width: "auto", // Ajuste proporcional
                            height: "auto", // Ajuste proporcional
                            maxWidth: "50px", // Tamaño máximo para que no sea demasiado grande
                            maxHeight: "50px", // Tamaño máximo para que no sea demasiado grande
                            marginTop: "10px"
                        }}></img>
                    </Grid>
                    {windowHeight > 580 &&
                primaryItems.map((groupItem, groupIdx) => (
                    <div key={groupItem + "-" + groupIdx}>
                        {filterItemsByPermission(groupItem).map((item, idx) => (
                            <SidebarItem
                                key={idx}
                                item={item}
                                onOpenDrawer={handleDrawerOpen}
                                isLocationActive={(link) => isLocationActive(link, item.title)}
                            />
                        ))}
                    </div>
                ))}
                    {windowHeight < HEIGHT_THRESHOLD && (
                        <Grid item xs="auto" container justifyContent="center">
                            <IconButton onClick={handleMenuClick} sx={{ color: theme.palette.primary.contrastText }}>
                                <MoreVertIcon fontSize="large" />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                {primaryItems.map((groupItem, groupIdx) =>
                                    groupItem.map((item, idx) => (
                                        <MenuItem key={groupIdx + "-" + idx} onClick={() => handleOnClick(item)}>
                                            <Tooltip title={item.title}>
                                                <Grid item container alignItems="center">
                                                    {React.createElement(item.icon, { size: "24px", color: theme.palette.primary.main, ...item.iconProps })}
                                                    <span style={{ marginLeft: 10, padding: "0px 10px" }} >{item.title}</span>
                                                </Grid>
                                            </Tooltip>
                                        </MenuItem>
                                    ))
                                )}
                                {secondaryItems.map((groupItem, groupIdx) =>
                                    groupItem.map((item, idx) => (
                                        <MenuItem key={groupIdx + "-" + idx} onClick={() => handleOnClick(item)}>
                                            <Tooltip title={item.title}>
                                                <Grid item container alignItems="center">
                                                    {React.createElement(item.icon, { size: "24px", color: theme.palette.primary.main, ...item.iconProps })}
                                                    <span style={{ marginLeft: 10 }}>{item.title}</span>
                                                </Grid>
                                            </Tooltip>
                                        </MenuItem>
                                    ))
                                )}
                            </Menu>
                        </Grid>
                    )}
                </Grid>

                {/* Secondary items section */}
                <Grid item container flexDirection="column" sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: theme.palette.primary.main,
                    paddingBottom: "10px"
                }}>
                    {/*  <>
                        <div><Grid
                            item
                            container
                            alignItems="center"
                            direction="column"
                            justifyContent="center"
                            color={theme.palette.background.default}
                            borderRadius="20px"
                            onClick={() => {
                                navigate("/system_notification")
                            }
                            }
                            sx={{
                                height: isMediumScreen ? "32px" : "37px",
                                cursor: "pointer",
                                ...(isLocationActive("/system_notification", "System Notification") && {
                                    background: theme.palette.secondary.dark,
                                    width: isMediumScreen ? "60%" : "70%",
                                    borderRadius: "20px",
                                    margin: "0 auto"
                                })
                            }}
                        >
                            <Tooltip title="Notifications">
                                <Badge badgeContent={total} sx={{ color: theme.palette.primary.contrastText }}>{React.createElement(total > 0 ? MdNotificationsActive : MdNotificationsNone, {
                                    size: isMediumScreen ? "22px" : "28px"
                                })}</Badge>
                            </Tooltip>
                        </Grid>
                        </div>
                        <Grid item sx={{ marginBottom: "5px", fontSize: isMediumScreen ? "10px" : "12px", color: theme.palette.primary.contrastText, textAlign: "center" }}>
                    Notifications
                        </Grid>
                    </> */}
                    {windowHeight > 645 && secondaryItems.map((groupItem, groupIdx) => (
                        <div key={`secondary-${groupIdx}`}>
                            {filterItemsByPermission(groupItem).map((item, idx) => (
                                <SidebarItem
                                    key={idx}
                                    item={item}
                                    onOpenDrawer={handleDrawerOpen}
                                    isLocationActive={(link) => isLocationActive(link, item.title)}
                                />
                            ))}
                        </div>
                    ))}
                </Grid>

                {/* Drawer with submenu and selected item title */}

            </Grid>
            {drawerOpen && (
                <div
                    ref={drawerRef}
                    style={{
                        position: "fixed",
                        top: 0,
                        left: "90px",
                        width: "300px",
                        height: "100%",
                        backgroundColor: theme.palette.primary.main,
                        zIndex: 998, // Aseguramos que tenga un z-index menor que el sidebar
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.4s ease-in-out",
                        transform: drawerOpen ? "translateX(0)" : "translateX(-100%)"
                    }}
                >
                    <Grid container flexDirection="column" sx={{ padding: "16px", color: theme.palette.primary.contrastText }}>
                        <Grid display="flex" justifyContent="space-between" alignItems="center">
                            <Typography fontSize="24px" fontWeight={700}>{selectedTitle}</Typography>
                            <IconButton onClick={handleDrawerClose}><CloseIcon /></IconButton>
                        </Grid>

                        {filterItemsByPermission(drawerContent?.subItems || []).map((subItem, idx) => {
                            if (subItem.title === "MyOrbikToolbox" && !license?.toolbox) {
                                return null
                            }

                            return (
                                <MenuItem
                                    key={idx}
                                    onClick={() => {
                                        navigate(subItem.link)
                                        handleDrawerClose()
                                    }}
                                    sx={{
                                        ...(isLocationActive(subItem.link, subItem.title) && {
                                            backgroundColor: theme.palette.secondary.dark
                                        })
                                    }}
                                >
                                    <Grid display="flex" alignItems="center" padding="5px 0px" gap={2}>
                                        {React.createElement(subItem.icon, { size: "24px", color: theme.palette.primary.contrastText })}
                                        <Typography fontSize="14px" color={theme.palette.primary.contrastText}>{subItem.title}</Typography>
                                    </Grid>
                                </MenuItem>
                            )
                        })}

                        {selectedTitle === "Settings" && <MembershipCard handleContactClick={handleContactClick} />}
                    </Grid>
                </div>
            )}
        </>
    )
}

export { Sidebar }
export default Sidebar
