import React, { useContext, useEffect, useState } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { Achilles, MonitorValue } from "@models/Achilles" // Importa tus modelos
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import { achillesStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface SectionProps {
    achillesData: Achilles[];
    externalData: any; // Actualiza esto al tipo de datos correcto
    customConclusion?: string
    template?: Template
    lang?: string
}
const i18n = {
    es: {
        indexTitle: "5.3.3. Conclusiones",
        conclusions: "Se ha visto que hay",
        conclusions1: "anomalías en la red que deben corregirse, especialmente en los protocolos con mayor número de incidencias:",
        conclusions2: "Es importante abordar estas anomalías para asegurar el correcto funcionamiento de la red.",
        total: "Se ha detectado un total de",
        total1: "anomalías en la red. Los protocolos más afectados son:",
        noTotal: "No se ha encontrado ninguna anomalía en la red."
    },
    en: {
        indexTitle: "5.3.3. Conclusions",
        conclusions: "It has been observed that there are",
        conclusions1: "anomalies in the network that need to be corrected, especially in the protocols with the highest number of incidents:",
        conclusions2: "It is important to address these anomalies to ensure the proper functioning of the network.",
        total: "A total of",
        total1: "anomalies have been detected in the network. The most affected protocols are:",
        noTotal: "No anomalies have been found in the network."
    }
} as const
const Conclusion: React.FC<SectionProps> = ({ achillesData, customConclusion, template, lang }) => {
    const [conclusionMessage, setConclusionMessage] = useState<string>("No se ha encontrado ninguna anomalía en la red.")
    const [resumenAnomalias, setResumenAnomalias] = useState<string[]>([])
    const [numAnomalias, setNumAnomalias] = useState<number>(0)
    const context = useContext(I18nContext)

    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    useEffect(() => {
        // Función para detectar y resumir anomalías
        const detectarAnomalias = () => {
            const protocolosConAnomalias: { [protocol: string]: number } = {}
            let totalAnomalias = 0

            achillesData.forEach(entry => {
                // Verifica si el valor del monitor es "monitor_yellow" o "test_error"
                if (entry.monitor_value === MonitorValue.MonitorYellow || entry.monitor_value === MonitorValue.TestError) {
                    if (!protocolosConAnomalias[entry.test_type]) {
                        protocolosConAnomalias[entry.test_type] = 0
                    }
                    protocolosConAnomalias[entry.test_type]++
                    totalAnomalias++
                }
            })

            setNumAnomalias(totalAnomalias)

            if (totalAnomalias > 0) {
                const protocolosResumen = Object.keys(protocolosConAnomalias)
                    .map(protocol => `${protocol}`)
                    .join(", ")
                    .replace(/, ([^,]*)$/, " y $1") // Formatea la lista de protocolos con "y"

                setResumenAnomalias([protocolosResumen])
                return `Se ha detectado un total de ${totalAnomalias} anomalías en la red. Los protocolos más afectados son: ${protocolosResumen}.`
            }
            return "No se ha encontrado ninguna anomalía en la red."
        }

        // Actualiza el mensaje de conclusión y el resumen de anomalías
        setConclusionMessage(detectarAnomalias())
    }, [achillesData])

    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template} externalData={null}/>
            <View style={{ ...achillesStyles.section, flexDirection: "row" }}>
                <View style={{ width: "100%" }}>
                    <Text style={{ ...achillesStyles.titulo }}>{i18n[locale].indexTitle}</Text>
                    <Text style={{ ...achillesStyles.textua }}>{conclusionMessage}</Text>
                    {numAnomalias > 0 && (
                        <View style={{ marginTop: 10 }}>
                            <Text style={{ ...achillesStyles.textua }}>
                                Se ha visto que hay {numAnomalias} anomalías en la red que deben corregirse, especialmente en los protocolos con mayor número de incidencias: {resumenAnomalias.join(", ")}. Es importante abordar estas anomalías para asegurar el correcto funcionamiento de la red.
                            </Text>
                        </View>
                    )}
                    <Text style={{ ...achillesStyles.textua }}>{customConclusion}</Text>
                </View>
            </View>
            <Footer externalData={null}/>
        </Page>
    )
}

export { Conclusion }
