/* eslint-disable multiline-ternary */
import ActionButton from "@components/common/Button/ActionButton"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { convertModelDefToGridColDef } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import ProductTableDefinition, { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import Component from "@models/Component"
import { ProductType } from "@models/Product"
import { Checkbox, Divider, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

const initValue: Component = {
    id: "",
    bom_ref: "",
    type: ProductType.Library,
    name: "",
    version: "",
    supressed: false,
    outdated: false,
    cpe: "",
    license: "",
    assessment_id: "",
    client_id: ""
}
const ComponentDetailTab: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const { componentId } = useParams<{ id: string; componentId: string }>()
    const theme = useTheme()
    const [error, setError] = useState<Error|null>(null)
    const [prevFormData, setPrevFormData] = useState<Component>(initValue)
    const [formData, setFormData] = useState<Component>(initValue)
    const componentService = useContext(ServicesContext).componentService
    const ability = useContext(AbilityContext)
    const productService = useContext(ServicesContext).productService
    const [cols, setCols] = useState<GridColDef[]>([])
    const [loading, setLoading] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [packageData, setPackageData] = useState<any>()
    const [hasDependencies, setHasDependencies] = useState<boolean>(false)
    // ------------------------------
    const { track, trackData } = useTrack()
    const [tabIndex, setTabIndex] = useState(0)

    const handleTabChange = (event: any, newIndex: number) => {
        setTabIndex(newIndex)
    }
    // Validation
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.version === ""

        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.version === prevFormData.version,
            formData.type === prevFormData.type,
            formData.supressed === prevFormData.supressed
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    // ------------------------------

    // First load
    useEffect(() => {
        track({ view: "ComponentDetail" })

        const fetchComponentData = async () => {
            try {
                const val = await componentService.get(componentId as string)
                setFormData(val)
                setPrevFormData(val)
                fetchPackageData(val.bom_ref) // Llamar a la función separada para obtener los paquetes
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }

        const fetchPackageData = async (bomRef: string) => {
            try {
                const fetchedPackage = await componentService.getPackages(bomRef)
                setPackageData(fetchedPackage)
            } catch (e: any) {
                if (e.status === 400 || e.status === 404) {
                    console.error("Package data not found:", e)
                } else {
                    setError({ message: e.error } as Error)
                    setSnackbarOpen(true)
                }
            }
        }

        fetchComponentData()
    }, [])
    // ------------------------------

    // Save handler
    const navigate = useNavigate()
    const saveHandler = async () => {
        try {
            await componentService.update(formData.id, formData)
            setFormData(formData)
            setPrevFormData(formData)
            navigate(-1)
        } catch (e: any) {
            // TODO fix this in backend
            navigate(-1)
            /* setError({ message: e.error } as Error)
            setSnackbarOpen(true) */
        }
    }
    // ------------------------------

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string | boolean, type: string, checked: boolean } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData(events.reduce((result, { target: { name, value, type, checked } }) => {
            // Si es un checkbox, usar el valor de 'checked' en lugar de 'value'
            const fieldValue = type === "checkbox" ? checked : value
            return { ...result, [name]: fieldValue }
        }, formData))
    }

    // ------------------------------

    // Set the columns for the products table
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
    }, [])
    // ------------------------------

    useEffect(() => {
        if (formData.cpe !== "") {
            setLoading(false)
        }
        setHasDependencies(!!formData.depends_on && formData.depends_on.length > 0)
    }, [formData])
    const handleClick = () => {
        if (packageData?.homepage) {
            window.open(packageData.homepage, "_blank")
        }
    }
    return (
        <Grid item container flexDirection="column" rowGap="25px">

            <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Component Edit Section //trans">
                <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={error?.message || context.t.translate("an_error")}
                            />
                            <Grid item container alignItems="center" justifyContent="flex-end">
                                <Grid>
                                    <ActionButton disabled={!formValid()} onClick={saveHandler} text={context.t.translate("save")} />
                                </Grid>
                            </Grid>
                            <Grid item my={2}>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }}/>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("name")}</Typography>
                                    <TextField disabled name="license" value={formData.name} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("reference")}</Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: "bolder",
                                            fontFamily: "Griff",
                                            fontSize: "16px",
                                            color: theme.palette.primary.main,
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: theme.palette.text.secondary // Change this to the desired hover color
                                            }
                                        }}
                                        onClick={handleClick}
                                    >
                                        {packageData?.name || "N/D"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("license_title")}</Typography>
                                    <TextField disabled name="license" value={formData.license} onChange={handleInputChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("version")}</Typography>
                                    <TextField disabled={!ability.can("*", "Component")} name="version" value={formData.version} onChange={handleInputChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("type")}</Typography>
                                    <TextField disabled={!ability.can("*", "Component")} margin="normal" select required variant="standard" name="type" style={{ fontFamily: "Griff", color: theme.palette.primary.main, backgroundColor: theme.palette.background.paper, borderRadius: "8px", padding: "5px" }}
                                        value={formData.type} onChange={handleInputChange}>
                                        {ProductTypeKeys.map((key, index) =>
                                            (<MenuItem key={index} value={ProductType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>{key}</Typography></MenuItem>)
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("supressed")}</Typography>
                                    <Checkbox checked={formData.supressed} onChange={handleInputChange} name="supressed"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </Grid >
    )
}
export { ComponentDetailTab }
export default ComponentDetailTab
