import { Grid } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Product, ProductType } from "@models/index"
import { AbilityContext } from "@components/permissions/index"
import { I18nContext } from "I18nProvider"
import { useTrack } from "@components/track/TrackContext"
import ProductHeader from "./ProductDetail2"
const initValue = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchies: [],
    inheritance: false,
    supressed: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    provider_ids: [],
    location: "",
    created_at: ""
}
const initLicenseData = {
    licenses: {},
    total: 0
}
export interface LicenseData {
    licenses: Record<string, number>;
    total: number;
  }
export interface TimeLineValues {
    x: number;
    name: string;
    description: string;
    version: string;
    id: string;
}

const ProductDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const productService = useContext(ServicesContext).productService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Product>(initValue)
    const [formData, setFormData] = useState<Product>(initValue)
    const ability = useContext(AbilityContext)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ProductDetail" })
    }, [])
    // Fetch product data from API
    // useEffects

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [id])
    // Fetch product data from API - END

    return (<Grid item xs container spacing="20px">
        <Grid item xs={12} marginTop={3}>
            <ProductHeader product={formData}/>
        </Grid>

    </Grid>
    )
}

export { ProductDetail }
export default ProductDetail
