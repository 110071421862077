import { Box, Chip, Stack, Typography, useTheme } from "@mui/material"
import React from "react"

interface CafFleetsStatusProps {
    value?: { finished: number; in_triage: number; not_started: number };
}

// Mapeo de las claves de estado a valores legibles
const statusMapping: { [key in keyof Required<CafFleetsStatusProps>["value"]] : string } = {
    finished: "Finished",
    in_triage: "In Triage",
    not_started: "Not Started"
}

const CafFleetsStatus: React.FC<CafFleetsStatusProps> = ({ value = {} }) => {
    const theme = useTheme()
    console.log("Status value:", value)

    // Si value está vacío, mostramos un mensaje en lugar de fallar
    if (!value || Object.keys(value).length === 0) {
        return <Typography variant="body2">No status available</Typography>
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
            {Object.keys(value).map((statusKey) => {
                const key = statusKey as keyof typeof statusMapping
                return (
                    <Stack direction="row" spacing={1} key={statusKey} sx={{ marginRight: 2 }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {statusMapping[key]}:
                        </Typography>
                        <Chip label={value[key]} color="primary" size="small" />
                    </Stack>
                )
            })}
        </Box>
    )
}

export { CafFleetsStatus }
export default CafFleetsStatus
