import { useLocation, useNavigate } from "react-router-dom"
import React from "react"
import { getVulnerabilitySeverity, VulnerabilitySeverity } from "@models/Vulnerability"
import { FilterOperation } from "@utils/queryParams"
import { VulnerabilitySnapshot } from "@models/Snapshot"
import { VulnerabilityIndicatorSnapshot } from "@components/common/indicator/VulnerabilityIndicatorSnapshot"

interface SnapshotProductVulnsProps {
    value: VulnerabilitySnapshot[]
    id: string;
}

const SnapshotProductVulns: React.FC<SnapshotProductVulnsProps> = ({ value, id }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const handleClick = () => {
        if (location.pathname !== "/manage/product") {
            navigate(`../../manage/product/${id}`)
        } else {
            navigate(`./${id}`)
        }
    }

    // Definir el tipo de severidades correctamente
    const severityCounts: Record<VulnerabilitySeverity, number> = {
        [VulnerabilitySeverity.Critical]: 0,
        [VulnerabilitySeverity.High]: 0,
        [VulnerabilitySeverity.Medium]: 0,
        [VulnerabilitySeverity.Low]: 0,
        [VulnerabilitySeverity.None]: 0,
        [VulnerabilitySeverity.Unknown]: 0 // Se maneja el caso Unknown para evitar errores
    }

    // Agrupar vulnerabilidades por severidad
    value.forEach(vuln => {
        const severity = getVulnerabilitySeverity(vuln.score)
        severityCounts[severity] += 1
    })
    return (
        <VulnerabilityIndicatorSnapshot
            filters={[{ field: "product_id", operation: FilterOperation.UUIDEqual, value: id }]}
            critical={severityCounts[VulnerabilitySeverity.Critical]}
            high={severityCounts[VulnerabilitySeverity.High]}
            medium={severityCounts[VulnerabilitySeverity.Medium]}
            low={severityCounts[VulnerabilitySeverity.Low]}
            none={severityCounts[VulnerabilitySeverity.None]}
            vulns={value}
        />
    )
}

export { SnapshotProductVulns }
export default SnapshotProductVulns
