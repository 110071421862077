import React, { useContext, useEffect } from "react"
import { MenuItem, TextField } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { SearchInput } from "@components/common/forms/index"
import { AbilityContext } from "@components/permissions"
import { ServicesContext } from "@context/index"
import { Product, ProductType } from "@models/index"
import { FilterOperation, FilterOption } from "@utils/index"

interface ProductSelectorProps{
    value: string
    customFilters?: FilterOption[]
    onSelect: (id: string|undefined, p: Product|null)=>void
}
const ProductSelector: React.FC<ProductSelectorProps> = ({ value, customFilters, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const productService = useContext(ServicesContext).productService
    const [disabled, setDisabled] = React.useState<boolean>(true)

    useEffect(() => {
        if ((customFilters !== undefined && customFilters[0].value !== undefined && customFilters[0].value !== "00000000-0000-0000-0000-000000000000") || !ability.can("*", "Client")) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [customFilters])

    if (disabled) {
        return (
            <TextField select required disabled fullWidth variant="filled" label={context.t.translate("product_newname")} value="no">
                <MenuItem value="no">
                    <em>No options</em>
                </MenuItem>
            </TextField>
        )
    }

    return (
        <SearchInput<Product>
            margin="none" required label={context.t.translate("product_newname")}
            variant="filled"
            value={value}
            requesterById={async v => {
                if (v !== undefined) {
                    const product = await productService.get(v)
                    return [product]
                }
                const pro: Product = { id: "", name: "", type: ProductType.Application, version: "", description: "", inheritance: false, client: { id: "" }, hierarchies: [], provider_ids: [], location: "", created_at: "" }
                return [pro]
            } }
            requester={async v => {
                let filter: FilterOption[] = [{ field: "name", operation: FilterOperation.StringContains, value: v }]
                if (customFilters !== undefined && customFilters[0].value !== "") {
                    filter = filter.concat(customFilters)
                }
                const products = ability.can("*", "Client") ? await productService.getAllOwned({ filters: filter }) : await productService.getAllOwned()
                return products.list
            }} getterID={elem => elem?.id} getterTitle={elem => elem.name === "" ? "" : `${elem.name} (v${elem.version})`} onClickElem={(v) => { onSelect(v?.id, v) }}></SearchInput>
    )
}

export { ProductSelector }
export default ProductSelector
