import ServicesContext from "@context/ServicesContext"
import { useContext, useEffect, useState } from "react"

const HierarchyStats = () => {
    const statsService = useContext(ServicesContext).statsService
    const [data, setData] = useState({
        critical: { detected: 0, in_triage: 0, triage_finished: 0 },
        high: { detected: 0, in_triage: 0, triage_finished: 0 },
        medium: { detected: 0, in_triage: 0, triage_finished: 0 },
        low: { detected: 0, in_triage: 0, triage_finished: 0 },
        none: { detected: 0, in_triage: 0, triage_finished: 0 }
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await statsService.getHierarchyStats()
                console.log(response)

                // Validamos que la respuesta no sea null o undefined
                if (!response || !response.detected_vulnerabilities) {
                    console.warn("Datos de la API no disponibles o incompletos")
                    return
                }

                // Mapeamos los datos con valores seguros
                setData({
                    critical: {
                        detected: response.detected_vulnerabilities.critical ?? 0,
                        in_triage: response.in_triage_vulnerabilities?.high ?? 0,
                        triage_finished: response.triage_finished_vulnerabilities?.high ?? 0
                    },
                    high: {
                        detected: response.detected_vulnerabilities.high ?? 0,
                        in_triage: response.in_triage_vulnerabilities?.high ?? 0,
                        triage_finished: response.triage_finished_vulnerabilities?.high ?? 0
                    },
                    medium: {
                        detected: response.detected_vulnerabilities.medium ?? 0,
                        in_triage: response.in_triage_vulnerabilities?.medium ?? 0,
                        triage_finished: response.triage_finished_vulnerabilities?.medium ?? 0
                    },
                    low: {
                        detected: response.detected_vulnerabilities.low ?? 0,
                        in_triage: response.in_triage_vulnerabilities?.low ?? 0,
                        triage_finished: response.triage_finished_vulnerabilities?.low ?? 0
                    },
                    none: {
                        detected: response.detected_vulnerabilities.none ?? 0,
                        in_triage: response.in_triage_vulnerabilities?.none ?? 0,
                        triage_finished: response.triage_finished_vulnerabilities?.none ?? 0
                    }
                })
            } catch (error: any) {
                console.error("Error al obtener datos:", error)
            }
        }
        fetchData()
    }, [])

    return (
        <></>
    )
}

export default HierarchyStats
