import { Alert, Button, Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions/index"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useTrack } from "@components/track/TrackContext"
import { ServicesContext } from "@context/index"
import Issue from "@models/Issue"
import { VulnerabilityJustification, VulnerabilityResponse, VulnerabilityState } from "@models/Vulnerability"
import Client from "@models/Client"
import Product, { ProductType } from "@models/Product"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"

const initValue: Issue = {
    id: "",
    key: "",
    webhook_event: "",
    status: {} as VulnerabilityState,
    project: "",
    client: "",
    product_name: "",
    product_version: "",
    cpe_name: "",
    cpe_version: "",
    cve: "",
    revised_score: "",
    justification: {} as VulnerabilityJustification,
    response: {} as VulnerabilityResponse,
    details: ""
}

const initClient: Client = {
    id: "",
    name: "",
    description: "",
    sector: undefined,
    license_validation_date: ""
}

const initProduct = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchies: [],
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    inheritance: false,
    provider_ids: [],
    location: "",
    created_at: ""
}
interface ProductNameProps {
    value: string;
    id: string;
}
const ProductNameNavigate: React.FC<ProductNameProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/product/${id}`, { state: { row: value } })
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "IssueDetail" })
    }, [])
    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                style={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}

const IssueDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const issueService = useContext(ServicesContext).issueService
    const clientService = useContext(ServicesContext).clientService
    const productService = useContext(ServicesContext).productService

    const [error, setError] = useState<Error|null>(null)

    const { issueId } = useParams<{ issueId: string }>()
    const [data, setData] = useState<Issue>(initValue)
    const [client, setClient] = useState<Client>(initClient)
    const [product, setProduct] = useState<Product>(initProduct)
    const [formData, setFormData] = useState<Issue>(initValue)

    const ability = useContext(AbilityContext)

    // Fetch license data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await issueService.get(issueId as string)
                setData(val)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [])
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await issueService.get(issueId as string)
                setData(val)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [issueId])
    useEffect(() => {
        const fecthData = async () => {
            try {
                const cli = await clientService.get(formData.client as string)
                setClient(cli)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                // navigate(-1)
            }
        }
        if (formData.client !== "") {
            fecthData()
        }
    }, [formData.client])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const filters: FilterOption[] | undefined = [] // Initialize as an empty array

                if (formData.product_name !== "" && formData.product_version !== "") {
                    filters.push({ field: "name", operation: FilterOperation.StringEqual, value: formData.product_name as string })
                    filters.push({ field: "version", operation: FilterOperation.StringEqual, value: formData.product_version as string })
                } else if (formData.product_name !== "") {
                    filters.push({ field: "name", operation: FilterOperation.StringEqual, value: formData.product_name as string })
                } else if (formData.product_version !== "") {
                    filters.push({ field: "version", operation: FilterOperation.StringEqual, value: formData.product_version as string })
                }

                if (filters.length > 0) {
                    const queryParameters: QueryParameters = { filters }
                    const prod = await productService.getAll(queryParameters)
                    setProduct(prod.list[0])
                }
            } catch (e: any) {
                setError({ message: e.message } as Error)
            }
        }

        if (formData.product_name !== "" || formData.product_version !== "") {
            fetchData()
        }
    }, [formData.product_name, formData.product_version])

    const navigate = useNavigate()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    if (data.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>{context.t.translate("loading")}</div>)

    return (<Grid item>
        <Grid item>
            <Grid item xs container flexDirection="column">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("issueDetails.title")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container gap={2}>
                        <StyledBox>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={error?.message || context.t.translate("an_error")}
                            />
                            <Grid container flexDirection="column" spacing="20px">

                                <Grid item>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                                </Grid>
                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.key")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="key" value={formData.key} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>
                                    {/* Status */}
                                    {/* CVE */}
                                    <Grid item xs={1.5}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.cve")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="cve" value={formData.cve} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>
                                    {/*
                                    !!IMPORTANT: CONFLICT WITH NEW CAF STATUS

                                    <Grid item xs={1.5}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.status")}
                                        </Typography>
                                        <VulnerabilityStateColored value={formData.status} />
                                    </Grid> */}

                                    {/* Webhook Event */}
                                    <Grid item xs={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.webhookEvent")} {/* Kept as is since it's not in issueDetails */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="webhookEvent" value={formData.webhook_event} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Project */}
                                    <Grid item xs={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.project")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="project" value={formData.project} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Client */}
                                    <Grid item xs={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.client")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="client" value={client.name} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Product Name */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.productName")}  {/* Updated */}
                                        </Typography>
                                        <ProductNameNavigate id={product.id} value={formData.product_name} />
                                    </Grid>

                                    {/* Product Version */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.productVersion")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="productVersion" value={formData.product_version} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* CPE Name */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.cpeName")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="cpeName" value={formData.cpe_name} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* CPE Version */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.cpeVersion")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="cpeVersion" value={formData.cpe_version} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Revised Score */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.revisedScore")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="revisedScore" value={formData.revised_score} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Justification */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.justification")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="justification" value={formData.justification} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Response */}
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.response")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="response" value={formData.response} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* Details */}
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("issueDetails.details")}  {/* Updated */}
                                        </Typography>
                                        <TextField disabled={!ability.can("read", "Issue")} name="details" maxRows={4} multiline value={formData.details} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid>

                </Grid>
            </Grid >
        </Grid >

    </Grid>
    )
}

export { IssueDetail }
export default IssueDetail
