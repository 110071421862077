import React, { useContext, useEffect, useState } from "react"
import { Grid, useTheme } from "@mui/material"
import GenericModal from "@components/common/modals/GenericModal"
import GenericTable from "@components/common/tables/GenericTable"
import { Provider } from "@models/Provider"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { GridColDef } from "@mui/x-data-grid"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef } from "@components/common/tables"
import ProviderTableDefinition from "@components/providers/ProviderTableDefinition"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"

interface ProviderModalProps {
    open: boolean;
    onClose: () => void;
    providerIds?: string[]; // Recibimos los ids de los proveedores como props
}

const ProviderModal: React.FC<ProviderModalProps> = ({ open, onClose, providerIds }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const providerService = useContext(ServicesContext).providerService
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const context = useContext(I18nContext)

    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProviderTableDefinition, ability))
    }, [ability])

    return (
        <GenericModal open={open} onClose={onClose} title={context.t.translate("providers")} minWidth={900}>
            <Grid width="100%">
                <Grid container item flexDirection="column" rowGap="35px">
                    <GenericTable<Provider>
                        entity="Provider"
                        columns={cols}
                        dataProvider={(filter) => {
                            // Si se proporcionan providerIds, filtramos la data
                            const updatedFilter: QueryParameters = {
                                ...filter,
                                filters: [{ field: "id", operation: FilterOperation.StringArrayContains, value: providerIds?.toString() || "" }]
                            }
                            return providerService.getAllExt(updatedFilter)
                        }}
                        onEdit={(elem: Provider) => navigate("../provider/" + elem.id)}
                        onDelete={(elem: Provider) => providerService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </GenericModal>
    )
}

export default ProviderModal
