import React, { useEffect, useState } from "react"
import { Autocomplete, CircularProgress, TextField, useTheme } from "@mui/material"
import { useFetch } from "@hooks/index"

interface Props<E>{
    label: string
    margin: "dense" | "normal" | "none"
    required?: boolean
    variant: "standard" | "filled" | "outlined"
    value?: string
    disabled?: boolean
    requester: (filter: string)=>Promise<E[]>
    requesterById: (filter: string)=>Promise<E[]>
    getterID: (elem: E)=>any
    getterTitle: (elem: E)=>string
    onClickElem: (elem: E|null)=>void
}

function SearchInput<E> (
    { label, required, margin, variant, value, disabled, requester, requesterById, getterID, getterTitle, onClickElem }: Props<E>
) {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const [queryValue, setQueryValue] = useState<string>("")
    const [inputValue, setInputValue] = useState<E | null>(null)
    const [selectedValue, setSelectedValue] = useState<E | null>(null) // Nuevo estado
    const [options, err, status] = useFetch(queryValue, requester, true, 1000)
    const [valById, errId, statusId] = useFetch(value as string, requesterById, false, 0)
    const [error, setError] = useState<Error | null>(null)
    const loading = open && (status === "debounced" || status === "fetching")

    useEffect(() => {
        if (!selectedValue && valById && valById.length > 0) {
            setInputValue(valById[0])
        }
    }, [valById, selectedValue])

    useEffect(() => {
        if (status === "error") {
            setError(err as Error)
        } else if (statusId === "error") {
            setError(errId as Error)
        } else {
            setError(null)
        }
    }, [status, statusId])

    const updateValue = (newValue: string) => {
        setQueryValue(newValue)
    }

    const handleChange = (_: any, newValue: E | null) => {
        setSelectedValue(newValue) // Guardar el valor seleccionado
        setInputValue(newValue)
        onClickElem(newValue)
    }

    return (
        <Autocomplete
            sx={{
                "& .MuiOutlinedInput-root": { borderRadius: "4px" },
                "& .MuiAutocomplete-popupIndicator": { color: theme.palette.primary.main },
                "& .MuiAutocomplete-popupIndicatorOpen": { transform: "rotate(180deg)" },
                "& .MuiAutocomplete-clearIndicator": {
                    color: theme.palette.error.main,
                    "&:hover": { color: theme.palette.error.dark }
                }
            }}
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => {
                if (!option || !value) return false
                return getterID(option) === getterID(value)
            }}
            getOptionLabel={(option) => getterTitle(option)}
            options={options}
            loading={loading}
            value={selectedValue || (inputValue && Object.keys(inputValue).length > 0 ? inputValue : null)}
            disabled={disabled}
            onInputChange={(_: any, newValue: string) => updateValue(newValue)}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    error={error != null}
                    helperText={error?.message}
                    {...params}
                    label={label}
                    variant={variant}
                    required={required}
                    margin={margin}
                    disabled={disabled}
                    sx={{
                        "&.Mui-disabled": {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.main
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                            color: theme.palette.text.primary,
                            WebkitTextFillColor: theme.palette.text.primary
                        },
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                color: theme.palette.text.primary,
                                borderColor: theme.palette.primary.main
                            }
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    )
}

export { SearchInput }
export default SearchInput
