import { getEnumKeys, ModelDefinition, ModelRelation } from "@models/utils"
import { NumVulnerability } from "./Vulnerability"

type HierarchyBase = {
 id: string;
 name: string;
 description: string;
 path: string;
 client: ModelRelation;
 is_proyect?: boolean;
 status?: CafHierarchyStatus | null
 num_vulnerability?: NumVulnerability
}

enum CafHierarchyStatus {
    // IN TRIAGE
    WaitingForCafAssessment = "waiting_caf_assessment",
    UnderAssessment = "under_assessment",

    // TRIAGE FINISHED
    WaitingForRemediation = "waiting_for_remediation",
    FinishWithRemediation = "finish_with_remediation",
    FinishWithMitigation = "finish_with_mitigation",
    FinishWithMitigationAndRemediation = "finish_with_mitigation_and_remediation",
    FinishWithoutRecommendation = "finish_without_recommendation"
}
type Hierarchy = HierarchyBase & {
    numProduct?: number;
}
const getCafHierarchyStatusColor = (status?: CafHierarchyStatus | null): string => {
    const statusColors: Record<CafHierarchyStatus, string> = {
        [CafHierarchyStatus.WaitingForCafAssessment]: "#FF9800", // Naranja
        [CafHierarchyStatus.UnderAssessment]: "#2196F3", // Azul
        [CafHierarchyStatus.WaitingForRemediation]: "#FFC107", // Amarillo oscuro
        [CafHierarchyStatus.FinishWithRemediation]: "#4CAF50", // Verde
        [CafHierarchyStatus.FinishWithMitigation]: "#8BC34A", // Verde claro
        [CafHierarchyStatus.FinishWithMitigationAndRemediation]: "#388E3C", // Verde oscuro
        [CafHierarchyStatus.FinishWithoutRecommendation]: "#E63946" // Gris
    }

    return status ? statusColors[status] || "#CCC" : "#CCC" // Negro por defecto si es null/undefined
}

const HierarchyDefinition: ModelDefinition<Hierarchy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.2 },
        { name: "path", label: "Path", type: "string", flex: 0.15 },
        { name: "description", label: "Description", type: "string", flex: 0.3 },
        { name: "num_product", label: "Products", type: "number", flex: 0.15 }
        // { name: "client.name", label: "Client", type: "struct", flex: 0.15, valueGetter: product => product.client.name, permission: { subject: "Multitenant", action: "read" } }
    ]
}
const splitPascalCase = (input: string): string => input.replace(/(?<!^)([A-Z][a-z])/g, " $1")
const HierarchyCAFStatusKeys = getEnumKeys(CafHierarchyStatus)
const HierarchyCAFStatusKeysValueOps = HierarchyCAFStatusKeys.map((key) => ({ label: splitPascalCase(key), value: CafHierarchyStatus[key] }))
export { HierarchyDefinition, CafHierarchyStatus, getCafHierarchyStatusColor, HierarchyCAFStatusKeysValueOps, HierarchyCAFStatusKeys }
export type { Hierarchy }
export default Hierarchy
