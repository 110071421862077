import React, { useContext, useEffect, useState } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { MonitorValue, TestType } from "@models/Achilles"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import { achillesStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

type OSILayer = "physical" | "datalink" | "network" | "transport" | "application";
type AchillesData = {
    [osi in OSILayer]?: {
        [protocol in TestType]?: {
            [test: string]: {
                [monitor: string]: {
                    alert: string,
                    value?: string
                }
            }
        }
    }
}

interface SectionProps {
    achillesData: AchillesData
    monitors: string[]
    template?: Template
    lang?: string
}
const i18n = {
    es: {
        indexTitle: "5.2. Resultados",
        noAnomaliesDetected: "No se han detectado anomalías.",
        protocol: "Protocolo",
        and: "y",
        seeMonitorStatus: "Ver que el monitor",
        seeMonitorStatus2: "está en estado Normal.",
        detectedAnomalies: "Se han detectado",
        detectedAnomalies2: "anomalías en los siguientes protocolos: ",
        tableTitle: "Tabla 3. Resultados agregados por protocolo y monitor",
        summaryAnomaliesDetected: "En resumen, las anomalías detectadas según categoría son:",
        severeNonConformities: "No conformidades graves:",
        minorNonConformities: "No conformidades leves:",
        assumableAnomalies: "Anomalías asumibles:",
        executionErrors: "Errores de ejecución:",
        moreDetails: "Para mayor detalle en los resultados, consulte el apartado 5.3.",
        tableDescription: "La Tabla 3 muestra los resultados agregados por protocolo y monitor (T: Test monitor, A1: ARP Monitor, I1: ICMP Monitor, L1: LinkState monitor, TP1: TCP Ports Monitor) de la ejecución final, donde los colores significan:",
        greenMeaning: "Verde: ",
        greenMeaning2: "En todas las ejecuciones todos los tests del protocolo han tenido resultado Normal.",
        yellowMeaning: "Ámbar: ",
        yellowMeaning2: "Se ha producido al menos un Warning en al menos una de las ejecuciones de al menos uno de los tests.",
        redMeaning: "Rojo:",
        redMeaning2: "Se ha producido al menos un Failure en al menos una de las ejecuciones de al menos uno de los tests. Un Failure en el monitor T indica que el test no puede reconectarse al DUT para continuar su ejecución."
    },
    en: {
        indexTitle: "5.2. Results",
        noAnomaliesDetected: "No anomalies detected.",
        seeMonitorStatus: "Check that the",
        protocol: "Protocol",
        and: "and",
        seeMonitorStatus2: "monitor is in Normal state.",
        detectedAnomalies: "Has been detected",
        detectedAnomalies2: "anomalies detected in the following protocols:",
        tableTitle: "Table 3. Aggregated results by protocol and monitor",
        summaryAnomaliesDetected: "In summary, the detected anomalies by category are:",
        severeNonConformities: "Severe non-conformities: ",
        minorNonConformities: "Minor non-conformities: ",
        assumableAnomalies: "Assumable anomalies:",
        executionErrors: "Execution errors:",
        moreDetails: "For more details on the results, see section 5.3.",
        tableDescription: "Table 3 shows the aggregated results by protocol and monitor (T: Test monitor, A1: ARP Monitor, I1: ICMP Monitor, L1: LinkState monitor, TP1: TCP Ports Monitor) from the final execution, where the colors mean:",
        greenMeaning: "Green:",
        greenMeaning2: "In all executions, all protocol tests have had a Normal result.",
        yellowMeaning: "Amber:",
        yellowMeaning2: "At least one Warning has occurred in at least one of the executions of at least one of the tests.",
        redMeaning: "Red:",
        redMeaning2: "At least one Failure has occurred in at least one of the executions of at least one of the tests. A Failure in the T monitor indicates that the test cannot reconnect to the DUT to continue its execution."
    }
} as const

const Resultado: React.FC<SectionProps> = ({ achillesData, monitors, template, lang }) => {
    // Protocol Monitor Value Map
    const [protocolMonitorValueMap, setProtocolMonitorValueMap] = useState<{ [key in TestType]?: { [key: string]: string } }>({})
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    useEffect(() => {
        const tempProtocolMonitorValueMap: { [key in TestType]?: { [key: string]: string } } = {}
        Object.keys(achillesData).forEach(osi => {
            Object.keys(achillesData[osi as OSILayer]!).forEach(protocol => {
                Object.keys(achillesData[osi as OSILayer]![protocol as TestType]!).forEach(test => {
                    Object.keys(achillesData[osi as OSILayer]![protocol as TestType]![test]).forEach(monitor => {
                        if (!tempProtocolMonitorValueMap[protocol as TestType]) {
                            tempProtocolMonitorValueMap[protocol as TestType] = {}
                        }
                        if (!tempProtocolMonitorValueMap[protocol as TestType]![monitor]) {
                            tempProtocolMonitorValueMap[protocol as TestType]![monitor] = achillesData[osi as OSILayer]![protocol as TestType]![test][monitor].alert
                        }
                    })
                })
            })
        })
        setProtocolMonitorValueMap(tempProtocolMonitorValueMap)
    }, [achillesData])
    // ------------------------------------------------

    // Generate the result message based on the status, key, protocolList and num
    const generateResultMessage = (status: boolean, key: string, protocolList?: string[], num?: number): string => {
        const formatProtocols = (protocols: string[]): string => {
            if (protocols.length === 0) return ""
            if (protocols.length === 1) return protocols[0]
            if (protocols.length === 2) return protocols.join(" y ")
            return `${protocols.slice(0, -1).join(", ")} y ${protocols[protocols.length - 1]}`
        }
        const result: {
            [key: string]: {
                true: string;
                false: (protocols: string[], numAnomalies: number) => string;
            }
        } = {
            NCG: {
                true: i18n[locale].noAnomaliesDetected + " " + i18n[locale].seeMonitorStatus + " L1 " + i18n[locale].seeMonitorStatus2,
                false: (protocols: string[], numAnomalies: number) => i18n[locale].detectedAnomalies + " " + `${numAnomalies}` + " " + i18n[locale].detectedAnomalies2 + " " + `${formatProtocols(protocols)}.`

            },
            NCL: {
                true: i18n[locale].noAnomaliesDetected + " " + i18n[locale].seeMonitorStatus + " A1, I1 " + i18n[locale].and + " TP1 " + i18n[locale].seeMonitorStatus2,
                false: (protocols: string[], numAnomalies: number) => i18n[locale].detectedAnomalies + " " + `${numAnomalies}` + " " + i18n[locale].detectedAnomalies2 + " " + `${formatProtocols(protocols)}.`
            },
            AA: {
                true: i18n[locale].noAnomaliesDetected + " " + i18n[locale].seeMonitorStatus + " A1, I1 " + i18n[locale].and + "TP1 " + i18n[locale].seeMonitorStatus2,
                false: (protocols: string[], numAnomalies: number) => i18n[locale].detectedAnomalies + " " + `${numAnomalies}` + " " + i18n[locale].detectedAnomalies2 + " " + `${formatProtocols(protocols)}.`

            },
            EE: {
                true: i18n[locale].noAnomaliesDetected + " " + i18n[locale].seeMonitorStatus + " T " + i18n[locale].seeMonitorStatus2,
                false: (protocols: string[], numAnomalies: number) => i18n[locale].detectedAnomalies + " " + `${numAnomalies}` + " " + i18n[locale].detectedAnomalies2 + " " + `${formatProtocols(protocols)}.`

            }
        }

        if (status) {
            return result[key]?.true || i18n[locale].noAnomaliesDetected
        }
        if (protocolList && num !== undefined) {
            if (result[key]) {
                return result[key].false(protocolList, num)
            }
        }
        return "Test no encontrado o faltan datos."
    }
    const EE: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        monitor === "T" &&
                        value.alert === MonitorValue.TestError
                    ).map(() => protocolName)
                )
            )
        )
    ))

    const NCG: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        monitor === "L1" &&
                        value.alert === MonitorValue.MonitorYellow &&
                        !EE.includes(protocolName)
                    ).map(() => protocolName)
                )
            )
        )
    ))
    const NCL: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        value.alert === MonitorValue.MonitorYellow &&
                        parseFloat(value.value as string) >= 50.00 &&
                        !EE.includes(protocolName)
                    ).map(() => protocolName)
                )
            )
        )
    ))
    const AA: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        value.alert === MonitorValue.MonitorYellow &&
                        parseFloat(value.value as string) < 50.00 &&
                        !EE.includes(protocolName)
                    ).map(() => protocolName)
                )
            )
        )
    ))
    // --------------------------------------------------

    // Functions
    const getColor = (values: MonitorValue[]): string => {
        if (values.includes(MonitorValue.MonitorRed) || values.includes(MonitorValue.TestRed)) {
            return "#ff9a9a" // Red
        } else if (values.includes(MonitorValue.MonitorYellow) || values.includes(MonitorValue.TestYellow)) {
            return "#ffffad" // Yellow
        } else if (values.every(value => value === MonitorValue.MonitorGreen || value === MonitorValue.TestGreen)) {
            return "#c2f09d" // Green
        }
        return "#ff9a9a" // White
    }
    // --------------------------------------------------

    return (
        <Page size="A4" style={{ paddingBottom: 80, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }} wrap>
            <Header template={template} externalData={undefined}/>
            <View style={{ ...achillesStyles.section }}>
                <View>
                    <Text style={{ ...achillesStyles.titulo }}>{i18n[locale].indexTitle}</Text>
                    <Text style={{ ...achillesStyles.textua }}>
                        {i18n[locale].tableDescription}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • &nbsp;<Text style={{ ...achillesStyles.textua, color: "green" }}>{i18n[locale].greenMeaning} </Text>{i18n[locale].greenMeaning2}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • &nbsp;<Text style={{ ...achillesStyles.textua, color: "orange" }}>{i18n[locale].yellowMeaning} </Text>{i18n[locale].yellowMeaning2}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • &nbsp;<Text style={{ ...achillesStyles.textua, color: "red" }}>{i18n[locale].redMeaning}  </Text> {i18n[locale].redMeaning2}
                    </Text>

                    <View style={{ ...achillesStyles.tableScope, marginTop: 15 }}>
                        <View style={{ ...achillesStyles.tableRowScope, backgroundColor: "yellow" }}>
                            <View style={{ ...achillesStyles.tableColScope }}>
                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>Monitor</Text>
                            </View>
                        </View>
                        <View style={{ ...achillesStyles.tableRowScope }}>
                            <View style={{ ...achillesStyles.tableColScope, backgroundColor: "white" }}>
                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{i18n[locale].protocol}</Text>
                            </View>
                            {monitors && monitors.map((monitor, idx) => (
                                <View key={idx} style={{
                                    ...achillesStyles.tableColScope,
                                    backgroundColor: "white"
                                }}>
                                    <Text wrap style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{monitor}</Text>
                                </View>
                            ))}
                        </View>
                        {protocolMonitorValueMap && Object.entries(protocolMonitorValueMap).map(([protocol, monitorList], pIndex) => (
                            <View key={pIndex} style={{ ...achillesStyles.tableRowScope }} break={pIndex === 10}>
                                <View style={{ ...achillesStyles.tableColScope, backgroundColor: "white" }}>
                                    <Text wrap={false} style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{protocol}</Text>
                                </View>
                                {monitors.map((monitor, idx) => {
                                    const monitorValue = monitorList[monitor]
                                    const color = monitorValue ? getColor([monitorValue as MonitorValue]) : "#ffffff"

                                    return (
                                        <View key={idx} style={{ ...achillesStyles.tableColScope, backgroundColor: color }}>
                                            <Text style={{ ...achillesStyles.texto, fontFamily: "Bold", fontSize: 9 }}>
                                                {color === "#ffffff" && monitor === "T" ? "N/A" : " "}
                                            </Text>
                                        </View>
                                    )
                                })}
                            </View>
                        ))}

                    </View>
                    <Text style={{ ...achillesStyles.textua, marginBottom: 30, marginLeft: "25%", marginRight: "-50%", fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }}>
                        {i18n[locale].tableTitle}
                    </Text>

                    <Text style={{ ...achillesStyles.textua }}>
                        {i18n[locale].summaryAnomaliesDetected}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • {i18n[locale].severeNonConformities} {generateResultMessage(NCG.length === 0, "NCG", NCG, NCG.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • {i18n[locale].minorNonConformities} {generateResultMessage(NCL.length === 0, "NCL", NCL, NCL.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • {i18n[locale].assumableAnomalies} {generateResultMessage(AA.length === 0, "AA", AA, AA.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • {i18n[locale].executionErrors} {generateResultMessage(EE.length === 0, "EE", EE, EE.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua }}>
                        {i18n[locale].executionErrors}
                    </Text>
                </View>
            </View>
            <Footer externalData={null}/>
        </Page>
    )
}

export { Resultado }
