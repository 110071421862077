import Hierarchy from "@models/Hierarchy"
import { ModelDefinition } from "@models/utils"
import HierarchyStatusColored from "@views/hierarchy/HierarchyStatusColored"
import HierarchyNameNavigate from "./HierarchyNameNavigate"

const HierarchyTableDefinition: ModelDefinition<Hierarchy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, minWidth: 100, isId: true },
        { name: "name", label: "Name", flex: 0.2, type: "string", render: HierarchyNameNavigate, minWidth: 150 },
        { name: "description", label: "Description", flex: 0.4, type: "string", minWidth: 150 },
        { name: "status", label: "Status", flex: 0.4, type: "string", render: HierarchyStatusColored, minWidth: 150 }

    ]
}

export default HierarchyTableDefinition
