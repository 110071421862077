import { BenchmarkLifeCycle, BenchmarkLifeCycleAssessment, BenchmarkLifeCycleProduct, BenchmarkProduct } from "@models/Benchmark"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class BenchmarkService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/benchmark", MediaTypes.JSON)
    }

    public getBenchmarkProduct = (id: string) => {
        return this.http.get(`/${this.path}/product/${id}`) as Promise<BenchmarkProduct[]>
    }

    public getBenchmarkLifeCycle = (interval?: string) => {
        return this.http.get(`/${this.path}/lifecycle/vulnerabilities?timeRange=${interval || "monthly"}`) as Promise<BenchmarkLifeCycle[]>
    }

    public getBenchmarkLifeCycleForProducts = () => {
        return this.http.get(`/${this.path}/lifecycle/products`) as Promise<BenchmarkLifeCycleProduct[]>
    }

    public getBenchmarkLifeCycleForAssessments = () => {
        return this.http.get(`/${this.path}/lifecycle/assessments`) as Promise<BenchmarkLifeCycleAssessment[]>
    }
}

export { BenchmarkService }
export default BenchmarkService
