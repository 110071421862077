import React from "react"
import { Circle, Path, Rect, Svg, Text } from "@react-pdf/renderer"

interface CVSS3Severity {
    None: number
    Low: number
    Medium: number
    High: number
    Critical: number
    Unknown: number
    Empty: boolean
}
function findFirstNonZeroIndex (arr: string | any[]) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
            return i
        }
    }
    return -1
}

const ProgressCircle: React.FC<CVSS3Severity> = (severityData) => {
    // const [empty, setEmpty] = useState<boolean>(false)
    // Porcentaje de progreso para cada sección
    const progressValues = Object.values(severityData).map(severity => severity / 10)
    progressValues[6] = 1

    // Asociación de colores a cada nivel de severidad
    const severityColors: any = {
        None: "#CCCCCC",
        Low: "#A8D1E7",
        Medium: "#FF8537",
        High: "#D9534F",
        Critical: "#000000",
        Unknown: "#808080"
    }

    if (severityData.Empty) {
        progressValues.pop()
        progressValues.push(1)
    }

    // Radio del círculo
    // const radius = 130
    const radius = 100
    const centerX = 65
    const centerY = 65

    // Función para generar el path de un segmento de círculo
    const generateSegmentPath = (startAngle: number, endAngle: number) => {
        const largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1
        const startX = centerX + radius * Math.cos(startAngle)
        const startY = centerY + radius * Math.sin(startAngle)
        const endX = centerX + radius * Math.cos(endAngle)
        const endY = centerY + radius * Math.sin(endAngle)
        return [
            `M ${centerX},${centerY}`,
            `L ${startX},${startY}`,
            `A ${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY}`,
            "Z" // Cerrar el path
        ].join(" ")
    }

    // Función para generar los paths de los segmentos
    const generateSegmentPaths = (empty: boolean) => {
        if (empty) {
            const severityColorsEmpty: any = {
                None: "#CCCCCC",
                Low: "#A8D1E7",
                Medium: "#FF8537",
                High: "#7940A5",
                Critical: "#000000",
                Unknown: "#808080",
                Empty: severityColors[Object.keys(severityData)[findFirstNonZeroIndex(progressValues)]]
            }
            let startAngle = -Math.PI / 2 // Ángulo inicial
            return progressValues.map((progress, index) => {
                const endAngle = startAngle + 2 * Math.PI * progress // Ángulo final del segmento
                const pathData = generateSegmentPath(startAngle, endAngle)
                startAngle = endAngle // Actualizar el ángulo inicial para el siguiente segmento
                return { pathData, color: severityColorsEmpty[Object.keys(severityData)[index]] } // Asignar un color diferente a cada segmento basado en la severidad
            })
        }
        let startAngle = -Math.PI / 2 // Ángulo inicial
        return progressValues.map((progress, index) => {
            const endAngle = startAngle + 2 * Math.PI * progress // Ángulo final del segmento
            const pathData = generateSegmentPath(startAngle, endAngle)
            startAngle = endAngle // Actualizar el ángulo inicial para el siguiente segmento
            return { pathData, color: severityColors[Object.keys(severityData)[index]] } // Asignar un color diferente a cada segmento basado en la severidad
        })
    }

    return (
        // <Svg width="100%" height="100%" style={{ marginTop: -170, marginLeft: 60 }} viewBox="-80 20 400 400">
        <Svg width="100%" height="100%" style={{ marginTop: -210, marginLeft: 60 }} viewBox="-80 20 400 400">
            {generateSegmentPaths(severityData.Empty).map(({ pathData, color }, index) => (

                <Path key={index} d={pathData} fill={color} stroke="none" />

            ))}
            <Circle cx="65" cy="65"
                // r="65"
                r="50"
                fill="#271968" />
            <Rect width="5" height="5" x="-10" y="180" fill="black"/>
            <Text x="0" y="185" style={{ fontFamily: "Griff", fontSize: 9, fill: "#fff" }}>Crítica</Text>
            <Rect width="5" height="5" x="35" y="180" fill="#7940A5"/>
            <Text x="45" y="185" style={{ fontFamily: "Griff", fontSize: 9, fill: "#fff" }}>Alta</Text>
            <Rect width="5" height="5" x="70" y="180" fill="#FF8537"/>
            <Text x="80" y="185" style={{ fontFamily: "Griff", fontSize: 9, fill: "#fff" }}>Media</Text>
            <Rect width="5" height="5" x="115" y="180" fill="#A8D1E7"/>
            <Text x="125" y="185" style={{ fontFamily: "Griff", fontSize: 9, fill: "#fff" }}>Baja</Text>

        </Svg>
    )
}

export default ProgressCircle
