import React, { useContext, useEffect, useState } from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import License from "@models/License"
import LicenseTableDefinition, { LicenseTableDefinitionES } from "@components/license/LicenseTableDefinition"
import { useTrack } from "@components/track/TrackContext"
import ActionButton from "@components/common/Button/ActionButton"

const LicenseList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const licenseService = useContext(ServicesContext).licenseService
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "LicenseList" })
    }, [])
    const [cols, setCols] = useState<GridColDef[]>([])

    const ability = useContext(AbilityContext)
    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const policyTableDefinition = context.language === "es" ? LicenseTableDefinition : LicenseTableDefinitionES
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    const navigate = useNavigate()

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("license_management")}
                </Typography>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <Can I="create" a="License">
                        <ActionButton onClick={() => navigate("./add")} text={context.t.translate("add_new_license")} />
                    </Can>
                </Grid>
            </Grid>
            <Grid item container flexDirection="column" sx={{ marginTop: "0px", paddingTop: "0px" }}>
                <Grid item container>
                    <GenericTable<License> entity="License" columns={cols}
                        dataProvider={(filter) => licenseService.getAll(filter)}
                        onEdit={(elem: License) => navigate("./" + elem.id)}
                        onDelete={(elem: License) => licenseService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </Grid>

    )
}

export { LicenseList }
export default LicenseList
