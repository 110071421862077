import ServicesContext from "@context/ServicesContext"
import React, { useContext, useEffect, useRef, useState } from "react"

type ComponentViewProps = {
    id: string
}

const ComponentView: React.FC<ComponentViewProps> = ({ id }) => {
    const fileService = useContext(ServicesContext).fileService
    const [fileUrl, setFileUrl] = useState<string | null>(null)
    const iframeRef = useRef<HTMLIFrameElement | null>(null)

    const fetchSunshine = async () => {
        try {
            const response = await fileService.getSunshineByAssessment(id ?? "")
            const blob = new Blob([response], { type: "text/html" })
            const url = URL.createObjectURL(blob)
            setFileUrl(url)
        } catch (error) {
            console.error(error)
        }
    }

    const modifyIframeStyles = () => {
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument

            if (iframeDocument) {
                const body = iframeDocument.querySelector("body")
                if (body) {
                    body.style.backgroundColor = "#F7F6F9" // Cambiar el color de fondo
                }

                const lightTextElements = iframeDocument.querySelectorAll(".light-text")
                lightTextElements.forEach((el) => el.remove())

                const uploadFileContainer = iframeDocument.querySelector("#upload-file-container")
                if (uploadFileContainer) {
                    uploadFileContainer.remove()
                }

                const footerElement = iframeDocument.querySelector("#footer")
                if (footerElement) {
                    footerElement.remove()
                }

                const head = iframeDocument.querySelector("head")
                if (head) {
                    const styleTag = iframeDocument.createElement("style")
                    styleTag.innerHTML = `
                        body {
                            background-color: #F7F6F9 !important;
                        }
                    `
                    head.appendChild(styleTag)
                }
            }
        }
    }

    useEffect(() => {
        fetchSunshine()
    }, [id])

    useEffect(() => {
        if (fileUrl && iframeRef.current) {
            iframeRef.current.onload = () => {
                modifyIframeStyles() // Aplica los cambios después de que el iframe haya cargado
            }
        }
    }, [fileUrl])

    return fileUrl
        ? (
            <iframe
                ref={iframeRef}
                src={fileUrl}
                style={{ width: "100%", height: "600px", border: "none" }}
            />
        )
        : (
            <p>Loading...</p>
        )
}

export default ComponentView
