import React, { useContext } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { AbilityContext } from "@components/permissions/index"
import { ClientDetail, ClientForm, ClientList } from "@views/client/index"
import PrivateRoutes from "@components/route"
import UserForm from "./user/UserForm"
import UserDetail from "./user/UserDetail"

const entity = "Client"
const Client: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []
    const navigate = useNavigate()

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <ClientList/>
            }
        ])
    } else {
        navigate(PrivateRoutes.HOME)
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: ":id",
                component: <ClientDetail/>
            }
        ])
    }

    if (ability.can("create", entity)) {
        routes.push(...[
            {
                route: "/add",
                component: <ClientForm/>
            }

        ])
    }

    routes.push(...[
        {
            route: ":id/add",
            component: <UserForm/>
        }
    ])
    routes.push(...[
        {
            route: ":id/user/:userId",
            component: <UserDetail/>
        }
    ])

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Client }
export default Client
