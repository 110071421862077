// stats/StatsCard.tsx
import React, { useContext, useState } from "react"
import { Box } from "@mui/material"
import { Subject } from "@casl/ability"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "../Snackbar/Snackbar"
import StatsCardSkeleton from "./StatsSkeleton"
import { useStatsData } from "./useStatsData"
import ComponentStats from "./ComponentStats"
import AssessmentStats from "./AssessmentStats"
import VulnerabilityStats from "./VulnerabilityStats"
import TagStats from "./TagStats"
import FadeInBox from "./components/FadeInBox"
import CAFVulnerabilityStats from "./CAFVulnerabilityStats"
import ProductStats from "./ProductStats"
import HierarchyStatsGraphs from "./HierarchyStatsGraphs"

interface StatsCardProps {
    title: string;
    entity: Subject;
    timeRange?: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ title, entity, timeRange }) => {
    const context = useContext(I18nContext)
    const { isLoading, error } = useStatsData(entity as string, timeRange)
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(!!error)
    const [isLoaded, setIsLoaded] = useState(false)

    // Check if context is available
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    // Set isLoaded state when loading is complete
    React.useEffect(() => {
        if (!isLoading) {
            setIsLoaded(true)
        }
        if (error) {
            setOpenSnackbar(true)
        }
    }, [isLoading, error])

    // Render the appropriate stats component based on entity type
    const renderStatsComponent = () => {
        switch (entity) {
        case "Component":
            return <ComponentStats timeRange={timeRange} />
        case "Assessment":
            return <AssessmentStats timeRange={timeRange} />
        case "Vulnerability":
            return <VulnerabilityStats timeRange={timeRange} />
        case "CAFVulnerability":
            return <CAFVulnerabilityStats timeRange={timeRange} />
        case "Product":
            return <ProductStats timeRange={timeRange} />
        case "Tag":
            return <TagStats timeRange={timeRange} />
        case "Hierarchy":
            return <HierarchyStatsGraphs timeRange={timeRange} />
        default:
            return null
        }
    }

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, p: 2 }}>
            <CustomSnackbar
                message={error || context.t.translate("an_error")}
                onClose={() => setOpenSnackbar(false)}
                open={openSnackbar}
            />

            {isLoading
                ? (
                    <StatsCardSkeleton entity={entity} />
                )
                : (
                    <FadeInBox isLoaded={isLoaded}>
                        {renderStatsComponent()}
                    </FadeInBox>
                )}
        </Box>
    )
}

export default StatsCard
