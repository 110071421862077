import { Box, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"
import { HardwareComponentsOrigin } from "@models/HardwareComponents"
import ActionButton from "@components/common/Button/ActionButton"
import SecurityAdvisoriesTable from "./SecurityAdvisoriesTable"

const HardwareComponentDetails: React.FC = () => {
    const theme = useTheme()
    const hardwareComponentService = useContext(ServicesContext).hardwareComponentService
    const { hardwareid } = useParams<{ hardwareid: string }>()
    const [formData, setFormData] = useState<any>(null)
    const [initialData, setInitialData] = useState<any>(null)
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const navigate = useNavigate()
    const context = useContext(I18nContext)

    if (context === null) throw new Error("The I18n context is not initialized.")

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await hardwareComponentService.get(hardwareid as string)
                setFormData(data)
                setInitialData(data)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        if (hardwareid) fetchData()
    }, [hardwareid, hardwareComponentService])

    const hasChanges = (): boolean => {
        return JSON.stringify(formData) !== JSON.stringify(initialData)
    }

    const formValid = (): boolean => {
        return formData?.identifier?.trim() !== "" && formData?.name?.trim() !== "" && hasChanges()
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await hardwareComponentService.update(hardwareid, formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Identifier
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            label="Identifier"
                            name="identifier"
                            value={formData?.identifier || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Name
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            variant="filled"
                            label="Name"
                            name="name"
                            value={formData?.name || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Origin
                        </Typography>
                        <TextField
                            margin="normal"
                            select
                            fullWidth
                            variant="filled"
                            label="Origin"
                            name="origin"
                            value={formData?.origin || HardwareComponentsOrigin.SIEMENS}
                            onChange={handleInputChange}
                        >
                            <MenuItem value={HardwareComponentsOrigin.SIEMENS}>
                                <Typography fontFamily="Griff" fontWeight="bolder">SIEMENS</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.DEMO}>
                                <Typography fontFamily="Griff" fontWeight="bolder">DEMO</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.ST}>
                                <Typography fontFamily="Griff" fontWeight="bolder">ST</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.RENESAS}>
                                <Typography fontFamily="Griff" fontWeight="bolder">RENESAS</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.CISA}>
                                <Typography fontFamily="Griff" fontWeight="bolder">CISA</Typography>
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <ActionButton
                    type="submit"
                    disabled={!formValid()}
                    text="Save Changes"
                    style={{ width: "100%", marginTop: "20px" }}
                />
            </Box>
            <Box>
                <SecurityAdvisoriesTable id={hardwareid} />
            </Box>
        </Box>
    )
}

export default HardwareComponentDetails
