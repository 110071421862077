import HardwareComponentExt from "@models/HardwareComponents"
import { ModelDefinition } from "@models/utils"
import { Button, Typography, useTheme } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
const HardwareComponentNavigate: React.FC<HardwareComponentNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    console.log(value)

    const handleClick = () => {
        navigate(`./hardwarecomponent/${id}`)
    }

    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                sx={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": {
                        color: theme.palette.text.secondary
                    }
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}
const HardwareComponentTableDefinition: ModelDefinition<HardwareComponentExt> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.05, isId: true },
        {
            name: "identifier",
            label: "Identifier",
            type: "string",
            flex: 0.5,
            render: HardwareComponentNavigate
        },
        { name: "name", label: "Name", type: "string", flex: 0.5 },
        { name: "origin", label: "Origin", type: "string", flex: 0.35 },
        { name: "num_remediations", label: "Num remediations", type: "number", flex: 0.25 }
    ]
}

interface HardwareComponentNavigateProps {
    value: string;
    id: string;
}

export { HardwareComponentTableDefinition }
export default HardwareComponentTableDefinition
