/* eslint-disable no-useless-catch */
// stats/hooks/useStatsData.ts
import { useContext, useEffect, useState } from "react"
import { ServicesContext } from "@context/index"
import {
    ComponentStats,
    StatsAssessments,
    StatsProducts,
    StatsTags,
    StatsVulnerabilities
} from "@models/Stats"
import { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { CVSS3Severity, VulnerabilityState } from "@models/Vulnerability"
import { ProductType } from "@models/Product"
import { TagCategoryType } from "@models/Tag"
import { I18nContext } from "I18nProvider"
import { ColumnValues } from "@components/hierarchy/HierarchyColumnChart"
import { AssessmentTypeColors } from "../colors/AssessmentTypeColors"
import { AssessmentStatusColors } from "../colors/AssessmentStatusColors"
import { ProductTypeColors } from "../colors/ProductTypeColors"
import { TagCategoryStateColors } from "../colors/TagCategoryStateColors"
import { VulnerabilityStateColors } from "../colors/VulnerabilityStateColors"

export interface DonutData {
    name: string;
    y: number;
    color: string;
}

interface ChartData {
    title: string;
    dataType: string;
    data: DonutData[];
    number: number;
    footer: string;
}

export interface StatsDataState {
    isLoading: boolean;
    error: string | null;
    circleCharts?: {
        suppressed: ChartData;
        outdated: ChartData;
        inherited: ChartData;
    };
    byProduct?: DonutData[];
    byCategory?: DonutData[];
    byStatus?: DonutData[];
    byVulnState?: DonutData[];
    byType?: DonutData[];
    byPolicies?: DonutData[];
    byLicenses?: DonutData[];
    productNumber?: number;
    totalTests?: number;
    testTypesNumber?: number;
    mitigatedVulnerability?: number;
    mitigatedVulnerabilities?: { [key in CVSS3Severity]: number };
    unmitigatedVulnerability?: number;
    unmitigatedVulnerabilities?: { [key in CVSS3Severity]: number };
    stateNumber?: number;
    typeNumber?: number;
    policyNumber?: number;
    licenseNumber?: number;
    assignedTagProduct?: number;
    unassignedTagProduct?: number;
    tagProductNumber?: number;
    categoryNumber?: number;
    topItems?: any[];
    vulns_by_category?: { [key: string]: number }
    detectedState?: DonutData[];
    inTriageState?: DonutData[];
    triageFinishState?: DonutData[];
    detectedTotal?: number;
    inTriageTotal?: number;
    triageFinishTotal?: number;
    inTriage?: {
        counts: {
            total: number,
            under_assessment: number,
            waiting_caf_assessment: number
        }
    };
    inTriageFinished?: {
        counts: {
            finish_with_mitigation: number,
            finish_with_mitigation_and_remediation: number,
            finish_with_remediation: number,
            finish_without_recommendation: number,
            total: number,
            waiting_for_remediation: number
        }
    }
    hierarchyStatsData?: ColumnValues
}

const getRandomColor = () => {
    const letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export const useStatsData = (entity: string, timeRange?: string, client_id?: string) => {
    const statsService = useContext(ServicesContext).statsService
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [state, setState] = useState<StatsDataState>({
        isLoading: true,
        error: null
    })

    useEffect(() => {
        const fetchData = async () => {
            setState(prev => ({ ...prev, isLoading: true }))

            try {
                switch (entity) {
                case "Component":
                    await fetchComponentStats()
                    break
                case "Assessment":
                    await fetchAssessmentStats()
                    break
                case "Vulnerability":
                    await fetchVulnerabilityStats()
                    break
                case "CAFVulnerability":
                    await fetchCAFVulnerabilityStats(client_id || "")
                    break
                case "Product":
                    await fetchProductStats()
                    break
                case "Tag":
                    await fetchTagStats()
                    break
                case "Hierarchy":
                    await fetchHierarchyStats()
                    break
                }
            } catch (err: any) {
                setState(prev => ({
                    ...prev,
                    isLoading: false,
                    error: err.message || context.t.translate("an_error")
                }))
            }
        }

        fetchData()
    }, [entity, timeRange])

    const fetchHierarchyStats = async () => {
        try {
            const response = await statsService.getHierarchyStats(timeRange)

            if (!response || !response.detected_vulnerabilities) {
                console.warn("Datos de la API no disponibles o incompletos")
                return
            }

            setState(prev => ({
                ...prev,
                isLoading: false,
                inTriage: response.in_triage,
                inTriageFinished: response.in_triage_finished,
                hierarchyStatsData: {
                    values: {
                        critical: {
                            detected: response.detected_vulnerabilities.critical ?? 0,
                            in_triage: response.in_triage_vulnerabilities?.critical ?? 0,
                            triage_finished: response.triage_finished_vulnerabilities?.critical ?? 0
                        },
                        high: {
                            detected: response.detected_vulnerabilities.high ?? 0,
                            in_triage: response.in_triage_vulnerabilities?.high ?? 0,
                            triage_finished: response.triage_finished_vulnerabilities?.high ?? 0
                        },
                        medium: {
                            detected: response.detected_vulnerabilities.medium ?? 0,
                            in_triage: response.in_triage_vulnerabilities?.medium ?? 0,
                            triage_finished: response.triage_finished_vulnerabilities?.medium ?? 0
                        },
                        low: {
                            detected: response.detected_vulnerabilities.low ?? 0,
                            in_triage: response.in_triage_vulnerabilities?.low ?? 0,
                            triage_finished: response.triage_finished_vulnerabilities?.low ?? 0
                        },
                        none: {
                            detected: response.detected_vulnerabilities.none ?? 0,
                            in_triage: response.in_triage_vulnerabilities?.none ?? 0,
                            triage_finished: response.triage_finished_vulnerabilities?.none ?? 0
                        }
                    }
                }
            }))
        } catch (err: any) {
            throw err
        }
    }

    const fetchComponentStats = async () => {
        try {
            const response = await statsService.GetStatsComponents(timeRange)

            if (!response || Object.keys(response).length === 0) {
                throw new Error("No component data available")
            }

            setState(prev => ({
                ...prev,
                isLoading: false,
                circleCharts: {
                    suppressed: transformSuppressedData(response.suppressed || []),
                    outdated: transformOutdatedData(response.outdated || []),
                    inherited: transformInheritedData(response.inherited || [])
                },
                topItems: response.top_vulnerable_components || []
            }))
        } catch (err: any) {
            throw err
        }
    }

    const fetchAssessmentStats = async () => {
        try {
            const response: StatsAssessments = await statsService.getStatsAssessment(timeRange)

            const byProduct = response?.ProductName
                ? Object.keys(response.ProductName).map((key) => ({
                    name: key,
                    y: response.ProductName[key],
                    color: getRandomColor()
                }))
                : []

            const byCategory = response?.TestCategory
                ? (Object.keys(response.TestCategory) as AssessmentType[]).map((key) => ({
                    name: key,
                    y: response.TestCategory[key],
                    color: AssessmentTypeColors[key] || getRandomColor()
                }))
                : []

            const byStatus = response?.TestStatus
                ? (Object.keys(response.TestStatus) as AssessmentStatus[]).map((key) => ({
                    name: key,
                    y: response.TestStatus[key],
                    color: AssessmentStatusColors[key] || getRandomColor()
                }))
                : []

            setState(prev => ({
                ...prev,
                isLoading: false,
                byProduct,
                byCategory,
                byStatus,
                productNumber: response?.ProductNumber || 0,
                testTypesNumber: response?.TestTypesNumber || 0,
                totalTests: response?.TotalTests || 0,
                topItems: response?.top_vulnerable_assessments || []
            }))
        } catch (err: any) {
            throw err
        }
    }

    const fetchVulnerabilityStats = async () => {
        try {
            const response: StatsVulnerabilities = await statsService.getStatsVulnerabilities(timeRange)

            const byVulnState = (Object.keys(response.VulnerabilityStates) as VulnerabilityState[]).map((key) => ({
                name: key,
                y: response.VulnerabilityStates[key],
                color: VulnerabilityStateColors[key] || getRandomColor()
            }))

            setState(prev => ({
                ...prev,
                isLoading: false,
                byVulnState,
                unmitigatedVulnerability: response.UnmitigatedVulnerability,
                mitigatedVulnerability: response.MitigatedVulnerability,
                stateNumber: response.StateNumber,
                mitigatedVulnerabilities: response.MitigatedVulnerabilities,
                unmitigatedVulnerabilities: response.UnmitigatedVulnerabilities,
                topItems: response.top_vulnerable_components
            }))
        } catch (err: any) {
            throw err
        }
    }
    const fetchCAFVulnerabilityStats = async (id: string) => {
        try {
            const response: StatsVulnerabilities = await statsService.getCafStats(timeRange)

            // Desestructurar con un fallback en caso de que no exista
            const vulns_by_category = response?.vulns_by_category || {}

            // Función para dar formato a las claves
            const formatVulnStatusName = (key: string): string =>
                key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())

            // Verificar que existan datos antes de mapear
            const detectedData = vulns_by_category.detected
                ? Object.keys(vulns_by_category.detected)
                    .filter(key => key !== "total")
                    .map((key) => ({
                        name: formatVulnStatusName(key),
                        y: vulns_by_category.detected[key],
                        color: getRandomColor()
                    }))
                : []

            const detectedTotal = vulns_by_category.detected?.total || 0

            const inTriageData = vulns_by_category.in_triage
                ? Object.keys(vulns_by_category.in_triage)
                    .filter(key => key !== "total")
                    .map((key) => ({
                        name: formatVulnStatusName(key),
                        y: vulns_by_category.in_triage[key],
                        color: getRandomColor()
                    }))
                : []

            const inTriageTotal = vulns_by_category.in_triage?.total || 0

            const triageFinishData = vulns_by_category.triage_finish
                ? Object.keys(vulns_by_category.triage_finish)
                    .filter(key => key !== "total")
                    .map((key) => ({
                        name: formatVulnStatusName(key),
                        y: vulns_by_category.triage_finish[key],
                        color: getRandomColor()
                    }))
                : []

            const triageFinishTotal = vulns_by_category.triage_finish?.total || 0

            setState(prev => ({
                ...prev,
                isLoading: false,
                detectedState: detectedData,
                inTriageState: inTriageData,
                triageFinishState: triageFinishData,
                detectedTotal,
                inTriageTotal,
                triageFinishTotal,
                topItems: response.top_vulnerable_components || []
            }))
        } catch (err: any) {
            setState(prev => ({
                ...prev,
                isLoading: false,
                error: err.message || "Error fetching CAF vulnerability stats"
            }))
        }
    }

    const fetchProductStats = async () => {
        try {
            const response: StatsProducts = await statsService.getStatsProduct(timeRange || "all")

            const byType = response?.ProductTypes
                ? (Object.keys(response.ProductTypes) as ProductType[]).map((key) => ({
                    name: key,
                    y: response.ProductTypes[key],
                    color: ProductTypeColors[key] || getRandomColor()
                }))
                : []

            const byLicenses = response?.LicenseCountByType
                ? (Object.keys(response.LicenseCountByType) as string[]).map((key) => ({
                    name: key,
                    y: response.LicenseCountByType[key],
                    color: getRandomColor()
                }))
                : []
            console.log(response.top_vulnerable_products)

            setState(prev => ({
                ...prev,
                isLoading: false,
                byType,
                byLicenses,
                typeNumber: response?.TypeNumber || 0,
                policyNumber: response?.PolicyNumber || 0,
                licenseNumber: response?.LicenseNumber || 0,
                topItems: response?.top_vulnerable_products || []
            }))
        } catch (err: any) {
            throw err
        }
    }

    const fetchTagStats = async () => {
        try {
            const response: StatsTags = await statsService.getStatsTags(timeRange)

            const byCategory = (Object.keys(response.TagCategory) as TagCategoryType[]).map((key) => ({
                name: key,
                y: response.TagCategory[key],
                color: TagCategoryStateColors[key] || getRandomColor()
            }))

            setState(prev => ({
                ...prev,
                isLoading: false,
                byCategory,
                tagProductNumber: response.TagedProducts,
                unassignedTagProduct: response.UntagedProducts,
                topItems: response.top_tags
            }))
        } catch (err: any) {
            throw err
        }
    }

    const transformSuppressedData = (suppressed: ComponentStats["suppressed"]): ChartData => ({
        title: "Suppressed Components",
        dataType: "array",
        data: [
            { name: "False", color: "#7940A5", y: suppressed.false },
            { name: "True", color: "#D9534F", y: suppressed.true }
        ],
        number: suppressed.total,
        footer: ""
    })

    const transformOutdatedData = (outdated: ComponentStats["outdated"]): ChartData => ({
        title: "Outdated Components",
        dataType: "array",
        data: [
            { name: "False", color: "#EC971F", y: outdated.false },
            { name: "True", color: "#F2CC0C", y: outdated.true }
        ],
        number: outdated.total,
        footer: ""
    })

    const transformInheritedData = (inherited: ComponentStats["inherited"]): ChartData => ({
        title: "Inherited Components",
        dataType: "array",
        data: [
            { name: "False", color: "#8dd3c7", y: inherited.false },
            { name: "True", color: "#A9A9A9", y: inherited.true }
        ],
        number: inherited.total,
        footer: ""
    })

    return { ...state }
}
