// components/PolicyBasicInfo.tsx
import React from "react"
import { FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material"
import Policy from "@models/Policy"
import { actionsTypeOptions, eventTypeOptions } from "./constants/constAndInit"

interface PolicyBasicInfoProps {
    formData: Policy;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown; checked?: boolean; type?: string }>) => void;
}

const PolicyBasicInfo: React.FC<PolicyBasicInfoProps> = ({ formData, onChange }) => {
    return (
        <Grid container spacing={2} marginBottom={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    variant="filled"
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={onChange}
                    multiline
                    rows={2}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="normal"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Event Type"
                    name="event_type"
                    value={formData.event_type}
                    onChange={onChange}
                >
                    {eventTypeOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="normal"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Action Type"
                    name="policy_actions"
                    value={formData.policy_actions}
                    onChange={onChange}
                >
                    {actionsTypeOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.status}
                            onChange={onChange}
                            name="status"
                            color="primary"
                        />
                    }
                    label="Policy Status (Active/Inactive)"
                    sx={{ marginTop: "16px" }}
                />
            </Grid>
        </Grid>
    )
}

export default PolicyBasicInfo
