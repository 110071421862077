/* eslint-disable no-tabs */
import { FilterOperation } from "@utils/queryParams"
import { ModelRelation } from "./utils"

type Filter = {
    id: string,
    type: FilterTypes,
    filters: Filters[],
    favorite: boolean,
    client: ModelRelation
}

type Filters = {
    Field: string,
    FilterOperation: FilterOperations, // Cambié el tipo de FilterOperation a FilterOperations (enum)
    Value: string[]
}

enum FilterTypes {
    Assessment = "assessment",
    Product = "product",
    Vulnerabilities = "vulnerability",
    Hierarchiess = "hierarchy",
    Provider = "provider",
    Clients = "client",
    Tagss = "tag",
    Components = "component"
}

enum FilterOperations {
    StringEqual = 1,
    StringNotEqual = 2,
    StringContains = 3,
    StringContainsStringArray = 4,
    StringNotContains = 5,
    StringArrayContains = 6,
    StringArrayLike = 7,
    DateEqual = 8,
    DateBefore = 9,
    DateAfter = 10,
    NumberEqual = 11,
    NumberNotEqual = 12,
    NumberLessThan = 13,
    NumberLessOrEqualThan = 14,
    NumberGreaterThan = 15,
    NumberGreaterOrEqualThan = 16,
    NumberArrayContains = 17,
    EnumEqual = 18,
    EnumNotEqual = 19,
    UUIDEqual = 20,
    UUIDNotEqual = 21,
    UUIDArrayContains = 22,
    JSONBContains = 23,
}
const operationMap: Record<number, string> = {
    1: "equals",
    2: "not equals",
    3: "contains",
    4: "contains (string array)",
    5: "does not contain",
    6: "array contains",
    7: "array like",
    8: "date equals",
    9: "date before",
    10: "date after",
    11: "number equals",
    12: "number not equals",
    13: "less than",
    14: "less or equal",
    15: "greater than",
    16: "greater or equal",
    17: "number array contains",
    18: "enum equals",
    19: "enum not equals",
    20: "UUID equals",
    21: "UUID not equals",
    22: "UUID array contains",
    23: "JSON contains"
}
// Mapeo entre FilterOperations y FilterOperation
const filterOperationMap: { [key in FilterOperations]: FilterOperation } = {
    [FilterOperations.StringEqual]: FilterOperation.StringEqual,
    [FilterOperations.StringNotEqual]: FilterOperation.StringNotEqual,
    [FilterOperations.StringContains]: FilterOperation.StringContains,
    [FilterOperations.StringContainsStringArray]: FilterOperation.StringContains,
    [FilterOperations.StringNotContains]: FilterOperation.StringNotContains,
    [FilterOperations.StringArrayContains]: FilterOperation.StringArrayContains,
    [FilterOperations.StringArrayLike]: FilterOperation.StringArrayContains,
    [FilterOperations.DateEqual]: FilterOperation.DateEqual,
    [FilterOperations.DateBefore]: FilterOperation.DateBefore,
    [FilterOperations.DateAfter]: FilterOperation.DateAfter,
    [FilterOperations.NumberEqual]: FilterOperation.NumberEqual,
    [FilterOperations.NumberNotEqual]: FilterOperation.NumberNotEqual,
    [FilterOperations.NumberLessThan]: FilterOperation.NumberLessThan,
    [FilterOperations.NumberLessOrEqualThan]: FilterOperation.NumberLessOrEqualThan,
    [FilterOperations.NumberGreaterThan]: FilterOperation.NumberGreaterThan,
    [FilterOperations.NumberGreaterOrEqualThan]: FilterOperation.NumberGreaterOrEqualThan,
    [FilterOperations.NumberArrayContains]: FilterOperation.NumberArrayContains,
    [FilterOperations.EnumEqual]: FilterOperation.EnumEqual,
    [FilterOperations.EnumNotEqual]: FilterOperation.EnumNotEqual,
    [FilterOperations.UUIDEqual]: FilterOperation.UUIDEqual,
    [FilterOperations.UUIDNotEqual]: FilterOperation.UUIDNotEqual,
    [FilterOperations.UUIDArrayContains]: FilterOperation.UUIDArrayContains,
    [FilterOperations.JSONBContains]: FilterOperation.JSONBContains
}

export { FilterTypes, filterOperationMap, operationMap }
export type { Filter, Filters }
