import { getEnumKeys, ModelDefinition } from "@models/utils"
import SAST from "@models/SAST"
import { Button, Chip, Tooltip, Typography, useTheme } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import React from "react"
import { IoIosStar } from "react-icons/io"
import { TagCategoryType } from "@models/Tag"
import { MdLocationOn, MdPerson, MdWarning } from "react-icons/md"

// Mapeo de cada categoría a un ícono y color
const categoryIcons = {
    client: { icon: <MdPerson />, color: "#6C9EAB" }, // Azul claro
    location: { icon: <MdLocationOn />, color: "#D98B30" }, // Naranja
    status: { icon: <MdWarning />, color: "#9C4A97" } // Púrpura
}

// Función para renderizar la categoría con icono
const renderCategory: React.FC<{ value: TagCategoryType }> = ({ value }) => {
    const category = categoryIcons[value]
    const formattedValue = value.charAt(0).toUpperCase() + value.slice(1)
    return (
        <Tooltip title={value} placement="top">
            <Chip
                icon={category?.icon}
                label={formattedValue}
                sx={{
                    backgroundColor: category?.color,
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "0px 5px",
                    fontWeight: "bolder"
                }}
                size="small"
            />
        </Tooltip>
    )
}

// Lista de colores disponibles
const colors = [
    { label: "Yellow", value: "#F39C12" },
    { label: "Red", value: "#C0392B" },
    { label: "Green", value: "#27AE60" },
    { label: "Blue", value: "#2980B9" }
]

const TagCategoryTypeKeys = getEnumKeys(TagCategoryType)
const TagCategoryTypeKeysValueOps = TagCategoryTypeKeys.map((key) => ({ label: key, value: TagCategoryType[key] }))

interface ProductNameNavigateProps {
    value: string;
    id: string;
}

interface TagFavouriteProps {
    value: boolean;
}
interface tagClientNameProps {
    value: { id: string, name: string };
}
interface ColorProps {
    value: string; // Este valor es el valor hexadecimal del color
}

// Función renderColor ajustada
const renderColor: React.FC<ColorProps> = ({ value }) => {
    // Buscar el color en la lista de colores usando el valor hexadecimal
    const color = colors.find(c => c.value === value)

    return (
        // Si se encuentra el color, mostramos el nombre y el color de fondo
        color
            ? (
                <Chip
                    label={color.label} // Mostrar el nombre del color
                    size="small" style={{
                        fontWeight: "bolder",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        color: "#fff",
                        backgroundColor: color?.value
                    }}
                />
            )
            : (
                // Si no se encuentra el color, mostramos solo el valor hexadecimal
                <Chip
                    label={value}
                    size="small" style={{
                        fontWeight: "bolder",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        color: "#fff",
                        backgroundColor: value
                    }}
                />
            )
    )
}

const tagNameNavigate: React.FC<ProductNameNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const handleClick = () => {
        navigate(`./${id}`)
    }

    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                sx={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": {
                        color: theme.palette.text.secondary // Change this to the desired hover color
                    }
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}

const tagFavourite: React.FC<TagFavouriteProps> = ({ value }) => {
    const theme = useTheme()
    const color = theme.palette.primary.main

    return !value ? null : <IoIosStar color={color} />
}

const tagClientName: React.FC<tagClientNameProps> = ({ value }) => {
    return !value.name ? null : <span>{value.name}</span>
}

const TagsTableDefinition: ModelDefinition<SAST> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 1, render: tagNameNavigate },
        { name: "description", label: "Description", type: "string", flex: 2 },
        { name: "color", label: "Color", type: "string", flex: 2, render: renderColor }, // Aquí usamos la función renderColor
        { name: "category", label: "Category", type: "singleSelect", flex: 1, render: renderCategory, allowedValueOptions: TagCategoryTypeKeysValueOps },
        { name: "client", label: "Client", type: "string", flex: 0.5, render: tagClientName },
        { name: "favorite", label: "Favourite", type: "singleSelect", flex: 0.5, render: tagFavourite }
    ]
}

export { TagsTableDefinition, TagCategoryTypeKeys, TagCategoryTypeKeysValueOps }
export default TagsTableDefinition
