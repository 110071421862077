// ModificationForm.tsx
import React, { useState } from "react"
import { Box, Grid, MenuItem, Paper, TextField, Tooltip, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { ConditionTarget, ModificationActionsData } from "@models/Policy"
import ActionButton from "@components/common/Button/ActionButton"
import { fieldOptionsByTarget } from "@views/policy/constants/constAndInit"
import { CafHierarchyStatus } from "@models/Hierarchy"
import { CafVulnStatus } from "@models/Vulnerability"
import useMediaResponsive from "@hooks/useMobile"

// Helper arrays for dropdown options
const conditionTargetOptions = Object.entries(ConditionTarget).map(([label, value]) => ({ label, value }))

interface ModificationFormProps {
    data: ModificationActionsData[];
    onChange: (data: ModificationActionsData[]) => void;
}

const ModificationForm: React.FC<ModificationFormProps> = ({ data, onChange }) => {
    const [currentTarget, setCurrentTarget] = useState<ConditionTarget>(ConditionTarget.Hierarchy)
    const [currentField, setCurrentField] = useState<string>("")
    const [currentValue, setCurrentValue] = useState<string>("")
    const [error, setError] = useState<string>("")
    const isMobile = useMediaResponsive("(max-width: 768px)")
    // Opciones de estado para cada target
    const cafHierarchyStatusOptions = Object.entries(CafHierarchyStatus).map(([key, value]) => ({
        label: key.replace(/([A-Z])/g, " $1"), // Convierte los nombres a formato legible (ej. "WaitingForCafAssessment" -> "Waiting For Caf Assessment")
        value
    }))

    const cafVulnStatusOptions = Object.entries(CafVulnStatus).map(([key, value]) => ({
        label: key.replace(/([A-Z])/g, " $1"), // Convierte los nombres a formato legible (ej. "LowRisk" -> "Low Risk")
        value
    }))

    const handleAddModification = () => {
        // Validar entradas
        if (!currentField.trim()) {
            setError("Field cannot be empty")
            return
        }

        if (!currentValue.trim()) {
            setError("Value cannot be empty")
            return
        }

        // Crear nueva modificación
        const newModification: ModificationActionsData = {
            target: currentTarget,
            field: currentField,
            value: currentValue
        }

        // Agregar a la lista
        onChange([...data, newModification])

        // Resetear formulario
        setCurrentField("")
        setCurrentValue("")
        setError("")
    }

    const handleDeleteModification = (index: number) => {
        const updatedData = [...data]
        updatedData.splice(index, 1)
        onChange(updatedData)
    }

    return (
        <Grid container spacing={2} sx={{ mb: 3, mt: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h6" fontFamily="Griff" color="primary" fontWeight="bold" mb={1}>
                    Modifications
                </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    select
                    fullWidth
                    variant="filled"
                    label="Target"
                    value={currentTarget}
                    onChange={(e) => setCurrentTarget(e.target.value as ConditionTarget)}
                >
                    {conditionTargetOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Campo Field */}
            <Grid item xs={12} sm={3}>
                <TextField
                    select
                    fullWidth
                    variant="filled"
                    label="Field"
                    value={currentField}
                    onChange={(e) => setCurrentField(e.target.value)}
                    error={!currentField && !!error}
                    helperText={!currentField && error ? error : ""}
                >
                    {fieldOptionsByTarget[currentTarget]?.map((field) => (
                        <MenuItem key={field} value={field}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {field}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Campo Value - Selección dinámica de acuerdo al Field */}
            <Grid item xs={12} sm={3}>
                {currentField === "Status"
                    ? (
                        currentTarget === ConditionTarget.Hierarchy
                            ? (
                                <TextField
                                    select
                                    fullWidth
                                    variant="filled"
                                    label="Value"
                                    value={currentValue}
                                    onChange={(e) => setCurrentValue(e.target.value)}
                                    error={!currentValue && !!error}
                                    helperText={!currentValue && error ? error : ""}
                                >
                                    {cafHierarchyStatusOptions.map((opt) => (
                                        <MenuItem key={opt.value} value={opt.value}>
                                            <Typography fontFamily="Griff" fontWeight="bolder">
                                                {opt.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                            : currentTarget === ConditionTarget.Vulnerability
                                ? (
                                    <TextField
                                        select
                                        fullWidth
                                        variant="filled"
                                        label="Value"
                                        value={currentValue}
                                        onChange={(e) => setCurrentValue(e.target.value)}
                                        error={!currentValue && !!error}
                                        helperText={!currentValue && error ? error : ""}
                                    >
                                        {cafVulnStatusOptions.map((opt) => (
                                            <MenuItem key={opt.value} value={opt.value}>
                                                <Typography fontFamily="Griff" fontWeight="bolder">
                                                    {opt.label}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )
                                : null
                    )
                    : (
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Value"
                            value={currentValue}
                            onChange={(e) => setCurrentValue(e.target.value)}
                            error={!currentValue && !!error}
                            helperText={!currentValue && error ? error : ""}
                        />
                    )}
            </Grid>

            <Grid item xs={12} sm={2} sx={{ display: "flex", alignItems: "center" }}>
                <ActionButton
                    onClick={handleAddModification}
                    text="Add"
                    style={{ height: "56px" }}
                />
            </Grid>

            <Grid item xs={12}>
                {data.length > 0
                    ? data.map((item, index) => (
                        <Paper key={index} sx={{ p: 2, mb: 2 }}>
                            <Grid
                                container
                                spacing={2}
                                alignItems="left"
                                direction={isMobile ? "column" : "row"}
                            >
                                <Grid item xs={12} sm={3}>
                                    <Typography fontWeight="bold" sx={{ textAlign: "left", width: "100%" }}>
                                        Target:
                                    </Typography>
                                    <Typography sx={{ textAlign: "left", width: "100%" }}>
                                        {Object.entries(ConditionTarget).find(([_, v]) => v === item.target)?.[0] || item.target}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Typography fontWeight="bold" sx={{ textAlign: "left", width: "100%" }}>
                                        Field:
                                    </Typography>
                                    <Typography sx={{ textAlign: "left", width: "100%" }}>
                                        {item.field}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <Typography fontWeight="bold" sx={{ textAlign: "left", width: "100%" }}>
                                        Value:
                                    </Typography>
                                    <Tooltip title={item.value} arrow>
                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: isMobile ? "normal" : "nowrap",
                                                maxHeight: isMobile ? "60px" : "none",
                                                maxWidth: isMobile ? "160px" : "90%"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12} sm={1} sx={{
                                    display: "flex",
                                    justifyContent: isMobile ? "flex-start" : "flex-end",
                                    alignItems: "center",
                                    paddingTop: isMobile ? 0 : undefined
                                }}>
                                    <DeleteIcon
                                        onClick={() => handleDeleteModification(index)}
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    ))
                    : (
                        <Box
                            sx={{
                                p: 2,
                                minHeight: "100px",
                                border: "1px dashed #ccc",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Typography color="text.secondary" fontStyle="italic">
                                No modifications added yet
                            </Typography>
                        </Box>
                    )}
            </Grid>
        </Grid>
    )
}

export default ModificationForm
