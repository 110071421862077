// ActionForm.tsx
import React from "react"
import { ActionsType, ModificationActionsData, NotificationActionsData } from "@models/Policy"
import NotificationForm from "./NotificationForm"
import ModificationForm from "./ModificationForm"

interface ActionFormProps {
  actionType: ActionsType;
  notificationData: NotificationActionsData;
  modificationData: ModificationActionsData[];
  onNotificationChange: (data: NotificationActionsData) => void;
  onModificationChange: (data: ModificationActionsData[]) => void;
}

const ActionForm: React.FC<ActionFormProps> = ({
    actionType,
    notificationData,
    modificationData,
    onNotificationChange,
    onModificationChange
}) => {
    // Render the appropriate form based on action type
    if (actionType === ActionsType.Notification) {
        return (
            <NotificationForm
                data={notificationData}
                onChange={onNotificationChange}
            />
        )
    } else if (actionType === ActionsType.Modification) {
        return (
            <ModificationForm
                data={modificationData}
                onChange={onModificationChange}
            />
        )
    }

    // Default case: no additional form
    return null
}

export default ActionForm
