import React, { useContext, useState } from "react"
import { Grid, Tab, Tabs } from "@mui/material"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import { LiaBoxOpenSolid } from "react-icons/lia"
import { I18nContext } from "I18nProvider"
import ProviderList from "./Provider/ProvidersList"
import ProductListCaf from "./ProductListCaf"

const TabNavigation: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [tabIndex, setTabIndex] = useState(0)

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex)
    }

    return (
        <Grid container direction="column">
            {/* Tabs Header */}
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                sx={{ alignItems: "flex-start", marginBottom: 2 }}
            >
                <Tab
                    label={
                        <Grid display="flex" alignItems="center" gap={1}>
                            <TbDeviceHeartMonitor size="25px" />
                            {context.t.translate("products")}
                        </Grid>
                    }
                />
                <Tab
                    label={
                        <Grid display="flex" alignItems="center" gap={1}>
                            <LiaBoxOpenSolid size="25px" />
                            {context.t.translate("providers")}
                        </Grid>
                    }
                />
            </Tabs>

            {/* Tab Content */}
            <Grid item>
                {tabIndex === 0 && <ProductListCaf />} {/* TODO: Change to ProductList if not CAF */}
                {tabIndex === 1 && <ProviderList />}
            </Grid>
        </Grid>
    )
}

export default TabNavigation
