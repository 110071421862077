import React, { useContext, useEffect, useState } from "react"
import { Avatar, Box, Divider, Grid, Typography, useTheme } from "@mui/material"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { FaRankingStar, FaRegShareFromSquare } from "react-icons/fa6"
import { FaPencilRuler } from "react-icons/fa"
import QualityIndicator, { ValorChip } from "@components/common/indicator/QualityIndicator"
import GradiantGauge from "@components/charts/gauge/GradientGauge"
import ServicesContext from "@context/ServicesContext"
import { useParams } from "react-router-dom"
import BomQuality from "@models/BomQuality"
import { pdf } from "@react-pdf/renderer"
import SBOMReportDocument from "@components/report/VulnReportDocumentQuality"
import { MdBorderInner, MdCheckCircle } from "react-icons/md"
import { LuListTree } from "react-icons/lu"
import WarningIcon from "@mui/icons-material/Warning"
import ErrorIcon from "@mui/icons-material/Error"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"

const newcategory = [
    {
        name: "Structural",
        icon: MdBorderInner
    },
    {
        name: "NTIA-minimum-elements",
        icon: LuListTree
    },
    {
        name: "Semantic",
        icon: FaPencilRuler

    },
    {
        name: "Quality",
        icon: FaRankingStar

    },
    {
        name: "Sharing",
        icon: FaRegShareFromSquare

    }
]

const groupByCategory = (scores: any) => {
    return scores.reduce((acc: any, item: any) => {
        const { category } = item
        if (!acc[category]) {
            acc[category] = []
        }
        acc[category].push(item)
        return acc
    }, {})
}

const SBOMQuality: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [error, setError] = useState<Error | null>(null)
    const fileService = useContext(ServicesContext).fileService
    const { id } = useParams<{ id: string }>()
    const [qualityMetrics, setQualityMetrics] = useState<BomQuality>({ avg_score: 0, scores: [], timestamp: "" })
    const [dataArray, setDataArray] = useState<[]>([])

    const scoresByCategory = qualityMetrics.scores.reduce((acc: { [key: string]: any[] }, score) => {
        if (!acc[score.category]) {
            acc[score.category] = []
        }
        acc[score.category].push(score)
        return acc
    }, {})
    const formatKey = (key: any) => {
        const formattedKey = key.replace(/-/g, " ")
        return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)
    }
    const averageScoresByCategory: { [key: string]: number } = {}
    React.useEffect(() => {
        for (const category in scoresByCategory) {
            const scores = scoresByCategory[category]
            const totalScores = scores.length
            const sumScores = scores.reduce((total: number, score: any) => total + score.score, 0)
            const averageScore = sumScores / totalScores
            averageScoresByCategory[category] = averageScore
        }
    }, [qualityMetrics])
    useEffect(() => {
        const fecthData = async () => {
            try {
                const response = await fileService.scoreBom(id as string, new File([""], "filename"))
                const prueba = await response.json()
                setQualityMetrics(prueba)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fecthData()
    }, [])

    useEffect(() => {
        const prueba = groupByCategory(qualityMetrics.scores)
        setDataArray(prueba)
    }, [qualityMetrics])
    const handleSBOM = async () => {
        try {
            // Generate the PDF Blob
            const blob = await pdf(<SBOMReportDocument qualityMetrics={qualityMetrics} />).toBlob()

            // Create a URL for the Blob and open it in a new tab
            const url = URL.createObjectURL(blob)
            window.open(url, "_blank") // Opens the PDF in a new tab

            // Optional: Revoke the Blob URL after a certain delay to free up memory
            setTimeout(() => URL.revokeObjectURL(url), 1000)
        } catch (error) {
            console.error("Error generating PDF:", error)
        }
    }

    const getScore = (valor: number) => {
        if (valor >= 8) {
            return <MdCheckCircle style={{ color: "#60ba39" }} />
        } else if (valor >= 5) {
            return <WarningIcon style={{ color: "#c58a17", fontSize: "36px" }} />
        }
        return <ErrorIcon style={{ color: "#c3331b", fontSize: "36px" }} />
    }

    return (
        <Box sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, padding: "20px" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("SBOM_quality")}
                </Typography>
                <Grid item sx={{ display: "flex", gap: 2 }}>

                    <ActionButton
                        text={context.t.translate("download_sbom")}
                        icon={<CloudDownloadIcon />}
                        onClick={handleSBOM}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box sx={{
                        padding: "10px",
                        backgroundColor: theme.palette.background.default,
                        borderRadius: "10px",
                        border: "1px solid #6D6C6F",
                        marginBottom: "20px"
                    }}>
                        <Typography fontSize="28px" fontFamily="Griff" fontWeight="bolder" color={theme.palette.primary.main}>{context.t.translate("score_summary")}</Typography>
                        <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F", mb: "25px" }} />

                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginLeft: "-5px", marginRight: "-25px" }}>
                            <GradiantGauge value={parseFloat(qualityMetrics.avg_score.toFixed(1))} backgroundColor={theme.palette.background.default} color={theme.palette.primary.main} />
                        </Grid>
                        <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F", mb: "25px" }} />
                        {Object.entries(scoresByCategory).map(([category, scores]) => (
                            <React.Fragment key={category}>
                                {newcategory.map((catIcon, index) => {
                                    if (catIcon.name === category) {
                                        return (
                                            <React.Fragment key={`${category}_${index}`}>
                                                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                    <Grid container xs={12} flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
                                                        <Grid container item xs={6} alignItems="center" flexDirection="row" display="flex">
                                                            <Grid item xs={3} sx={{ marginRight: "15px" }}>
                                                                <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                                                                    <catIcon.icon style={{ color: theme.palette.primary.contrastText }} />
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography fontSize="16px" fontFamily="Griff" fontWeight="bolder">
                                                                    {formatKey(category)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <ValorChip valor={parseFloat(
                                                                (qualityMetrics.scores.filter(score => score.category === category).reduce((total, score) => total + score.score, 0) /
                                                                    qualityMetrics.scores.filter(score => score.category === category).length).toFixed(2)
                                                            )} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F", mb: "25px" }} />
                                            </React.Fragment>
                                        )
                                    }
                                    return null
                                })}
                            </React.Fragment>
                        ))}

                    </Box>

                </Grid>
                <Grid item xs={9} >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {Object.entries(scoresByCategory).map(([category, scores]) => {
                                if (category === "Structural" || category === "NTIA-minimum-elements") {
                                    const catIcon = newcategory.find(item => item.name === category)

                                    return (
                                        <Box key={"category_" + category} sx={{
                                            padding: "10px",
                                            backgroundColor: theme.palette.background.default,
                                            borderRadius: "10px",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#6D6C6F",
                                            marginBottom: "20px"
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={10} >
                                                    <Grid container xs={12} justifyContent="space-between" flexDirection="row">
                                                        <Grid item xs={12} container alignItems="center">
                                                            <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                                                                {catIcon && catIcon.icon && (
                                                                    <catIcon.icon style={{ color: theme.palette.primary.main }} size="25px" />
                                                                )}
                                                            </Avatar>
                                                            <Typography fontSize="28px" fontFamily="Griff" fontWeight="bolder" color={theme.palette.primary.main}>
                                                                {formatKey(category)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Box sx={{ display: "flex", marginRight: "10px", flexDirection: "row", height: "100%", justifyContent: "center", alignItems: "center", fontSize: "36px", fontWeight: 300 }} gap={2}>
                                                        {getScore(parseFloat(
                                                            (
                                                                qualityMetrics.scores
                                                                    .filter((score) => score.category === category)
                                                                    .reduce((total, score) => total + score.score, 0) /
                                                                qualityMetrics.scores.filter((score) => score.category === category).length
                                                            ).toFixed(2)
                                                        ))}
                                                        {parseFloat(
                                                            (
                                                                qualityMetrics.scores
                                                                    .filter((score) => score.category === category)
                                                                    .reduce((total, score) => total + score.score, 0) /
                                                                qualityMetrics.scores.filter((score) => score.category === category).length
                                                            ).toFixed(1)
                                                        )}
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main, mb: "25px" }} />

                                            {scores.map((score, idx) => (
                                                <Grid key={"score_" + idx} container sx={{ marginBottom: "10px" }}>
                                                    <Grid item xs={1} sx={{ height: "100%" }}>
                                                        <Typography sx={{ fontSize: "30px" }} fontSize="34px" fontFamily="Griff" color="#6D6C6F">
                                                            {idx >= 0 && idx < 9 ? `0${idx + 1}.` : idx + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                        <Typography sx={{ marginBottom: "5px" }} fontSize="16px" fontFamily="Griff" color={theme.palette.text.primary}>
                                                            {formatKey(score.description)}
                                                        </Typography>
                                                        <QualityIndicator value={score.score} />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                                            <ValorChip valor={score.score} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    )
                                }
                                return null
                            })}
                        </Grid>
                        <Grid item xs={6}>
                            {Object.entries(scoresByCategory).map(([category, scores]) => {
                                if (category !== "Structural" && category !== "NTIA-minimum-elements") {
                                    const catIcon = newcategory.find(item => item.name === category)
                                    return (
                                        <Box key={"category_" + category} sx={{
                                            padding: "10px",
                                            backgroundColor: theme.palette.background.default,
                                            borderRadius: "10px",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#6D6C6F", // borde fino blanco
                                            marginBottom: "20px"
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} >
                                                    <Grid container xs={12} justifyContent="space-between" flexDirection="row">
                                                        <Grid item xs={12} container alignItems="center">
                                                            <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                                                                {catIcon && catIcon.icon && (
                                                                    <catIcon.icon style={{ color: theme.palette.primary.main }} />
                                                                )}
                                                            </Avatar>
                                                            <Typography fontSize="28px" fontFamily="Griff" fontWeight="bolder" color={theme.palette.primary.main}>
                                                                {formatKey(category)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                    <Box sx={{ display: "flex", marginRight: "10px", flexDirection: "row", height: "100%", justifyContent: "center", alignItems: "center", fontSize: "36px", fontWeight: 300 }} gap={2}>
                                                        {getScore(parseFloat(
                                                            (
                                                                qualityMetrics.scores
                                                                    .filter((score) => score.category === category)
                                                                    .reduce((total, score) => total + score.score, 0) /
                                                                qualityMetrics.scores.filter((score) => score.category === category).length
                                                            ).toFixed(2)
                                                        ))}
                                                        {parseFloat(
                                                            (
                                                                qualityMetrics.scores
                                                                    .filter((score) => score.category === category)
                                                                    .reduce((total, score) => total + score.score, 0) /
                                                                qualityMetrics.scores.filter((score) => score.category === category).length
                                                            ).toFixed(1)
                                                        )}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main, mb: "25px" }} />
                                            {scores.map((score, idx) => (
                                                <Grid key={"score_" + idx} container sx={{ marginBottom: "10px" }}>
                                                    <Grid item xs={1} sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                                                        <Typography sx={{ fontSize: "30px", textAlign: "center" }} fontSize="34px" fontFamily="Griff" color="#6D6C6F">
                                                            {idx >= 0 && idx < 9 ? `0${idx + 1}.` : idx + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                        <Typography sx={{ marginBottom: "5px" }} fontSize="16px" fontFamily="Griff" color={theme.palette.text.primary}>
                                                            {score.description}
                                                        </Typography>
                                                        <QualityIndicator value={score.score} />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                                            <ValorChip valor={score.score} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    )
                                }
                                return null
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SBOMQuality
