import React from "react"
import { Box, Tooltip, Typography } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

interface CustomTooltipProps {
  title: string | React.ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title }) => {
    return (
        <Tooltip
            title={
                <Box sx={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid yellow",
                    padding: "8px",
                    borderRadius: "4px"
                }}>
                    {typeof title === "string"
                        ? (
                            <Typography fontFamily="Griff" fontSize="14px">{title}</Typography>
                        )
                        : (
                            title
                        )}
                </Box>
            }
            placement="top"
            arrow
        >
            <HelpOutlineIcon
                sx={{
                    fontSize: "20px",
                    color: "gray",
                    marginLeft: "8px",
                    cursor: "pointer"
                }}
            />
        </Tooltip>
    )
}

export default CustomTooltip
