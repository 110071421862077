import { Product } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"
import { QueryParameters } from "@utils/queryParams"

class ProductService extends Service<Product> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/product", MediaTypes.JSON)
    }

    public getEvolution = (id: string) => {
        return this.http.get(`/${this.path}/${id}/evolution`) as any
    }

    public getProductByCPE = (cpe: string) => {
        return this.http.get(`/${this.path}/component/` + cpe) as any
    }

    public getAllOwned = (queryParameters?: QueryParameters) => {
        return this.http.getMultiple(`/${this.path}/owned`, queryParameters)
    }

    public getAllCaf = (queryParameters?: QueryParameters) => {
        return this.http.getMultiple(`/${this.path}/caf`, queryParameters)
    }
}

export { ProductService }
export default ProductService
