import React, { useContext } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import { FilterOperation } from "@utils/index"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions"

interface ClientSelectorProps{
    value: string
    disabled?: boolean
    onSelect: (id: string|undefined)=>void
}
const ClientSelector: React.FC<ClientSelectorProps> = ({ value, disabled, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const clientService = useContext(ServicesContext).clientService

    return (
        <SearchInput<Client>
            margin="normal" required label={context.t.translate("client_name")}
            variant="outlined"
            value={value}
            disabled={disabled}
            requesterById={async v => {
                if (v !== undefined && v !== "" && v !== "00000000-0000-0000-0000-000000000000") {
                    const client = await clientService.get(v)
                    return [client]
                }
                return [] // Return empty array instead of empty client object
            }}
            requester={async v => {
                const client = ability.can("*", "Client") && v !== "" ? await clientService.getAll({ filters: [{ field: "name", operation: FilterOperation.StringContains, value: v }] }) : await clientService.getAll()
                return client.list
            }} getterID={elem => elem?.id} getterTitle={elem => `${elem.name}`} onClickElem={(v) => onSelect(v?.id)}></SearchInput>
    )
}

export { ClientSelector }
export default ClientSelector
