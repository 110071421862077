import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import IssueTableDefinition, { IssueTableDefinitionES } from "@components/issue/IssueTableDefinition"
import { AbilityContext } from "@components/permissions"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import Issue from "@models/Issue"
import { IterableList } from "@models/iterable"
import { Grid, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const IssueList = (row: any) => {
    const [cols, setCols] = useState<GridColDef[]>([])
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    const issueService = useContext(ServicesContext).issueService
    const navigate = useNavigate()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "IssueList" })
    }, [])
    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const tableDefinition = context.language === "es" ? IssueTableDefinition : IssueTableDefinitionES
        setCols(convertModelDefToGridColDef(tableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>

                    <GenericTable<Issue> entity="Issue" columns={cols}
                        dataProvider={(filter) => {
                            if (row && typeof row === "object" && "row" in row && row !== "" && (row.row.productName !== undefined || row.row.version !== undefined)) {
                                if (filter.filters === undefined) {
                                    filter.filters = [{ field: "product_name", operation: FilterOperation.StringEqual, value: row.row.productName as string }]
                                    filter.filters.push({ field: "product_version", operation: FilterOperation.StringEqual, value: row.row.version as string })
                                } else {
                                    filter.filters.push({ field: "product_name", operation: FilterOperation.StringEqual, value: row.row.productName as string })
                                    filter.filters.push({ field: "product_version", operation: FilterOperation.StringEqual, value: row.row.version as string })
                                }
                                // Si `row` es un objeto con `row`, usa el identificador para la llamada al servicio

                                return issueService.getAll(filter)
                            } else if (row && typeof row === "object" && "row" in row && row !== "" && row.row !== undefined && row.row !== "") {
                                if (filter.filters === undefined) {
                                    filter.filters = [{ field: "cve", operation: FilterOperation.StringEqual, value: row.row as string }]
                                } else {
                                    filter.filters.push({ field: "cve", operation: FilterOperation.StringEqual, value: row.row as string })
                                }
                                // Si `row` es un objeto con `row`, usa el identificador para la llamada al servicio

                                return issueService.getAll(filter)
                            }
                            // Si `row` no es un identificador o no tiene `row`, maneja el caso aquí
                            const emptyIssueList: IterableList<Issue> = {
                                json: () => [],
                                next: "",
                                list: [],
                                count: 0
                            }
                            // Retornamos una promesa resuelta con el arreglo vacío para satisfacer el tipo esperado
                            return Promise.resolve(emptyIssueList)// Retorna una promesa resuelta con un array vacío o maneja el caso apropiadamente
                        }}
                        onEdit={(elem: Issue) => navigate("./issue/" + elem.id)}
                        onDelete={undefined}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export { IssueList }
export default IssueList
