import { CustomAction, GenericTable } from "@components/common/tables"
import ServicesContext from "@context/ServicesContext"
import Assessment from "@models/Assessment"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

const AssessmentComponent = ({ id, cols, customActions }: {id: string | undefined, cols: GridColDef[], customActions: (params: GridRowParams<any>) => CustomAction[]}) => {
    const assessmentService = useContext(ServicesContext).assessmentService
    const navigate = useNavigate()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" color={theme.palette.primary.main} sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("dash_assess")}</Typography>

                    <GenericTable<Assessment> entity="Assessment" columns={cols}
                        dataProvider={(filter) => {
                            // Define your filter criteria here
                            const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "product_id", operation: FilterOperation.UUIDEqual, value: id as string }] }
                            return assessmentService.getAllExt(updatedFilter)
                        }}
                        onEdit={(elem: Assessment) => navigate("../../assessment/" + elem.id)}
                        onDelete={(elem: Assessment) => assessmentService.delete(elem.id)}
                        customActions={customActions}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AssessmentComponent
