
import { ModelDefinition } from "@models/utils"
import SAST from "@models/SAST"
import { Button, Typography, useTheme } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import React from "react"

interface ProductNameNavigateProps {
    value: string;
    id: string;
}

const sastNameNavigate: React.FC<ProductNameNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const handleClick = () => {
        navigate(`./${id}`)
    }

    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                sx={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": {
                        color: theme.palette.text.secondary
                    }
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}

const SASTTableDefinition: ModelDefinition<SAST> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "file", label: "File", type: "string", flex: 2, render: sastNameNavigate },
        { name: "line", label: "Line", type: "string", flex: 0.3 },
        { name: "column", label: "Column", type: "string", flex: 0.3 },
        { name: "level", label: "Level", type: "number", flex: 0.3 },
        { name: "category", label: "Category", type: "string", flex: 0.8 },
        { name: "name", label: "Name", type: "string", flex: 0.8 },
        { name: "suggestion", label: "Warning", type: "string", flex: 2 }
    ]
}

export { SASTTableDefinition }
export default SASTTableDefinition
