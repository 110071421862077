import React, { useContext } from "react"
import { Font, Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import moment from "moment"
import styles from "../Styles"

Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const i18n = {
    es: {
        bannerText: "Llevando la ciberseguridad al siguiente nivel",
        title: "Test de Penetración",
        monthAndYear: "Mes y año:",
        cybersecurity: "Ciberseguridad",
        intellectualProperty: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
        confidentiality: "Este informe contiene información confidencial dirigida exclusivamente a su destinatario.",
        tlpAmber: "TLP-AMBER",
        projectOwner: "Jefe del proyecto:",
        assessmentLeader: "Responsable de Informe:",
        ref: "Ref. y nº del informe",
        rev: "Rev.:"
    },
    en: {
        bannerText: "Taking cybersecurity to the next level",
        title: "Penetration Test",
        monthAndYear: "Month and year:",
        cybersecurity: "Cybersecurity",
        intellectualProperty: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
        confidentiality: "This report contains confidential information exclusively addressed to its recipient.",
        tlpAmber: "TLP-AMBER",
        projectOwner: "Project Owner:",
        assessmentLeader: "Report Responsible:",
        ref: "Ref. and report number",
        rev: "Rev.:"
    }
} as const

interface SectionProps {
    externalData: any
    lang?: string;
    assessment?: Assessment
    template?: Template
}

const FrontPage: React.FC<SectionProps> = ({ externalData, lang, assessment, template }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    // --------------------------------------------------

    // Functions
    const formatDate = (date: string | undefined, locale: keyof typeof i18n): string => {
        const momentDate = date ? moment(date) : moment()
        const format = locale === "es" ? "MMMM YYYY" : "YYYY MMMM"
        return momentDate.locale(locale).format(format)
    }
    // --------------------------------------------------

    // Render variables
    const backgroundColor = template?.color ?? "#FAE733"
    let isCustomLogo = false
    let imagen = "/assets/img/report/portada_template.png"
    if (template !== undefined) {
        if (template.logo && template.logo !== "") {
            const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
            const blob = new Blob([byteArray], { type: "image/png" })
            imagen = URL.createObjectURL(blob)
            isCustomLogo = true
        }
    }
    const imgStyle: any = isCustomLogo ? { position: "absolute", top: -50, left: 20, width: 250, height: 250, objectFit: "contain" } : styles.template_image // TODO: Fix this :any
    // TODO: Handle cover page header and subheader
    const tableStyle: any = isCustomLogo ? { position: "absolute", top: 700, marginLeft: 37, marginRight: 36 } : styles.tablea // TODO: Fix this :any
    const footerStyle: any = { position: "fixed", bottom: 0, padding: "10px 30px", marginTop: 90 } // TODO: Fix this :any
    const proyectOwner = externalData?.["Front Page"]?.proyectOwner ?? "Nombre del jefe de proyecto"
    const assessmentLeader = externalData?.["Front Page"]?.assessmentLeader ?? "Nombre del responsable del informe"
    const ref = externalData?.["Front Page"]?.ref ?? "0000.0000.E0"
    const rev = externalData?.["Front Page"]?.rev ?? "1.0"
    // --------------------------------------------------

    return (
        <Page size="A4" style={{ backgroundColor }}>
            <View fixed >
                <Image style={imgStyle} src={imagen} />
                {isCustomLogo && (
                    <View style={{ position: "absolute", left: 20, top: 300, fontFamily: "Bold", fontSize: 40, fontWeight: "bold" }}>
                        <Text>
                            {template?.metadata.data?.cover_page?.header?.trim()
                                ? template.metadata.data.cover_page.header
                                : ""
                            }
                        </Text>
                    </View>
                )}
                <View style={tableStyle}>
                    <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                        <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                            <Text style={{ fontFamily: "Bold", fontSize: "18px" }}>
                                {i18n[locale].title}
                            </Text>
                            <Text style={{ fontFamily: "Griff", fontSize: "10px" }}>
                                {assessment?.product.name ?? "Nombre del producto"}
                            </Text>
                            <Text style={{ fontFamily: "Bold", fontSize: "11px", backgroundColor: "black", color: "yellow", alignSelf: "flex-start" }}>
                                {` ${i18n[locale].tlpAmber} `}
                            </Text>
                        </View>
                        <View style={{ ...styles.tableCola2 }}>
                            <View style={{ ...styles.tableRowa, borderWidth: 0, width: "100%" }}>
                                <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff" }}>
                                        {i18n[locale].projectOwner}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff" }}>
                                        {i18n[locale].assessmentLeader}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff" }}>
                                        {i18n[locale].ref}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff" }}>
                                        {i18n[locale].rev}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff" }}>
                                        {i18n[locale].monthAndYear}
                                    </Text>
                                </View>
                                <View style={{ ...styles.column, textAlign: "right" }}>
                                    <Text style={{ ...styles.titulo2page1, padding: 0, fontFamily: "Griff" }}>
                                        {proyectOwner}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, padding: 0, fontFamily: "Griff" }}>
                                        {assessmentLeader}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, padding: 0, fontFamily: "Griff" }}>
                                        {ref}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, padding: 0, fontFamily: "Griff" }}>
                                        {rev}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, padding: 0, fontFamily: "Griff" }}>
                                        {formatDate(assessment?.endDate ?? undefined, locale)}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                </View>

            </View>
            <View fixed style={footerStyle} >
                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 2 }} >
                    <Text style={{ color: "black", fontFamily: "Griff", fontSize: 6, textAlign: "justify", flex: 1, paddingRight: 30 }}>
                        {i18n[locale].intellectualProperty}
                        <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>
                            {i18n[locale].tlpAmber}
                        </Text>
                        {i18n[locale].confidentiality}
                    </Text>
                    <Text style={{ fontSize: 50, fontFamily: "Bold", fontWeight: "bold", textAlign: "right", flexShrink: 0 }}>
                                Orbik
                    </Text>
                </View>
            </View>
        </Page>
    )
}

export { FrontPage }
