import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Switch, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { ServicesContext } from "@context/index"
import { NewUser } from "@models/User"

const initValue: NewUser = {
    firstLogin: "",
    id: "",
    client_id: "",
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    pwd: "",
    admin: false,
    groups: [],
    last_login: "",
    provider: false
}

const UserForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const userService = useContext(ServicesContext).userService
    const [error, setError] = useState<Error | null>(null)
    const [phoneError, setPhoneError] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [formData, setFormData] = useState<NewUser>(initValue)
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    // Functions
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked
        }))
    }

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            client_id: id as string
        }))
    }, [id])

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.username === "",
            formData.pwd === "",
            formData.email === "",
            formData.firstname === "",
            formData.lastname === "",
            formData.phone === ""
        ]

        if (isNotNullrules.some(rule => rule)) {
            return false
        }

        return true
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await userService.create(formData)
            setError(null)
            navigate(-1) // Navigate back after submit
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value

        // Validación internacional: +34698741322, 34698741322, etc.
        const isValid = /^\+?[1-9]\d{1,14}$/.test(phoneNumber)

        setPhoneError(!isValid)
        setFormData(prevFormData => ({
            ...prevFormData,
            phone: phoneNumber
        }))
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("name")}
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("lastname")}
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("username")}
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("username")}
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("password")}
                            name="pwd"
                            value={formData.pwd}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("email")}
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={phoneError}>
                            <TextField
                                label={context.t.translate("phone")}
                                variant="outlined"
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                inputProps={{ pattern: "^\\+?[1-9]\\d{1,14}$" }}
                            />
                            {phoneError && <FormHelperText>{context.t.translate("phone_error")}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={1}><FormGroup>
                            <FormControlLabel label={context.t.translate("admin")} control={<Switch name="admin" checked={formData.admin} onChange={handleSwitchChange} />} />
                        </FormGroup></Grid>
                        <Grid item xs={1}><FormGroup>
                            <FormControlLabel label={context.t.translate("provider")} control={<Switch name="provider" checked={formData.provider} onChange={handleSwitchChange} />} />
                        </FormGroup></Grid>
                    </Grid>
                </Grid>

                <ActionButton type="submit" disabled={!formValid()} onClick={() => {}} text={context.t.translate("add_new_user")} style={{ width: "100%" }} />
            </Box>
        </Box>
    )
}

export { UserForm }
export default UserForm
