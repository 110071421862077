// StyledTabs.tsx
import React, { JSXElementConstructor, ReactElement } from "react"
import { Tab, Tabs } from "@mui/material"
import { styled } from "@mui/material/styles"

// StyledTabs Component
interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        variant="fullWidth"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{
            width: "100%",
            minHeight: 32
        }}
    />
))(({ theme }) => ({
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    },
    "& .MuiTabs-indicatorSpan": {
        width: "100%",
        backgroundColor: theme.palette.primary.main
    }
}))

// StyledTab Component
interface StyledTabProps {
  label: string;
  icon?: ReactElement<any, JSXElementConstructor<any>>;
}

const StyledTab = styled(({ icon, ...props }: StyledTabProps) => (
    <Tab disableRipple {...props} icon={icon} iconPosition="start" />
))(({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    color: "rgba(255, 255, 255, 0.7)",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "4px",
    minWidth: "auto",
    minHeight: 32,
    "&.Mui-selected": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    },
    "&.Mui-focusVisible": {
        backgroundColor: theme.palette.primary.light
    }
}))

// Export components
export { StyledTabs, StyledTab }
