import Service from "@services/abstract/BaseService"
import { MediaTypes } from "./HttpService"

class MitigateService extends Service<{product_ids: string[]}> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/product", MediaTypes.JSON)
    }

    public mitigateProducts = (product_ids: string[], cve: string) => {
        return this.http.put(`/${this.path}/mitigated/` + cve, { product_ids }) as any
    }
}

export { MitigateService }
export default MitigateService
