/* global RequestInit, HeadersInit, Body */

import { AuthContextProps } from "react-oidc-context"
import { IterableList } from "@models/iterable"
import { QueryParameters } from "@utils/index"

enum MediaTypes {
    JSON = "application/json",
    FILE = "multipart/form-data",
    XML = "application/xml",
    HTML = "text/html",
    ABC = "multipart/form-data",
    SARIF = "application/sarif+json"
}

interface ParserSerializer {
    serialize: (data: any)=>any
    parse: (response: Body)=>any
}

const ParserSerializers: {[id in MediaTypes]: ParserSerializer} = {
    [MediaTypes.JSON]: {
        serialize: (data) => JSON.stringify(data),
        parse: (response: Body) => response.json()
    },
    [MediaTypes.FILE]: {
        serialize: (data) => data,
        parse: (response: Body) => response.blob()
    },
    [MediaTypes.XML]: {
        serialize: (data) => data,
        parse: (response: Body) => response.text()
    },
    [MediaTypes.HTML]: {
        serialize: (data) => data,
        parse: (response: Body) => response.text()
    },
    [MediaTypes.SARIF]: {
        serialize: (data) => data,
        parse: (response: Body) => response.text()
    }
}

const QueryParametersToURL = (params: QueryParameters): string[] => {
    const query: string[] = []
    if (params.sortField != null) {
        query.push(`sort_by=${params.sortField}`)
        query.push(`sort_mode=${params.sortMode}`)
    }

    if (params.page_number != null && params.page_number > 0) {
        query.push(`page_number=${params.page_number}`)
    }
    if (params.page_size != null && params.page_size > 0) {
        query.push(`page_size=${params.page_size}`)
    }
    if (params.filters != null) {
        params.filters.forEach(filter => {
            query.push(`filter=${filter.field}(${filter.operation})${filter.value}`)
        })
    }
    if (params.orFilters != null) {
        params.orFilters.forEach(filter => {
            query.push(`or_filter=${filter.field}(${filter.operation})${filter.value}`)
        })
    }

    return query
}

class HttpService<E> {
    private baseUrl: string|undefined
    private auth: AuthContextProps|null
    private type: MediaTypes
    private parserSerializer: ParserSerializer

    public constructor (baseUrl: string|undefined, type: MediaTypes) {
        this.baseUrl = baseUrl
        this.type = type
        this.parserSerializer = ParserSerializers[this.type]
        this.auth = null
    }

    public setAuth (auth: AuthContextProps) {
        this.auth = auth
    }

    private async fetch (url: string, method:"GET"|"POST"|"PUT"|"DELETE", data: E | E[] | null = null) {
        const resp = await this.nativeFetch(url, method, data)
        if (resp.ok) {
            return resp
        } else if (resp.status === 401) {
            await this.auth?.signinRedirect()
        } else {
            throw await resp.json()
        }

        return resp
    }

    private nativeFetch (url: string, method:"GET"|"POST"|"PUT"|"DELETE", data:E | E[] | null = null) {
        if (this.auth?.user != null && this.auth?.isAuthenticated) {
            const token = this.auth.user.access_token

            const opts: RequestInit = { method }
            const headers: HeadersInit = { Authorization: `Bearer ${token}` }
            if (method === "POST" || method === "PUT") {
                if (this.type !== MediaTypes.FILE) {
                    headers["Content-Type"] = this.type
                } else {
                    (opts as any).responseType = "arraybuffer"
                }
                opts.body = this.parserSerializer.serialize(data)
            }
            opts.headers = headers

            // TODO: Handle 401 to refresh token and refetch
            return fetch(this.baseUrl + url, opts)
        }
        throw new Error("user not authenticated: " + this.auth?.isAuthenticated)
    }

    public get (url: string) {
        return this.fetch(url, "GET").then(response => this.parserSerializer.parse(response)) as Promise<E>
    }

    public getMultiple (url: string, queryParams?: QueryParameters | null, extraParams: string[] = []) {
        const queryString = new URLSearchParams([
            ...(queryParams ? QueryParametersToURL(queryParams) : []),
            ...extraParams
        ].join("&"))
        return this.fetch(url + (queryString.toString() ? "?" + queryString : ""), "GET")
            .then(response => response.json()) as Promise<IterableList<E>>
    }

    public post (url: string, data: E) {
        return this.fetch(url, "POST", data)
    }

    public postFile (url: string, data: E, mediaType: MediaTypes) {
        this.type = mediaType
        return this.fetch(url, "POST", data)
    }

    public put (url: string, data: E) {
        return this.fetch(url, "PUT", data)
    }

    public postMultiple (url: string, data: E[], mediaType: MediaTypes) {
        console.log(data)
        this.type = mediaType
        return this.fetch(url, "POST", data)
    }

    public delete (url: string) {
        return this.fetch(url, "DELETE")
    }
}

export { HttpService, MediaTypes, QueryParametersToURL }

export default HttpService
