import { createTheme } from "@mui/material/styles"
import "App.css"
import { CAFColorScheme, colorScheme } from "theme/colorSchema"
function getCSSVariableValue (variableName: string): string {
    const root = document.documentElement
    const computedStyle = getComputedStyle(root)
    return computedStyle.getPropertyValue(variableName).trim()
}
export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: CAFColorScheme.dark.primary,
            contrastText: CAFColorScheme.dark.onPrimary,
            light: CAFColorScheme.dark.primaryFixedDim,
            dark: CAFColorScheme.dark.onPrimaryFixedVariant
        },
        secondary: {
            main: CAFColorScheme.dark.secondary,
            contrastText: "#000",
            light: CAFColorScheme.dark.secondaryFixedDim,
            dark: CAFColorScheme.dark.onSecondaryFixedVariant
        },
        error: {
            main: CAFColorScheme.dark.error,
            contrastText: CAFColorScheme.dark.onError,
            light: CAFColorScheme.dark.errorContainer,
            dark: CAFColorScheme.dark.onErrorContainer
        },
        background: {
            default: CAFColorScheme.dark.background,
            paper: CAFColorScheme.dark.surface
        },
        text: {
            primary: "#000",
            secondary: CAFColorScheme.dark.onSurfaceVariant
        },
        action: {
            disabledBackground: "#BCB8D0"
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        color: "#271968", // Color del texto
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Fondo ligeramente visible
                        borderRadius: "8px"
                    },
                    "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main" // Borde cuando no está enfocado
                    },
                    "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "primary.dark" // Borde al pasar el mouse
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main" // Borde cuando está enfocado
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                            borderWidth: "1px"
                        },
                        "&:hover fieldset": {
                            borderColor: "rgba(0, 0, 0, 0.87)",
                            borderWidth: "1px"
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "primary.main",
                            borderWidth: "2px"
                        }
                    },
                    "&.Mui-disabled": {
                        "& .MuiInputBase-root": {
                            color: "#271968 !important", // Color del texto cuando está disabled
                            "& textarea": {
                                fontFamily: "Griff",
                                color: "#271968 !important",
                                "-webkit-text-fill-color": "#271968 !important"
                            }
                        },
                        "& .MuiInput-underline.Mui-disabled:before": {
                            borderBottomStyle: "solid",
                            borderBottomColor: "#271968 !important" // Color del borde inferior
                        }
                    }
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.2)", // Fondo ligeramente visible para el grupo de botones
                    borderRadius: "8px" // Bordes redondeados
                }
            }
        },

        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: "#271968", // Color del texto por defecto
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Fondo con opacidad ligera
                    borderRadius: "8px",
                    padding: "8px 16px", // Espaciado interno de los botones
                    transition: "background-color 0.3s", // Suaviza la transición de color de fondo

                    "&.Mui-selected": {
                        backgroundColor: "#271968", // Color de fondo cuando el botón está seleccionado
                        color: "#fff" // Color del texto cuando está seleccionado
                    },

                    "&:hover": {
                        backgroundColor: "#333",
                        color: "#fff"
                    },

                    "&:focus": {
                        outline: "none"
                    },

                    "&.Mui-disabled": {
                        backgroundColor: "rgba(255, 255, 255, 0.3)", // Fondo deshabilitado con opacidad
                        color: "#271968" // Color del texto deshabilitado
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.main !important" // Borde del DatePicker
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.dark !important" // Cambio de color al pasar el mouse
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.main !important" // Borde cuando el input está enfocado
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: CAFColorScheme.dark.onSurfaceVariant,
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: "black", // Color del fondo del ícono en pasos no activos
                    "& .MuiStepIcon-text": {
                        fill: "black" // Color negro solo para el número en el ícono
                    }
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    color: "#E8E7ED" // Color del fondo del ícono en pasos no activos

                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f5f5f5", // Fondo del Select
                    color: "#000 !important", // Color del texto
                    "&.Mui-disabled": {
                        backgroundColor: "#ccc", // Fondo cuando está deshabilitado
                        "& .MuiInputBase-input": {
                            color: "#000" // Color de texto más visible en disabled
                        }
                    },
                    borderColor: "#271968"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: "#000 !important", // Color del texto en el menú
                    "&.Mui-selected": {
                        backgroundColor: "#d1e0ff", // Color de fondo al seleccionar
                        color: "#000 !important"
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "#e0e0e0 !important", // Fondo gris más visible cuando está deshabilitado
                        "& .MuiInputBase-input": {
                            color: "#777 !important" // Color de texto más visible en disabled
                        }
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "#b0c4ff" // Cambio de color al pasar el mouse
                    },
                    "& .MuiListItemIcon-root svg": {
                        color: CAFColorScheme.dark.primary
                    },
                    "&:hover .MuiListItemIcon-root svg": {
                        color: CAFColorScheme.dark.primary
                    }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f5f5f5",
                    color: "#000 !important",
                    border: "1px solid #271968", // Añadimos un borde visible
                    "&:hover": {
                        backgroundColor: "#e0e0e0",
                        border: "1px solid rgba(0, 0, 0, 0.87)" // Borde más oscuro al hover
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "#e0e0e0",
                        color: "#000 !important",
                        border: "1px solid rgba(0, 0, 0, 0.23)"
                    },
                    "&.Mui-focused": {
                        backgroundColor: "#e0e0e0",
                        border: "2px solid #1976d2" // Borde más grueso y del color primary cuando está enfocado
                    },
                    "&:before": {
                        borderBottom: "none !important" // Removemos la línea inferior por defecto
                    },
                    "&:after": {
                        borderBottom: "none !important" // Removemos la línea inferior por defecto
                    },
                    "&:hover:before": {
                        borderBottom: "none !important" // Removemos la línea inferior por defecto en hover
                    },
                    "&.Mui-focused:before": {
                        borderBottom: "none !important" // Removemos la línea inferior por defecto en focus
                    },
                    transition: "border 0.3s", // Añadimos una transición suave para los cambios de borde
                    borderRadius: "4px 4px 0 0" // Mantenemos el estilo filled con esquinas superiores redondeadas
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "rgba(0, 0, 0, 0.6) !important", // Color del label
                    "&.Mui-focused": {
                        color: "#1976d2 !important" // Color del label cuando está enfocado
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#271968 !important",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "8px",
                    "& .MuiInputBase-input": {
                        color: "#000 !important" // Forzar el color del texto en el input
                    },
                    "&.Mui-disabled": {
                        color: "#271968 !important",
                        "-webkit-text-fill-color": "#271968 !important",
                        WebkitTextFillColor: "#271968 !important"
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "1px solid #271968 !important"
                    },
                    // Borde inferior al hacer hover
                    "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "2px solid #271968 !important"
                    },
                    // Borde inferior cuando está enfocado
                    "&:after": {
                        borderBottom: "2px solid #271968 !important"
                    },
                    "&.Mui-disabled": {
                        color: `${CAFColorScheme.dark.primary} !important`,
                        "-webkit-text-fill-color": `${CAFColorScheme.dark.primary} !important`,
                        "&:before": {
                            borderBottomStyle: "solid",
                            borderBottom: "1px solid #271968 !important"
                        },
                        "& input": {
                            color: "#271968 !important",
                            "-webkit-text-fill-color": "#271968 !important"
                        }
                    },
                    "& input": {
                        color: "#271968"
                    }
                }
            }
        }
    }
})

export const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: colorScheme.light.primary,
            contrastText: colorScheme.light.onPrimary,
            light: colorScheme.light.primaryFixedDim,
            dark: colorScheme.light.onPrimaryFixedVariant
        },
        secondary: {
            main: colorScheme.light.secondary,
            contrastText: colorScheme.light.onSecondary,
            light: colorScheme.light.secondaryFixedDim,
            dark: colorScheme.light.onSecondaryFixedVariant
        },
        error: {
            main: colorScheme.light.error,
            contrastText: colorScheme.light.onError,
            light: colorScheme.light.errorContainer,
            dark: colorScheme.light.onErrorContainer
        },
        background: {
            default: colorScheme.light.background,
            paper: colorScheme.light.surface
        },
        text: {
            primary: "#000",
            secondary: colorScheme.light.onSurfaceVariant
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            },
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        color: "primary.main" // Usa el color primario dinámicamente
                    },
                    "&.Mui-disabled": {
                        "& .MuiInputBase-root": {
                            color: "primary.main !important", // Aplica el color primario cuando está deshabilitado
                            "-webkit-text-fill-color": "primary.main !important"
                        }
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    border: "2px solid #6D6C6F",
                    borderRadius: "10px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        }
    }
})
/* export const darkTheme: any = {
    typography: {
        fontFamily: "Roboto"
    },
    palette: {
        mode: "dark",
        primary: {
            light: "#184d60", // 184d60    4a6952
            main: "#FAE733", // 2099C6    25ee32
            dark: "#104c63", // 104c63   2F6140,
            contrastText: "#E8E7ED"
        },
        secondary: {
            main: "#E8E7ED",
            light: "#CFF2F2",
            dark: "6D6C6F",
            contrastText: "#000"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#000",
                    border: "2px solid #6D6C6F",
                    borderRadius: "10px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: "black", // Color del fondo del ícono en pasos no activos
                    "& .MuiStepIcon-text": {
                        fill: "black" // Color negro solo para el número en el ícono
                    }
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    color: "#E8E7ED" // Color del fondo del ícono en pasos no activos

                }
            }
        }
    }
} as const

export const lightTheme: any = {
    typography: {
        fontFamily: "Roboto"
    },
    palette: {
        mode: "light",
        primary: {
            light: "#EFC3CA", // 184d60    4a6952
            main: "#FAE733", // 2099C6    25ee32
            dark: "#104c63" // 104c63   2F6140
        },
        secondary: {
            main: "#7d788d",
            light: "#931F32",
            dark: "6D6C6F",
            contrastText: "#fff"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    border: "2px solid #6D6C6F",
                    borderRadius: "10px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        }
    }
} as const */

export const createOrbikTheme = () => createTheme({ ...darkTheme })
export const createLightOrbikTheme = () => createTheme({ ...lightTheme })
