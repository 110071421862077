import React, { useEffect, useRef, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { Box, Breadcrumbs, Divider, Drawer, Grid, IconButton, Link, Paper, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import MenuIcon from "@mui/icons-material/Menu"
import { Navigate, Route, Link as RouterLink, Routes, useLocation } from "react-router-dom"
import { Sidebar } from "@components/index"
import HeaderNotifications from "@views/settings/HeaderNotifications"
import { hiddenItems, sidebarPrimaryItems, sidebarSecondaryItems } from "@components/sidebar/SideBarConfig"
import useMediaResponsive from "@hooks/useMobile"
import Footer from "./Footer"

const fullyDecodeURIComponent = (str: string) => {
    while (str.indexOf("%25") !== -1) {
        str = decodeURIComponent(str)
    }
    return decodeURIComponent(str)
}

const capitalize = (str: string) => {
    if (str === "system_notification") {
        return "Notifications"
    }
    if (str.startsWith("add")) {
        const remaining = str.slice(3)
        return `Add ${remaining.charAt(0).toUpperCase()}${remaining.slice(1).toLowerCase()}`
    }
    return fullyDecodeURIComponent(str)
        .split(/(?=[A-Z])|_+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
}

const AppLayout = () => {
    const theme = useTheme()
    const location = useLocation()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const isMobile = useMediaResponsive("(max-width: 540px)")
    const prevPathRef = useRef(location.pathname)

    const isUUID = (str: string) => {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
        return uuidRegex.test(str)
    }

    const isIgnoredSegment = (str: string) => {
        const ignoredSegments = ["settings", "manage", "hardwarecomponent", "provider", "edit", "mitigate", "package"]
        return ignoredSegments.includes(str.toLowerCase())
    }

    const generateBreadcrumbs = () => {
        const pathnames = location.pathname.split("/").filter((x) => x)
        return pathnames.map((value, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`

            let displayName
            if (isUUID(value)) {
                const previousSegment = pathnames[index - 1] || "Detail"
                displayName = `${capitalize(previousSegment)} Detail`
            } else {
                displayName = capitalize(decodeURIComponent(value))
            }
            if (value.toLowerCase().includes("create")) {
                return null // No renderizar nada para las rutas 'create' o 'add'
            }
            const isLast = index === pathnames.length - 1

            if (isIgnoredSegment(value)) {
                return (
                    <Typography key={index} color={isLast ? "primary" : "inherit"}>
                        {displayName}
                    </Typography>
                )
            }

            return isLast
                ? (
                    <Typography key={index} color="primary">
                        {displayName}
                    </Typography>
                )
                : (
                    <Link key={index} component={RouterLink} to={routeTo} color="inherit">
                        {displayName}
                    </Link>
                )
        })
    }

    const toggleSidebar = () => {
        setIsOpen(prev => !prev)
    }

    // Cerrar el sidebar cuando se navega a otra página
    useEffect(() => {
        if (prevPathRef.current !== location.pathname && isMobile && isOpen) {
            setIsOpen(false)
        }
        prevPathRef.current = location.pathname
    }, [location.pathname, isMobile, isOpen])

    // Ajustar el estado del sidebar según el tamaño de la pantalla
    useEffect(() => {
        if (isMobile) {
            setIsOpen(false)
        }
    }, [isMobile])

    return (
        <Paper sx={{ minHeight: "100vh", width: "100%", display: "flex", flexDirection: "column" }}>
            <Grid container sx={{ display: "flex", minHeight: "100vh" }}>
                {/* Sidebar para pantallas grandes */}
                {!isMobile && (
                    <Grid
                        item
                        sx={{
                            width: "90px",
                            background: theme.palette.primary.main,
                            paddingY: "40px"
                        }}
                    >
                        <Sidebar primaryItems={sidebarPrimaryItems} secondaryItems={sidebarSecondaryItems} />
                    </Grid>
                )}

                {/* Sidebar móvil como drawer */}
                {isMobile && (
                    <Drawer
                        anchor="left"
                        open={isOpen}
                        onClose={toggleSidebar}
                        sx={{
                            "& .MuiDrawer-paper": {
                                width: "90px",
                                background: theme.palette.primary.main,
                                paddingY: "40px"
                            }
                        }}
                    >
                        <Sidebar primaryItems={sidebarPrimaryItems} secondaryItems={sidebarSecondaryItems} />
                    </Drawer>
                )}

                {/* Content Area */}
                <Grid item xs sx={{ display: "flex", flexDirection: "column", overflowY: "auto", background: theme.palette.background.paper }}>
                    {/* Top Content */}
                    <Grid item xs container padding="40px" flexDirection="column" spacing="20px" sx={{ flexGrow: 1 }}>
                        {/* Breadcrumbs and Notifications */}
                        <Grid item container alignItems="center">
                            <Grid item xs display="flex" alignItems="center">
                                {isMobile && (
                                    <IconButton
                                        onClick={toggleSidebar}
                                        sx={{
                                            mr: 1,
                                            color: theme.palette.primary.main,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: "8px"
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                <Box>
                                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                        <Link component={RouterLink} to="/" color="primary" fontSize="32px" fontWeight="bolder" fontFamily="Griff" sx={{ textDecoration: "none" }}>
                                            <Grid>
                                                <img
                                                    src="/assets/img/LeadMind.png"
                                                    style={{ maxWidth: "250px", maxHeight: "250px", marginTop: "10px" }}
                                                />
                                            </Grid>
                                        </Link>
                                        {generateBreadcrumbs()}
                                    </Breadcrumbs>
                                </Box>
                            </Grid>
                            <Grid item sx={{ marginTop: "8px" }}>
                                <HeaderNotifications />
                            </Grid>
                        </Grid>

                        <Divider sx={{ height: "0.5px", width: "100%", background: theme.palette.primary.main, margin: "9px 0" }} />

                        <Routes>
                            {[...sidebarPrimaryItems, ...sidebarSecondaryItems, ...hiddenItems].flat(1).map((item, idx) => (
                                <Route key={idx} path={item.route} element={item.component} />
                            ))}
                            <Route key="" path="*" element={<Navigate to="/" replace={true} />} />
                        </Routes>
                    </Grid>

                    {/* Footer */}
                    <Footer />
                </Grid>
            </Grid>
        </Paper>
    )
}

export { AppLayout }
export default AppLayout
