import React, { useState } from "react"
import { Grid, IconButton, TextField } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ActionButton from "@components/common/Button/ActionButton"

interface ComponentDetailsGridProps {
    components: Array<{ name: string; version: string }>;
    setComponents: (updatedComponents: Array<{ name: string; version: string }>) => void;
}

const ComponentDetailsGrid: React.FC<ComponentDetailsGridProps> = ({
    components,
    setComponents
}) => {
    const [error, setError] = useState<string | null>(null)

    const handleInputChange = (index: number, field: "name" | "version", value: string) => {
        const updatedComponents = [...components]
        updatedComponents[index] = {
            ...updatedComponents[index],
            [field]: value
        }
        setComponents(updatedComponents)
    }

    const handleDelete = (index: number) => {
        const updatedComponents = components.filter((_, i) => i !== index)
        setComponents(updatedComponents)
    }

    const handleAddComponent = () => {
        if (components.length >= 1) {
            setError("You can only add up to 1 components.")
            return
        }
        setError(null)
        setComponents([...components, { name: "", version: "" }])
    }

    return (
        <Grid item spacing={2} alignItems="center" display="flex" xs={8} gap={2}>
            {components.map((component, index) => (
                <Grid
                    key={index}
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: "8px" }}
                >
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Component Name"
                            variant="standard"
                            value={component.name}
                            onChange={(e) => handleInputChange(index, "name", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Component Version"
                            variant="standard"
                            value={component.version}
                            onChange={(e) => handleInputChange(index, "version", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="error"
                            onClick={() => handleDelete(index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            {components.length === 0 && (
                <Grid item xs={12}>
                    <ActionButton text="Add new Components" onClick={handleAddComponent} />
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <span style={{ color: "red" }}>{error}</span>
                </Grid>
            )}
        </Grid>
    )
}

export default ComponentDetailsGrid
