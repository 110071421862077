// routes.ts
const PrivateRoutes = {
    ACHILLES: "/manage/achilles/*",
    HOME: "/",
    HIERARCHY: "/manage/hierarchy/*",
    PRODUCT: "/manage/product/*",
    ASSESSMENT: "/assessment/*",
    PENTEST: "/pentest/*",
    VULNERABILITY: "/vulnerability/*",
    BENCHMARK: "/benchmark/*",
    BILLING: "/settings/billing",
    USER_LOGS: "/settings/user_logs",
    USER_LOGS_DETAILS: "/settings/user_logs/:id",
    COMPLIANCE: "/compliance/*",
    EMAIL: "/settings/email/*",
    SETTINGS: "/settings/*",
    CLIENT: "manage/client/*",
    CALENDAR: "/calendar/*",
    USER: "manage/client/:id/user",
    USER_DETAIL: "manage/client/:id/user/:userId",
    CONTACT: "support/contact/*",
    FAQ: "support/faq/*",
    LOGOUT: "/logout",
    COMPONENT: "/manage/component/*",
    COMPONENT_ASSESSMENT: "/assessment/:id/component/",
    COMPONENT_DETAIL: "manage/component/:componentId",
    ISSUE: "/vulnerability/:id/issue/",
    ISSUE_DETAIL: "/vulnerability/:id/issue/:issueId",
    ISSUE_DETAIL_PRODUCT: "/product/:id/issue/:issueId",
    JIRA: "/settings/jira",
    SBOM_QUALITY: "/assessment/:id/sbomQuality",
    SBOM_EVOLUTION: "/manage/product/:id/evolution",
    TAGS: "/manage/tags/*",
    TICKET: "/manage/ticket/*",
    TICKET_DETAIL: "/manage/ticket/:id",
    TICKET_ADD: "/manage/ticket/add",
    TOOLBOX: "/settings/toolbox",
    COMPONENT_CPE: "/component/cpe/",
    CPE: "/assessment/:id/cpe",
    CPE_DETAIL: "/assessment/:id/cpe/:cpeId",
    HISTORIC_CVE: "/vulnerability/:id/historic",
    TEMPLATE: "manage/template/*",
    POLICY: "/manage/policy/*",
    PREFERENCES: "/settings/preferences",
    SLA: "/settings/sla/*",
    LICENSE: "/license/*",
    SAST: "/settings/sast/*",
    SYSTEM_NOTIFICATION: "/system_notification/*",
    SYSTEM_NOTIFICATION_DETAIL: "/system_notification/:id",
    SUPPORT: "/support/*",
    NOTIFICATION_CONFIG: "/notification-config/*"
} as const

export default PrivateRoutes
