import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { useContext, useEffect, useState } from "react"
import { Box, Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, MenuItem, TextField, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { MdEditNotifications, MdOutlinePolicy } from "react-icons/md"
import { LiaClipboardCheckSolid } from "react-icons/lia"
import { TbClockPlus } from "react-icons/tb"
import { I18nContext } from "I18nProvider"
import { StyledTextarea } from "@components/common/textareas"
import ClientSelector from "@components/client/ClientSelector"
import { AbilityContext } from "@components/permissions/Can"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useTrack } from "@components/track/TrackContext"
import { ServicesContext } from "@context/index"
import { LicenseType } from "@models/License"
import Email, { EmailFrequency, EmailFrequencyKeys } from "@models/Email"
import { Reminder } from "@models/Reminder"
import { NotificationConfigs } from "@models/NotificationConfig"
import { FilterOperation } from "@utils/queryParams"
import useMediaResponsive from "@hooks/useMobile"
import AddReminderModal from "./AddReminderModal"

const initValue: Email = {
    id: "",
    emails: [],
    email_enabled: false,
    email_frequency: EmailFrequency.Day,
    notification_new_cve: false,
    notification_updated_cve: false,
    notification_score_flag: 0,
    client_id: ""
}

const initNotificationConfigs: NotificationConfigs = {
    list: [],
    count: 0
}

const EmailDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const reminderService = useContext(ServicesContext).reminderService
    const emailService = useContext(ServicesContext).emailService
    const licenseService = useContext(ServicesContext).licenseService
    const notificationConfigService = useContext(ServicesContext).notificationConfigService
    const [error, setError] = React.useState<Error | null>(null)
    const [email, setEmail] = React.useState(initValue)
    const [prevFormData, setPrevFormData] = React.useState<Email>(initValue)
    const [prevNotificationConfigsFormData, setNotificationConfigsPrevFormData] = React.useState<NotificationConfigs>(initNotificationConfigs)
    const [licenseType, setLicenseType] = React.useState<LicenseType>(LicenseType.Golden)
    const [notificationConfigs, setNotificationConfigs] = React.useState <NotificationConfigs>(initNotificationConfigs)
    const { track, trackData } = useTrack()
    const [reminders, setReminders] = useState<Reminder[]>([])
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [clientId, setClientId] = useState<string>("")
    useEffect(() => {
        track({ view: "EmailDetail" })
    }, [])
    /* TODO: This function doesn't work as intended */
    const formValid = (): boolean => {
        const isNotSameOldValues = [
            email.email_enabled === prevFormData.email_enabled,
            email.email_frequency === prevFormData.email_frequency,
            email.notification_new_cve === prevFormData.notification_new_cve,
            email.notification_updated_cve === prevFormData.notification_updated_cve,
            email.notification_score_flag === prevFormData.notification_score_flag,
            email.emails?.join(";") === prevFormData.emails?.join(";")
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        if (notificationConfigs.count !== prevNotificationConfigsFormData.count) {
            return false
        }
        for (let i = 0; i < notificationConfigs.count; i++) {
            const a = notificationConfigs.list[i]
            const b = prevNotificationConfigsFormData.list[i]
            const countFields = a.fields.length
            if (countFields !== b.fields.length) {
                return false
            }
            for (let j = 0; j < countFields; j++) {
                if (a.fields[j].value !== b.fields[j].value) {
                    return false
                }
            }
        }
        return true
    }
    const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const handleSaveChanges = async () => {
        try {
            const response = await emailService.update(email.id, email)
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        try {
            const response = await notificationConfigService.updateAllByClient(clientId, notificationConfigs)
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
    }

    const getEmailDetail = async (id: string) => {
        setClientId(id)
        if (id === "") {
            setEmail(initValue)
            return
        }
        try {
            const response = await emailService.getAll({ filters: [{ field: "client_id", operation: FilterOperation.EnumEqual, value: id }] })
            if (response.count < 1) {
                setEmail(initValue)
                setPrevFormData(initValue)
            } else {
                setEmail(response.list[0])
                setPrevFormData(response.list[0])
                throw new Error(context.t.transtale("error_testing"))
            }
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        try {
            const response = await notificationConfigService.getAllByClient(id)
            if (response.count < 1) {
                setNotificationConfigs(initNotificationConfigs)
                setNotificationConfigsPrevFormData(initNotificationConfigs)
            } else {
                setNotificationConfigs(response)
                setNotificationConfigsPrevFormData(response)
            }
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
    }
    const fetchReminder = async (clientId?: string) => {
        try {
            let response
            if (clientId) {
                response = await reminderService.getAll({
                    filters: [{ field: "client_id", operation: FilterOperation.EnumEqual, value: clientId }]
                })
            } else {
                response = await reminderService.getAll()
            }
            setReminders(response.list)
        } catch (error: any) {
            setError(error)
            setSnackbarOpen(true)
        }
    }
    useEffect(() => {
        if (ability.can("read", "Client")) {
            fetchReminder(clientId)
        } else {
            fetchReminder()
        }
    }, [clientId])
    const handleChange = (event: any) => {
        const { name, value, type, checked } = event.target
        let newValue = type === "checkbox" ? checked : value
        if (name === "emails") {
            newValue = newValue.split(/;/).map((email:any) => email.trim())
        } else if (name === "notification_score_flag") {
            newValue = (value.match(/\d+/) ? parseInt(value.match(/\d+/)[0], 10) : 0)
        }
        setEmail({ ...email, [name]: newValue })
    }

    const handleNotificationConfigsChange = (event: any) => {
        const { id, value, type, checked } = event.target

        // Extract index (key) and field name from id
        const idParts = id.split("_")
        const key = parseInt(idParts[idParts.length - 1], 10)
        const fieldName = idParts.slice(0, -1).join("_")
        const newValue = type === "checkbox" ? checked : value
        const editedNotificationConfig = { ...notificationConfigs.list[key] }

        if (fieldName === "notification_config_enabled") {
            editedNotificationConfig.enabled = newValue
        } else {
            const fieldNameWithoutPrefix = fieldName.replace("notification_config_", "")
            const updatedFields = editedNotificationConfig.fields.map(field => {
                if (field.name === fieldNameWithoutPrefix) {
                    return { ...field, value: newValue }
                }
                return field
            })
            editedNotificationConfig.fields = updatedFields
        }

        const updatedList = [...notificationConfigs.list]
        updatedList[key] = editedNotificationConfig
        setNotificationConfigs({ ...notificationConfigs, list: updatedList })
    }
    let formattedEmail = ""
    if (Array.isArray(email.emails)) {
        formattedEmail = email.emails.join(";\n")
    }
    const ability = useContext(AbilityContext)
    if (!ability.can("read", "Client")) {
        useEffect(() => {
            const fetchData = async () => {
                const email = await emailService.getAll()
                if (email.count > 0) {
                    setEmail(email.list[0] as Email)
                    setPrevFormData(email.list[0])
                } else {
                    setEmail(initValue)
                }
            }
            const fetchLicense = async () => {
                const licenses = await licenseService.getAll()
                if (licenses.count > 0) {
                    setLicenseType(licenses.list[0].type)
                }
            }
            const fetchNotificationConfig = async () => {
                const notificationConfigs = await notificationConfigService.getAllByClient("")
                if (notificationConfigs.count > 0) {
                    setNotificationConfigs(notificationConfigs)
                    setNotificationConfigsPrevFormData(notificationConfigs)
                } else {
                    setNotificationConfigs(initNotificationConfigs)
                    // setNotificationConfigsPrevFormData(initNotificationConfigs)
                }
            }
            fetchData()
            fetchLicense()
            fetchNotificationConfig()
        }, [])
    }
    function formatAssessmentType (type: string): string {
        const acronymLabels = ["CRT", "SCA"]
        if (acronymLabels.includes(type.toUpperCase())) {
            return type.toUpperCase()
        }
        return type
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }

    function onCloseReminderModal () {
        setOpenModal(false)
        fetchReminder(clientId)
    }

    const isMobile = useMediaResponsive("(max-width: 780px)")

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid container alignItems={isMobile ? "flex-start" : "center"} sx={{ justifyContent: "space-between", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>

                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("notifications_title")}
                </Typography>
                {(licenseType !== LicenseType.Golden || ability.can("*", "Client")) && (
                    <Grid item mt={1} direction={{ xs: "column", md: "row" }} sx={{ display: "flex", gap: 2 }}>
                        <ActionButton icon={<LiaClipboardCheckSolid/>} onClick={() => navigate("../settings/sla")} text={context.t.translate("sla_management")} />
                        <ActionButton icon={<MdOutlinePolicy/>} onClick={() => navigate("../manage/policy")} text={context.t.translate("policy_management")} />
                        <ActionButton icon={<TbClockPlus />} onClick={() => setOpenModal(true) } text={context.t.translate("add_reminder")} />

                    </Grid>
                )}
            </Grid>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("an_error")}
                    />
                    <AddReminderModal open={openModal} onClose={() => onCloseReminderModal()} reminder={selectedReminder}/>
                    <StyledBox>

                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item container justifyContent={isMobile ? "center" : "space-between"} alignItems={isMobile ? "flex-start" : "center"} flexDirection={{ xs: "column", md: "row" }}>
                                {ability.can("*", "Email") && (
                                    <Grid item width="100%" xs={12} md={3}>
                                        <ClientSelector value={email.client_id} onSelect={(id) => getEmailDetail(id || "")} />
                                    </Grid>
                                )}
                                <Grid item>
                                    <ActionButton text={context.t.translate("save_changes")} disabled={!formValid()} onClick={handleSaveChanges} />
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                            </Grid>
                            <Grid item container justifyContent="space-around" alignItems="flex-start" mt={2}>
                                <Grid item container xs={12} md={6} textAlign="center">
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("report_filters")}</Typography>
                                    </Grid>
                                    <Grid item container flexDirection="row" textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder" >{context.t.translate("recieve_alerts")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} container direction="column">
                                            <Grid item>
                                                <FormControlLabel name="notification_new_cve" checked={email.notification_new_cve} onChange={handleChange} control={<Checkbox />} label={<Typography >{context.t.translate("new_CVEs")}</Typography>} labelPlacement="end"/>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel name="notification_updated_cve" checked={email.notification_updated_cve} onChange={handleChange} control={<Checkbox />} label={<Typography >{context.t.translate("CVEs_are")}</Typography>} labelPlacement="end"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container flexDirection="row" textAlign="left" spacing="10px">
                                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("recieve_alerts_when_CVSS")}
                                                <TextField name="notification_score_flag" size="small" variant="outlined" value={email.notification_score_flag} onChange={handleChange}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("alerts")}</Typography>
                                    </Grid>
                                    <Grid item container textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12} container alignItems="center">
                                            <FormControlLabel name="email_enabled" checked={email.email_enabled} onChange={handleChange} control={<Checkbox />} label={<Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("enable")}</Typography>} labelPlacement="end"/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems="center" spacing="40px">
                                        <Grid item xs={12} md={12} container>
                                            <Grid item xs={12}>
                                                <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("recipient")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <StyledTextarea minRows={3} name="emails" value={formattedEmail} onChange={handleChange} placeholder="example@orbik-cybersecurity.com ; test@orbik-cybersecurity.com" style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12} container alignItems="center">
                                            <FormControl fullWidth>
                                                <Grid item xs={12}>
                                                    <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("email_frequency")}</Typography>
                                                </Grid>
                                                <TextField margin="normal" select name="email_frequency" value={email.email_frequency} onChange={handleChange}>
                                                    {EmailFrequencyKeys.map((key, idx) =>
                                                        (<MenuItem key={idx} value={EmailFrequency[key]}><Typography fontFamily="Griff" fontWeight="bolder">{key}</Typography></MenuItem>)
                                                    )}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            {context.t.translate("reminders")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container spacing={1} mt={2} sx={{ border: "1px solid #ddd", borderRadius: "8px", p: 2 }}>
                                        {reminders.length > 0
                                            ? (
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        sx={{
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "8px",
                                                            overflow: "hidden",
                                                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)"
                                                        }}
                                                    >
                                                        {/* Encabezado de la tabla */}
                                                        <Grid
                                                            item
                                                            container
                                                            xs={12}
                                                            sx={{
                                                                bgcolor: theme.palette.primary.main,
                                                                p: 1.5,
                                                                color: "white"
                                                            }}
                                                        >
                                                            <Grid item xs={5}>
                                                                <Typography variant="subtitle1" fontWeight="bold">
                                                                    {context.t.translate("type")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography variant="subtitle1" fontWeight="bold">
                                                                    {context.t.translate("frequency")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                                                                    {context.t.translate("actions")}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        {reminders.map((reminder: Reminder, index) => (
                                                            <Grid
                                                                key={reminder.id}
                                                                item
                                                                container
                                                                xs={12}
                                                                alignItems="center"
                                                                sx={{
                                                                    p: 1.5,
                                                                    borderBottom: index < reminders.length - 1 ? "1px solid #e0e0e0" : "none",
                                                                    bgcolor: index % 2 === 0 ? "#f5f5f5" : "white",
                                                                    "&:hover": {
                                                                        bgcolor: "#edf7ff"
                                                                    },
                                                                    transition: "background-color 0.2s"
                                                                }}
                                                            >
                                                                <Grid item xs={5}>
                                                                    <Typography variant="body1" fontWeight="500">
                                                                        {formatAssessmentType(reminder.type)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <Typography variant="body1">
                                                                        {reminder.reminder_frequency.charAt(0).toUpperCase() + reminder.reminder_frequency.slice(1).toLowerCase()}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={2} container justifyContent="center">
                                                                    <ActionButton
                                                                        icon={<MdEditNotifications />}
                                                                        text={context.t.translate("edit")}
                                                                        onClick={() => {
                                                                            setSelectedReminder(reminder)
                                                                            setOpenModal(true)
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            )
                                            : (
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{
                                                        border: "1px dashed #ccc",
                                                        borderRadius: "8px",
                                                        p: 3,
                                                        minHeight: "120px",
                                                        bgcolor: "#f9f9f9"
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textSecondary"
                                                        textAlign="center"
                                                    >
                                                        {context.t.translate("no_reminders_message")}
                                                    </Typography>
                                                    <ActionButton
                                                        icon={<TbClockPlus />}
                                                        text={context.t.translate("add_reminder")}
                                                        onClick={() => setOpenModal(true)}
                                                    />
                                                </Grid>
                                            )}
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                            </Grid>
                            <Grid item container justifyContent="start" alignItems="flex-start" mt={2}>
                                {/* Transport Configuration */}
                                {notificationConfigs.list.length > 0 && (
                                    <Box sx={{ p: 3 }}>
                                        <Divider sx={{ mb: 3 }} />

                                        <Typography
                                            color="primary"
                                            fontSize={{ xs: "24px", md: "30px" }}
                                            fontFamily="Griff"
                                            fontWeight="bolder"
                                            sx={{ mb: 2 }}
                                        >
                                            {context.t.translate("transport_configurations")}
                                        </Typography>

                                        <Grid container spacing={3}>
                                            {notificationConfigs.list.map((notificationConfig, key) => (
                                                <Grid item xs={12} md={6} key={notificationConfig.transport}>
                                                    <Card variant="outlined">
                                                        <CardHeader
                                                            title={
                                                                <Typography
                                                                    color="primary"
                                                                    fontSize={{ xs: "20px", md: "24px" }}
                                                                    fontFamily="Griff"
                                                                    fontWeight="bolder"
                                                                >
                                                                    {notificationConfig.transport_label}
                                                                </Typography>
                                                            }
                                                        />
                                                        <CardContent>
                                                            <FormControlLabel
                                                                name="notification_config[enabled][]"
                                                                id={`notification_config_enabled_${key}`}
                                                                checked={notificationConfig.enabled}
                                                                onChange={() => {}}
                                                                control={<Checkbox />}
                                                                label="Is enabled?"
                                                                labelPlacement="end"
                                                                sx={{ mb: 2 }}
                                                            />

                                                            {notificationConfig.fields.map((field) => (
                                                                <Box key={field.name} sx={{ mb: 2 }}>
                                                                    <Typography
                                                                        fontSize="16px"
                                                                        fontFamily="Griff"
                                                                        fontWeight="bolder"
                                                                        sx={{ mb: 1 }}
                                                                    >
                                                                        {field.label}
                                                                    </Typography>
                                                                    <TextField
                                                                        name={`notification_config_[${field.name}][]`}
                                                                        id={`notification_config_${field.name}_${key}`}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        value={field.value}
                                                                        onChange={handleNotificationConfigsChange}
                                                                        fullWidth
                                                                    />
                                                                </Box>
                                                            ))}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid >
        </Grid >
    )
}

export { EmailDetail }
export default EmailDetail
