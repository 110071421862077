import React, { useCallback, useContext, useEffect, useState } from "react"
import { AlertColor, Box, Grid, TextField, Typography } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import ServicesContext from "@context/ServicesContext"
import { CustomAttributes } from "@models/Keycloak"

const GitComponent = ({ id, clientId }: {id: string | undefined, clientId: string | undefined}) => {
    const keycloackService = useContext(ServicesContext).keycloakService
    const [token, setToken] = useState<string>("")
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success")

    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const fetchToken = useCallback(async () => {
        if (id && clientId) {
            try {
                const tokenResult = await keycloackService.getAttribute(clientId, CustomAttributes.GitToken + id)
                setToken(tokenResult[CustomAttributes.GitToken + id])
            } catch (error) {
                console.warn(context.t.translate("git_token_not"))
                setToken("")
            }
        }
    }, [id, clientId])

    useEffect(() => {
        fetchToken()
    }, [id, clientId])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (id && clientId) {
            try {
                await keycloackService.postAttribute(clientId, { attribute: CustomAttributes.GitToken + id, value: token })
                setSnackbarMessage(context.t.translate("git_ok"))
                setSnackbarSeverity(context.t.translate("succes"))
                setOpenSnackbar(true)
            } catch (e: any) {
                setSnackbarMessage(e.error || e.message)
                setSnackbarSeverity(context.t.translate("an_error"))
                setOpenSnackbar(true)
            }
        }
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            id === "",
            clientId === "",
            token === ""
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("git_integration")}</Typography>
                    <StyledBox>
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                                <CustomSnackbar
                                    open={openSnackbar}
                                    onClose={() => setOpenSnackbar(false)}
                                    message={snackbarMessage}
                                    severity={snackbarSeverity}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField type="password" margin="normal" required fullWidth variant="filled" label={context.t.translate("git_token")} id="token" value={token}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setToken(event.target.value)
                                            }}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ActionButton type="submit" disabled={!formValid()} text={context.t.translate("git_save")}/>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GitComponent
