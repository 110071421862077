/* eslint-disable indent */
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DataGrid, getGridNumericOperators, getGridStringOperators, GridActionsCellItem, GridColDef, GridFilterModel, GridFilterOperator, GridRowParams, GridRowsProp, GridSortModel } from "@mui/x-data-grid"
import { AlertColor, Box, Button, FormGroup, Grid, IconButton, Paper, Skeleton, Tab, TableContainer, Tabs, Typography, useTheme } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import AccountTreeIcon from "@mui/icons-material/AccountTree"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import { MdCloudDownload } from "react-icons/md"
import { pdf } from "@react-pdf/renderer"
import { I18nContext } from "I18nProvider"
import { convertModelDefToGridColDef } from "@components/common/tables"
import ProductTableDefinition from "@components/product/ProductTableDefinition"
import { AbilityContext, Can } from "@components/permissions/index"
import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import ActionButton from "@components/common/Button/ActionButton"
import VulnReportDocumentHierarchy from "@components/report/VulnReportDocumentHierarchy"
import { useTrack } from "@components/track/TrackContext"
import { ServicesContext } from "@context/index"
import { NumVulnerability } from "@models/Vulnerability"
import Product from "@models/Product"
import Hierarchy, { CafHierarchyStatus } from "@models/Hierarchy"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/index"
import HierarchyCard from "@views/product/HierarchyCard"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import StatsCard from "@components/common/stats/StatsCard"
import FilterDropdown from "@components/filters/FiltersDrawer"
import { Filter, filterOperationMap } from "@models/Filter"
import { convertToTree } from "./hierarchyUtils"
import ProductsModal from "./modals/ProductsModal"
import HierarchyStatusColored from "./HierarchyStatusColored"
interface TreeNode {
    id: string;
    name: string;
    description: string,
    status: CafHierarchyStatus | null,
    num_vulnerability: NumVulnerability,
    numProduct?: number,
    numVulns?: number,
    children?: TreeNode[];
}

interface FlattenedTreeNode extends Omit<TreeNode, "children"> {
    parentId?: string;
    level: number;
    isLeaf: boolean;
}

const flattenTreeData = (
    nodes: TreeNode[],
    parentId: string | undefined = undefined,
    level: number = 0
): FlattenedTreeNode[] => {
    return nodes.flatMap(node => {
        const { children, ...rest } = node
        const isLeaf = !children || children.length === 0
        const flatNode: FlattenedTreeNode = { ...rest, parentId, level, isLeaf }
        if (isLeaf) {
            return [flatNode]
        }
        return [flatNode, ...flattenTreeData(children, node.id, level + 1)]
    })
}

const allowedStringOperators: GridFilterOperator[] = getGridStringOperators().filter(operator =>
    ["contains", "equals"].includes(operator.value)
)

const allowedNumberOperators: GridFilterOperator[] = getGridNumericOperators().filter(operator =>
    ["=", "!=", ">", ">=", "<", "<="].includes(operator.value)
)

const HierarchyList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const productService = useContext(ServicesContext).productService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [treeData, setTreeData] = useState<FlattenedTreeNode[]>([])
    const [visibleRows, setVisibleRows] = useState<FlattenedTreeNode[]>([])
    const [expanded, setExpanded] = useState<Set<string>>(new Set())
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [rows, setRows] = useState<GridRowsProp>([])
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const [productsOpen, setProductsOpen] = useState(false)
    const [vulnsOpen, setVulnsOpen] = useState(false)
    const [filters, setFilters] = useState<FilterOption[]>([])
    const [params, setParams] = useState<QueryParameters>({})
    const [products, setProducts] = useState<GridRowParams<Product> | null>(null)
    const [totalVulns, setTotalVulns] = useState<{ [key: string]: TotalData }>({})
    const handleProductsClose = () => setProductsOpen(false)
    const handleVulnClose = () => setVulnsOpen(false)
    const handleProductsOpen = async (params: any) => {
        setProducts(params.row)
    }
    const [fetchedFilters, setFetchedFilters] = useState<Filter[]>([])
    const filterService = useContext(ServicesContext).filterService
    const [hierarchies, setHierarchies] = useState<Hierarchy[]>([])
    const [tabIndex, setTabIndex] = React.useState(0)
    const [cols, setCols] = useState<GridColDef[]>([])
    const { track, trackData } = useTrack()
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info")
    useEffect(() => {
        track({ view: "HierarchyList" })
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
        fetchFilters()
    }, [])

    const toggleExpand = (id: string) => {
        setExpanded(prev => {
            const newExpanded = new Set(prev)
            if (newExpanded.has(id)) {
                newExpanded.delete(id)
            } else {
                newExpanded.add(id)
            }
            return newExpanded
        })
    }
    const fetchFilters = async () => {
        try {
            const response = await filterService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: "hierarchy" }] })
            console.log(response)
            setFetchedFilters(response.list)
        } catch (error: any) {
            console.error(error)
        }
    }
    const init = async () => {
        try {
            const sortField = sortModel.length > 0 ? sortModel[0].field : "name"
            const sortMode = sortModel.length > 0 ? (sortModel[0].sort as "asc" | "desc") : "asc"

            const filters: QueryParameters = {
                filters: filterModel.items
                    .filter(item => !!item.value)
                    .map(item => ({
                        field: item.field,
                        operation: (() => {
                            if (item.operator === "equals" || item.operator === "=") {
                                return item.field === "numProduct" ? FilterOperation.NumberEqual : FilterOperation.StringEqual
                            } else if (item.operator === "contains") {
                                return FilterOperation.StringContains
                            } else if (item.operator === "!=") {
                                return FilterOperation.NumberNotEqual
                            } else if (item.operator === ">") {
                                return FilterOperation.NumberGreaterThan
                            } else if (item.operator === "<") {
                                return FilterOperation.NumberLessThan
                            } else if (item.operator === ">=") {
                                return FilterOperation.NumberGreaterOrEqualThan
                            } else if (item.operator === "<=") {
                                return FilterOperation.NumberLessOrEqualThan
                            }
                            throw new Error(context.t.translate("unsupported_operator")` ${item.operator}`)
                        })(),
                        value: item.value as string
                    }))
            }

            const data = await hierarchyService.getAllExt({ ...filters, ...params })
            setHierarchies(data.list)
            const flattenedData = flattenTreeData(convertToTree(data.list, sortField as "name" | "description" | "status" | "numProduct", sortMode))
            setTreeData(flattenedData)

            const initialExpanded = new Set<string>()
            flattenedData.forEach(node => {
                initialExpanded.add(node.id)
            })
            setExpanded(initialExpanded)
        } catch (error: any) {
            console.error("Error fetching hierarchy data:", error)
            // Opcional: Mostrar un mensaje de error al usuario o manejarlo según sea necesario.
            setHierarchies([])
            setTreeData([])
            setExpanded(new Set<string>())
            setSnackbarMessage(error.message)
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
        }
    }

    useEffect(() => {
        init()
    }, [filterModel, sortModel, params.filters])

    useEffect(() => {
        const isVisible = (node: FlattenedTreeNode): boolean => {
            if (!node.parentId) {
                return true
            }
            if (!expanded.has(node.parentId)) {
                return false
            }
            const parent = treeData.find(row => row.id === node.parentId)
            return parent ? isVisible(parent) : false
        }

        const v = treeData.filter(isVisible)
        setVisibleRows(v)
    }, [expanded, treeData])

    useEffect(() => {
        const v = visibleRows.map(node => ({
            id: node.id,
            name: node.name,
            description: node.description,
            numProduct: node.numProduct,
            num_vulnerability: node.num_vulnerability,
            status: node.status,
            numVulns: totalVulns[node.id]?.critical +
                totalVulns[node.id]?.high +
                totalVulns[node.id]?.medium +
                totalVulns[node.id]?.low || 0,
            level: node.level,
            isLeaf: node.isLeaf
        }))
        setRows(v)
    }, [visibleRows])

    const handleGenerateReport = async (id: string) => {
        // HierarchyList
        const hierarchyParams: QueryParameters = { filters: [{ field: "path", operation: FilterOperation.StringContains, value: id }] }
        const hierarchyData = await hierarchyService.getAll(hierarchyParams)
        // ------------------------------
        // ProductList
        const productParams: QueryParameters = { filters: [{ field: "hierarchy", operation: FilterOperation.StringContains, value: id }] }
        const productData = await productService.getAll(productParams)
        // ------------------------------
        // VulnerabilityList
        const productIds = productData.list.map(product => product.id)
        const params2: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.UUIDArrayContains, value: productIds.toString() }] }
        const vulnerabilityList = await vulnerabilityService.getAllType(params2, "csv")
        // ------------------------------
        // Report
        const report = pdf(<VulnReportDocumentHierarchy hierarchyData={hierarchyData} productData={productData} vulnerabilityList={vulnerabilityList.list} />)
        const blob = await report.toBlob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
        // ------------------------------
    }

    const severityMap: { [key: string]: keyof SeverityCount } = {
        critical: "critical",
        high: "high",
        medium: "medium",
        low: "low"
    }

    interface SeverityCount {
        critical: number;
        high: number;
        medium: number;
        low: number;
        undefined: number;
    }

    interface TotalData extends SeverityCount {
        filters: FilterOption[]
    }

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: context.t.translate("name"),
            flex: 0.3,
            renderCell: params => {
                const node = treeData.find(row => row.id === params.row.id)
                const theme = useTheme()
                const navigate = useNavigate()

                // Maneja el clic en el nombre y redirige
                const handleClick = () => {
                    navigate(`./${params.row.id}`)
                }

                return node
                    ? (
                        <Box sx={{ pl: node.level * 2 }}>
                            {!node.isLeaf && (
                                <IconButton
                                    size="small"
                                    onClick={() => toggleExpand(node.id)}
                                >
                                    {expanded.has(node.id)
                                        ? (
                                            <ExpandMoreIcon color="primary" />
                                        )
                                        : (
                                            <ChevronRightIcon color="primary" />
                                        )}
                                </IconButton>
                            )}
                            <Button
                                onClick={handleClick}
                                style={{
                                    padding: 0,
                                    minWidth: 0,
                                    background: "none",
                                    border: "none",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bolder",
                                        fontFamily: "Griff",
                                        fontSize: "14px",
                                        color: theme.palette.primary.main,
                                        cursor: "pointer",
                                        "&:hover": {
                                            color: theme.palette.text.secondary // Color al pasar el mouse
                                        }
                                    }}
                                >
                                    {params.value}
                                </Typography>
                            </Button>
                        </Box>
                    )
                    : null
            },
            filterOperators: allowedStringOperators
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.25,
            renderCell: (params) => { return <HierarchyStatusColored value={params.value} /> },
            minWidth: 180
        },
        {
            field: "description",
            headerName: context.t.translate("description"),
            flex: 0.6,
            filterOperators: allowedStringOperators
        },
        {
            field: "numProduct",
            headerName: context.t.translate("products"),
            flex: 0.1,
            renderCell: params => {
                const node = treeData.find(row => row.id === params.row.id)
                return node

                    ? (
                        <Box sx={{ pl: 1 }}>
                            <ActionButton style={{ display: "flex", padding: "3px 10px", gap: 4 }} onClick={() => { setProductsOpen(true); handleProductsOpen(params) }} text={<><TbDeviceHeartMonitor size={25} />{params.value}</>} />
                        </Box>
                    )
                    : null
            },
            filterOperators: allowedNumberOperators
        },
        /*           {
            field: "vuln",
            headerName: context.t.translate("vulnerabilities"),
            flex: 0.1,
            renderCell: params => {
                const vulns = totalVulns[params.row.id] || {}
                console.log("ROWID", vulns)
                return (
                    <Box sx={{ pl: 1 }}>
                        {filters && <VulnerabiltyModal open={vulnsOpen} onClose={handleVulnClose} filters={vulns.filters} />}
                        <ActionButton style={{ display: "flex", padding: "3px 10px", gap: 4 }} onClick={() => { setVulnsOpen(true) }} text={<><BiBug size={25} />{params.value}</>} />
                    </Box>
                )
            }
        }, */
        {
            field: "num_vulnerability",
            headerName: context.t.translate("total_vulns"),
            type: "struct",
            flex: 0.3,
            renderCell: (params) => {
                return (<VulnerabilityIndicator
                    filters={[]}
                    critical={params?.value?.data?.critical ? params?.value?.data?.critical : 0}
                    high={params?.value?.data?.high ? params?.value?.data?.high : 0}
                    medium={params?.value?.data?.medium ? params?.value?.data?.medium : 0}
                    low={params?.value?.data?.low ? params?.value?.data?.low : 0}
                    none={params?.value?.data?.undefined ? params?.value?.data?.undefined : 0}
                    isHierarchy={true}
                    hierarchy={params.row.id}
                />)
            }
        }, {
            field: "Actions",
            type: "actions",
            headerName: context.t.translate("actions"),
            headerAlign: "center",
            getActions: (params) => {
                const vulns = totalVulns[params.row.id] || {}
                const totalVulnsCount = (vulns.critical || 0) + (vulns.high || 0) + (vulns.medium || 0) + (vulns.low || 0)

                const acts: React.JSX.Element[] = []
                if (ability.can("update", "Hierarchy")) {
                    acts.push(
                        <GridActionsCellItem
                            key="Edit"
                            icon={<MdCloudDownload style={{ fontSize: "20px" }} />}
                            label={context.t.translate("edit")}
                            disabled={totalVulnsCount === 0}
                            onClick={() => handleGenerateReport(params.row.id)}
                        />
                    )
                }
                return acts
            }
        }
    ]

    const handleFilterModelChange = (model: GridFilterModel) => {
        setFilterModel(model)
    }

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model)
    }
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }

    const [isLoading, setIsLoading] = useState(false)
    const handleFilterApplied = (filter: Filter) => {
        // Convertimos los filtros de 'Filter' a 'FilterOption' para que coincidan con el formato de 'QueryParameters'
        const newFilters: FilterOption[] = filter.filters.map((f) => ({
            field: f.Field, // Mapear el campo
            operation: filterOperationMap[f.FilterOperation], // Convertimos el FilterOperation de tipo numérico a cadena
            value: f.Value.join(",") // Asumimos que 'Value' es un array de strings y lo convertimos a una cadena
        }))

        setParams({ filters: newFilters })
    }
    const handleFilterDeselected = () => {
        setParams({ filters: [] })
    }

    return (
        <><Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
            <FilterDropdown filters={fetchedFilters} onFilterApplied={handleFilterApplied} onFilterDeselected={handleFilterDeselected} />

            <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                <Grid item>
                    <Typography
                        color={theme.palette.text.secondary}
                        fontSize="45px"
                        fontWeight="bolder"
                        fontFamily="Griff"
                    >
                        {context.t.translate("hierarchy")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        color={theme.palette.text.secondary}
                        fontSize="15px"
                        fontWeight="bolder"
                        fontFamily="Griff"
                    >
                        {context.t.translate("sections.productHierarchies")}
                    </Typography>
                </Grid>
            </Grid>
            <FormGroup>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <Can I="create" a="Hierarchy">
                        <ActionButton onClick={() => navigate("./management")} text={context.t.translate("hierar_manage")} />
                    </Can>

                    <Grid item>
                        <Can I="create" a="Hierarchy">
                            <ActionButton onClick={() => navigate("./add")} text={context.t.translate("hierar_node")} />
                        </Can>
                    </Grid>
                </Grid>
            </FormGroup>
        </Grid>
            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <Grid>
                <Tabs value={tabIndex} onChange={handleTabChange} centered sx={{ paddingBottom: "20px" }}>
                    <Tab label="list" icon={<FormatListBulletedIcon />} />
                    <Tab label="tree" icon={<AccountTreeIcon />} />
                </Tabs>

                {tabIndex === 0 && (
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid>
                            <StatsCard title="" entity="Hierarchy" />
                        </Grid>
                        {isLoading
                            ? (
                                <Skeleton variant="rounded" width="100%" height={(rows.length + 1) * 52 + 80} />
                            )
                            : (
                                <TableContainer component={Paper}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        autoHeight
                                        filterMode="server"
                                        filterModel={filterModel}
                                        onFilterModelChange={handleFilterModelChange}
                                        sortingMode="server"
                                        sortModel={sortModel}
                                        onSortModelChange={handleSortModelChange}
                                    />
                                </TableContainer>
                            )}
                        <ProductsModal open={productsOpen} onClose={handleProductsClose} productId={products?.id || null} columns={cols} />
                    </Grid>
                )}
                {tabIndex === 1 && (
                    <Grid>
                        <HierarchyCard hierarchies={hierarchies} />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export { HierarchyList }
export default HierarchyList
