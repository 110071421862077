
const en = {
    languages: {
        en: "English",
        es: "Spanish"
    },
    settings: {
        languageChange: "Change language",
        languageCurrent: "Chosen language"
    },
    addMessage: "Receive a message",
    add: "Add",
    messages: {
        one: "You have one message",
        other: "You have {count} messages"
    },
    max_monitoring: "Max Monitoring",
    details: "Details",
    vulnerability: "Vulnerability",
    products: "Products",
    tree: "Tree",
    snapshot: "Snapshot",
    tabs: "Tabs",
    unknown_status: "Unknown Status",
    downloads: "Downloads",
    dependencies: "Dependencies",
    mitigate: "Mitigate",
    mitigate_vuln: "This vulnerability is present in the following products. Please select the one in which you wish to mitigate it.",
    confirm_mitigation: "Confirm Mitigation",
    are_you_sure_mitigate: "Are you sure you want to mitigate the selected products?",
    buffer_desc: "Buffer overflow issues.",
    format_desc: "Errors in format strings.",
    integer_desc: "Errors in integer operations.",
    macros_desc: "Use of unsafe macros in C/C++.",
    misc_desc: "Other miscellaneous vulnerabilities.",
    race_desc: "Race conditions in concurrent systems.",
    pointer_desc: "Errors in pointer management.",
    sev_0: "No risk",
    sev_1: "Low",
    sev_2: "Moderate",
    sev_3: "High",
    sev_4: "Critical",
    sev_5: "Very critical",

    desc_0: "Not a vulnerability.",
    desc_1: "Possible issue, but low impact.",
    desc_2: "There may be a risk if not handled properly.",
    desc_3: "Likely vulnerability that requires review.",
    desc_4: "High probability of exploitation and major security impact.",
    desc_5: "Severe vulnerability that must be fixed immediately.",
    calendar: "Calendar",
    add_reminder: "Add reminder",
    reminders: "Reminders",
    no_reminders_message: "You don't have any reminders yet. You can add one by clicking here.",
    frequency: "Frequency",
    // dashboard
    dash_assess_finished: "Finished tests",
    dash_vulner_mitigated: "Mitigated vulnerabilities",
    dash_vulner_nomitigated: "Unmitigated vulnerabilities",
    dash_assess: "Assessments",
    dash_vulner: "Vulnerabilities",
    dash_product: "Products",
    dash_assess_byproduct: "By product",
    dash_assess_bystatus: "By status",
    dash_assess_bytype: "By type",
    dash_vulner_criticity: "Criticality",
    dash_vulner_state: "State",
    dash_vulner_mitigaciones: "Mitigations",
    dash_product_bytype: "By state",
    dash_export: "Export",
    dash_vulner_title: "Mitigated vs. unmitigated vulnerabilities",
    // Products
    product_newproduct: "Add new product",
    product_newtag: "Manage tags",
    product_newprovider: "Add provider",
    product_name: "Name",
    product_version: "Version",
    product_type: "Type",
    product_type_application: "A product of the “Application” type refers to a software application that runs on an operating system or a specific environment. These products are designed to perform specific tasks and can include web, desktop, or mobile applications.",
    product_type_library: "A product of the “Library” type is a code library that is used to add specific functionalities to other applications or products. Libraries are not executable by themselves but provide functions, classes, or methods that can be used by applications integrating them.",
    product_type_firmware: "A product of the “Firmware” type refers to specialized software integrated into hardware devices. Firmware is designed to control and manage specific hardware and is commonly found in electronic devices such as routers, printers, cameras, and more.",
    product_type_os: "A product of the “Operating System” type refers to a complete operating system, such as Linux, Windows, macOS, etc. The operating system manages the hardware and system resources, allowing applications and users to interact with the device.",
    product_type_container: "A product of the “Container” type refers to a software unit that packages an application and its dependencies into a single container. This allows applications to run consistently in any environment, whether local or in the cloud. Containers are popular in microservices-based infrastructures.",
    product_type_device: "A product of the “Device” type refers to a physical device, such as a server, computer, IoT (Internet of Things) device, etc. These devices may be subject to firmware updates or interact with specific applications and operating systems.",
    product_description: "Description",
    product_client: "Client name",
    product_descrip: "Product description",
    product_save: "Save changes",
    product_tag: "Search Tag",
    product_addtag: "Add tag",
    product_createtag: "Create Tag",
    product_color: "Color",
    product_newname: "Product Name",
    product_location: "Product Location",
    product_details: "Details",
    // Provider
    providers: "Providers",
    provider_manage: "Manage provider",
    // Assessment
    assess_noption: "No options",
    asess_template_name: "Template name",
    assess_status: "Status",
    assess_date_contrac: "Contract date",
    assess_date_start: "Start date",
    assess_date_end: "End date",
    assess_new: "Add new assessment",
    assess_close: "Close",
    assess_comp: "Components",
    assess_scan: "Scanned at",
    assess_quali: "Quality Score",
    assess_metric: "SBOM Quality Metrics",
    // Benchmark
    bench_criti: "Criticity",
    // CRA compliance
    CRA_title: "Compliance Quiz",
    CRA_edit: "Edit Text",
    CRA_text: "Edit the Conclusion field of the compliance",
    CRA_save: "Save",
    CRA_cancel: "Exit",
    CRA_conclusion: "Conclusion",
    CRA_resume: "Resume compliance quiz",
    CRA_start: "Start your compliance quiz",
    CRA_view: "View response",
    CRA_quiz: "Quiz",
    CRA_details: "Details",
    CRA_total: "Total Score",
    CRA_closetext: "Cancel",
    CRA_text2: "You can't undo this operation",
    CRA_noquiz: "You have not completed any compliance quiz yet",
    CRA_noquiz2: "Take our quiz to know yout CRA compliance score",
    CRA_help: "The help request was sent successfully.",
    pending: "Pending answers",
    // Textos de views/assessment
    customize_this: "Customize this sections for your Assessment",
    // Textos de views/assessment/components
    vulnerabilities: "Vulnerabilities",
    CAF_vulnerabilities: "CAF vulnerabilities",
    criticity: "Criticity",
    there_are_no: "There are no mitigated vulnerabilities",
    // Textos de views/assessment/customactions
    realanalyze: "Reanalyze",
    SBOM_metrics: "SBOM Metrics",
    SBOM_updated: "SBOM Updated",
    components: "Components",
    SBOM_quality: "SBOM Quality Metrics",
    tooltip_select_two_products: "You need to select two products first",
    diff: "Difference",
    merge: "Merge",
    SBOM_editor: "SBOM editor",
    selected_products: "Selected products",
    select_two_or_none: "You have selected only one product. If you wish to use SBOMs that are already associated with a product, select two. If you prefer to upload new SBOMs, do not select any product.",
    will_compare_products: "You are about to compare the selected products.",
    will_merge_products: "You are about to merge the selected products.",
    quality_score: "Quality Score",
    scanned_at: "Scanned at",
    file_uploaded: "File uploaded successfully. Status changed correctly, please refresh the page.",
    file: "File",
    succes: "Succes",
    customize_text: "Customize text",
    // Textos de views/assessment/AssesmentCustomText.tsx
    custom_text: "Custom text cleared successfully!",
    error_saving: "Error saving custom text.",
    error_clearing: "Error clearing custom text.",
    introduction: "Introduction",
    scope1: "Scope",
    test_initialization: "Test Initialization",
    conclusions: "Conclusions",
    // Textos de views/assessment/modals
    max_height: "max-height 0.3s ease-in-out",
    // Textos de views/assessment/AssessmentsDetail
    error_generating: "Error generating the CRT report",
    date_start: "Start date",
    date_end: "End date",
    date_contrac: "Contract date",
    upload_sbom: "Upload SBOM",
    SBOM_approval: "After the SBOM is approved, it will be locked and cannot be modified. To enable editing again, the approval must be revoked.",
    approve_SBOM: "Approve SBOM",
    approve_SBOM_error: "Error approving SBOM",
    approve: "Approve",
    report: "Report",
    original: "Original",
    assessment_section: "Assessment Section",
    unsupported_file: "Unsupported file type",
    wrong_file: "Wrong file type uploaded",
    an_error: "An error occurred.",
    add_new: "Add new assessment",
    error_downloading: "Error downloading the file:",
    error_downloading_report: "Error downloading the report file.",
    error_updating: "Error updating assessment status and startDate:",
    failed_to_update: "Failed to update assessment status and startDate.",
    // Textos de views/assessment/AssessmentsForm
    invalid_date: "Invalid date",
    error_testing: "Error testing",
    // Textos de views/assessment/AssessmentList.tsx
    add_new_assess: "Add new assessment",
    reanalyze: "Reanalyze",
    analysis_request: "Analysis request successful!",
    // Textos de views/assessment/CVSSCalculator.tsx
    cvvs_3_1_calculator: "CVSS 3.1 Calculator",
    base: "Base",
    temporal: "Temporal",
    environmental: "Environmental",
    overall: "Overall",
    cvss_vector: "CVSS v3.1 Vector",
    information_about: "Information about CVSS",
    base_score: "Base Score Metrics",
    temporal_score: "Temporal Score",
    environmental_score: "Environmental Score Metrics",
    exploitability: "Exploitability Metrics",
    attack_vector: "Attack Vector (AV)*",
    attack_complexity: "Attack Complexity (AC)*",
    pribileges_required: "Privileges Required (PR)*",
    user_interaction: "User Interaction (UI)*",
    scope: "Scope (S)*",
    impact_metrics: "Impact Metrics",
    confidentiality_imparct: "Confidentiality Impact (C)*",
    integrity_impact: "Integrity Impact (I)*",
    availability_impact: "Availability Impact (A)*",
    exploit_code: "Exploit Code Maturity (E)",
    remediation_level: "Remediation Level (RL)",
    report_confidence: "Report Confidence (RC)",
    exploitability_metrics: "Exploitability Metrics",
    attack_vector_m: "Attack Vector (MAV)",
    attack_complexity_m: "Attack Complexity (MAC)",
    privileges_required: "Privileges Required (MPR)",
    user_interaction_m: "User Interaction (MUI)",
    scope_ms: "Scope (MS)",
    confidentiality_imparct_m: "Confidentiality Impact (MC)",
    integrity_impact_m: "Integrity Impact (MI)",
    availability_impact_m: "Availability Impact (MA)",
    impact_subscore: "Impact Subscore Modifiers",
    confidentiality_requirement: "Confidentiality Requirement (CR)",
    integrity_requirement: "Integrity Requirement (IR)",
    availability_requirement: "Availability Requirement (AR)",
    // Textos de views/assessment/SBOMEvolution.tsx
    sbom_evolution: "SBoM Evolution",
    back: "back",
    outdated_only: "Outdated only",
    product_comparation: "Product comparation",
    // Textos de views/assessment/SBOMQuality.tsx
    download_sbom: "Download SBOM",
    score_summary: "Score Summary",
    // Textos de views/assessment/SunburstView.tsx
    level: "level",
    item: "item",
    // Textos de views/component
    component_edit_section: "Component Edit Section",
    reference: "Reference",
    supressed: "Supressed",
    add_new_component: "Add new component",
    outdated: "Outdated",
    supressed_only: "Supressed only",
    add_component: "Add component",
    error_adding_cpe: "Error adding CPE",
    hardware: "Hardware",
    software: "Software",
    add_vulnerability: "Add vulnerability",
    there_are_no_unmitigated: "There are no unmitigated vulnerabilities",
    // Textos de apartado views/client
    licenses: "Licenses",
    add_new_client: "Add new client",
    user_logs: "User logs",
    // Textos de apartado views/compliance
    no_data_found: "No data found",
    open: "Open",
    saved_successfully: "Saved successfully",
    is_compliant: "Is compliant?",
    true: "True",
    false: "False",
    next: "Next",
    send_the_results: "Send the results",
    congratulations: "Congratulations, you completed all the questions of the quiz",
    do_you_want: "Do you want to send the results?",
    we_will_send: "We will send you the report with our conclusions.",
    sla_management: "SLA management",
    failed_to_fetch: "Failed to fetch hierarchy",
    unsupported_operator: "Unsupported operator:",
    total_vulns: "Total Vulns",
    edit: "Edit",
    hierarchy: "Hierarchy",
    no_vuln: "This hierarchy doesn´t have vulnerabilities",
    failed_to_save: "Failed to save hierarchy",
    failed_to_delete: "Failed to delete hierarchy",
    delete_node: "Delete node",
    are_you_sure: "Are you sure you want to delete selected item and its ",
    children: " children?",
    are_you_sure_delete: "Are you sure you want to delete selected node?",
    identity_status: "Identity status",
    breakdown_levels: "Breakdown levels",
    product_categories: "Product categories",
    category: "Category",
    manufacturers: "Manufacturers",
    update_status: "Update status",
    user_sessions: "User Sessions",
    login_in_last: "Login in last 2 days:",
    users_in_last: "Users in last 2 days:",
    dashboard: "Dashboard",
    issue_management: "Issue Management",
    jira_configuration: "Jira configuration saved successfully",
    jira_token_not: "Jira token not set",
    jira_webhook: "Jira webhook secret not set",
    error_fetching: "Error fetching client data:",
    jira_project: "Jira project created successfully",
    jira_project_restored: "Jira project restored successfully",
    // Textos que se encuentran en el apartado de dashboard (la mayoria):
    assessments: "Assessments",
    vuln_details: "Vulnerability Details",
    finished_tests: "Finished tests",
    mitigated_vulnerabilities: " Mitigated vulnerabilities",
    unmitigated_vulnerabilities: " Unmitigated vulnerabilities",
    by_product: "By product",
    by_status: "By status",
    by_type: "By type",
    by_state: "By state",
    mitigations: "Mitigations",
    mitigated_vs_unmitigated: "Mitigated vs Unmitigated vulnerabilities",
    hierarchy_graphic: "Detected vs In Triage vs Triage Finished vulnerabilities",
    number_of_vulnerabilities: "Number of vulnerabilities",
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
    none: "None",
    mitigated: "mitigated",
    unmitigated: "unmitigated",
    evolution_of_vulnerabilities: "Evolution of vulnerabilities",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    quarterly: "Quarterly",
    yearly: "Yearly",

    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    select_frequency: "Select frequency",
    select_day: "Select day",
    // Textos de views/assessment/Components/SecurityAdvisoriesTable
    securityAdvisories: "Affected products and solution",
    affectedVersions: "Affected versions",
    remediation: "Remediation",
    sourceDocument: "Security advisory source document",
    // Textos del apartado de Hierarchy
    manage_hierarchy: "Manage Hierarchy",
    name: "Name",
    description: "Description",
    actions: "Actions",
    // Textos que se encuentran en el apartado de Benchmark
    choose_two_products: "Choose two products to compare the vulnerabilities between them",
    select_product: "Select Product",
    sector: "Sector",
    industry: "industry",
    // Tabla del apartado de benchmark
    energy_and_electric: "Energy and electric networks",
    transport: "Transport",
    elevator: "Elevator",
    IOTDevice: "Iot device",
    automotive: "Automotive",
    health_Care: "Health care",
    // Textos que se encuentran en la pagina de CRA compliance
    summary: "Summary",
    security_requirement: "Security Requirement",
    vulnerability_management: "Vulnerability Management Requirement",
    conclusion: "Conclusion",
    export: "Export",
    quiz: "Quiz",
    total_score: "Total score",
    // textos dentro de la edicion de conclusiones en CRA Compliance
    edit_text: "Edit text",
    edit_the_conclusion: "Edit the Conclusion field of the compliance",
    // Textos dentro del apartado de quiz en CRA compliance
    save: "Save",
    cancel: "Cancel",
    are_you_sure_you: "Are you sure you want to exit without saving changes?",
    close_compliance: "Close Compliance",
    you_cant_undo: "You can't undo this operation",
    // Textos que se encuentran en la seccion de Notifications & alerts
    // policy_management: "Policy Management",
    // save_changes: "",
    notifications_title: "Notifications & Alerts",
    notifications: "Notifications",
    report_filters: "Report Filters",
    recieve_alerts: "Receive alerts when:",
    new_CVEs: "New CVEs are discovered",
    CVEs_are: "CVEs are updated",
    recieve_alerts_when_CVSS: "Receive alerts when CVSS is higher than:",
    alerts: "Alerts",
    enable: "Enable",
    recipient: "Recipient of the email",
    email_frequency: "Email frequency",
    // textos de Policy Management dentro del boton de la seccion de Policy Management
    add_new_policy: "Add new policy",
    // textos cuando editas una gestion de politica
    policy_name: "Policy Name",
    policy_add: "New Policy",
    policy_title: "Policy Detail",
    send_method: "Sending method",
    policy_conditions: "Conditions",
    policy_action_add_condition: "Add new condition",
    policy_action_add: "Add new policy",
    policy_notification_channels: "Notification channels",
    operator: "Operator",
    value: "Value",
    conditions: "Conditions",
    // Cuando añades una nueva politica
    violation_state: "Violation State",
    condition_subject: "Condition Subject",
    condition_operator: "Condition Operator",
    add_new_condition: "Add new condition",
    // Textos colocados en la seccin Account & Settings en orden
    user_information: "User Information",
    billing: "Billing",
    system_settings: "System settings",
    switch: "Switch between dark and light mode",
    tool_for_automatic_SBoM: "Tool for automatic SBoM generation, see details below",
    download: "Download",
    support: "Support",
    have_any_question: "Have any question in mind? Put in contact with our team!",
    check_subscription: "Check out our subscription plan and info in the following link",
    or_contact_us: "Or contact us through",
    subscription_plan: "Subscription plan",
    gold: "Gold",
    platinum: "Platinum",
    titanium: "Titanium",
    yearly_subscription: "Yearly subscription",
    CVEs_affecting: "CVEs affecting your software components",
    email_notifications: "Email notifications on CVE discovery or modification",
    track_multiple_software: "Track multiple Software Bill of Materials (BOMs)/manifests",
    limited: "Limited",
    unlimited: "Unlimited",
    on_demand_CVE: "On-demand CVE report generation via Web",
    customized: "Customized",
    custom_vulnerability_score: "Custom vulnerability score/prioritization and filtering",
    based_on_EPSS: "Based on EPSS and KEV",
    added_AI: "Added AI",
    added_AI_custom: "Added AI custom",
    CVE_summary: "CVE summary by severity, status, and software package",
    severity: "Severity level",
    SBoM_quality_metric: "SBoM Quality Metric Score",
    CVSS_calculator: "CVSS Calculator for adding a custom revised score in CVEs",
    referece_links: "Referece links to available patches, miigation and exploits",
    filter_based: "Filter based on Component or Status",
    custom_report_generation: "Custom report generation",
    customizable_hierarchy: "Customizable hierarchy of components",
    benchmark_comparasion: "Benchmark comparison between Sectors: energy, transport, etc...",
    product_and_test: "Product and test creation",
    with_support: "With support",
    integration_with_scanning: "Integration with scanning tools (Nessus, Acunetix, BurpSuite and Achilles)",
    integration_with_jira: "Integration with Jira for streamlined vulnerability issue tracking and management",
    software_BOM: "Software BOM/Manifest editor and revision management",
    comparison_of_changes: "Comparison of changes between build/releases (SBOM historic)",
    CVE_remediation: "CVE remediation suggestions using IA",
    myorbiktoolbox: "myorbikToolbox: SBoM generation via command line",
    adding_private: "Adding Private Vulnerability Repository",
    sending_reports: "Sending reports to clients",
    policy_management: "Policy Management",
    upgrade_plan: "Upgrade plan",
    // textos de Facturacion dentro de Account & Settings en orden
    you_are_currently_free: "You are currently on a Free membership.",
    ticketing: "Ticketing",
    ask_for_additional_assessment: "Ask for additional assessment or product creation to our team",
    you_are_currently_pro: "You are currently on a Pro membership of type ",
    add_new_ticket: "Add new ticket",
    // textos del menu cuando añades un nuevo ticket
    client_name: "Client name",
    type: "Type",
    status: "Status",
    // textos que estan dentro de la edicion de tickets ya creados
    client: "Client",
    due_date: "Due Date",
    notes: "Notes",
    License_validity: "License Validity Date",
    License_new: "New License",
    save_changes: "Save changes",
    // textos de licenses dentro de views
    loading: "Loading...",
    vendor: "Vendor",
    version: "Version",
    part: "Part",
    product: "Product",
    last_modified: "Last modified",
    created_at: "Created at",
    license_title: "License",
    max_products: "Max Products",
    max_assessments: "Max Assessments",
    include_toolbox: "Include Toolbox",
    without_toolbox: "Without Toolbox",
    create_license: "Create License",
    license_management: "License Management",
    add_new_license: "Add New License",
    // Vulnerabilities
    vulner_produc: "Product",
    vulner_vers: "Version",
    vulner_comp: "Component",
    vulner_criti: "Criticity",
    vulner_revis: "Revised Score",
    vulner_score: "Score",
    vulner_vector: "Vector",
    vulner_prio: "Priority",
    vulner_epss: "EPSS",
    vulner_kev: "KEV",
    vulner_nokev: "Not included in KEV catalog",
    vulner_sikev: "Included in KEV catalog",
    vulner_desc: "Description",
    vulner_review: "Review",
    vulner_assess: "Assess",
    vulner_addre: "Address",
    vulner_stat: "Status",
    vulner_just: "Justification",
    vulner_resp: "Response",
    vulner_det: "Details",
    vulner_weak: "Weaknessess (CWES)",
    vulner_meta: "Metadata",
    vulner_cvs: "CVSS3 Calculator",
    vulner_generator: "Generator",
    vulner_generator_time: "Generator Time",
    // Hierarchy
    hierar_level: "Hierarchy level",
    hierar_option: "No options",
    hierar_manage: "Manage hierarchy",
    hierar_node: "Add New Node",
    hierar_back: "Back",
    hierar_reset: "Reset",
    is_project: "Project",
    sla: {
        detail: "SLA Detail",
        clientName: "Client Name",
        name: "Name",
        days: "Days",
        enforcements: "Enforcements",
        critical: "critical:",
        high: "high:",
        medium: "medium:",
        low: "low:",
        checkCritical: "Check Critical",
        checkHigh: "Check High",
        checkMedium: "Check Medium",
        checkLow: "Check Low",
        productSave: "Save Product",
        new: "New SLA",
        description: "Description",
        criticalDays: "Critical Days",
        highDays: "High Days",
        mediumDays: "Medium Days",
        lowDays: "Low Days",
        addNew: "Add New SLA"
    },
    issueDetails: {
        title: "Issue Details",
        key: "Key",
        status: "Status",
        project: "Project",
        client: "Client",
        productName: "Product Name",
        productVersion: "Product Version",
        cpeName: "CPE Name",
        cpeVersion: "CPE Version",
        cve: "CVE",
        revisedScore: "Revised Score",
        justification: "Justification",
        response: "Response",
        details: "Details",
        webhookEvent: "Webhook Event"
    },
    hierarchy_tree: "Tree",
    jira_integration: "Jira integration",
    jira_url: "Jira URL",
    jira_email: "Jira admin user email",
    jira_account: "Jira admin user account id",
    jira_token: "Jira API token",
    jira_secret: "Jira webhook secret",
    jira_create_project: "Create myOrbik Jira project",
    jira_restore_project: "Restore myOrbik Jira project",
    cpe_title: "CPE Detail",
    cpe_search: "CPE Search",
    add_cpe: "Add CPE",
    component_title: "Component Detail",
    package_title: "Package Detail",
    no_date: "No date provided",
    read_all: "Read All",
    compliance: "Compliance",
    client_picker: "Choose a user",
    last_updated: "Last Updated",
    error_message: "An error occurred.",
    error_download: "An error occurred during the download.",
    epss_modal: "EPSS: The Exploit Prediction Scoring System predicts the likelihood that a vulnerability will be exploited within the next 30 days.",
    kev_modal: "KEV: The Known Exploited Vulnerabilities (KEV) catalog is a list maintained by CISA that identifies vulnerabilities with active real-world exploits, helping organizations prioritize the remediation of the most critical ones.",
    dialogs: {
        helpDialogTitleOffline: "README",
        helpDialogTextOffline: "This file contains the commands \"generate_sbom_offline.sh\" and \"generate_sbom_online.sh\" used for the creation of SBOMs on files, directories, or images and their subsequent management.",
        installationTitle: "Installation",
        portableBinaryLimitations: "Limitations & Requirements:",
        requirementsList: [
            "The scripts cannot be added to the PATH, so they must be executed from the same folder where they are located.",
            "A \"Linux\" device with Bash is required.",
            "Have git installed on the system.",
            "At least one tool for decompressing zip files is necessary."
        ],
        usageTitle: "Usage",
        usageRequirements: "Requirements:",
        usageRequirementsList: [
            "If you want to perform a remote execution, you must have access to the system.",
            "It is recommended to have an SSH key created on the system that will run the script. If not, the script will guide you through the creation process.",
            "You must have execution permissions for the script and read permissions (for files and images) or read and execute permissions if it's a directory.",
            "If the script is executed online, internet access is required for post-creation handling of the SBoM."
        ],
        executionExamplesList: [
            "For a normal execution",
            "For an automatic execution",
            "For execution on a system with IP 192.168.1.5"
        ],
        commands: "Commands",
        closeButtonText: "Close"
    },
    color_dialog: {
        title: "Color too dark",
        content: "This color is too dark and may affect the visibility of the text. We recommend choosing a lighter color to ensure better readability.",
        button: "Accept"
    },
    favorite: "Favorite",
    color: "Color",
    pricing: {
        title: "Get an attractive price here",
        subtitle: "Choose the perfect plan for your needs. Unlock powerful features and grow your business with our flexible pricing options.",
        priceSuffix: "/year"
    },
    system_notification: "System Notification",
    date: "Date",
    action: "Action",
    read_status: "Read Status",
    target_role: "Target Role",
    channels: "Channels",
    setting_check_subscription: "Check info for subscription",
    myorbiktoolbox_description: "My Orbik Toolbox is a custom terminal tool for uploading to Github. It is used for creating SBOMs (Software Bill of Materials) for files, directories, or images, and for their subsequent management. It can be executed both online and offline.",
    stepper: {
        title: "Steps to follow",
        download: "Download and unzip the file",
        step2: "Enter the following command in the terminal to grant execution permissions to the scripts",
        step3: "If you want to access the command from any directory",
        step1_description: "It contains two folders with the two tools (online and offline).",
        step3_description: "Open the bashrc file and write the export command in it."
    },
    myorbiktoolbox_commands: "The generated SBoM will be saved on the local device even if the analyzed file is on a remote system",
    myorbiktoolbox_commands2: "For different types of executions:",
    quizz_export: "Download report",
    quizz_send: "Send quiz results",
    quizz_status_title: "Current Status",
    question: "Question",
    answered: "Answered",
    compliant: "Compliant",
    by_policy: "By policy",
    by_license: "By license",
    user: "User",
    message: "Message",
    messages_text: "Messages",
    email: "Email",
    phone: "phone",
    location: "Location",
    users_distribution: "User's distribution",
    average_title: "Average Days to Resolution",
    discovered_vulns: "Discovered vulnerabilities",
    code_summary: " Code Analysis Summary",
    total_files: " Total files scanned:",
    total_code_lines: "Total lines of scanned code:",
    total_findings: "Total findings in code:",
    findings_summary: "Open Findings Summary",
    total_mitigated: "Total mitigated vulns:",
    total_tracked: "Total tracked findings:",
    total_triaged: "Total findings triaged:",
    username: "Username",
    provider: "Provider",
    admin: "Administrator",
    add_new_user: "Add new user",
    password: "Password",
    users: "Users",
    add_conclusion: "Add conclusion",
    license: "License",
    preferences_language: "UI Preferences & Language",
    no_timeline_data: "No timeline data available.",
    findings_filter: "Findings filters",
    delete_filter: "Delete filter",
    remove_filter_favorite: "Remove from favorites",
    add_filter_favorite: "Add to favorites",
    apply_filter: "Apply filter",
    saved_filters: "Saved filters",
    filters_description: "Access your recent and favorite filters",
    no_favorite_filters: "No favorite filters",
    no_recent_filters: "No recent filters",
    theme: "Theme",
    assessment_type: "Assessment Type",
    assessment_type_crt: "A Code Review Test (CRT) is an evaluation that focuses on reviewing the source code of an application or system to identify security vulnerabilities, bad practices, and possible implementation flaws. This type of test is performed by developers or security experts who search for weaknesses in the code.",
    assessment_type_logical_pentest: "A Logical Penetration Test (LogicalPentest) is a type of penetration test that not only evaluates the technical infrastructure but also the business logic behind an application or system. This can include evaluating how the system handles data flows, authentication, and authorization, looking for logical errors that could be exploited.",
    assessment_type_functional_test: "A Functional Test evaluates the features of a system or application to ensure it meets defined requirements. It focuses less on security and more on the correct operation of the system in terms of performance of its functions and features.",
    assessment_type_sca: "Software Composition Analysis (SCA) refers to an analysis that examines the third-party software libraries and components used in an application to identify possible security vulnerabilities. SCA focuses on verifying if any of the external dependencies contain known security flaws.",
    assessment_type_port_scan: "A Port Scan is an assessment that analyzes open ports on a network to identify potential access points or vulnerabilities in the system. It is performed to detect exposed services that could be exploited by attackers.",
    assessment_type_network_scan: "A Network Scan is a scan performed at the network level to identify connected devices, open ports, and active services within a network infrastructure. It may also include the detection of potential vulnerabilities in the configuration of the network, such as routers, switches, or firewalls.",
    assessment_type_web_scan: "A Web Scan focuses on identifying vulnerabilities in web applications. It uses tools and techniques to discover issues like SQL injection, XSS, and other common risks associated with websites.",
    assessment_dates: "Assessment dates",
    assessment_type_container_scan: "This type of assessment analyzes software containers to identify insecure configurations or known vulnerabilities. It focuses on evaluating the security of the containers, their dependencies, and the infrastructure supporting them.",
    assessment_type_threat_model: "Threat modeling is an approach to identify, understand, and mitigate potential threats to a system. This process helps predict how an attacker could compromise a system and take preventive measures before an attack occurs.",
    assessment_type_static_analysis: "Static code analysis, which examines the code of a system or application without executing it. This technique aims to identify vulnerabilities or coding errors before the system is deployed or run.",
    assessment_type_dynamic_analysis: "Dynamic analysis of a system or application that is performed while it is running. Through real-time testing, it seeks to identify vulnerabilities that can only be detected when the system is active and functioning.",
    template_name: "Template Name",
    preview: "Preview",
    labelTemplateProperties: "Template Properties",
    descriptionTemplateProperties: "Customice the properties of the assessments you want to create as a Template",
    tooltipUploadLogo: "Upload Logo",
    labelVulnerCriticity: "Vulnerability Criticity",
    labelCVSSMinimum: "CVSS minimum",
    add_template: "Add Template",
    custom_logo: "Custom Logo",
    new_template: "New Template",
    dropped: "Items dropped",
    items_dropped: "This is where the items are dropped",
    content: "Content",
    drag_and_drop: "Drag and Drop the contents to create a custom assessment.",
    available_items: "Available items",
    choose: "Choose from the available page",
    add_sla: "Add New SLA",
    vendor_project: "Vendor / Project",
    KEV_details: "KEV Details",
    KEV_error: "An error occurred while fetching KEV data.",
    date_added: "Date Added",
    ransomware_campaign: "Ransomware Campaign",
    required_action: "Ransomware Campaign",
    no_action: "No action specified",
    no_notes: "No notes available.",
    policy_logs: "Policy Logs",
    cvss_4: "CVSS v4.0",
    export_pdf: "Export to PDF",
    phone_error: "Invalid phone number format",
    lastname: "LastName",
    last_week: "Last 7 Days",
    last_month: "Last Month",
    last_year: "Last Year",
    all: "All",
    assigned_tags: "Assigned tags",
    tags: "Tags",
    tag_no_products: "This tag is not associated with any products.",
    tags_details: "Tags details",
    tags_products: "Associated Products",
    recent_favorites: "Recent & favorites",
    recent_tags: "Recent Tags",
    favorite_tags: "Favorite tags",
    no_recent_tags: "No recent tags",
    recents: "Recents",
    no_favorite_tags: "No favorite tags",
    policies: "Policies",
    issues: "Issues",
    add_new_tag: "Add New Tag",
    no_products: "No products available to display.",
    no_vulns: "No vulnerabilities available to display.",
    account_settings: "Account & Settings",
    title: "Title",
    detected: "Detected",
    in_triage: "In triage",
    triage_finish: "Triage Finish",
    top5_products: "Top 5 Vulnerable Products",
    top5_assessments: "Top 5 Vulnerable Assessments",
    top5_components: "Top 5 Vulnerable Components",
    top5_tagged: "Top 5 Tagged Products",
    FAQ: " Frequently Asked Questions",
    sections: {
        reportsCalendar: "Check the calendar for completed reports and their dates. If you have scheduled recurring reports, the tool will remind you when your next test is due.",
        vulnerabilitiesList: "View all identified vulnerabilities in the products registered on the platform. Stay informed about potential risks and take action to mitigate them.",
        productInventory: "Browse the inventory of products registered on the platform. Filter by vendor or view the full list to easily manage your assets.",
        productProviders: "Browse the inventory of products registered on the platform filtered by providers. Filter by vendor or view the full list to easily manage your assets.",
        productHierarchies: "Group and organize products at different hierarchical levels for a more structured and efficient management.",
        reportsAndAnalysis: "Access reports linked to your products. Upload SBOMs for analysis, integrate results from third-party vulnerability detection tools, and stay in control of your asset security.",
        sectorBenchmark: "Compare key metrics with other clients on the platform to gain an industry-wide perspective. Use these insights to assess and improve your cybersecurity posture.",
        complianceAndRegulations: "Access questionnaires designed to guide regulatory compliance in your industry. Ensure your organization follows best practices and applicable regulations.",
        settings: "Customize the platform to suit your needs. Configure notifications, change the language, and adjust other settings to enhance your user experience.",
        tagManagement: "Create and manage custom tags to assign to products. Improve organization and make searching easier with more precise filtering.",
        components: "View the list of components extracted from SBOMs and their associated vulnerabilities. Gain a detailed overview of the security status of each component.",
        reportTemplates: "Create custom report templates and apply them when generating new analyses. Ensure each report follows the format and level of detail you require."
    },
    templates: "Templates",
    benchmark: "Benchmark",
    switch_plan: "Contact us to switch plan",
    current_plan: "Current plan:",
    no_answer: "No answer",
    add_conclusions: "Add conclusions",
    unknown_question: "Unknown question",
    // Git integration
    git_integration: "Git integration",
    git_token: "Git product repository token",
    git_save: "Save",
    git_token_not: "Git product repository token not found",
    git_ok: "Token saved"

}

export default en
