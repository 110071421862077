import { CafVulnStatus, VulnerabilityState } from "@models/Vulnerability"

export const VulnerabilityStateColors: Record<VulnerabilityState, string> = {
    detected: "#8dd3c7", // baby blue
    in_triage: "#e49029", // mahogany orange
    exploitable: "#7940A5", // scarlet red
    resolved: "#4db04a", // malachite green
    false_positive: "orange", // peach brown
    not_affected: "#A9A9A9" // lilac grey
}

export const CafVulnStatusColors: Record<CafVulnStatus, string> = {
    low_risk: "#66BB6A", // verde suave, riesgo bajo
    waiting_for_triage: "#FFA726", // naranja claro, en espera
    waiting_supplier_assessment: "#F06292", // rosa fuerte, en espera de proveedor
    waiting_caf_assessment: "#AB47BC", // púrpura medio, en espera de CAF
    waiting_for_remediation: "#42A5F5", // azul claro, en espera de solución
    finish_with_remediation: "#388E3C", // verde oscuro, solucionado
    finish_with_mitigation: "#4CAF50", // verde medio, mitigado
    finish_with_mitigation_and_remediation: "#2E7D32", // verde más oscuro, mitigado y solucionado
    finish_without_remediation: "#D4E157", // amarillo verdoso, finalizado sin solución
    not_affected: "#B0BEC5" // gris azulado claro, no afectado
}
