import React, { useContext, useEffect, useState } from "react"
import { Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { convertModelDefToGridColDef, CustomAction, GenericTable } from "@components/common/tables/index"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import ClientTableDefinition from "@components/client/ClientTableDefinition"
import ActionButton from "@components/common/Button/ActionButton"
import LocalPoliceIcon from "@mui/icons-material/LocalPolice"
import { FilterOperation } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"

const ClientList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const navigate = useNavigate()
    const theme = useTheme()
    const [cols, setCols] = useState<GridColDef[]>([])
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ClientList" })
    }, [])
    const clientService = useContext(ServicesContext).clientService
    const licenseService = useContext(ServicesContext).licenseService
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ClientTableDefinition, ability))
    }, [])

    const handleOpenLicense = async (id: string) => {
        // TODO: get license_id by client_id
        const response = await licenseService.getAll({ filters: [{ field: "client_id", operation: FilterOperation.EnumEqual, value: id }] })
        if (response.count === 1) {
            navigate(`../license/${response.list[0].id}`)
        }
    }

    const customActions = (params: GridRowParams<Client>) => {
        const actions: CustomAction[] = []
        actions.push(
            {
                icon: <Can key="templates" I="create" an="License">
                    <Tooltip title= {context.t.translate("license")}>
                        <IconButton size="small" component="label" onClick={() => handleOpenLicense(params.row.id as string)}>
                            <LocalPoliceIcon />
                        </IconButton>
                    </Tooltip>
                </Can>,
                visualize: true
            }
        )

        return actions
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>

            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("client")}
                </Typography>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <Can I="read" a="License">
                        <ActionButton onClick={() => navigate("../license")} text= {context.t.translate("license")}/>
                    </Can>
                    <Can I="create" a="Client">
                        <ActionButton onClick={() => navigate("./add")} text= {context.t.translate("add_new_client")} />
                    </Can>
                </Grid>
            </Grid>

            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Client> entity="Client" columns={cols}
                    dataProvider={(filter) => clientService.getAll(filter)}
                    onEdit={(elem: Client) => navigate("./" + elem.id)}
                    onDelete={(elem: Client) => clientService.delete(elem.id)}
                    customActions={customActions}
                />
            </Grid >
        </Grid>
    )
}

export { ClientList }
export default ClientList
