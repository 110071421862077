import React from "react"
import { Grid, Skeleton } from "@mui/material"

const VulnerabilityEvolutionChartSkeleton: React.FC = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} container alignItems="center">
                <Grid item xs={6}>
                    <Skeleton variant="text" width="50%" height={40} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Skeleton variant="rectangular" width="100%" height={300} />
            </Grid>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
                {[...Array(5)].map((_, index) => (
                    <Grid key={index} item display="flex" alignItems="center" justifyContent="center" gap={2}>
                        <Grid><Skeleton variant="circular" width={15} height={15} /></Grid>
                        <Grid><Skeleton variant="text" width={60} height={20} /></Grid>

                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default VulnerabilityEvolutionChartSkeleton
