import React, { useContext, useEffect, useState } from "react"
import { Grid, useMediaQuery } from "@mui/material"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import Heatmap, { DateCount } from "./stats/Heatmap"
import Average, { AverageTime } from "./stats/Average"
import Metrics from "./stats/Metrics"
import AverageSkeleton from "./skeleton/AverageSkeleton"
import SampleHeatMapSkeleton from "./skeleton/AverageHeatmap"
import MetricsSkeleton from "./skeleton/AverageMetrics"

type DashboardStats = {
    average_resolution_time: AverageTime,
    VulnerabilityState: {
        detected: number,
        exploitable: number,
        resolved: number
    },
    MitigatedVulnerabilities: {
        critical: number,
        high: number,
        low: number,
        medium: number,
        none: number,
        unknown: number
    },
    UnmitigatedVulnerabilities: {
        critical: number,
        high: number,
        low: number,
        medium: number,
        none: number,
        unknown: number
    },
    MitigatedPercentage: number,
    AssessmentTotal: {
        crt: number,
        functional_test: number,
        logical_pentest: number,
        network_scan: number,
        sca: number,
        static_analysis: number
    },
    DailyVulnerabilities: DateCount[],
    TotalTrackedFindings: number,
    TotalFilesScanned: number,
    TotalTriagedFindings: number
}

const Stats: React.FC = () => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const isMobile = useMediaQuery("(max-width: 768px)")
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const statsService = useContext(ServicesContext).statsService
    const [data, setData] = useState<DashboardStats>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await statsService.getDashboardStats()
                setData(response)
            } catch (error: any) {
                setError(error)
                console.error("Error", error)
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={error?.message || context.t.translate("an_error")}
            />
            <Grid
                container
                spacing={2}
                direction={isMobile ? "column" : "row"} // Columna en móvil, fila en desktop
                justifyContent="space-between"
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                        borderRadius: "8px"
                    }}
                >
                    {data ? <Average averageTime={data.average_resolution_time} /> : <AverageSkeleton />}
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                        borderRadius: "8px"
                    }}
                >
                    {data ? <Heatmap dateCountData={data.DailyVulnerabilities} /> : <SampleHeatMapSkeleton />}
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minHeight: "100px",
                        borderRadius: "8px"
                    }}
                >
                    {data
                        ? (
                            <Metrics
                                tracked={data.TotalTrackedFindings}
                                triaged={data.TotalTriagedFindings}
                                mitigated={data.MitigatedPercentage}
                                totalFiles={data.TotalFilesScanned}
                            />
                        )
                        : (
                            <MetricsSkeleton />
                        )}
                </Grid>
            </Grid>
        </>
    )
}

export { Stats }
export default Stats
