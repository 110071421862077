import React, { useContext, useEffect, useState } from "react"
import { AlertColor, Box, Chip, Grid, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material"
import GenericModal from "@components/common/modals/GenericModal"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import { GridDeleteForeverIcon } from "@mui/x-data-grid"
import { Provider } from "@models/Provider"
import ServicesContext from "@context/ServicesContext"
import { ClientSelector } from "@components/client"
import { AbilityContext } from "@components/permissions"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

interface CreateProviderModalProps {
    open: boolean;
    onClose: () => void;
    onProviderCreated: ()=> void
}
const initValue: Provider = {
    id: "",
    name: "",
    description: "",
    provider_id: "",
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" }

}
const CreateProviderModal: React.FC<CreateProviderModalProps> = ({
    open,
    onClose,
    onProviderCreated
}) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [providers, setProviders] = useState<Provider[]>([]) // Lista de tags existentes
    const [activeTab, setActiveTab] = useState(0) // Controla la pestaña activa
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const [formProviderData, setFormProviderData] = useState<Provider>(initValue)
    const providerService = useContext(ServicesContext).providerService
    const clientService = useContext(ServicesContext).clientService
    const ability = useContext(AbilityContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [selectedClient, setSelectedClient] = useState<string>("")

    useEffect(() => {
        setFormProviderData(prevState => ({
            ...prevState,
            client: {
                ...prevState.client,
                id: selectedClient
            }
        }))
    }, [selectedClient])
    const fetchTags = async () => {
        try {
            const response = await providerService.getAll()
            setProviders([...response.list])
        } catch (e) {
            console.error("Error fetching tags:", e)
        }
    }
    const fetchClient = async () => {
        try {
            const client = await clientService.getAll()
            setSelectedClient(client.list[0].id)
        } catch (error: any) {
            console.error(error.message)
        }
    }
    useEffect(() => {
        if (!ability.can("*", "Client")) {
            fetchClient()
        }
    }, [])
    const handleSelectClient = (id: string | undefined) => {
        if (id) { setSelectedClient(id) }
    }
    useEffect(() => {
        if (open) {
            fetchTags()
        }
    }, [open])

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setFormProviderData({ ...formProviderData, [name]: value })
    }

    const handleCreateProvider = async () => {
        try {
            await providerService.create(formProviderData)

            // Limpia el formulario y cierra el modal
            setFormProviderData({
                id: "",
                name: "",
                description: "",
                provider_id: "",
                client: { id: "00000000-0000-0000-0000-000000000000", name: "" }
            })
            onProviderCreated()
            onClose()
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || "Error creating tag")
            setSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const handleDeleteProvider = async (providerId: string) => {
        try {
            await providerService.delete(providerId)
            const updatedTags = providers.filter(provider => provider.id !== providerId)
            setProviders(updatedTags)
            // Mostrar mensaje de éxito
            setSnackbarMessage("Provider deleted successfully")
            setSeverity("success")
            setSnackbarOpen(true)
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || "Error deleting tag")
            setSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    return (
        <GenericModal open={open} onClose={onClose} title={context.t.translate("product_newprovider")}>
            <CustomSnackbar
                message={snackbarMessage}
                onClose={() => setSnackbarOpen(false)}
                severity={severity}
                open={snackbarOpen}/>
            <Grid container spacing={2} >
                <Grid item container gap={2}>
                    {ability.can("*", "Client") && <ClientSelector onSelect={handleSelectClient} value={selectedClient}/>}
                    <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
                        <Tab label="Create provider" />
                        <Tab label="Delete providers" />
                    </Tabs>
                    <Box sx={{ width: "100%", marginTop: 2 }}>
                        {activeTab === 0 && (
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        variant="filled"
                                        label="Provider name"
                                        name="name"
                                        value={formProviderData.name}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        variant="filled"
                                        label={context.t.translate("description")}
                                        name="description"
                                        value={formProviderData.description}
                                        onChange={handleInputChange}
                                    />
                                </Grid>

                                <ActionButton
                                    onClick={handleCreateProvider}
                                    text="Create Tag"
                                    style={{ width: "100%", marginTop: "15px" }}
                                />
                            </Grid>
                        )}
                        {activeTab === 1 && (
                            <Box mt={2}>
                                <Typography fontSize="18px" fontWeight="bold" mb={2}>Existing Providers</Typography>
                                <Box
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                                        gap: "16px"
                                    }}
                                >
                                    {providers.map(provider => (
                                        <Chip
                                            key={provider.id}
                                            label={provider.name}
                                            style={{
                                                backgroundColor: "#ccc",
                                                color: "#000"
                                            }}
                                            onDelete={() => handleDeleteProvider(provider.id)}
                                            deleteIcon={
                                                <Tooltip title="Delete">
                                                    <GridDeleteForeverIcon style={{ color: "#000" }} />
                                                </Tooltip>
                                            }
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </GenericModal>
    )
}

export default CreateProviderModal
