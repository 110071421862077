import React from "react"
import { Box, Grid, Skeleton } from "@mui/material"

const MetricsSkeleton: React.FC = () => {
    const renderProgressSkeleton = () => (
        <Box sx={{ position: "relative", width: "100%" }}>
            <Skeleton
                variant="text"
                width={40}
                height={20}
                sx={{
                    position: "absolute",
                    top: -20,
                    left: "50%",
                    transform: "translateX(-50%)"
                }}
            />
            <Skeleton
                variant="rectangular"
                width="100%"
                height={10}
                sx={{
                    borderRadius: 5
                }}
            />
        </Box>
    )

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <Skeleton variant="text" width={200} height={40} sx={{ mb: 4 }} />

                <Grid container spacing={2}>
                    {[1, 2].map((item) => (
                        <Grid
                            key={item}
                            item
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid xs={6}>
                                <Skeleton variant="text" width={120} height={20} />
                            </Grid>
                            <Grid xs={6}>
                                <Skeleton variant="text" width={50} height={20} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Skeleton variant="text" width={200} height={40} sx={{ mb: 4 }} />

                <Grid container spacing={2}>
                    {[1, 2, 3].map((item) => (
                        <Grid
                            key={item}
                            item
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid xs={6}>
                                <Skeleton variant="text" width={120} height={20} />
                            </Grid>
                            <Grid xs={6}>
                                {renderProgressSkeleton()}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MetricsSkeleton
