import React, { useContext, useState } from "react"
import { AlertColor, Box, Checkbox, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext } from "@components/permissions"
import { ClientSelector } from "@components/client"
import { TagCategoryStateColors } from "@components/common/colors/TagCategoryStateColors"
import ServicesContext from "@context/ServicesContext"
import { getEnumKeys } from "@models/utils"
import Tag, { TagCategoryType } from "@models/Tag"
import { initValue } from "./TagsDetail"
export const TagCategoryTypeKeys = getEnumKeys(TagCategoryType)
export const TagCategoryTypeKeysValueOps = TagCategoryTypeKeys.map((key) => ({ label: key, value: TagCategoryType[key] }))
const TagForm: React.FC = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const tagService = useContext(ServicesContext).tagService
    const context = useContext(I18nContext)
    const ability = useContext(AbilityContext)
    const [formTagData, setFormTagData] = useState<Tag>(initValue)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const colors = [
        { label: "Yellow", value: "#F39C12" },
        { label: "Red", value: "#C0392B" },
        { label: "Green", value: "#27AE60" },
        { label: "Blue", value: "#2980B9" }
    ]

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const { name, value } = e.target
        setFormTagData({ ...formTagData, [name]: value })
    }

    const handleCreateTag = async () => {
        try {
            await tagService.create(formTagData)

            navigate(-1)
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || "Error creating tag")
            setSeverity("error")
            setSnackbarOpen(true)
        }
    }
    const handleSelectClient = (clientId: string | undefined) => {
        if (clientId) {
            setFormTagData(prevState => ({
                ...prevState,
                client: { id: clientId }
            }))
        }
    }

    return (
        <Box width="50%" margin="0 auto">
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={severity}
            />

            <Box mt={2}>
                <Grid container justifyContent="center" width="100%" direction="column">
                    <Grid item>
                        {ability.can("*", "*") && <ClientSelector value={formTagData.client.id} onSelect={(id) => handleSelectClient(id)} />}
                    </Grid>
                    <Grid item container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                variant="filled"
                                label="Tag name"
                                name="name"
                                value={formTagData.name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} marginTop="5px">
                            <TextField
                                select
                                required
                                fullWidth
                                variant="filled"
                                label={context.t.translate("category")}
                                name="category"
                                value={formTagData.category}
                                onChange={handleInputChange} // Ahora actualiza el estado correctamente
                            >
                                {TagCategoryTypeKeysValueOps.map((opt, idx) => (
                                    <MenuItem key={idx} value={opt.value}>
                                        <Typography
                                            fontFamily="Griff"
                                            fontWeight="bolder"
                                            sx={{ color: TagCategoryStateColors[TagCategoryType[opt.label]] }}
                                        >
                                            {opt.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("description")}
                            name="description"
                            value={formTagData.description}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item container direction="row">
                        <Grid xs={6}> <Grid item>
                            <Typography fontSize="18px" fontWeight="bolder" >{context.t.translate("product_color")}</Typography>
                        </Grid>
                        <Grid item xs width="80%">
                            <Select name="color" value={formTagData.color} variant="filled" onChange={handleInputChange} fullWidth sx={{
                                borderRadius: "8px",
                                "& .MuiSelect-icon": {
                                    color: theme.palette.primary.main // Asegura que la flecha sea visible
                                }
                            }}>
                                {colors.map((color, index) => (
                                    <MenuItem key={index} value={color.value}>
                                        <Typography sx={{ color: color.value }}>{color.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid></Grid>
                        <Grid xs={6}> <Grid item>
                            <Typography fontSize="18px" fontWeight="bolder" >{context.t.translate("favorite")}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Checkbox checked={formTagData.favorite} onChange={(e) => setFormTagData({ ...formTagData, favorite: e.target.checked })}></Checkbox>
                        </Grid></Grid>

                    </Grid>
                </Grid>
                <ActionButton
                    onClick={handleCreateTag}
                    text="Create Tag"
                    style={{ width: "100%", marginTop: "15px" }}
                    dataTestId="create-tag-button"
                />
            </Box>

        </Box>
    )
}

export default TagForm
