import React, { useContext } from "react"
import { Box, Grid } from "@mui/material"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { I18nContext } from "I18nProvider"
import { useStatsData } from "./useStatsData"
import TopItemsList from "./components/TopItemList"

interface ProductStatsProps {
    timeRange?: string;
}

const ProductStats: React.FC<ProductStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const {
        byType,
        byPolicies,
        byLicenses,
        typeNumber,
        policyNumber,
        licenseNumber,
        topItems,
        isLoading
    } = useStatsData("Product", timeRange)

    if (isLoading || !context) return null

    return (
        <Grid
            container
            spacing={2}
            sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}
        >
            <Grid item xs={12} sm={6} sx={{ flex: 1, minWidth: "220px" }}>
                <Box>
                    <GenericDonutChart
                        title={context.t.translate("by_type")}
                        data={byType || []}
                        dataType="array"
                        number={typeNumber || 0}
                        footer="TYPES"
                    />
                </Box>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ flex: 1, minWidth: "220px" }}>
                <Box>
                    <GenericDonutChart
                        title={context.t.translate("by_license")}
                        data={byLicenses || []}
                        dataType="array"
                        number={licenseNumber || 0}
                        footer="LICENSES"
                    />
                </Box>
            </Grid>

            {topItems && topItems.length > 0 && (
                <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                        flex: 1,
                        minWidth: "300px",
                        display: "flex",
                        justifyContent: "flex-end" // Empuja los top items a la derecha
                    }}
                >
                    <TopItemsList
                        title={context.t.translate("top5_products")}
                        items={topItems}
                        valueKey="vulnerability_count"
                        nameKey="product_with_version"
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default ProductStats
