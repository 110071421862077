import Policy, { ActionsType, ConditionOperator, ConditionSubject } from "@models/Policy"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import { FilterOperation } from "@utils/queryParams"
import PolicyNameNavigate from "./PolicyNameNavigate"
import ActionColumn from "./ActionColumn"
import EventTypeColumn from "./EventTypeColumn"

const PolicyOperatorKeys = getEnumKeys(FilterOperation)
const PolicyOperatorKeysValueOps = PolicyOperatorKeys.map((key) => ({ label: key, value: FilterOperation[key] }))

const ViolationStateKeys = getEnumKeys(ActionsType)
const ViolationStateKeysValueOps = ViolationStateKeys.map((key) => ({ label: key, value: ActionsType[key] }))

const ConditionOperatorKeys = getEnumKeys(ConditionOperator)
const ConditionOperatorKeysValueOps = ConditionOperatorKeys.map((key) => ({ label: key, value: ConditionOperator[key] }))

const ConditionSubjectKeys = getEnumKeys(ConditionSubject)
const ConditionSubjectKeysValueOps = ConditionSubjectKeys.map((key) => ({ label: key, value: ConditionSubject[key] }))

const PolicyTableDefinition: ModelDefinition<Policy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 2, render: PolicyNameNavigate },
        { name: "description", label: "Description", type: "string", flex: 2 },
        { name: "policy_actions", label: "Action", type: "string", flex: 2, render: ActionColumn },
        { name: "event_type", label: "Event type", type: "string", flex: 2, render: EventTypeColumn }

    ]
}
const PolicyTableDefinitionES: ModelDefinition<Policy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Nombre", type: "string", flex: 2, render: PolicyNameNavigate },
        { name: "description", label: "Descripción", type: "string", flex: 2 },
        { name: "action", label: "Acción", type: "string", flex: 2, render: ActionColumn },
        { name: "event_type", label: "Tipo de evento", type: "string", flex: 2, render: EventTypeColumn }

    ]
}

export { PolicyOperatorKeysValueOps, ViolationStateKeysValueOps, PolicyOperatorKeys, ViolationStateKeys, ConditionOperatorKeysValueOps, ConditionSubjectKeysValueOps, PolicyTableDefinitionES, PolicyTableDefinition }
export default PolicyTableDefinition
