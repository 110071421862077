import React, { useContext } from "react"
import { Box, Grid, LinearProgress, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

interface ProgressBarProps {
    value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
    const theme = useTheme()
    return (
        <Box sx={{ position: "relative", width: "100%" }}>
            <Typography
                variant="body2"
                sx={{
                    position: "absolute",
                    top: -20,
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontWeight: "bold"
                }}
            >
                {value}%
            </Typography>
            <LinearProgress
                variant="determinate"
                value={value}
                sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: theme.palette.primary.main
                    }
                }}
            />
        </Box>
    )
}

type MetricsProps = {
    triaged: number,
    tracked: number,
    mitigated: number,
    totalFiles: number
}
const Metrics: React.FC<MetricsProps> = ({ triaged, tracked, mitigated, totalFiles }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    return (
        <Grid container spacing={4}>
            {/*  <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold" sx={{ mb: 4 }}>
                    {context.t.translate("code_summary")}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" fontWeight="bold">
                            {context.t.translate("total_files")}
                        </Typography>
                        <Typography variant="body2">{totalFiles}</Typography>
                    </Grid>
                    {/* To Do: Queda pendiente de hacer el total code y total findings para un futuro */}
            {/* <Grid item container justifyContent="space-between">
                        <Typography variant="body2" fontWeight="bold">
                            {context.t.translate("total_code_lines")}
                        </Typography>
                        <Typography variant="body2">60</Typography>
                    </Grid>

                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" fontWeight="bold">
                            {context.t.translate("total_findings")}
                        </Typography>
                        <Typography variant="body2">899</Typography>
                    </Grid>
                </Grid>
            </Grid> */}

            {/* Open Findings Summary */}
            <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold" sx={{ mb: 4 }}>
                    {context.t.translate("findings_summary")}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item container alignItems="center" justifyContent="space-between">
                        <Grid xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                                {context.t.translate("total_mitigated")}
                            </Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Box sx={{ width: "100%" }}>
                                <ProgressBar value={mitigated} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems="center" justifyContent="space-between">
                        <Grid xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                                {context.t.translate("total_tracked")}
                            </Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Box sx={{ width: "100%" }}>
                                <ProgressBar value={tracked} />
                            </Box>
                        </Grid>

                    </Grid>

                    <Grid item container alignItems="center" justifyContent="space-between">
                        <Grid xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                                {context.t.translate("total_triaged")}
                            </Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Box sx={{ width: "100%" }}>
                                <ProgressBar value={triaged} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Metrics
