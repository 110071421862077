import React, { useContext, useEffect, useState } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Vulnerability, { CVSS3Severity, getCVSS3Criticality } from "@models/Vulnerability"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"

interface SectionProps {
    vulnerabilities: Vulnerability[];
    externalData: any;
    customConclusion?: string;
    template?: Template;
    lang?: string;
}
const i18n = {
    es: {
        title_conclusions: "5. Conclusions",
        conlcusions1: "Detailed breakdown of vulnerabilities:",
        conlcusions2: " Prioritize critical and high vulnerabilities to mitigate the most severe risks on your network.",
        critical: "critical",
        high: "high",
        medium: "medium",
        low: "low",
        and: "and",
        none: "with no severity."
    },
    en: {
        title_conclusions: "5. Conclusions",
        conlcusions1: "Detailed breakdown of vulnerabilities:",
        conlcusions2: " Prioritize critical and high vulnerabilities to mitigate the most severe risks on your network.",
        critical: "critical",
        high: "high",
        medium: "medium",
        low: "low",
        and: "and",
        none: "with no severity."
    }
} as const
const Conclusion: React.FC<SectionProps> = ({ vulnerabilities, customConclusion, template, lang }) => {
    const [conclusionMessage, setConclusionMessage] = useState<string>("No vulnerabilities have been found.")
    const [summary, setSummary] = useState<{ [key: string]: number }>({})
    const [totalVulnerabilities, setTotalVulnerabilities] = useState<number>(0)
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    useEffect(() => {
        const analyzeVulnerabilities = () => {
            const counts: { [key in CVSS3Severity]: number } = {
                critical: 0,
                high: 0,
                medium: 0,
                low: 0,
                none: 0,
                [CVSS3Severity.Unknown]: 0
            }

            // Classify vulnerabilities
            vulnerabilities.forEach((vuln) => {
                const severity = getCVSS3Criticality(vuln.score)
                if (severity in counts) {
                    counts[severity]++
                }
            })

            const total = Object.values(counts).reduce((sum, value) => sum + value, 0)
            setSummary(counts)
            setTotalVulnerabilities(total)

            // Generate conclusion message
            if (total === 0) {
                return "No vulnerabilities have been found."
            }

            const criticalityMessages: string[] = []
            if (counts.critical > 0) criticalityMessages.push(`${counts.critical} critical`)
            if (counts.high > 0) criticalityMessages.push(`${counts.high} high`)
            if (counts.medium > 0) criticalityMessages.push(`${counts.medium} medium`)
            if (counts.low > 0) criticalityMessages.push(`${counts.low} low`)

            const vulnerabilitiesSummary = criticalityMessages.join(", ").replace(/, ([^,]*)$/, " and $1")
            return `A total of ${total} vulnerabilities have been identified on the network, classified as follows: ${vulnerabilitiesSummary}. It is crucial to prioritize addressing critical and high vulnerabilities.`
        }

        setConclusionMessage(analyzeVulnerabilities())
    }, [vulnerabilities])

    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template} />
            <View style={{ ...styles.section, flexDirection: "row" }}>
                <View style={{ width: "100%" }}>
                    <Text style={{ ...styles.titulo }}>{i18n[locale].title_conclusions}</Text>
                    <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>{conclusionMessage}</Text>
                    {totalVulnerabilities > 0 && (
                        <View style={{ marginTop: 10 }}>
                            <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>
                                {i18n[locale].conlcusions1}{" "}
                                {summary.critical} {i18n[locale].critical}, {summary.high} {i18n[locale].high}, {summary.medium} {i18n[locale].medium}, {summary.low} {i18n[locale].low}, {i18n[locale].and} {summary.none} {i18n[locale].none}
                            </Text>
                            <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>
                                {i18n[locale].conlcusions2}
                            </Text>
                        </View>
                    )}
                    {customConclusion && (
                        <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>{customConclusion}</Text>
                    )}
                </View>
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}

export { Conclusion }
