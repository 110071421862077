/* eslint-disable space-before-function-paren */

import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class FileService extends Service<File> {
    public constructor() {
        super(process.env.REACT_APP_API, "api/v1/file", MediaTypes.FILE)
    }

    public downloadFile = (id: any, type: string, format: string = "") => {
        return this.http.get(`/${this.path}?ID=${id}&type=${type}&format=${format}`)
    }

    public getSunshineByAssessment = (assessmentID: string) => {
        return this.http.get(`/${this.path}/${assessmentID}/sunshine`)
    }

    public uploadFile = (id: any, type: string, data: File) => {
        return this.http.post(`/${this.path}?ID=${id}&type=${type}`, data)
    }

    public uploadFileV2 = (id: any, type: string, mediaType: MediaTypes, data: File) => {
        return this.http.postFile(`/${this.path}?ID=${id}&type=${type}`, data, mediaType)
    }

    public scoreBom = (id: any, data: File) => {
        return this.http.post(`/${this.path}Quality?ID=${id}`, data)
    }

    public downloadOrbikTool = (type: string, os: string) => {
        return this.http.get(`/${this.path}/download?platform=${os}&version=${type}`)
    }

    public sunshine = (assessmentID: any) => {
        return this.http.get(`/${this.path}/${assessmentID}/sunshine`)
    }
}

export { FileService }
export default FileService
