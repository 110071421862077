import React, { useContext, useEffect, useState } from "react"
import { AlertColor, Chip, FormGroup, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowClassNameParams, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { FaTags } from "react-icons/fa"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { CustomAction, GenericTable } from "@components/common/tables/index"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Product } from "@models/index"
import Tag, { TagCategoryType } from "@models/Tag"
import { IterableList } from "@models/iterable"
import { FilterOperation, QueryParameters } from "@utils/index"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import ProductTableDefinition, { ProductTableDefinitionES } from "@components/product/ProductTableDefinition"
import { MdCloudDownload } from "react-icons/md"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { pdf } from "@react-pdf/renderer"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import Hierarchy from "@models/Hierarchy"
import ModalMergeDiff from "@views/assessment/Modals/ModalMergeDiff"
import StatsCard from "@components/common/stats/StatsCard"
import AddTagModal from "./Modals/AddTagModal"
import VisualizeTagModal from "./Modals/VisualizeTagModal"
import FilterBox from "./Components/FilterBox"

const initValue: Tag = {
    id: "",
    name: "",
    description: "",
    color: "",
    favorite: false,
    category: TagCategoryType.Client,
    created_at: "",
    products: [],
    client: { id: "" }
}

const ProductList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const tagService = useContext(ServicesContext).tagService
    const clientService = useContext(ServicesContext).clientService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [tagNameList, setTagNameList] = useState<{ id: string; name: string; color: string; description: string; client_id: string; products: string[] }[]>([])
    const [addTagOpen, setAddTagOpen] = React.useState(false)
    const [visualizeTagOpen, setvisualizeTagOpen] = React.useState(false)
    const [updateTags, setUpdateTags] = useState(false)
    const [formTagData, setFormTagData] = useState<Tag>(initValue)
    const [productId, setProductID] = useState<string>("")
    const [productName, setProductName] = useState<string>("")
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const [selectedRow, setSelectedRow] = useState<string[]>([])
    const [isFormValid, setIsFormValid] = useState(false)
    const [cols, setCols] = useState<GridColDef[]>([])
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const [queryParameters, setQueryParameters] = useState<QueryParameters>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
    const [SBOMEditor, setSBOMEditor] = useState<boolean>(false)
    const [openMergeDiff, setOpenMergeDiff] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<string[]>([])
    const [type, setType] = useState<string>("")
    const [clientId, setClientId] = useState("")
    const handleRowSelection = (newSelection: GridRowSelectionModel) => {
        if (newSelection.length <= 2) {
            setSelectedRows(newSelection)
            setSelectedProduct(newSelection as string[])
            if (newSelection.length === 2) {
                setSBOMEditor(true)
            } else {
                setSBOMEditor(false)
            }
        }
    }
    const handleCloseMergeDiffClose = () => setOpenMergeDiff(false)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }
    const handleProviderChange = (providerId: string) => {
        if (providerId) {
            setQueryParameters((prev) => ({
                ...prev,
                filters: [
                    ...(prev?.filters || []).filter((filter) => filter.field !== "provider_ids"),
                    { field: "provider_ids", operation: FilterOperation.StringArrayContains, value: providerId }
                ]
            }))
        } else {
            setQueryParameters((prev) => ({
                ...prev,
                filters: (prev?.filters || []).filter((filter) => filter.field !== "provider_ids")
            }))
        }
    }
    const fetchClients = async () => {
        try {
            const response = await clientService.getAll()
            console.log("clients", response)
        } catch (error: any) {
            console.error(error)
            setSnackbarMessage(error.message)
            setSnackbarOpen(true)
        }
    }
    const handleHierarchyChange = (hierarchies: (Hierarchy | null)[]) => {
        const validHierarchies = hierarchies.filter((hierarchy): hierarchy is Hierarchy => hierarchy !== null)

        if (validHierarchies.length > 0) {
            const hierarchyIds = validHierarchies.map(hierarchy => hierarchy.id).join(";")
            setQueryParameters((prev) => ({
                ...prev,
                filters: [
                    ...(prev?.filters || []).filter((filter) => filter.field !== "hierarchy"),
                    {
                        field: "hierarchy",
                        operation: FilterOperation.StringContains,
                        value: hierarchyIds
                    }
                ]
            }))
        } else {
            setQueryParameters((prev) => ({
                ...prev,
                filters: (prev?.filters || []).filter((filter) => filter.field !== "hierarchy")
            }))
        }
    }

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ProductList" })
        fetchClients()
    }, [])

    useEffect(() => {
        const policyTableDefinition = context.language === "es" ? ProductTableDefinitionES : ProductTableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability])

    const handleAddTagOpen = async (params: any) => {
        setAddTagOpen(true)
        setProductID(params.id)
        setClientId(params.client.id)
    }
    const handleAddTagClose = () => {
        setAddTagOpen(false)
    }
    const handleVisualizeTagOpen = async (params: any) => {
        try {
            const filters: QueryParameters = { sortField: "name" }
            const data = await tagService.getAll(filters)
            const filteredList = data.list.filter(item => item.products && item.products.includes(params.id))
            const filteredTagNames = filteredList.map(item => ({
                name: item.name,
                color: item.color,
                products: item.products,
                client_id: params.client.id,
                description: item.description,
                id: item.id
            }))
            setTagNameList(filteredTagNames)
            setSelectedRow(params)
            setProductName(params.name)
            setvisualizeTagOpen(true)
        } catch (e:any) {
            setSnackbarMessage(e.message)
            setSnackbarOpen(false)
        }
    }

    const handleVisualizeTagClose = () => {
        setvisualizeTagOpen(false)
        setTagNameList([])
    }

    const fetchData = async (): Promise<IterableList<Tag> | undefined> => {
        try {
            const filters: QueryParameters = { sortField: "name" }
            return await tagService.getAll(filters)
        } catch (e: any) {
            setSnackbarMessage(e.message)
            setSnackbarOpen(true)
            return undefined
        }
    }
    useEffect(() => {
        const { name, description, color } = formTagData
        if (name && description && color) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formTagData])
    useEffect(() => {
        const init = async () => {
            // setLoadingPromises(true)
            try {
                const result = await fetchData()
                let tagList: Tag[]

                if (result !== undefined) {
                    tagList = result?.list
                }

                const newCol = convertModelDefToGridColDef(ProductTableDefinition, ability)
                newCol.push({
                    field: "Tags",
                    type: "tags",
                    headerName: "Tags",
                    flex: 0.1,
                    minWidth: 280,
                    renderCell: (params) => {
                        if (ability.can("read", "Product")) {
                            const tagList2 = tagList && tagList.filter(tag => tag.products && tag.products.some(item => item === params.id))
                            return (
                                <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {tagList2 && tagList2.length >= 1
                                        ? (
                                            <>
                                                {tagList2.slice(0, 2).map((chip, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={chip.name}
                                                        variant="outlined"
                                                        style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color, marginRight: "5px" }}
                                                        deleteIcon={<HighlightOffIcon style={{ color: theme.palette.secondary.contrastText }} />}
                                                        onDelete={() => handleDelete(chip, params.row)}
                                                        onClick={() => navigate(`../manage/tags/${chip.id}`)}
                                                    />
                                                ))}
                                                <MoreVertIcon onClick={() => handleVisualizeTagOpen && handleVisualizeTagOpen(params.row)} sx={{ color: theme.palette.primary.main, cursor: "pointer", "&:hover": { backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.contrastText, borderRadius: "50%" } }} fontSize="small" />
                                            </>
                                        )
                                        : (
                                            tagList2 && tagList2.map((chip, index) => (
                                                <Chip
                                                    key={index}
                                                    label={chip.name}
                                                    variant="outlined"
                                                    style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color }}
                                                    deleteIcon={<HighlightOffIcon style={{ color: theme.palette.secondary.contrastText }} />}
                                                    onDelete={() => handleDelete(chip, params)}
                                                    onClick={() => navigate(`../manage/tags/${chip.id}`)}
                                                />
                                            ))
                                        )}
                                </Grid>
                            )
                        }
                        return (
                            <div>
                                {params.value}
                            </div>

                        )
                    }

                })
                setCols(newCol)
            } catch (e: any) {
                setSnackbarMessage(e.message)
                setSnackbarOpen(true)
            }
            // setLoadingPromises(false)
        }
        init()
    }, [updateTags])
    const handleDelete = async (chip: any, params: any) => {
        const { id: productId } = params
        const updatedProducts = chip.products.filter((productID: string) => productID !== productId)
        const updatedChip = { ...chip, products: updatedProducts }
        try {
            await tagService.update(updatedChip.id, updatedChip)
            setTagNameList((prevList) => prevList.filter(tag => tag.id !== chip.id))
        } catch (e: any) {
            setSnackbarMessage(e.message)
            setSnackbarOpen(true)
        }
        setUpdateTags(prev => !prev)
    }

    const handleGenerateReport = async (id: string) => {
        const params: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.UUIDEqual, value: id }] }
        const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
        const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
        const blob = await report.toBlob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
    }

    const customActions = (params: GridRowParams<Product>) => {
        const actions: CustomAction[] = []

        if ((params.row as any).num_vulnerability > 0) {
            actions.push(
                {
                    icon: <Tooltip title="Generate report">
                        <IconButton size="small" onClick={() => handleGenerateReport(params.row.id)}>
                            <MdCloudDownload />
                        </IconButton>
                    </Tooltip>,
                    visualize: true
                }
            )
        }

        actions.push(
            {
                icon: <Can key="update" I="update" an="Product" >
                    <Tooltip title="Add tags">
                        <IconButton size="small" component="label" onClick={() => handleAddTagOpen(params.row)}>
                            <FaTags color="black"/>
                        </IconButton>
                    </Tooltip>
                </Can>,
                visualize: true
            }
        )

        return actions
    }
    const handleMenuItemClick = (option: string) => {
        if (option === "merge") {
            setType(option)
        } else if (option === "diff") {
            setType(option)
        }
        handleCloseMenu()
        setOpenMergeDiff(true)
    }
    return (
        <>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", marginBottom: "0px", paddingBottom: "0px" }}>

                {/* Primera columna */}
                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="45px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("products")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="15px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("sections.productInventory")}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Segunda columna */}
                <Grid container item sx={{ flex: 1, justifyContent: "flex-end" }}>
                    <FormGroup>
                        <Grid item sx={{ display: "flex", gap: 2 }}>
                            <Tooltip
                                title={!SBOMEditor ? context.t.translate("tooltip_select_two_products") : ""}
                            >
                                <Can I="create" a="Product">
                                    <span>
                                        <ActionButton
                                            onClick={handleClick}
                                            variant="contained"
                                            text={context.t.translate("SBOM_editor")}
                                        />
                                    </span>
                                </Can>
                            </Tooltip>
                            <Can I="update" a="Provider">
                                <ActionButton
                                    onClick={() => {}}
                                    text={context.t.translate("upload_sbom")}
                                />
                            </Can>
                            <Can I="create" a="Product">
                                <div id="products">
                                    <ActionButton
                                        onClick={() => navigate("./add")}
                                        text={context.t.translate("product_newproduct")}
                                    />
                                </div>
                            </Can>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                <MenuItem onClick={() => handleMenuItemClick("merge")}>
                                    {context.t.translate("merge")}
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick("diff")}>
                                    {context.t.translate("diff")}
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </FormGroup>
                </Grid>

            </Grid>
            <FilterBox onProviderChange={handleProviderChange} onHierarchyChange={handleHierarchyChange} />
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={severity}
            />
            <StatsCard title={context.t.translate("products")} entity="Product" />
            <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }} mt={2}>
                <Grid item container flexDirection="column" rowGap="35px">

                    <GenericTable<Product> entity="Product" columns={cols}
                        dataProvider={(filter) => productService.getAllExt(filter)}
                        onEdit={(elem: Product) => navigate("./" + elem.id)}
                        onDelete={(elem: Product) => productService.delete(elem.id)}
                        handleAddTagOpen={handleAddTagOpen}
                        handleVisualizeTagOpen={handleVisualizeTagOpen}
                        withTags={true}
                        customActions={customActions}
                        externalParameters={queryParameters}
                        dataGridProps={{
                            minWidth: 500,
                            checkboxSelection: true,
                            selectionModel: selectedRows,
                            disableRowSelectionOnClick: true,
                            onRowSelectionModelChange: handleRowSelection, // Cambiar a onRowSelectionModelChange
                            isRowSelectable: (params: any) => selectedRows.length < 2 || selectedRows.includes(params.id),
                            getRowClassName: (params: GridRowClassNameParams) =>
                                params.row.supressed ? "suppressedRow" : "",
                            sx: {
                                minWidth: 500,
                                "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 },
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none" // Oculta la casilla de selección en el header
                                }
                            }
                        }}/>
                </Grid>
                {/* <CreateTagModal
                    open={createTagOpen}
                    onClose={handleCreateTagClose}
                    formTagData={formTagData}
                    setFormTagData={setFormTagData}
                    isFormValid={isFormValid}
                    onTagDeleted={() => setUpdateTags(prev => !prev)}
                    onTagCreated={handleTagCreated} /> */}
                <AddTagModal
                    open={addTagOpen}
                    onClose={handleAddTagClose}
                    productId={productId}
                    onTagAdded={() => setUpdateTags(prev => !prev)}
                    setError={setSnackbarMessage}
                    clientId={clientId}/>
                <VisualizeTagModal
                    open={visualizeTagOpen}
                    onClose={handleVisualizeTagClose}
                    selectedRow={selectedRow}
                    tagNameList={tagNameList}
                    onDeleteTag={handleDelete}
                    productName={productName} />
            </Grid>
            <ModalMergeDiff open={openMergeDiff} type={type} onClose={handleCloseMergeDiffClose} products_id={selectedProduct}/></>
    )
}

export { ProductList }
export default ProductList
