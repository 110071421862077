import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import { Grid, Tab, Tabs, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { I18nContext } from "I18nProvider"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"
import Tag from "@models/Tag"
import TagsTableDefinition from "@components/tag/TagsTableDefinition"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { Filter, filterOperationMap } from "@models/Filter"
import FilterDropdown from "@components/filters/FiltersDrawer"
import RecentFavoriteTags from "./RecentFavoritesTags"

const TagsList: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const { track } = useTrack()
    const navigate = useNavigate()
    const tagService = useContext(ServicesContext).tagService
    const filterService = useContext(ServicesContext).filterService
    const [tabIndex, setTabIndex] = useState(0)
    const [params, setParams] = useState<QueryParameters>({})
    const [fetchedFilters, setFetchedFilters] = useState<Filter[]>([])
    useEffect(() => {
        track({ view: "TagsList" })
    }, [])

    useEffect(() => {
        setCols(convertModelDefToGridColDef(TagsTableDefinition, ability))
    }, [context.language, ability])

    const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue)
    }
    const fetchFilters = async () => {
        try {
            const response = await filterService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: "tags" }] })
            console.log(response)
            setFetchedFilters(response.list)
        } catch (error: any) {
            console.error(error)
        }
    }
    // Data for recent & favourites
    const [recentTags, setRecentTags] = useState<Tag[]>([])
    const [favouriteTags, setFavouriteTags] = useState<Tag[]>([])

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await tagService.getRecentFavTags()
                if (val && val.recent_favs && Array.isArray(val.recent_favs.list)) {
                    setRecentTags(val.recent_favs.list)
                } else {
                    console.error("recentFavs or recentFavs.list is undefined or not an array")
                }

                if (val && val.favs && Array.isArray(val.favs.list)) {
                    setFavouriteTags(val.favs.list)
                } else {
                    console.error("favs or favs.list is undefined or not an array")
                }
            } catch (e: any) {
                console.error(e)
            }
        }
        fecthData()
        fetchFilters()
    }, [])
    const handleFilterApplied = (filter: Filter) => {
        // Convertimos los filtros de 'Filter' a 'FilterOption' para que coincidan con el formato de 'QueryParameters'
        const newFilters: FilterOption[] = filter.filters.map((f) => ({
            field: f.Field, // Mapear el campo
            operation: filterOperationMap[f.FilterOperation], // Convertimos el FilterOperation de tipo numérico a cadena
            value: f.Value.join(",") // Asumimos que 'Value' es un array de strings y lo convertimos a una cadena
        }))

        setParams({ filters: newFilters })
    }
    const handleFilterDeselected = () => {
        setParams({ filters: [] })
    }
    const dataProvider = useCallback(
        async (filter: any) => {
            try {
                const response = await tagService.getAll({ ...filter, ...params })
                return response
            } catch (error) {
                console.error("Error fetching data:", error)
                throw error
            }
        },
        [params, params.filters]
    )
    return (
        <Grid container flexDirection="column" spacing={2} sx={{ position: "relative" }} padding="20px">
            <FilterDropdown filters={fetchedFilters} onFilterApplied={handleFilterApplied} onFilterDeselected={handleFilterDeselected} />

            <Grid container sx={{ paddingLeft: "20px" }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleChangeTab}
                    sx={{
                        marginBottom: 2,
                        "& .MuiTabs-indicator": {
                            backgroundColor: theme.palette.primary.main,
                            height: 3
                        },
                        "& .Mui-selected": {
                            color: theme.palette.primary.main,
                            fontWeight: "bold"
                        }
                    }}
                >
                    <Tab label={context.t.translate("tags")} />
                    <Tab label={context.t.translate("recent_favorites")} />
                </Tabs>

                {tabIndex === 0 && (
                    <>
                        <Grid container sx={{ justifyContent: "space-between", alignItems: "center", paddingLeft: "20px" }}>
                            <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                                <Grid item>
                                    <Typography
                                        color={theme.palette.text.secondary}
                                        fontSize="45px"
                                        fontWeight="bolder"
                                        fontFamily="Griff"
                                    >
                                        {context.t.translate("tags")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color={theme.palette.text.secondary}
                                        fontSize="15px"
                                        fontWeight="bolder"
                                        fontFamily="Griff"
                                    >
                                        {context.t.translate("sections.tagManagement")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <ActionButton onClick={() => navigate("./add")} text={context.t.translate("add")} />
                        </Grid>
                        <GenericTable<Tag>
                            entity="Tags"
                            columns={cols}
                            dataProvider={dataProvider}
                            onEdit={(elem: Tag) => navigate("./" + elem.id)}
                            onDelete={(elem: Tag) => tagService.delete(elem.id)}
                            key={JSON.stringify(params)}
                        />
                    </>
                )}
                {tabIndex === 1 && (
                    <RecentFavoriteTags
                        recentTags={recentTags}
                        favouriteTags={favouriteTags}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export { TagsList }
export default TagsList
