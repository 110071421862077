import React from "react"
import { Card, CardContent, Grid, LinearProgress, Typography, useTheme } from "@mui/material"
import { LicenseData } from "../ProductDetail"

type ComponentsLicenseProps = {
  licenses: LicenseData;
};

const ComponentsLicense: React.FC<ComponentsLicenseProps> = ({ licenses }) => {
    const theme = useTheme()
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>Components Licenses</Typography>
                    <Card sx={{ width: "70%", margin: "0 auto" }}>
                        <CardContent sx={{ width: "80%", margin: "0 auto" }}>

                            <Typography variant="h6">
                                TOTAL {licenses.total}
                            </Typography>
                            <Grid sx={{ display: "flex", justifyContent: "space-between", marginTop: 3 }}>
                                <Typography color={theme.palette.primary.contrastText}>Licenses</Typography>
                                <Typography color={theme.palette.primary.contrastText}>Quantity</Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                {Object.entries(licenses.licenses).map(([license, quantity]) => (
                                    <Grid item xs={12} key={license}>
                                        <CardContent>
                                            <Grid display="flex" alignItems="center" justifyContent="space-between">
                                                <Typography variant="body2" mr={2}>
                                                    {license}
                                                </Typography>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(quantity / licenses.total) * 100}
                                                    color="primary"
                                                    style={{
                                                        flexGrow: 2,
                                                        height: 10,
                                                        borderRadius: 5
                                                    }}
                                                />
                                                <Typography variant="body2" ml={2}>
                                                    {quantity}
                                                </Typography>
                                            </Grid>
                                        </CardContent>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>

        </Grid>

    )
}

export default ComponentsLicense
