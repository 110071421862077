import ServicesContext from "@context/ServicesContext"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import HistoricCve from "@models/HistoricCve"
import { Grid, Typography, useTheme } from "@mui/material"
import { Chrono } from "react-chrono"
import { format } from "date-fns"
import ActionButton from "@components/common/Button/ActionButton"

const HistoricCveView: React.FC = () => {
    // I18n
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // -------------------------------

    // Constants
    const { id } = useParams<{ id: string }>()
    const theme = useTheme()
    const navigate = useNavigate()
    const historicCveService = useContext(ServicesContext).historicCveService
    const [data, setData] = React.useState<HistoricCve[]>()
    // -------------------------------

    // Initial Effects
    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParameters: QueryParameters = {
                    filters: [{
                        field: "modified",
                        operation: FilterOperation.UUIDEqual,
                        value: id as string
                    }]
                }
                const response = await historicCveService.getAll(queryParameters)
                setData(response.list)
                setChronoItems(convertDataToChronoItems(response.list))
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])
    // -------------------------------

    // Chrono Items
    interface ChronoItem {
        title: string
        cardTitle: string
        url: string
        cardSubtitle: string
        cardDetailedText: string | string[]
        media: {
            type: string
            source: {
                url: string
            }
        }
    }
    const [chronoItems, setChronoItems] = React.useState<ChronoItem[]>([])
    const convertDataToChronoItems = (data: HistoricCve[]): ChronoItem[] => {
        return data.map((item) => ({
            title: format(new Date(item.modified_at), "dd/MM/yyyy HH:mm"),
            cardTitle: "The vulnerability has been modified",
            cardSubtitle: "Will be shown the previous values",
            cardDetailedText: [`Justification: ${item.prev_justification}`,
                `Response: ${item.prev_response} `,
                `State: ${item.prev_state} `,
                `Score: ${item.prev_revised_score}`,
                `Vector: ${item.prev_vector}`]
        })) as ChronoItem[]
    }

    return (
        <Grid item container flexDirection="column" rowGap="25px">
            <Grid item xs container justifyContent="space-between" alignItems="center">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        Historic CVE
                </Typography>
                <Grid item><ActionButton onClick={() => { navigate(-1) }} text="Back" /></Grid>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        {data && (
                            <Chrono items={chronoItems} mode="VERTICAL"
                                theme={{
                                    primary: theme.palette.primary.main,
                                    secondary: theme.palette.primary.light,
                                    cardBgColor: theme.palette.primary.light, // Color de fondo por defecto
                                    cardSubtitleColor: "white",
                                    cardTitleColor: theme.palette.primary.main,
                                    cardDetailsColor: "white",
                                    textColor: "red",
                                    titleColor: theme.palette.text.primary,
                                    titleColorActive: "black",
                                    toolbarBgColor: theme.palette.primary.light,
                                    toolbarBtnBgColor: "#FFF",
                                    toolbarTextColor: "black"
                                }}
                            />

                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HistoricCveView
