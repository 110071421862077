import { Box, Grid, MenuItem, Switch, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { ProductType } from "@models/index"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { MAX_DEEP_LEVEL } from "@views/hierarchy/hierarchyUtils"
import { I18nContext } from "I18nProvider"
import Hierarchy from "@models/Hierarchy"
import { Component } from "@models/Component"
import { AbilityContext } from "@components/permissions"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import { useTrack } from "@components/track/TrackContext"

const initValue: Component = {
    id: "",
    bom_ref: "",
    type: ProductType.Library,
    name: "",
    version: "",
    supressed: false,
    outdated: false,
    cpe: "",
    license: "",
    assessment_id: "",
    client_id: ""
}

const ComponentForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized.")
    const theme = useTheme()
    const componentService = useContext(ServicesContext).componentService
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const ability = useContext(AbilityContext)
    const [formData, setFormData] = useState<Component>(initValue)
    const navigate = useNavigate()
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    // --------------------------------------------------
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ComponentForm" })
    }, [])
    // Functions
    // Generic functions
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setFormData({ ...formData, [name]: e.target.checked })
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === ""
        ]

        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await componentService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // --------------------------------------------------

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("name")} name="name"
                            value={formData.name} onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("version")} name="version"
                            value={formData.version} onChange={handleInputChange} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" fullWidth variant="filled" label="CPE" name="cpe"
                            value={formData.cpe} onChange={handleInputChange} />
                    </Grid>

                    {/* Switch for 'supressed' */}
                    <Grid item xs={12} sm={4}>
                        <Typography>{context.t.translate("supressed")}</Typography>
                        <Switch
                            checked={formData.supressed}
                            onChange={(e) => handleSwitchChange(e, "supressed")}
                            name="supressed"
                        />
                    </Grid>

                    {/* Switch for 'outdated' */}
                    <Grid item xs={12} sm={4}>
                        <Typography>{context.t.translate("outdated")}</Typography>
                        <Switch
                            checked={formData.outdated}
                            onChange={(e) => handleSwitchChange(e, "outdated")}
                            name="outdated"
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("type")} name="type"
                            value={formData.type} onChange={handleInputChange}>
                            {ProductTypeKeys.map((key, index) => (
                                <MenuItem key={index} value={ProductType[key]}>
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>
                                        {key}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>

                <ActionButton type="submit" disabled={!formValid()} onClick={() => {}} text={context.t.translate("add_new_component")} style={{ width: "100%" }} />
            </Box>
        </Box>
    )
}

export { ComponentForm }
export default ComponentForm
