import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

type TestType = "Fuzzing" | "Network Stress";

interface SectionProps {
    externalData: any
    testType: TestType
    template?: Template
    lang?: string
}
const i18n = {
    es: {
        indexTitle: "5. Identificación de anomalías",
        anomaliesIntroductionSubTitle: "5.1. Introducción",
        anomaliesIntroduction: " En este apartado se procederá a describir todas las anomalías halladas a lo largo del test ejecutado. Para una mejor comprensión del formato de presentación de estas y el detalle que se muestra, se explicarán una serie de conceptos para su clasificación.",
        anomaliesIntroduction2: "En función de las condiciones en las que se han producido las anomalías, éstas se clasifican según los criterios que recoge la Tabla 2.",
        result: "Resultado",
        description: "Descripción",
        severeNonConformitie: "No conformidad grave",
        severeNonConformitie1: "Warning(s) en los monitores que observan el comportamiento tiempo-real (DLA y DA) y el enlace de comunicaciones (L1).",
        severeNonConformitie2: "Warning(s) en los monitores de enlace de comunicaciones (L1).",
        minorNonConformitie: "No conformidad leve",
        minorNonConformitie1: "Warning(s) en los monitores que observan la función de red (A1, I1 y TP1) o el resultado del test (T), ocurridos durante la ejecución de los Tests.",
        minorNonConformitie2: "Warning(s) en los monitores que observan la función de red (A1, I1 y TP1), por encima del 50% de storm rate, o el resultado del test (T), ocurridos durante la ejecución de los Tests.",
        assumableAnomalies: "Anomalía asumible",
        assumableAnomalies1: "No son clasificables en las pruebas de tipo Fuzzing.",
        assumableAnomalies2: "Warning(s) en los monitores que observan la función de red (A1, I1 y TP1), por debajo del 50% de storm rate, o el resultado del test (T), ocurridos durante la ejecución de los Tests.",
        detectedAnomalies2: "anomalías en los siguientes protocolos: ",
        tableTitle: "Tabla 2. Listado de protocolos"
    },
    en: {
        indexTitle: "5. Identification of Anomalies",
        anomaliesIntroductionSubTitle: "5.1. Introduction",
        anomaliesIntroduction: "This section will describe all the anomalies found during the executed test. To better understand the format in which these are presented and the level of detail shown, a series of concepts for their classification will be explained.",
        anomaliesIntroduction2: "Depending on the conditions under which the anomalies occurred, they are classified according to the criteria listed in Table 2.",
        result: "Result",
        description: "Description",
        severeNonConformitie: "Severe non-conformity",
        severeNonConformitie1: "Warning(s) in the monitors that observe real-time behavior (DLA and DA) and the communication link (L1).",
        severeNonConformitie2: "Warning(s) in the communication link monitors (L1).",
        minorNonConformitie: "Minor non-conformity",
        minorNonConformitie1: "Warning(s) in the monitors that observe the network function (A1, I1, and TP1) or the test result (T), occurring during the execution of the tests.",
        minorNonConformitie2: "Warning(s) in the monitors that observe the network function (A1, I1, and TP1), above the 50% storm rate, or the test result (T), occurring during the execution of the tests.",
        assumableAnomalies: "Assumable anomaly",
        assumableAnomalies1: "Not classifiable in Fuzzing-type tests.",
        assumableAnomalies2: "Warning(s) in the monitors that observe the network function (A1, I1, and TP1), below the 50% storm rate, or the test result (T), occurring during the execution of the tests.",
        detectedAnomalies2: "anomalies in the following protocols: ",
        tableTitle: "Table 2. List of Protocols"
    }
} as const
const AnomalyIdentification: React.FC<SectionProps> = ({ testType, externalData, template, lang }) => {
    const context = useContext(I18nContext)

    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template} externalData={null}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].indexTitle}</Text>
                    <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>{i18n[locale].anomaliesIntroductionSubTitle}</Text>
                    <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                        {i18n[locale].anomaliesIntroduction}
                    </Text>
                    <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                        {i18n[locale].anomaliesIntroduction2}
                    </Text>

                    <View style={{ ...styles.tableScope, textAlign: "center", marginTop: 13, width: "100%" }}>
                        <View style={{ ...styles.tableRowScope, fontFamily: "Bold", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                                {i18n[locale].result}
                            </Text>
                            <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                {i18n[locale].description}
                            </Text>
                        </View>
                        <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                                {i18n[locale].severeNonConformitie}
                            </Text>
                            {externalData && externalData.oscilloscope
                                ? (
                                    <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                        {i18n[locale].severeNonConformitie1}
                                    </Text>
                                )
                                : (
                                    <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                        {i18n[locale].severeNonConformitie2}
                                    </Text>
                                )}
                        </View>
                        <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                                {i18n[locale].minorNonConformitie}
                            </Text>
                            {testType === "Fuzzing" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    {i18n[locale].minorNonConformitie1}

                                </Text>
                            )}
                            {testType === "Network Stress" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    {i18n[locale].minorNonConformitie2}

                                </Text>
                            )}
                        </View>
                        <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                                {i18n[locale].assumableAnomalies}

                            </Text>
                            {testType === "Fuzzing" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 0, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    {i18n[locale].assumableAnomalies1}
                                </Text>
                            )}
                            {testType === "Network Stress" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    {i18n[locale].assumableAnomalies2}
                                </Text>
                            )}
                        </View>

                    </View>
                    <Text style={{ ...styles.textua, marginLeft: 45, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray", textAlign: "center" }} >
                        {i18n[locale].tableTitle}
                    </Text>
                </View>
            </View>

            <Footer externalData={null}></Footer>
        </Page>

    )
}

export { AnomalyIdentification }
