import { Button, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React from "react"

interface PolicyNameNavigateProps {
    value: string;
    id: string;
}

const PolicyNameNavigate: React.FC<PolicyNameNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`./${id}`)
    }

    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                style={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}
export { PolicyNameNavigate }
export default PolicyNameNavigate
