import { CVSS3Severity, Vulnerability, VulnerabilityCAFStatusKeysValueOps, VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, VulnerabilityStateKeysValueOps } from "@models/index"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import ProductClientNavigate from "@components/product/ProductClientNavigate"
import { VulnerabilitySnapshot } from "@models/Snapshot"
import { NumberColoredCVSS, VulnerabilityStateColored } from "."
import TagColored from "./TagColored"
import KevCheckbox from "./KevCheckbox"
import AssessmentStateColored from "./AssessmentStateColored"
import VulnerabilityNameNavigate from "./VulnerabilityNameNavigate"
import MitigateVuln from "./MitigateVuln"
import CafVulnerabilityNameNavigate from "./caf/CafVulnerabilityNameNavigate"
import CafListNavigator from "./caf/CafListNavigator"
import CafFleetsAffected from "./caf/CafFleetsAffected"
import CafFleetsStatus from "./caf/CafFleetsStatus"
import VulnProductNavigate from "./VulnerabilityProductNavigate"

// import TagColored from "./TagColored"

const VulnerabilityTableDefinition: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, minWidth: 100, isId: true },
        { name: "identifier", label: "Identifier", flex: 0.2, type: "string", render: VulnerabilityNameNavigate, minWidth: 150 },
        { name: "score", label: "Score", type: "number", flex: 0.18, render: NumberColoredCVSS, minWidth: 120 },
        { name: "revised_score", label: "Revised Score", type: "number", flex: 0.18, render: NumberColoredCVSS, minWidth: 120 },
        { name: "priority", label: "Priority", type: "number", flex: 0.12 },
        { name: "epss", label: "EPSS", type: "number", flex: 0.12, minWidth: 100 },
        { name: "supressed", label: "Supressed", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "kev", label: "KEV", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "vector", label: "Vector", type: "string", flex: 0.3, hide: true, minWidth: 150 },
        { name: "status", label: "Status", type: "singleSelect", flex: 0.25, allowedValueOptions: VulnerabilityCAFStatusKeysValueOps, render: VulnerabilityStateColored, minWidth: 180 },
        { name: "description", label: "Description", type: "string", flex: 0.5, hide: true, minWidth: 200 },
        { name: "justification", label: "Justification", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityJustificationKeysValueOps, hide: true, minWidth: 150 },
        { name: "response", label: "Response", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityResponseKeysValueOps, hide: true, minWidth: 150 },
        { name: "time_spent", label: "Time Spent", type: "number", flex: 0.1, hide: true, minWidth: 100 },
        { name: "cmp_name", label: "Cmp Name", type: "string", flex: 0.2, hide: false, minWidth: 150 },
        { name: "cmp_version", label: "Cmp Version", type: "string", flex: 0.2, hide: false },
        { name: "assesment.name", label: "Assessment", type: "singleSelect", flex: 0.15, valueGetter: vuln => vuln.assessment.name, render: AssessmentStateColored, minWidth: 150 },
        { name: "product", label: "Product", type: "string", flex: 0.2, render: VulnProductNavigate, minWidth: 150 },
        { name: "tags", label: "Tags", type: "string", flex: 0.25, render: TagColored, valueGetter: vuln => vuln.product.id, minWidth: 150 },
        { name: "product.version", label: "Version", type: "string", flex: 0.1, valueGetter: vulnerability => vulnerability.product.version },
        { name: "client", label: "Client", type: "string", flex: 0.1, render: ProductClientNavigate },
        { name: "rank", label: "Rank", type: "number", flex: 0.1, minWidth: 100 }
    ]
}

const VulnerabilityTableDefinitionES: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, isId: true },
        { name: "identifier", label: "Identificador", flex: 0.2, type: "string", render: VulnerabilityNameNavigate },
        { name: "score", label: "Puntuación", type: "number", flex: 0.18, render: NumberColoredCVSS },
        { name: "revised_score", label: "Puntuación Revisada", type: "number", flex: 0.18, render: NumberColoredCVSS },
        { name: "priority", label: "Prioridad", type: "number", flex: 0.12 },
        { name: "epss", label: "EPSS", type: "number", flex: 0.12 },
        // { name: "percentile", label: "EPSS %", type: "number", flex: 0.15 },
        { name: "supressed", label: "Suprimido", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "kev", label: "KEV", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "vector", label: "Vector", type: "string", flex: 0.3, hide: true },
        { name: "status", label: "Status", type: "singleSelect", flex: 0.25, allowedValueOptions: VulnerabilityCAFStatusKeysValueOps, render: VulnerabilityStateColored, minWidth: 180 },
        { name: "state", label: "Estado", type: "singleSelect", flex: 0.16, allowedValueOptions: VulnerabilityStateKeysValueOps, render: VulnerabilityStateColored },
        { name: "description", label: "Descripción", type: "string", flex: 0.5, hide: true },
        { name: "justification", label: "Justificación", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityJustificationKeysValueOps, hide: true },
        { name: "response", label: "Respuesta", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityResponseKeysValueOps, hide: true },
        { name: "time_spent", label: "Tiempo Empleado", type: "number", flex: 0.1, hide: true },
        { name: "cmp_name", label: "Nombre Componente", type: "string", flex: 0.2, hide: false },
        { name: "cmp_version", label: "Versión Componente", type: "string", flex: 0.2, hide: false },
        { name: "assesment.name", label: "Evaluación", type: "singleSelect", flex: 0.15, valueGetter: vuln => vuln.assessment.name, render: AssessmentStateColored },
        { name: "product.name", label: "Producto", type: "string", flex: 0.2, valueGetter: vuln => vuln.product.name },
        { name: "tags", label: "Etiquetas", type: "string", flex: 0.25, render: TagColored, valueGetter: vuln => vuln.product.id },
        { name: "product.version", label: "Versión", type: "string", flex: 0.1, valueGetter: vulnerability => vulnerability.product.version },
        { name: "client.name", label: "Cliente", type: "string", flex: 0.1, valueGetter: vuln => vuln.client.name },
        { name: "rank", label: "Rango", type: "number", flex: 0.1, minWidth: 100 }
    ]
}
const VulnerabilityTableDefinitionReduced: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, minWidth: 100, isId: true },
        { name: "identifier", label: "Identifier", flex: 0.2, type: "string", render: VulnerabilityNameNavigate, minWidth: 150 },
        { name: "score", label: "Score", type: "number", flex: 0.18, render: NumberColoredCVSS, minWidth: 120 },
        { name: "supressed", label: "Supressed", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "kev", label: "KEV", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "vector", label: "Vector", type: "string", flex: 0.3, hide: true, minWidth: 150 },
        { name: "state", label: "Status", type: "singleSelect", flex: 0.16, allowedValueOptions: VulnerabilityStateKeysValueOps, render: VulnerabilityStateColored, minWidth: 120 },
        { name: "description", label: "Description", type: "string", flex: 0.5, hide: true, minWidth: 200 },
        { name: "justification", label: "Justification", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityJustificationKeysValueOps, hide: true, minWidth: 150 },
        { name: "response", label: "Response", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityResponseKeysValueOps, hide: true, minWidth: 150 },
        { name: "assesment.name", label: "Assessment", type: "singleSelect", flex: 0.15, valueGetter: vuln => vuln.assessment.name, render: AssessmentStateColored, minWidth: 150 },
        { name: "product.name", label: "Product", type: "string", flex: 0.2, valueGetter: vuln => vuln.product.name, minWidth: 150 },
        { name: "mitigate", label: "Mitigate", type: "struct", flex: 0.1, render: (params) => MitigateVuln(params), valueGetter: vuln => vuln, minWidth: 100 }
    ]
}

const VulnerabilityTableDefinitionReducedSnapshot: ModelDefinition<VulnerabilitySnapshot> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, minWidth: 100, isId: true },
        { name: "identifier", label: "Identifier", flex: 0.2, type: "string", render: VulnerabilityNameNavigate, minWidth: 150 },
        { name: "score", label: "Score", type: "number", flex: 0.18, render: NumberColoredCVSS, minWidth: 120 },
        { name: "supressed", label: "Supressed", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "kev", label: "KEV", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "vector", label: "Vector", type: "string", flex: 0.3, hide: true, minWidth: 150 },
        { name: "status", label: "Status", type: "singleSelect", flex: 0.25, allowedValueOptions: VulnerabilityCAFStatusKeysValueOps, render: VulnerabilityStateColored, minWidth: 180 },
        { name: "description", label: "Description", type: "string", flex: 0.5, hide: true, minWidth: 200 },
        { name: "assessment.type", label: "Assessment", type: "singleSelect", flex: 0.15, valueGetter: vuln => vuln.assessment.type, minWidth: 150 },
        { name: "assessment.product.name", label: "Product", type: "string", flex: 0.2, valueGetter: vuln => vuln.assessment.product.name, minWidth: 150 }
    ]
}

const CAFVulnerabilityTableDefinition: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "identifier", label: "Identifier", type: "string", flex: 0.3, minWidth: 100, render: CafVulnerabilityNameNavigate },
        {
            name: "suppliers",
            label: "Suppliers",
            type: "string",
            flex: 0.3,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.suppliers ?? []}
                    labelKey="name"
                    type="provider"
                />
            )
        },
        {
            name: "products",
            label: "Products",
            type: "string",
            flex: 0.3,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.products ?? []}
                    labelKey="name"
                    type="product"
                />
            )
        },
        { name: "fleets_affected", label: "Fleets affected", type: "number", flex: 0.15, render: CafFleetsAffected },
        { name: "status_by_fleets", label: "Status by fleet", type: "number", flex: 0.50, render: CafFleetsStatus }

    ]
}
const CAFVulnerabilityTableDefinitionReduced: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "identifier", label: "Identifier", type: "string", flex: 0.3, minWidth: 100, render: CafVulnerabilityNameNavigate },
        {
            name: "suppliers",
            label: "Suppliers",
            type: "string",
            flex: 0.3,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.suppliers ?? []}
                    labelKey="name"
                    type="provider"
                />
            )
        },
        {
            name: "products",
            label: "Products",
            type: "string",
            flex: 0.3,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.products ?? []}
                    labelKey="name"
                    type="product"
                />
            )
        },
        { name: "fleets_affected", label: "Fleets affected", type: "number", flex: 0.15, render: CafFleetsAffected }

    ]
}

const CAFVulnerabilityTableDefinitionES: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "identifier", label: "Identificador", type: "string", flex: 0.3, minWidth: 100, render: CafVulnerabilityNameNavigate },
        {
            name: "suppliers",
            label: "Proveedores",
            type: "string",
            flex: 0.3,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.suppliers ?? []}
                    labelKey="name"
                    type="provider"
                />
            )
        },
        {
            name: "products",
            label: "Productos",
            type: "string",
            flex: 0.3,
            render: (params) => (
                <CafListNavigator<any>
                    value={params.row?.products ?? []}
                    labelKey="name"
                    type="product"
                />
            )
        },
        { name: "fleets_affected", label: "Fleets afectados", type: "number", flex: 0.15, render: CafFleetsAffected },
        { name: "status_by_fleets", label: "Estados por fleet", type: "number", flex: 0.40, render: CafFleetsStatus }

    ]
}
const CVSS3SeverityKeys = getEnumKeys(CVSS3Severity)
const CVSS3SeverityKeysValueOps = CVSS3SeverityKeys.map((key) => ({ label: key, value: CVSS3Severity[key] }))

export { VulnerabilityTableDefinition, CAFVulnerabilityTableDefinitionReduced, VulnerabilityTableDefinitionReducedSnapshot, CAFVulnerabilityTableDefinition, CAFVulnerabilityTableDefinitionES, VulnerabilityTableDefinitionES, VulnerabilityTableDefinitionReduced, VulnerabilityStateKeysValueOps, VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, CVSS3SeverityKeysValueOps }
export default { VulnerabilityTableDefinition, VulnerabilityTableDefinitionES, VulnerabilityTableDefinitionReduced }
