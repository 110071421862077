import React, { useContext, useState } from "react"
import { Box, Chip, IconButton, Stack, Typography, useTheme } from "@mui/material"
import { Fullscreen, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { I18nContext } from "I18nProvider"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import { LiaBoxOpenSolid } from "react-icons/lia"
import { useNavigate } from "react-router-dom"
import ProviderModal from "./modal/ProviderModal"
import ProductModal from "./modal/ProductModal"

type ModalType = "provider" | "product"

interface CafListNavigatorProps<T extends { id: string; name: string; version?: string }> {
    value: T[]; // Aquí se pasan los proveedores (u otros elementos)
    labelKey: keyof T;
    type: ModalType;
}

const CafListNavigator = <T extends { id: string; name: string; version?: string }>({
    value,
    labelKey,
    type
}: CafListNavigatorProps<T>): React.ReactElement | null => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const totalItems = value.length
    const context = useContext(I18nContext)
    const theme = useTheme()
    const navigate = useNavigate()

    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const goToPrevious = (e: React.MouseEvent) => {
        e.stopPropagation()
        setCurrentIndex((prev) => (prev > 0 ? prev - 1 : totalItems - 1))
    }

    const goToNext = (e: React.MouseEvent) => {
        e.stopPropagation()
        setCurrentIndex((prev) => (prev < totalItems - 1 ? prev + 1 : 0))
    }

    const openModal = (e: React.MouseEvent) => {
        e.stopPropagation()
        setModalOpen(true)
    }

    // Extraemos los IDs de los proveedores del value
    const providerIds = value.map((item) => item.id)

    if (!value || value.length === 0) {
        return <Typography variant="body2">No items</Typography>
    }

    return (
        <>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ flexGrow: 1, overflow: "hidden", mr: 1 }}>
                    <Chip
                        label={
                            type === "product"
                                ? `${value[currentIndex].name} - ${value[currentIndex].version}`
                                : String(value[currentIndex][labelKey])
                        }
                        icon={type === "product" ? <TbDeviceHeartMonitor color="primary" /> : <LiaBoxOpenSolid color="primary" />}
                        sx={{
                            background: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.primary.main}`,
                            color: `${theme.palette.primary.main} !important`,
                            fontWeight: "bold"
                        }}
                        // Redirigiendo al 'id' de cada item
                        onClick={() =>
                            navigate(
                                type === "product"
                                    ? `../manage/product/${value[currentIndex].id}`
                                    : `../../manage/product/provider/${value[currentIndex].id}`
                            )
                        }
                    />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: "80px", justifyContent: "flex-end" }}>
                    <Typography variant="caption" sx={{ mx: 1 }}>
                        {currentIndex + 1}/{totalItems}
                    </Typography>

                    <Stack direction="column">
                        <IconButton size="small" onClick={goToPrevious} sx={{ padding: "2px" }}>
                            <KeyboardArrowUp fontSize="small" color="primary"/>
                        </IconButton>
                        <IconButton size="small" onClick={goToNext} sx={{ padding: "2px" }}>
                            <KeyboardArrowDown fontSize="small" color="primary"/>
                        </IconButton>
                    </Stack>

                    <IconButton size="small" onClick={openModal} sx={{ ml: 1 }} title="Ver todos">
                        <Fullscreen fontSize="small" color="primary"/>
                    </IconButton>
                </Box>
            </Box>

            {type === "provider" && <ProviderModal open={modalOpen} onClose={() => setModalOpen(false)} providerIds={providerIds} />}
            {type === "product" && <ProductModal open={modalOpen} onClose={() => setModalOpen(false)} productsIds={providerIds} />}
        </>
    )
}

export { CafListNavigator }
export default CafListNavigator
