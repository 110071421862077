import { Grid, Link, Typography, useTheme } from "@mui/material"

const Footer = () => {
    const theme = useTheme()
    const version = "0.4.21"

    return (
        <Grid
            item
            component="footer"
            sx={{
                padding: "20px",
                textAlign: "center",
                backgroundColor: theme.palette.primary.main,
                width: "100%", // Asegúrate de que ocupe todo el ancho
                maxHeight: "100px" // Limita la altura del footer para evitar expansión innecesaria
            }}
        >
            <Typography variant="body2" color={theme.palette.common.white}>
                v. {version} (release mode)
            </Typography>
            <Typography variant="body2" color={theme.palette.common.white}>
                Details of privacy policy can be found in our{" "}
                <Link href="https://orbik-cybersecurity.com/security-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Visit our security policy page"
                    sx={{ color: theme.palette.primary.light }} >website</Link>
            </Typography>
            <Typography variant="body2" color={theme.palette.common.white}>
                Copyright © {new Date().getFullYear()} Orbik Cybersecurity | Powered by Orbik Cybersecurity
            </Typography>
        </Grid>
    )
}

export default Footer
