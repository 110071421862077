import SLA from "@models/SLA"
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import ClientSelector from "@components/client/ClientSelector"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"
import { useTrack } from "@components/track/TrackContext"

const initValue: SLA = {
    id: "",
    name: "",
    description: "",
    days: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0
    },
    enforcements: {
        check_critical: false,
        check_high: false,
        check_medium: false,
        check_low: false
    },
    client: { id: "00000000-0000-0000-0000-000000000000" }
}

const SLAForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const navigate = useNavigate()
    const slaService = useContext(ServicesContext).slaService
    const [error, setError] = useState<Error|null>(null)
    const [formData, setFormData] = useState<SLA>(initValue)
    const ability = useContext(AbilityContext)
    const { track, trackData } = useTrack()

    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        track({ view: "SLAForm" })
    }, [])
    // --------------------------------------------------
    // Functions
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleInputChangeV2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target
        const isCheckbox = e.target instanceof HTMLInputElement && type === "checkbox"

        setFormData((prevState) => {
            const updatedData = { ...prevState }

            // Modificación de propiedades específicas
            switch (name) {
            case "name":
            case "description":
                updatedData[name] = value
                break
            case "days.critical":
                updatedData.days.critical = Number(value) // Convertimos a número
                break
            case "days.high":
                updatedData.days.high = Number(value) // Convertimos a número
                break
            case "days.medium":
                updatedData.days.medium = Number(value) // Convertimos a número
                break
            case "days.low":
                updatedData.days.low = Number(value) // Convertimos a número
                break
            case "enforcements.check_critical":
                updatedData.enforcements.check_critical = isCheckbox ? (e.target as HTMLInputElement).checked : false
                break
            case "enforcements.check_high":
                updatedData.enforcements.check_high = isCheckbox ? (e.target as HTMLInputElement).checked : false
                break
            case "enforcements.check_medium":
                updatedData.enforcements.check_medium = isCheckbox ? (e.target as HTMLInputElement).checked : false
                break
            case "enforcements.check_low":
                updatedData.enforcements.check_low = isCheckbox ? (e.target as HTMLInputElement).checked : false
                break
            default:

                break
            }

            return updatedData
        })
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.description === ""
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000", formData.client.id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await slaService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // --------------------------------------------------
    return (
        <Box sx={{ width: "100%" }}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    width: "50%",
                    alignSelf: "center",
                    margin: "50px auto 50px auto"
                }}
            >
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid item xs container flexDirection="column">
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("sla.new")}
                    </Typography>
                </Grid>
                <Can key="showClient" I="*" a="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={6}>
                            <ClientSelector value={formData.client.id} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("sla.name")} name="name" value={formData.name} onChange={handleInputChangeV2} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("sla.description")} name="description" value={formData.description} onChange={handleInputChangeV2} />
                    </Grid>

                    {/* Fields for Days in a single row */}
                    <Grid item xs={12}>
                        <Typography
                            fontSize="34px"
                            fontFamily="Griff"
                            color="primary"
                            fontWeight="bolder"
                            sx={{ paddingLeft: "23px", paddingTop: "-20px" }}
                        >
                            {context.t.translate("sla.days")}
                        </Typography>
                        <Grid container spacing={2}>
                            {/* Critical Days */}
                            <Grid item xs={3}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="filled"
                                    label={context.t.translate("sla.criticalDays")}
                                    name="days.critical"
                                    type="number"
                                    value={formData.days.critical}
                                    onChange={handleInputChangeV2}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>

                            {/* High Days */}
                            <Grid item xs={3}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="filled"
                                    label={context.t.translate("sla.highDays")}
                                    name="days.high"
                                    type="number"
                                    value={formData.days.high}
                                    onChange={handleInputChangeV2}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>

                            {/* Medium Days */}
                            <Grid item xs={3}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="filled"
                                    label={context.t.translate("sla.mediumDays")}
                                    name="days.medium"
                                    type="number"
                                    value={formData.days.medium}
                                    onChange={handleInputChangeV2}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>

                            {/* Low Days */}
                            <Grid item xs={3}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="filled"
                                    label={context.t.translate("sla.lowDays")}
                                    name="days.low"
                                    type="number"
                                    value={formData.days.low}
                                    onChange={handleInputChangeV2}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Enforcements Section in a single row */}
                    <Grid item xs={12}>
                        <Typography
                            fontSize="34px"
                            fontFamily="Griff"
                            color="primary"
                            fontWeight="bolder"
                            sx={{ paddingLeft: "23px", paddingTop: "-20px" }}
                        >
                            {context.t.translate("sla.enforcements")}
                        </Typography>
                        <Grid container spacing={2}>
                            {/* Checkbox for Check Critical */}
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.enforcements.check_critical}
                                            onChange={handleInputChangeV2}
                                            name="enforcements.check_critical"
                                        />
                                    }
                                    label={context.t.translate("sla.checkCritical")}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.enforcements.check_high}
                                            onChange={handleInputChangeV2}
                                            name="enforcements.check_high"
                                        />
                                    }
                                    label={context.t.translate("sla.checkHigh")}
                                />
                            </Grid>

                            {/* Checkbox for Check Medium */}
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.enforcements.check_medium}
                                            onChange={handleInputChangeV2}
                                            name="enforcements.check_medium"
                                        />
                                    }
                                    label={context.t.translate("sla.checkMedium")}
                                />
                            </Grid>

                            {/* Checkbox for Check Low */}
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.enforcements.check_low}
                                            onChange={handleInputChangeV2}
                                            name="enforcements.check_low"
                                        />
                                    }
                                    label={context.t.translate("sla.checkLow")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container gap={2}>
                    <ActionButton type="submit" onClick={() => handleSubmit} style={{ width: "100%" }} disabled={!formValid()} text={context.t.translate("sla.addNew")} />
                </Grid>
            </Box>
        </Box>
    )
}

export { SLAForm }
export default SLAForm
