import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import User from "@models/User"

class UserService extends Service<User> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/client/user", MediaTypes.JSON)
    }
}

export { UserService }
export default UserService
