/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
import React, { useContext, useEffect, useRef } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import useMediaResponsive from "@hooks/useMobile"

// Inicializar el módulo de accesibilidad
highchartsAccessibility(Highcharts)

interface DonutChartValues {
    title: string;
    dataType: "array" | "object" | "mitigated-unmitigated" | "done-pending";
    data: Array<{ name: string, color: string, y: number }> | { [key: string]: number } | { mitigated: number, unmitigated: number } | { done: number, pending: number };
    number: number;
    footer?: string;
    size?: "small" | "medium" | "large"; // Propiedad para definir el tamaño
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const transformName = (name: string) => {
    if (name.toLowerCase() === "crt" || name.toLowerCase() === "sca") {
        return name.toUpperCase()
    }
    return capitalizeFirstLetter(name)
}

const GenericDonutChart: React.FC<DonutChartValues> = ({ title, dataType, data, number, footer, size = "medium" }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const chartRef = useRef<HighchartsReact.RefObject>(null)

    // Usa el hook personalizado para detectar si es mobile
    const isMobile = useMediaResponsive("(max-width: 768px)")

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.chart.update({
                series: [{ type: "pie", data: seriesData }], // Actualiza los datos del gráfico
                legend: getResponsiveLegendOptions() // Actualiza la leyenda
            })
        }
    }, [data, title, isMobile])

    let seriesData: Array<{ name: string, color: string, y: number }> = []

    // Procesar los datos según el tipo
    if (dataType === "array") {
        seriesData = (data as Array<{ name: string, color: string, y: number }>).map(serie => ({
            ...serie,
            borderRadius: 0,
            borderColor: theme.palette.secondary.contrastText
        }))
    } else if (dataType === "object") {
        seriesData = Object.keys(data).map((key) => ({
            name: transformName(key),
            y: (data as { [key: string]: number })[key],
            color: getColorForSeverity(key),
            borderRadius: 0,
            borderColor: theme.palette.secondary.contrastText
        })).filter(serie => serie.y > 0)
    } else if (dataType === "mitigated-unmitigated") {
        const mitigated = (data as { mitigated: number, unmitigated: number }).mitigated
        const unmitigated = (data as { mitigated: number, unmitigated: number }).unmitigated
        seriesData = [
            {
                name: context.t.translate("mitigated"),
                y: mitigated,
                color: "#8dd3c7"
            },
            {
                name: context.t.translate("unmitigated"),
                y: unmitigated,
                color: "#A9A9A9"
            }
        ]
    } else if (dataType === "done-pending") {
        const done = (data as { done: number, pending: number }).done
        const pending = (data as { done: number, pending: number }).pending

        seriesData = [
            {
                name: "Done",
                y: done,
                color: "#fae733"
            },
            {
                name: "Pending",
                y: pending,
                color: "#2a292a"
            }
        ]
    }

    // Configuración de tamaño basado en el prop `size`
    const sizeConfig = {
        small: { width: 260, height: 170, fontSize: "16px", legendFontSize: "14px", legendWidth: 150 },
        medium: { width: 350, height: 190, fontSize: "24px", legendFontSize: "16px", legendWidth: 200 },
        large: { width: 460, height: 220, fontSize: "30px", legendFontSize: "20px", legendWidth: 220 }
    }

    const { width, height, fontSize, legendFontSize, legendWidth } = sizeConfig[size]

    // Leyenda responsiva
    const getResponsiveLegendOptions = (): Highcharts.LegendOptions => {
        if (isMobile) {
            return {
                enabled: true,
                title: { text: title, style: { fontSize: legendFontSize, fontFamily: "Griff", color: theme.palette.primary.main } },
                align: "center",
                layout: "horizontal",
                verticalAlign: "bottom",
                itemStyle: {
                    color: theme.palette.text.primary,
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                itemMarginTop: 15,
                itemMarginBottom: 5,
                width: width - 20,
                y: 40,
                x: 0,
                useHTML: true
            }
        }
        return {
            enabled: true,
            title: { text: title, style: { fontSize: legendFontSize, fontFamily: "Griff", color: theme.palette.primary.main } },
            align: "right",
            layout: "vertical",
            verticalAlign: "top",
            itemStyle: {
                color: theme.palette.text.primary,
                textOverflow: "ellipsis",
                overflow: "hidden"
            },
            itemMarginTop: 5,
            itemMarginBottom: 5,
            width: legendWidth,
            y: 0,
            x: 20,
            itemWidth: legendWidth - 40,
            useHTML: true
        }
    }

    // Centrado de gráfico responsivo
    const getPieCenter = (): [number | string, number | string] => {
        if (isMobile) {
            // En dispositivos móviles, alinear el gráfico a la izquierda
            return ["30%", "50%"] // Cambio para alinearlo a la izquierda
        }
        // En escritorio, mantener el centro
        return [65, 65]
    }

    const options: Highcharts.Options = {
        title: { text: undefined },
        chart: {
            type: "pie",
            backgroundColor: "transparent",
            height,
            width,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: isMobile ? 40 : 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: isMobile ? [0, 0, 80, 0] : [0, 0, 10, 0],
            events: {
                load() {
                    const chart = this
                    const centerX = isMobile ? chart.chartWidth / 2 : chart.chartWidth / 4.7
                    const centerY = isMobile ? (size === "small" ? 50 : (size === "medium" ? 65 : 80)) : chart.plotHeight / 2 + 15

                    chart.renderer
                        .text(`${number}`, isMobile ? centerX - 63 : centerX + 10, isMobile ? centerY - 10 : centerY - 20)
                        .attr({ zIndex: 5, align: "center" })
                        .css({
                            color: theme.palette.text.primary,
                            fontSize: isMobile ? "16px" : fontSize,
                            fontWeight: "bolder",
                            textAlign: "center",
                            fontFamily: "Griff"
                        })
                        .add()

                    if (footer) {
                        chart.renderer
                            .text(footer, isMobile ? centerX - 63 : centerX + 10, isMobile ? centerY : centerY - 10)
                            .attr({ zIndex: 5, align: "center" })
                            .css({
                                color: theme.palette.text.primary,
                                fontSize: "8px",
                                textAlign: "center",
                                fontFamily: "Griff"
                            })
                            .add()
                    }
                }
            }
        },
        credits: { enabled: false },
        legend: getResponsiveLegendOptions(),
        plotOptions: {
            pie: {
                innerSize: "48%",
                dataLabels: { enabled: false },
                center: getPieCenter()
            }
        },
        exporting: { enabled: false },
        series: [{ showInLegend: true, data: seriesData, borderColor: theme.palette.primary.contrastText, type: "pie" }],
        tooltip: {
            pointFormatter: function () {
                const total = seriesData.reduce((acc, point) => acc + point.y, 0)
                const yValue = this.y ?? "N/A"
                const percentage = this.y !== undefined ? Math.round((this.y / total) * 100) : "N/A"
                return `<b>${yValue}</b></br><b>${percentage}%</b>`
            }
        }
    }

    return (
        <div style={{ position: "relative", width: "100%" }}>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} key={`${title}-${number}-${JSON.stringify(data)}-${isMobile}`} />
        </div>
    )
}

const getColorForSeverity = (severity: string) => {
    switch (severity.toLowerCase()) {
        case "critical":
            return "#D00000"
        case "high":
            return "#E63946"
        case "medium":
            return "#F8961E"
        case "low":
            return "#FFD166"
        case "none":
            return "#A0A0A0"
        default:
            return "#000000"
    }
}

export { GenericDonutChart }
export default GenericDonutChart
