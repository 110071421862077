import React, { useContext, useEffect, useState } from "react"
import {
    AlertColor,
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Tab,
    Tabs,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ActionButton from "@components/common/Button/ActionButton"
import { Provider } from "@models/Provider"
import ServicesContext from "@context/ServicesContext"
import { ClientSelector } from "@components/client"
import { AbilityContext } from "@components/permissions"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"
import ProviderSelector from "./ClientProviderSelector"

const initValue: Provider = {
    id: "",
    name: "",
    description: "",
    provider_id: "00000000-0000-0000-0000-000000000000",
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" }
}

const ProviderForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const [providers, setProviders] = useState<Provider[]>([])
    const [activeTab, setActiveTab] = useState(0)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const [formProviderData, setFormProviderData] = useState<Provider>(initValue)
    const providerService = useContext(ServicesContext).providerService
    const clientService = useContext(ServicesContext).clientService
    const ability = useContext(AbilityContext)
    const theme = useTheme()
    const [selectedClient, setSelectedClient] = useState<string>("")

    useEffect(() => {
        setFormProviderData(prevState => ({
            ...prevState,
            client: {
                ...prevState.client,
                id: selectedClient
            }
        }))
    }, [selectedClient])

    const fetchTags = async () => {
        try {
            const response = await providerService.getAll()
            setProviders([...response.list])
        } catch (e) {
            console.error("Error fetching tags:", e)
        }
    }

    const fetchClient = async () => {
        try {
            const client = await clientService.getAll()
            setSelectedClient(client.list[0].id)
        } catch (error: any) {
            console.error(error.message)
        }
    }

    useEffect(() => {
        if (!ability.can("*", "Client")) {
            fetchClient()
        }
    }, [])

    const handleSelectClient = (id: string | undefined) => {
        if (id) { setSelectedClient(id) }
    }

    useEffect(() => {
        fetchTags()
    }, [])

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setFormProviderData({ ...formProviderData, [name]: value })
    }

    const handleCreateProvider = async () => {
        try {
            await providerService.create(formProviderData)
            setFormProviderData({
                ...formProviderData,
                id: "",
                name: "",
                description: ""
            })
            setSnackbarMessage("Provider created successfully")
            setSeverity("success")
            setSnackbarOpen(true)
            fetchTags()
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || "Error creating provider")
            setSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const handleDeleteProvider = async (providerId: string) => {
        try {
            await providerService.delete(providerId)
            const updatedProviders = providers.filter(provider => provider.id !== providerId)
            setProviders(updatedProviders)
            setSnackbarMessage("Provider deleted successfully")
            setSeverity("success")
            setSnackbarOpen(true)
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || "Error deleting provider")
            setSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    return (
        <Box sx={{ padding: 3 }}>
            <CustomSnackbar
                message={snackbarMessage}
                onClose={() => setSnackbarOpen(false)}
                severity={severity}
                open={snackbarOpen}
            />
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("providers")}
            </Typography>

            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
                <Tab label="Create provider" />
                <Tab label="Delete providers" />
            </Tabs>

            <Box sx={{ width: "100%", marginTop: 2 }}>
                {activeTab === 0 && (
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                variant="filled"
                                label="Provider name"
                                name="name"
                                value={formProviderData.name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                variant="filled"
                                label={context.t.translate("description")}
                                name="description"
                                value={formProviderData.description}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item>
                            <ProviderSelector
                                value={formProviderData.provider_id}
                                onSelect={(id) => setFormProviderData((prev) => ({ ...prev, provider_id: id || "" }))}
                            />
                        </Grid>
                        {ability.can("*", "Client") && (
                            <Grid item>
                                <ClientSelector onSelect={handleSelectClient} value={selectedClient} />
                            </Grid>
                        )}

                        <ActionButton
                            onClick={handleCreateProvider}
                            text="Create Provider"
                            style={{ width: "100%", marginTop: "15px" }}
                        />
                    </Grid>
                )}
                {activeTab === 1 && (
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {providers.map(provider => (
                                <Grid item xs={12} sm={2} md={2} key={provider.id}>
                                    <Card
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            transition: "box-shadow 0.3s",
                                            "&:hover": {
                                                boxShadow: 3
                                            }
                                        }}
                                    >
                                        <CardHeader
                                            sx={{
                                                p: 1,
                                                "& .MuiCardHeader-title": {
                                                    fontSize: "1rem",
                                                    fontWeight: 500
                                                }
                                            }}
                                            action={
                                                <IconButton
                                                    onClick={() => handleDeleteProvider(provider.id)}
                                                    color="error"
                                                    size="small"
                                                    sx={{ mt: -0.5 }}
                                                    aria-label="delete provider"
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            }
                                            title={provider.name}
                                        />
                                        <CardContent sx={{ p: 1, pt: 1, pb: "10px !important" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                {provider.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default ProviderForm
