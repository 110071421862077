import { useMediaQuery, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsAccessibility from "highcharts/modules/accessibility"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"
import ServicesContext from "@context/ServicesContext"
import { BenchmarkLifeCycle } from "@models/Benchmark"
import { I18nContext } from "I18nProvider"

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsAccessibility(Highcharts)

type VulnerabilityEvolutionChartProps = {
    timeRange: string
}

const VulnerabilityEvolutionChart: React.FC<VulnerabilityEvolutionChartProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const theme = useTheme()
    const isMobile = useMediaQuery("(max-width: 768px)")

    const benchmarkService = useContext(ServicesContext).benchmarkService
    const [data, setData] = useState<BenchmarkLifeCycle[]>([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await benchmarkService.getBenchmarkLifeCycle(timeRange)
                setData(response)
            } catch (error) {
                console.error("Error fetching data: ", error)
            }
        }
        fetchData()
    }, [timeRange])

    const options: Highcharts.Options = {
        chart: {
            type: "line",
            backgroundColor: theme.palette.background.paper,
            spacingTop: isMobile ? 5 : 10,
            spacingRight: isMobile ? 5 : 10,
            spacingBottom: isMobile ? 5 : 10,
            spacingLeft: isMobile ? 5 : 10,
            plotBorderWidth: 1,
            plotBorderColor: theme.palette.primary.main,
            height: isMobile ? 300 : 500 // Ajuste de altura
        },
        title: { text: "", style: { color: theme.palette.primary.main } },
        xAxis: {
            type: "datetime",
            categories: data.map(item => item.vulnerability_detection),
            labels: {
                style: { color: theme.palette.primary.main, fontSize: isMobile ? "12px" : "14px" }
            }
        },
        yAxis: {
            title: {
                text: "Vulnerabilities",
                style: { color: theme.palette.primary.main, fontSize: isMobile ? "12px" : "16px" }
            },
            labels: {
                style: { color: theme.palette.primary.main, fontSize: isMobile ? "10px" : "14px" }
            },
            tickInterval: isMobile ? 10 : 5
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            itemStyle: {
                color: theme.palette.primary.main,
                fontSize: isMobile ? "10px" : "14px"
            }
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: true,
                    symbol: "circle",
                    radius: isMobile ? 3 : 6 // Reducir tamaño de los puntos en móvil
                }
            }
        },
        series: [
            {
                type: "line",
                name: context.t.translate("critical"),
                data: data.map(item => item.critical_vulnerabilities),
                color: CVSS3SeverityColors.critical
            },
            {
                type: "line",
                name: context.t.translate("high"),
                data: data.map(item => item.high_vulnerabilities),
                color: CVSS3SeverityColors.high
            },
            {
                type: "line",
                name: context.t.translate("medium"),
                data: data.map(item => item.medium_vulnerabilities),
                color: CVSS3SeverityColors.medium
            },
            {
                type: "line",
                name: context.t.translate("low"),
                data: data.map(item => item.low_vulnerabilities),
                color: CVSS3SeverityColors.low
            },
            {
                type: "line",
                name: context.t.translate("none"),
                data: data.map(item => item.none_vulnerabilities),
                color: "gray"
            }
        ],
        responsive: {
            rules: [
                {
                    condition: { maxWidth: 500 },
                    chartOptions: {
                        legend: {
                            itemStyle: { fontSize: "10px" }
                        }
                    }
                }
            ]
        },
        exporting: { enabled: false },
        navigator: { enabled: !isMobile },
        scrollbar: { enabled: !isMobile },
        rangeSelector: { enabled: false }
    }

    return (
        <div style={{ width: "100%", maxWidth: "1200px", margin: "auto" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
}

export { VulnerabilityEvolutionChart }
export default VulnerabilityEvolutionChart
