
import { ModelRelation } from "@models/utils"

type License = {
    id: string;
    name: string;
    type: LicenseType;
    toolbox: boolean;
    max_prod: string;
    max_assessment: string;
    max_monitor: number;
    client: ModelRelation;

}

enum LicenseType {
    Golden = "golden",
    Platinum = "platinum",
    Titanium = "titanium"
}

export { LicenseType }
export type { License }
export default License
