import React, { useContext, useEffect, useState } from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import ServicesContext from "@context/ServicesContext"
import User from "@models/User"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import ActionButton from "@components/common/Button/ActionButton"
import { useNavigate } from "react-router-dom"
import { AbilityContext, Can } from "@components/permissions"
import { GridColDef } from "@mui/x-data-grid"
import { UserTableDefinition } from "@components/client/ClientTableDefinition"

type UserListProps = {
    clientID: string | undefined
}
const UserList = ({ clientID }: UserListProps) => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const clientService = useContext(ServicesContext).clientService
    const userService = useContext(ServicesContext).userService
    const [cols, setCols] = useState<GridColDef[]>([])
    // --------------------------------------------------

    // UseEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(UserTableDefinition, ability))
    }, [])
    // --------------------------------------------------

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("users")}
                </Typography>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <Can I="create" a="User">
                        <ActionButton onClick={() => navigate("./add")} text={context.t.translate("add_new_user")} />
                    </Can>
                </Grid>
            </Grid>
            <Grid item container flexDirection="column" sx={{ marginTop: "0px", paddingTop: "0px" }}>
                <Grid item container>
                    <GenericTable<User> entity="User" columns={cols}
                        dataProvider={() => clientService.getUsers(clientID as string)}
                        onEdit={(elem: User) => navigate("./user/" + elem.id)}
                        onDelete={(elem: User) => userService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export { UserList }
export default UserList
