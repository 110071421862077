import { Typography } from "@mui/material"
import React from "react"

interface PolicyNameNavigateProps {
    value?: string; // Se hace opcional para evitar errores
    id: string;
}

const ActionColumn: React.FC<PolicyNameNavigateProps> = ({ value }) => {
    const capitalizeFirstLetter = (str?: string) => {
        if (!str) return "N/A" // Si es undefined/null, muestra un texto por defecto
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }

    return <Typography fontSize="14px">{capitalizeFirstLetter(value)}</Typography>
}

export { ActionColumn }
export default ActionColumn
