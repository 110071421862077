import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { KEVTableDefinition } from "@components/kev/KEVTableDefinition"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import { KEV } from "@models/KEV"
import { Grid, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
type KevListProps = {
    id: string
}
const KevList = ({ id }: KevListProps) => {
    const [cols, setCols] = useState<GridColDef[]>([])
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    const issueService = useContext(ServicesContext).issueService
    const navigate = useNavigate()
    const context = useContext(I18nContext)
    const KEVService = useContext(ServicesContext).KEVService

    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    useEffect(() => {
        setCols(convertModelDefToGridColDef(KEVTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>

                    <GenericTable<KEV> entity="Issue" columns={cols}
                        dataProvider={(filter: any) => { // Si `row` es un objeto con `row`, usa el identificador para la llamada al servicio
                            return KEVService.getKEVById(id)
                        }}
                        disabledEdit={true}
                        onEdit={(elem: KEV) => navigate("./issue/" + elem.id)}
                        onDelete={undefined}
                        disabledActions={true}
                    />
                </Grid>
            </Grid>
        </Grid>

    )
}

export { KevList }
export default KevList
