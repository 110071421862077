import { Box, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Product, ProductType } from "@models/index"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { I18nContext } from "I18nProvider"
import { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"
import { useTrack } from "@components/track/TrackContext"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import ProviderGrid from "./Components/ProviderGrid"

export const getDescriptionForType = (type: ProductType): string => {
    const descriptions = {
        [ProductType.Application]: "A product of the “Application” type refers to a software application that runs on an operating system or a specific environment. These products are designed to perform specific tasks and can include web, desktop, or mobile applications.",
        [ProductType.Library]: "A product of the “Library” type is a code library that is used to add specific functionalities to other applications or products. Libraries are not executable by themselves but provide functions, classes, or methods that can be used by applications integrating them.",
        [ProductType.Firmware]: "A product of the “Firmware” type refers to specialized software integrated into hardware devices. Firmware is designed to control and manage specific hardware and is commonly found in electronic devices such as routers, printers, cameras, and more.",
        [ProductType.OS]: "A product of the “Operating System” type refers to a complete operating system, such as Linux, Windows, macOS, etc. The operating system manages the hardware and system resources, allowing applications and users to interact with the device.",
        [ProductType.Container]: "A product of the “Container” type refers to a software unit that packages an application and its dependencies into a single container. This allows applications to run consistently in any environment, whether local or in the cloud. Containers are popular in microservices-based infrastructures.",
        [ProductType.Device]: "A product of the “Device” type refers to a physical device, such as a server, computer, IoT (Internet of Things) device, etc. These devices may be subject to firmware updates or interact with specific applications and operating systems."

    }
    return descriptions[type] || ""
}
const initValue: Product = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchies: [],
    inheritance: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    provider_ids: [],
    location: "",
    created_at: ""
}

const ProductForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const productService = useContext(ServicesContext).productService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const ability = useContext(AbilityContext)
    const [formData, setFormData] = useState<Product>(initValue)
    const navigate = useNavigate()
    const { track, trackData } = useTrack()

    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        track({ view: "ProductForm" })
    }, [])
    // --------------------------------------------------
    // Functions
    // Generic functions
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.version === "",
            formData.description === ""
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000", formData.client.id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await productService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Can key="showClient" I="*" a="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={12}>
                            <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField id="productName" margin="normal" required fullWidth variant="filled" label={context.t.translate("product_newname")} name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField id="productVersion" margin="normal" required fullWidth variant="filled" label={context.t.translate("product_version")} name="version"
                            value={formData.version} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_location")} name="location"
                            value={formData.location} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="productType"
                            margin="normal"
                            select
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("type")}
                            name="type"
                            value={formData.type}
                            onChange={handleInputChange}
                            SelectProps={{
                                renderValue: (selected: unknown) => {
                                    // Aseguramos que el valor seleccionado tenga un tipo explícito
                                    const selectedValue = selected as ProductType

                                    // Encontrar la clave (mayúsculas) correspondiente al valor seleccionado
                                    const selectedKey = ProductTypeKeys.find(key => ProductType[key] === selectedValue)

                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%"
                                            }}
                                        >
                                            <Typography
                                                fontFamily="Griff"
                                                fontWeight="bolder"
                                                sx={{ color: ProductTypeColors[selectedValue] }}
                                            >
                                                {selectedKey}
                                            </Typography>
                                            <Tooltip title={getDescriptionForType(selectedValue)}>
                                                <IconButton size="small">
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            }}
                        >
                            {ProductTypeKeys.map((key, index) => (
                                <MenuItem
                                    key={index}
                                    value={ProductType[key]}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Typography
                                        fontFamily="Griff"
                                        fontWeight="bolder"
                                        sx={{ color: ProductTypeColors[ProductType[key]] }}
                                    >
                                        {key} {/* Mostrar la clave del enum */}
                                    </Typography>
                                    <Tooltip title={getDescriptionForType(ProductType[key])}>
                                        <IconButton size="small">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                </Grid>
                <Grid>
                    <TextField id="productDescription" margin="normal" required fullWidth variant="filled" label={context.t.translate("product_description")} name="description"
                        value={formData.description} onChange={handleInputChange}/>
                </Grid>
                <Grid mb={2}>
                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                Provider
                    </Typography>
                    <Grid item direction="column" spacing={2}>
                        <ProviderGrid
                            initialProviders={formData.provider_ids}
                            onChange={(providers: string[]) => {
                                setFormData({ ...formData, provider_ids: providers })
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <ActionButton dataTestId="create-product-button" type="submit" disabled={!formValid()} onClick={() => {}} text={context.t.translate("product_newproduct")} style={{ width: "100%" }}/>
                </Grid>

            </Box>
        </Box>
    )
}

export { ProductForm }
export default ProductForm
