import React, { createContext, useContext, useState } from "react"

interface HierarchyContextType {
    hierarchyId: string | null;
    setHierarchyId: (id: string | null) => void;
}

const HierarchyContext = createContext<HierarchyContextType | undefined>(undefined)

export const useHierarchy = () => {
    const context = useContext(HierarchyContext)
    if (!context) {
        throw new Error("useHierarchy must be used within a HierarchyProvider")
    }
    return context
}

export const HierarchyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [hierarchyId, setHierarchyId] = useState<string | null>(null)

    return (
        <HierarchyContext.Provider value={{ hierarchyId, setHierarchyId }}>
            {children}
        </HierarchyContext.Provider>
    )
}
