import React, { createContext, ReactNode, useContext, useState } from "react"
import { Placement } from "react-joyride"

interface Step {
  target: string;
  content: string;
  data?: { next?: string; previous?: string };
  disableBeacon?: boolean;
  title?: string;
  placement?: Placement;
}

interface AppState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

interface AppContextType {
  state: AppState;
  setState: React.Dispatch<React.SetStateAction<AppState>>;
}

const AppContext = createContext<AppContextType | null>(null)

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<AppState>({
        run: true,
        stepIndex: 0,
        steps: [
            {
                target: "body",
                content: "Getting started with MyOrbik is simple and allows you to quickly organize and analyze your software products. Follow these steps to set up your first project effectively.",
                data: { next: "/manage/hierarchy" },
                title: "Setting Up Your First Project",
                disableBeacon: true
            },
            {
                target: "body",
                content: "To better organize and manage your products, you can create a hierarchy to group them under specific categories or organizational structures.",
                data: { previous: "/", next: "body" },
                title: "Define Hierarchy Levels",
                disableBeacon: true
            },
            {
                target: "body",
                content: "Click Save to finalize your hierarchy.",
                data: { previous: "body", next: "/manage/product" },
                disableBeacon: true
            },
            {
                target: "body",
                content: "Now, register your product in the system to begin managing its components and assessments.",
                data: { previous: "/manage/hierarchy/management", next: "/" },
                title: "Create a new product",
                disableBeacon: true
            }
            //  target #prducts is not taken correctly
            // ,
            // {
            //   target: "#products",
            //   content: "Here we can create a product",
            //   data: { previous: "body", next: "/manage/product/add" },
            //   title: "Create a new product",
            //   disableBeacon: true
            // },
            // {
            //   target: "#productName",
            //   content: "The product’s name for identification",
            //   data: { previous: "/manage/product", next: "#productVersion" },
            //   disableBeacon: true
            // },
            // {
            //   target: "#productVersion",
            //   content: "A unique version identifier",
            //   data: { previous: "#productName", next: "#productType" },
            //   disableBeacon: true
            // },
            // {
            //   target: "#productType",
            //   content: "Select from predefined categories like Application, Library, Firmware, OS, Container, or Device",
            //   data: { previous: "#productVersion", next: "#productDescription" },
            //   disableBeacon: true
            // },
            // {
            //   target: "#productDescription",
            //   content: "Include additional details about the products to clarify its purpose or usage",
            //   data: { previous: "#productType", next: "/" },
            //   disableBeacon: true
            // },
            // {
            //   target: "#first",
            //   content: "El tour se ha terminado",
            //   data: { previous: "/manage/product/add"},
            //   disableBeacon: true
            // }
        ]
    })

    return (
        <AppContext.Provider value={{ state, setState }}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => {
    const context = useContext(AppContext)
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider")
    }
    return context
}
