import React, { useContext, useEffect, useState } from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import ServicesContext from "@context/ServicesContext"
import UserLog from "@models/UserLog"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { useNavigate } from "react-router-dom"
import { AbilityContext } from "@components/permissions"
import { GridColDef } from "@mui/x-data-grid"
import UserLogTableDefinition from "@components/clientLog/ClientLogTableDefinition"

const ClientLogList: React.FC = () => {
    // Constants
    const context = useContext(I18nContext) ?? (() => { throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.") })()
    const theme = useTheme()
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const userLogService = useContext(ServicesContext).userLogService
    const [cols, setCols] = useState<GridColDef[]>([])
    // --------------------------------------------------

    // UseEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(UserLogTableDefinition, ability))
    }, [])
    // --------------------------------------------------

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("user_logs")}
                </Typography>
            </Grid>
            <Grid item container flexDirection="column" sx={{ marginTop: "0px", paddingTop: "0px" }}>
                <Grid item container>
                    <GenericTable<UserLog> entity="UserLog" columns={cols}
                        dataProvider={(filter) => userLogService.getAll(filter)}
                        onEdit={(elem: UserLog) => navigate("../../settings/user_logs/" + elem.id)}
                        onDelete={(elem: UserLog) => userLogService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export { ClientLogList }
export default ClientLogList
