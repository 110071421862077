import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import SnapshotTableDefinition from "@components/snapshot/SnapshotTableDefinition"
import ServicesContext from "@context/ServicesContext"
import Product from "@models/Product"
import Snapshot from "@models/Snapshot"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface SnapshotTabProps {
    hierarchyId: string;
}

const SnapshotTab: React.FC<SnapshotTabProps> = ({ hierarchyId }) => {
    const navigate = useNavigate()
    const snapshotService = useContext(ServicesContext).snapshotService
    const productService = useContext(ServicesContext).productService
    const theme = useTheme()
    const [products, setProducts] = useState<Product[]>([])
    const [loading, setLoading] = useState(true)

    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const fetchProducts = async () => {
        try {
            const filters = { filters: [{ field: "hierarchies", operation: FilterOperation.StringArrayContains, value: hierarchyId }] }
            const fetchedProducts = await productService.getAll(filters)
            setProducts(fetchedProducts.list)
        } catch (e: any) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(SnapshotTableDefinition, ability))
    }, [])
    return (<Grid item xs container flexDirection="column" rowGap="25px" aria-description="Product Section">
        <Grid item xs sx={{ top: "0px" }}>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                Snapshot
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Grid item xs container flexDirection="column" spacing="20px">
                        <Grid item container flexDirection="column" rowGap="35px">
                            <Grid item container>
                                <GenericTable<Snapshot> entity="Snapshot" columns={cols}
                                    dataProvider={(filter) => {
                                        const updatedFilter: QueryParameters = {
                                            ...filter,
                                            filters: [{ field: "product_id", operation: FilterOperation.StringContains, value: hierarchyId }]
                                        }
                                        return snapshotService.getAll(filter)
                                    }}
                                    onEdit={(elem: Snapshot) => navigate("../../manage/product/" + elem.id)}
                                    onDelete={(elem: Snapshot) => snapshotService.delete(elem.id)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}

export default SnapshotTab
