import React, { useContext, useEffect, useState } from "react"
import { Grid, useTheme } from "@mui/material"
import GenericModal from "@components/common/modals/GenericModal"
import GenericTable from "@components/common/tables/GenericTable"
import { Product } from "@models/Product"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { GridColDef } from "@mui/x-data-grid"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef } from "@components/common/tables"
import ProductTableDefinition from "@components/product/ProductTableDefinition"
import { I18nContext } from "I18nProvider"
import { FilterOperation, QueryParameters } from "@utils/queryParams"

interface ProductModalProps {
    open: boolean;
    onClose: () => void;
    productsIds?: string[];
}

const ProductModal: React.FC<ProductModalProps> = ({ open, onClose, productsIds }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const productService = useContext(ServicesContext).productService
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
    }, [])

    return (
        <GenericModal open={open} onClose={onClose} title={context.t.translate("products")} minWidth={900}>
            <Grid container item flexDirection="column" rowGap="35px">
                <GenericTable<Product>
                    entity="Product"
                    columns={cols}
                    dataProvider={
                        (filter) => {
                            const updatedFilter: QueryParameters = {
                                ...filter,
                                filters: [{ field: "id", operation: FilterOperation.StringArrayContains, value: productsIds?.toString() || "" }]
                            }
                            return productService.getAllExt(updatedFilter)
                        }}
                    onEdit={(elem: Product) => navigate("../product/" + elem.id)}
                    onDelete={(elem: Product) => productService.delete(elem.id)}
                />
            </Grid>
        </GenericModal>
    )
}

export default ProductModal
