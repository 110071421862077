import React, { useCallback, useState } from "react"
import {
    Box,
    Button,
    Grid,
    IconButton,
    Typography,
    useTheme
} from "@mui/material"
import {
    ChevronRight as ChevronRightIcon,
    ExpandMore as ExpandMoreIcon,
    InsertDriveFile as FileIcon,
    Folder as FolderIcon,
    CloudUpload as UploadIcon
} from "@mui/icons-material"
import JSZip from "jszip"
import ActionButton from "@components/common/Button/ActionButton"

interface FileData {
  name: string;
  size: number;
  totalFiles: number;
  executables: number;
  shellScripts: number;
  type: string;
}

interface FileItem {
  type: "file" | "folder";
  size?: number;
  path?: string;
  children?: { [key: string]: FileItem };
}

interface FileTreeItemProps {
  name: string;
  item: FileItem;
  path?: string;
}

interface FileStructure {
  [key: string]: FileItem;
}

const SASTManagement: React.FC = () => {
    const theme = useTheme()
    const [fileData, setFileData] = useState<FileData | null>(null)
    const [fileStructure, setFileStructure] = useState<FileStructure | null>(null)
    const [expandedFolders, setExpandedFolders] = useState<Set<string>>(new Set())
    const [error, setError] = useState<string | null>(null)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const processFile = async (file: File): Promise<void> => {
        try {
            setSelectedFile(file)
            const zip = new JSZip()
            const contents = await zip.loadAsync(file)

            const fileInfo: FileData = {
                name: file.name,
                size: file.size,
                totalFiles: Object.keys(contents.files).length,
                executables: Object.keys(contents.files).filter(name => name.endsWith(".exe")).length,
                shellScripts: Object.keys(contents.files).filter(name => name.endsWith(".sh")).length,
                type: file.type || "application/zip"
            }

            const structure = await processZipStructure(contents.files)

            setFileData(fileInfo)
            setFileStructure(structure)
            setError(null)
        } catch (err) {
            setError("Error processing file. Please ensure it is a valid ZIP file.")
            console.error(err)
        }
    }

    const processZipStructure = async (files: { [key: string]: JSZip.JSZipObject }): Promise<FileStructure> => {
        const structure: FileStructure = {}

        for (const path of Object.keys(files)) {
            const parts = path.split("/")
            let current: any = structure

            for (let index = 0; index < parts.length; index++) {
                const part = parts[index]
                if (!part) continue
                if (index === parts.length - 1) {
                    const file = files[path]
                    const content = await file.async("blob")
                    current[part] = {
                        type: "file" as const,
                        size: content.size,
                        path
                    }
                } else {
                    current[part] = current[part] || { type: "folder" as const, children: {} }
                    current = current[part].children
                }
            }
        }

        return structure
    }

    const toggleFolder = (path: string): void => {
        setExpandedFolders(prev => {
            const newSet = new Set(prev)
            if (newSet.has(path)) {
                newSet.delete(path)
            } else {
                newSet.add(path)
            }
            return newSet
        })
    }

    const FileTreeItem: React.FC<FileTreeItemProps> = ({ name, item, path = "" }) => {
        const fullPath = path ? `${path}/${name}` : name
        const isExpanded = expandedFolders.has(fullPath)

        return (
            <div>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        py: 0.5,
                        px: 1,
                        cursor: item.type === "folder" ? "pointer" : "default",
                        "&:hover": {
                            bgcolor: "action.hover",
                            borderRadius: 1
                        },
                        ml: path ? path.split("/").length * 2 : 0
                    }}
                    onClick={() => item.type === "folder" && toggleFolder(fullPath)}
                >
                    {item.type === "folder"
                        ? (
                            <>
                                <IconButton size="small" sx={{ mr: 1 }}>
                                    {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                </IconButton>
                                <FolderIcon color="primary" sx={{ mr: 1 }} />
                            </>
                        )
                        : (
                            <>
                                <Box sx={{ width: 28, ml: 1 }} />
                                <FileIcon color="action" sx={{ mr: 1 }} />
                            </>
                        )}
                    <Typography variant="body2" sx={{ flex: 1 }}>
                        {name}
                    </Typography>
                    {item.size && (
                        <Typography variant="caption" color="text.secondary">
                            {Math.round(item.size / 1024)}KB
                        </Typography>
                    )}
                </Box>

                {item.type === "folder" && isExpanded && item.children && (
                    <div>
                        {Object.entries(item.children).map(([childName, childItem]) => (
                            <FileTreeItem
                                key={childName}
                                name={childName}
                                item={childItem}
                                path={fullPath}
                            />
                        ))}
                    </div>
                )}
            </div>
        )
    }
    const handleUpload = () => {
        console.log("Uploading file:", selectedFile)
    }
    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const file = e.dataTransfer.files[0]
        if (file) processFile(file)
    }, [])

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }, [])
    const removeFile = () => {
        setFileData(null)
        setFileStructure(null)
        setSelectedFile(null)
        setExpandedFolders(new Set())
    }
    return (
        <Grid pl="20px">
            <Typography
                color={theme.palette.text.secondary}
                fontSize="45px"
                fontWeight="bolder"
                fontFamily="Griff"
            >
                SAST Management
            </Typography>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                {!selectedFile
                    ? (<Grid item xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                p: 3
                            }}
                        >

                            <Button
                                component="label"
                                variant="outlined"
                                size="large"
                                startIcon={<UploadIcon />}
                                sx={{
                                    p: 2,
                                    height: "100px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1
                                }}
                            >
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => e.target.files?.[0] && processFile(e.target.files[0])}
                                    accept=".zip"
                                />
                                <Typography variant="h6" component="span">
                                    Select ZIP File
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    Drag & Drop or Click
                                </Typography>
                            </Button>

                        </Box>
                    </Grid>)
                    : (
                        <></>
                    )}

                {error && (
                    <Grid item xs={12}>
                        <Typography color="error">{error}</Typography>
                    </Grid>
                )}

                {fileData && (
                    <Grid item xs={12}>
                        <Box sx={{
                            bgcolor: "background.paper",
                            p: 3,
                            borderRadius: 2,
                            boxShadow: 1
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h5" gutterBottom>
                                        {fileData.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Total Files
                                    </Typography>
                                    <Typography variant="h6">
                                        {fileData.totalFiles}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Size
                                    </Typography>
                                    <Typography variant="h6">
                                        {Math.round(fileData.size / 1024)}KB
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Executables
                                    </Typography>
                                    <Typography variant="h6">
                                        {fileData.executables}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Shell Scripts
                                    </Typography>
                                    <Typography variant="h6">
                                        {fileData.shellScripts}
                                    </Typography>
                                </Grid>
                                <Grid container display="flex" direction="row" alignItems="center" spacing={2} xs={2}>
                                    <Grid item>
                                        <ActionButton text="Remove" variant="outlined" onClick={removeFile} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>
                )}

                {fileStructure && (
                    <Grid item xs={12}>
                        <Box sx={{
                            bgcolor: "background.paper",
                            p: 3,
                            borderRadius: 2,
                            boxShadow: 1,
                            maxHeight: "500px",
                            overflow: "auto"
                        }}>
                            {Object.entries(fileStructure).map(([name, item]) => (
                                <FileTreeItem
                                    key={name}
                                    name={name}
                                    item={item}
                                />
                            ))}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default SASTManagement
