import React, { useContext, useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { Grid, Typography } from "@mui/material"
import { PointOptionsObject } from "highcharts"
import SunburstChart from "@components/charts/SunburstChart"
import ServicesContext from "@context/ServicesContext"
import { DeviceStats } from "@models/Stats"
import { I18nContext } from "I18nProvider"
import SunburstChartSkeleton from "./ProductSunburstSkeleton"

const ProductSunburst = () => {
    const theme = useTheme()
    const statsService = useContext(ServicesContext).statsService

    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const [data, setData] = React.useState<DeviceStats[]>()
    const [isLoading, setIsLoading] = useState(true)
    const [pointOptionsObject, setPointOptionsObject] = useState<PointOptionsObject[]>([])

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const fetchedData = await statsService.getDeviceStats()
            setData(fetchedData)
        } catch (e: any) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (data) {
            const generateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
            const options: PointOptionsObject[] = data.map((entity) => {
                const optionObject: PointOptionsObject = {
                    id: entity.ID,
                    name: entity.Name,
                    color: generateRandomColor()
                }
                if (entity.Path.length > 0) {
                    const parentList = entity.Path[0].split(";")
                    if (parentList.length > 1) {
                        optionObject.parent = parentList.slice(0, -1).join(".")
                        optionObject.id = optionObject.parent + "." + optionObject.id
                    }
                    if (entity.IsProduct) {
                        if (entity.ID === "00000000-0000-0000-0000-000000000000") {
                            optionObject.id = parentList.join(".") + "." + optionObject.name as string
                            optionObject.parent = parentList.join(".")
                        } else {
                            optionObject.name = entity.Version
                            optionObject.parent = parentList.join(".") + "." + entity.Name
                            optionObject.id = optionObject.parent + "." + optionObject.name
                            optionObject.value = entity.TotalVulnerabilities
                        }
                    }
                }
                return optionObject
            })
            options.push({
                id: "00000000-0000-0000-0000-000000000000",
                name: "Without hierarchy",
                color: generateRandomColor()
            })
            setPointOptionsObject(options)
        }
    }, [data])

    // While loading, show skeleton
    if (isLoading) {
        return (
            <Grid item container>
                <SunburstChartSkeleton />
            </Grid>)
    }

    return (

        <Grid container flexDirection="column" spacing="20px">
            <Grid paddingTop="15px !important" item>
                <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">Products</Typography>
            </Grid>
            {pointOptionsObject && pointOptionsObject.length > 1
                ? (
                    <Grid item sx={{ height: "100%", width: "100%" }}>
                        <SunburstChart data={pointOptionsObject} legend={<></>} />
                    </Grid>
                )
                : (
                    <Grid item>
                        <Typography variant="h6" color="textSecondary">
                            {context.t.translate("no_products")}
                        </Typography>
                    </Grid>
                )}
        </Grid>
    )
}

export default ProductSunburst
