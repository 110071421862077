import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import SASTManagement from "./SASTManagement"
import SASTList from "./SASTList"
import SASTDetail from "./SASTDetail"

const entity = "SAST"
const SAST: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <SASTList/>
            }
        ])
    }
    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: ":id",
                component: <SASTDetail/>
            }
        ])
    }

    if (ability.can("create", entity)) {
        routes.push(...[
            {
                route: "/management",
                component: <SASTManagement/>
            }
        ])
    }
    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { SAST }
export default SAST
