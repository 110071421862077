import React, { useContext, useEffect, useState } from "react"
import { Box, ClickAwayListener, Divider, Grid, Menu, MenuItem, MenuList, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Client, Product } from "@models/index"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { AbilityContext } from "@components/permissions"
import ProductTableDefinition, { ProductTableDefinitionES } from "@components/product/ProductTableDefinition"
import { FaRegUserCircle } from "react-icons/fa"
import { FiUsers } from "react-icons/fi"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { StyledTab, StyledTabs } from "@views/product/Components/StyledTabs"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import useMediaResponsive from "@hooks/useMobile"
import { MdTableRows } from "react-icons/md"
import ActionButton from "@components/common/Button/ActionButton"
import ClientLogList from "./ClientLogList"
import UserList from "./user/UserList"
import ClientDetailTab from "./ClientDetailTab"

const initValue = {
    id: "",
    name: "",
    description: ""
}

const ClientDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const clientService = useContext(ServicesContext).clientService
    const productService = useContext(ServicesContext).productService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [cols, setCols] = useState<GridColDef[]>([])
    const [queryParameters, setQueryParameters] = useState<QueryParameters>()
    const ability = useContext(AbilityContext)
    const isMobile = useMediaResponsive("(max-width: 780px)")
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ClientDetail" })
    }, [])
    const { id } = useParams<{ id: string }>()

    const handleTabChange = (event: any, newIndex: number) => {
        setTabIndex(newIndex)
    }

    const [data, setData] = useState<Client>(initValue)
    const [formData, setFormData] = useState<Client>(initValue)
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await clientService.get(id as string)
                setData(val)
                setFormData(val)
            } catch (e) {
                setError(e as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
        const policyTableDefinition = context.language === "es" ? ProductTableDefinitionES : ProductTableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
        setQueryParameters((prev) => ({
            ...prev,
            filters: [
                { field: "client_id", operation: FilterOperation.UUIDEqual, value: id ?? "" }
            ]
        }))
    }, [])

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleMobileTabChange = (newIndex: number) => {
        setTabIndex(newIndex)
    }

    const handleClickTabActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorTabActions(event.currentTarget)
    }

    const [anchorTabActions, setAnchorTabActions] = React.useState<null | HTMLElement>(null)
    const openTabActions = Boolean(anchorTabActions)

    const handleCloseTabActions = () => {
        setAnchorTabActions(null)
    }

    return (
        <Grid>
            <Grid container flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                <Grid item xs>
                    <Typography variant="h5" fontWeight="bold">
                        {data.name}
                    </Typography>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("an_error")}
                    />
                </Grid>
                {!isMobile
                    ? <Grid item sx={{ flexGrow: 0.5, flexShrink: 0, minWidth: "unset", display: "flex", justifyContent: "flex-end" }}>
                        <StyledTabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            sx={{ alignItems: "flex-start" }}
                        >
                            <StyledTab
                                icon={<ExpandMoreIcon />}
                                label={context.t.translate("details")}
                            />
                            <StyledTab
                                icon={<FaRegUserCircle size="25px" />}
                                label={context.t.translate("users")}
                            />
                            <StyledTab
                                icon={<FiUsers size="25px" />}
                                label={context.t.translate("user_logs")}
                            />
                            <StyledTab
                                icon={<TbDeviceHeartMonitor size="25px" />}
                                label={context.t.translate("products")}
                            />
                        </StyledTabs>
                    </Grid>
                    : <>
                        <ActionButton
                            text={context.t.translate("tabs")}
                            icon={<MdTableRows />}
                            onClick={handleClickTabActions}
                        />
                        <Menu open={openTabActions} anchorEl={anchorTabActions} disablePortal>
                            <ClickAwayListener onClickAway={handleCloseTabActions}>
                                <MenuList aria-labelledby="composition-button">
                                    <MenuItem onClick={() => handleMobileTabChange(0)}>
                                        <ExpandMoreIcon style={{ marginRight: "10px" }} />
                                        {context.t.translate("details")}
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMobileTabChange(1)}>
                                        <FaRegUserCircle style={{ marginRight: "12px" }}/>
                                        {context.t.translate("users")}
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMobileTabChange(2)}>
                                        <FiUsers style={{ marginRight: "12px" }}/>
                                        {context.t.translate("user_logs")}
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMobileTabChange(3)}>
                                        <TbDeviceHeartMonitor style={{ marginRight: "12px" }}/>
                                        {context.t.translate("products")}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Menu>
                    </> }

                <Divider sx={{ width: "100%", backgroundColor: "gray", mt: "2px" }} />
            </Grid >
            <Box mt={3}>
                {tabIndex === 0 && <ClientDetailTab />}
                {tabIndex === 1 && <UserList clientID={id as string} />}
                {tabIndex === 2 && <ClientLogList />}
                {tabIndex === 3 && (
                    <GenericTable<Product>
                        entity="Product"
                        columns={cols}
                        dataProvider={(filter) => productService.getAllExt(filter)}
                        onEdit={(elem: Product) => navigate("./" + elem.id)}
                        onDelete={(elem: Product) => productService.delete(elem.id)}
                        externalParameters={queryParameters}
                        dataGridProps={{ checkboxSelection: true, disableRowSelectionOnClick: true }}
                    />
                )}
            </Box>
            {/* TODO: Review this part */}
            {/* <Grid item xs container flexDirection="column" spacing="20px" mt={5}>
                <Grid item container flexDirection="column" paddingTop="0 !important" rowGap="35px">
                    <Products></Products>
                </Grid >
            </Grid > */}
        </Grid>
    )
}

export { ClientDetail }
export default ClientDetail
