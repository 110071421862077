// constants.ts
import Policy, { ActionsType, ConditionField, ConditionTarget, EventType } from "@models/Policy"
import { FilterOperation } from "@utils/queryParams"

export const initValue: Policy = {
    name: "",
    description: "",
    policy_conditions: [],
    id: "",
    policy_actions: ActionsType.Modification,
    policy_actions_data: { type: ActionsType.Modification, data: [] },
    status: true,
    event_type: EventType.VulnerabilityCreated,
    client: { id: "00000000-0000-0000-0000-000000000000" }
}

export const fieldOperatorOptions: Record<ConditionField, { label: string, value: string }[]> = {
    [ConditionField.Severity]: [
        { label: "Equal", value: "eq" },
        { label: "Not Equal", value: "ne" },
        { label: "Less Than", value: "lt" },
        { label: "Greater Than", value: "gt" }
    ],
    [ConditionField.VulnerabilityCve]: [
        { label: "Equal", value: "eq" },
        { label: "Not Equal", value: "ne" },
        { label: "Contains", value: "ct" },
        { label: "Not Contains", value: "nc" }
    ],
    [ConditionField.EPSS]: [
        { label: "Equal", value: "eq" },
        { label: "Not Equal", value: "ne" },
        { label: "Less Than", value: "lt" },
        { label: "Greater Than", value: "gt" }
    ],
    [ConditionField.KEV]: [
        { label: "Equal", value: "eq" }
    ],
    [ConditionField.Status]: [
        { label: "Equal", value: "eq" },
        { label: "Not Equal", value: "ne" }
    ],
    [ConditionField.ID]: [
        { label: "Equal", value: "eq" },
        { label: "Not Equal", value: "ne" }
    ]
}
export const fieldOptionsByTarget = {

    [ConditionTarget.Hierarchy]: [
        "Status"
    ],
    [ConditionTarget.Vulnerability]: [
        "Status"
    ]
}

// Helper arrays for dropdown options
export const actionsTypeOptions = Object.entries(ActionsType).map(([label, value]) => ({ label, value }))
export const eventTypeOptions = Object.entries(EventType).map(([label, value]) => ({ label, value }))
export const conditionTargetOptions = Object.entries(ConditionTarget).map(([label, value]) => ({ label, value }))
export const conditionFieldOptions = Object.entries(ConditionField).map(([label, value]) => ({ label, value }))
export const conditionOperatorOptions = Object.entries(FilterOperation)
    .filter(([key]) => key !== "UnspecifiedFilter") // Filter out UnspecifiedFilter if needed
    .map(([key, value]) => ({ label: key, value }))
