import React, { useCallback, useContext, useEffect, useState } from "react"
import { AlertColor, Grid, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { GridColDef, GridFilterModel, GridRowParams } from "@mui/x-data-grid"
import { CustomAction, GenericTable } from "@components/common/tables/index"
import { AbilityContext, Can } from "@components/permissions/index"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { AssessmentTableDefinition } from "@components/assessment/index"
import { ServicesContext } from "@context/index"
import BomQuality from "@models/BomQuality"
import { Assessment, AssessmentExt } from "@models/index"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import StatsCard from "@components/common/stats/StatsCard"
import FilterDropdown from "@components/filters/FiltersDrawer"
import { Filter, filterOperationMap } from "@models/Filter"
import { MyModalComponents } from "./Modals/MyModalComponents"
import { MyModal } from "./Modals/MyModal"
import { generateCustomActions } from "./CustomActions/CustomActions"

const AssessmentList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const assessmentService = useContext(ServicesContext).assessmentService
    const fileService = useContext(ServicesContext).fileService
    const filterService = useContext(ServicesContext).filterService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [fetchedFilters, setFetchedFilters] = useState<Filter[]>([])
    const [requestError, setRequestError] = React.useState<Error | null>(null)
    const [analysisSuccess, setAnalysisSuccess] = React.useState<boolean>(false)
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const [openComponents, setOpenComponents] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(true)
    const [error, setError] = React.useState<Error | null>(null)
    const [params, setParams] = useState<QueryParameters>({})
    // const [sbomComponents, setSbomComponents] = useState<Component[]>([])
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [queryOptions, setQueryOptions] = useState<GridFilterModel | null>(null)
    const [qualityMetrics, setQualityMetrics] = useState<BomQuality>({ avg_score: 0, scores: [], timestamp: "" })
    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "AssessmentList" })
        fetchFilters()
    }, [])
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info")
    const [appliedFilters, setAppliedFilters] = useState<FilterOption[]>([])
    const handleClose = () => setOpen(false)
    const handleCloseComponents = () => setOpenComponents(false)
    const handleOpen = async (id: string) => {
        const response = await fileService.scoreBom(id, new File([""], "filename"))
        setQualityMetrics(await response.json())
        setOpen(true)
    }
    const handleOpenComponents = async (id: string) => {
        const filters: QueryParameters = { sortField: "name", sortMode: "desc" }

        navigate(`./${id}/component`)
        // try {
        // setLoading(true)
        // const response = await vulnerabilityService.getAllType(filters, "cyclonedx")
        // setSbomComponents(await response.components)
        // // setSbomComponents(await response.vulnerabilities)
        // setRowCountState(response.components.length)
        // setLoading(false)

        // if (response.components.length < (paginationModel.page * paginationModel.pageSize)) {
        //     setPaginationModel({ page: paginationModel.page - 1, pageSize: paginationModel.pageSize })
        // }
        // } catch (e) {
        //     setError(e as Error)
        // }
        // setOpenComponents(true)
    }
    /*
    const handleOpenTemplates = async (id: string) => {
        navigate(`../template/${id}`)
    }
*/
    const fetchFilters = async () => {
        try {
            const response = await filterService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: "assessments" }] })
            console.log(response)
            setFetchedFilters(response.list)
        } catch (error: any) {
            setSnackbarMessage(error.message)
            setOpenSnackbar(true)
        }
    }
    const RequestAnalyze = async (id: string) => {
        try {
            const response = await vulnerabilityService.analyzeByAssessment(id)
            setRequestError({ message: response.message } as Error)
            setAnalysisSuccess(true)
            setSnackbarMessage("Analysis request successful!")
            setSnackbarSeverity("success")
            setOpenSnackbar(true)
        } catch (e: any) {
            console.error(e)
            setError({ message: e.error } as Error)
            setAnalysisSuccess(false)
            setSnackbarMessage("Error requesting analysis")
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
        }
    }
    const ability = useContext(AbilityContext)
    const [cols, setCols] = useState<GridColDef[]>([])
    const customActions = useCallback((params: GridRowParams<AssessmentExt>): CustomAction[] => {
        return generateCustomActions(
            params,
            navigate,
            RequestAnalyze,
            setError,
            handleOpenComponents,
            // handleOpenTemplates,
            ability,
            reloadTableData,
            handleSnackbar
        )
    }, [navigate, RequestAnalyze, setError, handleOpenComponents, /* handleOpenTemplates */ ability])

    useEffect(() => {
        setCols(convertModelDefToGridColDef(AssessmentTableDefinition, ability))
    }, [])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setShowAlert(false)
        }, 8000)

        return () => clearTimeout(timeout)
    }, [analysisSuccess])
    const handleFilterApplied = (filter: Filter) => {
        // Convertimos los filtros de 'Filter' a 'FilterOption' para que coincidan con el formato de 'QueryParameters'
        const newFilters: FilterOption[] = filter.filters.map((f) => ({
            field: f.Field, // Mapear el campo
            operation: filterOperationMap[f.FilterOperation], // Convertimos el FilterOperation de tipo numérico a cadena
            value: f.Value.join(",") // Asumimos que 'Value' es un array de strings y lo convertimos a una cadena
        }))

        // Reemplazar los filtros existentes con los nuevos
        setAppliedFilters(newFilters)
        setParams({ filters: newFilters })
    }

    const handleSnackbar = (message: string, severity: AlertColor) => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity)
        setOpenSnackbar(true)
    }
    const handleFilterDeselected = () => {
        setParams({ filters: [] })
    }
    const reloadTableData = () => {
        setQueryOptions((prev: any) => {
            const newOptions = {
                items: prev?.items ?? [], // Aseguramos que items nunca sea undefined
                logicOperator: prev?.logicOperator ?? "and", // Default logicOperator, por ejemplo
                quickFilterValues: prev?.quickFilterValues ?? [], // Default quickFilterValues
                quickFilterLogicOperator: prev?.quickFilterLogicOperator ?? "and", // Default
                quickFilterExcludeHiddenColumns: prev?.quickFilterExcludeHiddenColumns ?? false // Default excludeHiddenColumns
            }

            return newOptions
        })
        setParams(prev => ({ ...prev }))
        // Forzar re-renderización de la tabla si es necesario (opcional)
        setQueryOptions((prev: any) => ({ ...prev }))
    }

    const dataProvider = useCallback(
        async (filter: any) => {
            try {
                console.log("Fetching data with filters:", { ...filter, ...params })
                const response = await assessmentService.getAll({ ...filter, ...params })
                return response
            } catch (error) {
                console.error("Error fetching data:", error)
                throw error
            }
        },
        [params, params.filters]
    )
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative", paddingLeft: "20px" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="45px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("assessments")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="15px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("sections.reportsAndAnalysis")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    {/*
                    <Can I="create" a="Template" key="template">
                        <ActionButton onClick={() => navigate("../template")} text="templates" icon={<CgTemplate />} />
                    </Can>
                    */}
                    <Can I="create" a="Assessment" key="assessment">
                        <ActionButton onClick={() => navigate("./add")} text={context.t.translate("assess_new")} />
                    </Can>
                </Grid>
            </Grid>

            <StatsCard title={context.t.translate("assessments")} entity="Assessment" />
            <FilterDropdown filters={fetchedFilters} onFilterApplied={handleFilterApplied} onFilterDeselected={handleFilterDeselected} />
            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

            <Grid item container flexDirection="column" sx={{ marginTop: "0px", paddingTop: "0px", paddingLeft: "20px" }} paddingLeft="20px">
                <Grid item container>
                    <GenericTable<Assessment> entity="Assessment" columns={cols}
                        key={JSON.stringify(params)}
                        dataProvider={dataProvider}
                        onEdit={(elem: Assessment) => navigate("./" + elem.id)}
                        onDelete={(elem: Assessment) => assessmentService.delete(elem.id)}
                        customActions={customActions}
                    />
                </Grid>
            </Grid>

            <MyModal open={open} onClose={handleClose} qualityMetrics={qualityMetrics} />
            <MyModalComponents open={openComponents} onClose={handleCloseComponents} />
        </Grid>
    )
}
export { AssessmentList }
export default AssessmentList
