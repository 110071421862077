import { Button, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React from "react"

interface ProviderNameNavigateProps {
    value: string;
    id: string;
}

const ProviderNameNavigate: React.FC<ProviderNameNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`./provider/${id}`, { state: { row: value } })
    }

    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                sx={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": {
                        color: "secondary.main" // Change this to the desired hover color
                    }
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}
export { ProviderNameNavigate }
export default ProviderNameNavigate
