import { Grid, Typography, useTheme } from "@mui/material"
import { useContext, useState } from "react"
import { I18nContext } from "I18nProvider"
import ServicesContext from "@context/ServicesContext"
import { SecurityAdvisory } from "@models/SecurityAdvisory"
import { GenericTable } from "@components/common/tables"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { Link } from "@mui/icons-material"

type SecurityAdvisoriesTableProps = {
    id: string | undefined
}

const SecurityAdvisoriesTable = ({ id }: SecurityAdvisoriesTableProps) => {
    const hardwareComponentsService = useContext(ServicesContext).hardwareComponentService
    const theme = useTheme()
    const context = useContext(I18nContext)

    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const [rows, setRows] = useState<SecurityAdvisory[]>([])
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [loading, setLoading] = useState(true) // Estado para el loading

    // Función para obtener los datos de las advisories
    const columns = [
        { field: "name", headerName: context.t.translate("name"), flex: 3 },
        { field: "version", headerName: context.t.translate("affectedVersions"), flex: 2 },
        { field: "details", headerName: context.t.translate("details"), flex: 2 },
        {
            field: "url",
            headerName: context.t.translate("remediation"),
            flex: 1,
            renderCell: (params: any) => {
                return params.value
                    ? (
                        <a
                            href={params.value}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                        >
                            <Link />
                        </a>
                    )
                    : null
            }
        },
        {
            field: "title",
            headerName: context.t.translate("sourceDocument"),
            flex: 4,
            renderCell: (params: any) => {
                const row = params.row as SecurityAdvisory
                return (
                    <a
                        href={row.html}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                    >
                        {row.title}
                    </a>
                )
            }
        }
    ]

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Security Advisories Section">
            <Grid item xs sx={{ position: "sticky", top: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("securityAdvisories")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={handleSnackbarClose}
                            message={error?.message || context.t.translate("an_error")} // Mostrar el mensaje de error
                        />
                        <GenericTable<SecurityAdvisory>
                            entity="SecurityAdvisory"
                            columns={columns}
                            dataProvider={(filter) => hardwareComponentsService.getSecurityAdvisories(id as string)}
                            isInModal={false}
                            disabledEdit={false}
                            disabledActions={false}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SecurityAdvisoriesTable
