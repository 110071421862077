import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { TMetadataSCAContent } from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../Styles"
import { Footer, Header } from "./HeaderFooter"

const SimplifiedIndex: React.FC<{ metadata?: TMetadataSCAContent; lang?: "es" | "en" }> = ({ metadata, lang }) => {
    const i18n = {
        es: {
            Titulo: "Índice",
            indice: metadata
                ? [
                    metadata?.introduction && "Introducción..........................................................3",
                    metadata?.executive_summary && "Resumen ejecutivo................................................4",
                    metadata?.table_of_content && "Siguientes pasos.......................................................5",
                    metadata?.scope && "Identificación de vulnerabilidades............................7"
                ].filter(Boolean)
                : [
                    "Introducción..........................................................3",
                    "Resumen ejecutivo................................................4",
                    "Siguientes pasos.......................................................5",
                    "Identificación de vulnerabilidades............................7"
                ],
            normativas: [
                { id: "Normativas" },
                { id: "- Open Web Application Security Project (OWASP)" },
                { id: "- IEC 62443-4-1" },
                { id: "- The Penetration Testing Execution Standard Documentation" },
                { id: "- PCI Data Security Standard (PCI DSS)" }
            ]
        },
        en: {
            Titulo: "Index",
            indice: metadata
                ? [
                    metadata?.introduction && "Introduction..........................................................3",
                    metadata?.executive_summary && "Executive Summary...............................................4",
                    metadata?.table_of_content && "Next Steps.......................................................5",
                    metadata?.scope && "Vulnerability Identification............................7"
                ].filter(Boolean)
                : [
                    "Introduction..........................................................3",
                    "Executive Summary................................................4",
                    "Next Steps........................................................5",
                    "Vulnerability Identification............................7"
                ],
            normativas: [
                { id: "Regulations" },
                { id: "- Open Web Application Security Project (OWASP)" },
                { id: "- IEC 62443-4-1" },
                { id: "- The Penetration Testing Execution Standard Documentation" },
                { id: "- PCI Data Security Standard (PCI DSS)" }
            ]
        }
    } as const

    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en"

    if (lang === "es" || lang === "en") {
        locale = lang
    } else if (context?.language === "es" || context?.language === "en") {
        locale = context.language
    } else {
        console.warn(`Idioma no soportado o indefinido: ${lang || context?.language}. Usando idioma por defecto: "en".`)
    }

    const renderIndex = () => {
        return i18n[locale].indice.map((point, index) => {
            return (
                <Text
                    key={index}
                    style={{
                        ...styles.puntuak2,
                        paddingLeft: 40,
                        marginRight: -30,
                        paddingBottom: 5
                    }}
                >
                    <Text
                        style={{
                            ...styles.puntuak,
                            textAlign: "left",
                            fontFamily: "Bold",
                            fontSize: 10.5,
                            marginRight: -20
                        }}
                    >
                        {index + 1}. {point}
                    </Text>
                </Text>
            )
        })
    }

    return (
        <Page size="A4">
            <Header ></Header>

            <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 100, paddingRight: 10 }}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].Titulo}</Text>
                <View>{renderIndex()}</View>
                <Text>&nbsp;</Text>
                <View style={{ ...styles.tableScope, width: "100%" }}>
                    <Text
                        style={{
                            ...styles.tableColScope,
                            textAlign: "left",
                            width: "58.25%",
                            height: 120,
                            fontSize: 10.5
                        }}
                    >
                        {i18n[locale].normativas.map((fila, index) =>
                            index === 0 ? fila.id : `\n\n${fila.id}`
                        )}
                    </Text>
                </View>

            </View>
            <Footer ></Footer>
        </Page>
    )
}

const FooterFrontPage: React.FC<{lang?: string}> = ({ lang }) => {
    const i18n = {
        es: {
            footer: {
                copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
                confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
                disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
                errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
                tlpAmber: "TLP-AMBER"
            }
        },
        en: {
            footer: {
                copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
                confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
                disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
                errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
                tlpAmber: "TLP-AMBER"
            }

        }
    } as const
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View>
            <View>
                <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                    {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                    {i18n[locale].footer.disclosureWarning}
                    {i18n[locale].footer.errorNotice}
                </Text>
            </View>
        </View>
    )
}

export { SimplifiedIndex }
