import React, { useContext, useState } from "react"
import { Box, Grid } from "@mui/material"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { I18nContext } from "I18nProvider"
import { useStatsData } from "./useStatsData"

interface VulnerabilityStatsProps {
    timeRange?: string;
}

const CAFVulnerabilityStats: React.FC<VulnerabilityStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const [client, setClient] = useState<string>("")
    const [filter1, setFilter1] = useState<string>("")
    const [filter2, setFilter2] = useState<string>("")
    const [filter3, setFilter3] = useState<string>("")
    const [filter4, setFilter4] = useState<string>("")

    // Llamada a useStatsData con los nuevos filtros
    const {
        detectedState,
        inTriageState,
        triageFinishState,
        detectedTotal,
        inTriageTotal,
        triageFinishTotal,
        topItems,
        isLoading
    } = useStatsData("CAFVulnerability", timeRange, client)
    // Si está cargando o no hay contexto, no renderizamos nada
    if (isLoading || !context) return null
    return (
        <Grid container spacing={2} sx={{ width: "100%", flexWrap: "wrap" }}>
            {/* <ClientSelector value={client} onSelect={(id) => setClient(id || "")} /> */}
            {/* Columna de Detected */}
            <Grid item xs={12} sm={4}>
                <Box sx={{ width: "100%" }}>
                    <GenericDonutChart
                        title={context.t.translate("detected")}
                        dataType="array"
                        data={detectedState || []}
                        number={detectedTotal ?? 0}
                        footer="TOTAL"
                    />
                </Box>
            </Grid>
            {/* Columna de In Triaged */}
            <Grid item xs={12} sm={4}>
                <Box sx={{ width: "100%" }}>
                    <GenericDonutChart
                        title={context.t.translate("in_triage")}
                        dataType="array"
                        data={inTriageState || []}
                        number={inTriageTotal ?? 0}
                        footer="TOTAL"

                    />
                </Box>
            </Grid>
            {/* Columna de Triaged Finished */}
            <Grid item xs={12} sm={4}>
                <Box sx={{ width: "100%" }}>
                    <GenericDonutChart
                        title={context.t.translate("triage_finish")}
                        dataType="array"
                        data={triageFinishState || []}
                        number={triageFinishTotal || 0}
                        footer="TOTAL"
                    />
                </Box>
            </Grid>

            {/*
                    <FormControl fullWidth>
                        <InputLabel>Filtro 1</InputLabel>
                        <Select
                            value={filter1}
                            onChange={(e) => setFilter1(e.target.value)}
                            label="Filtro 1"
                        >
                            <MenuItem value="">---</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>Filtro 2</InputLabel>
                        <Select
                            value={filter2}
                            onChange={(e) => setFilter2(e.target.value)}
                            label="Filtro 2"
                        >
                            <MenuItem value="">---</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>Filtro 3</InputLabel>
                        <Select
                            value={filter3}
                            onChange={(e) => setFilter3(e.target.value)}
                            label="Filtro 3"
                        >
                            <MenuItem value="">---</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>Filtro 4</InputLabel>
                        <Select
                            value={filter4}
                            onChange={(e) => setFilter4(e.target.value)}
                            label="Filtro 4"
                        >
                            <MenuItem value="">---</MenuItem>

                        </Select>
                    </FormControl>  */}

            {/* Opcional: Mostrar los top items */}
            {/* {topItems && topItems.length > 0 && (
                <Grid item xs={3} width="100%">
                    <TopItemsList
                        title={context.t.translate("top5_components")}
                        items={topItems}
                        valueKey="total"
                        nameKey="name"
                    />
                </Grid>
            )} */}
        </Grid>
    )
}

export default CAFVulnerabilityStats
