// NotificationForm.tsx
import React, { useContext, useEffect, useState } from "react"
import { Box, Chip, Grid, TextField, Typography } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"
import ServicesContext from "@context/ServicesContext"
import { NotificationActionsData, PolicyNotificationConfig } from "@models/Policy"
import PolicyNotificationConfigsForm from "@views/policy/PolicyNotificationConfigsForm"

interface NotificationFormProps {
  data: NotificationActionsData;
  onChange: (data: NotificationActionsData) => void;
}

const NotificationForm: React.FC<NotificationFormProps> = ({ data, onChange }) => {
    const [policyNotificationConfigs, setPolicyNotificationConfigs] = useState<PolicyNotificationConfig[]>([])
    const notificationConfigService = useContext(ServicesContext).notificationConfigService

    useEffect(() => {
        const fetchData = async () => {
            try {
                const notificationConfigs = await notificationConfigService.getAllByClient("")
                const items: PolicyNotificationConfig[] = []
                for (const notificationConfig of notificationConfigs.list) {
                    const item: PolicyNotificationConfig = {
                        value: notificationConfig.transport,
                        label: notificationConfig.transport_label,
                        isAvailable: notificationConfig.enabled,
                        isActive: data.transport_types.includes(notificationConfig.transport)
                    }
                    items.push(item)
                }
                setPolicyNotificationConfigs(items)
            } catch (e: any) {
                console.log(e)
            }
        }
        fetchData()
    }, [])

    const [emailInput, setEmailInput] = useState<string>("")
    const [error, setError] = useState<string>("")

    // Basic email validation
    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleAddEmail = () => {
    // Trim whitespace
        const trimmedEmail = emailInput.trim()

        // Validate email
        if (!trimmedEmail) {
            setError("Email cannot be empty")
            return
        }

        if (!isValidEmail(trimmedEmail)) {
            setError("Please enter a valid email address")
            return
        }

        // Check if email already exists
        if (data.emails.includes(trimmedEmail)) {
            setError("This email is already added")
            return
        }

        // Add email to the list
        const updatedEmails = [...data.emails, trimmedEmail]
        onChange({ emails: updatedEmails, transport_types: data.transport_types })
        setEmailInput("")
        setError("")
    }

    const handleRemoveEmail = (emailToRemove: string) => {
        const updatedEmails = data.emails.filter(email => email !== emailToRemove)
        onChange({ emails: updatedEmails, transport_types: data.transport_types })
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault()
            handleAddEmail()
        }
    }

    const handleNotificationConfigChange = (transports: string[]) => {
        onChange({ emails: data.emails, transport_types: transports })
    }

    return (
        <Grid container spacing={2} sx={{ mb: 3, mt: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h6" fontFamily="Griff" color="primary" fontWeight="bold" mb={1}>
          Notification Recipients
                </Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
                <TextField
                    fullWidth
                    variant="filled"
                    label="Email Address"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    error={!!error}
                    helperText={error}
                />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ display: "flex", alignItems: "center" }}>
                <ActionButton
                    onClick={handleAddEmail}
                    text="Add Email"
                    style={{ height: "56px" }}
                />
            </Grid>

            <Grid item xs={12}>
                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    p: 2,
                    minHeight: "50px",
                    border: data.emails.length ? "none" : "1px dashed #ccc",
                    borderRadius: "4px"
                }}>
                    {data.emails.length > 0
                        ? (
                            data.emails.map((email) => (
                                <Chip
                                    key={email}
                                    label={email}
                                    onDelete={() => handleRemoveEmail(email)}
                                    color="primary"
                                    variant="outlined"
                                />
                            ))
                        )
                        : (
                            <Typography color="text.secondary" fontStyle="italic">
              No email recipients added yet
                            </Typography>
                        )}
                </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
                <PolicyNotificationConfigsForm
                    data={policyNotificationConfigs}
                    onChange={handleNotificationConfigChange}
                ></PolicyNotificationConfigsForm>
            </Grid>
        </Grid>
    )
}

export default NotificationForm
