import React, { useContext, useState } from "react"
import Highcharts, { Chart } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

highchartsAccessibility(Highcharts)

export interface ColumnValues {
    values: {
        critical: { detected: number, in_triage: number, triage_finished: number };
        high: { detected: number, in_triage: number, triage_finished: number };
        medium: { detected: number, in_triage: number, triage_finished: number };
        low: { detected: number, in_triage: number, triage_finished: number };
        none: { detected: number, in_triage: number, triage_finished: number };
    },

}

const HierarchyColumnChart: React.FC<ColumnValues> = (props: ColumnValues) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const [legend, setLegend] = useState(<></>)
    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "column",
            backgroundColor: "transparent",
            borderWidth: 0,
            borderColor: theme.palette.secondary.contrastText,
            plotBorderWidth: 0,
            height: 350,
            width: 400,
            style: {
                fontFamily: "Roboto",
                fontWeight: "700"
            }
        },
        credits: { enabled: false },
        legend: {
            // enabled: true,
            // title: { text: "Vulnerabilities", style: { fontSize: "25px", fontFamily: "Griff", color: "white", fontWeight: "normal" } },
            // align: "right",
            // layout: "vertical",
            // verticalAlign: "top",
            // itemStyle: { color: "white" },
            // padding: 3,
            // itemMarginTop: 15,
            // itemMarginBottom: 5,
            // y: 180
            enabled: false

        },
        plotOptions: {
            column: {
                colorByPoint: false,
                borderRadius: 0,
                borderWidth: 0
            }
        },
        xAxis: {
            categories: [context.t.translate("critical"), context.t.translate("high"), context.t.translate("medium"), context.t.translate("low"), context.t.translate("none")],
            labels: {
                style: {
                    color: theme.palette.primary.main,
                    font: "Roboto"
                }
            },
            tickWidth: 1,
            tickColor: theme.palette.primary.main
        },
        yAxis: {
            lineWidth: 1,
            lineColor: theme.palette.primary.main,
            gridLineWidth: 0,
            tickWidth: 1,
            tickColor: theme.palette.primary.main,
            plotLines: [{
                value: 0,
                width: 1,
                color: theme.palette.primary.main,
                zIndex: 10
            }],
            title: {
                text: context.t.translate("hierarchy_graphic"),
                style: {
                    color: theme.palette.primary.main,
                    font: "16px Roboto"
                },
                margin: 20
            },
            labels: {
                style: {
                    color: theme.palette.primary.main,
                    font: "Roboto"
                }
            }
        },
        series: [
            {
                name: context.t.translate("detected"),
                data: [
                    { x: 0, y: props.values.critical.detected, color: "#7940A540" }, // Morado claro
                    { x: 1, y: props.values.high.detected, color: "#D9534F40" }, // Rojo claro
                    { x: 2, y: props.values.medium.detected, color: "#EC971F40" }, // Naranja claro
                    { x: 3, y: props.values.low.detected, color: "#F2CC0C40" }, // Amarillo claro
                    { x: 4, y: props.values.none.detected, color: "#A9A9A940" } // Gris claro
                ],
                type: "column",
                dataLabels: {
                    enabled: true,
                    align: "center",
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontWeight: "bold"
                    }
                }
            }, {
                name: context.t.translate("in_triage"),
                data: [
                    { x: 0, y: props.values.critical.in_triage, color: "#7940A599" }, // Morado medio
                    { x: 1, y: props.values.high.in_triage, color: "#D9534F99" }, // Rojo medio
                    { x: 2, y: props.values.medium.in_triage, color: "#EC971F99" }, // Naranja medio
                    { x: 3, y: props.values.low.in_triage, color: "#F2CC0C99" }, // Amarillo medio
                    { x: 4, y: props.values.none.in_triage, color: "#A9A9A999" } // Gris medio
                ],
                color: "#D9534F",
                type: "column",
                dataLabels: {
                    enabled: true,
                    align: "center",
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontWeight: "bold"
                    }
                }
            }, {
                name: context.t.translate("triage_finish"),
                data: [
                    { x: 0, y: props.values.critical.triage_finished, color: "#7940A5" }, // Morado sólido
                    { x: 1, y: props.values.high.triage_finished, color: "#D9534F" }, // Rojo sólido
                    { x: 2, y: props.values.medium.triage_finished, color: "#EC971F" }, // Naranja sólido
                    { x: 3, y: props.values.low.triage_finished, color: "#F2CC0C" }, // Amarillo sólido
                    { x: 4, y: props.values.none.triage_finished, color: "#A9A9A9" } // Gris sólido
                ],
                color: "#D9534F",
                type: "column",
                dataLabels: {
                    enabled: true,
                    align: "center",
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontWeight: "bold"
                    }
                }
            }
        ],
        tooltip: {
            enabled: false
        },
        exporting: {
            enabled: false // Esto ocultará el botón de menú de opciones de exportación
        }
    }
    const chartCallback = (chart: Chart) => {
        setLegend(<Grid container flexDirection="row" flexWrap="nowrap" spacing={4} height="100%" alignItems="center" justifyContent="space-between" sx={{ overflow: "auto" }}>
            {chart.series.map((serie, i) =>
                <Grid key={i} item margin="auto">
                    <Typography fontSize="14px" fontWeight="700" lineHeight="32px" color="#271968">{serie.name}</Typography>
                    <Grid container item flexDirection="column" spacing="8px">
                        {serie.data.map((point, j) => {
                            return (<Grid key={j} item container alignItems="center" spacing="4px">
                                <Grid item><span style={{ height: "10px", width: "10px", backgroundColor: point.color as any, borderRadius: "50%", display: "block" }}></span></Grid>
                                <Grid item><Typography component="span" display="block" fontSize="10px">{point.category}</Typography></Grid>
                            </Grid>)
                        })}
                    </Grid>
                </Grid>
            )}
        </Grid>)
    }

    return (
        <Grid
            container
            justifyContent="center"
            flexDirection="row"
            spacing={1}
            wrap="nowrap"
            style={{ width: "100%", height: "100%" }}
        >
            <Grid>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    callback={chartCallback}
                    containerProps={{ style: { width: "100%", height: "100%" } }}

                />
            </Grid>
            <Grid>
                {legend}
            </Grid>
        </Grid>
    )
}

export { HierarchyColumnChart }
export default HierarchyColumnChart
