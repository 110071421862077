
import { Vulnerability } from "@models/index"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import { FilterOperation, QueryParameters } from "@utils/index"

class VulnerabilityService extends Service<Vulnerability> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/vulnerability", MediaTypes.JSON)
    }

    public getAllLimited = (params: QueryParameters, size: number) => {
        return this.http.getMultiple(`/${this.path}`, params, [`page_size=${size}&type=csv`]) as any
    }

    // TODO: Recode this method to use the QueryParametersToURL function

    public getAllFormatted = (id: any, type: string) => {
        return this.http.getMultiple(`/${this.path}`, { filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: id }] }, [`type=${type}`]) as any
    }

    public getAllType = (params: QueryParameters, type: string) => {
        return this.http.getMultiple(`/${this.path}`, params, [`type=${type}`]) as any
    }

    public analyze = () => {
        return this.http.get(`/${this.path}/analyze`) as any
    }

    public analyzeByAssessment = (id: any) => {
        return this.http.get(`/${this.path}/analyze/${id}`) as any
    }

    public getAllCafVulns (params?: QueryParameters) {
        return this.http.getMultiple(`/${this.path}/caf`, params) as any
    }

    public getbyIdExt = (cve: string) => {
        return this.http.get(`/${this.path}/caf/${cve}`) as any
    }
}

export { VulnerabilityService }
export default VulnerabilityService
