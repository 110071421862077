import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, IconButton, MenuItem, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { ProductSelector } from "@components/product/index"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType } from "@models/index"
import ClientSelector from "@components/client/ClientSelector"
import { AssessmentTypeKeysValueOps } from "@components/assessment"
import { FilterOperation } from "@utils/queryParams"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"
import TemplateSelector from "@components/template/TemplateSelector"
import { useTrack } from "@components/track/TrackContext"
import ActionButton from "@components/common/Button/ActionButton"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

const initValue: Assessment = {
    id: "",
    type: AssessmentType.SCA,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "00000000-0000-0000-0000-000000000000" },
    client: { id: "00000000-0000-0000-0000-000000000000" },
    analysis_status: 0,
    template_id: "00000000-0000-0000-0000-000000000000"
}

const AssessmentForm: React.FC = () => {
    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "AssessmentForm" })
    }, [])
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    // TODO: if permissions show pentest
    const [openPentest, setOpenPentest] = React.useState(false)
    const handleClosePentest = () => setOpenPentest(false)
    const assessmentService = useContext(ServicesContext).assessmentService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [formData, setFormData] = useState<Assessment>({
        ...initValue,
        status: AssessmentStatus.Planned,
        contractDate: moment().format("YYYY-MM-DDTHH:mm:ssZ")
    })
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.contractDate === context.t.translate("invalid_date"),
            formData.contractDate === "",
            formData.contractDate === undefined,
            formData.contractDate === null
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    const assessmentTypeDescriptions: { [key in AssessmentType]: string } = {
        [AssessmentType.CRT]: context.t.translate("assessment_type_crt"),
        [AssessmentType.FunctionalTest]: context.t.translate("assessment_type_functional_test"),
        [AssessmentType.LogicalPentest]: context.t.translate("assessment_type_logical_pentest"),
        [AssessmentType.PortScan]: context.t.translate("assessment_type_port_scan"),
        [AssessmentType.NetworkScan]: context.t.translate("assessment_type_network_scan"),
        [AssessmentType.WebScan]: context.t.translate("assessment_type_web_scan"),
        [AssessmentType.SCA]: context.t.translate("assessment_type_sca"),
        [AssessmentType.ContainerScan]: context.t.translate("assessment_type_container_scan"),
        [AssessmentType.ThreatModel]: context.t.translate("assessment_type_threat_model"),
        [AssessmentType.StaticAnalysis]: context.t.translate("assessment_type_static_analysis"),
        [AssessmentType.DynamicAnalysis]: context.t.translate("assessment_type_dynamic_analysis")
    }
    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }
    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // API Call create new assessment
    const navigate = useNavigate()
    const handleSubmit = async () => {
        try {
            await assessmentService.create(formData)
            navigate(-1)
            throw new Error(context.t.translate("error_testing"))
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call create new assessment - END
    function formatLabel (label: string) {
        const acronymLabels = ["CRT", "SCA"]
        if (acronymLabels.includes(label)) {
            return label
        }
        return label.replace(/([a-z])([A-Z])/g, "$1 $2")
    }

    const canSeeCRT = ability.can("read", "Achilles")
    const canSeeLogicalPentest = ability.can("read", "Pentest")

    const filteredAssessmentTypes = AssessmentTypeKeysValueOps.filter(opt => {
        if (opt.value === AssessmentType.CRT && !canSeeCRT) {
            return false
        }
        if (opt.value === AssessmentType.LogicalPentest && !canSeeLogicalPentest) {
            return false
        }
        return true
    })
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <Grid container spacing={2}>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("an_error")}
                    />
                    <Grid item container flexDirection="row" alignItems='flex-start' spacing="25px">
                        <Can key="viewClient" I="*" an="Client">
                            <Grid item xs={12}>
                                <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                            </Grid>
                        </Can>
                        <Grid item xs={12} md={6}>
                            <ProductSelector value={formData.product.id} customFilters={ability.can("*", "Client") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }] : undefined} onSelect={(id, p) => {
                                handleInputChange([{ target: { name: "product", value: { id } } }])
                            }}></ProductSelector>
                        </Grid>
                        {/* {ability.can("*", "Client") && (
                            <Grid item xs={12} md={6}>
                                <TextField select required fullWidth variant="filled" label={context.t.translate("status")} name="status"
                                    value={formData.status} onChange={handleInputChange}>
                                    {AssessmentStatusKeysValueOps.map((opt, idx) => (
                                        <MenuItem key={idx} value={opt.value}>
                                            <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>
                                                {opt.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )} */}
                        {/*  )
                            // : (
                            //     <Grid item xs={12} md={6}>
                            //         <TextField disabled fullWidth variant="filled" label={context.t.translate("status")} value={AssessmentStatus.Planned} />
                            //     </Grid>
                            // )} */}
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                required
                                fullWidth
                                variant="filled"
                                label={context.t.translate("type")}
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                            >
                                {filteredAssessmentTypes.map((opt, idx) => {
                                    const formattedLabel = formatLabel(opt.label)
                                    const description = assessmentTypeDescriptions[AssessmentType[opt.label]] // Obtener la descripción correspondiente

                                    return (
                                        <MenuItem key={idx} value={opt.value}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>
                                                    {formattedLabel}
                                                </Typography>

                                                <Tooltip title={description} arrow>
                                                    <IconButton size="small">
                                                        <HelpOutlineIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TemplateSelector value={formData.template_id} customFilters={ability.can("*", "Client") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }, { field: "assessment_type", operation: FilterOperation.EnumEqual, value: formData.type }] : [{ field: "assessment_type", operation: FilterOperation.EnumEqual, value: formData.type }]} onSelect={(id, p) => {
                                handleInputChange([{ target: { name: "template_id", value: id } }])
                            }}></TemplateSelector>
                        </Grid>

                        {/* <Grid item container flexDirection="row" alignItems='flex-start' spacing="25px">
                            {ability.can("*", "Client") && (
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label={context.t.translate("assess_date_contrac")}
                                        value={formData.contractDate != null ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                                        slots={{
                                            textField: (params) => <TextField required variant='filled' {...params} />
                                        }}
                                    />
                                </Grid>
                            )}

                            {ability.can("*", "Client") && (
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label={context.t.translate("assess_date_start")}
                                        value={formData.startDate != null ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                        slots={{
                                            textField: (params) => <TextField variant='filled' {...params} />
                                        }}
                                    />
                                </Grid>
                            )}

                            {ability.can("*", "Client") && (
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label={context.t.translate("assess_date_end")}
                                        value={formData.endDate != null ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                        slots={{
                                            textField: (params) => <TextField variant='filled' {...params} />
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid> */}

                    </Grid>
                    <Grid item xs={12}>
                        <ActionButton type="button" variant="contained" style={{ width: "100%" }} onClick={handleSubmit} disabled={!formValid()} text={context.t.translate("add_new")} />
                    </Grid>
                </Grid>

            </Box>
        </Box>
    )
}

export { AssessmentForm }
export default AssessmentForm
