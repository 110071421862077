import ServicesContext from "@context/ServicesContext"
import { Chip } from "@mui/material"
import { QueryParameters } from "@utils/queryParams"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface TagColoredProps {
    value: string;
  }

const TagColored: React.FC<TagColoredProps> = ({ value }) => {
    const tagService = useContext(ServicesContext).tagService
    const [tagNames, setTagNames] = useState<{ id: string, name: string, color: string }[]>([])
    const [error, setError] = useState<Error|null>(null)
    const navigate = useNavigate()
    useEffect(() => {
        const init = async () => {
            try {
                const filters: QueryParameters = { sortField: "name" }
                const data = await tagService.getAll(filters)
                const dato = value
                const tagNames = data.list.filter(item => item.products.includes(dato)).map(item => ({ id: item.id, name: item.name, color: item.color }))

                setTagNames(tagNames)
            } catch (e: any) {
                setError(e as Error)
            }
        }
        init()
    }, [value])

    return (
        <>
            {tagNames.map((tagName, index) => (
                <Chip
                    key={index}
                    size="small"
                    style={{
                        fontWeight: "bolder",
                        color: "black",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        backgroundColor: tagName.color,
                        margin: "2px"
                    }}
                    label={tagName.name}
                    onClick={() => navigate(`../manage/tags/${tagName.id}`) }
                />
            ))}
        </>
    )
}

export { TagColored }
export default TagColored
