import React, { useContext, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { ClickAwayListener, Divider, Grid, Menu, MenuItem, MenuList, Tooltip, Typography, useTheme } from "@mui/material"
import { ServicesContext } from "@context/index"
import { CafVulnStatus, Vulnerability, VulnerabilityState } from "@models/index"
import "./styles.css"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import IssueList from "@views/issue/IssueList"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { StyledTab, StyledTabs } from "@views/product/Components/StyledTabs"
import { NumberColoredCVSS, VulnerabilityStateColored } from "@components/vulnerability"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { FaJira } from "react-icons/fa"
import { MdOutlineDataObject, MdTableRows } from "react-icons/md"
import { PiSealWarning } from "react-icons/pi"
import { BiSolidComponent } from "react-icons/bi"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import useMediaResponsive from "@hooks/useMobile"
import ActionButton from "@components/common/Button/ActionButton"
import AffectedComponentList from "./Components/AffectedComponent"
import Metadata from "./Components/Metadata"
import KevList from "./Components/KevList"
import VulnDetailsTab from "./VulnDetailsTab"

const initValue: Vulnerability = {
    id: "",
    identifier: "",
    score: 0,
    revised_score: 0,
    priority: 0,
    epss: 0,
    percentile: 0,
    kev: false,
    supressed: false,
    vector: "",
    description: "",
    state: VulnerabilityState.Detected,
    justification: null,
    response: [],
    revised_severity: -1,
    time_spent: -1,
    cmp_name: undefined,
    cmp_version: undefined,
    assessment: { id: "" },
    product: { id: "" },
    client: { id: "" },
    origin: "",
    metadata: undefined,
    generator: undefined,
    generator_time: undefined,
    affected_component: "",
    rank: 0,
    status: CafVulnStatus.LowRisk
}

const VulnerabilityDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const componentService = useContext(ServicesContext).componentService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    // const { id } = useParams<{ id: string }>()
    const [formData, setFormData] = useState<Vulnerability>(initValue)
    const [prevFormData, setPrevFormData] = useState<Vulnerability>(initValue)
    const isMobile = useMediaResponsive("(max-width: 780px)")
    const location = useLocation()
    const { row } = location.state || {}
    const { id } = useParams()
    const [tabIndex, setTabIndex] = useState(0)

    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "VulnerabilityDetail" })
    }, [])

    // Generic change handler for all form fields

    // Generic change handler for all form fields - END

    // Fetch vulnerability data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await vulnerabilityService.get(id as string)
                setFormData(val)
                setPrevFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [])

    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }

    const handleMobileTabChange = (newIndex: number) => {
        setTabIndex(newIndex)
    }

    const [anchorTabActions, setAnchorTabActions] = React.useState<null | HTMLElement>(null)
    const openTabActions = Boolean(anchorTabActions)

    const handleClickTabActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorTabActions(event.currentTarget)
    }

    const handleCloseTabActions = () => {
        setAnchorTabActions(null)
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <StyledBox>
                <Grid>
                    <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                        {context.t.translate("vuln_details")}
                    </Typography>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("error_message")}
                />
                <Grid container flexDirection="row" alignItems="center" xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid sx={{ flexDirection: { xs: "column", md: "row" } }} display="flex" gap={2}>
                            <Grid xs={12} item sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="body2" sx={{ marginRight: 1 }}>
                                    <strong>{context.t.translate("status")}:</strong>
                                </Typography>
                                {formData.status
                                    ? <Tooltip title={context.t.translate("status")}>
                                        <VulnerabilityStateColored value={formData.status}/>
                                    </Tooltip>
                                    : <Typography><strong>{context.t.translate("unknown_status")}</strong></Typography>}
                            </Grid>

                            <Grid xs={12} item sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="body2" sx={{ marginRight: 1 }}>
                                    <strong>{context.t.translate("bench_criti")}:</strong>
                                </Typography>
                                <Tooltip title={context.t.translate("status")}>
                                    <NumberColoredCVSS value={formData.score}/>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        {!isMobile
                            ? <Grid item sx={{ flexGrow: 0.3, flexShrink: 0, minWidth: "unset", display: "flex", justifyContent: "flex-end" }}>
                                <StyledTabs value={tabIndex} onChange={handleTabChange} >
                                    <StyledTab icon={<ExpandMoreIcon />} label="Details" />
                                    {formData.metadata && Object.keys(formData.metadata).length > 0 && (
                                        <StyledTab icon={<MdOutlineDataObject />} label={context.t.translate("vulner_meta")} />
                                    )}
                                    <StyledTab icon={<BiSolidComponent />} label="Affected Components" />
                                    <StyledTab icon={<FaJira />} label="Jira Issues" />
                                    <StyledTab icon={<PiSealWarning />} label="Known Exploits" />
                                </StyledTabs>
                            </Grid>
                            : <Grid pt={2}>
                                <ActionButton
                                    text={context.t.translate("tabs")}
                                    icon={<MdTableRows />}
                                    onClick={handleClickTabActions}
                                />
                                <Menu open={openTabActions} anchorEl={anchorTabActions} disablePortal>
                                    <ClickAwayListener onClickAway={handleCloseTabActions}>
                                        <MenuList aria-labelledby="composition-button">
                                            <MenuItem onClick={() => handleMobileTabChange(0)}>
                                                <ExpandMoreIcon sx={{ marginRight: 1 }} />
                                                {context.t.translate("details")}
                                            </MenuItem>

                                            {formData.metadata && Object.keys(formData.metadata).length > 0 && (
                                                <MenuItem onClick={() => handleMobileTabChange(1)}>
                                                    <MdOutlineDataObject style={{ marginRight: 8 }} />
                                                    {context.t.translate("vulner_meta")}
                                                </MenuItem>
                                            )}

                                            <MenuItem onClick={() => handleMobileTabChange(formData.metadata ? 2 : 1)}>
                                                <BiSolidComponent style={{ marginRight: 8 }} />
                                            Affected Components
                                            </MenuItem>

                                            <MenuItem onClick={() => handleMobileTabChange(formData.metadata ? 3 : 2)}>
                                                <FaJira style={{ marginRight: 8 }} />
                                            Jira Issues
                                            </MenuItem>

                                            <MenuItem onClick={() => handleMobileTabChange(formData.metadata ? 4 : 3)}>
                                                <PiSealWarning style={{ marginRight: 8 }} />
                                            Known Exploits
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Menu>
                            </Grid>}
                    </Grid>

                </Grid>
                <Divider sx={{ width: "100%", backgroundColor: "gray", mt: "5px" }} />
                <Grid item xs={12} paddingLeft="20px">
                    {tabIndex === 0
                        ? (
                            <VulnDetailsTab />
                        )
                        : tabIndex === 1 && formData.metadata && Object.keys(formData.metadata).length > 1
                            ? (
                                <Metadata formData={formData} />
                            )
                            : tabIndex === (formData.metadata ? 2 : 1)
                                ? (
                                    <AffectedComponentList row={row} />
                                )
                                : tabIndex === (formData.metadata ? 3 : 2)
                                    ? (
                                        <IssueList row={row} />
                                    )
                                    : (
                                        <KevList id={formData.identifier} />

                                    )}
                </Grid>
            </StyledBox>
        </Grid >
    )
}

export { VulnerabilityDetail }
export default VulnerabilityDetail
