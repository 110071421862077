
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from "react"
import { I18nContext } from "I18nProvider"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"
import SAST from "@models/SAST"
import SASTTableDefinition from "@components/sast/sastTableDefinition"

const SASTList: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "SASTList" })
    }, [])
    useEffect(() => {
        setCols(convertModelDefToGridColDef(SASTTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    const navigate = useNavigate()
    const sastService = useContext(ServicesContext).SASTService

    return (
        <>
            <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
                <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "0px", marginLeft: "20px" }}>SAST</Typography>
                    <ActionButton onClick={() => navigate("./management")} text="Manage" />
                </Grid>
                <Grid item container flexDirection="column" rowGap="35px">
                    <GenericTable<SAST> entity="SAST" columns={cols}
                        dataProvider={(filter) => sastService.getAll(filter)}
                        onEdit={(elem: SAST) => navigate("./" + elem.id)}
                        onDelete={(elem: SAST) => sastService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </>

    )
}
export { SASTList }
export default SASTList
