import ServicesContext from "@context/ServicesContext"
import { Box, Grid, Paper, useTheme } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FilterOption } from "@utils/queryParams"
import React, { useContext, useEffect, useState } from "react"
import { AbilityContext } from "@components/permissions"
import { VulnerabilityTableDefinition, VulnerabilityTableDefinitionReduced, VulnerabilityTableDefinitionReducedSnapshot } from "@components/vulnerability"
import { useNavigate } from "react-router-dom"
import { useHierarchy } from "@context/useHierarchy"
import { VulnerabilitySnapshot } from "@models/Snapshot"
import { convertModelDefToGridColDef } from "../tables"
import GenericModal from "../modals/GenericModal"

interface Props {
    filters: FilterOption[];
    low: number,
    medium: number,
    high: number,
    critical: number,
    none: number,
    isHierarchy?: boolean,
    hierarchy?: string
    vulns?: VulnerabilitySnapshot[]
}

interface Indicator {
    backgroundColor: string,
    color: string;
    count: number;
}

const VulnerabilityIndicatorSnapshot: React.FC<Props> = ({ filters = [], low, medium, high, critical, none, isHierarchy = false, hierarchy, vulns = [] }) => {
    // Constants
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const { hierarchyId, setHierarchyId } = useHierarchy()

    // Estados
    const [cols, setCols] = useState<GridColDef[]>([])
    const [vulnerabilitiesOpen, setVulnerabilitiesOpen] = useState(false)
    const handleVulnerabilitiesClose = () => setVulnerabilitiesOpen(false)

    // Agregar indicadores según los niveles de severidad
    if (none > 0) indicators.push({ backgroundColor: "black", color: "#A0A0A0", count: none }) // Gris neutro
    if (low > 0) indicators.push({ backgroundColor: "black", color: "#FFD166", count: low }) // Amarillo suave
    if (medium > 0) indicators.push({ backgroundColor: "black", color: "#F8961E", count: medium }) // Naranja fuerte
    if (high > 0) indicators.push({ backgroundColor: "black", color: "#E63946", count: high }) // Rojo brillante
    if (critical > 0) indicators.push({ backgroundColor: "black", color: "#D00000", count: critical }) // Rojo intenso

    if (indicators.length === 0) {
        indicators.push({ backgroundColor: "black", color: "gray", count: 0 })
    }

    useEffect(() => {
        setCols(convertModelDefToGridColDef(!isHierarchy ? VulnerabilityTableDefinition : VulnerabilityTableDefinitionReduced, ability))
    }, [])

    const totalCount = indicators.reduce((sum, indicator) => sum + indicator.count, 0)

    return (
        <>
            <Grid
                component={Paper}
                elevation={2}
                container
                sx={{ width: "200px", height: "12px", display: "flex", borderRadius: "12px" }}
            >
                {indicators.map((indicator, index) => {
                    const widthPercentage = totalCount === 0 ? 100 : (indicator.count / totalCount) * 100
                    return (
                        <Grid
                            key={index}
                            item
                            sx={{
                                width: `${widthPercentage}%`,
                                background: indicator.color,
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"
                                } ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"
                                }`,
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                if (hierarchy) setHierarchyId(hierarchy)
                                setVulnerabilitiesOpen(indicator.count > 0)
                            }}
                        >
                            {indicator.count}
                        </Grid>
                    )
                })}
            </Grid>

            <GenericModal open={vulnerabilitiesOpen} onClose={handleVulnerabilitiesClose} title="Vulnerabilities">
                <Box width="100%" gap={2}>
                    <Grid item width="1000px">
                        {vulns.length > 0
                            ? (
                                <DataGrid
                                    rows={vulns.filter(v => v && v.id)} // ✅ Filtra valores nulos/indefinidos
                                    columns={convertModelDefToGridColDef(VulnerabilityTableDefinitionReducedSnapshot, ability)}
                                    pageSizeOptions={[5, 10, 20]}
                                    autoHeight
                                />
                            )
                            : (
                                <></>
                            )}
                    </Grid>
                </Box>
            </GenericModal>
        </>
    )
}

export { VulnerabilityIndicatorSnapshot }
