import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { score } from "@models/BomQuality"
import { I18nContext } from "I18nProvider"
import { Footer, Header } from "./HeaderFooter"
import styles from "./styles"

interface SectionProps {
    items: score[];
    title: string;
    lang?: string;
}

const getScoreColor = (score: number) => {
    if (score >= 8) return "#c2f09d"
    if (score >= 5) return "#ffffad"
    return "#ff9a9a"
}
const i18n = {
    es: {
        introduction: "2. Lista de SBOM Quality",
        explanation: "La Lista de Calidad de SBOM proporciona una evaluación detallada de varios elementos que contribuyen a la calidad general de una Lista de Materiales de Software (SBOM). Cada sección evalúa aspectos específicos como la estructura, el cumplimiento con los elementos mínimos de la NTIA, la claridad semántica, el control de calidad y las prácticas de intercambio. Estas evaluaciones son esenciales para identificar áreas de mejora y garantizar que el SBOM cumpla con los estándares de la industria en cuanto a transparencia, seguridad y gestión efectiva de riesgos. Al revisar cada sección, las organizaciones pueden obtener información sobre los puntos fuertes y las posibles deficiencias dentro de su documentación SBOM.",
        description: "Descripción",
        score: "Puntuación",
        no_data: "No hay información disponible"

    },
    en: {
        introduction: "2. SBOM Quality List",
        explanation: "The SBOM Quality List provides an in-depth assessment of various elements contributing to the overall quality of a Software Bill of Materials (SBOM). Each section evaluates specific aspects such as structure, compliance with NTIA minimum elements, semantic clarity, quality control, and sharing practices. These evaluations are essential for identifying areas of improvement and ensuring that the SBOM meets industry standards for transparency, security, and effective risk management. By reviewing each section, organizations can gain insights into the strengths and potential gaps within their SBOM documentation.",
        description: "Description",
        score: "score",
        no_data: "No data available"
    }
} as const
const TableSection: React.FC<SectionProps> = ({ items, title, lang }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    // --------------------------------------------------

    // Functions
    // --------------------------------------------------

    // Render variables
    // --------------------------------------------------

    return (
        <View style={{ marginBottom: 20 }} wrap={false}>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.table}>

                <View style={styles.headerRow}>
                    <Text style={styles.headerCell}> { i18n[locale].description } </Text>
                    <Text style={styles.scoreHeaderCell}>{ i18n[locale].score }</Text>
                </View>

                {items && items.length > 0
                    ? (
                        items.map((item, index) => (
                            <View key={index} style={styles.row}>
                                <Text style={styles.descriptionCell}>{item.description}</Text>
                                <Text
                                    style={[
                                        styles.scoreCell,
                                        { backgroundColor: getScoreColor(item.score) }
                                    ]}
                                >
                                    {item.score}
                                </Text>
                            </View>
                        ))
                    )
                    : (
                        <Text style={{ color: "grey", marginTop: 20, textAlign: "center" }}>
                            { i18n[locale].no_data }
                        </Text>
                    )}
            </View>
        </View>
    )
}
const CombinedSections: React.FC<{
    structuralItems: score[];
    ntiaItems: score[];
    semanticItems: score[];
    qualityItems: score[];
    sharingItems: score[];
    lang?: string;
}> = ({ structuralItems, ntiaItems, semanticItems, qualityItems, sharingItems, lang }) => {
    // Get context
    const context = useContext(I18nContext)

    // Determine locale
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang
        } else {
            console.warn(`Unsupported language: ${lang}. Using default.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("I18n context is not initialized. Ensure the provider is configured correctly.")
    }

    return (
        <Page size="A4" style={{ paddingBottom: 80 }}>
            <Header externalData={null} />

            <View>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{ i18n[locale].introduction }</Text>
            </View>

            <View style={styles.introTextContainer}>
                <Text style={styles.introText}> { i18n[locale].explanation } </Text>
            </View>

            <View style={styles.combinedSectionsContainer}>
                <TableSection items={structuralItems} title="Structural Section" />
                <TableSection items={ntiaItems} title="NTIA Minimum Elements Section" />
                <TableSection items={semanticItems} title="Semantic Section" />
                <TableSection items={qualityItems} title="Quality Section" />
                <TableSection items={sharingItems} title="Sharing Section" />
            </View>

            <Footer externalData={null} />
        </Page>
    )
}
export { CombinedSections }
