import React from "react"
import { Grid, Skeleton } from "@mui/material"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"

const SampleHeatMapSkeleton: React.FC = () => {
    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Skeleton
                    variant="text"
                    width={250}
                    height={40}
                    sx={{ textAlign: "left" }}
                />
            </Grid>
            <Grid item container spacing={2} justifyContent="space-between">
                <Skeleton
                    variant="rectangular"
                    width={710}
                    height={130}
                    sx={{
                        borderRadius: "4px",
                        background: `linear-gradient(to right, 
                            ${CVSS3SeverityColors.low}20, 
                            ${CVSS3SeverityColors.medium}20, 
                            ${CVSS3SeverityColors.high}20, 
                            ${CVSS3SeverityColors.critical}20
                        )`
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default SampleHeatMapSkeleton
