import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import TrackTableDefinition from "@components/track/TrackTableDefinition"
import ServicesContext from "@context/ServicesContext"
import Track from "@models/Track"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

interface TrackListProps {
    invoke?: string;
    externalFilters?: FilterOption[];
}

const TrackList: React.FC<TrackListProps> = ({ invoke, externalFilters = [] }) => {
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const trackService = useContext(ServicesContext).trackService
    const [cols, setCols] = useState<GridColDef[]>([])
    const [extQueryParameters, setExtQueryParameters] = useState<QueryParameters | undefined>(() => {
        return invoke
            ? {
                filters: [
                    { field: "invoke", operation: FilterOperation.StringEqual, value: invoke },
                    ...externalFilters
                ]
            }
            : undefined
    })
    // UseEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(TrackTableDefinition, ability))
    }, [])

    const queryParameters = useMemo(() => {
        return invoke
            ? {
                filters: [
                    { field: "invoke", operation: FilterOperation.StringEqual, value: invoke },
                    ...externalFilters
                ]
            }
            : undefined
    }, [invoke, externalFilters])

    useEffect(() => {
        if (JSON.stringify(queryParameters) !== JSON.stringify(extQueryParameters)) {
            setExtQueryParameters(queryParameters)
        }
    }, [queryParameters, extQueryParameters])

    const dataProvider = useCallback((filter:QueryParameters) => trackService.getAll(filter), [trackService])
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container flexDirection="column" >
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }} color={theme.palette.primary.main}>
                        {context.t.translate("policy_logs")}
                    </Typography>

                    <Grid item container>
                        <GenericTable<Track> entity="Track" columns={cols} dataProvider={dataProvider} externalParameters={extQueryParameters} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export { TrackList }
export default TrackList
