import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, FormControl, Grid, IconButton, MenuItem, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { HexColorPicker } from "react-colorful"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { ClientSelector } from "@components/client/index"
import { AssessmentTypeKeysValueOps } from "@components/assessment/AssessmentTableDefinition"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"
import { CVSS3SeverityKeysValueOps } from "@components/vulnerability/VulnerabilityTableDefinition"
import LanguageSelector from "@components/common/switches/LanguageSelector"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { ServicesContext } from "@context/index"
import { AssessmentType, CVSS3Severity, Template, TMetadataSCAContent } from "@models/index"
import { pdf } from "@react-pdf/renderer"
import "./draganddropstyle.css"
import { TemplateReportByAssessmentType } from "@models/Template"
import vulnerabilityGenerator from "mockData/vulnerabilityChart"
import GenericModal from "@components/common/modals/GenericModal"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import { I18nContext } from "I18nProvider"
import { useTrack } from "@components/track/TrackContext"
import ActionButton from "@components/common/Button/ActionButton"
import { ContentData } from "./sections/CustomContents"
import { CoverData } from "./sections/CoverPage"
import { emptySCAMetadata, getCVSSMinimumFromSeverity, getSeverityFromScore, isColorBright } from "./TemplateForm"
import TemplateContentSection from "./components/TemplateContentSection"

type FormValue = {
    id: string,
    type: string,
    value: CoverData | TMetadataSCAContent | ContentData[],
    valid: boolean
}

type Item = {
    id: string;
    headerName: any;
    initValue: any;
    defaultValid: boolean;
    type: React.FC<any>;
    multiple: boolean;
}

const initValue: Template = {
    id: "",
    name: "",
    language: "en",
    color: "#fffa37",
    logo: null,
    type: AssessmentType.CRT,
    client: { id: "" },
    config: {
        type: AssessmentType.CRT,
        data: {
            cvss_criticality: CVSS3Severity.Unknown,
            cvss_minimum: 0,
            limit_vulnerability_count: 100
        }

    },
    metadata: {
        type: AssessmentType.SCA,
        data: emptySCAMetadata
    }

}

const TemplateDetail: React.FC = () => {
    // Constants
    const theme = useTheme()
    const navigate = useNavigate()
    const templateService = useContext(ServicesContext).templateService
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const [error, setError] = useState<Error|null>(null)
    const [file, setFile] = useState<File>(new File([], "logo"))
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null)
    const { id } = useParams<{ id: string }>()
    const { showDialog } = useConfirmationDialog()
    const [formData, setFormData] = useState<Template>(initValue)
    const [prevFormData, setPrevFormData] = useState<Template>(initValue)
    const [color, setColor] = useState(formData.color)
    const [sectionData, setSectionData] = useState<FormValue[]>([])
    const [imgURL, setImgURL] = useState<string>("")
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const handleRequest = (url: string) => {
        setImgURL(url)
        setModalOpen(true)
        console.log("URL", url)
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "TemplateDetail" })
    }, [])
    // ----------------------------

    // Functions
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === prevFormData.name,
            formData.language === prevFormData.language,
            formData.type === prevFormData.type,
            formData.config === prevFormData.config,
            formData.metadata === prevFormData.metadata,
            formData.color === prevFormData.color,
            formData.logo === prevFormData.logo
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: any}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData(f => events.reduce((result, { target: { name, value } }) => {
            if (name === "cvss_minimum") {
                value = Math.min((value as any).match(/\d+/) ? parseInt((value as any).match(/\d+/)[0], 10) : 0, 10)
            }
            if (name === "type") {
                return {
                    ...prevFormData,
                    type: value,
                    config: {
                        ...prevFormData.config,
                        type: value !== AssessmentType.CRT ? AssessmentType.SCA : value
                    },
                    metadata: {
                        ...prevFormData.metadata,
                        type: value !== AssessmentType.CRT ? AssessmentType.SCA : value
                    }
                }
            }
            return { ...result, [name]: value }
        }, { ...f }))
    }
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const handleColorChange = (selectedColor: string) => {
        if (isColorBright(selectedColor)) {
            setColor(selectedColor)
            setFormData((prevFormData) => ({
                ...prevFormData,
                color: selectedColor
            }))
        } else {
            showDialog(
                context.t.translate("color_dialog.title"),
                context.t.translate("color_dialog.content"),
                () => {},
                context.t.translate("color_dialog.button")
            )
        }
    }
    const saveHandler = async () => {
        try {
            await templateService.update(formData.id, formData)
            setPrevFormData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // ----------------------------
    const processMetadata = (metadata: any): FormValue[] => {
        if (!metadata) return []

        const updatedSectionData: FormValue[] = []

        if (
            metadata.cover_page &&
            Object.values(metadata.cover_page).some(value => value !== "")
        ) {
            updatedSectionData.push({
                id: "1" + "-" + Math.floor(Math.random() * (50000)),
                type: "CoverPage",
                value: metadata.cover_page,
                valid: true
            })
        }

        if (
            metadata.table_of_contents &&
            Object.values(metadata.table_of_contents).some(value => value === true)
        ) {
            updatedSectionData.push({
                id: "2" + "-" + Math.floor(Math.random() * (50000)),
                type: "TableOfContents",
                value: metadata.table_of_contents,
                valid: true
            })
        }

        if (metadata.custom_content &&
            Object.values(metadata.custom_content).some(value => value !== "")
        ) {
            updatedSectionData.push({
                id: "3" + "-" + Math.floor(Math.random() * (50000)),
                type: "CustomContents",
                value: metadata.custom_content,
                valid: true
            })
        }

        return updatedSectionData
    }

    // useEffects
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await templateService.get(id as string)

                setFormData(val)
                setColor(val.color)
                setPrevFormData(val)
                if (val.logo !== null && val.logo !== "") {
                    setPreviewUrl("data:image/png;base64," + val.logo)
                }
                const updatedSectionData = processMetadata(val.metadata.data)
                console.log("TTTT", val.metadata.data)

                setSectionData(updatedSectionData)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [])
    useEffect(() => {
        console.log("formData", formData)
    }, [formData])

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const loadPreview = async () => {
        const template: Template = {
            ...formData

        }
        const TemplateReport = TemplateReportByAssessmentType[template.type]
        const report = pdf(<TemplateReport template={template} data={vulnerabilityGenerator()}/>)
        const blob = await report.toBlob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
    }
    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = "" // Clear the input value to trigger onChange even if the same file is selected
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]
        if (selectedFile) {
            try {
                const reader = new FileReader()
                reader.onloadend = () => {
                    if (reader.result) {
                        handleInputChange({ target: { name: "logo", value: reader.result as string } })
                        setPreviewUrl(reader.result as string)
                    }
                }

                reader.readAsDataURL(selectedFile)
                setFile(selectedFile)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
    }

    const handleLanguageChange = (langId: string) => {
        setFormData({ ...formData, language: langId })
    }
    useEffect(() => {
        const updatedTemplateMetadata = sectionData.reduce((acc: any, section) => {
            switch (section.type) {
            case "CoverPage":
                acc.cover_page = section.value
                break
            case "TableOfContents":
                acc.table_of_contents = section.value
                break
            case "CustomContents":
                acc.custom_content = section.value
                break
            default:
                console.warn(`Unknown section ID: ${section.id}  /// ${section.type}`)
            }
            return acc
        }, {})

        // Actualizar formData con el nuevo metadata
        setFormData((prevFormData) => ({
            ...prevFormData,
            metadata: {
                ...prevFormData.metadata,
                data: updatedTemplateMetadata
            }
        }))
    }, [sectionData]) // Ejecutar solo cuando sectionData cambie
    const handleCVSSChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: "cvss_criticality" | "cvss_minimum") => {
        const { value } = e.target
        const decimalValue = parseFloat(value).toFixed(1)

        if (field === "cvss_criticality") {
            const severity = value as CVSS3Severity
            const min = getCVSSMinimumFromSeverity(severity)

            setFormData((prevFormData) => ({
                ...prevFormData,
                config: {
                    ...prevFormData.config,
                    data: {
                        ...prevFormData.config.data,
                        cvss_criticality: severity,
                        cvss_minimum: min
                    }
                }
            }))
        } else if (field === "cvss_minimum") {
            let cvssScore = parseFloat(decimalValue)

            if (cvssScore > 10) {
                cvssScore = 10
            }
            const severity = getSeverityFromScore(cvssScore)
            setFormData((prevFormData) => ({
                ...prevFormData,
                config: {
                    ...prevFormData.config,
                    data: {
                        ...prevFormData.config.data,
                        cvss_criticality: severity,
                        cvss_minimum: cvssScore
                    }
                }
            }))
        }
    }
    useEffect(() => {
        setFormData({ ...formData, color })
    }, [color])

    if (formData.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>{context.t.translate("loading")}</div>)
    return (
        <Grid container sx={{ padding: "25px" }} gap={2}>
            <StyledBox>
                <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>{context.t.translate("labelTemplateProperties")}</Typography>
                <Typography sx={{ fontSize: "18px", fontFamily: "Griff", color: theme.palette.text.secondary }}>{context.t.translate("descriptionTemplateProperties")}</Typography>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid item flexDirection="row">
                    <Grid container flexDirection="row" justifyContent="end" alignItems="center" gap={2} sx={{ marginTop: "10px" }}>
                        <ActionButton variant="contained" onClick={loadPreview} text={context.t.translate("preview")} />
                        <ActionButton variant="contained" onClick={saveHandler} disabled={!formValid()} text={context.t.translate("product_save")} />
                    </Grid>
                </Grid>
                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={6}>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("client")}</Typography>
                            <ClientSelector disabled value={formData.client.id} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Grid>
                <TextField
                    margin="normal"
                    required
                    variant="standard"
                    label={context.t.translate("template_name")}
                    name="name" value={formData.name} onChange={handleChange}
                />
                <Grid item xs={12} md={6}>
                    <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("assessment_type")} name="type"
                        value={formData.type} onChange={handleInputChange}>
                        {AssessmentTypeKeysValueOps.map((opt, idx) =>
                            (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                        )}
                    </TextField>
                </Grid>
                {/* TODO: modificar componente guardar info en template */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                        <LanguageSelector onLanguageChange={handleLanguageChange}></LanguageSelector>
                    </FormControl>
                </Grid>
                <Typography sx={{ fontSize: "27px", fontFamily: "Griff", mt: "20px", fontWeight: "bolder", color: theme.palette.primary.main }}> {context.t.translate("theme")}</Typography>
                <Grid item container flexDirection="row" textAlign="left" spacing="10px">
                    {/* TODO: añadir filtrado de vulnerabilidades */}
                    <Grid item container xs={6} justifyContent="flex-start">
                        <Grid item flexDirection="column" textAlign="left" spacing="10px">
                            <Typography fontSize="20px" fontFamily="Griff" color={theme.palette.text.secondary} fontWeight="bolder"> {context.t.translate("product_color")}</Typography>
                            <HexColorPicker color={color} onChange={handleColorChange} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-start" alignItems="center" mt="15px">
                        <Grid item xs={12}>
                            <input
                                type="file"
                                accept=".jpg"
                                style={{ display: "none" }}
                                onClick={handleFileInputClick}
                                onChange={handleFileInputChange}
                                id={fileId}
                            />
                            <label htmlFor={fileId}>
                                <Typography fontSize="20px" fontFamily="Griff" color={theme.palette.text.secondary} fontWeight="bolder">{context.t.translate("labelCustomLogo")}</Typography>
                                <Tooltip title={context.t.translate("tooltipUploadLogo")}>
                                    <IconButton size="small" component="span" onClick={(e) => e.stopPropagation()}>
                                        <CloudUploadIcon/>
                                    </IconButton>
                                </Tooltip>
                            </label>
                        </Grid>
                        {(previewUrl !== null && previewUrl !== "") && (
                            <Grid container alignItems="center" spacing={1} mt="8px">
                                <Grid item>
                                    <img style={{ width: "50%", height: "50%" }} src={previewUrl.toString()} alt={context.t.translate("preview")} />
                                </Grid>
                                {/* <Grid item>
                                    <ActionButton
                                        text="Remove Logo"
                                        onClick={() => {
                                            setPreviewUrl(null) // Elimina la vista previa
                                            handleInputChange({ target: { name: "logo", value: "" } }) // Limpia el logo en formData
                                        }}
                                    />
                                </Grid> */}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Typography sx={{ fontSize: "27px", fontFamily: "Griff", mt: "20px", fontWeight: "bolder", color: theme.palette.primary.main }}>{context.t.translate("findings_filter")}</Typography>
                <Grid item container flexDirection="row" textAlign="left" spacing={2}>
                    {/* Primer campo de texto */}
                    <Grid item xs={6} container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Typography
                            fontSize="20px"
                            fontFamily="Griff"
                            color={theme.palette.text.secondary}
                            fontWeight="bolder"
                            marginBottom="8px"
                        >
                            {context.t.translate("dash_vulner_criticity")}
                        </Typography>
                        <TextField
                            margin="normal"
                            select
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("type")}
                            name="config.data.cvss_criticality"
                            value={formData.config.data.cvss_criticality}
                            onChange={(e) => handleCVSSChange(e, "cvss_criticality")} // Usamos la nueva función
                            sx={{ margin: "0px" }}
                        >
                            {CVSS3SeverityKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: CVSS3SeverityColors[CVSS3Severity[opt.label]] }}>
                                        {opt.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={6} container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Typography
                            fontSize="20px"
                            fontFamily="Griff"
                            color={theme.palette.text.secondary}
                            fontWeight="bolder"
                            marginBottom="8px"
                        >
                            {context.t.translate("labelCVSSMinimum")}
                        </Typography>
                        <TextField
                            name="config.data.cvss_minimum"
                            variant="outlined"
                            value={formData.config.data.cvss_minimum}
                            onChange={(e) => handleCVSSChange(e, "cvss_minimum")}
                            fullWidth
                            type="number"
                            inputProps={{
                                max: 10,
                                min: 0,
                                step: "0.1",
                                pattern: "^[0-9]*(.[0-9]{1})?$"
                            }}
                            sx={{ marginBottom: "10px" }}
                        />

                    </Grid>

                </Grid>
            </StyledBox>
            <TemplateContentSection
                sectionData={sectionData}
                setSectionData={setSectionData}
                loadPreview={loadPreview}
                openRequest={handleRequest}
            />
            <GenericModal title="" onClose={() => setModalOpen(false) } open={modalOpen && imgURL !== ""} >
                <img src={imgURL} ></img>
            </GenericModal>

        </Grid>
    )
}

export { TemplateDetail }
export default TemplateDetail
