import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import { useHierarchy } from "@context/useHierarchy"
import { SnapshotFleetStatusModel } from "@models/Snapshot"
import Hierarchy from "@models/Hierarchy"
import HierarchyTableDefinition from "@components/hierarchy/HierarchyTableDefinition"
import { I18nContext } from "I18nProvider"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import GenericModal from "../modals/GenericModal"
import { convertModelDefToGridColDef, GenericTable } from "../tables"

interface Props {
    waitingForCafAssessment: number;
    underAssessment: number;
    waitingForRemediation: number;
    finishWithRemediation: number;
    finishWithMitigation: number;
    finishWithMitigationAndRemediation: number;
    finishWithoutRecommendation: number;
    isHierarchy?: boolean;
    hierarchy?: string;
    hierarchies: SnapshotFleetStatusModel[] | Hierarchy[]
}

interface Indicator {
    backgroundColor: string;
    color: string;
    count: number;
    type: string
}

const HierarchyStatusIndicator: React.FC<Props> = ({
    waitingForCafAssessment,
    underAssessment,
    waitingForRemediation,
    finishWithRemediation,
    finishWithMitigation,
    finishWithMitigationAndRemediation,
    finishWithoutRecommendation,
    isHierarchy = false,
    hierarchy,
    hierarchies
}) => {
    // Constants
    const theme = useTheme()
    const navigate = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const [hierarchyStatusOpen, setHierarchyStatusOpen] = React.useState(false)
    const handleHierarchyStatusClose = () => setHierarchyStatusOpen(false)
    const { hierarchyId, setHierarchyId } = useHierarchy()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // Agrupar los estados en "In Triage" y "Triage Finished"
    const inTriageCount = waitingForCafAssessment + underAssessment
    const triageFinishedCount =
        waitingForRemediation +
        finishWithRemediation +
        finishWithMitigation +
        finishWithMitigationAndRemediation +
        finishWithoutRecommendation

    const indicators: Array<Indicator> = []
    if (inTriageCount > 0) {
        indicators.push({ backgroundColor: "black", color: "#F8961E", count: inTriageCount, type: "In triage" }) // Naranja
    }
    if (triageFinishedCount > 0) {
        indicators.push({ backgroundColor: "black", color: "#4CAF50", count: triageFinishedCount, type: "Triage finished" }) // Verde
    }

    if (indicators.length === 0) {
        indicators.push({ backgroundColor: "black", color: "gray", count: 0, type: "N/D" })
    }

    const totalCount = indicators.reduce((sum, indicator) => sum + indicator.count, 0)

    useEffect(() => {
        setCols(convertModelDefToGridColDef(HierarchyTableDefinition, ability))
    }, [])

    if (indicators.length > 0) {
        return (
            <Grid
                component={Paper}
                elevation={2}
                container
                sx={{ width: "200px", height: "12px", display: "flex", borderRadius: "12px" }}
            >
                {indicators.map((indicator, index) => {
                    const widthPercentage = totalCount === 0 ? 100 : (indicator.count / totalCount) * 100
                    return (
                        <Tooltip title={indicator.type} key={index}>
                            <Grid
                                item
                                sx={{
                                    width: `${widthPercentage}%`,
                                    background: indicator.color,
                                    color: "black",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"} 
                                ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"}`,
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if (hierarchy) { setHierarchyId(hierarchy) }
                                    setHierarchyStatusOpen(indicator.count > 0)
                                }}
                            >
                                {indicator.count}
                            </Grid>
                        </Tooltip>
                    )
                })}
                <GenericModal open={hierarchyStatusOpen} onClose={handleHierarchyStatusClose} title={context.t.translate("hierarchy")}>
                    <Box width="100%" gap={2}>
                        <Grid item width="1000px">

                            <GenericTable entity="Hierarchy" columns={cols}
                                dataProvider={(filter) => {
                                    const updatedFilter: QueryParameters = {
                                        ...filter,
                                        filters: [
                                            { field: "id", operation: FilterOperation.StringArrayContains, value: hierarchies.map(h => h.id).toString() }
                                        ]
                                    }
                                    return hierarchyService.getAll(updatedFilter)
                                }}
                                onEdit={(elem: Hierarchy) => { navigate("../" + elem.id); handleHierarchyStatusClose() }}
                                onDelete={(elem: Hierarchy) => hierarchyService.delete(elem.id)}
                                dataGridProps={{
                                    getRowClassName: (params: GridRowClassNameParams) =>
                                        params.row.supressed ? "suppressedRow" : "",
                                    sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } }
                                }}
                                isInModal={true}
                            />
                        </Grid>
                    </Box>
                </GenericModal>
            </Grid>
        )
    }

    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.text.secondary}>
            No hierarchy status data
        </Typography>
    )
}

export { HierarchyStatusIndicator }
