import React, { useContext, useEffect, useState } from "react"
import { Autocomplete, Grid, TextField } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"
import GenericModal from "@components/common/modals/GenericModal"
import { ServicesContext } from "@context/index"
import { FilterOperation, QueryParameters } from "@utils/index"
import Tag from "@models/Tag"
import { I18nContext } from "I18nProvider"

interface AddTagModalProps {
    open: boolean;
    onClose: () => void;
    productId: string;
    clientId: string;
    onTagAdded: () => void; // Callback to notify parent when a tag is successfully added
    setError: React.Dispatch<React.SetStateAction<string>>
}

const AddTagModal: React.FC<AddTagModalProps> = ({
    open,
    onClose,
    productId,
    clientId,
    onTagAdded,
    setError
}) => {
    const tagService = useContext(ServicesContext).tagService

    const [tagNameList, setTagNameList] = useState<string[]>([])
    const [selectedTag, setSelectedTag] = useState<string>("")
    const [tagData, setTagData] = useState<Tag[]>([])

    // Fetch available tags when the modal opens
    useEffect(() => {
        const fetchTags = async () => {
            try {
                const filters: QueryParameters = { sortField: "name", filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: clientId }] }
                const data = await tagService.getAll(filters)
                const availableTags = data.list.filter(tag => !tag.products?.includes(productId))
                setTagNameList(availableTags.map(tag => tag.name))
                setTagData(data.list)
            } catch (e: any) {
                setError(e.message)
            }
        }

        if (open) {
            fetchTags()
        }
    }, [open, productId, tagService])

    const handleAddTag = async () => {
        const tagToAdd = tagData.find(tag => tag.name === selectedTag)

        if (tagToAdd) {
            try {
                const updatedTag = {
                    ...tagToAdd,
                    products: [...(tagToAdd.products || []), productId]
                }

                await tagService.update(updatedTag.id, updatedTag)
                onTagAdded() // Notify the parent component that a tag was added successfully
                onClose() // Close the modal
                setSelectedTag("") // Reset selection
            } catch (e: any) {
                setError(e.message)
            }
        }
    }
    const addTagButton = <ActionButton onClick={handleAddTag} text="Add Tag" disabled={!selectedTag} />
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <GenericModal open={open} onClose={onClose} title={context.t.translate("product_addtag")} actions={addTagButton}>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    onChange={(event, newValue) => setSelectedTag(newValue || "")}
                    options={tagNameList}
                    renderInput={(params) => <TextField {...params} />}
                    value={selectedTag}
                    fullWidth
                />
            </Grid>
        </GenericModal>
    )
}

export default AddTagModal
