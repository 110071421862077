import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"

// Función para calcular números de página dinámicos
const calculatePageNumbers = (sections: string[]) => {
    let currentPage = 3 // Página inicial de la primera sección
    return sections.reduce((pageMap, section) => {
        pageMap[section] = currentPage++
        return pageMap
    }, {} as Record<string, number>)
}
const i18n = {
    es: {
        index: "Índice",
        introduction: "Introducción",
        scope: "Alcance",
        resume: "Resumen ejecutivo",
        identification: "Identificación de vulnerabilidades",
        monthyear: "Mes y año:",
        footer: {
            copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
            confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
            disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
            errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    },
    en: {
        index: "Index",
        introduction: "Introduction",
        scope: "Scope",
        resume: "Executive Summary",
        identification: "Vulnerability Identification",
        footer: {
            copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
            confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
            disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
            errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    }
} as const
const Index: React.FC<{lang?: string}> = ({ lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    const sections = [
        i18n[locale].introduction,
        i18n[locale].scope,
        i18n[locale].resume,
        i18n[locale].introduction
    ]

    const pageMap = calculatePageNumbers(sections)

    const dynamicIndex = sections.map(
        (section) => `${section}..................................................................${pageMap[section]}`
    )

    const normativas = [
        { id: "Normativas" },
        { id: "- Open Web Application Security Project (OWASP)" },
        { id: "- IEC 62443-4-1" },
        { id: "- The Penetration Testing Execution Standard Documentation" },
        { id: "- PCI Data Security Standard (PCI DSS)" }
    ]

    return (
        <Page size="A4">
            <View style={styles.page}>
                <Image
                    style={{
                        ...styles.image3,
                        paddingRight: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 595,
                        height: 842,
                        zIndex: -1
                    }}
                    src="/assets/img/report/indice_def.jpg"
                />
                <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 300, paddingRight: 10 }}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>i18n[locale].index</Text>
                    <View>
                        {dynamicIndex.map((point, index) => (
                            <Text
                                key={index}
                                style={{
                                    ...styles.puntuak2,
                                    paddingLeft: 40,
                                    marginRight: -30,
                                    paddingBottom: 5
                                }}
                            >
                                <Text
                                    style={{
                                        ...styles.puntuak,
                                        textAlign: "left",
                                        fontFamily: "Bold",
                                        fontSize: 10.5,
                                        marginRight: -20
                                    }}
                                >
                                    {index + 1}. {point}
                                </Text>
                            </Text>
                        ))}
                    </View>
                    <Text>&nbsp;</Text>
                    <View style={{ ...styles.tableScope, width: "100%" }}>
                        <Text
                            style={{
                                ...styles.tableColScope,
                                textAlign: "left",
                                width: "58.25%",
                                height: 120,
                                fontSize: 10.5
                            }}
                        >
                            {normativas.map((fila, index) =>
                                index === 0 ? fila.id : `\n\n${fila.id}`
                            )}
                        </Text>
                    </View>
                    <FooterIndex />
                </View>
            </View>
        </Page>
    )
}

const FooterIndex: React.FC<{lang?: string}> = ({ lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View>
            <View>
                <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                    {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                    {i18n[locale].footer.disclosureWarning}
                    {i18n[locale].footer.errorNotice}
                </Text>
            </View>
        </View>
    )
}

export { Index }
