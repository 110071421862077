import React from "react"
import { Box, Typography } from "@mui/material"
import ShieldIcon from "@mui/icons-material/Shield"

interface ShieldProps {
    text: string;
    color: string;
}

const Shield: React.FC<ShieldProps> = ({ text, color }) => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 80,
                height: 80
            }}
        >
            {/* Icono de escudo */}
            <ShieldIcon
                sx={{
                    fontSize: 80,
                    color
                }}
            />

            {/* Texto centrado sobre el icono */}
            <Typography
                variant="body2"
                sx={{
                    position: "absolute",
                    color: "white",
                    fontWeight: "bold"
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}

export default Shield
