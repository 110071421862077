/* eslint-disable space-before-function-paren */
import Assessment, { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { I18nContext } from "I18nProvider"
import { FC, useContext } from "react"
import moment from "moment"
import AssessmentStatusColored from "@components/assessment/AssessmentStatusColored"
import ActionButton from "@components/common/Button/ActionButton"
import { AssessmentStatusKeysValueOps } from "@components/assessment"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { AbilityContext } from "@components/permissions"
import AssessmentStateColored from "@components/vulnerability/AssessmentStateColored"
import useMediaResponsive from "@hooks/useMobile"

interface DetailsTabProps {
    formData: Assessment,
    handleInputChange: (e: any) => void
    onSave: () => void
    formValid: () => void
}

const DetailsTab: FC<DetailsTabProps> = ({ formData, handleInputChange, onSave, formValid }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    function formatLabel(label: string) {
        const acronymLabels = ["CRT", "SCA"]
        if (acronymLabels.includes(label)) {
            return label
        }
        return label.replace(/([a-z])([A-Z])/g, "$1 $2")
    }
    const assessmentTypeDescriptions: { [key in AssessmentType]: string } = {
        [AssessmentType.CRT]: context.t.translate("assessment_type_crt"),
        [AssessmentType.FunctionalTest]: context.t.translate("assessment_type_functional_test"),
        [AssessmentType.LogicalPentest]: context.t.translate("assessment_type_logical_pentest"),
        [AssessmentType.PortScan]: context.t.translate("assessment_type_port_scan"),
        [AssessmentType.NetworkScan]: context.t.translate("assessment_type_network_scan"),
        [AssessmentType.WebScan]: context.t.translate("assessment_type_web_scan"),
        [AssessmentType.SCA]: context.t.translate("assessment_type_sca"),
        [AssessmentType.ContainerScan]: context.t.translate("assessment_type_container_scan"),
        [AssessmentType.ThreatModel]: context.t.translate("assessment_type_threat_model"),
        [AssessmentType.StaticAnalysis]: context.t.translate("assessment_type_static_analysis"),
        [AssessmentType.DynamicAnalysis]: context.t.translate("assessment_type_dynamic_analysis")
    }

    const isMobile = useMediaResponsive("(max-width: 780px)")

    return (
        <Grid container flexDirection="column" rowGap="35px">
            <Grid container flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"}>
                <Grid item xs={12} md={2}>
                    <Typography fontSize="17px" fontFamily="Griff" color="primary" fontWeight="bolder">
                        {context.t.translate("issueDetails.productName")}
                    </Typography>
                    <Typography fontSize="14px" fontFamily="Griff" color="primary" >
                        {formData.product.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography fontSize="17px" fontFamily="Griff" color="primary" fontWeight="bolder">
                        {context.t.translate("issueDetails.productVersion")}
                    </Typography>
                    <Typography fontSize="14px" fontFamily="Griff" color="primary" >
                        {formData.product.version}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography fontSize="17px" fontFamily="Griff" color="primary" fontWeight="bolder">
                        {context.t.translate("assessment_type")}
                    </Typography>
                    <AssessmentStateColored value={formData.type} />

                </Grid>

                <Grid item xs={12} md={2} sx={{ width: "100%", maxWidth: { xs: "300px", md: "100%" } }}>
                    <Typography fontSize="17px" fontFamily="Griff" color="primary" fontWeight="bolder">
                        {context.t.translate("status")}
                    </Typography>
                    {!ability.can("*", "Assessment")
                        ? <AssessmentStatusColored value={formData.status} />
                        : <Select name="status" size="small" value={formData.status} onChange={handleInputChange} fullWidth variant="filled" sx={{
                            width: "100%",
                            maxWidth: { xs: "300px", md: "100%" }, // Más ancho en xs, normal en md
                            "& .MuiSelect-icon": { color: "#000" }
                        }}>
                            {AssessmentStatusKeysValueOps.map((opt, idx) =>
                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                            )}
                        </Select>}
                </Grid>
            </Grid>

            <Grid item width={isMobile ? "100%" : "70%"}>
                <Grid sx={{
                    borderRadius: "10px",
                    border: "1px solid #271968"
                }}>
                    <Grid sx={{ background: "#271968", color: "#fff", borderRadius: "9px 0px 9px 0px" }} width="fit-content"><Typography padding={1}>{context.t.translate("assessment_dates")}</Typography></Grid>
                    <Grid item container flexDirection="row" alignItems='center' spacing="20px" sx={{ margin: "0 auto" }}>

                        <Grid item xs={10} md={4}>
                            <DatePicker
                                label={context.t.translate("date_contrac")}
                                value={formData.contractDate ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                                slots={{
                                    textField: (params) => <TextField required margin="normal" variant="filled" {...params} />
                                }}
                                slotProps={{
                                    openPickerButton: { sx: { color: "primary.main" } },
                                    actionBar: { sx: { color: "primary.main" } },
                                    calendarHeader: { sx: { ".MuiPickersArrowSwitcher-button": { color: "primary.main" } } },
                                    switchViewButton: { sx: { color: "primary.main" } }
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} md={4}>
                            <DatePicker
                                label={context.t.translate("date_start")}
                                value={formData.startDate ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                slots={{
                                    textField: (params) => <TextField margin="normal" variant="filled" {...params} />
                                }}
                                slotProps={{
                                    openPickerButton: { sx: { color: "primary.main" } },
                                    actionBar: { sx: { color: "primary.main" } },
                                    calendarHeader: { sx: { ".MuiPickersArrowSwitcher-button": { color: "primary.main" } } },
                                    switchViewButton: { sx: { color: "primary.main" } }
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} md={4}>
                            <DatePicker
                                label={context.t.translate("date_end")}
                                value={formData.endDate ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                slots={{
                                    textField: (params) => <TextField margin="normal" variant="filled" {...params} />
                                }}
                                slotProps={{
                                    openPickerButton: { sx: { color: "primary.main" } },
                                    actionBar: { sx: { color: "primary.main" } },
                                    calendarHeader: { sx: { ".MuiPickersArrowSwitcher-button": { color: "primary.main" } } },
                                    switchViewButton: { sx: { color: "primary.main" } }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item><ActionButton text={context.t.translate("save")} onClick={onSave} disabled={!formValid} /></Grid>
        </Grid>
    )
}

export default DetailsTab
