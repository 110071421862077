import React, { useContext, useEffect, useState } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Achilles, { TestType } from "@models/Achilles"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"
import ProgressCircle from "../sections/ProgressCircle"

const severityMap: { [key: string]: keyof SeverityCount } = {
    critical: "critical",
    high: "high",
    medium: "medium",
    low: "low"
}
type OSILayer = "physical" | "datalink" | "network" | "transport" | "application";
type AchillesData = {
    [osi in OSILayer]?: {
        [protocol in TestType]?: {
            [test: string]: {
                [monitor: string]: {
                    alert: string,
                    value?: string
                }
            }
        }
    }
}
interface SeverityCount {
    critical: number;
    high: number;
    medium: number;
    low: number;
    undefined: number;
     totalAnomalies: number;
}

const suggestion = {
    critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, these vulnerabilities could trigger a cybersecurity breach." },
    high: { color: "red", label: "HIGH", subtext: ", which, if not addressed in time, these vulnerabilities could trigger a cybersecurity breach." },
    medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, these vulnerabilities could trigger a cybersecurity breach." },
    low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, these vulnerabilities could trigger a cybersecurity breach." },
    undefined: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
}

type AchillesType = "Fuzzing" | "Network Stress";

interface SectionProps {
    achilles: Achilles[];
    testType: AchillesType;
    achillesData: AchillesData
    monitors: string[]
    template?: Template
    lang?: string
}

enum Conformidades {
    grave = "No conformidad grave",
    leve = "No conformidad leve",
    asumible = "Anomalía asumible",
    sin_anomalias = "Sin anomalías"
}
const i18n = {
    es: {
        indexTitle: "3. Resumen Ejecutivo",
        resumeintroduction: "El objetivo del test es hacer uso de la ejecución del",
        resumeintroduction2: "test para identificar posibles anomalías en el funcionamiento del DUT y su capacidad para sobrellevar dichas pruebas. El resumen que figura a continuación es un enfoque condensado de los principales hallazgos.",
        resumeintroduction3: "Durante el transcurso del ejercicio",
        resumeintroduction4: "anomalías",
        resumeintroduction5: "fueron descubiertas.",
        resumeintroduction6: "Una descripción más detallada de las anomalías se encuentra en el apartado de",
        resumeintroduction7: "Identificación de anomalías",
        resumeintroduction8: "donde se describen cada una de ellas.",
        resume1: "Vulnerabilidades:",
        conformidades: {
            grave: "No conformidad grave",
            leve: "No conformidad leve",
            asumible: "Anomalía asumible",
            sin_anomalias: "Sin anomalías"
        },
        suggestion: {
            critical: { color: "black", label: "CRÍTICA", subtext: ", que, si no se abordan lo antes posible, podrían provocar una brecha de ciberseguridad." },
            high: { color: "red", label: "ALTA", subtext: ", que, si no se abordan a tiempo, podrían provocar una brecha de ciberseguridad." },
            medium: { color: "#FF8537", label: "MEDIA", subtext: ", que, si no se corrigen, podrían provocar una brecha de ciberseguridad." },
            low: { color: "#A8D1E7", label: "BAJA", subtext: ", que, si no se evalúan más a fondo, podrían provocar una brecha de ciberseguridad." },
            undefined: { color: "grey", label: "DESCONOCIDA", subtext: ", y se recomienda un análisis más detallado." }
        }
    },
    en: {
        indexTitle: "3. Executive Summary",
        resumeintroduction: "The objective of the test is to execute the",
        resumeintroduction2: "test to identify potential anomalies in the operation of the DUT and its ability to withstand these tests. The summary below is a condensed approach to the main findings.",
        resumeintroduction3: "During the course of the exercise",
        resumeintroduction4: "anomalies",
        resumeintroduction5: "were discovered.",
        resumeintroduction6: "A more detailed description of the anomalies can be found in the",
        resumeintroduction7: "Identification of Anomalies",
        resumeintroduction8: "section, where each of them is described.",
        resume1: "Vulnerabilities:",
        conformidades: {
            grave: "Severe non-conformity",
            leve: "Minor non-conformity",
            asumible: "Acceptable anomaly",
            sin_anomalias: "No anomalies"
        },
        suggestion: {
            critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, could trigger a cybersecurity breach." },
            high: { color: "red", label: "HIGH", subtext: ", which, if not addressed in time, could trigger a cybersecurity breach." },
            medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, could trigger a cybersecurity breach." },
            low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, could trigger a cybersecurity breach." },
            undefined: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
        }
    }
} as const

const ExecutiveSection: React.FC<SectionProps> = ({ testType, achillesData, achilles, monitors, template, lang }) => {
    const [resultValueMap, setResultValueMap] = useState<{ [key in Conformidades]?: number } & { totalAnomalies?: number }>({})
    const [empty, setEmpty] = useState<boolean>(false)
    const context = useContext(I18nContext)

    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    useEffect(() => {
        const tempProtocolResultValue: { [key in Conformidades]: number } = {
            [Conformidades.grave]: 0,
            [Conformidades.leve]: 0,
            [Conformidades.asumible]: 0,
            [Conformidades.sin_anomalias]: 0
        }

        const processedMonitors = new Set<string>()
        const isEmpty = Object.keys(achillesData).length === 0 ||
            Object.values(achillesData).every(protocols =>
                Object.values(protocols).every(tests =>
                    Object.values(tests).every(monitorList => Object.keys(monitorList).length === 0)
                )
            )

        setEmpty(isEmpty)

        Object.keys(achillesData).forEach(osi => {
            Object.keys(achillesData[osi as OSILayer] || {}).forEach(protocol => {
                Object.keys(achillesData[osi as OSILayer]![protocol as TestType] || {}).forEach(test => {
                    Object.entries(achillesData[osi as OSILayer]![protocol as TestType]![test] || {}).forEach(([monitor, monitorList]) => {
                        const monitorIdentifier = `${osi}-${protocol}-${test}-${monitor}`
                        if (processedMonitors.has(monitorIdentifier)) {
                            return
                        }

                        processedMonitors.add(monitorIdentifier)

                        const { alert, value } = monitorList
                        const valueAsNumber = value ? parseFloat(value.replace("%", "")) : NaN

                        if (alert === "test_error" || alert === "monitor_yellow") {
                            if (!isNaN(valueAsNumber)) {
                                if (valueAsNumber > 50) {
                                    tempProtocolResultValue[Conformidades.leve] += 1
                                } else {
                                    tempProtocolResultValue[Conformidades.asumible] += 1
                                }
                            } else {
                                tempProtocolResultValue[Conformidades.grave] += 1
                            }
                        } else if (alert === "monitor_green") {
                            tempProtocolResultValue[Conformidades.sin_anomalias] += 1
                        } else {
                            console.error(`El valor '${value}' o alerta '${alert}' no coincide con las reglas`)
                        }
                    })
                })
            })
        })

        setResultValueMap(tempProtocolResultValue)
    }, [achillesData])

    useEffect(() => {
        let totalAnomalies = 0

        const processedMonitors = new Set<string>()

        Object.keys(achillesData).forEach(osi => {
            Object.keys(achillesData[osi as OSILayer]!).forEach(protocol => {
                Object.keys(achillesData[osi as OSILayer]![protocol as TestType]!).forEach(test => {
                    Object.entries(achillesData[osi as OSILayer]![protocol as TestType]![test]).forEach(([monitor, monitorData]) => {
                        const monitorIdentifier = `${osi}-${protocol}-${test}-${monitor}`

                        if (processedMonitors.has(monitorIdentifier)) {
                            return
                        }

                        processedMonitors.add(monitorIdentifier)

                        if (monitorData.alert !== "monitor_green") {
                            totalAnomalies++
                        }
                    })
                })
            })
        })

        setResultValueMap(prevState => ({
            ...prevState,
            totalAnomalies
        }))
    }, [achillesData])

    return (
        <Page size="A4" style= {{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header externalData={null} template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].indexTitle}</Text>
                    <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].resumeintroduction} {testType} {i18n[locale].resumeintroduction2}</Text>

                    <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>
                        {i18n[locale].resumeintroduction3} <Text style={{ fontFamily: "Bold" }}>{resultValueMap.totalAnomalies} {i18n[locale].resumeintroduction4}</Text> {i18n[locale].resumeintroduction5}
                    </Text>

                    <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].resumeintroduction6}  <Text style={{ fontFamily: "Bold" }}> {i18n[locale].resumeintroduction7}</Text>{i18n[locale].resumeintroduction8}</Text>
                </View>
                <View style={styles.column}>
                    <View style={{ ...styles.rightImage, marginTop: -40, height: 700, marginLeft: 30, marginRight: -20, paddingTop: -200, marginBottom: -100, backgroundColor: "#fffa37", flexDirection: "row" }}>
                        <View style={{ ...styles.column, top: 50 }}>
                            <Image style={{ ...styles.rightImage, marginLeft: 20, paddingTop: 0, marginTop: 39.5, height: 90, width: 48 }} src="/assets/img/report/ColourArrows3.jpg" />
                            {/*                             <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Bold", fontSize: 16, textAlign: "left" }}>Servicios con anomalías detectadas: {achilles[0].anomaly} </Text> */}
                            <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Bold", fontSize: 16 }}>{i18n[locale].resume1} </Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 5, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 21 }}>{resultValueMap["No conformidad grave"]}</Text> {i18n[locale].conformidades.grave }</Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 5, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 21 }}>{ resultValueMap["No conformidad leve"]}</Text> {i18n[locale].conformidades.leve}</Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 5, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 21 }}>{ resultValueMap["Anomalía asumible"]}</Text> {i18n[locale].conformidades.asumible}</Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 4, fontFamily: "Griff", fontSize: 12, marginBottom: 200 }}><Text style={{ fontFamily: "Bold", fontSize: 21 }}>{ resultValueMap["Sin anomalías"]}</Text> {i18n[locale].conformidades.sin_anomalias}</Text>

                            <ProgressCircle Low={resultValueMap["Sin anomalías"] || 0} Medium={resultValueMap["Anomalía asumible"] || 0} High={resultValueMap["No conformidad leve"] || 0} Critical={resultValueMap["No conformidad grave"] || 0} Unknown={0} None={0} Empty={empty}></ProgressCircle>

                        </View>
                    </View>
                </View>
            </View>
            <Footer externalData={null}></Footer>
        </Page>)
}

export { ExecutiveSection }
