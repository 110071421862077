import Hierarchy, { CafHierarchyStatus } from "@models/Hierarchy"
import { ModelRelation } from "@models/utils"
import { NumVulnerability } from "@models/Vulnerability"

export const MAX_DEEP_LEVEL = 4
export const PATH_SEPARATOR = ";"

export interface TreeNode {
    id: string;
    name: string;
    description: string;
    status: CafHierarchyStatus | null;
    numProduct: number;
    num_vulnerability: NumVulnerability;
    client: ModelRelation;
    children?: TreeNode[];
  }

export const convertToTree = (entries: Hierarchy[], sortField: "name" | "description" | "status" | "numProduct", sortOrder: "asc" | "desc"): TreeNode[] => {
    const idToNodeMap: { [key: string]: TreeNode } = {}
    const rootNodes: TreeNode[] = []

    // Create a map of all nodes
    entries.forEach(entry => {
        idToNodeMap[entry.id] = {
            id: entry.id,
            name: entry.name,
            description: entry.description,
            status: entry.status ? entry.status : null,
            num_vulnerability: entry.num_vulnerability ? entry.num_vulnerability : { data: {}, count: 0 } as NumVulnerability,
            numProduct: entry.numProduct ? entry.numProduct : 0,
            client: entry.client,
            children: []
        }
    })

    // Build the tree structure
    entries.forEach(entry => {
        const pathSegments = entry.path.split(";")
        const node = idToNodeMap[entry.id]
        if (pathSegments.length === 1) {
            // This is a root node
            rootNodes.push(node)
        } else {
            // This is a child node
            const parentId = pathSegments[pathSegments.length - 2]
            const parentNode = idToNodeMap[parentId]
            parentNode.children!.push(node)
        }
    })

    // Sorting function
    const sortNodes = (nodes: TreeNode[]) => {
        nodes.sort((a, b) => {
            if (sortOrder === "asc") {
                if (typeof a[sortField] === "string" && typeof b[sortField] === "string") {
                    return (a[sortField] as string).localeCompare(b[sortField] as string)
                }
                return (a[sortField] as number) - (b[sortField] as number)
            }
            if (typeof a[sortField] === "string" && typeof b[sortField] === "string") {
                return (b[sortField] as string).localeCompare(a[sortField] as string)
            }
            return (b[sortField] as number) - (a[sortField] as number)
        })

        nodes.forEach(node => {
            if (node.children && node.children.length > 0) {
                sortNodes(node.children)
            }
        })
    }

    // Sort the root nodes and their children
    sortNodes(rootNodes)
    return rootNodes
}

export const convertTreeToHierarchy = (nodes: TreeNode[]): Hierarchy[] => {
    const hierarchies: Hierarchy[] = []

    const traverseNode = (node: TreeNode, parentPath: string = "") => {
        const hierarchy: Hierarchy = {
            id: node.id,
            name: node.name,
            description: node.description,
            status: node.status,
            numProduct: node.numProduct ? node.numProduct : 0,
            path: parentPath === "" ? `${node.id}` : `${parentPath};${node.id}`,
            client: node.client
        }
        hierarchies.push(hierarchy)

        if (node.children) {
            node.children.forEach(child => traverseNode(child, hierarchy.path))
        }
    }

    nodes.forEach(node => traverseNode(node))

    return hierarchies
}
