import { getEnumKeys, ModelRelation } from "@models/utils"

type Vulnerability = {
    id: string;
    identifier: string;
    score: number;
    revised_score: number;
    priority: number;
    epss: number;
    percentile: number;
    kev: boolean;
    supressed: boolean;
    vector: string;
    description: string;
    state: VulnerabilityState;
    justification?: VulnerabilityJustification | null;
    response?: VulnerabilityResponse[] | null,
    revised_severity: number,
    time_spent: number,
    cmp_name?: string,
    cmp_version?: string,
    assessment: ModelRelation;
    solution?: string;
    product: ProductRelation;
    client: ModelRelation;
    cwe?: number[];
    rank: number;
    origin: string;
    metadata?: Metadata;
    references?: string;
    generator?: string;
    generator_time?: string;
    affected_component?: string;
    product_id?: string;
    status?: CafVulnStatus;
    RevisedScore?: number;
}

type VulnerabilityExt = Vulnerability & {
    rank: number;
}
enum CafVulnStatus {
    LowRisk = "low_risk",
    WaitingForTriage = "waiting_for_triage",
    WaitingSupplierAssessment = "waiting_supplier_assessment",
    WaitingCafAssessment = "waiting_caf_assessment",
    WaitingForRemediation = "waiting_for_remediation",
    FinishWithRemediation = "finish_with_remediation",
    FinishWithMitigation = "finish_with_mitigation",
    FinishWithMitigationAndRemediation = "finish_with_mitigation_and_remediation",
    FinishWithoutRemediation = "finish_without_remediation",
    NotAffected = "not_affected"
}

type Metadata =
    | { type: "Nessus"; data: NessusMetadata }
    | { type: "Burp"; data: BurpMetadata }
    | { type: "Acunetix"; data: AcunetixMetadata }
    | { type: "Pentest"; data: PentestMetadata }
    | { type: "Generic"; data: { additionalInfo: string } };
type NessusMetadata = {
    plugin_id: string;
    plugin_name: string;
    plugin_family: string;
    plugin_type: string;
    port: string;
    service: string;
    severity: string;
    exploit_available: string;
    risk_factor: string;
    vpr_score: string;
    see_also: string;
    solution: string;
    xref: string;
    plugin_output: string;
};
type PentestMetadata = {
    name: string;
    actives: string;
}
type BurpMetadata = {
    title: string;
    instances: Instance[];
    detail_vuln: string;
    solution_vuln: string;
    severity?: string;
    confidence?: string;
};
type Instance = {
    Titles: string;
    Details?: string;
    Solutions?: string;
    Severity?: string;
    Confidence?: string;
};

type AcunetixMetadata = {
    title: string;
    severity: string;
    impact: string;
    recommendation: string;
};

export type ProductRelation = {
    id: string,
    name?: string,
    version?: string
}

export type NumVulnerability = {
    data: {
        critical: number,
        high: number,
        low: number,
        medium: number,
        none: number,
        unknown: number
    }
    count: number
}

export enum VulnerabilitySeverity {
    None = "none",
    Low = "low",
    Medium = "medium",
    High = "high",
    Critical = "critical",
    Unknown = "unknown"
}

enum CVSS3Severity {
    None = "none",
    Low = "low",
    Medium = "medium",
    High = "high",
    Critical = "critical",
    Unknown = "unknown"
}

enum VulnerabilityState {
    Detected = "detected",
    InTriage = "in_triage",
    Exploitable = "exploitable",
    Resolved = "resolved",
    FalsePositive = "false_positive",
    NotAffected = "not_affected"
}

enum VulnerabilityJustification {
    CodeNotPresent = "code_not_present",
    CodeNotReachable = "code_not_reachable",
    RequiresConfiguration = "requires_configuration",
    RequiresDependency = "requires_dependency",
    RequiresEnvironment = "requires_environment",
    ProtectedByCompiler = "protected_by_compiler",
    ProtectedAtRuntime = "protected_at_runtime",
    ProtectedAtPerimeter = "protected_at_perimeter",
    ProtectedByMigatingControl = "protected_by_mitigating_control",
}

enum VulnerabilityResponse {
    CanNotFix = "can_not_fix",
    WillNotfix = "will_not_fix",
    Update = "update",
    Rollback = "rollback",
    WorkaroundAvailable = "workaround_available",
}

const getCVSS3Criticality = (value: number): CVSS3Severity => {
    if (value === 0) {
        return CVSS3Severity.None
    } else if (value >= 0.1 && value <= 3.9) {
        return CVSS3Severity.Low
    } else if (value >= 4.0 && value <= 6.9) {
        return CVSS3Severity.Medium
    } else if (value >= 7.0 && value <= 8.9) {
        return CVSS3Severity.High
    } else if (value >= 9.0 && value <= 10.0) {
        return CVSS3Severity.Critical
    }
    return CVSS3Severity.Unknown
}

const getCVSS3CriticalityValue = (value: CVSS3Severity): number => {
    if (value === CVSS3Severity.Critical) {
        return 9.0
    } else if (value === CVSS3Severity.High) {
        return 7.0
    } else if (value === CVSS3Severity.Medium) {
        return 4.0
    } else if (value === CVSS3Severity.Low) {
        return 0.1
    }
    return 0
}

export const getVulnerabilitySeverity = (value: number): VulnerabilitySeverity => {
    if (value === 0) {
        return VulnerabilitySeverity.None
    } else if (value >= 0.1 && value <= 3.9) {
        return VulnerabilitySeverity.Low
    } else if (value >= 4.0 && value <= 6.9) {
        return VulnerabilitySeverity.Medium
    } else if (value >= 7.0 && value <= 8.9) {
        return VulnerabilitySeverity.High
    } else if (value >= 9.0 && value <= 10.0) {
        return VulnerabilitySeverity.Critical
    }
    return VulnerabilitySeverity.Unknown
}

export const VulnerabilitySeverityScore: Record<VulnerabilitySeverity, number> = {
    critical: 9.0,
    high: 7.0,
    medium: 4.0,
    low: 0.1,
    none: 0,
    unknown: 0
}

export const VulneabilitySeverityColors: Record<CVSS3Severity, string> = {
    critical: "#7940A5", // dark orchid
    high: "#D9534F", // light coral
    medium: "#EC971F", // golden yellow
    low: "#F2CC0C", // golden rod
    none: "A9A9A9", // dark grey
    unknown: "#477db8" // steel blue
}
const vulnerabilityDescriptions: Record<VulnerabilityState, { en: string; es: string }> = {
    [VulnerabilityState.Detected]: {
        en: "The vulnerability has been detected but not yet triaged.",
        es: "La vulnerabilidad ha sido detectada, pero aún no ha sido evaluada."
    },
    [VulnerabilityState.InTriage]: {
        en: "The vulnerability is being investigated.",
        es: "La vulnerabilidad está siendo investigada."
    },
    [VulnerabilityState.Exploitable]: {
        en: "The vulnerability may be directly or indirectly exploitable.",
        es: "La vulnerabilidad puede ser explotada directa o indirectamente."
    },
    [VulnerabilityState.FalsePositive]: {
        en: "The vulnerability is not specific to the component or service and was falsely identified or associated.",
        es: "La vulnerabilidad no es específica del componente o servicio y fue identificada o asociada erróneamente."
    },
    [VulnerabilityState.NotAffected]: {
        en: "The component or service is not affected by the vulnerability. Justification should be specified for all not_affected cases.",
        es: "El componente o servicio no está afectado por la vulnerabilidad. Se debe especificar una justificación para todos los casos de no afectado."
    },
    [VulnerabilityState.Resolved]: {
        en: "The vulnerability has been remediated.",
        es: "La vulnerabilidad ha sido remediada."
    }
}
const justificationDescriptions: Record<VulnerabilityJustification, { en: string; es: string }> = {
    [VulnerabilityJustification.CodeNotPresent]: {
        en: "The code has been removed or tree-shaked.",
        es: "El código ha sido eliminado o optimizado."
    },
    [VulnerabilityJustification.CodeNotReachable]: {
        en: "The vulnerable code is not invoked at runtime.",
        es: "El código vulnerable no se ejecuta en tiempo de ejecución."
    },
    [VulnerabilityJustification.ProtectedAtPerimeter]: {
        en: "Attacks are blocked at physical, logical, or network perimeter.",
        es: "Los ataques son bloqueados en el perímetro físico, lógico o de red."
    },
    [VulnerabilityJustification.ProtectedAtRuntime]: {
        en: "Exploits are prevented at runtime.",
        es: "Los exploits se previenen en tiempo de ejecución."
    },
    [VulnerabilityJustification.ProtectedByCompiler]: {
        en: "Exploitability requires a compiler flag to be set/unset.",
        es: "La explotación requiere una configuración específica del compilador."
    },
    [VulnerabilityJustification.ProtectedByMigatingControl]: {
        en: "Preventative measures have been implemented that reduce the likelihood and/or impact of the vulnerability.",
        es: "Se han implementado medidas preventivas que reducen la probabilidad y/o impacto de la vulnerabilidad."
    },
    [VulnerabilityJustification.RequiresConfiguration]: {
        en: "Exploitability requires a configurable option to be set/unset.",
        es: "La explotación requiere que una opción configurable esté activada o desactivada."
    },
    [VulnerabilityJustification.RequiresDependency]: {
        en: "Exploitability requires a dependency that is not present.",
        es: "La explotación requiere una dependencia que no está presente."
    },
    [VulnerabilityJustification.RequiresEnvironment]: {
        en: "Exploitability requires a certain environment that is not present.",
        es: "La explotación requiere un entorno específico que no está presente."
    }
}

const responseDescriptions: Record<VulnerabilityResponse, { en: string; es: string }> = {
    [VulnerabilityResponse.CanNotFix]: {
        en: "Issue cannot be fixed at this time.",
        es: "El problema no puede ser solucionado en este momento."
    },
    [VulnerabilityResponse.WillNotfix]: {
        en: "Issue will not be fixed at this time.",
        es: "El problema no será solucionado en este momento."
    },
    [VulnerabilityResponse.Update]: {
        en: "The consumer should upgrade to a newer version.",
        es: "El usuario debe actualizar a una versión más reciente."
    },
    [VulnerabilityResponse.Rollback]: {
        en: "The consumer should rollback to another version.",
        es: "El usuario debe revertir a otra versión."
    },
    [VulnerabilityResponse.WorkaroundAvailable]: {
        en: "Consumer will need to implement the workaround.",
        es: "El usuario deberá implementar una solución alternativa."
    }
}

// Funciones para obtener descripciones
export const getJustificationDescription = (justification: VulnerabilityJustification, lang: "en" | "es"): string => {
    return justificationDescriptions[justification]?.[lang] || ""
}

export const getResponseDescription = (response: VulnerabilityResponse, lang: "en" | "es"): string => {
    return responseDescriptions[response]?.[lang] || ""
}

export const getDescriptionForState = (state: VulnerabilityState, lang: "en" | "es"): string => {
    return vulnerabilityDescriptions[state][lang] || ""
}

const splitPascalCase = (input: string): string => input.replace(/(?<!^)([A-Z][a-z])/g, " $1")

const CVSS3CriticalityKeys = getEnumKeys(CVSS3Severity)
const CVSS3CriticalityKeysValueOps = CVSS3CriticalityKeys.map((key) => ({ label: splitPascalCase(key), value: CVSS3Severity[key] }))

const VulnerabilityStateKeys = getEnumKeys(VulnerabilityState)
const VulnerabilityStateKeysValueOps = VulnerabilityStateKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilityState[key] }))

const VulnerabilityCAFStatusKeys = getEnumKeys(CafVulnStatus)
const VulnerabilityCAFStatusKeysValueOps = VulnerabilityCAFStatusKeys.map((key) => ({ label: splitPascalCase(key), value: CafVulnStatus[key] }))

const VulnerabilityJustificationKeys = getEnumKeys(VulnerabilityJustification)
const VulnerabilityJustificationKeysValueOps = VulnerabilityJustificationKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilityJustification[key] }))

const VulnerabilityResponseKeys = getEnumKeys(VulnerabilityResponse)
const VulnerabilityResponseKeysValueOps = VulnerabilityResponseKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilityResponse[key] }))

const VulnerabilitySeverityKeys = getEnumKeys(VulnerabilitySeverity)
const VulnerabilitySeverityKeysValueOps = VulnerabilitySeverityKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilitySeverity[key] }))

export { VulnerabilityState, CafVulnStatus, splitPascalCase, VulnerabilityCAFStatusKeys, VulnerabilityCAFStatusKeysValueOps, vulnerabilityDescriptions, VulnerabilityResponseKeys, VulnerabilityJustificationKeys, VulnerabilityJustification, VulnerabilityResponse, CVSS3Severity, CVSS3CriticalityKeys, VulnerabilityStateKeysValueOps, VulnerabilityStateKeys, VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, CVSS3CriticalityKeysValueOps, getCVSS3Criticality, getCVSS3CriticalityValue, VulnerabilitySeverityKeysValueOps, VulnerabilitySeverityKeys }
export type { Vulnerability, VulnerabilityExt, NessusMetadata, AcunetixMetadata, PentestMetadata, BurpMetadata, Metadata, Instance }
export default Vulnerability
