import React, { useContext, useEffect, useMemo, useState } from "react"
import { Box, Chip, CircularProgress, ClickAwayListener, Divider, Grid, IconButton, Menu, MenuItem, MenuList, Tooltip, Typography, useTheme } from "@mui/material"
import { ArrowForward } from "@mui/icons-material"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { ProductType } from "@models/Product"
import { I18nContext } from "I18nProvider"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import FavoriteIcon from "@mui/icons-material/Favorite"
import moment from "moment"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PolicyIcon from "@mui/icons-material/Policy"
import BugReportIcon from "@mui/icons-material/BugReport"
import GitHubIcon from "@mui/icons-material/GitHub"
import ActionButton from "@components/common/Button/ActionButton"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, Product, StatsVulnerabilities } from "@models/index"
import { VulnReportDownloader, VulnReportUploader } from "@components/report/VulnReportUpDownloader"
import { convertModelDefToGridColDef, CustomAction } from "@components/common/tables"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { AbilityContext, Can } from "@components/permissions/index"
import { BiSolidComponent } from "react-icons/bi"
import CalculateIcon from "@mui/icons-material/Calculate"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { TbAnalyze } from "react-icons/tb"
import Hierarchy from "@models/Hierarchy"
import { PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import { AssessmentTableDefinition } from "@components/assessment"
import { useTrack } from "@components/track/TrackContext"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import TrackList from "@views/track/trackList"
import IssueList from "@views/issue/IssueList"
import HierarchyCard from "@views/product/HierarchyCard"
import { CgFileDocument } from "react-icons/cg"
import LocalPoliceIcon from "@mui/icons-material/LocalPolice"
import useMediaResponsive from "@hooks/useMobile"
import { MdTableRows } from "react-icons/md"
import { GrTree } from "react-icons/gr"
import ComponentsLicense from "./Components/ComponentsLicense"
import { StyledTab, StyledTabs } from "./Components/StyledTabs"
import StatusComponent from "./Components/Status"
import AssessmentComponent from "./Components/AssessmentsComponent"
import Details from "./Components/Details"
import GitComponent from "./Components/GitComponent"

const initValue = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchies: [],
    inheritance: false,
    supressed: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    provider_ids: [],
    location: "",
    created_at: ""
}
const initLicenseData = {
    licenses: {},
    total: 0
}
export interface LicenseData {
    licenses: Record<string, number>;
    total: number;
}
export interface TimeLineValues {
    x: number;
    name: string;
    description: string;
    version: string;
    id: string;
}

const ProductHeader: React.FC<{ product: Product }> = ({ product }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const tagService = useContext(ServicesContext).tagService
    const statsService = useContext(ServicesContext).statsService
    const componenteService = useContext(ServicesContext).componentService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Product>(initValue)
    const [formData, setFormData] = useState<Product>(initValue)
    const [dataTime, setDataTime] = useState<TimeLineValues[]>([])
    const [cols, setCols] = useState<GridColDef[]>([])
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filter, setFilter] = useState({})
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy)[][]>([])
    const ability = useContext(AbilityContext)
    const [successfulMessage, setSuccessfulMessage] = useState<string>()
    const [tabIndex, setTabIndex] = useState(0)
    const [licenseComponents, setLicenseComponents] = useState<LicenseData>(initLicenseData)
    const [tagNameList, setTagNameList] = useState<{ id: string; name: string; color: string; description: string; client_id: string; products: string[] }[]>([])
    const isMobile = useMediaResponsive("(max-width: 780px)")

    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ProductDetail" })
    }, [])

    useEffect(() => {
        const fecthData = async () => {
            try {
                const newCol = convertModelDefToGridColDef(AssessmentTableDefinition, ability)
                setCols(newCol)
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)

                if (val.hierarchies && val.hierarchies.length > 0) {
                    const fetchedHierarchies = await Promise.all(
                        val.hierarchies.map(async (path) => {
                            const pathIds = path.split(PATH_SEPARATOR)
                            const hierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                            return hierarchies.map(h => h || null)
                        })
                    )
                    setSelectedHierarchies(fetchedHierarchies)
                }

                const filterssss: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(filterssss)

                const componentes = await componenteService.getLicenseByProductID(id as string)
                setLicenseComponents(componentes)
                setVulnStats(vulnsStats)
                const initialFilter = {
                    sortField: "name",
                    sortOrder: "asc"
                }
                setFilter(initialFilter)
                loadTags(val.id)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [id])

    const loadTags = async (productId: string) => {
        try {
            const filters: QueryParameters = { sortField: "name" }
            const data = await tagService.getAll(filters)
            const filteredList = data.list.filter(item => item.products && item.products.includes(productId))
            const filteredTagNames = filteredList.map(item => ({
                name: item.name,
                color: item.color,
                products: item.products,
                client_id: product.client.id,
                description: item.description,
                id: item.id
            }))
            setTagNameList(filteredTagNames)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // Fetch product data from API - END
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = formData.name
                const filter: QueryParameters = { filters: [{ field: "name", operation: FilterOperation.EnumEqual, value: data as string }] }
                const allVal = await productService.getAllExt(filter)
                const timeLineValues: TimeLineValues[] = allVal.list.map(item => {
                    const date = new Date(item.created_at)
                    const year = date.getUTCFullYear()
                    const month = date.getUTCMonth()
                    const day = date.getUTCDate()

                    return {
                        x: Date.UTC(year, month, day), // Convertir la fecha a un array [year, month, day]
                        name: item.name,
                        description: item.description,
                        version: item.version,
                        id: item.id
                    }
                })
                setDataTime(timeLineValues)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fetchData()
    }, [formData])

    const navigate = useNavigate()
    const handleOpenComponents = async (id: string) => {
        const filters: QueryParameters = { sortField: "name", sortMode: "desc" }

        navigate(`../../assessment/${id}/component`)
    }
    // API Call save product

    // API Call save product - END

    const RequestAnalyze = async (id: string) => {
        try {
            const response = await vulnerabilityService.analyzeByAssessment(id)
            setSuccessfulMessage(response.message)
            setSnackbarOpen(true)
            setError(null)
        } catch (e: any) {
            console.error(e)
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    const customActions = (params: GridRowParams<Assessment>) => {
        const actions: CustomAction[] = [
            {
                icon: <Box key="analyze" sx={{ position: "relative", display: "inline-flex", height: "100%", width: "100%", mr: "15px", top: "0px" }}>
                    <CircularProgress variant="determinate" value={params.row.analysis_status === undefined ? 0 : params.row.analysis_status! * 16.6666} size={32} style={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Tooltip title="Reanalyze"><IconButton key="analyze" onClick={() => { RequestAnalyze(params.row.id) }} sx={{ width: 33, height: 33 }} ><TbAnalyze size={20} /></IconButton ></Tooltip>
                    </Box>
                </Box>,
                visualize: true

            },
            { icon: <Can key="create" I="create" an="File" ><VulnReportUploader setError={setError} simplified={false} key="vexUploader" assessment={params.row} /></Can>, visualize: true },
            // <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader simplified={true} key="vexDownloader" assessment={params.row} /></Can>,
            { icon: <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader setError={setError} simplified={false} key="vexDownloader" assessment={params.row} /></Can>, visualize: true },

            {
                icon: <Can key="components" I="read" an="Vulnerability">
                    <Tooltip title="Components">
                        <IconButton size="small" component="label" onClick={() => handleOpenComponents(params.id as string)}>
                            <BiSolidComponent />
                        </IconButton>
                    </Tooltip>
                </Can>,
                visualize: true
            }
        ]
        if (params.row && params.row.status === AssessmentStatus.Ended && params.row.type === AssessmentType.SCA) {
            actions.push(
                {
                    icon: <Can key="score" I="create" an="File" >
                        <Tooltip title="SBOM Metrics">
                            <IconButton size="small" component="label" onClick={() => navigate(`../../assessment/${params.row.id}/sbomQuality`)}>
                                <CalculateIcon />
                            </IconButton>
                        </Tooltip>
                    </Can>,
                    visualize: true
                }
            )
        }
        return actions
    }

    // --------------------------------------------
    const externalFilters = useMemo<FilterOption[]>(() => [
        { field: "registry", operation: FilterOperation.JSONBContains, value: `[{"ProductID": "${id}"}]` }
    ], [id])
    // --------------------------------------------
    const hasHierarchyTab = selectedHierarchies.length > 0
    const hasLicenseTab = licenseComponents.total > 0
    const totalTabs = 6 + (hasHierarchyTab ? 1 : 0) + (hasLicenseTab ? 1 : 0) // 6 fijas + hierarchy + licenses

    // Ajustar tabIndex si excede el total de pestañas
    useEffect(() => {
        if (tabIndex >= totalTabs) {
            setTabIndex(totalTabs - 1) // Ajustar al último índice válido
        }
    }, [tabIndex, totalTabs])

    // Ajustar índices de pestañas dinámicamente
    const getAdjustedIndex = (originalIndex: number) => {
        if (!hasHierarchyTab && originalIndex > 2) {
            return originalIndex - 1 // Desplazar índices si no hay hierarchy
        }
        return originalIndex
    }

    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }

    const handleMobileTabChange = (newIndex: number) => {
        setTabIndex(newIndex)
    }

    const handleClickTabActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorTabActions(event.currentTarget)
    }

    const [anchorTabActions, setAnchorTabActions] = React.useState<null | HTMLElement>(null)
    const openTabActions = Boolean(anchorTabActions)

    const handleCloseTabActions = () => {
        setAnchorTabActions(null)
    }

    return (
        <StyledBox>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={error?.message || successfulMessage || context.t.translate("an_error")}
                severity={successfulMessage ? "success" : "error"}
            />

            <Grid item xs={12} container direction="column" sx={{ height: "100%" }}>
                {/* Primera Row (75%) */}
                <Grid item container justifyContent="space-between" alignItems="center" paddingBottom={2} >
                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h5" fontWeight="bold">
                            {product.name}
                        </Typography>
                        <ArrowForward sx={{ mx: 1 }} />
                        <Typography variant="h6">v{product.version}</Typography>
                    </Grid>

                    <Grid item>

                        <ActionButton variant="outlined" onClick={() => navigate("./evolution")} text="Historic" />
                    </Grid>
                </Grid>

                <Grid
                    item
                    sx={{
                        height: "25%",
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: "space-between",
                        alignItems: isMobile ? "flex-start" : "center"
                    }}
                >
                    <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                <strong>{context.t.translate("type")}:</strong>
                            </Typography>
                            <Tooltip title={context.t.translate("type")}>
                                <Chip
                                    label={product.type.toLocaleUpperCase()}
                                    sx={{
                                        backgroundColor: ProductTypeColors[product.type].toLocaleUpperCase() || "gray",
                                        color: "white"
                                    }}
                                />
                            </Tooltip>
                        </Grid>

                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                <strong>{context.t.translate("created_at")}:</strong>
                            </Typography>
                            {moment(product.created_at).format("YYYY-MM-DD")}
                        </Grid>

                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                <strong>{context.t.translate("assigned_tags")}</strong>
                            </Typography>
                            {tagNameList.map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag.name}
                                    variant="outlined"
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "pink",
                                        marginRight: "0.5rem"
                                    }}
                                    deleteIcon={<HighlightOffIcon style={{ color: tag.color }} />}
                                />
                            ))}
                        </Grid>

                    </Grid>

                    {!isMobile
                        ? <StyledTabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                            <StyledTab icon={<ExpandMoreIcon />} label={context.t.translate("details")} />
                            <StyledTab icon={<FavoriteIcon />} label={context.t.translate("status")} />
                            {selectedHierarchies.length > 0 && <StyledTab icon={<GrTree />} label={context.t.translate("hierarchy")} />}
                            <StyledTab icon={<CgFileDocument />} label={context.t.translate("dash_assess")} />
                            <StyledTab icon={<PolicyIcon />} label={context.t.translate("policies")} />
                            <StyledTab icon={<BugReportIcon />} label={context.t.translate("issues")} />
                            <StyledTab icon={<GitHubIcon />} label={context.t.translate("git_integration")} />
                            {licenseComponents.total > 0 && <StyledTab icon={<LocalPoliceIcon />} label={context.t.translate("licenses")} />}
                        </StyledTabs>
                        : <Grid pt={2} mb={1}>
                            <ActionButton
                                text={context.t.translate("tabs")}
                                icon={<MdTableRows />}
                                onClick={handleClickTabActions}
                            />
                            <Menu open={openTabActions} anchorEl={anchorTabActions} disablePortal>
                                <ClickAwayListener onClickAway={handleCloseTabActions}>
                                    <MenuList aria-labelledby="composition-button">
                                        <MenuItem onClick={() => handleMobileTabChange(0)}>
                                            <ExpandMoreIcon sx={{ marginRight: 1 }} />
                                            {context.t.translate("details")}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMobileTabChange(1)}>
                                            <FavoriteIcon sx={{ marginRight: 1 }} />
                                            {context.t.translate("status")}
                                        </MenuItem>
                                        {selectedHierarchies.length > 0 && (
                                            <MenuItem onClick={() => handleMobileTabChange(2)}>
                                                <GrTree style={{ marginRight: 8 }} />
                                                {context.t.translate("hierarchy")}
                                            </MenuItem>
                                        )}
                                        <MenuItem onClick={() => handleMobileTabChange(getAdjustedIndex(3))}>
                                            <CgFileDocument style={{ marginRight: 8 }} />
                                            {context.t.translate("dash_assess")}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMobileTabChange(getAdjustedIndex(4))}>
                                            <PolicyIcon style={{ marginRight: 8 }} />
                                            {context.t.translate("policies")}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMobileTabChange(getAdjustedIndex(5))}>
                                            <BugReportIcon style={{ marginRight: 8 }} />
                                            {context.t.translate("issues")}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMobileTabChange(getAdjustedIndex(6))}>
                                            <GitHubIcon style={{ marginRight: 8 }} />
                                            {context.t.translate("git_integration")}
                                        </MenuItem>
                                        {licenseComponents.total > 0 && (
                                            <MenuItem onClick={() => handleMobileTabChange(getAdjustedIndex(7))}>
                                                <LocalPoliceIcon style={{ marginRight: 8 }} />
                                                {context.t.translate("licenses")}
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Menu>
                        </Grid>}

                </Grid>
                <Divider sx={{ width: "100%", backgroundColor: "gray", mt: "0px" }} />

                <Grid item xs={12} padding="30px">

                    {tabIndex === 0 && <Grid><Details id={id} /></Grid>}
                    {tabIndex === 1 && <Grid><StatusComponent id={id} vulnStats={vulnStats} dataTime={dataTime} /></Grid>}
                    {tabIndex === 2 && selectedHierarchies.length > 0 && <Grid container spacing={2}>
                        {selectedHierarchies.map((hierarchyPath, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <HierarchyCard hierarchies={hierarchyPath} />
                            </Grid>
                        ))}
                    </Grid>}
                    {tabIndex === getAdjustedIndex(3) && <AssessmentComponent cols={cols} id={id} customActions={customActions} />}
                    {tabIndex === getAdjustedIndex(4) && <TrackList invoke="policy" externalFilters={externalFilters} />}
                    {tabIndex === getAdjustedIndex(5) && <IssueList row={{ productName: formData.name, version: formData.version }} />}
                    {tabIndex === getAdjustedIndex(6) && <GitComponent id={id} clientId={formData.client.id} />}
                    {tabIndex === getAdjustedIndex(7) && licenseComponents.total > 0 && <ComponentsLicense licenses={licenseComponents} />}
                </Grid>
            </Grid>

        </StyledBox>

    )
}

export default ProductHeader
