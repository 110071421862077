import React from "react"
import { Condition, ConditionField, ConditionTarget } from "@models/Policy"
import { Grid, MenuItem, TextField, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { FilterOperation } from "@utils/queryParams"
import { CafHierarchyStatus } from "@models/Hierarchy"
import { CafVulnStatus } from "@models/Vulnerability"
import { conditionFieldOptions, conditionTargetOptions, fieldOperatorOptions } from "./constants/constAndInit"

// Importamos los enums de estados

interface ConditionsListProps {
    conditions: Condition[];
    onConditionChange: (condition: Condition, index: number) => void;
    onDeleteCondition: (index: number) => void;
}

interface ConditionItemProps {
    condition: Condition;
    index: number;
    onChange: (condition: Condition, index: number) => void;
    onDelete: (index: number) => void;
}

const ConditionItem: React.FC<ConditionItemProps> = ({ condition, index, onChange, onDelete }) => {
    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target

        // Asignamos el valor de 'operator' asegurándonos de que sea de tipo 'FilterOperation'
        const operatorValue: FilterOperation = fieldOperatorOptions[value as ConditionField]?.[0]?.value as FilterOperation

        const updatedCondition = {
            ...condition,
            field: value as ConditionField,
            operator: operatorValue || FilterOperation.StringEqual,
            id: condition.id,
            target: condition.target,
            value: condition.value
        }

        onChange(updatedCondition, index)
    }

    // Manejar los cambios en los campos del formulario
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        if (!name) return

        const updatedCondition = {
            ...condition,
            [name]: value
        }

        onChange(updatedCondition, index)
    }

    // Filtrar las opciones de operador basadas en el campo seleccionado
    const operatorOptions = fieldOperatorOptions[condition.field] || []

    // Determinar si el campo "status" está seleccionado y cargar las opciones correspondientes
    const statusOptions =
        condition.field === ConditionField.Status
            ? condition.target === ConditionTarget.Hierarchy
                ? Object.entries(CafHierarchyStatus).map(([key, value]) => ({
                    label: key.replace(/([A-Z])/g, " $1"),
                    value
                }))
                : condition.target === ConditionTarget.Vulnerability
                    ? Object.entries(CafVulnStatus).map(([key, value]) => ({
                        label: key.replace(/([A-Z])/g, " $1"),
                        value
                    }))
                    : []
            : []

    // Si el field es 'KEV', mostramos un selector de true/false para 'value'
    const renderValueField =
        condition.field === ConditionField.Status
            ? (
                <TextField
                    margin="none"
                    required
                    fullWidth
                    variant="filled"
                    label="Value"
                    name="value"
                    select
                    value={condition.value}
                    onChange={handleInputChange}
                >
                    {statusOptions.map((opt) => (
                        <MenuItem key={String(opt.value)} value={String(opt.value)}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>

            )
            : condition.field === ConditionField.KEV
                ? (
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        variant="filled"
                        label="Value"
                        name="value"
                        select
                        value={condition.value}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="true">
                            <Typography fontFamily="Griff" fontWeight="bolder">True</Typography>
                        </MenuItem>
                        <MenuItem value="false">
                            <Typography fontFamily="Griff" fontWeight="bolder">False</Typography>
                        </MenuItem>
                    </TextField>
                )
                : (
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        variant="filled"
                        label="Value"
                        name="value"
                        value={condition.value}
                        onChange={handleInputChange}
                    />
                )

    return (
        <Grid container spacing={2} alignItems="center" marginBottom={3}>
            {/* Target Field */}
            <Grid item xs={12} sm={3}>
                <TextField
                    margin="none"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Target"
                    name="target"
                    value={condition.target}
                    onChange={handleInputChange}
                >
                    {conditionTargetOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Field */}
            <Grid item xs={12} sm={3}>
                <TextField
                    margin="none"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Field"
                    name="field"
                    value={condition.field}
                    onChange={handleFieldChange} // Usamos handleFieldChange para actualizar el operador al cambiar el campo
                >
                    {conditionFieldOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Operator */}
            <Grid item xs={12} sm={2}>
                <TextField
                    margin="none"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Operator"
                    name="operator"
                    value={condition.operator}
                    onChange={handleInputChange}
                >
                    {operatorOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Value */}
            <Grid item xs={12} sm={3}>
                {renderValueField}
            </Grid>

            {/* Delete Icon */}
            <Grid item xs={12} sm={1}>
                <DeleteIcon
                    onClick={() => onDelete(index)}
                    color="error"
                    sx={{ margin: "0px", cursor: "pointer" }}
                />
            </Grid>
        </Grid>
    )
}

const ConditionsList: React.FC<ConditionsListProps> = ({
    conditions,
    onConditionChange,
    onDeleteCondition
}) => {
    return (
        <>
            {conditions.map((condition, index) => (
                <ConditionItem
                    key={`condition-${index}`}
                    condition={condition}
                    index={index}
                    onChange={onConditionChange}
                    onDelete={onDeleteCondition}
                />
            ))}
        </>
    )
}

export default ConditionsList
