import { Box, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSectorKeysValueOps } from "@components/client/ClientTableDefinition"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"
import { ServicesContext } from "@context/index"
import { NewClient } from "@models/index"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import { I18nContext } from "I18nProvider"
import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"
import useMediaResponsive from "@hooks/useMobile"
const initValue: NewClient = {
    id: "",
    name: "",
    description: "",
    sector: undefined,
    username: "",
    email: "",
    pwd: "",
    admin: false,
    provider: false,
    country: "",
    region: ""
}

const ClientForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const clientService = useContext(ServicesContext).clientService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error|null>(null)

    const [selectedCountry, setSelectedCountry] = useState("")
    const [formData, setFormData] = useState<NewClient>(initValue)
    const theme = useTheme()
    const isFormValid = formData.name.trim() !== "" && formData.description.trim() !== ""
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ClientForm" })
    }, [])

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked
        }))
    }
    // const handleCountryChange = (val: string) => {
    //     setFormData((prev) => ({
    //         ...prev,
    //         country: val,
    //         region: ""
    //     }))
    // }
    countries.registerLocale(enLocale)

    const handleCountryChange = (countryCodeAlpha2: string) => {
        const countryCodeAlpha3 = countries.alpha2ToAlpha3(countryCodeAlpha2)
        setSelectedCountry(countryCodeAlpha2)
        setFormData((prev) => ({
            ...prev,
            country: countryCodeAlpha3,
            region: ""
        }))
    }
    const handleRegionChange = (val: string) => {
        setFormData((prev) => ({
            ...prev,
            region: val
        }))
    }
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await clientService.create(formData)
            navigate(-1)
        } catch (e) {
            setError(e as Error)
            setSnackbarOpen(true)
        }
    }

    const isMobile = useMediaResponsive("(max-width: 780px)")

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Client Name" name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" select required fullWidth variant="filled" label="Sector" name="sector"
                            value={formData.sector} onChange={handleInputChange}>
                            {ClientSectorKeysValueOps.map((opt, idx) =>
                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label === "EnergyAndElectricNetworks" ? "Energy And Electric Networks" : opt.label}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Description" name="description"
                            value={formData.description} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CountryDropdown
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            valueType="short"
                            style={{
                                width: "100%",
                                padding: "16.5px 14px",
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderRadius: "8px"
                            }}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RegionDropdown
                            country={selectedCountry || ""}
                            value={formData.region || ""}
                            onChange={handleRegionChange}
                            disableWhenEmpty={true}
                            countryValueType="short"
                            style={{
                                width: "100%",
                                padding: "16.5px 14px",
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderRadius: "8px"
                            }}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Username" name="username"
                            value={formData.username} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Password" name="pwd"
                            value={formData.pwd} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Email" name="email"
                            value={formData.email} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={6} container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <FormControlLabel
                                label="Provider"
                                control={
                                    <Switch
                                        name="provider"
                                        checked={formData.provider}
                                        onChange={handleSwitchChange}
                                        sx={{
                                            "& .MuiSwitch-track": { backgroundColor: "#B0B0B0" }, // Color OFF
                                            "& .Mui-checked + .MuiSwitch-track": { backgroundColor: "#6A0572" } // Color ON
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                label="Administrator"
                                control={
                                    <Switch
                                        name="admin"
                                        checked={formData.admin}
                                        onChange={handleSwitchChange}
                                        sx={{
                                            "& .MuiSwitch-track": { backgroundColor: "#B0B0B0" }, // Color OFF
                                            "& .Mui-checked + .MuiSwitch-track": { backgroundColor: "#6A0572" } // Color ON
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item container flexDirection={isMobile ? "column" : "row"} xs={12} spacing={1}>
                        <Grid item xs={5.5}>
                            <ActionButton
                                type="submit"
                                variant="contained"
                                text={context.t.translate("licenses")}
                            />
                        </Grid>
                        <Grid item xs={5.5}>
                            <ActionButton
                                type="submit"
                                variant="contained"
                                text={context.t.translate("add_new_client")}
                                disabled={!isFormValid}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export { ClientForm }
export default ClientForm
