import React, { useState } from "react"
import { FormControlLabel, Grid, Switch } from "@mui/material"
import { PolicyNotificationConfig, PolicyNotificationTransportType } from "@models/Policy"

interface PolicyNotificationConfigsFormProps {
    data: PolicyNotificationConfig[];
    onChange: (data: PolicyNotificationTransportType[]) => void;
}

const PolicyNotificationConfigsForm: React.FC<PolicyNotificationConfigsFormProps> = ({ data, onChange }) => {
    const initTransports: PolicyNotificationTransportType[] = []
    for (const policyNotificationConfig of data) {
        if (policyNotificationConfig.isActive) {
            initTransports.push(policyNotificationConfig.value)
        }
    }
    const [transports, setTransports] = useState<PolicyNotificationTransportType[]>(initTransports)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { value: string, checked?: boolean }>) => {
        const value = e.target.value || ""
        let definedTransports = transports
        if (transports.includes(value)) {
            definedTransports = transports.filter(item => item !== value)
        } else if (value !== "") {
            definedTransports.push(value)
        }
        setTransports(definedTransports)
        onChange(definedTransports)
    }

    const isChecked = (transport: string): boolean => {
        for (const policyNotificationConfig of data) {
            if (policyNotificationConfig.value === transport) {
                return policyNotificationConfig.isActive
            }
        }
        return false
    }

    return (
        <Grid container spacing={2} marginBottom={3}>
            <Grid item xs={12} sm={12}>
                {data.map((policyNotificationConfig, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Switch
                                checked={transports.includes(policyNotificationConfig.value)}
                                onChange={handleChange}
                                name="transport[]"
                                value={policyNotificationConfig.value}
                                color="primary"
                            />
                        }
                        label={policyNotificationConfig.label.concat("", policyNotificationConfig.isAvailable ? "" : " (not configured)")}
                        sx={{ marginTop: "16px" }}
                    // disabled={!policyNotificationConfig.isAvailable}
                    />
                ))}
            </Grid>
        </Grid>
    )
}

export default PolicyNotificationConfigsForm
