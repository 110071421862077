import React, { useEffect, useState } from "react"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { Document, Font } from "@react-pdf/renderer"
import Product from "@models/Product"
import Vulnerability from "@models/Vulnerability"
import { ExecutiveSection } from "./Pentest/4_ExecutiveSection"
import { FrontPage } from "./Pentest/0_FrontPage"
import { Intro } from "./Pentest/1_Intro"
import { Methodology } from "./Pentest/2_Methodology"
import { Scope } from "./Pentest/3_Scope"
import { ResumenSection } from "./Pentest/10_Resumen"
import { SystemDiscovery } from "./Pentest/5_SystemDiscovery"
import { IdentificationSection } from "./Pentest/6_IdentificationSection"
import { VulnSection } from "./Pentest/8_VulnSection"
import { ExecutionSection } from "./Pentest/9_Execution"
import { ResultSection } from "./Pentest/7_ResultSection"

Font.register({ family: "Griff", fonts: [{ src: "/assets/fonts/Griff-Regular.otf" }, { src: "/assets/fonts/Griff-Bold.otf", fontWeight: "bold" }] })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Splits the word into syllables (basic example, may need improvement)
    return word.split("-")
}

// Register hyphenation callback
Font.registerHyphenationCallback(hyphenationCallback)

interface PentestReportProps {
    data: Vulnerability[];
    hierarchies?: string[];
    products?: Product[];
    assessments?: Assessment[];
    template?: Template;
    externalData?: any
}

const PentestReport: React.FC<PentestReportProps> = ({ hierarchies, products, assessments, template, externalData, data }) => {
    // Constants
    const [orderedVulns, setOrderedVulns] = useState<Vulnerability[]>([])
    const [orderedLimitedVulns, setOrderedLimitedVulns] = useState<Vulnerability[]>([])
    // ------------------------------

    // useEffect
    // TODO: Fix case of data.length === 0
    useEffect(() => {
        // Order vulnerabilities
        const ordered = [...data].sort((a, b) => {
            let rank = 0
            const rankB = (a.score)
            const rankA = (a.score)
            if (rankA != null && rankB != null) {
                rank = rankA - rankB
            }
            if (rank === 0) {
                rank = (b.score || 0) - (a.score || 0)
            }
            return rank
        })
        setOrderedVulns(ordered)
        setOrderedLimitedVulns(ordered.slice(0, 10))
        // ------------------------------
    }, [data])
    // ------------------------------

    return (
        <Document pageLayout="twoColumnRight">
            <FrontPage externalData={externalData}></FrontPage>
            <Intro externalData={externalData}></Intro>
            <Methodology externalData={null}></Methodology>
            <Scope externalData={externalData}></Scope>
            <ExecutionSection externalData={externalData}></ExecutionSection>
            <ExecutiveSection vulnerabilities={data}></ExecutiveSection>
            <ResumenSection externalData={externalData}></ResumenSection>
            <SystemDiscovery externalData={externalData}></SystemDiscovery>
            <IdentificationSection></IdentificationSection>
            <ResultSection vulnerabilities={orderedVulns} ></ResultSection>
            <VulnSection vulnerabilities={orderedVulns}></VulnSection>
        </Document>
    )
}

export { PentestReport }
export default PentestReport
