import { FilterOperation } from "@utils/queryParams"
import { ModelRelation } from "./utils"

type Policy = {
    id: string
    name: string
    description: string
    event_type: EventType
    policy_conditions: Condition[]
    policy_actions: ActionsType
    policy_actions_data: ActionsData
    status: boolean
    client: ModelRelation
    // method?:PolicyMethods
}
enum PolicyMethods {
    Slack="slack",
    Teams="teams",
    Email="email"

}
type ActionsData = {
    type: ActionsType,
    data: NotificationActionsData | ModificationActionsData[]
}
type NotificationActionsData = {
    emails: string[]
    transport_types: PolicyNotificationTransportType[]
}
type ModificationActionsData = {
    target: ConditionTarget,
    field: string,
    value: string
}
type Condition = {
    id: string,
    target: ConditionTarget,
    field: ConditionField,
    operator: FilterOperation,
    value: string
}
type PolicyNotificationTransportType = string
type PolicyNotificationConfig = {
    label: string,
    value: PolicyNotificationTransportType,
    isAvailable: boolean,
    isActive: boolean,
}
enum ActionsType {
        Notification = "NOTIFICATION",
        Modification = "MODIFICATION"
   }
enum EventType {
        VulnerabilityCreated = "VULNERABILITY_CREATED",
        VulnerabilityUpdated = "VULNERABILITY_UPDATED"
   }
enum ConditionField {
    Severity = "severity",
    VulnerabilityCve = "vulnerabilityCve",
    EPSS = "epss",
    KEV = "kev",
    Status = "Status",
    ID = "id"
}
enum ConditionTarget {
        /* Product = "product", */
        Hierarchy = "hierarchy",
        Vulnerability = "vulnerability"
}
enum ConditionOperator {
    IS = "IS",
    ISNOT = "IS_NOT"
}

enum ConditionSubject {
    Severity = "SEVERITY",
    VulnerabilityId = "VULNERABILITY_ID"
}
export { ConditionOperator, ConditionField, ConditionTarget, ConditionSubject, EventType, ActionsType, PolicyMethods }
export type { Policy, Condition, NotificationActionsData, ModificationActionsData, PolicyNotificationConfig, PolicyNotificationTransportType }
export default Policy
