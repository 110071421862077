import Service from "@services/abstract/BaseService"
import { MediaTypes } from "./HttpService"

class CycloneDXService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/cycloneDX", MediaTypes.JSON)
    }

    public mergeProducts = (type: string, products_id: string[]) => {
        return this.http.post(`/${this.path}/${type}/products`, products_id as string[])
    }

    public mergeProductsSboms = (type: string, files: File[]) => {
        console.log("333", files)

        return this.http.postMultiple(`/${this.path}/${type}`, files, MediaTypes.JSON)
    }
}

export { CycloneDXService }
export default CycloneDXService
