import React, { ReactNode, SyntheticEvent, useContext, useState } from "react"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { AccordionProps, Grid, styled, Typography, useTheme } from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
import { I18nContext } from "I18nProvider"

interface FaqItem {
    question: string
    answer: ReactNode
}

const faqDataEN: FaqItem[] = [
    {
        question: "What is a SBOM or product manifest?",
        answer: (
            <div>
                <p>An SBOM, or Software Bill of Materials, is a comprehensive inventory or list of all the components, libraries, and dependencies that make up a software application or system. It helps in identifying and managing vulnerabilities in third-party components, making it easier to respond to security threats.</p>
            </div>
        )
    },
    {
        question: "How does myorbik.com work?",
        answer: (
            <div>
                <p><b>myorbik.com</b> pulls CVE information from the National Vulnerability Database (NVD) every 24 hours to ensure you’re always equipped with the latest security data.
                Each CVE (Common Vulnerabilities and Exposures) is linked to specific software or hardware through a unique naming scheme called Common Platform Enumeration (CPE). CPE is essential for accurately mapping a CVE to its corresponding product name and version, which is a standard method used by most commercial and open-source tools, including <b>myorbik.com.</b></p>
                <p>When a CPE for a particular piece of hardware or software is found in a client’s Software Bill of Materials (SBOM), <b>myorbik.com</b> automatically retrieves and correlates the relevant CVE data. By integrating with your SBOM, <b>myorbik.com</b> not only helps in tracking vulnerabilities but also ensures that the security posture of your software components is continuously monitored and updated. This proactive approach minimizes the risks associated with outdated or vulnerable components, keeping your systems secure and compliant with industry standards.</p>
            </div>
        )
    },
    {
        question: "Is it possible to run myorbik.com on a device not connected to the Internet?",
        answer: (
            <div>
                <p><b>Myorbik.com</b> does not interact with the target device. Myorbik.com works by the user generating/uploading a Software BOM CSV file to the web or using the REST API, then compares the list of packages/versions against the internal vulnerability database and generates a report.</p>
                <p>Currently, myorbik.com is a hosted/cloud only solution; we do not provide an on-premises version of myorbik.com that can be on your network without internet access. However, we do plan to provide an on-premises version later this year.</p>
            </div>
        )
    },
    {
        question: "Using myorbik.com, who is responsible for fixing/mitigating a vulnerability?",
        answer: (
            <div>
                <p><b>Myorbik.com</b> assists with the monitoring and tracking of vulnerabilities and available fixes. The process of triaging identified CVEs and how they apply to your product, the decision to apply available fixes, the implementation of fixes, and the building and testing of the modified Linux product image is the responsibility of you/your engineering team.</p>
                <p>We also offer an externally managed solution to help organizations ensure the highest level of accuracy and reliability in their vulnerability management processes. This service includes expert oversight, regular audits, and continuous updates to keep your SBOMs accurate and aligned with the latest security standards. For more information and pricing details, please feel free to contact us.</p>
            </div>
        )
    },
    {
        question: "Can you get false positives?",
        answer: (
            <div>
                <p>Yes, false positives are a common issue when using tools that rely on CPE data from the National Vulnerability Database (NVD), like those found on myorbik.com. False positives occur when a tool reports a vulnerability (CVE) that doesn’t actually apply to the specific software package or version in question. This can be due to CPE data quality issues (like incorrect product names or version information), incorrect SBOM information (name/version number), or delays in a CVE being published in the NVD.</p>
                <p>To address the issue of SBOM accuracy, we’ve developed an SBOM quality metrics tool: it is designed to evaluate the quality of a Software Bill of Materials (SBOM) by providing a score based on several critical metrics.</p>
                <ul>
                    <li><b>NTIA-minimum-elements:</b>Includes features, which help you to quickly understand if an SBOM complies with NTIA’s minimum element guidelines. </li>
                    <li><b>Structural:</b>Checks if an SBOM complies with the underlying specifications, be it SPDX or CycloneDX. </li>
                    <li><b>Semantic:</b>Checks meaning of SBOM fields specific to their standard. </li>
                    <li><b>Quality:</b>Helps to determine the quality of the data in an SBOM. </li>
                    <li><b>Sharing:</b>Helps to determine if an SBOM can be shared.</li>
                </ul>
            </div>
        )
    },
    {
        question: "What information is collected when I upload my SBOM?",
        answer: (
            <div>
                <p>When you upload your SBOM for security monitoring, <b>myorbik.com</b> gathers only the package or recipe names, their versions, any applied patches, and the version of the build system. This data is exclusively shared with your team members. <b>myorbik.com</b> does not necessitate the submission of your product’s source code.</p>
            </div>
        )
    }
]

const faqDataES: FaqItem[] = [
    {
        question: "¿Qué es un SBOM o manifiesto de producto?",
        answer: (
            <div>
                <p>Un SBOM, o Lista de Materiales de Software, es un inventario detallado de todos los componentes, bibliotecas y dependencias que conforman una aplicación o sistema de software. Ayuda a identificar y gestionar vulnerabilidades en componentes de terceros, facilitando la respuesta a amenazas de seguridad.</p>
            </div>
        )
    },
    {
        question: "¿Cómo funciona myorbik.com?",
        answer: (
            <div>
                <p><b>myorbik.com</b> obtiene información de vulnerabilidades (CVE) de la Base de Datos Nacional de Vulnerabilidades (NVD) cada 24 horas para garantizar que siempre dispongas de los datos de seguridad más actualizados.</p>
                <p>Cada CVE (Vulnerabilidades y Exposiciones Comunes) está vinculado a un software o hardware específico mediante un esquema de nomenclatura único llamado Enumeración de Plataforma Común (CPE). El CPE es esencial para mapear con precisión un CVE con su correspondiente nombre y versión de producto, lo cual es un método estándar utilizado por la mayoría de las herramientas comerciales y de código abierto, incluyendo <b>myorbik.com</b>.</p>
                <p>Cuando se encuentra un CPE en un hardware o software dentro del SBOM de un cliente, <b>myorbik.com</b> recupera y correlaciona automáticamente los datos de CVE relevantes. Al integrarse con tu SBOM, <b>myorbik.com</b> no solo ayuda a rastrear vulnerabilidades, sino que también garantiza una supervisión continua de la seguridad de los componentes de software. Este enfoque proactivo minimiza los riesgos asociados con componentes obsoletos o vulnerables, manteniendo tus sistemas seguros y en conformidad con los estándares de la industria.</p>
            </div>
        )
    },
    {
        question: "¿Es posible ejecutar myorbik.com en un dispositivo sin conexión a Internet?",
        answer: (
            <div>
                <p><b>myorbik.com</b> no interactúa con el dispositivo objetivo. Funciona permitiendo que el usuario genere o cargue un archivo SBOM en formato CSV en la web o utilice la API REST, comparando la lista de paquetes y versiones con la base de datos interna de vulnerabilidades para generar un informe.</p>
                <p>Actualmente, <b>myorbik.com</b> es una solución basada en la nube y no ofrecemos una versión local que pueda ejecutarse sin acceso a Internet. Sin embargo, planeamos ofrecer una versión on-premises más adelante este año.</p>
            </div>
        )
    },
    {
        question: "Usando myorbik.com, ¿quién es responsable de corregir/mitigar una vulnerabilidad?",
        answer: (
            <div>
                <p><b>myorbik.com</b> ayuda en el monitoreo y rastreo de vulnerabilidades y posibles soluciones. Sin embargo, el proceso de análisis de los CVE identificados, la decisión de aplicar correcciones, la implementación de las soluciones y la construcción y prueba de la imagen del producto modificado es responsabilidad de tu equipo de ingeniería.</p>
                <p>También ofrecemos un servicio de gestión externa para ayudar a las organizaciones a garantizar la máxima precisión y fiabilidad en sus procesos de gestión de vulnerabilidades. Este servicio incluye supervisión experta, auditorías regulares y actualizaciones continuas para mantener tus SBOM alineados con los últimos estándares de seguridad. Para más información y precios, no dudes en contactarnos.</p>
            </div>
        )
    },
    {
        question: "¿Es posible obtener falsos positivos?",
        answer: (
            <div>
                <p>Sí, los falsos positivos son un problema común en herramientas que dependen de datos CPE de la Base de Datos Nacional de Vulnerabilidades (NVD), como <b>myorbik.com</b>. Estos ocurren cuando una herramienta informa sobre una vulnerabilidad (CVE) que en realidad no aplica a un software o versión específica. Esto puede deberse a problemas de calidad de los datos CPE (nombres de productos o versiones incorrectas), información inexacta en el SBOM o retrasos en la publicación de un CVE en la NVD.</p>
                <p>Para abordar la precisión del SBOM, hemos desarrollado una herramienta de métricas de calidad de SBOM. Esta herramienta evalúa la calidad de un SBOM proporcionando una puntuación basada en varios criterios clave:</p>
                <ul>
                    <li><b>Elementos mínimos NTIA:</b> Identifica si un SBOM cumple con las pautas mínimas establecidas por la NTIA.</li>
                    <li><b>Estructural:</b> Verifica si un SBOM cumple con las especificaciones subyacentes, ya sea SPDX o CycloneDX.</li>
                    <li><b>Semántico:</b> Analiza el significado de los campos del SBOM en función de su estándar.</li>
                    <li><b>Calidad:</b> Evalúa la calidad de los datos dentro de un SBOM.</li>
                    <li><b>Compartición:</b> Determina si un SBOM puede ser compartido.</li>
                </ul>
            </div>
        )
    },
    {
        question: "¿Qué información se recopila cuando subo mi SBOM?",
        answer: (
            <div>
                <p>Cuando subes tu SBOM para su monitoreo de seguridad, <b>myorbik.com</b> recopila únicamente los nombres de los paquetes o recetas, sus versiones, los parches aplicados y la versión del sistema de compilación. Estos datos son compartidos exclusivamente con los miembros de tu equipo. <b>myorbik.com</b> no requiere que envíes el código fuente de tu producto.</p>
            </div>
        )
    }
]

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&::before": {
        display: "none"
    },
    backgroundColor: theme.palette.background.default // Cambiado a theme.palette.background.default
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem", color: "#271968" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.default, // Cambiado a theme.palette.background.default
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
        marginLeft: "10px"
    },
    "& .MuiAccordionSummary-expandIcon": {
        color: theme.palette.primary.main // Cambiado para que la flecha también tenga el color del tema
    },
    "& .MuiTypography-root": {
        color: theme.palette.primary.main // Cambiado para que el texto tenga el color del tema
    }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default, // Cambiado a theme.palette.background.default
    "& .MuiTypography-root": {
        color: theme.palette.primary.main // Cambiado para que el texto en los detalles también tenga el color del tema
    }
}))

const FAQ = () => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState<number | false>(false)
    const [faqData, setFaqData] = useState<FaqItem[]>(faqDataEN)

    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    if (context.language === "es") {
        setFaqData(faqDataES)
    }

    const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("FAQ")}
                </Typography>
            </Grid>
            <Grid item>
                {faqData.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                    >
                        <AccordionSummary>
                            <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.contrastText, fontSize: "20px" }}>
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: theme.palette.primary.contrastText }}>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
    )
}

export default FAQ
