import React, { useContext, useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { ProductType } from "@models/Product"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { IconButton, Tooltip } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { I18nContext } from "I18nProvider"
import { ProductTypeValueOps } from "./ProductTableDefinition"

interface ColorAndBg {
    color: string;
    "background-color": string;
}

type ProductTypeColorMap = {
  [key in ProductType]: ColorAndBg;
};

type ProductTypeDescriptionMap = {
  [key in ProductType]: string;
};

// Map de colores para cada tipo de producto.
const getProductTypeColor = (status: ProductType): ColorAndBg => {
    const assessmentStatusColorMap: ProductTypeColorMap = {
        [ProductType.Application]: { color: "#000000", "background-color": ProductTypeColors.application },
        [ProductType.Library]: { color: "#000000", "background-color": ProductTypeColors.library },
        [ProductType.Firmware]: { color: "#000000", "background-color": ProductTypeColors.firmware },
        [ProductType.OS]: { color: "#000000", "background-color": ProductTypeColors["operating-system"] },
        [ProductType.Container]: { color: "#000000", "background-color": ProductTypeColors.container },
        [ProductType.Device]: { color: "#000000", "background-color": ProductTypeColors.device }
    }
    return assessmentStatusColorMap[status]
}

// Map de descripciones para cada tipo de producto.

interface ProductTypeColoredProps {
    value: ProductType;
}

const ProductTypeColored: React.FC<ProductTypeColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg | null>(null)
    const [label, setLabel] = useState<string | undefined>("")
    const [description, setDescription] = useState<string>("")
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const productTypeDescriptions: ProductTypeDescriptionMap = {
        [ProductType.Application]: context.t.translate("product_type_application"),
        [ProductType.Library]: context.t.translate("product_type_library"),
        [ProductType.Firmware]: context.t.translate("product_type_firmware"),
        [ProductType.OS]: context.t.translate("product_type_os"),
        [ProductType.Container]: context.t.translate("product_type_container"),
        [ProductType.Device]: context.t.translate("product_type_device")
    }

    useEffect(() => {
        setLabel(ProductTypeValueOps.find(v => v.value === value)?.label)
        setColor(getProductTypeColor(value))
        setDescription(productTypeDescriptions[value])
    }, [value])

    return (
        <>
            <Chip
                size="small"
                style={{
                    fontWeight: "bolder",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }}
                label={label}
            />
            <Tooltip title={description}>
                <IconButton>
                    <HelpOutlineIcon color="primary"/>
                </IconButton>
            </Tooltip>
        </>
    )
}

export { ProductTypeColored }
export default ProductTypeColored
