import Cpe from "@models/Cpe"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class CPEService extends Service<Cpe> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/cpe", MediaTypes.JSON)
    }

    public inyectCPE = (assessmentID: string, cpe: Cpe[]) => {
        return this.http.postMultiple(`/${this.path}/assessment/${assessmentID}`, cpe, MediaTypes.JSON)
    }
}

export { CPEService }
export default CPEService
