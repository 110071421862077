import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import moment from "moment"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
const i18n = {
    es: {
        cibersecurity: "Ciberseguridad",
        monthyear: "Mes y año:",
        footer: {
            copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
            confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
            disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
            errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    },
    en: {
        cibersecurity: "Cybersecurity",
        monthyear: "Month and year:",
        footer: {
            copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
            confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
            disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
            errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    }
} as const
const FrontPage: React.FC<{lang?: string}> = ({ lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4">
            <View fixed style={{ backgroundColor: "white", height: "65%" }}> {/* Fondo blanco ocupando el 65% */}
                <View style={{ flexDirection: "row", justifyContent: "space-between", height: "100%", paddingLeft: 3 }}> {/* Imágenes una al lado de la otra */}
                    <Image style={{ width: "100%", height: "auto", objectFit: "contain" }} src="/assets/img/CAF.png" /> {/* Ajuste para que no se estiren */}
                    <Image style={{ width: "100%", height: "auto", objectFit: "contain" }} src="/assets/img/LeadMind.png" /> {/* Ajuste para que no se estiren */}
                </View>
            </View>
            <View style={{ backgroundColor: "#271968", height: "35%", color: "#fff" }}> {/* Fondo azul ocupando el 35% */}
                <View style={{ marginRight: 37, marginLeft: 36, paddingTop: "35px" }}>
                    <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                        <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Griff", paddingBottom: 0, color: "white" }}>{i18n[locale].cibersecurity}</Text> {/* Título en blanco */}
                        </View>
                        <View style={{ ...styles.tableCola2 }}>
                            <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                                <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff", color: "white" }}>{i18n[locale].monthyear}</Text> {/* Texto en blanco */}
                                </View>
                                <View style={{ ...styles.column, marginLeft: -60, marginTop: 0 }}>
                                    <Text style={{ ...styles.titulo2page1, paddingBottom: 2, fontFamily: "Griff", color: "white" }}>{moment().format("MMMM YYYY")}</Text> {/* Texto en blanco */}
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <FooterFrontPage /> {/* Footer debajo de todo */}
            </View>
        </Page>
    )
}
const FooterFrontPage: React.FC<{lang?: string}> = ({ lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
            <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, fontSize: 5.5, textAlign: "justify", color: "white" }}>
                {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                {i18n[locale].footer.disclosureWarning}
                {i18n[locale].footer.errorNotice}
            </Text>
        </View>
    )
}
export { FrontPage }
