import React, { useContext, useEffect } from "react"
import { MenuItem, TextField } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { Hierarchy } from "@models/Hierarchy"
import { FilterOperation, FilterOption } from "@utils/index"
import { AbilityContext } from "@components/permissions"

interface HierarchySelectorProps {
    value: string
    customFilters?: FilterOption[]
    onSelect: (id: string|undefined, h: Hierarchy|null) => void
    index: number
}

const HierarchySelector: React.FC<HierarchySelectorProps> = ({ value, customFilters, onSelect, index }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const [disabled, setDisabled] = React.useState<boolean>(true)
    // Añadimos una key para forzar el rerender del SearchInput
    const [filterKey, setFilterKey] = React.useState(0)

    useEffect(() => {
        let isEnabled = customFilters !== undefined &&
                        customFilters[0]?.value !== undefined &&
                        customFilters[0].value !== ""

        if (!ability.can("*", "*")) {
            isEnabled = true
        }
        setDisabled(!isEnabled)
        if (!ability.can("*", "*")) {
            isEnabled = true
        }
        // Cuando cambian los filtros, actualizamos la key
        if (isEnabled) {
            setFilterKey(prev => prev + 1)
            // Opcional: limpiar el valor seleccionado cuando cambian los filtros
            // onSelect(undefined, null)
        }
    }, [customFilters, onSelect])

    if (disabled) {
        return (
            <TextField
                margin="normal"
                select
                disabled
                fullWidth
                variant="filled"
                label={context.t.translate("hierar_level")}
                value="no"
            >
                <MenuItem value="no">
                    <em>{context.t.translate("hierar_option")}</em>
                </MenuItem>
            </TextField>
        )
    }

    return (
        <SearchInput<Hierarchy>
            key={filterKey} // Esto forza el rerender cuando cambian los filtros
            margin="normal"
            label={`Hierarchy level ${index}`}
            variant="outlined"
            value={value}
            requesterById={async v => {
                if (v && v !== "00000000-0000-0000-0000-000000000000") {
                    const hierarchy = await hierarchyService.get(v)
                    return [hierarchy]
                }
                const hi: Hierarchy = { id: "", name: "", path: "", description: "", client: { id: "" } }
                return [hi]
            }}
            requester={async v => {
                if (v === "" || v === undefined) {
                    const hierarchies = customFilters
                        ? await hierarchyService.getAll({ filters: customFilters })
                        : await hierarchyService.getAll()
                    return hierarchies.list
                }
                let filter: FilterOption[] = [{ field: "name", operation: FilterOperation.StringContains, value: v }]
                if (customFilters?.length && customFilters[0].value !== "") {
                    filter = filter.concat(customFilters)
                }
                const hierarchies = await hierarchyService.getAll({ filters: filter })
                return hierarchies.list
            }}
            getterID={elem => elem?.id}
            getterTitle={elem => elem?.name || ""}
            onClickElem={(v) => onSelect(v?.id, v)}
        />
    )
}

export { HierarchySelector }
export default HierarchySelector
