import React from "react"
import { Grid, Skeleton } from "@mui/material"

const SunburstChartSkeleton: React.FC = () => {
    return (
        <Grid container spacing={2}>
            {/* Left Side - Circular Skeleton */}
            <Grid item xs={9}>
                <Skeleton
                    variant="circular"
                    width={700}
                    height={700}
                    sx={{ margin: "auto" }}
                />
            </Grid>

            {/* Right Side - Rectangular Skeletons */}
            <Grid item container xs={3} spacing={2} direction="column">
                {[1, 2, 3].map((item) => (
                    <Grid item key={item}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default SunburstChartSkeleton
