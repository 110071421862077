import { StyledBox } from "@components/common/Box/BoxContainer"
import Hierarchy from "@models/Hierarchy"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import ColoredHierarchy from "@views/product/HierarchyCard"
import { FilterOperation } from "@utils/queryParams"
import ServicesContext from "@context/ServicesContext"

type TreeTabProps = {
    hierarchie: Hierarchy
}

const TreeTab: React.FC<TreeTabProps> = ({ hierarchie }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    const hierarchyService = useContext(ServicesContext).hierarchyService

    const [children, setChildren] = useState<Hierarchy[]>([]) // Estado para almacenar los hijos

    // Verificar si el contexto de I18n está disponible
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    // Función para obtener los hijos de un nodo basándose en el path
    const fetchChildren = async (node: Hierarchy) => {
        if (!node.path) return // Si no tiene path, no podemos buscar hijos
        const pathIds = node.path.split(";")
        try {
            const filters = { orFilters: [{ field: "id", operation: FilterOperation.UUIDArrayContains, value: pathIds.toString() }, { field: "path", operation: FilterOperation.StringContains, value: hierarchie.id }] }
            const childNodes = await hierarchyService.getAll(filters) // Obtener hijos desde el servicio
            setChildren(childNodes.list) // Guardar los hijos obtenidos
        } catch (error) {
            console.error("Error fetching children:", error)
        }
    }
    useEffect(() => {
        console.log("CHILD", children)
    }, [children])
    // Llamar a la función fetchChildren cuando el componente se monte
    useEffect(() => {
        fetchChildren(hierarchie)
    }, [hierarchie])

    return (
        <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Product Section">
            <Grid item xs sx={{ top: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("product")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <StyledBox>
                            <Grid item xs container flexDirection="column" spacing="20px">
                                <Grid item container flexDirection="column" rowGap="35px">
                                    <Grid item container>
                                        {/* Pasar los hijos obtenidos al componente ColoredHierarchy */}
                                        <ColoredHierarchy hierarchies={[...children, hierarchie]} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TreeTab
