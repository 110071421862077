import { Box, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions"
import HardwareComponents, { HardwareComponentsOrigin } from "@models/HardwareComponents"

const initValue: HardwareComponents = {
    id: "",
    identifier: "",
    name: "",
    origin: HardwareComponentsOrigin.SIEMENS, // Default origin, you can adjust as necessary
    client: { id: "" }, // Not needed in the form
    product: { id: "" } // Not needed in the form
}

const HardwareComponentForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const hardwareComponentService = useContext(ServicesContext).hardwareComponentService
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const ability = useContext(AbilityContext)
    const [formData, setFormData] = useState<HardwareComponents>(initValue)
    const navigate = useNavigate()
    const assessmentService = useContext(ServicesContext).assessmentService
    const { id } = useParams<{ id: string }>()

    // Functions
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const val = await assessmentService.get(id as string)
                setFormData(prevFormData => ({
                    ...prevFormData,
                    client: { id: val.client.id },
                    product: { id: val.product.id }
                }))
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fetchData()
    }, [assessmentService, id])

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.identifier === "",
            formData.name === "",
            !Object.values(HardwareComponentsOrigin).includes(formData.origin)
        ]

        if (isNotNullrules.some(rule => rule)) {
            return false
        }

        return true
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await hardwareComponentService.create(formData)
            setError(null)
            navigate(-1) // Navigate back after submit
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label="Identifier"
                            name="identifier"
                            value={formData.identifier}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            select
                            required
                            fullWidth
                            variant="filled"
                            label="Origin"
                            name="origin"
                            value={formData.origin}
                            onChange={handleInputChange}
                        >
                            <MenuItem value={HardwareComponentsOrigin.SIEMENS}>
                                <Typography fontFamily="Griff" fontWeight="bolder">SIEMENS</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.DEMO}>
                                <Typography fontFamily="Griff" fontWeight="bolder">DEMO</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.ST}>
                                <Typography fontFamily="Griff" fontWeight="bolder">ST</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.RENESAS}>
                                <Typography fontFamily="Griff" fontWeight="bolder">RENESAS</Typography>
                            </MenuItem>
                            <MenuItem value={HardwareComponentsOrigin.CISA}>
                                <Typography fontFamily="Griff" fontWeight="bolder">CISA</Typography>
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <ActionButton type="submit" disabled={!formValid()} onClick={() => {}} text="Create Hardware Component" style={{ width: "100%" }} />
            </Box>
        </Box>
    )
}

export { HardwareComponentForm }
export default HardwareComponentForm
