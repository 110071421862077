import { Button, Tooltip, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React, { useContext, useState } from "react"
import ServicesContext from "@context/ServicesContext"

interface ComponentNameNavigateProps {
    value: string;
    id: string;
}

const ComponentPackageNavigate: React.FC<ComponentNameNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const componentService = useContext(ServicesContext).componentService
    const [data, setData] = useState<any>()

    const handleClick = () => {
        const cleanedValue = value.replace("pkg:", "")
        navigate(`./package/${encodeURIComponent(cleanedValue)}`)
    }

    return (
        <Tooltip title={value} arrow>
            <Button
                onClick={handleClick}
                style={{
                    padding: 0,
                    minWidth: 0,
                    background: "none",
                    border: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "100%", // Para asegurarse de que respete el contenedor padre
                    overflow: "hidden"
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bolder",
                        fontFamily: "Griff",
                        fontSize: "12px",
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                        "&:hover": {
                            color: theme.palette.text.secondary
                        },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "block"
                    }}
                >
                    {value}
                </Typography>
            </Button>
        </Tooltip>
    )
}

export { ComponentPackageNavigate }
export default ComponentPackageNavigate
