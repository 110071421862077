/* eslint-disable react/display-name */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react"
import { Grid, useTheme } from "@mui/material"
import Hierarchy from "@models/Hierarchy"
import { I18nContext } from "I18nProvider"
import { Connection, Controls, Edge, Position, ReactFlow, useNodesState } from "@xyflow/react"
type HierarchyManageProps = {
    fetchedHierarchies: Hierarchy[]
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    setUpdatedHierarchies: React.Dispatch<React.SetStateAction<Hierarchy[]>>;
    updatedHierarchies: Hierarchy[]
}
const HierarchyManage = forwardRef(({ fetchedHierarchies, setIsEditing, setUpdatedHierarchies, updatedHierarchies }: HierarchyManageProps, ref) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const [originalData, setOriginalData] = useState<Hierarchy[]>([])
    const [hierarchies, setHierarchies] = useState<Hierarchy[]>([])
    const [edges, setEdges] = useState<Edge[]>([])

    useEffect(() => {
        setHierarchies(fetchedHierarchies)
        setOriginalData(fetchedHierarchies)
        setUpdatedHierarchies(fetchedHierarchies)
    }, [fetchedHierarchies])
    const getDirectChildren = (parentId: string): Hierarchy[] => {
        return hierarchies.filter(h =>
            h.path &&
            h.path.split(";").slice(-2, -1)[0] === parentId
        )
    }
    // Función para obtener nodos raíz (sin padres o con path de un solo elemento)
    const getRootNodes = (): Hierarchy[] => {
        return hierarchies.filter(h =>
            !h.path || h.path.split(";").length === 1
        )
    }
    const handleRestore = () => {
        setIsEditing(false)
        setHierarchies(originalData)
        setUpdatedHierarchies(originalData)
        setNodes(originalData.map((hierarchy) => {
            const position = finalPositions.get(hierarchy.id) || { x: 0, y: 0 }
            return {
                id: hierarchy.id,
                position,
                data: { label: hierarchy.name || "Unnamed Hierarchy" },
                style: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: "white",
                    border: "2px solid gray",
                    borderRadius: "12px",
                    padding: "10px",
                    width: "auto",
                    minWidth: "150px",
                    textAlign: "center"
                },
                sourcePosition: Position.Bottom,
                targetPosition: Position.Top,
                connectable: true,
                draggable: true
            }
        }))
        setEdges(originalData
            .filter((h) => h.path)
            .flatMap((hierarchy) => {
                const parentIds = hierarchy.path.split(";")
                const directParentId = parentIds[parentIds.length - 2]
                if (!directParentId || directParentId === hierarchy.id) return []

                return [{
                    id: `${directParentId}-${hierarchy.id}`,
                    source: directParentId,
                    target: hierarchy.id,
                    type: "smoothstep",
                    animated: false,
                    style: { stroke: "#888", strokeWidth: 2, strokeDasharray: "0" }
                }]
            })
        )
    }
    // Función recursiva para calcular las posiciones
    const calculatePositions = (
        node: Hierarchy,
        level: number,
        horizontalOffset: number,
        widthMap: Map<string, number>
    ): { positions: Map<string, { x: number; y: number }>, width: number } => {
        const children = getDirectChildren(node.id)
        const verticalSpacing = 150
        const horizontalSpacing = 250

        if (children.length === 0) {
            const position = { x: horizontalOffset, y: level * verticalSpacing }
            const positions = new Map([[node.id, position]])
            widthMap.set(node.id, horizontalSpacing)
            return { positions, width: horizontalSpacing }
        }

        let totalWidth = 0
        const childPositions = new Map<string, { x: number; y: number }>()

        // Calcular posiciones de los hijos
        children.forEach((child) => {
            const { positions: childPos, width } = calculatePositions(
                child,
                level + 1,
                horizontalOffset + totalWidth,
                widthMap
            )
            childPos.forEach((pos, id) => childPositions.set(id, pos))
            totalWidth += width
        })

        // Calcular posición del padre en el centro de sus hijos
        const firstChildX = childPositions.get(children[0].id)?.x || 0
        const lastChildX = childPositions.get(children[children.length - 1].id)?.x || 0
        const parentX = firstChildX + (lastChildX - firstChildX) / 2

        // Añadir posición del padre
        childPositions.set(node.id, {
            x: parentX,
            y: level * verticalSpacing
        })

        widthMap.set(node.id, totalWidth)
        return { positions: childPositions, width: totalWidth }
    }

    // Calcular todas las posiciones
    const widthMap = new Map<string, number>()
    const finalPositions = new Map<string, { x: number; y: number }>()
    let currentOffset = 0

    getRootNodes().forEach(root => {
        const { positions } = calculatePositions(root, 0, currentOffset, widthMap)
        positions.forEach((pos, id) => finalPositions.set(id, pos))
        currentOffset += (widthMap.get(root.id) || 0) + 50
    })
    const [nodes, setNodes, onNodesChange] = useNodesState(hierarchies.map((hierarchy) => {
        const position = finalPositions.get(hierarchy.id) || { x: 0, y: 0 }
        return {
            id: hierarchy.id,
            position,
            data: { label: hierarchy.name || "Unnamed Hierarchy" },
            style: {
                backgroundColor: theme.palette.secondary.dark,
                color: "white",
                border: "2px solid gray",
                borderRadius: "12px",
                padding: "10px",
                width: "auto",
                minWidth: "150px",
                textAlign: "center" as const
            },
            sourcePosition: Position.Bottom,
            targetPosition: Position.Top,
            connectable: true,
            draggable: true
        }
    }))
    // Crear nodos con las nuevas posiciones
    useEffect(() => {
        setNodes(hierarchies.map((hierarchy) => {
            const position = finalPositions.get(hierarchy.id) || { x: 0, y: 0 }
            return {
                id: hierarchy.id,
                position,
                data: { label: hierarchy.name || "Unnamed Hierarchy" },
                style: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: "white",
                    border: "2px solid gray",
                    borderRadius: "12px",
                    padding: "10px",
                    width: "auto",
                    minWidth: "150px",
                    textAlign: "center" as const
                },
                sourcePosition: Position.Bottom,
                targetPosition: Position.Top,
                connectable: true,
                draggable: true
            }
        }))
        const newEdges = hierarchies
            .filter((h) => h.path)
            .flatMap((hierarchy) => {
                const parentIds = hierarchy.path.split(";")
                const directParentId = parentIds[parentIds.length - 2]
                if (!directParentId || directParentId === hierarchy.id) return []

                return [{
                    id: `${directParentId}-${hierarchy.id}`,
                    source: directParentId,
                    target: hierarchy.id,
                    type: "smoothstep",
                    animated: true,
                    style: { stroke: "#888" }
                }]
            })
        setEdges(newEdges)
        setUpdatedHierarchies(hierarchies)
    }, [hierarchies])

    const handleConnect = (connection: Connection) => {
        const { source, target } = connection
        if (!source || !target) return

        // Buscar jerarquías de los nodos
        const targetHierarchy = hierarchies.find((h) => h.id === target)
        const sourceHierarchy = hierarchies.find((h) => h.id === source)

        const targetPathSegments = targetHierarchy?.path ? targetHierarchy.path.split(";") : []
        const sourcePathSegments = sourceHierarchy?.path ? sourceHierarchy.path.split(";") : []

        // Verificar si el source ya es ancestro del target (evita ciclos directos)
        if (targetPathSegments.includes(source)) {
            console.warn(`No se puede conectar: ${source} ya es ancestro de ${target} (evita ciclos)`)
            return
        }

        // Verificar si el target ya es ancestro del source (evita ciclos inversos)
        if (sourcePathSegments.includes(target)) {
            console.warn(`No se puede conectar: ${target} ya es ancestro de ${source} (evita ciclos)`)
            return
        }

        // Verificar si el target ya tiene un padre (path con más de 1 elemento)
        if (targetPathSegments.length > 1) {
            console.warn(`No se puede conectar: ${target} ya tiene un padre`)
            return
        }

        // Agregar la nueva conexión al estado de edges
        setEdges((prevEdges) => [
            ...prevEdges,
            {
                id: `${source}-${target}`,
                source,
                target,
                type: "default",
                animated: true,
                style: { stroke: "#888" }
            }
        ])

        // Actualizar updatedHierarchies con el nuevo path
        setHierarchies((prevHierarchies) =>
            prevHierarchies.map((h) => {
                if (h.id === target) {
                    let pathSegments = h.path ? h.path.split(";") : []

                    // Asegurar que la ID del target no esté repetida
                    pathSegments = pathSegments.filter((id) => id !== target)

                    // Insertar el padre (source) antes del hijo (target)
                    pathSegments.unshift(source)
                    pathSegments.push(target)

                    return { ...h, path: pathSegments.join(";") }
                }
                return h
            })
        )
        setIsEditing(true)
    }

    const handleEdgeClick = (_: React.MouseEvent, edge: Edge) => {
        // Eliminar la conexión de edges
        setEdges((prevEdges) => prevEdges.filter((e) => e.id !== edge.id))

        const updateHierarchyPaths = (prevHierarchies: Hierarchy[]) =>
            prevHierarchies.map((h) => {
                // Verificar si el nodo afectado es el target del edge eliminado
                if (h.id === edge.target) {
                    let pathSegments = h.path.split(";")

                    // Encontrar el índice del nodo que se elimina
                    const indexToRemove = pathSegments.indexOf(edge.source)

                    if (indexToRemove !== -1) {
                        // Eliminar el nodo `source` del `path`
                        pathSegments = pathSegments.slice(0, indexToRemove)

                        // Asegurar que el `path` siempre contenga su propia `id`
                        if (!pathSegments.includes(h.id)) {
                            pathSegments.push(h.id)
                        }

                        return { ...h, path: pathSegments.join(";") }
                    }
                }
                return h
            })

        setHierarchies(updateHierarchyPaths)
        setIsEditing(true)
    }

    useImperativeHandle(ref, () => ({
        restore: handleRestore
    }))
    const handleEdgeDelete = (edgesToRemove: Edge[]) => {
        setEdges((prevEdges) => prevEdges.filter((e) => !edgesToRemove.some((edge) => edge.id === e.id)))
        const nodesToUpdate = hierarchies.filter((h) =>
            edgesToRemove.some((edge) => edge.target === h.id)
        )
        setHierarchies((prevHierarchies) =>
            prevHierarchies.map((h) => {
                let updatedPath = h.path

                nodesToUpdate.forEach((nodeToRemove) => {
                    if (updatedPath.includes(nodeToRemove.id)) {
                        // Eliminamos el nodo que corresponde al "padre" del path y todos los nodos a la derecha
                        updatedPath = updatedPath.split(";").slice(0, updatedPath.split(";").indexOf(nodeToRemove.id)).join(";")
                    }
                })

                return { ...h, path: updatedPath }
            })
        )
    }

    return (

        <Grid item container flexDirection="column" rowGap="35px">
            <div style={{ width: "100%", height: "500px" }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onEdgeClick={handleEdgeClick}
                    onEdgesDelete={handleEdgeDelete}
                    onConnect={handleConnect}
                    onNodesChange={onNodesChange}
                    fitView
                >
                    <Controls style={{ backgroundColor: theme.palette.primary.main, color: "#000" }}></Controls>
                </ReactFlow>
            </div>
        </Grid>

    )
})

export { HierarchyManage }
export default HierarchyManage
