import ServicesContext from "@context/ServicesContext"
import Vulnerability from "@models/Vulnerability"
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material"
import { FilterOption } from "@utils/queryParams"
import React, { useContext, useEffect, useState } from "react"
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid"
import { AbilityContext } from "@components/permissions"
import { VulnerabilityTableDefinition, VulnerabilityTableDefinitionReduced } from "@components/vulnerability"
import { useNavigate } from "react-router-dom"
import { useHierarchy } from "@context/useHierarchy"
import GenericTable from "../tables/GenericTable"
import { convertModelDefToGridColDef } from "../tables"
import GenericModal from "../modals/GenericModal"

interface Props {
    filters: FilterOption[];
    low: number,
    medium: number,
    high: number,
    critical: number,
    none: number,
    isHierarchy?: boolean,
    hierarchy?: string
}
interface Indicator {
    backgroundColor: string,
    color: string;
    count: number;
    type: string
}
const VulnerabilityIndicator: React.FC<Props> = ({ filters = [], low, medium, high, critical, none, isHierarchy = false, hierarchy }) => {
    // Constants
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    const navigate = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [vulnerabilitiesOpen, setVulnerabilitiesOpen] = React.useState(false)
    const handleVulnerabilitiesClose = () => setVulnerabilitiesOpen(false)
    const { hierarchyId, setHierarchyId } = useHierarchy()
    // --------------------------------------------------
    if (none > 0) { indicators.push({ backgroundColor: "black", color: "#A0A0A0", count: none, type: "None" }) } // Gris neutro
    if (low > 0) { indicators.push({ backgroundColor: "black", color: "#FFD166", count: low, type: "Low" }) } // Amarillo suave
    if (medium > 0) { indicators.push({ backgroundColor: "black", color: "#F8961E", count: medium, type: "Medium" }) } // Naranja fuerte
    if (high > 0) { indicators.push({ backgroundColor: "black", color: "#E63946", count: high, type: "High" }) } // Rojo brillante
    if (critical > 0) { indicators.push({ backgroundColor: "black", color: "#D00000", count: critical, type: "Critical" }) } // Rojo intenso

    if (indicators.length === 0) {
        indicators.push({ backgroundColor: "black", color: "gray", count: 0, type: "N/D" })
    }
    // useEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(!isHierarchy ? VulnerabilityTableDefinition : VulnerabilityTableDefinitionReduced, ability))
    }, [])
    useEffect(() => {
    }, [indicators])
    const totalCount = indicators.reduce((sum, indicator) => sum + indicator.count, 0)
    // --------------------------------------------------
    // const handlerIndicatorClick = async () => {
    //     const params: QueryParameters = {
    //         filters: [
    //             { field: "affected_component", operation: FilterOperation.EnumEqual, value: componentId }
    //         ]
    //     }
    //     const response = await vulnerabilityService.getAll(params)
    //     console.log(response)
    //     console.log(componentId)
    // }

    if (indicators.length > 0) {
        return (
            <Grid
                component={Paper}
                elevation={2}
                container
                sx={{ width: "200px", height: "12px", display: "flex", borderRadius: "12px" }}
            >
                {indicators.map((indicator, index) => {
                    const widthPercentage = totalCount === 0 ? 100 : (indicator.count / totalCount) * 100

                    return (
                        <Tooltip title={indicator.type} key={index}>
                            <Grid
                                item
                                sx={{
                                    width: `${widthPercentage}%`,
                                    background: indicator.color,
                                    color: "black",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"
                                    } ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"
                                    }`,
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                // handlerIndicatorClick()
                                    if (hierarchy) { setHierarchyId(hierarchy) }
                                    setVulnerabilitiesOpen(indicator.count > 0)
                                }}
                            >
                                {indicator.count}
                            </Grid>
                        </Tooltip>
                    )
                })}
                <GenericModal open={vulnerabilitiesOpen} onClose={handleVulnerabilitiesClose} title="Vulnerabilities">
                    <Box width="100%" gap={2}>
                        <Grid item width="1000px">
                            <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                                dataProvider={(filter) => {
                                    const newFilter = filter
                                    newFilter.filters = [...newFilter.filters || [], ...filters]
                                    return vulnerabilityService.getAll(newFilter)
                                }}
                                onEdit={(elem: Vulnerability) => navigate("../vulnerability/" + elem.id)}
                                onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                                dataGridProps={{
                                    getRowClassName: (params: GridRowClassNameParams) =>
                                        params.row.supressed ? "suppressedRow" : "",
                                    sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } }
                                }}
                                // TODO: Check why this renders twice
                                externalParameters={{ filters }}
                                isInModal={true}
                            />
                        </Grid>
                    </Box>
                </GenericModal >
            </Grid >
        )
    }
    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.text.secondary}>
            No vulnerabilities
        </Typography>
    )
}
export { VulnerabilityIndicator }
