import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import ComponentList from "./ComponentList"
import ComponentDetail from "./ComponentDetail"
import ComponentForm from "./ComponentForm"
import ComponentPackageDetail from "./ComponentPackageDetail"

const entity = "Component"
const Component: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    // Primero las rutas más específicas
    if (ability.can("create", entity)) {
        routes.push({
            route: "/create/add", // ruta exacta para agregar
            component: <ComponentForm />
        })
    }

    if (ability.can("update", entity)) {
        routes.push({
            route: "/package/:package", // ruta específica para paquete
            component: <ComponentPackageDetail />
        })
    }

    // Luego las rutas más generales
    if (ability.can("read", entity)) {
        routes.push({
            route: "", // ruta para la lista
            component: <ComponentList />
        })
    }

    if (ability.can("update", entity)) {
        routes.push({
            route: ":componentId", // ruta dinámica para el detalle
            component: <ComponentDetail />
        })
    }

    return (
        <Routes>
            {routes.map((item, idx) => (
                <Route key={idx} path={item.route} element={item.component} />
            ))}
        </Routes>
    )
}

export { Component }
export default Component
