/* eslint-disable no-useless-escape */
/* eslint-disable space-before-function-paren */
import {
    Box,
    Button,
    Collapse,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Tooltip,
    Typography
} from "@mui/material"
import {
    AiFillStar,
    AiOutlineCheck,
    AiOutlineClockCircle,
    AiOutlineClose,
    AiOutlineDown,
    AiOutlineHeart,
    AiOutlineRight
} from "react-icons/ai"
import React, { useContext, useState } from "react"
import { I18nContext } from "I18nProvider"
import { Filter, Filters, operationMap } from "@models/Filter"

interface FilterSheetProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    recentFilters: Filter[];
    favoriteFilters: Filter[];
    onApplyFilter: (filter: Filter) => void;
    onToggleFavorite: (e: React.MouseEvent, filter: Filter) => void;
    onRemoveFilter: (e: React.MouseEvent, filter: Filter) => void;
    onFilterDeselected?: () => void;
}

export default function FilterSheet({
    isOpen,
    onOpenChange,
    recentFilters,
    favoriteFilters,
    onApplyFilter,
    onToggleFavorite,
    onRemoveFilter,
    onFilterDeselected
}: FilterSheetProps) {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    // Estado para rastrear qué filtros están expandidos
    const [expandedFilters, setExpandedFilters] = useState<Record<string, boolean>>({})

    // Estado para rastrear el filtro seleccionado
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null)

    const formatFilterText = (text: string) => {
        return text
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }

    const formatFilter = (filter: Filters) => {
        const { Field, FilterOperation, Value } = filter

        // Ocultamos client_id
        if (Field === "client_id") return null

        // Mapeo de la operación
        const operation = operationMap[Number(FilterOperation)] || "unknown operation"

        // Formateo del campo y el valor
        const fieldFormatted = formatFilterText(Field)
        const valueFormatted = Array.isArray(Value) ? Value.join(", ") : Value

        return `${fieldFormatted} ${operation} ${valueFormatted}`
    }

    // Process filters to make them more readable
    const processFilters = (filters: Filters[]) => {
        return filters
            .map(formatFilter) // formateamos cada filtro individualmente
            .filter(filter => filter !== null) // Eliminar los valores nulos
    }

    // Manejar la expansión/contracción de un filtro
    const handleToggleExpand = (filterId: string) => {
        setExpandedFilters(prev => ({
            ...prev,
            [filterId]: !prev[filterId]
        }))
    }

    // Manejar la selección/deselección de un filtro
    const handleFilterSelection = (filter: Filter) => {
        // Si el filtro seleccionado es el mismo, lo deseleccionamos
        if (selectedFilter && selectedFilter.id === filter.id) {
            setSelectedFilter(null)
            // Call the deselection callback if provided
            if (onFilterDeselected) {
                onFilterDeselected()
            }
        } else {
            // Otherwise, select the new filter
            setSelectedFilter(filter)
            onApplyFilter(filter)
        }
    }

    const renderFilterItem = (filter: Filter, type: "recent" | "favorite") => {
        const visibleFilters = processFilters(filter.filters)
        const filterId = `${type}-${filter.id}`
        const isExpanded = expandedFilters[filterId] || false
        const hasMoreFilters = visibleFilters.length > 1
        const isSelected = selectedFilter?.id === filter.id

        if (visibleFilters.length === 0) {
            return null
        }

        const firstFilter = visibleFilters[0]
        const remainingFilters = visibleFilters.slice(1)

        return (
            <Paper
                key={filterId}
                elevation={0}
                sx={{
                    mb: 1,
                    borderRadius: 2,
                    "&:last-child": { mb: 0 },
                    overflow: "hidden",
                    border: isSelected ? "1px solid" : "none",
                    borderColor: isSelected ? "primary.main" : "transparent"
                }}
            >
                <ListItemButton
                    onClick={() => handleFilterSelection(filter)}
                    sx={{
                        p: 2,
                        transition: "all 0.2s",
                        "&:hover": { bgcolor: "rgba(0,0,0,0.03)" }
                    }}
                >
                    <ListItemText
                        primary={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        py: 0.5,
                                        px: 1.5,
                                        borderRadius: 1,
                                        bgcolor: "rgba(0,0,0,0.05)",
                                        display: "inline-block"
                                    }}
                                >
                                    {firstFilter}
                                </Typography>
                                {hasMoreFilters && (
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleToggleExpand(filterId)
                                        }}
                                        sx={{ ml: 1 }}
                                        color="primary"
                                    >
                                        {isExpanded ? <AiOutlineDown size={14} color="primary" /> : <AiOutlineRight size={14} color="primary" />}
                                    </IconButton>
                                )}
                            </Box>
                        }
                        sx={{ m: 0 }}
                    />
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 1
                    }}>
                        <Tooltip title={filter.favorite ? context.t.translate("remove_filter_favorite") : context.t.translate("add_filter_favorite")}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onToggleFavorite(e, filter)
                                }}
                                sx={{
                                    borderRadius: 1,
                                    mr: 1,
                                    bgcolor: filter.favorite ? "rgba(255, 215, 0, 0.1)" : "transparent",
                                    "&:hover": {
                                        bgcolor: filter.favorite ? "rgba(255, 215, 0, 0.2)" : "rgba(0,0,0,0.05)"
                                    }
                                }}
                            >
                                <AiFillStar size={16} color={filter.favorite ? "#FFD700" : "#aaa"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={context.t.translate("delete_filter")}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onRemoveFilter(e, filter)
                                }}
                                sx={{
                                    borderRadius: 1,
                                    "&:hover": { bgcolor: "rgba(255,0,0,0.05)" }
                                }}
                            >
                                <AiOutlineClose size={16} color="#f44336" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </ListItemButton>

                {/* Lista expandible de filtros adicionales (solo si hay más de uno) */}
                {hasMoreFilters && (
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box sx={{
                            p: 2,
                            pt: 0,
                            pb: 1.5,
                            borderTop: "1px dashed",
                            borderColor: "divider",
                            bgcolor: "rgba(0,0,0,0.01)"
                        }}>
                            <List disablePadding dense>
                                {remainingFilters.map((f, idx) => (
                                    <Typography
                                        key={idx}
                                        variant="body2"
                                        sx={{
                                            py: 0.5,
                                            px: 1.5,
                                            my: 0.5,
                                            borderRadius: 1,
                                            bgcolor: "rgba(0,0,0,0.05)",
                                            display: "inline-block",
                                            ml: 1
                                        }}
                                    >
                                        {f}
                                    </Typography>
                                ))}
                            </List>
                        </Box>
                    </Collapse>
                )}

                <Tooltip title={isSelected ? context.t.translate("deselect_filter") : context.t.translate("apply_filter")}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleFilterSelection(filter)}
                        startIcon={isSelected ? <AiOutlineClose size={16} /> : <AiOutlineCheck size={16} />}
                        sx={{
                            borderRadius: "0 0 8px 8px",
                            textTransform: "none",
                            py: 0.5,
                            boxShadow: "none",
                            bgcolor: isSelected ? "error.light" : "primary.light",
                            "&:hover": {
                                bgcolor: isSelected ? "error.main" : "primary.main",
                                boxShadow: "none"
                            }
                        }}
                    >
                        {isSelected ? context.t.translate("deselect_filter") : context.t.translate("apply_filter")}
                    </Button>
                </Tooltip>
            </Paper>
        )
    }

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => onOpenChange(false)}
            PaperProps={{
                sx: {
                    width: { xs: "100%", sm: 380 },
                    borderRadius: { xs: 0, sm: "12px 0 0 12px" }
                }
            }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                p: 3
            }}>
                {/* Header */}
                <Box sx={{ mb: 3 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                        <Typography variant="h6" fontWeight="bold">{context.t.translate("saved_filters")}</Typography>
                        <IconButton
                            onClick={() => onOpenChange(false)}
                            size="small"
                            sx={{
                                bgcolor: "rgba(0,0,0,0.05)",
                                borderRadius: 1,
                                "&:hover": { bgcolor: "rgba(0,0,0,0.1)" }
                            }}
                        >
                            <AiOutlineClose size={16} />
                        </IconButton>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        {context.t.translate("filters_description")}
                    </Typography>
                </Box>

                {/* Content - Scrollable area */}
                <Box sx={{
                    flex: 1,
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3
                }}>
                    {/* Recent Filters Section */}
                    <Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 2
                        }}>
                            <AiOutlineClockCircle size={18} />
                            <Typography variant="subtitle2" fontWeight="medium">
                                {context.t.translate("recents")}
                            </Typography>
                        </Box>

                        {recentFilters.length === 0
                            ? (
                                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic", p: 2, textAlign: "center" }}>
                                    {context.t.translate("no_recent_filters")}
                                </Typography>
                            )
                            : (
                                <List disablePadding sx={{
                                    bgcolor: "background.paper",
                                    borderRadius: 2,
                                    boxShadow: "0px 2px 8px rgba(0,0,0,0.05)"
                                }}>
                                    {recentFilters.map((filter) => renderFilterItem(filter, "recent"))}
                                </List>
                            )}
                    </Box>

                    {/* Favorite Filters Section */}
                    <Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 2
                        }}>
                            <AiOutlineHeart size={18} />
                            <Typography variant="subtitle2" fontWeight="medium">
                                {context.t.translate("favorite")}
                            </Typography>
                        </Box>

                        {favoriteFilters.length === 0
                            ? (
                                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic", p: 2, textAlign: "center" }}>
                                    {context.t.translate("no_favorite_filters")}
                                </Typography>
                            )
                            : (
                                <List disablePadding sx={{
                                    bgcolor: "background.paper",
                                    borderRadius: 2,
                                    boxShadow: "0px 2px 8px rgba(0,0,0,0.05)"
                                }}>
                                    {favoriteFilters.map((filter) => renderFilterItem(filter, "favorite"))}
                                </List>
                            )}
                    </Box>
                </Box>

                {/* Footer */}
                <Box sx={{ mt: 3, pt: 2, borderTop: 1, borderColor: "divider" }}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            // Deselect filter when closing
                            if (selectedFilter && onFilterDeselected) {
                                onFilterDeselected()
                            }
                            setSelectedFilter(null)
                            onOpenChange(false)
                        }}
                        sx={{
                            borderRadius: 2,
                            py: 1.2,
                            textTransform: "none",
                            fontWeight: "medium"
                        }}
                    >
                        {context.t.translate("assess_close")}
                    </Button>
                </Box>
            </Box>
        </Drawer>
    )
}
