import HBOM from "@models/HBOM"
import React from "react"
import { TextField } from "@mui/material"

interface HBOMFormProps {
  visibleSections: (keyof HBOM)[];
}

const HBOMForm = ({ activeStep, formData, onChange }: { activeStep: number; formData: HBOM; onChange: (section: keyof HBOM, field: string, value: string | number) => void }) => {
    const sectionKeys: (keyof HBOM)[] = [
        "header_information",
        "finished_product_details",
        "entity_name",
        "entity_location",
        "component_part_info",
        "component_part_details",
        "product_details"
    ]

    const currentSection = sectionKeys[activeStep]

    return (
        <form>
            {Object.keys(formData[currentSection]).map((key) => (
                <TextField
                    key={key}
                    label={key}
                    value={(formData[currentSection] as any)[key]}
                    onChange={(e) => onChange(currentSection, key, e.target.value)}
                    fullWidth
                    margin="normal"
                />
            ))}
        </form>
    )
}

export default HBOMForm
