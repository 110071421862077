import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import { VulnerabilityTableDefinition } from "@components/vulnerability"
import ServicesContext from "@context/ServicesContext"
import { StatsVulnerabilities } from "@models/Stats"
import Vulnerability from "@models/Vulnerability"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

type VulnerabilitiesTableProps = {
    vulnStats: StatsVulnerabilities | undefined,
    id: string | undefined
}

const VulnerabilitiesTable = ({ vulnStats, id }: VulnerabilitiesTableProps) => {
    const navigate = useNavigate()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    }, [])
    return (<Grid item xs container flexDirection="column" rowGap="25px" aria-description="Vulnerability Section">
        <Grid item xs sx={{ top: "0px" }}>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("vulnerabilities")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    {vulnStats != null && <Grid item container spacing={2}>
                        <Grid item xs={12} sm={6} container alignItems="center" justifyContent="center" direction="column">
                            <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                {context.t.translate("mitigated_vulnerabilities")}
                            </Typography>
                            {vulnStats.MitigatedVulnerability > 0
                                ? <GenericDonutChart title={context.t.translate("criticity")} dataType="object" data={vulnStats.MitigatedVulnerabilities} number={vulnStats.MitigatedVulnerability} />
                                : <Typography fontSize="15px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>{context.t.translate("there_are_no")}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center" justifyContent="center" direction="column">
                            <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                {context.t.translate("dash_vulner_nomitigated")}
                            </Typography>
                            {vulnStats.UnmitigatedVulnerability > 0
                                ? <GenericDonutChart title={context.t.translate("criticity")} dataType="object" data={vulnStats.UnmitigatedVulnerabilities} number={vulnStats.UnmitigatedVulnerability} />
                                : context.t.translate("there_are_no_unmitigated")
                            }
                        </Grid>
                    </Grid>}
                    <Grid item xs container flexDirection="column" spacing="20px">
                        <Grid item container flexDirection="column" rowGap="35px">
                            <Grid item container>
                                <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                                    dataProvider={(filter) => {
                                        // Define your filter criteria here

                                        const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                                        return vulnerabilityService.getAll(updatedFilter)
                                    }}
                                    onEdit={(elem: Vulnerability) => navigate("../../vulnerability/" + elem.id)}
                                    onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}

export default VulnerabilitiesTable
