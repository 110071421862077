/* eslint-disable multiline-ternary */
import { I18nContext } from "I18nProvider"
import React, { useContext, useState } from "react"
import { Box, Grid } from "@mui/material"
import HierarchyColumnChart, { ColumnValues } from "@components/hierarchy/HierarchyColumnChart"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { useStatsData } from "./useStatsData"

interface HierarchyStatsProps {
    timeRange?: string;
}

const HierarchyStatsGraphs: React.FC<HierarchyStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const {
        inTriage,
        inTriageFinished,
        hierarchyStatsData,
        isLoading
        // isLoading maybe is needed for rendering the skeleton
    } = useStatsData("Hierarchy", timeRange)

    const [columnValues, setColumnValues] = useState<ColumnValues>({
        values: {
            critical: { detected: 0, in_triage: 0, triage_finished: 0 },
            high: { detected: 0, in_triage: 0, triage_finished: 0 },
            medium: { detected: 0, in_triage: 0, triage_finished: 0 },
            low: { detected: 0, in_triage: 0, triage_finished: 0 },
            none: { detected: 0, in_triage: 0, triage_finished: 0 }
        }
    })

    const getRandomColor = () => {
        const letters = "0123456789ABCDEF"
        let color = "#"
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }

    const transformToChartData = (data?: { counts: Record<string, number> }) => {
        return data ? Object.entries(data.counts)
            .filter(([key]) => key !== "total") // Filtrar "total" antes de mapear
            .map(([key, value]) => ({
                name: key,
                y: value,
                color: getRandomColor()
            })) : []
    }

    const transformToArray = (data?: { counts: Record<string, number> }) => {
        return data ? Object.entries(data.counts).map(([key, value]) => ({
            key,
            value,
            color: getRandomColor() // Asignar un color aleatorio a cada elemento
        })) : []
    }

    console.log(inTriageFinished)
    return (
        <Grid
            container
            flexDirection="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="nowrap"
        >
            <Grid item xs={6}>
                <HierarchyColumnChart values={(hierarchyStatsData || columnValues).values} />
            </Grid>

            <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ minWidth: "220px", flex: 1 }}>
                    <GenericDonutChart
                        title="In Triage"
                        data={transformToChartData(inTriage)} // Convertir a array antes de enviarlo
                        dataType="array" // Opcional, si el componente necesita saber que es un array
                        number={inTriage?.counts?.total || 0}
                        footer=""
                    />
                </Box>
            </Grid>

            <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ minWidth: "220px", flex: 1 }}>
                    <GenericDonutChart
                        title="Assessment Finished"
                        data={transformToChartData(inTriageFinished)}
                        dataType="array"
                        number={inTriageFinished?.counts.total || 0}
                        footer=""
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default HierarchyStatsGraphs
