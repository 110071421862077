import React, { useContext } from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import styles from "../Styles"

const i18n = {
    es: {
        header: {
            title: "Análisis de Composición de SW"
        },
        footer: {
            copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
            confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
            disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
            errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    },
    en: {
        header: {
            title: "SW Composition Analysis"
        },
        footer: {
            copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
            confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
            disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
            errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    }
} as const

interface SectionProps {
    externalData: any
    lang?: string;
    template?: Template
}

const Header: React.FC<SectionProps> = ({ externalData, lang, template }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    // --------------------------------------------------

    // Render variables
    let imagen: string = "/assets/img/report/encabezado.jpg"
    if (template?.logo != null) {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/jpg" })
        imagen = URL.createObjectURL(blob)
    }
    // --------------------------------------------------

    return (
        <View fixed>
            <Image
                style={{ width: 596, height: 250, position: "relative", backgroundColor: template?.color }}
                src={imagen}
            />
            <Text style={{ ...styles.textoEncima, color: "black" }}>{i18n[locale].header.title}</Text>
            <Text>&nbsp;</Text>
        </View>
    )
}

const Footer: React.FC<SectionProps> = ({ externalData, lang, template }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    // --------------------------------------------------

    // Render variables
    const color = template?.color || "#fffa37"
    // --------------------------------------------------

    return (
        <View fixed style={{ width: "100%", position: "absolute", backgroundColor: color || "#fffa37", flexDirection: "row", justifyContent: "space-between", columnGap: 20, bottom: 0, padding: 25 }}>
            <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 5.5 }}>
                    {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                    {i18n[locale].footer.disclosureWarning}
                    {i18n[locale].footer.errorNotice}
                </Text>
            </View>
            <View style={{ flex: 0.2, textAlign: "right", alignItems: "flex-end", justifyContent: "center" }}>
                <Text style={{ fontSize: 13 }} render={({ pageNumber }) => `${pageNumber}`} />
            </View>
        </View>
    )
}

export { Header, Footer }
