import React, { useContext, useEffect, useState } from "react"
import { ThemeProvider } from "@mui/material/styles"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useAuth } from "react-oidc-context"
import { PureAbility } from "@casl/ability"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { AbilityContext } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import AppLayout from "@layouts/AppLayout"
import { AssessmentService, BenchmarkService, ClientService, ComplianceService, ComponentService, FileService, GroupService, ProductService, StatsService, TagService, VulnerabilityService } from "@services/index"
import EmailService from "@services/EmailService"
import TemplatesService from "@services/TemplatesService"
import { Actions, buildAbilityFor, Subjects } from "@permissions/ability"
import KeycloakService from "@services/KeycloakService"
import { TrackProvider } from "@components/track/TrackContext"
import TrackService from "@services/TrackService"
import AchillesService from "@services/AchillesService"
import { createLightOrbikTheme, createOrbikTheme } from "./theme"
import { I18nContext } from "I18nProvider"
import "App.css"
import { ButtonStateProvider, useButtonState } from "@components/theme/ButtonStateContext"
import TicketingService from "@services/TicketingService"
import SectorStatsService from "@services/SectorStatsService"
import HierarchyService from "@services/HierarchyService"
import HistoricCveService from "@services/HistoricCveService"
import PolicyService from "@services/PolicyService"
import LicenseService from "@services/LicenseService"
import UserLogService from "@services/UserLogService"
import { ConfirmationDialogProvider } from "@components/dialogs/ConfirmationDialog"
import SystemNotificationService from "@services/SystemNotificationService"
import SLAService from "@services/SLAService"
import CPEService from "@services/CPEService"
import IssueService from "@services/IssueService"
import JiraConfigService from "@services/JiraConfigService"
import JiraService from "@services/JiraService"
import PentestService from "@services/PentestService"
import CustomTextService from "@services/CustomTextService"
import KEVService from "@services/KEVService"
import VaultService from "@services/VaultService"
import HardwareComponentService from "@services/HardwareComponentService"
import ProviderService from "@services/ProviderService"
import UserService from "@services/UserService"
import MitigateService from "@services/MitigateService"
import { HierarchyProvider } from "@context/useHierarchy"
import SASTService from "@services/SASTService"
import JoyrideTour from "./components/walkthrough/JoyrideTour";
import { AppProvider } from "./components/walkthrough/WalkthroughProvider";
import ReminderService from "@services/ReminderService"
import CycloneDXService from "@services/CycloneDXService"
import WalkthroughService from "@services/WalkthroughService"
import CAFVulnerabilityService from "@services/CAFVulnerabilities"
import NotificationConfigService from "@services/NotificationConfigService"
import { SnapshotService } from "@services/SnapshotService"
import FilterService from "@services/FilterService"

const router = createBrowserRouter([
    { path: "*", Component: AppLayout }
])

const JoyrideWrapper: React.FC = () => {
    const navigate = (path: string) => {
        window.history.pushState({}, "", path);
        window.dispatchEvent(new Event("popstate"));
    };

    return <JoyrideTour navigate={navigate} />;
}

const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { darkMode } = useButtonState()
    const theme = darkMode ? createOrbikTheme() : createLightOrbikTheme()

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}
const theme = createOrbikTheme()

const services = {
    clientService: new ClientService(),
    walkthroughService: new WalkthroughService(),
    groupService: new GroupService(),
    productService: new ProductService(),
    assessmentService: new AssessmentService(),
    vulnerabilityService: new VulnerabilityService(),
    fileService: new FileService(),
    statsService: new StatsService(),
    benchmarkService: new BenchmarkService(),
    tagService: new TagService(),
    emailService: new EmailService(),
    templateService: new TemplatesService(),
    keycloakService: new KeycloakService(),
    trackService: new TrackService(),
    componentService: new ComponentService(),
    complianceService: new ComplianceService(),
    achillesService: new AchillesService(),
    ticketingService: new TicketingService(),
    sectorStatsService: new SectorStatsService(),
    hierarchyService: new HierarchyService(),
    historicCveService: new HistoricCveService(),
    policyService: new PolicyService(),
    licenseService: new LicenseService(),
    userLogService: new UserLogService(),
    systemNotificationServices: new SystemNotificationService(),
    slaService: new SLAService(),
    cpeService: new CPEService(),
    issueService: new IssueService(),
    jiraConfigService: new JiraConfigService(),
    jiraService: new JiraService(),
    pentestService: new PentestService(),
    customTextService: new CustomTextService(),
    KEVService: new KEVService(),
    vaultService: new VaultService(),
    hardwareComponentService: new HardwareComponentService(),
    providerService: new ProviderService(),
    userService: new UserService(),
    mitigateService: new MitigateService(),
    SASTService: new SASTService(),
    reminderService: new ReminderService(),
    cycloneDXService: new CycloneDXService(),
    cAFVulnerabilityService: new CAFVulnerabilityService(),
    notificationConfigService: new NotificationConfigService(),
    snapshotService: new SnapshotService(),
    filterService: new FilterService()
}

const App: React.FC = () => {
    const context = useContext(I18nContext)

    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [darkMode, setDarkMode] = useState(true)
    const theme = darkMode ? createOrbikTheme() : createLightOrbikTheme()
    const auth = useAuth()
    useEffect(() => {
        return auth.events.addSilentRenewError(() => {
            auth.signinRedirect()
        })
    }, [auth.events])
    const [ability, setAbility] = useState(new PureAbility<[Actions, Subjects]>())
    useEffect(() => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect()
        }

        if (auth.isAuthenticated) {
            setAbility(buildAbilityFor(auth.user?.profile.group as string[]))
        }
    }, [auth])
    services.clientService.setAuth(auth)
    services.walkthroughService.setAuth(auth)
    services.groupService.setAuth(auth)
    services.productService.setAuth(auth)
    services.assessmentService.setAuth(auth)
    services.vulnerabilityService.setAuth(auth)
    services.fileService.setAuth(auth)
    services.statsService.setAuth(auth)
    services.benchmarkService.setAuth(auth)
    services.tagService.setAuth(auth)
    services.emailService.setAuth(auth)
    services.templateService.setAuth(auth)
    services.keycloakService.setAuth(auth)
    services.trackService.setAuth(auth)
    services.componentService.setAuth(auth)
    services.complianceService.setAuth(auth)
    services.achillesService.setAuth(auth)
    services.ticketingService.setAuth(auth)
    services.sectorStatsService.setAuth(auth)
    services.hierarchyService.setAuth(auth)
    services.historicCveService.setAuth(auth)
    services.policyService.setAuth(auth)
    services.licenseService.setAuth(auth)
    services.systemNotificationServices.setAuth(auth)
    services.slaService.setAuth(auth)
    services.cpeService.setAuth(auth)
    services.issueService.setAuth(auth)
    services.jiraConfigService.setAuth(auth)
    services.jiraService.setAuth(auth)
    services.userLogService.setAuth(auth)
    services.pentestService.setAuth(auth)
    services.customTextService.setAuth(auth)
    services.KEVService.setAuth(auth)
    services.vaultService.setAuth(auth)
    services.hardwareComponentService.setAuth(auth)
    services.providerService.setAuth(auth)
    services.userService.setAuth(auth)
    services.mitigateService.setAuth(auth)
    services.SASTService.setAuth(auth)
    services.reminderService.setAuth(auth)
    services.cycloneDXService.setAuth(auth)
    services.cAFVulnerabilityService.setAuth(auth)
    services.notificationConfigService.setAuth(auth)
    services.snapshotService.setAuth(auth)
    services.filterService.setAuth(auth)

    return (<>
        {auth.isAuthenticated && (
            <HierarchyProvider>
                        <ServicesContext.Provider value={services}>
                            <AppProvider>
                                <AbilityContext.Provider value={ability}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <TrackProvider interval={600000}>
                                            <ThemeProvider theme={theme}>
                                                <ButtonStateProvider>
                                                    <ConfirmationDialogProvider>
                                                                <ThemeWrapper>
                                                                    <JoyrideWrapper/>
                                                                    <RouterProvider router={router} />
                                                                </ThemeWrapper>
                                                    </ConfirmationDialogProvider>
                                                </ButtonStateProvider>
                                            </ThemeProvider>
                                        </TrackProvider>
                                    </LocalizationProvider>
                                </AbilityContext.Provider>
                            </AppProvider>
                        </ServicesContext.Provider>
            </HierarchyProvider>
        )}
    </>)
}
export default App
