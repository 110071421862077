/* eslint-disable multiline-ternary */
import ServicesContext from "@context/ServicesContext"
import Component from "@models/Component"
import { ProductType } from "@models/Product"
import { Chip, CircularProgress, Grid, Skeleton, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const initValue: Component = {
    id: "",
    bom_ref: "",
    type: ProductType.Library,
    name: "",
    version: "",
    supressed: false,
    outdated: false,
    cpe: "",
    license: "",
    assessment_id: "",
    client_id: ""
}

const ComponentPackageDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const { package: packageParam } = useParams()
    const decodedPackage = decodeURIComponent(packageParam ?? "")
    const componentService = useContext(ServicesContext).componentService
    const [packageData, setPackageData] = useState<any | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const theme = useTheme()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedPackage = await componentService.getPackages(decodedPackage)
                setPackageData(fetchedPackage)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    const handleClick = () => {
        if (packageData?.homepage) {
            window.open(packageData.homepage, "_blank")
        }
    }

    // Mostrar indicador de carga mientras packageData no esté listo
    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <CircularProgress color="primary" />
            </Grid>
        )
    }

    // Mostrar mensaje de error si no hay datos
    if (!packageData) {
        return (
            <Typography color="error" align="center">
                {error?.message || "No se pudo cargar la información del paquete."}
            </Typography>
        )
    }
    const styles = {
        fontFamily: "Griff",
        "& .MuiInputBase-root": {
            color: theme.palette.primary.main, // Color del texto
            backgroundColor: theme.palette.background.paper // Color de fondo
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.primary.main // Color de la línea subrayada antes de enfocar
        },
        "& .MuiInput-underline:hover:before": {
            borderBottomColor: theme.palette.secondary.main // Color de la línea al pasar el cursor
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.dark // Color de la línea al enfocar
        }
    }

    return (
        <Grid item container flexDirection="column" rowGap="25px">
            <Grid item xs container flexDirection="column" rowGap="25px">
                <Grid item xs>
                    <Typography color="primary" fontSize="45px" fontWeight="bolder">
                        {packageData.name || <Skeleton width={200} />}
                    </Typography>
                </Grid>
                <Grid item container flexDirection="row" alignItems="center" spacing="40px">
                    <Grid item xs={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Ecosystem
                        </Typography>
                        <TextField
                            disabled
                            name="ecosystem"
                            value={packageData.ecosystem || ""}
                            variant="standard"
                            sx={styles}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            {context.t.translate("license")}
                        </Typography>
                        <TextField
                            disabled
                            name="license"
                            value={packageData.normalized_licenses?.join(", ") || ""}
                            variant="standard"
                            sx={styles}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
        Homepage
                        </Typography>
                        <Typography
                            component="a"
                            href={packageData.homepage || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                fontFamily: "Griff",
                                color: theme.palette.primary.main,
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }}
                        >
                            {packageData.homepage || "N/A"}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            {context.t.translate("description")}
                        </Typography>
                        <TextField
                            disabled
                            name="description"
                            value={packageData.description || ""}
                            variant="standard"
                            sx={styles}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            {context.t.translate("created_at")}
                        </Typography>
                        <TextField
                            disabled
                            name="created_at"
                            value={packageData.created_at ? new Date(packageData.created_at).toLocaleDateString() : ""}
                            variant="standard"
                            sx={styles}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                            Critical
                        </Typography>
                        <Chip
                            label={packageData.critical ? "Yes" : "No"}
                            color={packageData.critical ? "success" : "error"}
                            sx={{ fontFamily: "Griff", fontWeight: "bolder" }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ComponentPackageDetail
