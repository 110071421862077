import React, { useContext } from "react"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import { Footer, Header } from "./HeaderFooter"
import styles, { globalStyles } from "../Styles"

interface introProps {
    title: string;
    description_1: string;
    aspects_list: string[];
    first_aspect_desc: string;
    first_aspect_table: {
        header: string[];
        dummie_data: Array<{ id: string; content: string }>;
        legend: string;
    }
    description_2: (param: string) => string;
};

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        title: "Alcance",
        description_1: "La realización de un Test de Penetración implica con carácter general la definición de una serie de aspectos:",
        aspects_list: [
            "Identificación de los activos.",
            "Profundidad del ataque e impacto.",
            "En relación con la profundidad del ataque, el alcance se ha limitado al dispositivo o software objetivo.",
            "En relación con el impacto, el alcance ha consistido en realizar todas las pruebas disponibles a excepción de todas aquellas que podrían generar una denegación de servicio. Dichas vulnerabilidades no han sido testadas y solo se identifican en base a las versiones del software."
        ],
        first_aspect_desc: "Los activos iniciales dentro del alcance se recogen en la siguiente tabla:",
        first_aspect_table: {
            header: ["Tipo de activo", "URL/IP"],
            dummie_data: [
                { id: "EXAMPLE", content: "127.0.0.1" }
            ],
            legend: "Tabla 1: Activos iniciales"
        },
        description_2: (empresa: string) => `El activo pertenece a la empresa ${empresa}`
    },
    en: {
        title: "Scope",
        description_1: "The realization of a Penetration Test generally implies the definition of a series of aspects:",
        aspects_list: [
            "•\tIdentification of assets.",
            "•\tAttack depth and impact."
        ],
        first_aspect_desc: "The initial assets within the scope are collected in the following table:",
        first_aspect_table: {
            header: ["Asset type", "URL/IP"],
            dummie_data: [
                { id: "EXAMPLE", content: "127.0.0.1" }
            ],
            legend: "Table 1: Initial assets"
        },
        description_2: (company: string) => `The asset belongs to the company ${company}`
    }
}

interface SectionProps {
    externalData: any
    lang?: string;
    assessment?: Assessment
    template?: Template
}

const Scope: React.FC<SectionProps> = ({ externalData, lang, assessment, template }) => {
    const context = useContext(I18nContext)
    console.log(externalData)

    // Lógica del idioma (sin cambios)
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }

    // Extraer datos de la propiedad "Alcance"
    const alcanceData = externalData?.Alcance || []

    return (
        <Page size="A4" style={globalStyles.page} >
            <Header externalData={null} template={template}></Header>
            <View style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "0px 20px"
            }} >
                <View style={globalStyles.column} >
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.title}>{i18n[locale].title}</Text>
                        <Text style={globalStyles.texto}>{i18n[locale].description_1}</Text>
                        {i18n[locale].aspects_list.map((aspect, index) => (
                            <React.Fragment key={index}>
                                <View style={{ flexDirection: "row", marginLeft: 10, paddingTop: 5 }}>
                                    <Text style={{ ...globalStyles.texto, marginRight: 5 }}>•</Text>
                                    <Text style={{ ...globalStyles.texto }}>{aspect}</Text>
                                </View>
                                {index === 0 && (
                                    <>
                                        <View style={{ flexDirection: "row", marginLeft: 20, paddingTop: 5 }}>
                                            <Text style={{ ...globalStyles.texto }}>{i18n[locale].first_aspect_desc}</Text>
                                        </View>
                                        <View style={{ ...globalStyles.table, paddingTop: "10px" }}>
                                            {/* Cabecera de la tabla */}
                                            <View style={globalStyles.row}>
                                                {i18n[locale].first_aspect_table.header.map((header, headerIndex) => (
                                                    <View key={headerIndex} style={{ ...globalStyles.col, backgroundColor: "yellow" }}>
                                                        <Text style={globalStyles.textoBold}>{header}</Text>
                                                    </View>
                                                ))}
                                            </View>
                                            {/* Datos dinámicos de la tabla */}
                                            {alcanceData.map((row: any, rowIndex: any) => (
                                                <View key={rowIndex} style={globalStyles.row}>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{row.activo}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{row.url}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={globalStyles.table_legend}>
                                            <Text style={globalStyles.texto}>{i18n[locale].first_aspect_table.legend}</Text>
                                        </View>
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                        <Text style={{ ...globalStyles.texto, paddingTop: "10px", paddingBottom: "5px" }}>
                            {i18n[locale].description_2("EXAMPLE")}{" "}
                            {externalData?.customScope}
                        </Text>
                    </View>
                </View>
                <View style={globalStyles.column} fixed>
                    <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -20, height: 550 }} src="/assets/img/report/octogono.jpg" />
                </View>
            </View>
            <Footer externalData={null} template={template}></Footer>
        </Page>
    )
}

export { Scope }
