import React, { useContext } from "react"
import { Box, Grid } from "@mui/material"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { I18nContext } from "I18nProvider"
import { useStatsData } from "./useStatsData"
import TopItemsList from "./components/TopItemList"

interface TagStatsProps {
  timeRange?: string;
}

const TagStats: React.FC<TagStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const {
        byCategory,
        tagProductNumber,
        unassignedTagProduct,
        categoryNumber,
        topItems,
        isLoading
    } = useStatsData("Tag", timeRange)

    if (isLoading || !context) return null

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Box sx={{ minWidth: "220px" }}>
                        <GenericDonutChart
                            title={context.t.translate("by_category")}
                            data={byCategory || []}
                            dataType="array"
                            number={categoryNumber || 0}
                            footer="CATEGORIES"
                        />
                    </Box>
                </Grid>
                {/* Add more charts if needed */}
            </Grid>
            {topItems && topItems.length > 0 && (
                <TopItemsList
                    title={context.t.translate("top5_tagged")}
                    items={topItems}
                    valueKey="total"
                    nameKey="name"
                    categoryKey="category"
                />
            )}
        </>
    )
}

export default TagStats
