import { ModelDefinition } from "@models/utils"
import { Provider } from "@models/Provider"
import ProviderNameNavigate from "./ProviderNameNavigate"

const ProviderTableDefinition: ModelDefinition<Provider> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, isId: true },
        // { name: "bom_ref", label: "Bom Ref", type: "string", flex: 0.25 },
        { name: "name", label: "Name", type: "string", flex: 0.25, render: ProviderNameNavigate },
        { name: "description", label: "Descripcion", type: "string", flex: 0.25 },
        { name: "product_count", label: "Products", type: "number", flex: 0.10 }

    ]
}

export default ProviderTableDefinition
