import React from "react"
import styled from "@emotion/styled"
import Highcharts from "highcharts"

interface SunburstChartBreadCrumbProps {
    totalDevices: number;
    selectedPath: Highcharts.Point[];
}

const BreadCrumb = styled.div`
    margin: 0 auto;
    width: 380px;
    padding: 20px; 
    color: #000000;
    position: sticky;
    font-weight: 700;

    /* Media query para pantallas más pequeñas */
    @media (max-width: 600px) {
        width: 250px;  /* Ajusta el tamaño del ancho para dispositivos móviles */
        padding: 15px;  /* Reduce el padding */
    }
`

interface StepProps {
    background: string;
}

const Step = styled.div<StepProps>`
   position: relative;
    text-align: center;
    padding: 12px;
    margin-bottom: 6px;
    line-height: 55px;

    /* Media query para pantallas más pequeñas */
    @media (max-width: 600px) {
        padding: 8px;  /* Ajusta el padding para dispositivos móviles */
        line-height: 40px;  /* Reduce la altura de línea para que el texto no quede demasiado grande */
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 51%;
        background: ${props => props.background};
        transform: skew(0deg, 6deg);
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background: ${props => props.background};
        transform: skew(0deg, -6deg);
        z-index: -1;
    }
`

const SunburstChartBreadCrumb: React.FC<SunburstChartBreadCrumbProps> = (props: SunburstChartBreadCrumbProps) => {
    return (
        <BreadCrumb>
            {props.selectedPath.map((point: any, i) => (
                <Step background={point.color} key={i}>
                    <span style={{ left: "20px", position: "absolute", top: "-5px" }}>{((point.value / props.totalDevices) * 100).toFixed(2)}%</span>
                    <span style={{ marginTop: "20px" }}>{point.name}</span>
                    <span style={{ right: "20px", position: "absolute", top: "-5px" }}>{point.value}</span>
                </Step>
            ))}
        </BreadCrumb>
    )
}

export default SunburstChartBreadCrumb
