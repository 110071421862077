import ServicesContext from "@context/ServicesContext"
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material"
import { FilterOption } from "@utils/queryParams"
import React, { useContext, useEffect, useState } from "react"
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid"
import { AbilityContext } from "@components/permissions"
import { useNavigate } from "react-router-dom"
import { useHierarchy } from "@context/useHierarchy"
import CAFVulnerability from "@models/CAFVulnerability"
import { CAFVulnerabilityTableDefinitionReduced } from "@components/vulnerability"
import { convertModelDefToGridColDef, GenericTable } from "../tables"
import GenericModal from "../modals/GenericModal"

interface Props {
    filters: FilterOption[];
    detected: number,
    inTriage: number,
    triageFinished: number,
    hierarchy?: string
}

interface Indicator {
    backgroundColor: string,
    color: string;
    count: number;
    type: string
}

const CAFVulnerabilityIndicator: React.FC<Props> = ({ filters = [], detected, inTriage, triageFinished, hierarchy }) => {
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    const navigate = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [vulnerabilitiesOpen, setVulnerabilitiesOpen] = useState(false)
    const handleVulnerabilitiesClose = () => setVulnerabilitiesOpen(false)
    const { hierarchyId, setHierarchyId } = useHierarchy()

    if (detected > 0) { indicators.push({ backgroundColor: "black", color: "#E63946", count: detected, type: "Detected" }) }
    if (inTriage > 0) { indicators.push({ backgroundColor: "black", color: "#F8961E", count: inTriage, type: "In triage" }) }
    if (triageFinished > 0) { indicators.push({ backgroundColor: "black", color: "#2ECC71", count: triageFinished, type: "Triage finished" }) }

    if (indicators.length === 0) {
        indicators.push({ backgroundColor: "black", color: "gray", count: 0, type: "N/D" })
    }

    useEffect(() => {
        setCols(convertModelDefToGridColDef(CAFVulnerabilityTableDefinitionReduced, ability))
    }, [])

    const totalCount = indicators.reduce((sum, indicator) => sum + indicator.count, 0)

    if (indicators.length > 0) {
        return (
            <Grid
                component={Paper}
                elevation={2}
                container
                sx={{
                    width: "200px",
                    height: "16px",
                    display: "flex",
                    flexWrap: "nowrap",
                    borderRadius: "12px",
                    overflow: "hidden"
                }}
            >
                {indicators.map((indicator, index) => {
                    let widthPercentage = totalCount === 0 ? 100 : (indicator.count / totalCount) * 100
                    widthPercentage = Math.max(widthPercentage, 5)
                    console.log(indicator.type, widthPercentage)

                    return (
                        <Tooltip title={indicator.type} key={index}>
                            <Grid
                                item
                                sx={{
                                    flexGrow: indicator.count,
                                    width: `${widthPercentage}%`,
                                    minWidth: "5%",
                                    background: indicator.color,
                                    color: "black",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    borderRadius: index === 0 ? "10px 0 0 10px" : index === indicators.length - 1 ? "0 10px 10px 0" : "0"
                                }}
                                onClick={() => {
                                    if (hierarchy) { setHierarchyId(hierarchy) }
                                    setVulnerabilitiesOpen(indicator.count > 0)
                                }}
                            >
                                {indicator.count}
                            </Grid>
                        </Tooltip>
                    )
                })}
                <GenericModal open={vulnerabilitiesOpen} onClose={handleVulnerabilitiesClose} title="Vulnerabilities" minWidth={900}>
                    <Box width="100%" gap={2}>
                        <GenericTable<CAFVulnerability> entity="CAFVulnerability" columns={cols}
                            dataProvider={(filter) => {
                                return vulnerabilityService.getAllCafVulns(filter)
                            }}
                            onEdit={(elem: CAFVulnerability) => navigate("../vulnerability/" + elem.id)}
                            onDelete={(elem: CAFVulnerability) => vulnerabilityService.delete(elem.id)}
                            dataGridProps={{
                                getRowClassName: (params: GridRowClassNameParams) =>
                                    params.row.supressed ? "suppressedRow" : "",
                                sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } }
                            }}
                            // TODO: Check why this renders twice
                            externalParameters={{ filters }}
                        />
                    </Box>
                </GenericModal>
            </Grid>
        )
    }
    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.text.secondary}>
            No vulnerabilities
        </Typography>
    )
}

export { CAFVulnerabilityIndicator }
