import { Box, Button, Checkbox, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import { LicenseTypeKeys } from "@components/license/LicenseTableDefinition"
import { LicenseTypeColors } from "@components/common/colors/LicenseTypeColors"
import License, { LicenseType } from "@models/License"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useTrack } from "@components/track/TrackContext"

const initValue: License = {
    id: "",
    name: "",
    type: LicenseType.Golden,
    max_assessment: "0",
    max_prod: "0",
    max_monitor: 0,
    toolbox: false,
    client: { id: "" }
}
const LicenseForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const licenseService = useContext(ServicesContext).licenseService
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    // API Call form submission
    const [formData, setFormData] = useState<License>(initValue)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "LicenseForm" })
    }, [])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target

        // Handle special cases
        if (name === "max_monitor") {
            // Always ensure max_monitor is a number
            setFormData(prevData => ({
                ...prevData,
                max_monitor: Number(value)
            }))
            return
        }

        // Check if the value is a string representation of a number for numeric string fields
        if ((name === "max_prod" || name === "max_assessment") && !/^\d*$/.test(value)) {
            // Skip update if not a valid numeric string
            return
        }

        // Handle other fields normally
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.max_assessment === "0",
            formData.max_prod === "0",
            formData.client.id === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }

    useEffect(() => {
        console.log("Form", formData)
    }, [formData])

    // Api call form validation
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            // Ensure max_monitor is definitely a number before submitting
            const dataToSubmit = {
                ...formData,
                max_monitor: Number(formData.max_monitor)
            }
            await licenseService.create(dataToSubmit)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <Grid item xs container flexDirection="column">
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("License_new")}
                    </Typography>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />

                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item xs={6}>
                        <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("license_title")} name="name"
                            value={formData.name} onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("type")} name="type"
                            value={formData.type} onChange={handleInputChange}>
                            {LicenseTypeKeys.map((key, index) =>
                                (<MenuItem key={index} value={LicenseType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: LicenseTypeColors[LicenseType[key]] }}>{key}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("max_products")}
                            name="max_prod"
                            value={formData.max_prod}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("max_assessments")}
                            name="max_assessment"
                            value={formData.max_assessment}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="number"
                            variant="filled"
                            label={context.t.translate("max_monitoring")}
                            name="max_monitor"
                            value={formData.max_monitor}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} container alignItems="center">
                        <Checkbox
                            name="Toolbox"
                            checked={formData.toolbox}
                            onChange={(e) => setFormData({ ...formData, toolbox: e.target.checked })}
                            sx={{ color: theme.palette.primary.main }}
                        />
                        <Typography
                            color={theme.palette.primary.main}
                            fontSize="18px"
                            fontFamily="Griff"
                            fontWeight={formData.toolbox ? "bolder" : "normal"}
                            sx={{ ml: 1 }}
                        >
                            {context.t.translate(formData.toolbox ? "include_toolbox" : "without_toolbox")}
                        </Typography>
                    </Grid>
                </Grid>

                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.primary.contrastText }} disabled={!formValid()}>
                    {context.t.translate("create_license")}
                </Button>
            </Box>
        </Box>
    )
}

export { LicenseForm }
export default LicenseForm
