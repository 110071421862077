import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ActionButton from "@components/common/Button/ActionButton"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { AbilityContext } from "@components/permissions"
import Hierarchy from "@models/Hierarchy"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import HierarchyItem from "./HierarchyItem"

interface HierarchyGridProps {
    initialHierarchies: Hierarchy[][];
    clientId?: string;
    onChange?: (updatedHierarchies: Hierarchy[][]) => void;
}

const HierarchyGrid: React.FC<HierarchyGridProps> = ({ initialHierarchies, onChange, clientId = "00000000-0000-0000-0000-000000000000" }) => {
    const [hierarchies, setHierarchies] = useState<Hierarchy[][]>(initialHierarchies)
    const [selectedHierarchy, setSelectedHierarchy] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }))
    const [newHierarchy, setNewHierarchy] = useState(false)

    const ability = useContext(AbilityContext)

    useEffect(() => {
        setHierarchies(initialHierarchies)
    }, [initialHierarchies])

    const handleNewHierarchy = () => {
        setNewHierarchy(true)
    }

    const handleCancelHierarchy = () => {
        setSelectedHierarchy(Array.from({ length: MAX_DEEP_LEVEL }))
        setNewHierarchy(false)
    }

    const handleAddHierarchy = () => {
        if (selectedHierarchy.length > 0 && selectedHierarchy[0]) { // TODO and not exists in selectedHierarchies
            const filteredHierarchy = selectedHierarchy.filter((h): h is Hierarchy => h !== null)
            const newHierarchies = [...hierarchies, filteredHierarchy]
            setHierarchies(newHierarchies)
            onChange?.(newHierarchies)
            setNewHierarchy(false)
            setSelectedHierarchy(Array.from({ length: MAX_DEEP_LEVEL }))
        }
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchy = [...selectedHierarchy]
        newSelectedHierarchy[index] = hierarchy
        newSelectedHierarchy.fill(null, index + 1, MAX_DEEP_LEVEL)
        setSelectedHierarchy(newSelectedHierarchy)
    }

    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            if (ability.can("*", "Client")) {
                if (clientId !== "00000000-0000-0000-0000-000000000000") {
                    return [
                        { field: "client_id", operation: FilterOperation.UUIDEqual, value: clientId },
                        { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    ]
                }
                return undefined
            }
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchy[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    const handleDeleteHierarchy = (index: number) => {
        const updatedHierarchies = hierarchies.filter((_, i) => i !== index)
        setHierarchies(updatedHierarchies)

        const hierarchyToDelete = hierarchies[index]
        setHierarchies(prev => prev.filter(id => id !== hierarchyToDelete))

        onChange?.(updatedHierarchies) // Notificar al padre
    }

    return (
        <Grid container spacing={2}>
            {hierarchies.length > 0 && hierarchies.map((hierarchyPath, index) => (
                <Grid item key={index} alignItems="center" spacing={2} display="flex" gap={1} xs={8}>
                    <Grid item xs={10}>
                        <HierarchyItem hierarchies={hierarchyPath} />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton color="error" onClick={() => handleDeleteHierarchy(index)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            {!newHierarchy && (
                <Grid item xs={12}>
                    <ActionButton text="New Hierarchy" onClick={handleNewHierarchy} />
                </Grid>
            )}
            {newHierarchy && (
                <>
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={3}>
                            <HierarchySelector
                                value={selectedHierarchy[0]?.id || ""}
                                customFilters={getCustomFilters(0)}
                                onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                                index={0}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {selectedHierarchy[0] && (
                                <Box >
                                    <HierarchySelector
                                        value={selectedHierarchy[1]?.id || ""}
                                        customFilters={getCustomFilters(1)}
                                        onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                                        index={1}
                                    />
                                </Box>

                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {selectedHierarchy[1] && (
                                <Box >
                                    <HierarchySelector
                                        value={selectedHierarchy[2]?.id || ""}
                                        customFilters={getCustomFilters(2)}
                                        onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                                        index={2}
                                    />
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {selectedHierarchy[2] && (
                                <Box >
                                    <HierarchySelector
                                        value={selectedHierarchy[3]?.id || ""}
                                        customFilters={getCustomFilters(3)}
                                        onSelect={(id, hierarchy) => handleSelectorChange(3, id, hierarchy)}
                                        index={3}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item>
                            <ActionButton text="Add" onClick={handleAddHierarchy} />
                        </Grid>
                        <Grid item>
                            <ActionButton text="Cancel" onClick={handleCancelHierarchy} />
                        </Grid>
                    </Grid>

                </>
            )}
        </Grid>
    )
}

export default HierarchyGrid
