import React, { useCallback, useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import { GridColDef, GridFilterItem, GridState } from "@mui/x-data-grid"
import { Typography, useTheme } from "@mui/material"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext } from "@components/permissions/index"
import { CAFVulnerabilityTableDefinition, CAFVulnerabilityTableDefinitionES } from "@components/vulnerability/index"
import { ServicesContext } from "@context/index"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import StatsCard from "@components/common/stats/StatsCard"
import CAFVulnerability from "@models/CAFVulnerability"
import { ClientSelector } from "@components/client"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import Hierarchy from "@models/Hierarchy"

const CAFVulnerabilityList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService

    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const { track, trackData } = useTrack()

    const ability = useContext(AbilityContext)
    useEffect(() => {
        track({ view: "VulnerabilityList" })
        const CafVulnerabilityTableDefinition = context.language === "es" ? CAFVulnerabilityTableDefinitionES : CAFVulnerabilityTableDefinition
        setCols(convertModelDefToGridColDef(CafVulnerabilityTableDefinition, ability))
    }, [])

    const [params, setParams] = useState<QueryParameters>({})
    const [clientId, setClientId] = useState<string>("")
    const [hierarchyId, setHierarchyId] = useState<string>("")
    const [providerId, setProviderId] = useState<string>("")

    const handleStateChange = useCallback(
        (state: GridState) => {
            if (state.filter.filterModel.items.every((filterItem: GridFilterItem) => filterItem.field !== undefined && filterItem.operator !== undefined && filterItem.value !== undefined)) {
                const newFilters: FilterOption[] = state.filter.filterModel.items.map((filterItem: GridFilterItem) => ({
                    field: filterItem.field.toString(),
                    operation: filterItem.operator as FilterOperation,
                    value: filterItem.value.toString()
                }))
                setParams((prevParams) => {
                    const filtersChanged = JSON.stringify(prevParams.filters) !== JSON.stringify(newFilters)
                    if (filtersChanged) {
                        return {
                            ...prevParams,
                            filters: newFilters
                        }
                    }
                    return prevParams
                })
            }
        },
        []
    )

    // Tracking
    useEffect(() => {
        track({ view: "VulnerabilityList" })
    }, [])

    useEffect(() => {
        setParams((prevParams) => {
            const newFilters = [
                ...(clientId ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: clientId }] : []),
                ...(hierarchyId ? [{ field: "hierarchy_id", operation: FilterOperation.UUIDEqual, value: hierarchyId }] : [])
            ]

            return { ...prevParams, filters: newFilters }
        })
    }, [clientId, hierarchyId])

    const dataProvider = useCallback(
        (filter: any) => vulnerabilityService.getAllCafVulns({ ...filter, ...params }),
        [params.filters, vulnerabilityService, clientId, hierarchyId]
    )

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                            {context.t.translate("vulnerabilities")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color={theme.palette.text.secondary} fontSize="15px" fontWeight="bolder" fontFamily="Griff">
                            {context.t.translate("sections.vulnerabilitiesList")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                </Grid>
            </Grid>
            <StatsCard title={context.t.translate("vulnerabilities")} entity="CAFVulnerability" />

            {/* Fila con los selectores debajo de las estadísticas */}
            <Grid container sx={{ alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }} spacing={2}>
                {ability.can("*", "*") &&
                <Grid item xs={2}>
                    <ClientSelector value={clientId} onSelect={(client_id: string | undefined) => {
                        if (client_id) setClientId(client_id)
                    }} />
                </Grid>}

                <Grid item xs={2}>
                    <HierarchySelector index={0} value={hierarchyId} customFilters={ability.can("*", "*") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: clientId }] : undefined} onSelect={(id: string | undefined, h: Hierarchy | null) => {
                        if (id) setHierarchyId(id)
                    }} />
                </Grid>
            </Grid>

            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<CAFVulnerability>
                    key={JSON.stringify(params)} // Clave para forzar re-renderizado cuando los filtros cambian
                    entity="CAFVulnerability"
                    columns={cols}
                    dataProvider={dataProvider}
                    disabledActions={true}
                    onEdit={(elem: CAFVulnerability) => navigate("./" + elem.id, { state: { row: elem } })}
                    onDelete={(elem: CAFVulnerability) => vulnerabilityService.delete(elem.id)}
                />

            </Grid>
        </Grid>
    )
}

export { CAFVulnerabilityList }
export default CAFVulnerabilityList
