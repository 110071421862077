import React from "react"
import { Grid, Skeleton } from "@mui/material"

const ColumnChartSkeleton: React.FC = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid
                item
                xs={12}
                container
                alignItems="center"
            >
                <Grid item xs={6}>
                    <Skeleton
                        variant="text"
                        width="50%"
                        height={40}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8} md={9}>
                    <Skeleton variant="rectangular" width="80%" height={500} sx={{ margin: "auto" }} />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container flexDirection="column" spacing={2}>
                                {[...Array(5)].map((_, index) => (
                                    <Grid key={index} item container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Skeleton variant="circular" width={15} height={15} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width={60} height={20} />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container flexDirection="column" spacing={2}>
                                {[...Array(5)].map((_, index) => (
                                    <Grid key={index} item container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Skeleton variant="circular" width={15} height={15} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton variant="text" width={60} height={20} />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ColumnChartSkeleton
