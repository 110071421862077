import { Breadcrumbs, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Hierarchy from "@models/Hierarchy"

const HierarchyItem = ({ hierarchies }: { hierarchies: Hierarchy[] }) => {
    return (
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {hierarchies.map((hierarchy, index) => {
                return (
                    <Typography key={index}>{hierarchy.name}</Typography>
                )
            })}
        </Breadcrumbs>
    )
}

export default HierarchyItem
