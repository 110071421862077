import { CVSS3Severity } from "@models/Vulnerability"

export const CVSS3SeverityColors: Record<CVSS3Severity, string> = {
    critical: "#D00000", // baby blue
    high: "#E63946", // mahogany orange
    medium: "#F8961E", // scarlet red
    low: "#FFD166", // malachite green
    none: "#A0A0A0", // peach brown
    unknown: "#477db8" // lilac grey
}
