import React, { useContext } from "react"
import { Box, Grid } from "@mui/material"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { I18nContext } from "I18nProvider"
import { useStatsData } from "./useStatsData"
import TopItemsList from "./components/TopItemList"

interface ComponentStatsProps {
    timeRange?: string;
}

const ComponentStats: React.FC<ComponentStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const { circleCharts, topItems, isLoading } = useStatsData("Component", timeRange)

    if (isLoading || !circleCharts || !context) return null

    return (
        <Box sx={{ width: "100%" }}>
            <Grid
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flexWrap: "nowrap", // Evita que los elementos se muevan a otra línea
                    justifyContent: "space-between", // Distribuye los elementos uniformemente en la fila
                    width: "100%" // Asegura que ocupe el 100% del ancho
                }}
            >
                {/* Donut Chart 1 */}
                <Grid item xs={12} sm={3} sx={{ minWidth: "220px", flex: 1 }}>
                    <Box>
                        <GenericDonutChart
                            title={circleCharts.suppressed.title}
                            dataType="array"
                            data={circleCharts.suppressed.data}
                            number={circleCharts.suppressed.number}
                            footer={circleCharts.suppressed.footer}
                        />
                    </Box>
                </Grid>

                {/* Donut Chart 2 */}
                <Grid item xs={12} sm={3} sx={{ minWidth: "220px", flex: 1 }}>
                    <Box>
                        <GenericDonutChart
                            title={circleCharts.outdated.title}
                            dataType="array"
                            data={circleCharts.outdated.data}
                            number={circleCharts.outdated.number}
                            footer={circleCharts.outdated.footer}
                        />
                    </Box>
                </Grid>

                {/* Donut Chart 3 */}
                <Grid item xs={12} sm={3} sx={{ minWidth: "220px", flex: 1 }}>
                    <Box>
                        <GenericDonutChart
                            title={circleCharts.inherited.title}
                            dataType="array"
                            data={circleCharts.inherited.data}
                            number={circleCharts.inherited.number}
                            footer={circleCharts.inherited.footer}
                        />
                    </Box>
                </Grid>

                {/* Top Items List */}
                {topItems && topItems.length > 0 && (
                    <Grid item xs={12} md={3} sx={{ minWidth: "300px", flex: 1 }}>
                        <TopItemsList
                            title={context.t.translate("top5_components")}
                            items={topItems}
                            valueKey="total"
                            nameKey="name"
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default ComponentStats
