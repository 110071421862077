
import { Reminder } from "@models/Reminder"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class ReminderService extends Service<Reminder> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/reminder", MediaTypes.JSON)
    }
}

export { ReminderService }
export default ReminderService
