import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { I18nContext } from "I18nProvider"
import { Footer, Header } from "./HeaderFooter"
import styles from "./styles"

const i18n = {
    es: {
        introduction: "1. Introducción",
        explanation: "La Lista de Materiales de Software (SBOM, por sus siglas en inglés) es un componente crítico en ciberseguridad, ya que proporciona un listado detallado de todos los componentes, bibliotecas y dependencias dentro de una aplicación de software. La calidad del SBOM es esencial para garantizar transparencia, seguridad y cumplimiento a lo largo del ciclo de vida del desarrollo de software. Un SBOM de alta calidad permite a las organizaciones identificar y abordar vulnerabilidades de manera rápida, mejorar la evaluación de riesgos y fortalecer su postura general de ciberseguridad.",
        resume: "Resumen de Puntuación"
    },
    en: {
        introduction: "1. Introduction",
        explanation: "The Software Bill of Materials (SBOM) is a critical component in cybersecurity, providing a detailed list of all components, libraries, and dependencies within a software application. SBOM quality is essential for ensuring transparency, security, and compliance throughout the software development lifecycle. A high-quality SBOM allows organizations to quickly identify and address vulnerabilities, improve risk assessment, and enhance overall cybersecurity posture.",
        resume: "Score Summary"
    }
} as const

interface ScoreSummaryProps {
    avgScore: number;
    structuralScore: number;
    ntiaScore: number;
    semanticScore: number;
    qualityScore: number;
    sharingScore: number;
    lang?: string; // Ensure `lang` is included
}

// Function to determine color based on score
const getColorForScore = (score: number) => {
    const red = Math.max(0, 255 - Math.round((score / 10) * 255))
    const green = Math.min(255, Math.round((score / 10) * 255))
    return `rgb(${red}, ${green}, 0)`
}

const ScoreSummary: React.FC<ScoreSummaryProps> = ({
    avgScore,
    structuralScore,
    ntiaScore,
    semanticScore,
    qualityScore,
    sharingScore,
    lang
}) => {
    // Get context
    const context = useContext(I18nContext)

    // Determine locale
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang
        } else {
            console.warn(`Unsupported language: ${lang}. Using default.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("I18n context is not initialized. Ensure the provider is configured correctly.")
    }

    return (
        <Page size="A4">
            <Header externalData={null} />

            <View>
                <Text style={styles.titulo}>{i18n[locale].introduction}</Text>
                <Text style={styles.textua}>
                    {i18n[locale].explanation}
                </Text>
            </View>

            <View style={styles.centeredContainer}>
                <View style={styles.summaryContainer}>
                    <Text style={styles.title}>{i18n[locale].resume}</Text>
                    <View style={styles.summaryContent}>

                        <View style={styles.gaugeContainer}>
                            <View style={[styles.gauge, { backgroundColor: getColorForScore(avgScore) }]}>
                                <Text style={styles.avgScoreText}>{avgScore.toFixed(1)}</Text>
                                <Text style={styles.gaugeLabel}>
                                    {avgScore >= 7 ? "Good" : avgScore >= 4 ? "Average" : "Poor"}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.scoreDetailsContainer}>
                        {[
                            { label: "Structural", score: structuralScore },
                            { label: "NTIA Minimum Elements", score: ntiaScore },
                            { label: "Semantic", score: semanticScore },
                            { label: "Quality", score: qualityScore },
                            { label: "Sharing", score: sharingScore }
                        ].map((item, index) => (
                            <View key={index} style={styles.scoreRow}>
                                <Text style={styles.scoreLabel}>{item.label}</Text>
                                <View
                                    style={[
                                        styles.scoreIndicator,
                                        { backgroundColor: getColorForScore(item.score) }
                                    ]}
                                >
                                    <Text style={styles.scoreText}>{item.score.toFixed(1)}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
            </View>

            <Footer externalData={null} />
        </Page>
    )
}

export { ScoreSummary }
