import { Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React from "react"

interface CafFleetsAffectedProps {
    value: number;
}

const CafFleetsAffected: React.FC<CafFleetsAffectedProps> = ({ value }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    return (

        <Typography
        >
            {value} Fleets
        </Typography>
    )
}
export { CafFleetsAffected }
export default CafFleetsAffected
