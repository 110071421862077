import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"

const normativas = [
    { id: "Normativas" },
    { id: "- Open Web Application Security Project (OWASP)" },
    { id: "- IEC 62443-4-1" },
    { id: "- The Penetration Testing Execution Standard Documentation" },
    { id: "- PCI Data Security Standard (PCI DSS)" }
]

const i18n = {
    es: {
        index: "Índice",
        introduction: "Introducción",
        resume: "Resumen ejecutivo",
        identification: "Identificación de vulnerabilidades",
        monthyear: "Mes y año:",
        footer: {
            copyright: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
            confidential: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial exclusivamente dirigida a su destinatario.",
            disclosureWarning: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
            errorNotice: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    },
    en: {
        index: "Index",
        introduction: "Introduction",
        resume: "Executive Summary",
        identification: "Vulnerability Identification",
        footer: {
            copyright: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
            confidential: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
            disclosureWarning: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
            errorNotice: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.",
            tlpAmber: "TLP-AMBER"
        }
    }
} as const
const Index: React.FC<{ template?: Template, lang?: string }> = ({ template, lang }) => {
    // Obtener las configuraciones de la metadata
    const toc = template?.metadata?.data.table_of_contents
    const theme = useTheme()
    // Generar dinámicamente el índice
    const dynamicIndex = []
    let pageIndex = 3 // Página inicial (puedes ajustarlo según tu diseño)
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "en"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    if (toc?.introduction) {
        dynamicIndex.push({ title: i18n[locale].introduction, page: pageIndex++ })
    }
    if (toc?.executive_summary) {
        dynamicIndex.push({ title: i18n[locale].resume, page: pageIndex++ })
    }
    if (toc?.table_of_content) {
        dynamicIndex.push({ title: i18n[locale].identification, page: pageIndex++ })
    }

    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}></Header>
            <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 150, paddingRight: 10 }}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>Index</Text>
                <View>
                    {dynamicIndex.map((item, index) => (
                        <Text
                            key={index}
                            style={{
                                ...styles.puntuak2,
                                paddingLeft: 40,
                                marginRight: -30,
                                paddingBottom: 5
                            }}
                        >
                            <Text
                                style={{
                                    ...styles.puntuak,
                                    textAlign: "left",
                                    fontFamily: "Bold",
                                    fontSize: 10.5,
                                    marginRight: -20
                                }}
                            >
                                {index + 1}. {item.title}..........................................................{item.page}
                            </Text>
                        </Text>
                    ))}
                </View>
                <Text>&nbsp;</Text>
                <View style={{ ...styles.tableScope, width: "100%" }}>
                    <Text
                        style={{
                            ...styles.tableColScope,
                            textAlign: "left",
                            width: "58.25%",
                            height: 120,
                            fontSize: 10.5
                        }}
                    >
                        {normativas.map((fila, index) => (index === 0 ? fila.id : `\n\n${fila.id}`))}
                    </Text>
                </View>
            </View>
            <Footer externalData={null} template={template} />
        </Page>
    )
}

const FooterIndex: React.FC<{lang?: string}> = ({ lang }) => {
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    let locale: keyof typeof i18n = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View>
            <View>
                <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                    {i18n[locale].footer.copyright} {i18n[locale].footer.confidential}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].footer.tlpAmber}</Text>
                    {i18n[locale].footer.disclosureWarning}
                    {i18n[locale].footer.errorNotice}
                </Text>
            </View>
        </View>
    )
}

export { Index }
