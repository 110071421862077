/* eslint-disable multiline-ternary */
import { Subject } from "@casl/ability"
import { Box, Grid, Skeleton, useTheme } from "@mui/material"
import React from "react"

const StatsCardSkeleton: React.FC<{ entity: Subject }> = ({ entity }) => {
    const theme = useTheme()

    const renderDonutSkeletons = () => (
        <Grid container spacing={2} justifyContent="center">
            {[1, 2, 3].map((item) => (
                <Grid item xs={12} sm={4} key={item}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {/* Si es el primer item y la entidad es "Hierarchy", renderizar un rectángulo en vez de un círculo */}
                        {item === 1 && entity === "Hierarchy"
                            ? (
                                <Skeleton variant="rounded" width={300} height={180} sx={{ flexShrink: 0 }} />
                            )
                            : (
                                <Skeleton variant="circular" width={150} height={150} sx={{ flexShrink: 0 }} />
                            )}

                        {/* Contenedor de textos a la derecha */}
                        <Box sx={{ pl: "20px", marginLeft: 5, textAlign: "left", flexGrow: 1 }}>
                            <Skeleton variant="text" width={100} height={30} sx={{ marginBottom: 1 }} />
                            <Grid container spacing={1} alignItems="center">
                                {[1, 2, 3].map((subItem) => (
                                    <Grid item container xs={12} alignItems="center" key={subItem}>
                                        <Skeleton variant="circular" width={15} height={15} sx={{ marginRight: 1 }} />
                                        <Skeleton variant="text" width={80} height={20} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )

    const renderTopVulnerableSkeleton = () => (
        <Box sx={{ width: "30%", mt: { xs: 3, lg: 0 }, p: 1, ml: 2 }}>
            <Skeleton variant="text" width={200} height={40} sx={{ marginBottom: 2 }} />
            <Grid container spacing={2} justifyContent="center">
                {[1, 2, 3, 4, 5].map((item) => (
                    <Grid item xs={6} sm={4} md={3} key={item} sx={{ textAlign: "center" }}>
                        <Skeleton variant="text" width={80} height={20} sx={{ margin: "auto" }} />
                        <Skeleton variant="text" width={40} height={30} sx={{ margin: "auto" }} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )

    return (
        <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            p: 2,
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {renderDonutSkeletons()}
            {entity !== "Hierarchy" && renderTopVulnerableSkeleton()}
        </Box>
    )
}

export default StatsCardSkeleton
