/* eslint-disable multiline-ternary */
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import ProductTableDefinition from "@components/product/ProductTableDefinition"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import Product, { ProductType } from "@models/Product"
import { Divider, Grid, Tab, Tooltip, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { StyledTab, StyledTabs } from "@views/product/Components/StyledTabs"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import { ArrowForward } from "@mui/icons-material"
import Component from "@models/Component"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { ProductTypeColored } from "@components/product"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import DependenciesTab from "./DependenciesTab"
import ComponentDetailTab from "./ComponentDetailTab"

const initValue: Component = {
    id: "",
    bom_ref: "",
    type: ProductType.Library,
    name: "",
    version: "",
    supressed: false,
    outdated: false,
    cpe: "",
    license: "",
    assessment_id: "",
    client_id: ""
}
const ComponentDetail: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const { componentId } = useParams<{ id: string; componentId: string }>()
    const [error, setError] = useState<Error|null>(null)
    const [formData, setFormData] = useState<Component>(initValue)
    const componentService = useContext(ServicesContext).componentService
    const ability = useContext(AbilityContext)
    const productService = useContext(ServicesContext).productService
    const [cols, setCols] = useState<GridColDef[]>([])
    const [loading, setLoading] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [packageData, setPackageData] = useState<any>()
    const [hasDependencies, setHasDependencies] = useState<boolean>(false)
    // ------------------------------
    const { track, trackData } = useTrack()
    const [tabIndex, setTabIndex] = useState(0)

    const handleTabChange = (event: any, newIndex: number) => {
        setTabIndex(newIndex)
    }

    // First load
    useEffect(() => {
        track({ view: "ComponentDetail" })

        const fetchComponentData = async () => {
            try {
                const val = await componentService.get(componentId as string)
                setFormData(val)
                fetchPackageData(val.bom_ref) // Llamar a la función separada para obtener los paquetes
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }

        const fetchPackageData = async (bomRef: string) => {
            try {
                const fetchedPackage = await componentService.getPackages(bomRef)
                setPackageData(fetchedPackage)
            } catch (e: any) {
                if (e.status === 400 || e.status === 404) {
                    console.error("Package data not found:", e)
                } else {
                    setError({ message: e.error } as Error)
                    setSnackbarOpen(true)
                }
            }
        }

        fetchComponentData()
    }, [])
    // ------------------------------

    // Save handler
    const navigate = useNavigate()

    // Set the columns for the products table
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
    }, [])

    return (
        <Grid item container flexDirection="column" rowGap="25px">
            <StyledBox>
                <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Component Edit Section //trans">
                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h5" fontWeight="bold">
                            {formData.name}
                        </Typography>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={error?.message || context.t.translate("an_error")}
                        />
                        <ArrowForward sx={{ mx: 1 }} />
                        <Typography variant="h6">v{formData.version}</Typography>
                    </Grid>
                </Grid >

                <Grid container flexDirection="row" alignItems="center" gap={2} justifyContent="space-between" sx={{ mt: 2 }}>

                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ marginRight: 1 }}>
                            <strong>{context.t.translate("type")}:</strong>
                        </Typography>
                        <Tooltip title={context.t.translate("type")}>
                            <ProductTypeColored value={formData.type}/>
                        </Tooltip>
                    </Grid>
                    <Grid item >
                        <StyledTabs value={tabIndex} onChange={handleTabChange} aria-label="component tabs">
                            <StyledTab icon={<ExpandMoreIcon />} label="Details" sx={{ minWidth: 100, width: "auto", paddingX: 1, fontSize: "0.875rem" }}/>
                            <StyledTab icon={<TbDeviceHeartMonitor />} label={context.t.translate("products")} sx={{ minWidth: 100, width: "auto", paddingX: 1, fontSize: "0.875rem" }}/>
                            {hasDependencies && <Tab label={context.t.translate("dependencies")} />}
                        </StyledTabs>
                    </Grid>

                </Grid>

                <Divider sx={{ width: "100%", backgroundColor: "gray", mb: 2 }} />

                {tabIndex === 0 && (
                    <ComponentDetailTab/>
                )}

                {tabIndex === 1 && (
                    <Grid item container>
                        {loading && formData.cpe === ""
                            ? (
                                null
                            ) : (
                                <Grid item container>
                                    <GenericTable<Product>
                                        entity="Product"
                                        columns={cols}
                                        dataProvider={(filter) => {
                                            // Asegúrate de que formData.cpe ya tiene un valor
                                            return productService.getProductByCPE(formData.cpe!)
                                        }}
                                        onEdit={(elem: Product) => navigate("./" + elem.id)}
                                        onDelete={(elem: Product) => productService.delete(elem.id)}
                                    />
                                </Grid>
                            )}
                    </Grid>
                )}

                {tabIndex === 2 && hasDependencies && (
                    <DependenciesTab parentComponent={formData}/>
                )}
            </StyledBox>
        </Grid >
    )
}
export { ComponentDetail }
export default ComponentDetail
