/* eslint-disable indent */
import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import RateReviewIcon from "@mui/icons-material/RateReview"
import AssessmentIcon from "@mui/icons-material/Assessment"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Checkbox, Chip, Divider, Grid, MenuItem, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material"
import { StyledTextarea } from "@components/common/textareas/index"
import { ServicesContext } from "@context/index"
import { CafVulnStatus, CVSS3CriticalityKeysValueOps, getCVSS3Criticality, Vulnerability, VulnerabilityCAFStatusKeys, VulnerabilityCAFStatusKeysValueOps, VulnerabilityState } from "@models/index"
import CVSS3Metric from "@models/CVSS"
import "./styles.css"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import CalculateIcon from "@mui/icons-material/Calculate"
import HistoryIcon from "@mui/icons-material/History"
import CustomTooltip from "@components/common/tooltip/CustomTooltip"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { format } from "date-fns"
import { CafVulnStatusColors } from "@components/common/colors/VulnerabilityStateColors"
import useMediaResponsive from "@hooks/useMobile"
import CWEDataGrid from "./CWE/CWEDataGrid"

const initValue: Vulnerability = {
    id: "",
    identifier: "",
    score: 0,
    revised_score: 0,
    priority: 0,
    epss: 0,
    percentile: 0,
    kev: false,
    supressed: false,
    vector: "",
    description: "",
    state: VulnerabilityState.Detected,
    justification: null,
    response: [],
    revised_severity: -1,
    time_spent: -1,
    cmp_name: undefined,
    cmp_version: undefined,
    assessment: { id: "" },
    product: { id: "" },
    client: { id: "" },
    origin: "",
    metadata: undefined,
    generator: undefined,
    generator_time: undefined,
    affected_component: "",
    rank: 0,
    status: CafVulnStatus.LowRisk
}

interface TabPanelProps {
    children?: any
    index: number[]
    value: number
}

const CustomTabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props
    if (index.includes(value)) {
        return (children)
    }
    return (<div></div>)
}

const VulnDetailsTab: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const componentService = useContext(ServicesContext).componentService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    // const { id } = useParams<{ id: string }>()
    const [formData, setFormData] = useState<Vulnerability>(initValue)
    const [prevFormData, setPrevFormData] = useState<Vulnerability>(initValue)
    const [label, setLabel] = useState<string | undefined>("")
    const [colorone, setColor] = useState<string>("#ffffff")
    const [color1, setColor1] = useState<string>("#ffffff")
    const [color2, setColor2] = useState<string>("#ffffff")
    const location = useLocation()
    const { row } = location.state || {}
    const { id } = useParams()
    const [isFormValid, setIsFormValid] = useState(false)
    const isMobile = useMediaResponsive("(max-width: 780px)")

    useEffect(() => {
        const isValid = formValidResponse()
        console.log("UseEffectStatus", isValid)
        setIsFormValid(isValid)

        if (formData.status !== CafVulnStatus.LowRisk && formData.status !== CafVulnStatus.WaitingForTriage &&
            formData.status !== CafVulnStatus.WaitingSupplierAssessment && formData.status !== CafVulnStatus.WaitingCafAssessment) {
                setFormData((prevState) => ({
                    ...prevState,
                    revised_score: 0
                }))
                console.log("Estamos en finished")
                console.log("Valor de score en finish", formData.revised_score)
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    revised_score: prevFormData.revised_score
                }))
            }

        console.log("Valor vuelta score", formData.revised_score)
    }, [formData])

    const formValidResponse = (): boolean => {
        console.log("formData.status:", formData.status)
        console.log("prevFormData.status:", prevFormData.status)

        const isNotNullrules = [
            formData.response as any === "",
            formData.revised_score as any === ""
        ]

        console.log("Valor de score", formData.revised_score)

        const isNotSameOldValues = [
            formData.state === prevFormData.state,
            formData.justification === prevFormData.justification,
            formData.response === prevFormData.response,
            formData.revised_score === prevFormData.revised_score,
            formData.vector === prevFormData.vector,
            formData.status === prevFormData.status
        ]

        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "VulnerabilityDetail" })
    }, [])
    const handleStateChange = (value: any) => {
        if (value === CafVulnStatus.LowRisk || value === CafVulnStatus.WaitingForTriage) {
            setRegistry(0)
            setColor("yellow")
            setColor1("#ffffff")
            setColor2("#ffffff")
        } else if (value === CafVulnStatus.WaitingSupplierAssessment || value === CafVulnStatus.WaitingCafAssessment) {
            setRegistry(1)
            setColor1("yellow")
        } else if (value === CafVulnStatus.WaitingForRemediation || value === CafVulnStatus.FinishWithMitigation || value === CafVulnStatus.FinishWithRemediation || value === CafVulnStatus.FinishWithMitigationAndRemediation || value === CafVulnStatus.FinishWithoutRemediation || value === CafVulnStatus.NotAffected) {
            setRegistry(2)
            setColor2("yellow")
        }
    }

    // Generic change handler for all form fields
    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === "state") {
            handleStateChange(value)
        }
        if (name === "status") {
            handleStateChange(value)
        }
        if (name === "revised_score") {
            if (value > 10) {
                return
            }
        }
        setFormData({ ...formData, [name]: value })
    }
    // Generic change handler for all form fields - END

    // Fetch vulnerability data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await vulnerabilityService.get(id as string)
                setFormData(val)
                setPrevFormData(val)
                handleStateChange(val.status)
                const criticality = getCVSS3Criticality(val.revised_score)
                setLabel(CVSS3CriticalityKeysValueOps.find(v => v.value === criticality)?.label)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [])
    // useEffect(() => {
    //     const fecthData = async () => {
    //         try {
    //             const val = await vulnerabilityService.get(id as string)
    //             setData(val)
    //             setFormData(val)
    //             handleStateChange(val.state)
    //             initValue = val
    //             const criticality = getCVSS3Criticality(val.revised_score)
    //             setLabel(CVSS3CriticalityKeysValueOps.find(v => v.value === criticality)?.label)
    //         } catch (e: any) {
    //             setError({ message: e.Errors.Response } as Error)
    //         }
    //     }
    //     fecthData()
    // }, [])
    // Fetch vulnerability data from API - END
    // Evita renderizar objetos en React directamente
    const renderMetadata = (metadata: any) => {
        if (!metadata) return "No metadata available"

        if (typeof metadata === "object") {
            return Object.entries(metadata.data || {}).map(([key, value]) => (
                <Typography key={key} variant="body2">
                    <strong>{key}:</strong> {Array.isArray(value) ? value.join(", ") : value?.toString()}
                </Typography>
            ))
        }

        return metadata.toString()
    }

    const navigate = useNavigate()
    // API Call save assessment
    const saveHandler = async () => {
        try {
            formData.revised_score = formData.state === VulnerabilityState.Resolved || formData.state === VulnerabilityState.NotAffected || formData.state === VulnerabilityState.FalsePositive ? 0 : Number(formData.revised_score)
            await vulnerabilityService.update(formData.id, formData)
            setPrevFormData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call save assessment - END

    // TABS
    const [registry, setRegistry] = React.useState(1)

    /* const handleRegistryChange = (event: React.SyntheticEvent, newRegistry: number) => {
        setRegistry(newRegistry)
    } */

    // MODAL
    const handleOpenCalculator = () => {
        track({ view: "CVSSCalculator" })
        navigate("./cvss")
    }
    const handleOpenCalculator4 = () => {
        track({ view: "CVSSCalculator4" })
        navigate("./cvss4")
    }
    const handleOpenHistoric = () => {
        track({ view: "VulnerabilityHistoric" })
        navigate("./historic")
    }
    const handleOpenAffectedComponents = async () => {
        const response = await componentService.getComponentsByCVE(prevFormData.identifier)
    }

    type CVSS3MetricValues = {
        label: string
        metric: string
        value: number
    }
    type CVSS3MetricI = {
        AV: CVSS3MetricValues | null
        AC: CVSS3MetricValues | null
        PR: {
            label: string
            metric: string
            value: number | { U: number, C: number }
        } | null
        UI: CVSS3MetricValues | null
        S: CVSS3MetricValues | null
        C: CVSS3MetricValues | null
        I: CVSS3MetricValues | null
        A: CVSS3MetricValues | null
    }
    // Almacena los value de cada metrica

    const [CVSS3Metrics, setCVSS3Metrics] = useState<CVSS3MetricI>({
        AV: CVSS3Metric.AV.values.N,
        AC: CVSS3Metric.AC.values.L,
        PR: CVSS3Metric.PR.values.N,
        UI: CVSS3Metric.UI.values.N,
        S: CVSS3Metric.S.values.U,
        C: CVSS3Metric.C.values.N,
        I: CVSS3Metric.I.values.N,
        A: CVSS3Metric.A.values.N
    })
    useEffect(() => {
        importCVSS3(prevFormData.vector)
        // setDefaultValuesTempEnv()
    }, [prevFormData])

    const importCVSS3 = (cvss: string) => {
        cvss.split("/").forEach((metric) => {
            const [key, value] = metric.split(":")
            if (key in CVSS3Metric) {
                setCVSS3Metrics((prevState) => ({
                    ...prevState,
                    [key]: Object.values(CVSS3Metric[key as keyof typeof CVSS3Metric].values).find((v) => v.metric === value)
                }))
            }
        })
    }
    const InfoField = ({ label, value, isChip = false }: { label: string, value: string | number | undefined, isChip?: boolean | undefined }) => (
        <Grid item>
            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                {label}
            </Typography>
            {isChip
                ? (
                    <Chip size="small" label={value} style={{ fontWeight: "bolder", borderWidth: "1px", borderStyle: "solid", marginTop: "5px" }} />
                )
                : (
                    <TextField disabled value={value} variant="standard" className="" />
                )}
        </Grid>
    )
    const infoFields = [
        { label: context.t.translate("vulner_product") || "Product", value: formData.product.name, xs: 2 },
        { label: context.t.translate("vulner_vers"), value: formData.product.version, xs: 2 },
        { label: context.t.translate("vulner_comp"), value: `${formData.cmp_name} (${formData.cmp_version})`, xs: 2 },
        { label: context.t.translate("vulner_criti"), value: label, xs: 2 },
        { label: context.t.translate("vulner_score"), value: formData.score, xs: 1, isChip: true },
        { label: context.t.translate("vulner_prio"), value: formData.priority, xs: 1 },
        { label: context.t.translate("vulner_vector"), value: formData.vector, xs: 3 }
    ]

    useEffect(() => {
        console.log("FORMRR", formData)
    }, [formData])
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative", pt: "20px" }}>
            <Grid item container>
                <StyledBox>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("error_message")}
                    />
                    <Grid item container flexDirection="column" spacing="20px">
                        <Grid item container justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }} xs>
                            <Grid item>
                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                    {formData.identifier}
                                </Typography>
                            </Grid>
                            <Grid item container spacing={2} xs justifyContent={isMobile ? "flex-start" : "flex-end"} gap={2}>
                                {/* <ActionButton icon={<HistoryIcon/>} onClick={handleOpenAffectedComponents} text="Affected Components" variant="outlined"/> */}
                                <ActionButton icon={<HistoryIcon />} onClick={handleOpenHistoric} text="Vulnerability Historic" variant="outlined" />
                                <ActionButton icon={<CalculateIcon />} onClick={handleOpenCalculator} text={context.t.translate("vulner_cvs")} variant="outlined" />
                                <ActionButton icon={<CalculateIcon />} onClick={handleOpenCalculator4} text={context.t.translate("cvss_4")} variant="outlined" />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        </Grid>

                        <Grid item container flexDirection="row" spacing={2} marginBottom={4}>
                            {infoFields.map((field, index) => (
                                <Grid item xs={12} sm={6} md={field.xs} key={index}>
                                    <InfoField label={field.label} value={field.value} isChip={field.isChip} />
                                </Grid>
                            ))}

                            {prevFormData.revised_score !== prevFormData.score && (
                                <Grid item xs={2}>
                                    <InfoField label={context.t.translate("vulner_revis")} value={formData.revised_score} />
                                </Grid>
                            )}
                            <Grid item xs={12} md={3} direction="column">
                                <Grid item container xs={12} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_kev")}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomTooltip title={context.t.translate("kev_modal")} />
                                    </Grid>
                                    <Grid container xs={12} alignItems="center" direction="row">
                                        <Checkbox name="KEV" disabled checked={formData.kev} style={{ fontFamily: "Griff" }} sx={{
                                            "&.Mui-disabled": {
                                                color: theme.palette.primary.main, // Color del ícono del checkbox cuando está deshabilitado
                                                "& .MuiSvgIcon-root": {
                                                    fill: theme.palette.primary.main // Color del SVG del checkbox (el ícono)
                                                }
                                            }
                                        }} />
                                        {formData.kev
                                            ? (
                                                <Typography color="primary" fontSize="18px" fontFamily="Griff" fontWeight="bolder" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("vulner_sikev")}
                                                </Typography>
                                            )
                                            : (
                                                <Typography color="primary" fontSize="18px" fontFamily="Griff" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("vulner_nokev")}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={2} container direction="column">
                                <Grid item container alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_epss")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomTooltip title={context.t.translate("epss_modal")} />
                                    </Grid>
                                </Grid>
                                <TextField name="EPSS" disabled value={formData.epss} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                            </Grid>
                            {formData.generator !== undefined && (
                                <>
                                    <Grid item xs={1.5} container direction="column">
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_generator")}
                                        </Typography>
                                        <TextField
                                            name="Generator"
                                            disabled
                                            value={formData.generator}
                                            variant="standard"
                                            style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                        />
                                    </Grid>

                                    <Grid item xs={1.5} container direction="column">
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_generator_time")}
                                        </Typography>
                                        <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                            {formData.generator_time ? format(new Date(formData.generator_time), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={4} >
                                <Typography color="primary" width="100%" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_desc")}</Typography>
                                <TextField
                                    name="Description"
                                    maxRows={4}
                                    multiline
                                    disabled
                                    value={formData.description}
                                    variant="standard"
                                    style={{ width: "100%" }}
                                    InputProps={{
                                        sx: {
                                            "& textarea": {
                                                color: `${theme.palette.primary.main} !important`,
                                                WebkitTextFillColor: `${theme.palette.primary.main} !important`,
                                                fontFamily: "Griff !important",
                                                opacity: "1 !important"
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid item mt={1} mb={5}>
                                <Tabs centered value={registry} sx={{ justifyContent: "space-around", width: "100%" }} orientation={isMobile ? "vertical" : "horizontal"}>
                                    <Tab label={
                                        <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                            <RateReviewIcon style={{ marginRight: "5px", marginBottom: "-10px", fontSize: "20px", width: "32px", height: "32px" }} />
                                            {context.t.translate("detected")}</Typography>
                                    } sx={{ flex: 1, color: colorone }} value={0} />
                                    <Tab label={
                                        <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                            <AssessmentIcon style={{ marginRight: "5px", marginBottom: "-8px", fontSize: "20px", width: "32px", height: "32px" }} />
                                            {context.t.translate("in_triage")}</Typography>
                                    } sx={{ flex: 1, color: color1 }} value={1} />
                                    <Tab label={
                                        <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                            <CheckCircleIcon style={{ marginRight: "5px", marginBottom: "-8px", fontSize: "20px", width: "32px", height: "32px" }} />
                                            {context.t.translate("triage_finish")}
                                        </Typography>
                                    } sx={{ flex: 1, color: color2 }} value={2} />
                                </Tabs>
                            </Grid>

                            <Grid item container flexDirection="row" alignItems="center" xs={12} spacing={4}>
                                <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                                <CustomTabPanel value={registry} index={[0, 1, 2]}>
                                    <Grid item xs={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_stat")}
                                        </Typography>
                                        <TextField
                                            select
                                            size="small"
                                            variant="filled"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleChange}
                                            sx={{
                                                paddingTop: "0px",
                                                "& .MuiSelect-icon": {
                                                    color: "primary.main", // Cambiar el color de la flecha usando un color de tu tema, por ejemplo `primary.main`
                                                    top: "50%",
                                                    right: "8px",
                                                    transform: "translateY(-50%)" // Asegura que la flecha se coloque bien
                                                }
                                            }}
                                            SelectProps={{
                                                renderValue: (selected: unknown) => {
                                                    const selectedValue = selected as CafVulnStatus
                                                    const selectedKey = (VulnerabilityCAFStatusKeys as (keyof typeof CafVulnStatus)[]).find(
                                                        (key) => CafVulnStatus[key] === selectedValue
                                                    )

                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: "-10px" }}>
                                                            <Typography
                                                                fontFamily="Griff"
                                                                fontWeight="bolder"
                                                                sx={{ color: CafVulnStatusColors[selectedValue] }}
                                                            >
                                                                {selectedKey} {/* Muestra el nombre del estado */}
                                                            </Typography>
                                                        </div>
                                                    )
                                                }
                                            }}
                                        >
                                            {VulnerabilityCAFStatusKeysValueOps.map((opt, idx) => (
                                                <MenuItem
                                                    key={idx}
                                                    value={opt.value}
                                                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                                >
                                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: CafVulnStatusColors[opt.value] }}>
                                                        {opt.label} {/* Mostrar la etiqueta legible */}
                                                    </Typography>
                                                    {/* <Tooltip title={getDescriptionForState(opt.value, context.language as "en" | "es")}>
                                                        <IconButton size="small">
                                                            <HelpOutlineIcon color="primary"/>
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                </CustomTabPanel>
                                </Grid>

                                <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                                    <CustomTabPanel value={registry} index={[1, 2]}>
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" noWrap fontWeight="bolder">{context.t.translate("vulner_revis")}</Typography>
                                            <TextField key={formData.revised_score} type="number" name="revised_score" value={registry > 1 ? 0 : formData.revised_score} disabled={registry > 1} variant="outlined" size="small" style={{ fontFamily: "Griff", color: theme.palette.primary.main }} onChange={handleChange} sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                color: theme.palette.primary.main, // Color del texto cuando está deshabilitado
                                                WebkitTextFillColor: theme.palette.primary.main // Para compatibilidad con algunos navegadores
                                            }
                                        }} />
                                        </Grid>
                                    </CustomTabPanel>
                                </Grid>

                                {/* <CustomTabPanel value={registry} index={[1, 2]}>
                                    <Grid container justifyContent="center" item xs={12} spacing="35px">

                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                                {context.t.translate("vulner_just")}
                                            </Typography>
                                            <Select
                                                name="justification"
                                                size="small"
                                                variant="outlined"
                                                value={formData.justification}
                                                onChange={handleChange}
                                                fullWidth
                                                sx={{
                                                    borderRadius: "8px",
                                                    border: "1px solid #271968"
                                                }}
                                                renderValue={(selected) => {
                                                    const selectedValue = selected as VulnerabilityJustification
                                                    const selectedKey = VulnerabilityJustificationKeys.find(
                                                        (key) => VulnerabilityJustification[key as keyof typeof VulnerabilityJustification] === selectedValue
                                                    )

                                                    return (
                                                        <Box display="flex" alignItems="center" gap={1}>
                                                            <Typography fontFamily="Griff" fontWeight="bolder">
                                                                {selectedKey}
                                                            </Typography>
                                                            <Tooltip title={getJustificationDescription(selectedValue, context.language as "en" | "es")}>
                                                                <HelpOutlineIcon sx={{ fontSize: 18, cursor: "pointer" }} color="primary"/>
                                                            </Tooltip>
                                                        </Box>
                                                    )
                                                }}
                                            >
                                                {VulnerabilityJustificationKeysValueOps.map((opt, idx) => (
                                                    <MenuItem key={idx} value={opt.value} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography>
                                                        <Tooltip title={getJustificationDescription(opt.value, context.language as "en" | "es")}>
                                                            <HelpOutlineIcon sx={{ fontSize: 18, cursor: "pointer" }} color="primary"/>
                                                        </Tooltip>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                                {context.t.translate("vulner_resp")}
                                            </Typography>
                                            <Select
                                                name="response"
                                                size="small"
                                                value={formData.response}
                                                onChange={handleChange}
                                                fullWidth
                                                sx={{
                                                    borderRadius: "8px",
                                                    border: "1px solid #271968",
                                                    "&:hover": { border: "2px solid #271968" },
                                                    "&.Mui-focused": { border: "2px solid primary.main" }
                                                }}
                                                renderValue={(selected) => {
                                                    if (!selected || Array.isArray(selected)) return ""
                                                    const selectedValue = selected as VulnerabilityResponse
                                                    const selectedKey = VulnerabilityResponseKeys.find(
                                                        (key) => VulnerabilityResponse[key as keyof typeof VulnerabilityResponse] === selectedValue
                                                    )

                                                    return (
                                                        <Box display="flex" alignItems="center" gap={1}>
                                                            <Typography fontFamily="Griff" fontWeight="bolder">
                                                                {selectedKey}
                                                            </Typography>
                                                            <Tooltip title={getResponseDescription(selectedValue, context.language as "en" | "es")}>
                                                                <HelpOutlineIcon sx={{ fontSize: 18, cursor: "pointer" }} color="primary" />
                                                            </Tooltip>
                                                        </Box>
                                                    )
                                                }}
                                            >
                                                {VulnerabilityResponseKeysValueOps.map((opt, idx) => (
                                                    <MenuItem key={idx} value={opt.value} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography>
                                                        <Tooltip title={getResponseDescription(opt.value, context.language as "en" | "es")}>
                                                            <HelpOutlineIcon sx={{ fontSize: 18, cursor: "pointer" }} color="primary" />
                                                        </Tooltip>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>

                                </CustomTabPanel> */}
                                <CustomTabPanel value={registry} index={[1, 2]}>
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_det")}</Typography>
                                        <StyledTextarea minRows={4} placeholder="Enter details here..." style={{ width: "100%", boxSizing: "border-box" }} />
                                    </Grid>
                                </CustomTabPanel>

                                {prevFormData && <Grid item xs={12} my={2}><ActionButton variant="contained" onClick={saveHandler} disabled={!isFormValid} style={{ width: "100%" }} text={context.t.translate("product_save")} /></Grid>}

                            </Grid>
                        </Grid>
                    </Grid>
                    {formData.cwe && formData.cwe.length > 0 &&
                        <><Grid item>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_weak")}</Typography>
                        </Grid>
                            <CWEDataGrid formData={formData} /></>
                    }
                </StyledBox>

            </Grid>

        </Grid >
    )
}

export { VulnDetailsTab }
export default VulnDetailsTab
