import React, { useContext } from "react"
import { Box, Grid } from "@mui/material"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { I18nContext } from "I18nProvider"
import { useStatsData } from "./useStatsData"
import TopItemsList from "./components/TopItemList"

interface VulnerabilityStatsProps {
    timeRange?: string;
}

const VulnerabilityStats: React.FC<VulnerabilityStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const {
        byVulnState,
        unmitigatedVulnerability,
        unmitigatedVulnerabilities,
        mitigatedVulnerability,
        stateNumber,
        topItems,
        isLoading
    } = useStatsData("Vulnerability", timeRange)

    if (isLoading || !context) return null

    return (
        <Grid container spacing={2} sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <Grid item xs={12} sm={3} width="100%" sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ minWidth: "220px", flex: 1 }}>
                    <GenericDonutChart
                        title={context.t.translate("criticity")}
                        dataType="object"
                        data={unmitigatedVulnerabilities || {}}
                        number={unmitigatedVulnerability || 0}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={3} width="100%" sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ minWidth: "220px", flex: 1 }}>
                    <GenericDonutChart
                        title={context.t.translate("mitigations")}
                        dataType="mitigated-unmitigated"
                        data={{
                            mitigated: mitigatedVulnerability || 0,
                            unmitigated: unmitigatedVulnerability || 0
                        }}
                        number={(mitigatedVulnerability || 0) + (unmitigatedVulnerability || 0)}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={3} width="100%" sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ minWidth: "220px", flex: 1 }}>
                    <GenericDonutChart
                        title={context.t.translate("by_state")}
                        data={byVulnState || []}
                        dataType="array"
                        number={stateNumber || 0}
                        footer="TYPES"
                    />
                </Box>
            </Grid>
            {topItems && topItems.length > 0 && (
                <Grid item xs={12} sm={3} sx={{ display: "flex", justifyContent: "left" }}>
                    <Box sx={{ minWidth: "220px", flex: 1 }}>
                        <TopItemsList
                            title={context.t.translate("top5_components")}
                            items={topItems}
                            valueKey="total"
                            nameKey="name"
                        />
                    </Box>
                </Grid>
            )}
        </Grid>

    )
}

export default VulnerabilityStats
