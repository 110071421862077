import React from "react"
import { Box, Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"

interface TopItemsListProps {
    title: string;
    items: any[];
    valueKey: string;
    nameKey: string;
    categoryKey?: string;
    colorFn?: (item: any) => string | undefined;
}

const TopItemsList: React.FC<TopItemsListProps> = ({
    title,
    items,
    valueKey,
    nameKey,
    categoryKey,
    colorFn
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")) // Detecta si es móvil
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")) // Detecta si es tableta

    if (!items || items.length === 0) return null

    return (
        <Box sx={{
            width: "100%",
            mt: { xs: 3, lg: 0 },
            p: 1,
            ml: 1
        }}>
            <Typography variant="h6" sx={{ fontSize: isMobile ? "16px" : "20px", fontWeight: "bold" }}>
                {title}
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: 2,
                    flexWrap: isMobile ? "wrap" : "nowrap", // En móviles, los elementos se apilan
                    display: "flex",
                    justifyContent: isMobile ? "center" : "space-between", // Centrado en móviles
                    gap: isMobile ? 1 : 2 // Espaciado dinámico
                }}
            >
                {items.map((item, index) => (
                    <Grid
                        item
                        key={index}
                        xs={isMobile ? 12 : isTablet ? 6 : "auto"} // Tamaño flexible según la pantalla
                        sx={{
                            textAlign: "center",
                            flexGrow: 1,
                            minWidth: isMobile ? "100%" : 0, // En móviles ocupa toda la fila
                            maxWidth: isMobile ? "100%" : "auto"
                        }}
                    >
                        <Tooltip title={categoryKey ? `${item[nameKey]} - ${item[categoryKey]}` : item[nameKey]} placement="top">
                            <Box sx={{ maxWidth: "100%" }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: colorFn ? colorFn(item) : theme.palette.text.secondary,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                        display: "block",
                                        fontSize: isMobile ? "14px" : "16px"
                                    }}
                                >
                                    {item[nameKey]}
                                </Typography>
                                {categoryKey && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            color: theme.palette.text.secondary,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "100%",
                                            display: "block",
                                            fontSize: isMobile ? "12px" : "14px"
                                        }}
                                    >
                                        {item[categoryKey]}
                                    </Typography>
                                )}
                            </Box>
                        </Tooltip>
                        <Typography
                            variant="body2"
                            fontWeight="bold"
                            fontSize={isMobile ? "14px" : "16px"} // Reduce el tamaño en móviles
                        >
                            {item[valueKey]}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default TopItemsList
