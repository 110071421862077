import React, { useContext } from "react"
import { Grid, Typography } from "@mui/material"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"
import { I18nContext } from "I18nProvider"
import Shield from "./Shield"

export type AverageTime = {
    Low: number,
    Medium: number,
    High: number,
    Critical: number
}

type AverageProps = {
    averageTime: AverageTime
}

const Average: React.FC<AverageProps> = ({ averageTime }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    return (
        <Grid container spacing={2} direction="column">
            {/* Primera fila con Typography */}
            <Grid item>
                <Typography variant="h6" sx={{ textAlign: "left", fontWeight: "bold", mb: 1 }}>
                    {context.t.translate("average_title")}
                </Typography>
            </Grid>

            {/* Segunda fila con 4 columnas, cada columna tiene un Shield y su nombre debajo */}
            <Grid item container spacing={2} justifyContent="space-between">
                <Grid item xs={6} sm={3} style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Shield text={averageTime.Low ? averageTime.Low.toString() : "N/A"} color={CVSS3SeverityColors.low} />
                    <Typography sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", sm: "1rem" }, mt: 1 }}>
                        {context.t.translate("low")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3} style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Shield text={averageTime.Medium ? averageTime.Medium.toString() : "N/A"} color={CVSS3SeverityColors.medium} />
                    <Typography sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", sm: "1rem" }, mt: 1 }}>
                        {context.t.translate("medium")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3} style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Shield text={averageTime.High ? averageTime.High.toString() : "N/A"} color={CVSS3SeverityColors.high} />
                    <Typography sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", sm: "1rem" }, mt: 1 }}>
                        {context.t.translate("high")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3} style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Shield text={averageTime.Critical ? averageTime.Critical.toString() : "N/A"} color={CVSS3SeverityColors.critical} />
                    <Typography sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", sm: "1rem" }, mt: 1 }}>
                        {context.t.translate("critical")}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Average
