import React, { useEffect } from "react"
import { Document, Font } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Achilles, { MonitorName, TestType } from "@models/Achilles"
import Template from "@models/Template"
import { CustomText } from "@models/CustomText"
import { BackPage } from "./Aquiles/sections/99_BackPage"
import { FrontPage } from "./Aquiles/networkStress/0_FrontPage"
import { Index } from "./Aquiles/networkStress/0_Index"
import { Intro } from "./Aquiles/networkStress/1_Intro"
import { ScopeSection } from "./Aquiles/networkStress/2_ScopeSection"
import { Resultado } from "./Aquiles/networkStress/5_2_Results"
import { Conclusion } from "./Aquiles/networkStress/6_Conclusion"
import { ServiceDetection } from "./Aquiles/networkStress/4_1_ServiceDetection"
import { AnomalyIdentification } from "./Aquiles/networkStress/5_AnomalyIdentification"
import { TestExecutions } from "./Aquiles/networkStress/5_3_TestExecutions"
import { ExecutiveSection } from "./Aquiles/networkStress/3_ExecutiveSection"
import { CustomFrontPage } from "./GenericVulnerability/customSections/0_CustomFrontPage"
import { SimplifiedIndex } from "./sections/0_Simplified_index"
import CustomContentPage from "./sections/CustomContentPage"

Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    return word.split("-")
}

Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    assessment: Assessment;
    achilles: Achilles[];
    externalData?: any;
    customText?: CustomText;
    template?: Template;
}

type AchillesTestType = "Fuzzing" | "Network Stress";

const VulnReportDocumentAchilles: React.FC<VEXDocumentProps> = ({ assessment, template, achilles, externalData, customText }) => {
    const [testType, setTestType] = React.useState<AchillesTestType>("Network Stress")
    const [protocolByOSI, setServices] = React.useState<{ [key: string]: string[] } | null>(null)

    // OSI Logic
    type OSILayer = "physical" | "datalink" | "network" | "transport" | "application";
    const orderedOSI = ["physical", "datalink", "network", "transport", "session", "presentation", "application"]
    const osiLayers: { [key: string]: OSILayer } = {
        Ethernet: "physical",
        ARP: "datalink",
        LLDP: "datalink",
        "EtherNet/IP": "datalink",
        IP: "network",
        ICMP: "network",
        IGMP: "network",
        TCP: "transport",
        UDP: "transport",
        HTTP: "application",
        HTTPS: "application",
        FTP: "application",
        NTP: "application",
        RDP: "application",
        RCP: "application",
        SNMP: "application",
        Telnet: "application",
        DNP3: "application",
        "FF-HSE": "application",
        GOOSE: "application",
        "IEC 104": "application",
        MMS: "application",
        "MODBUS/TCP": "application",
        "OPC UA": "application",
        PROFINET: "application",
        "ARINC 429": "application",
        ZigBee: "application",
        "SES-92": "application"
    }
    const groupServicesByOsiLayer = (protocolByOSI: string[]) => {
        const groupedServices = protocolByOSI.reduce<{ [key: string]: string[] }>((acc, service) => {
            const layer = osiLayers[service]
            if (layer) {
                if (!acc[layer]) {
                    acc[layer] = []
                }
                if (!acc[layer].includes(service)) {
                    acc[layer].push(service)
                }
            } else {
                if (!acc.Others) {
                    acc.Others = []
                }
                if (!acc.Others.includes(service)) {
                    acc.Others.push(service)
                }
            }
            return acc
        }, {})
        const orderedGroupedServices: { [key: string]: string[] } = {}
        orderedOSI.forEach((layer) => {
            if (groupedServices[layer]) {
                orderedGroupedServices[layer] = groupedServices[layer]
            }
        })
        if (groupedServices.Others) {
            orderedGroupedServices.Others = groupedServices.Others
        }
        return orderedGroupedServices
    }
    React.useEffect(() => {
        if (achilles && Array.isArray(achilles)) {
            setServices(groupServicesByOsiLayer(achilles.map((d) => d.test_type)))
        }
    }, [achilles])
    // --------------------------------------------------

    // Monitor Logic
    const orderedMonitors = ["T", "L1", "A1", "I1", "TP1", "UDP1"]
    const monitorNameMapping: { [key: string]: MonitorName } = {
        T: MonitorName.Test,
        L1: MonitorName.LinkStateMonitor,
        A1: MonitorName.ARPMonitor,
        I1: MonitorName.ICMPMonitor,
        TP1: MonitorName.TCPPortsMonitor
    }
    const getMonitorKeyByEnumValue = (value: MonitorName): string | undefined => {
        for (const key in monitorNameMapping) {
            if (monitorNameMapping[key] === value) {
                return key
            }
        }
        return undefined
    }
    const achillesMonitors: string[] = Array.from(
        new Set(achilles.map(item => getMonitorKeyByEnumValue(item.monitor_name))
            .filter((monitor): monitor is string => monitor !== undefined)
        )
    )
    const monitors = [
        ...orderedMonitors.filter(monitor => achillesMonitors.includes(monitor)),
        ...achillesMonitors.filter(monitor => !orderedMonitors.includes(monitor))
    ]
    // --------------------------------------------------
    type AchillesProtocols = "Ethernet" | "ARP" | "LLDP" | "IP" | "ICMP" | "IGMP" | "TCP" | "HTTP" | "UDP" | "FTP" | "NTP" | "RDP" | "RPC" | "SNMP" | "TELNET" | "DNP3" | "EtherNetIP" | "CIP" | "FFHSE" | "GOOSE" | "IEC 104" | "MMS" | "OPC UA" | "MODBUSTCP" | "PROFINET"
    type AchillesData = {
        [osi in OSILayer]?: {
            [protocol in TestType]?: {
                [test: string]: {
                    [monitor: string]: {
                        alert: string,
                        value?: string
                    }
                }
            }
        }
    }
    const data: AchillesData = {}
    achilles.forEach(item => {
        const currentOSI = osiLayers[item.test_type]
        if (!data[currentOSI]) {
            data[currentOSI] = {}
        }
        if (!data[currentOSI]![item.test_type]) {
            data[currentOSI]![item.test_type] = {}
        }
        if (!data[currentOSI]![item.test_type]![item.test_name]) {
            data[currentOSI]![item.test_type]![item.test_name] = {}
        }
        const currentMonitor = getMonitorKeyByEnumValue(item.monitor_name)
        if (currentMonitor) {
            data[currentOSI]![item.test_type]![item.test_name][currentMonitor] = { alert: item.monitor_value, value: item.failure_percentage.toString() }
        } else {
            console.error(`Monitor name ${item.monitor_name} is not mapped`)
        }
    })
    const tableOfContents = template?.metadata?.data?.table_of_contents
    useEffect(() => {
        console.log("$$$", template)
    }, [template])
    return (
        <>
            {!template || !template.id || template.id === "00000000-0000-0000-0000-000000000000"
                ? (
                    <Document pageLayout="twoColumnRight">
                        <FrontPage assessment={assessment} testType={testType} />
                        <Index />
                        <Intro assessment={assessment} testType={testType} customIntro={customText?.custom_text.introduction} />
                        <ScopeSection assessment={assessment} testType={testType} externalData={null} customScope={customText?.custom_text.scope} />
                        <ExecutiveSection achilles={achilles} testType={testType} achillesData={data} monitors={monitors} />
                        <ServiceDetection protocolByOSI={protocolByOSI} externalData={null} customTestInit={customText?.custom_text.test_initialization}/>
                        <AnomalyIdentification testType={testType} externalData={null} />
                        <Resultado achillesData={data} monitors={monitors} />
                        <TestExecutions achillesData={data} monitors={monitors} />
                        <Conclusion achillesData={achilles} externalData={null} customConclusion={customText?.custom_text.conclusion}/>
                        <BackPage />
                    </Document>
                )
                : (
                    <Document pageLayout="twoColumnRight">
                        <CustomFrontPage
                            template={template}
                            assessment={{ endDate: new Date().toUTCString() } as Assessment}
                        />
                        <SimplifiedIndex
                            metadata={
                                template.metadata.data.table_of_contents
                            }
                        />
                        {tableOfContents?.introduction && (
                            <Intro assessment={assessment} testType={testType} template={template} customIntro={customText?.custom_text.introduction}/>
                        )}
                        {tableOfContents?.scope && (
                            <ScopeSection assessment={assessment} testType={testType} externalData={null} template={template} customScope={customText?.custom_text.scope}/>
                        )}
                        {tableOfContents?.executive_summary && (
                            <ExecutiveSection
                                achilles={achilles}
                                testType={testType}
                                achillesData={data}
                                monitors={monitors}
                                template={template}
                            />
                        )}
                        {tableOfContents?.table_of_content && (
                            <ServiceDetection protocolByOSI={protocolByOSI} externalData={null} template={template} customTestInit={customText?.custom_text.test_initialization}/>
                        )}
                        <AnomalyIdentification testType={testType} externalData={null} template={template} />
                        <Resultado achillesData={data} monitors={monitors} template={template}/>
                        <TestExecutions achillesData={data} monitors={monitors} template={template}/>
                        <CustomContentPage template={template}/>
                        <Conclusion achillesData={achilles} externalData={null} template={template} customConclusion={customText?.custom_text.conclusion}/>
                        <BackPage template={template} />
                    </Document>
                )}
        </>
    )
}

export { VulnReportDocumentAchilles }
export default VulnReportDocumentAchilles
