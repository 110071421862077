import React, { useState } from "react"
import { Grid, TextField } from "@mui/material"

interface ReferenceGridProps {
    initialReference?: string; // Opcional para iniciar con un dato existente
    onChange?: (updatedReference: string) => void; // Prop para pasar el estado actualizado al padre
}

const ReferenceGrid: React.FC<ReferenceGridProps> = ({ initialReference = "", onChange }) => {
    const [reference, setReference] = useState<string>(initialReference)

    const handleInputChange = (value: string) => {
        setReference(value) // Actualizar el estado
        onChange?.(value) // Notificar al padre
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Reference"
                    value={reference}
                    onChange={(e) => handleInputChange(e.target.value)}
                />
            </Grid>

        </Grid>
    )
}

export default ReferenceGrid
