import Snapshot from "@models/Snapshot"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class SnapshotService extends Service<Snapshot> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/snapshot", MediaTypes.JSON)
    }
}

export { SnapshotService }
export default SnapshotService
