import { ModelRelation } from "@models/utils"
import { CVSS3Severity } from "./Vulnerability"
type Assessment = {
    id: string;
    type: AssessmentType;
    status: AssessmentStatus;
    contractDate: string | null;
    startDate?: string | null;
    endDate?: string | null;
    product: ProductRelation;
    client: ModelRelation;
    template_id: string;
    analysis_status?: number | null;
    monitoring?: boolean
}

type AssessmentExt = Assessment & {
    // VulnerabilityNumber: number;

}

type ProductRelation = {
    id: string,
    name?: string,
    version?: string
}

type TemplateRelation = {
    id: string,
    name?: string
}

enum AssessmentType {
    CRT = "crt",
    LogicalPentest = "logical_pentest",
    FunctionalTest = "functional_test",
    SCA = "sca",
    PortScan = "port_scan",
    NetworkScan = "network_scan",
    WebScan = "web_scan",
    ContainerScan = "container_scan",
    ThreatModel = "threat_model",
    StaticAnalysis = "static_analysis",
    DynamicAnalysis = "dynamic_analysis"
}

enum AssessmentStatus {
    Contracted = "contracted",
    Planned = "planned",
    Started = "started",
    Ended = "ended",
}
type TotalAssessment = Record<CVSS3Severity, number>;
export { AssessmentType, AssessmentStatus }
export type { Assessment, AssessmentExt, TotalAssessment }
export default Assessment
