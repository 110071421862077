import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import License, { LicenseType } from "@models/License"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { useContext, useEffect, useState } from "react"
import { MdOutlineMail, MdOutlineWallet } from "react-icons/md"
import { useNavigate } from "react-router-dom"

const MembershipCard = ({ handleContactClick }:{handleContactClick: () => void}) => {
    const [data, setData] = useState<License>()
    const clientService = useContext(ServicesContext).clientService
    const licenseService = useContext(ServicesContext).licenseService
    const ability = useContext(AbilityContext)
    const theme = useTheme()
    const fetchData = async () => {
        try {
            const licenseData = await licenseService.getAll({ sortField: "id" })
            const clientData = await clientService.getAll({ sortField: "name" })

            if (clientData.count > 0) {
                const clientId = clientData.list[0].id
                const matchingLicense = licenseData.list.find(license => license.client.id === clientId)

                if (matchingLicense) {
                    setData(matchingLicense)
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    const context = useContext(I18nContext)
    const navigate = useNavigate()
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <Box
            border="1px solid #4A4733"
            borderRadius="8px"
            padding="5px"
            justifyContent="center"
            gap={2}
            margin="0 auto"
            marginTop="15px"
            width="90%"
        >
            <Grid display="flex" gap={2} >
                <Grid>
                    <MdOutlineWallet fontSize="30px" />
                </Grid>
                <Grid>
                    <Typography>{context.t.translate("current_plan")}</Typography>
                    <Typography sx={{ fontFamily: "Griff" }}>
                        {data?.type === LicenseType.Golden || data?.type === LicenseType.Platinum || data?.type === LicenseType.Titanium
                            ? `${context.t.translate("you_are_currently_pro")} ${data?.type}`
                            : context.t.translate("you_are_currently_free")}
                    </Typography>
                    {/* <Link onClick={() => navigate("./manage/ticket")} sx={{ cursor: "pointer", color: theme.palette.primary.contrastText }} fontSize="14px">See princig plans</Link> */}
                </Grid>

            </Grid>
            <Grid
                display="flex"
                alignItems="center"
                border="1px solid #4A4733"
                borderRadius="20px"
                color={theme.palette.primary.contrastText}
                padding="5px 10px"
                marginTop="10px"
                gap={2}
                onClick={() => handleContactClick()}
                sx={{
                    cursor: "pointer"
                }}>
                <MdOutlineMail />
                <Typography>{context.t.translate("switch_plan")}</Typography>
            </Grid>
        </Box>
    )
}

export default MembershipCard
