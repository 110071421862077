/* eslint-disable space-before-function-paren */

import { ComponentStats, DeviceStats, LocationStats, StatsAssessments, StatsProducts, StatsTags, StatsVulnerabilities } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"
import { FilterOperation, QueryParameters } from "@utils/index"

class StatsService extends Service<any> {
    public constructor() {
        super(process.env.REACT_APP_API, "api/v1/stats", MediaTypes.JSON)
    }

    public getStatsAssessment = (time?: string) => {
        return this.http.get(`/${this.path}/assessments?time_range=${time}`) as Promise<StatsAssessments>
    }

    public getStatsAssessmentFilter = (id: string) => {
        return this.http.getMultiple(`/${this.path}/assessments`, { filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: id }] }) as any
    }

    public getStatsVulnerabilities = (time?: string) => {
        return this.http.get(`/${this.path}/vulnerabilities?time_range=${time}`) as Promise<StatsVulnerabilities>
    }

    public getStatsVulnerabilitiesWithParams = (params: QueryParameters) => {
        return this.http.getMultiple(`/${this.path}/vulnerabilities`, params) as any
    }

    public getStatsVulnerabilitiesFilter = (id: string) => {
        return this.http.getMultiple(`/${this.path}/vulnerabilities`, { filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: id }] }) as any
    }

    public getStatsProduct = (time: string = "all") => {
        return this.http.get(`/${this.path}/products?time_range=${time}`) as Promise<StatsProducts>
    }

    public getDeviceStats = () => {
        return this.http.get(`/${this.path}/devices`) as Promise<DeviceStats[]>
    }

    public GetStatsComponents = async (time?: string): Promise<ComponentStats> => {
        try {
            const response = await this.http.get(`/${this.path}/components?time_range=${time}`)

            // Si la respuesta es null, undefined o un objeto vacío, lanzar error "No content"
            if (!response || Object.keys(response).length === 0) {
                throw new Error("Error. No content")
            }

            return response // Si hay datos, devolverlos normalmente
        } catch (error) {
            console.error("Error al obtener estadísticas de componentes:", error)
            throw error // Relanzar el error para manejarlo en useEffect
        }
    }

    public getDashboardStats = () => {
        return this.http.get(`/${this.path}/dashboard`) as Promise<any>
    }

    public getCafStats = (time?: string) => {
        return this.http.get(`/${this.path}/caf?time_range=${time}`) as Promise<any>
    }

    public getStatsLocation = () => {
        return this.http.get(`/${this.path}/locations`) as Promise<LocationStats>
    }

    public getStatsTags = (time?: string) => {
        return this.http.get(`/${this.path}/tags?time_range=${time}`) as Promise<StatsTags>
    }

    public getHierarchyStats = (time?: string) => {
        return this.http.get(`/${this.path}/hierarchy/caf?time_range=${time}`) as Promise<any>
    }
}

export { StatsService }

export default StatsService
