
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import CAFVulnerability from "@models/CAFVulnerability"

class CAFVulnerabilityService extends Service<CAFVulnerability> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/caf/vulnerability", MediaTypes.JSON)
    }

    public getbyIdExt = (cve: string) => {
        return this.http.get(`/${this.path}/${cve}`) as any
    }
}

export { CAFVulnerabilityService }
export default CAFVulnerabilityService
