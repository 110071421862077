
type SystemMessage = {
    id: string;
    title: string;
    message: string;
    action?: string | null;
    type: MessageType;
    // client: ModelRelation;
    date?: string | null;
    read: boolean;
    created_at: string;
};

enum MessageType {
    Conversational = "Conversational",
    RequiredAction = "RequiredAction",
}

export { MessageType }
export type { SystemMessage }
export default SystemMessage
