import React, { useCallback, useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import { GridColDef, GridRowClassNameParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid"
import { Typography, useTheme } from "@mui/material"
import { format } from "date-fns"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext } from "@components/permissions/index"
import { VulnerabilityTableDefinition } from "@components/vulnerability/index"
import { ServicesContext } from "@context/index"
import { Vulnerability } from "@models/index"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { pdf } from "@react-pdf/renderer"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import StatsCard from "@components/common/stats/StatsCard"
import { Filter, filterOperationMap } from "@models/Filter"
import FilterDropdown from "@components/filters/FiltersDrawer"

const VulnerabilityList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService

    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const { track, trackData } = useTrack()

    const ability = useContext(AbilityContext)
    useEffect(() => {
        track({ view: "VulnerabilityList" })
        setCols(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    }, [])

    const RequestAnalyze = async () => {
        try {
            const response = await vulnerabilityService.analyze()
        } catch (error) {
            console.error(error)
        }
    }

    const apiRef = useGridApiRef()
    const [params, setParams] = useState<QueryParameters>({})

    const HandleExport = async () => {
        try {
            if (rowSelectionModel.length > 0) {
                params.filters = [{
                    field: "id",
                    operation: FilterOperation.UUIDArrayContains,
                    value: rowSelectionModel.join(",")
                }]
            }
            const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
            const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }

    const getAllVulns = async () => {
        try {
            const filters: QueryParameters = { sortField: "name", sortMode: "desc" }
            const response = await vulnerabilityService.getAllType(filters, "csv")

            if (response.list.length === 0) {
                console.error("No data to export 1.")
                return
            }
            // Generar encabezados CSV
            const headers = ["identifier", "state", "score", "revised_score", "priority", "epss", "kev", "vector", "client", "product", "description"].join(";")

            const safeValue = (value: any) => (value === null || value === undefined ? "" : value)

            // Generar contenido CSV
            const rows = response.list.map((vuln: { identifier: any; state: any; score: any; revised_score: any; priority: any; epss: any; kev: any; vector: any; client: { name: any }; product: { name: any }; description: string }) =>
                [safeValue(vuln.identifier), safeValue(vuln.state), safeValue(vuln.score), safeValue(vuln.revised_score), safeValue(vuln.priority), safeValue(vuln.epss), safeValue(vuln.kev), safeValue(vuln.vector), safeValue(vuln.client.name), safeValue(vuln.product.name), safeValue(vuln.description.replace(/;/g, "."))].join(";")
            ).join("\n")

            const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`
            const encodedUri = encodeURI(csvContent)
            const link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            const date = Date.now()
            const time = format(date, "MMMM do, yyyy H:mma")
            link.setAttribute("download", "cves_" + time + ".csv")
            document.body.appendChild(link) // Requerido para Firefox
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.error(error)
            // return undefined; // Retorna undefined en caso de error
        }
    }
    const exportToCSV = () => {
        getAllVulns()
    }

    // Tracking
    useEffect(() => {
        track({ view: "VulnerabilityList" })
        fetchFilters()
    }, [])
    const filterService = useContext(ServicesContext).filterService
    const [fetchedFilters, setFetchedFilters] = useState<Filter[]>([])

    // const ability = useContext(AbilityContext)
    // useEffect(() => {
    //     setColumn(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    // }, [])

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
    const fetchFilters = async () => {
        try {
            const response = await filterService.getAll({ filters: [{ field: "type", operation: FilterOperation.StringEqual, value: "vulnerabilities" }] })
            console.log(response)
            setFetchedFilters(response.list)
        } catch (error: any) {
            console.error(error)
        }
    }
    const handleExportPDF = async () => {
        try {
            const customParams = { ...params }
            if (rowSelectionModel.length > 0) {
                customParams.filters = [{ field: "id", operation: FilterOperation.UUIDArrayContains, value: rowSelectionModel.join(",") }]
            } else {
                customParams.page_number = 0
                customParams.page_size = 100
                customParams.filters = [{ field: "supressed", operation: FilterOperation.StringEqual, value: "false" }]
            }

            const vulnerabilityList = await vulnerabilityService.getAll(customParams)

            const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }
    const handleFilterApplied = (filter: Filter) => {
        // Convertimos los filtros de 'Filter' a 'FilterOption' para que coincidan con el formato de 'QueryParameters'
        const newFilters: FilterOption[] = filter.filters.map((f) => ({
            field: f.Field, // Mapear el campo
            operation: filterOperationMap[f.FilterOperation], // Convertimos el FilterOperation de tipo numérico a cadena
            value: f.Value.join(",") // Asumimos que 'Value' es un array de strings y lo convertimos a una cadena
        }))

        setParams({ filters: newFilters })
    }
    const handleFilterDeselected = () => {
        setParams({ filters: [] })
    }
    const dataProvider = useCallback(
        async (filter: any) => {
            try {
                const response = await vulnerabilityService.getAll({ ...filter, ...params })
                return response
            } catch (error) {
                console.error("Error fetching data:", error)
                throw error
            }
        },
        [params, params.filters]
    )
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <FilterDropdown filters={fetchedFilters} onFilterApplied={handleFilterApplied} onFilterDeselected={handleFilterDeselected} />

                <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="45px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("vulnerabilities")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            color={theme.palette.text.secondary}
                            fontSize="15px"
                            fontWeight="bolder"
                            fontFamily="Griff"
                        >
                            {context.t.translate("sections.vulnerabilitiesList")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <ActionButton onClick={handleExportPDF} text={context.t.translate("export_pdf")} />
                    {/* <SplitButton options={[
                        { label: "Export to PDF", action: handleExportPDF }
                        // { label: "Export to XML", action: handleExportXML } // TODO: Implementar exportación a XML
                    ]}></SplitButton> */}
                </Grid>
            </Grid>
            <Grid width="100%">
                <StatsCard title={context.t.translate("vulnerabilities")} entity="CAFVulnerability" />
            </Grid>

            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={RequestAnalyze} startIcon={<TbAnalyze/>}>Reanalyze</Button> "170px" */}
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={HandleExport} startIcon={<FaFileExport />}>{context.t.translate("dash_export")}</Button> "170px" */}
            {/* <ButtonMenu mainActions={} additionalActions={}></ButtonMenu> */}
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px" }} onClick={exportToCSV} startIcon={<FaFileExport/>}>Export Data</Button> */}
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                    dataProvider={dataProvider}
                    onEdit={(elem: Vulnerability) => navigate("./" + elem.id, { state: { row: elem } })}
                    onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                    key={JSON.stringify(params)}
                    dataGridProps={{
                        minWidth: 400,
                        getRowClassName: (params: GridRowClassNameParams) =>
                            params.row.supressed ? "suppressedRow" : "",
                        sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } },
                        checkboxSelection: true,
                        rowSelectionModel,
                        onRowSelectionModelChange: (newRowSelectionModel: GridRowSelectionModel) => { setRowSelectionModel(newRowSelectionModel) },
                        apiRef
                    }} />
            </Grid >

        </Grid>
    )
}

export { VulnerabilityList }
export default VulnerabilityList
