import React, { useContext } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { FilterOperation, FilterOption } from "@utils/index"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions"
import { Provider } from "@models/Provider"

interface ProviderSelectorProps {
    value: string;
    customFilters?: FilterOption[];
    onSelect: (id: string) => void;
    selectedProviders: string[]; // Recibimos la lista de proveedores seleccionados
}

const ProviderSelector: React.FC<ProviderSelectorProps> = ({ value, customFilters, onSelect, selectedProviders }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const providerService = useContext(ServicesContext).providerService

    // Filtramos los proveedores seleccionados para que no aparezcan en las opciones
    const isProviderSelected = (providerId: string) => {
        return selectedProviders.includes(providerId)
    }

    return (
        <SearchInput<Provider>
            margin="none"
            required
            label={context.t.translate("product_newprovider")}
            variant="outlined"
            value={value}
            requesterById={async (v) => {
                if (v !== undefined) {
                    const product = await providerService.get(v)
                    return [product]
                }
                const pro: Provider = {
                    id: "",
                    name: "",
                    description: "",
                    provider_id: "",
                    client: { id: "", name: "" }
                }
                return [pro]
            }}
            requester={async (v) => {
                let filter: FilterOption[] = [{ field: "name", operation: FilterOperation.StringContains, value: v }]
                if (customFilters !== undefined && customFilters[0].value !== "") {
                    filter = filter.concat(customFilters)
                }
                // Obtenemos todos los proveedores, pero filtramos para eliminar los ya seleccionados
                const products = await providerService.getAll({ filters: filter })
                const filteredProducts = products.list.filter(
                    (product) => !selectedProviders.includes(product.id)
                )
                return filteredProducts // Devolvemos solo los proveedores no seleccionados
            }}
            getterID={(elem) => elem?.id}
            getterTitle={(elem) => elem.name}
            onClickElem={(v) => {
                if (!isProviderSelected(v?.id || "")) {
                    onSelect(v?.id || "")
                }
            }}
        />
    )
}

export { ProviderSelector }
export default ProviderSelector
