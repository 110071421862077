import React, { useEffect } from "react"
import { Controls, ReactFlow } from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import Hierarchy, { CafHierarchyStatus, getCafHierarchyStatusColor } from "@models/Hierarchy"
import { Grid, useTheme } from "@mui/material"

const ColoredHierarchy = ({ hierarchies }: { hierarchies: Hierarchy[] }) => {
    const hierarchyMap = new Map(hierarchies.map(h => [h.id, h]))
    const theme = useTheme()
    // Función para obtener los hijos directos de un nodo
    const getDirectChildren = (parentId: string): Hierarchy[] => {
        return hierarchies.filter(h =>
            h.path &&
            h.path.split(";").slice(-2, -1)[0] === parentId
        )
    }
    // Función para formatear el status en un formato legible
    const formatHierarchyStatus = (status?: CafHierarchyStatus | null): string => {
        if (!status) return "N/A"

        // Reemplaza guiones bajos con espacios y capitaliza cada palabra
        return status
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }
    // Función para obtener nodos raíz (sin padres o con path de un solo elemento)
    const getRootNodes = (): Hierarchy[] => {
        return hierarchies.filter(h =>
            !h.path || h.path.split(";").length === 1
        )
    }
    useEffect(() => {
        console.log("$$$", hierarchies)
    }, [hierarchies])
    // Función recursiva para calcular las posiciones
    const calculatePositions = (
        node: Hierarchy,
        level: number,
        horizontalOffset: number,
        widthMap: Map<string, number>
    ): { positions: Map<string, { x: number; y: number }>, width: number } => {
        const children = getDirectChildren(node.id)
        const verticalSpacing = 150
        const horizontalSpacing = 250

        if (children.length === 0) {
            const position = { x: horizontalOffset, y: level * verticalSpacing }
            const positions = new Map([[node.id, position]])
            widthMap.set(node.id, horizontalSpacing)
            return { positions, width: horizontalSpacing }
        }

        let totalWidth = 0
        const childPositions = new Map<string, { x: number; y: number }>()

        // Calcular posiciones de los hijos
        children.forEach((child) => {
            const { positions: childPos, width } = calculatePositions(
                child,
                level + 1,
                horizontalOffset + totalWidth,
                widthMap
            )
            childPos.forEach((pos, id) => childPositions.set(id, pos))
            totalWidth += width
        })

        // Calcular posición del padre en el centro de sus hijos
        const firstChildX = childPositions.get(children[0].id)?.x || 0
        const lastChildX = childPositions.get(children[children.length - 1].id)?.x || 0
        const parentX = firstChildX + (lastChildX - firstChildX) / 2

        // Añadir posición del padre
        childPositions.set(node.id, {
            x: parentX,
            y: level * verticalSpacing
        })

        widthMap.set(node.id, totalWidth)
        return { positions: childPositions, width: totalWidth }
    }

    // Calcular todas las posiciones
    const widthMap = new Map<string, number>()
    const finalPositions = new Map<string, { x: number; y: number }>()
    let currentOffset = 0

    getRootNodes().forEach(root => {
        const { positions } = calculatePositions(root, 0, currentOffset, widthMap)
        positions.forEach((pos, id) => finalPositions.set(id, pos))
        currentOffset += (widthMap.get(root.id) || 0) + 50
    })

    // Crear nodos con las nuevas posiciones
    const nodes = hierarchies.map((hierarchy) => {
        const position = finalPositions.get(hierarchy.id) || { x: 0, y: 0 }
        return {
            id: hierarchy.id,
            position,
            data: {
                label: (
                    <Grid sx={{ color: "black" }}>
                        <div style={{ fontWeight: "bold" }}>{hierarchy.name || "Unnamed Hierarchy"}</div>
                        <div style={{ fontSize: "0.8em", marginTop: "3px" }}>Status: {formatHierarchyStatus(hierarchy.status)}</div>
                    </Grid>
                )
            },
            style: {
                backgroundColor: getCafHierarchyStatusColor(hierarchy?.status),
                color: "white",
                border: "2px solid gray",
                borderRadius: "12px",
                padding: "10px",
                width: "auto",
                minWidth: "150px",
                textAlign: "center" as const
            }
        }
    })

    // Crear edges
    const edges = hierarchies
        .filter((h) => h.path)
        .flatMap((hierarchy) => {
            const parentIds = hierarchy.path.split(";")
            const directParentId = parentIds[parentIds.length - 2]
            if (!directParentId || directParentId === hierarchy.id) return []

            return [{
                id: `${directParentId}-${hierarchy.id}`,
                source: directParentId,
                target: hierarchy.id,
                type: "smoothstep",
                animated: false,
                style: { stroke: "#888" }
            }]
        })

    return (
        <div style={{ width: "100%", height: "500px" }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
            >
                <Controls style={{ backgroundColor: theme.palette.primary.main, color: "#000" }}/>
            </ReactFlow>
        </div>
    )
}

export default ColoredHierarchy
