import React from "react"
import { Image, Page, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "../Styles"

interface BackPageProps {
  template?: Template;
}
const CustomImage = () => <Page size="A4">
    <View fixed>
        <Image style={styles.image} src="/assets/img/report/contra.jpg" />
    </View>
</Page>
const BackPage: React.FC<BackPageProps> = ({ template }) => {
    /* if (!template) {
    // Renderiza la estructura básica si no hay un template
        return (
            <CustomImage />
        )
    } */

    const imagen = ""
    let backgroundColor = ""
    const isCustomLogo = false
    // Si el template tiene un logo, lo procesamos
    /*  if (template?.logo && template.logo !== "") {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
    } else {
    // Si no hay logo, usamos la imagen predeterminada
        return (
            <CustomImage />
        )
    } */

    // Si el template tiene un color de fondo, lo aplicamos
    if (template?.color) {
        backgroundColor = !template || template.color === "#fffa37" ? "" : template.color
    }

    return (
        <Page size="A4" style={{ backgroundColor }}>
            <View fixed >
                <View style={{ flexDirection: "row", justifyContent: "space-between", height: "100%", paddingLeft: 3 }}> {/* Imágenes una al lado de la otra */}
                    <Image style={{ width: "100%", height: "auto", objectFit: "contain" }} src="/assets/img/CAF.png" /> {/* Ajuste para que no se estiren */}
                    <Image style={{ width: "100%", height: "auto", objectFit: "contain" }} src="/assets/img/LeadMind.png" /> {/* Ajuste para que no se estiren */}
                </View>
            </View>
        </Page>
    )
}

export { BackPage }
