import React, { useContext } from "react"
import { Box, Grid } from "@mui/material"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { I18nContext } from "I18nProvider"
import { AssessmentType } from "@models/Assessment"
import { AssessmentTypeColors } from "../colors/AssessmentTypeColors"
import { useStatsData } from "./useStatsData"
import TopItemsList from "./components/TopItemList"

interface AssessmentStatsProps {
  timeRange?: string;
}

const AssessmentStats: React.FC<AssessmentStatsProps> = ({ timeRange }) => {
    const context = useContext(I18nContext)
    const {
        byProduct,
        byCategory,
        byStatus,
        productNumber,
        totalTests,
        testTypesNumber,
        topItems,
        isLoading
    } = useStatsData("Assessment", timeRange)

    if (isLoading || !context) return null

    return (
        <Grid container spacing={2} sx={{ width: "100%", flexWrap: "wrap" }}>
            <Grid item xs={12} sm={3} sx={{ minWidth: "220px", flex: 1 }}>
                <Box>
                    <GenericDonutChart
                        title={context.t.translate("by_product")}
                        data={byProduct || []}
                        dataType="array"
                        number={productNumber || 0}
                        footer="PRODUCTS"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ minWidth: "220px", flex: 1 }}>
                <Box>
                    <GenericDonutChart
                        title={context.t.translate("by_status")}
                        data={byStatus || []}
                        dataType="array"
                        number={totalTests || 0}
                        footer="TOTAL TESTS"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ minWidth: "220px", flex: 1 }}>
                <Box>
                    <GenericDonutChart
                        title={context.t.translate("by_type")}
                        data={byCategory || []}
                        dataType="array"
                        number={testTypesNumber || 0}
                        footer="TEST TYPES"
                    />
                </Box>
            </Grid>

            {topItems && topItems.length > 0 && (
                <Grid item xs={12} sm={3} sx={{ minWidth: "300px", flex: 1 }}>
                    <TopItemsList
                        title={context.t.translate("top5_assessments")}
                        items={topItems}
                        valueKey="vulnerability_count"
                        nameKey="product_with_version"
                        colorFn={(item) => AssessmentTypeColors[item.assessment_type as AssessmentType] || undefined}
                    />
                </Grid>
            )}
        </Grid>

    )
}

export default AssessmentStats
